import PropertyDetailAction, {
  DELETE_PROPERTY_ERROR_TYPE,
} from "./PropertyDetailActions";
import PropertyDetailState from "./PropertyDetailState";

const initialState: PropertyDetailState = {
  property: undefined,
  ownerOrganisation: undefined,
  isLoading: false,
  error: undefined,
  isUpdatingContractor: false,
  availableContractors: [],
  deletion: undefined,
};

const propertyDetailReducer = (
  state = initialState,
  action: PropertyDetailAction
): PropertyDetailState => {
  switch (action.type) {
    case "RESET_PROPERTY":
      return { ...initialState };
    case "LOAD_PROPERTY":
      // Don't reload if we don't need to.
      if (action.propertyId === state.property?.id) {
        return { ...state };
      } else {
        return {
          property: undefined,
          ownerOrganisation: undefined,
          isLoading: true,
          error: undefined,
          isUpdatingContractor: false,
          availableContractors: [],
          deletion: undefined,
        };
      }

    case "PROPERTY_LOADED":
      return {
        property: action.property,
        ownerOrganisation: action.ownerOrganisation,
        error: undefined,
        isLoading: false,
        isUpdatingContractor: false,
        availableContractors: action.availableContractors,
        deletion: undefined,
      };

    case "PROPERTY_LOAD_ERROR":
      return {
        property: undefined,
        ownerOrganisation: undefined,
        error: action.error,
        isLoading: false,
        isUpdatingContractor: false,
        availableContractors: [],
        deletion: undefined,
      };

    case "PROPERTY_UPDATED_TYPE":
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isUpdatingContractor: false,
      };

    case "PROPERTY_UPDATED_SUCCESS_TYPE":
      return {
        ...state,
        property: action.property,
        error: undefined,
        isLoading: false,
        isUpdatingContractor: false,
        deletion: undefined,
      };

    case "PROPERTY_UPDATED_ERROR_TYPE":
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isUpdatingContractor: false,
        deletion: undefined,
      };

    case "UPDATE_CONTRACTOR_TYPE":
      return {
        ...state,
        isLoading: true,
        error: undefined,
        isUpdatingContractor: true,
        deletion: undefined,
      };

    case "DELETE_PROPERTY":
      return {
        ...state,
        deletion: "loading",
        error: undefined,
      };

    case "DELETE_PROPERTY_SUCCESS_TYPE":
      return {
        ...state,
        deletion: "deleted",
        error: undefined,
      };

    case DELETE_PROPERTY_ERROR_TYPE:
      return {
        ...state,
        deletion: undefined,
        error: action.error,
      };

    default:
      return state;
  }
};

export default propertyDetailReducer;
