import { Box, Chip, Divider } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import ColoredText from "../../../../../ui/components/ColoredText";
import LabelledText from "../../../../../ui/components/LabelledText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import { colorForStatus } from "../../../../properties/PropertyListPage/model/RAGStatus";

interface Props extends WithTranslation {
  organisationCounter: number | undefined;
  countryId: string;
}

const AgentInfoCard = (props: Props) => {
  //Labels
  const agentLabel = props.t("agentInfoCard.labelTitle").toLocaleUpperCase();
  const organisationLabel = props.t("agentInfoCard.organisationLabel");
  const countryLabel = props.t("agentInfoCard.countryLabel");
  const countryName = props.t(`countryCode.${props.countryId}`);
  const orgCounter =
    props.organisationCounter !== undefined ? props.organisationCounter : 0;

  return (
    <BorderedBox display="flex" flexDirection="column" height="fit-content">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={SMALL_SPACING}
      >
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="h4">
          <strong>{agentLabel}</strong>
        </ColoredText>

        <Box display="flex" flexDirection="column" marginLeft={SMALL_SPACING}>
          <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
            <strong>{organisationLabel}</strong>
          </ColoredText>
          <Chip
            size="small"
            label={<strong>{orgCounter}</strong>}
            style={{
              color: "white",
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor:
                orgCounter === 0 ? colorForStatus("amber") : colorForStatus("green"),
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" marginTop={SMALL_SPACING}>
        <LabelledText label={countryLabel} text={countryName} />
      </Box>
    </BorderedBox>
  );
};

export default withTranslation()(AgentInfoCard);
