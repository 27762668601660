import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import isValidEmail from "../../../../utils/isValidEmail";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  isLoading: boolean;
  inviteSent: boolean;

  onClose: () => void;
  sendInvite: (email: string) => void;
}

interface State {
  email: FieldState;
  confirmEmail: FieldState;
  showSuccess: boolean;
}

class AdminInviteContractorDialog extends React.Component<Props, State> {
  state = {
    email: { value: "" },
    confirmEmail: { value: "" },
    showSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.inviteSent && !prevProps.inviteSent) {
      this.props.onClose();

      this.setState({
        email: { value: "" },
        confirmEmail: { value: "" },
      });
    }
  }

  render() {
    const titlePlaceholder = this.props.t("adminInviteContractorDialog.titlePlaceholder");
    const emailHint = this.props.t("adminInviteContractorDialog.emailHint");
    const confirmEmailHint = this.props.t("adminInviteContractorDialog.confirmEmailHint");
    const inviteButton = this.props.t("adminInviteContractorDialog.inviteButton");
    const descriptionText = this.props.t("adminInviteContractorDialog.descriptionText");
    const contactHint = this.props.t("adminInviteContractorDialog.contactHint");
    const contactEmail = this.props.t("adminInviteContractorDialog.contactEmail");
    const title = titlePlaceholder
      .replace("%s", this.props.organisationName)
      .toLocaleUpperCase();

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Box padding={DEFAULT_SPACING}>
          <Grid container spacing={SMALL_SPACING} alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" align="center">
                {title}
              </Typography>
            </Grid>

            <Grid item />

            <Grid item xs={12} alignItems="center">
              <Typography variant="body2" align="center">
                {descriptionText}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  textTransform: "uppercase",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
                color="primary"
              >
                {contactHint}
                <a href={"mailto:" + contactEmail}>{contactEmail}</a>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <PrimaryTextField
                {...this.state.email}
                hint={emailHint}
                onChange={(v) => this.handleFieldChanged(v, "email")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <PrimaryTextField
                hint={confirmEmailHint}
                {...this.state.confirmEmail}
                onChange={(v) => this.handleFieldChanged(v, "confirmEmail")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>

            <Grid item />

            <Grid item xs={12}>
              <PrimaryButton
                label={inviteButton}
                endIcon={<FontAwesomeIcon icon={faArrowRight} size="2x" />}
                onClick={this.handleInviteSent}
                fullWidth
                isLoading={this.props.isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }

  // Handlers

  handleFieldChanged = (value: string, field: keyof State) => {
    const state: any = {};
    state[field] = { value: value };
    this.setState(state);
  };

  handleInviteSent = () => {
    if (this.validate()) {
      const email = this.state.email.value.trim();
      this.props.sendInvite(email);
    }
  };

  // Validation

  validate = (): boolean => {
    let valid = true;

    if (!isValidEmail(this.state.email.value)) {
      this.setState({
        email: {
          value: this.state.email.value,
          errorText: this.props.t("adminInviteContractorDialog.emailRequiredError"),
        },
      });
      valid = false;
    }

    if (this.state.confirmEmail.value.length === 0) {
      this.setState({
        confirmEmail: {
          value: this.state.confirmEmail.value,
          errorText: this.props.t("adminInviteContractorDialog.confirmEmailRequired"),
        },
      });
      valid = false;
    } else if (this.state.confirmEmail.value !== this.state.email.value) {
      this.setState({
        confirmEmail: {
          value: this.state.confirmEmail.value,
          errorText: this.props.t("adminInviteContractorDialog.confirmEmailMatchError"),
        },
      });
      valid = false;
    }

    return valid;
  };
}

export default withTranslation()(AdminInviteContractorDialog);
