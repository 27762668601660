import { AxiosInstance } from "axios";
import CurrentUserDetails from "../../pages/account/AccountSettingsPage/model/CurrentUserDetails";
import DataWrapper, { unwrapData, unwrapResponse } from "../utils/DataWrapper";
import PersonalInfoFormData from "./model/PersonalInfoFormData";
import UserDetails from "./model/UserDetails";
import UserRepository from "./UserRepository";
import SegmentationQuestion from "./model/SegmentationQuestion";

class RestUserRepository implements UserRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }
  getSegmentationQuestions = (): Promise<SegmentationQuestion[]> => {
    return this.axios
      .get<DataWrapper<SegmentationQuestion[]>>("/onboarding/questions")
      .then((response) => response.data)
      .then(unwrapData);
  };

  getMe = (): Promise<UserDetails> => {
    return this.axios
      .get<DataWrapper<UserDetails>>("/me")
      .then((response) => response.data)
      .then(unwrapData);
  };

  updateUserCategory = (): Promise<void> => {
    return Promise.resolve();
  };

  updateUserDetails = (
    user: CurrentUserDetails,
    segmentationAnswers: { [x in string]: string }
  ): Promise<UserDetails> => {
    let onboarding: { [x in string]: string } = {};

    if (Object.keys(segmentationAnswers).length > 0) {
      onboarding = { ...onboarding, ...segmentationAnswers };
    }

    //Params should be appended as JSON object
    const params = {
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
      address_postcode: user.address_postcode,
      onboarding: {
        ...onboarding,
      },
    };

    return this.axios.put<DataWrapper<UserDetails>>("/me", params).then(unwrapResponse);
  };

  registerUserDetails = (form: PersonalInfoFormData): Promise<UserDetails> => {
    const params = new URLSearchParams();
    params.append("terms_accepted", form.termsAccepted ? "1" : "0");
    params.append("first_name", form.firstName);
    params.append("last_name", form.lastName);
    params.append("phone_number", form.phoneNumber);
    params.append("address_postcode", form.addressPostcode);

    return this.axios
      .post<DataWrapper<UserDetails>>("/auth/register/user", params)
      .then(unwrapResponse);
  };
}

export default RestUserRepository;
