import { Country } from "../../../../rest/countries/model/Country";
import CreateOrganisationForm from "../../../auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";

const buildOrgFormFromCode = (
  organisation: CreateOrganisationForm,
  countryList: Country[] | undefined
): CreateOrganisationForm => {
  const newOrg: CreateOrganisationForm = {
    ...organisation,
    address: {
      ...organisation.address,
      country:
        countryList?.find((country) => country.code === organisation.address.country)
          ?.id || "",
    },
  };
  return newOrg;
};

export default buildOrgFormFromCode;
