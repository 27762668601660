import { Box, Grid } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import PhoneNumberTextField from "../../../../ui/components/PhoneNumberTextField";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import EditableFieldLabel from "../../PropertyDetailPage/components/EditableField/components/EditableFieldLabel";
import AddPropertyContactsInterface from "../interfaces/AddPropertyContactsInterface";
import ContactFormState from "../model/ContactFormState";
import { Save } from "@material-ui/icons";
import HomeZoneSwitchPanel from "../../PropertyDetailPage/components/HomeZoneSwitchPanel/HomeZoneSwitchPanel";

interface ExternalProps {
  //Callbacks
  onFormSubmitted: (contactProps: ContactFormState) => void;
  setContactProps: (contactProps: ContactFormState) => void;
  //Objects
  organisationList: Organisation[];
  addingProperty?: boolean;
  homezoneAvailable?: boolean;
  property: ContactFormState | undefined;
}

type AddPropertyContactFromProps = ExternalProps & WithT;
interface State extends AddPropertyContactsInterface {
  homezoneEnabled: boolean;
}

class AddPropertyContactForm extends React.Component<AddPropertyContactFromProps, State> {
  state = {
    contactFirstName: {
      value: this.props.property?.contactFirstName || "",
    } as FieldState,
    contactLastName: { value: this.props.property?.contactLastName || "" } as FieldState,
    contactNumber: { value: this.props.property?.contactNumber || "" } as FieldState,
    email: { value: this.props.property?.email || "" } as FieldState,
    notes: { value: this.props.property?.notes || "" } as FieldState,
    homezoneEnabled: false,
  };

  render() {
    const contactFirstNameHint = this.props.t("addPropertyDialog.firstNameHint");
    const contactLastNameHint = this.props.t("addPropertyDialog.lastNameHint");
    const contactNumberHint = this.props.t("addPropertyDialog.contactNumberHint");
    const notesHint = this.props.t("addPropertyDialog.notesHint");
    const emailHint = this.props.t("addPropertyDialog.emailHint");
    const backButton = this.props.t("addPropertyDialog.backButton");

    const submitButton = this.userHasFilledForm()
      ? this.props.t("addPropertyDialog.saveButton")
      : this.props.t("addPropertyDialog.skipButton");

    return (
      <Grid container spacing={SMALL_SPACING} alignContent="center">
        <Grid item />

        <Grid item xs={12}>
          <EditableFieldLabel
            label={this.props.t("addPropertyDialog.contactDetailsTitle")}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.contactFirstName}
            disabled={this.props.addingProperty}
            hint={contactFirstNameHint}
            onChange={(v) => this.onFieldChanged("contactFirstName", v)}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.contactLastName}
            disabled={this.props.addingProperty}
            hint={contactLastNameHint}
            onChange={(v) => this.onFieldChanged("contactLastName", v)}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.email}
            disabled={this.props.addingProperty}
            hint={emailHint}
            onChange={(v) => this.onFieldChanged("email", v)}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberTextField
            {...this.state.contactNumber}
            hint={contactNumberHint}
            disabled={this.props.addingProperty}
            onChange={(v) => this.onFieldChanged("contactNumber", v)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.notes}
            multiline={true}
            disabled={this.props.addingProperty}
            hint={notesHint}
            onChange={(v) => this.onFieldChanged("notes", v)}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          {this.props.homezoneAvailable && (
            <HomeZoneSwitchPanel
              loading={false}
              disabled={
                this.props.addingProperty || this.state.email.value === "" || false
              }
              homezoneEnabled={this.state.homezoneEnabled}
              onSwitchToggle={(homezoneStatus: boolean) => {
                this.setState({ ...this.state, homezoneEnabled: homezoneStatus });
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton
            onClick={this.onCreatePressed}
            fullWidth
            label={submitButton}
            endIcon={<Save />}
            isLoading={this.props.addingProperty}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <SecondaryButton label={backButton} onClick={this.onBackHandler} />
          </Box>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    );
  }

  onCreatePressed = () => {
    let property: ContactFormState = {
      contactFirstName: this.state.contactFirstName.value,
      contactLastName: this.state.contactLastName.value,
      contactNumber: this.state.contactNumber.value,
      email: this.state.email.value,
      notes: this.state.notes.value,
      homezoneStatus: this.state.homezoneEnabled,
    };
    this.props.onFormSubmitted(property);
  };

  userHasFilledForm = (): boolean => {
    const isFilled = (state: FieldState): boolean => {
      return state.value.trim().length > 0;
    };

    return (
      isFilled(this.state.contactFirstName) ||
      isFilled(this.state.contactLastName) ||
      isFilled(this.state.contactNumber) ||
      isFilled(this.state.email) ||
      isFilled(this.state.notes)
    );
  };

  onBackHandler = () => {
    let property: ContactFormState = {
      contactFirstName: this.state.contactFirstName.value,
      contactLastName: this.state.contactLastName.value,
      contactNumber: this.state.contactNumber.value,
      email: this.state.email.value,
      notes: this.state.notes.value,
      homezoneStatus: this.state.homezoneEnabled,
    };
    this.props.setContactProps(property);
  };

  onFieldChanged = (field: keyof AddPropertyContactsInterface, value: string) => {
    const newState = { ...this.state };
    newState[field] = { value: value };
    this.setState(newState);
  };
}

export default withTranslation()(AddPropertyContactForm);
