import { Button, withStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { PRIMARY_COLOR } from "../../../theme/createMaterialTheme";

interface Props extends RouteComponentProps {
  key: string;
  children: ReactNode;
  route: string;
}

const HeaderTabItem = (props: Props) => {
  let tabStyle: any = {
    paddingBottom: "1px",
    fontSize: 18,
    borderRadius: 0,
    whiteSpace: "nowrap",
  };

  const isActive = matchPath(props.location.pathname, props.route);

  if (isActive) {
    tabStyle = {
      ...tabStyle,
      color: PRIMARY_COLOR,
      borderBottom: "1px solid " + PRIMARY_COLOR,
    };
  } else {
    tabStyle = {
      ...tabStyle,
      color: "white",
      opacity: 0.8,
      "&:hover": {
        borderBottom: "1px solid white",
      },
    };
  }

  const Tab = withStyles({
    root: tabStyle as any,
  })(Button);

  const onClick = () => {
    props.history.replace(props.route);
  };

  return <Tab onClick={onClick}>{props.children}</Tab>;
};

export default withRouter(HeaderTabItem);
