import {
  faBatteryEmpty,
  faBatteryHalf,
  faGaugeSimple,
  faGaugeSimpleHigh,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Checkbox, Collapse, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { SenseProductType } from "../../../../../../rest/sense/model/SenseDevice";
import { SECONDARY_COLOR } from "../../../../../../ui/theme/createMaterialTheme";
import {
  SMALL_SPACING,
  LARGE_SPACING,
  DEFAULT_SPACING,
} from "../../../../../../ui/theme/dimensions";
import DeviceStatus from "../../../model/DeviceStatus";
import ProductStatusCheckbox from "./ProductStatusCheckbox";

interface ExternalProps {
  checkboxState: [SenseProductType, Set<DeviceStatus>] | undefined;
  deviceType: SenseProductType;
  onCheckChanged: (
    deviceFilters: [SenseProductType, Set<DeviceStatus>] | undefined
  ) => void;
}

type Props = ExternalProps & WithTranslation;

const ProductFilterBox = (props: Props) => {
  const handleHeaderCheckboxChange = (checked: boolean) => {
    let newSet: Set<DeviceStatus> = new Set<DeviceStatus>();

    if (!checked) {
      newSet = new Set<DeviceStatus>([]);
      props.onCheckChanged([props.deviceType, newSet]);
    } else {
      props.onCheckChanged(undefined);
    }
  };

  const handleCheckboxChange = (status: DeviceStatus, checked: boolean) => {
    let newStatuses = new Set(props.checkboxState?.[1]);

    if (checked) {
      newStatuses.add(status);
    } else {
      newStatuses.delete(status);
    }

    // Don't submit if nothing's changed.
    if (props.checkboxState?.[1] !== newStatuses) {
      props.onCheckChanged([props.deviceType, newStatuses]);
    }
  };

  return (
    <Box display="flex" flexDirection="column" marginY={SMALL_SPACING}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginRight={SMALL_SPACING}
        style={{ marginRight: SMALL_SPACING }}
      >
        <Box display="flex" alignItems="center">
          <Typography style={{ width: 110 }}>
            {props.t(`senseDeviceNames.${props.deviceType}`)}
          </Typography>
          <Box width={LARGE_SPACING + 14} />
        </Box>
        <Checkbox
          style={{ marginLeft: "11px" }}
          checked={props.checkboxState?.[0] === props.deviceType}
          onChange={(e, checked) => handleHeaderCheckboxChange(!checked)}
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-around">
        <Box
          display="flex"
          flexDirection="column"
          width={DEFAULT_SPACING}
          borderRadius={90}
          style={{
            backgroundColor: SECONDARY_COLOR,
          }}
        />
        <Box width={LARGE_SPACING} />
        <Box display="flex" flexDirection="column">
          <Collapse in={props.checkboxState?.[0] === props.deviceType}>
            <ProductStatusCheckbox
              startIcon={faBatteryHalf}
              status={"battery_low"}
              isChecked={
                props.checkboxState
                  ? (props.checkboxState[1] as Set<DeviceStatus>).has(
                      "battery_low" as DeviceStatus
                    )
                  : false
              }
              onCheckChanged={handleCheckboxChange}
            />
            <ProductStatusCheckbox
              startIcon={faBatteryEmpty}
              status={"battery_critical"}
              isChecked={
                props.checkboxState
                  ? (props.checkboxState[1] as Set<DeviceStatus>).has(
                      "battery_critical" as DeviceStatus
                    )
                  : false
              }
              onCheckChanged={handleCheckboxChange}
            />
            <ProductStatusCheckbox
              startIcon={faWifi}
              status={"no_contact_with_device"}
              isChecked={
                props.checkboxState
                  ? (props.checkboxState[1] as Set<DeviceStatus>).has(
                      "no_contact_with_device" as DeviceStatus
                    )
                  : false
              }
              onCheckChanged={handleCheckboxChange}
            />
            <ProductStatusCheckbox
              startIcon={faGaugeSimple}
              status={"magnetite_level_medium"}
              isChecked={
                props.checkboxState
                  ? (props.checkboxState[1] as Set<DeviceStatus>).has(
                      "magnetite_level_medium" as DeviceStatus
                    )
                  : false
              }
              onCheckChanged={handleCheckboxChange}
            />
            <ProductStatusCheckbox
              startIcon={faGaugeSimpleHigh}
              status={"magnetite_level_high"}
              isChecked={
                props.checkboxState
                  ? (props.checkboxState[1] as Set<DeviceStatus>).has(
                      "magnetite_level_high" as DeviceStatus
                    )
                  : false
              }
              onCheckChanged={handleCheckboxChange}
            />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(ProductFilterBox);
