import React, { useEffect, useState } from "react";
import SegmentationQuestion from "../../../../../../rest/user/model/SegmentationQuestion";
import { Grid } from "@material-ui/core";
import ArrowIcon from "../../../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../../../ui/components/PrimaryButton";
import { SMALL_SPACING } from "../../../../../../ui/theme/dimensions";
import SegmentationAnswer from "../../../../../../rest/user/model/SegmentationAnswer";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../../../ui/components/SecondaryButton";

interface IProps {
  categoryQuestion: SegmentationQuestion;
  onBackPressed: () => void;
  onActionPressed: (selectedAnswer: SegmentationAnswer) => void;
  actionLabel: string;
  showBackButton?: boolean;
  selectedAnswer?: SegmentationAnswer;
  loading?: boolean;
}

const SegmentationQuestionPanel = (props: IProps) => {
  //Destructure props
  const {
    categoryQuestion,
    loading,
    onBackPressed,
    onActionPressed,
    actionLabel,
    selectedAnswer,
    showBackButton,
  } = props;

  //Hooks
  const { t, i18n } = useTranslation();

  //State
  const [localSelectedAnswer, setLocalSelectedAnswer] = useState<
    SegmentationAnswer | undefined
  >(undefined);

  useEffect(() => {
    if (!selectedAnswer) {
      setLocalSelectedAnswer(undefined);
    } else {
      setLocalSelectedAnswer(selectedAnswer);
    }
  }, [categoryQuestion, selectedAnswer]);

  //Strings
  const backButtonLabel = t("buttons.back");

  //Handlers
  const handleActionButtonPressed = () => {
    if (localSelectedAnswer) {
      onActionPressed(localSelectedAnswer);
    }
  };

  const handleBackPressed = () => {
    setLocalSelectedAnswer(undefined);
    onBackPressed();
  };

  //Helpers
  const isSelected = (id: string) => {
    return localSelectedAnswer?.id === id;
  };

  const getAnswerLabel = (answer: SegmentationAnswer): string => {
    const localisationKey = `userSegmentation.answerDescription.${answer.key}`;
    return i18n.exists(localisationKey) ? t(localisationKey) : answer.title;
  };

  const getQuestionLabel = (question: SegmentationQuestion): string => {
    const localisationKey = `userSegmentation.questionTitle.${question.key}`;
    return i18n.exists(localisationKey) ? t(localisationKey) : question.title;
  };

  return (
    <CenteredAuthCard title={getQuestionLabel(categoryQuestion)} includeSignOut>
      <br />
      <br />
      <Grid container direction="column" spacing={SMALL_SPACING}>
        {categoryQuestion.options.map((option, index) => (
          <Grid item key={index}>
            <label>
              <input
                type="radio"
                checked={isSelected(option.id)}
                onChange={() => setLocalSelectedAnswer(option)}
              />
              {getAnswerLabel(option)}
            </label>
          </Grid>
        ))}
        <Grid item></Grid>

        <Grid item>
          <PrimaryButton
            fullWidth
            isLoading={loading}
            label={actionLabel}
            disabled={localSelectedAnswer === undefined || loading}
            endIcon={<ArrowIcon />}
            onClick={handleActionButtonPressed}
          />
        </Grid>
        {showBackButton && (
          <Grid item>
            <SecondaryButton
              fullWidth
              label={backButtonLabel}
              disabled={loading}
              onClick={handleBackPressed}
            />
          </Grid>
        )}
        <Grid item></Grid>
      </Grid>
    </CenteredAuthCard>
  );
};

export default SegmentationQuestionPanel;
