import { AxiosError } from "axios";
import { put, takeLatest } from "redux-saga/effects";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import MockAxiosError from "../../../../errors/test/MockAxiosError";
import getDependency from "../../../../redux/utils/getDependency";
import SystemMessageRepository from "../../../../rest/system/SystemMessageRepository";
import SystemMessage from "../model/SystemMessage";
import {
  InitGetSystemMessagesAction,
  INIT_GET_SYSTEM_MESSAGES,
  systemDownAction,
  systemMessagesErrorAction,
  systemMessagesSuccessAction,
} from "./SystemMessageAction";

function* systemMessageSagas() {
  yield takeLatest(INIT_GET_SYSTEM_MESSAGES, onGetSystemMessagesAction);
}

function* onGetSystemMessagesAction(action: InitGetSystemMessagesAction) {
  try {
    const messagesRepo: SystemMessageRepository = yield getDependency(
      "systemMessagesRepository"
    );
    const messages: SystemMessage[] = yield messagesRepo.getActiveSystemMessages();

    const sortedMessages = messages.sort((a, b) => {
      const firstEndDate = Date.parse(a.end_date);
      const secondEndDate = Date.parse(b.end_date);
      return firstEndDate - secondEndDate;
    });
    if (action.debug) {
      throw new MockAxiosError(503);
    }

    const localSystemMessages = localStorage.getItem("systemMessages");

    let filteredSortedMessages = [...sortedMessages];

    if (localSystemMessages) {
      let parsedLocalMessages = JSON.parse(localSystemMessages) as SystemMessage[];

      //1. Filtering to update local storage - remove any items with ids not present in the received list
      parsedLocalMessages = parsedLocalMessages.filter((localMessage) => {
        return sortedMessages.map((v) => v.id).indexOf(localMessage.id) > -1;
      });

      //2. Clean up storage
      localStorage.setItem("systemMessages", JSON.stringify(parsedLocalMessages));

      //3. Remove all messages present in the local storage before passing them to the redux
      filteredSortedMessages = filteredSortedMessages.filter((localMessage) => {
        return parsedLocalMessages.map((v) => v.id).indexOf(localMessage.id) === -1;
      });
    }

    yield put(systemMessagesSuccessAction(filteredSortedMessages));
  } catch (e) {
    if ((e as AxiosError).response?.status === 503) {
      yield put(systemDownAction());
    } else {
      const error = getErrorMessage(e, "systemMessagesDialog.loadError");
      yield put(systemMessagesErrorAction(error));
    }
  }
}

export default systemMessageSagas;
