import { Box, Dialog } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import InvitationForm, {
  InviteType,
} from "../../../rest/organisation/model/InvitationForm";
import { RoleName } from "../../../rest/roles/model/Role";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import { DEFAULT_SPACING, LARGE_SPACING } from "../../../ui/theme/dimensions";
import CountryCode from "../../../utils/CountryCode";
import InviteEntityPanel from "./components/InviteEntityPanel";
import InviteSentPanel from "./components/InviteSentPanel";
import InviteWarningPanel from "./components/InviteWarningPanel";
import { inviteEntity } from "./redux/CurrentInviteAction";
import isOrgDevFeatureEnabled from "../../../redux/utils/isOrgDevFeatureEnabled";

//MARK: Interfaces
interface ReduxDispatchProps {
  onFormSubmitted: (form: InvitationForm) => void;
}

interface ReduxStateProps {
  isLoading: boolean;
  receivedInvite: PendingInvite | undefined;
  canInviteDevelopers: boolean;
  error: string | undefined;
  orgCountry: CountryCode | undefined;
}

interface Props extends ReduxDispatchProps, ReduxStateProps, WithT {
  //Required params
  pageType: InviteType;
  open: boolean;
  onClose: () => void;
  //Optional params
  warningPopup?: boolean;
  forcedUserRole?: RoleName;
}

interface State {
  success: boolean;
  warningPopup: boolean;
}

class InviteDialog extends React.Component<Props, State> {
  state = {
    success: false,
    warningPopup: false,
  };

  componentDidMount() {
    if (this.props.warningPopup) {
      this.setState({
        warningPopup: true,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.receivedInvite !== undefined &&
      this.props.receivedInvite.id !== prevProps?.receivedInvite?.id
    ) {
      this.setState({
        success: true,
      });
    }

    if (this.props.open && this.props.open !== prevProps.open) {
      this.setState({
        warningPopup: this.props.warningPopup ? this.props.warningPopup : false,
        success: false,
      });
    }
  }

  render() {
    const content = this.state.warningPopup ? (
      <InviteWarningPanel
        type={this.props.pageType}
        onNextClicked={this.handleWarningNextClick}
        onCancelClicked={this.handleClose}
        country={this.props.orgCountry}
      />
    ) : this.state.success ? (
      <InviteSentPanel
        email={this.props.receivedInvite?.email}
        onContinuePressed={this.handleClose}
      />
    ) : (
      <InviteEntityPanel
        forcedUserRole={this.props.forcedUserRole}
        canInviteDevelopers={this.props.canInviteDevelopers}
        type={this.props.pageType}
        isLoading={this.props.isLoading}
        sendInvite={this.handleFormSubmittedClick}
      />
    );

    return (
      <Dialog open={this.props.open} onClose={this.handleClose} maxWidth="sm">
        <Box display="flex" flexDirection="row">
          <Box
            paddingX={LARGE_SPACING}
            paddingTop={DEFAULT_SPACING}
            paddingBottom={DEFAULT_SPACING}
          >
            {content}
          </Box>

          <ErrorSnackbarHandler message={this.props.error} />
        </Box>
      </Dialog>
    );
  }

  //MARK: Handlers

  handleFormSubmittedClick = (email: string, type: InviteType, role?: RoleName) => {
    let inviteForm: InvitationForm = {
      userEmail: email,
      type: type,
      role: role,
    };
    this.props.onFormSubmitted(inviteForm);
  };

  handleClose = () => {
    if (!this.props.isLoading) {
      this.props.onClose();
    }
  };

  handleWarningNextClick = () => {
    this.setState({ warningPopup: false });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  isLoading: state.inviteEntity.isLoading,
  receivedInvite: state.inviteEntity.receivedInvite,
  error: state.inviteEntity.error,
  canInviteDevelopers:
    state.adeyAdmin.hasAdminAccess ||
    state.adeyAgent.hasAgentAccess ||
    (state.activeOrganisation.currentOrganisation?.role.name === "owner" &&
      isOrgDevFeatureEnabled(
        state.activeOrganisation.currentOrganisation.features || []
      )),
  orgCountry:
    state.adeyAdmin.hasAdminAccess || state.adeyAgent.hasAgentAccess
      ? undefined
      : (state.activeOrganisation.currentOrganisation?.address_country
          .code as CountryCode),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onFormSubmitted: (form) => dispatch(inviteEntity(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InviteDialog));
