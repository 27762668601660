import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import Organisation from "../../../../rest/organisation/model/Organisation";

// Load organisations
export const LOAD_ORGANISATIONS_TYPE = "LOAD_ORGANISATIONS_TYPE";
export interface LoadOrganisationsAction {
  type: typeof LOAD_ORGANISATIONS_TYPE;
}
export const loadOrganisations = () => {
  return {
    type: LOAD_ORGANISATIONS_TYPE,
  };
};

// Load organisations success
export const LOAD_ORGANISATIONS_SUCCESS_TYPE = "LOAD_ORGANISATIONS_SUCCESS_TYPE";
export interface LoadOrganisationsSuccessAction {
  type: typeof LOAD_ORGANISATIONS_SUCCESS_TYPE;
  contractors: Organisation[];
  associatedOrgs: Organisation[];
  invites: PendingInvite[];
}
export const loadOrganisationsSuccess = (
  contractors: Organisation[],
  associatedOrgs: Organisation[],
  invites: PendingInvite[]
): LoadOrganisationsSuccessAction => {
  return {
    type: LOAD_ORGANISATIONS_SUCCESS_TYPE,
    contractors: contractors,
    associatedOrgs: associatedOrgs,
    invites: invites,
  };
};

// Load organisations error
export const LOAD_ORGANISATIONS_ERROR_TYPE = "LOAD_ORGANISATIONS_ERROR_TYPE";
export interface LoadOrganisationsErrorAction {
  type: typeof LOAD_ORGANISATIONS_ERROR_TYPE;
  error: string;
}
export const loadOrganisationsError = (error: string) => {
  return {
    type: LOAD_ORGANISATIONS_ERROR_TYPE,
    error: error,
  };
};

type OrganisationListAction =
  | LoadOrganisationsAction
  | LoadOrganisationsErrorAction
  | LoadOrganisationsSuccessAction;

export default OrganisationListAction;
