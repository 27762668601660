import { put, takeLatest } from "@redux-saga/core/effects";
import { select } from "redux-saga/effects";
import getDependency from "../../../redux/utils/getDependency";
import RootState from "../../../redux/RootState";
import InviteRepository from "../../../rest/invites/InviteRepository";
import Organisation from "../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../rest/organisation/OrganisationRepository";
import {
  ACTIVE_ORGANISATION_CHANGED_TYPE,
  organisationAdded,
} from "../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import {
  checkLoginStatus,
  loginStateChanged,
  LoginStateChangedAction,
  LOGIN_STATUS_CHANGED_TYPE,
} from "../../auth/Login/redux/LoginAction";
import PendingInviteService, { AllPendingInvites } from "../service/PendingInviteService";
import {
  AcceptInviteAction,
  acceptInviteError,
  acceptInviteSuccess,
  ACCEPT_INVITE_SUCCESS_TYPE,
  ACCEPT_INVITE_TYPE,
  clearAcceptedInviteFlag,
  fetchPendingInvitesSuccess,
  FETCH_PENDING_INVITES_TYPE,
  orgOwnerStateChanged,
  RejectInviteAction,
  rejectInviteError,
  rejectInviteSuccess,
  REJECT_INVITE_SUCCESS_TYPE,
  REJECT_INVITE_TYPE,
} from "./PendingInviteAction";
import getErrorMessage from "../../../errors/messages/getErrorMessage";
import inviteErrorHandler from "../../../rest/invites/errors/inviteErrorHandler";

function* pendingInviteSagas() {
  yield takeLatest(FETCH_PENDING_INVITES_TYPE, onFetchPendingInvites);
  yield takeLatest(ACCEPT_INVITE_TYPE, onAcceptInvite);
  yield takeLatest(REJECT_INVITE_TYPE, onRejectInvite);

  yield takeLatest(
    [ACCEPT_INVITE_SUCCESS_TYPE, REJECT_INVITE_SUCCESS_TYPE],
    onInviteAcceptedOrRejected
  );

  yield takeLatest(LOGIN_STATUS_CHANGED_TYPE, loginStatusChangedSaga);

  yield takeLatest(ACTIVE_ORGANISATION_CHANGED_TYPE, orgOwnerSaga);
}

function* onFetchPendingInvites() {
  try {
    const inviteService: PendingInviteService = yield getDependency(
      "pendingInviteService"
    );
    const invites: AllPendingInvites = yield inviteService.fetchAllPendingInvites();
    yield put(fetchPendingInvitesSuccess(invites.contractor, invites.user));
  } catch (e) {
    // If there's an error then don't stop the user from
    // continuing. We can try again later
    yield put(fetchPendingInvitesSuccess([], []));
  }
}

function* onAcceptInvite(action: AcceptInviteAction) {
  try {
    const inviteRepository: InviteRepository = yield getDependency("inviteRepository");
    const organisationRepository: OrganisationRepository = yield getDependency(
      "organisationRepository"
    );

    const organisationId = action.pendingInvite.organisation.id;

    yield inviteRepository.acceptInvite(
      organisationId,
      action.pendingInvite.code,
      action.pendingInvite.type
    );

    // Now we've accepted, add it to the switcher
    const org: Organisation = yield organisationRepository.getOrganisation(
      organisationId
    );
    // Add the org to the switcher
    yield put(organisationAdded(org));

    // Clear the pending invite
    yield put(acceptInviteSuccess(action.pendingInvite));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "acceptOrganisationInvitePanel.acceptError",
      inviteErrorHandler
    );
    yield put(acceptInviteError(message));
  }
}

function* onRejectInvite(action: RejectInviteAction) {
  try {
    const inviteRepo: InviteRepository = yield getDependency("inviteRepository");

    yield inviteRepo.rejectInvite(action.pendingInvite.code);

    yield put(rejectInviteSuccess(action.pendingInvite));
  } catch (e) {
    const message = getErrorMessage(e, "acceptOrganisationInvitePanel.rejectError");
    yield put(rejectInviteError(message));
  }
}

function* onInviteAcceptedOrRejected() {
  // after accepting an invite, check if it's the last one
  const rootState: RootState = yield select();

  const remainingInvites = rootState.pendingInvite.invites?.user?.length || 0;
  const isLoggedIn = rootState.login.status === "logged_in";

  // If we have accepted all of our invites, and we're in the auth flow.
  // check login status to complete the auth flow
  if (remainingInvites === 0 && !isLoggedIn) {
    yield put(loginStateChanged("unknown"));
    yield put(checkLoginStatus());
  }
}

function* loginStatusChangedSaga(action: LoginStateChangedAction) {
  if (action.loginStatus === "logged_in") {
    yield put(clearAcceptedInviteFlag());
  }
}

function* orgOwnerSaga() {
  const rootState: RootState = yield select();
  const allOrganisations = rootState.activeOrganisation.organisationList || [];

  const isOrgOwner = allOrganisations.find((o) => o.role.name === "owner") !== undefined;

  yield put(orgOwnerStateChanged(isOrgOwner));
}

export default pendingInviteSagas;
