import { takeEvery } from "@redux-saga/core/effects";
import { put, select } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import { activeOrganisationUpdated } from "../../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import {
  editCurrentOrganisationError,
  EditCurrentOrganisationSubmitAction,
  editCurrentOrganisationSuccess,
  EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE,
} from "./EditCurrentOrganisationAction";
import buildOrganisationFromCode from "../../../admin/AdminOrganisationDetailsPage/utils/buildOrganisationFromCode";
import RootState from "../../../../redux/RootState";

function* editCurrentOrganisationSagas() {
  yield takeEvery(EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE, submitEditSaga);
}

function* submitEditSaga(action: EditCurrentOrganisationSubmitAction) {
  try {
    const rootState: RootState = yield select();

    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");

    const newOrg = buildOrganisationFromCode(
      action.organisationDetails,
      rootState.countryList.countryList
    );

    const orgResponse: Organisation = yield orgRepo.updateOrganisation(newOrg);

    yield put(editCurrentOrganisationSuccess(orgResponse));
    yield put(activeOrganisationUpdated(orgResponse));
  } catch (e) {
    const message = getErrorMessage(e, "currentOrganisationPage.editError");
    yield put(editCurrentOrganisationError(message));
  }
}

export default editCurrentOrganisationSagas;
