import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../theme/dimensions";

const titleStyle = {
  paddingLeft: EXTRA_LARGE_SPACING,
  paddingRight: EXTRA_LARGE_SPACING,
  paddingTop: LARGE_SPACING,
  width: "100%",
};

const itemStyle = {
  paddingLeft: LARGE_SPACING,
  paddingRight: LARGE_SPACING,
  paddingTop: LARGE_SPACING,
  paddingBottom: LARGE_SPACING,
  width: "100%",
};

interface Props {
  // The text of the list item
  text: string;

  // A small subtitle below the main text
  subtext?: string;

  // The click handler (setting this will add a ripple)
  onClick?: () => void;

  // If this should be styled as a title
  isTitle?: boolean;

  // An icon to go on the right.
  icon?: JSX.Element;

  // If a loading icon should appear on the right
  loading?: boolean;

  // If the button is disabled
  disabled?: boolean;
}

const OrganisationSwitcherListItem = (props: Props) => {
  const typographyVariant = props.isTitle ? "button" : "body1";
  const typographyColor = props.isTitle ? "secondary" : undefined;
  const style = props.isTitle ? titleStyle : itemStyle;
  const centerMargin = props.icon ? LARGE_SPACING : undefined;

  const hasSubtext = props.subtext !== undefined;

  const text = (
    <Box style={style} display="flex" alignItems="center">
      {props.icon}

      <Box marginY={hasSubtext ? -0.5 : 0} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography align="left" variant={typographyVariant} color={typographyColor}>
            <strong style={{ marginLeft: centerMargin }}>{props.text}</strong>
          </Typography>
        </Box>

        {hasSubtext ? (
          <Typography
            style={{ marginTop: -4, marginLeft: centerMargin }}
            align="left"
            variant="button"
            color="secondary"
          >
            {props.subtext}
          </Typography>
        ) : undefined}
      </Box>

      {props.loading ? <Box flexGrow={1} /> : undefined}
      {props.loading ? <CircularProgress color="secondary" size={20} /> : undefined}
    </Box>
  );

  // If we have a click handler wrap the whole thing in a Buton
  // to add a ripple
  if (props.onClick) {
    return (
      <Button
        disabled={props.disabled}
        style={{ width: "100%", textTransform: "initial" }}
        onClick={props.onClick}
      >
        {text}
      </Button>
    );
  } else {
    return text;
  }
};

export default OrganisationSwitcherListItem;
