import { Box, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

type Props = WithTranslation;

const AccountActivationLoadingCard = (props: Props) => {
  const title = props.t("accountActivationPage.title");

  return (
    <CenteredAuthCard title={title}>
      <Grid container justify="center">
        <Box margin={DEFAULT_SPACING}>
          <CircularProgress />
        </Box>
      </Grid>
    </CenteredAuthCard>
  );
};

export default withTranslation()(AccountActivationLoadingCard);
