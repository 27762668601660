import { Box } from "@material-ui/core";
import Linkify from "react-linkify";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  BACKGROUND_ACCENT_COLOR,
  RAG_GREEN,
  RAG_AMBER,
  RAG_RED,
  BORDER_COLOR,
} from "../theme/createMaterialTheme";
import { LARGE_SPACING, SMALL_SPACING } from "../theme/dimensions";
import { Alert, Color } from "@material-ui/lab";
import ColoredText from "./ColoredText";

interface ExternalProps {
  magnetiteLevel: number;
}

type Props = ExternalProps & WithTranslation;

const MagnetiteBlocks = (props: Props) => {
  //Strings
  const magnetiteLabel = props.t("senseDeviceReadingsCard.magnetiteLevel");

  //Helpers
  const getColorForMagnetite = () => {
    let colour = BACKGROUND_ACCENT_COLOR;

    switch (props.magnetiteLevel) {
      case 1:
      case 2:
      case 3:
        colour = RAG_GREEN;
        break;
      case 4:
        colour = RAG_AMBER;
        break;
      case 5:
        colour = RAG_RED;
        break;
    }

    return colour;
  };

  const getMagnetiteAlertSeverity = (magnetiteLevel: number) => {
    let severity: Color = "info";

    switch (magnetiteLevel) {
      case 1:
      case 2:
      case 3:
        severity = "success";
        break;
      case 4:
        severity = "warning";
        break;
      case 5:
        severity = "error";
        break;
    }
    return severity;
  };

  const getInfoText = () => {
    let text = "";

    if (props.magnetiteLevel > 0 && props.magnetiteLevel <= 5) {
      text = props.t(`senseDeviceMagnetiteIndicator.${props.magnetiteLevel}`);
    } else {
      text = props.t("senseDeviceMagnetiteIndicator.noLevel");
    }

    return text;
  };

  const buildMagnetiteBlocks = () => {
    const blocks: JSX.Element[] = [];
    blocks.push(
      <Box
        maxWidth="100%"
        width="25%"
        mx="2px"
        borderRadius={30}
        bgcolor={getColorForMagnetite()}
      />
    );
    for (let i = 2; i < props.magnetiteLevel; ++i) {
      blocks.push(
        <Box
          maxWidth="100%"
          width="25%"
          mx="2px"
          borderRadius={30}
          bgcolor={getColorForMagnetite()}
        />
      );
    }
    return blocks;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
          <strong>{magnetiteLabel}</strong>
        </ColoredText>
        <Box
          display="flex"
          flexDirection="row"
          width="50%"
          borderRadius={30}
          padding="2px"
          height={LARGE_SPACING}
          border={"1px solid " + BORDER_COLOR}
          marginRight={SMALL_SPACING / 2}
        >
          {buildMagnetiteBlocks()}
        </Box>
      </Box>

      <Alert
        style={{ marginTop: LARGE_SPACING, marginBottom: SMALL_SPACING }}
        icon={false}
        severity={getMagnetiteAlertSeverity(props.magnetiteLevel)}
      >
        <Linkify>{getInfoText()}</Linkify>
      </Alert>
    </Box>
  );
};

export default withTranslation()(MagnetiteBlocks);
