class Env {
  static get awsRegion(): string {
    return this._getEnvironmentVariable("REACT_APP_AWS_REGION");
  }

  static get awsClientId(): string {
    return this._getEnvironmentVariable("REACT_APP_AWS_CLIENT_ID");
  }

  static get awsUserPoolId(): string {
    return this._getEnvironmentVariable("REACT_APP_AWS_USER_POOL_ID");
  }

  static get apiBaseUrl(): string {
    return this._getEnvironmentVariable("REACT_APP_API_BASE_URL");
  }

  static get proClubBaseUrl(): string {
    return this._getEnvironmentVariable("REACT_APP_PROCLUB_API_BASE_URL");
  }

  static get developerZoneBaseUrl(): string {
    return this._getEnvironmentVariable("REACT_APP_DEVELOPER_ZONE_URL");
  }

  static get loqateBaseUrl(): string {
    return this._getEnvironmentVariable("REACT_APP_LOQATE_URL");
  }

  static get maintenanceKey(): string {
    return this._getEnvironmentVariable("REACT_APP_API_MAINTENANCE_KEY");
  }

  static get googleMapsApiKey(): string {
    return this._getEnvironmentVariable("REACT_APP_GMAPS_API_KEY");
  }

  static get developerZoneEnabled(): boolean {
    return this._getEnvironmentVariable("REACT_APP_DEVELOPER_ZONE_ENABLED") === "1";
  }

  /// Gets an environment variable with a name and throws an
  /// error if it's null/undefined.
  private static _getEnvironmentVariable = (name: string): string => {
    const variable = process.env[name];
    if (!variable) {
      throw new Error(`No environment variable named '${name}'`);
    }
    return variable;
  };
}

export default Env;
