import Organisation from "../../../../rest/organisation/model/Organisation";
import { OrganisationRelationship } from "./OrganisationDetailState";

// Load organisation
export const LOAD_ORGANISATION_TYPE = "LOAD_ORGANISATION_TYPE";
export interface LoadOrganisationAction {
  type: typeof LOAD_ORGANISATION_TYPE;
  organisationId: string;
}
export const loadOrganisation = (organisationId: string): LoadOrganisationAction => ({
  type: LOAD_ORGANISATION_TYPE,
  organisationId: organisationId,
});

// Load organisation (Adey Admin)
export const LOAD_ORGANISATION_ADMIN_TYPE = "LOAD_ORGANISATION_ADMIN_TYPE";
export interface LoadOrganisationAdminAction {
  type: typeof LOAD_ORGANISATION_ADMIN_TYPE;
  organisationId: string;
}
export const adminLoadOrganisation = (
  organisationId: string
): LoadOrganisationAdminAction => ({
  type: LOAD_ORGANISATION_ADMIN_TYPE,
  organisationId: organisationId,
});

// Load organisation success
export const LOAD_ORGANISATION_SUCCESS_TYPE = "LOAD_ORGANISATION_SUCCESS_TYPE";
export interface LoadOrganisationSuccessAction {
  type: typeof LOAD_ORGANISATION_SUCCESS_TYPE;
  organisation: Organisation;
  relationship: OrganisationRelationship | undefined;
}
export const loadOrganisationSuccess = (
  organisation: Organisation,
  relationship: OrganisationRelationship | undefined
): LoadOrganisationSuccessAction => ({
  type: LOAD_ORGANISATION_SUCCESS_TYPE,
  organisation: organisation,
  relationship: relationship,
});

// Load organisation error
export const LOAD_ORGANISATION_ERROR_TYPE = "LOAD_ORGANISATION_ERROR_TYPE";
export interface LoadOrganisationErrorAction {
  type: typeof LOAD_ORGANISATION_ERROR_TYPE;
  error: string;
}
export const loadOrganisationError = (error: string): LoadOrganisationErrorAction => ({
  type: LOAD_ORGANISATION_ERROR_TYPE,
  error: error,
});

// Update organisation dispatch
export const EDIT_ORGANISATION_TYPE = "EDIT_ORGANISATION";
export interface EditOrganisationAction {
  type: typeof EDIT_ORGANISATION_TYPE;
  organisation: Organisation;
}

export const updateOrganisation = (organisation: Organisation) => ({
  type: EDIT_ORGANISATION_TYPE,
  organisation: organisation,
});

// Update organisation dispatch (admin)
export const ADMIN_EDIT_ORGANISATION_TYPE = "ADMIN_EDIT_ORGANISATION";
export interface AdminEditOrganisationAction {
  type: typeof ADMIN_EDIT_ORGANISATION_TYPE;
  organisation: Organisation;
}

export const adminUpdateOrganisation = (organisation: Organisation) => ({
  type: ADMIN_EDIT_ORGANISATION_TYPE,
  organisation: organisation,
});

// Update organisation success
export const EDIT_ORGANISATION_SUCCESS_TYPE = "EDIT_ORGANISATION_SUCCESS";
export interface EditOrganisationSuccessAction {
  type: typeof EDIT_ORGANISATION_SUCCESS_TYPE;
  organisation: Organisation;
}

export const updateOrganisationSuccess = (organisation: Organisation) => ({
  type: EDIT_ORGANISATION_SUCCESS_TYPE,
  organisation: organisation,
});

// Update organisation error
export const EDIT_ORGANISATION_ERROR_TYPE = "EDIT_ORGANISATION_ERROR";
export interface EditOrganisationErrorAction {
  type: typeof EDIT_ORGANISATION_ERROR_TYPE;
  error: string;
}

export const updateOrganisationError = (error: string) => ({
  type: EDIT_ORGANISATION_TYPE,
  error: error,
});

//Remove organisation dispatch
export const REMOVE_ORGANISATION_TYPE = "REMOVE_ORGANISATION_TYPE";
export interface RemoveOrganisationAction {
  type: typeof REMOVE_ORGANISATION_TYPE;
  contractor: Organisation;
  organisation: Organisation;
}
export const removeOrganisation = (
  contractor: Organisation,
  organisation: Organisation
) => ({
  type: REMOVE_ORGANISATION_TYPE,
  contractor: contractor,
  organisation: organisation,
});

//Remove organisation success
export const REMOVE_ORGANISATION_SUCCESS_TYPE = "REMOVE_ORGANISATION_SUCCESS_TYPE";
export interface RemoveOrganisationSuccessAction {
  type: typeof REMOVE_ORGANISATION_SUCCESS_TYPE;
  contractor: Organisation;
}
export const removeOrganisationSuccess = (contractor: Organisation) => ({
  type: REMOVE_ORGANISATION_SUCCESS_TYPE,
  contractor: contractor,
});

//Remove organisation error
export const REMOVE_ORGANISATION_ERROR_TYPE = "REMOVE_ORGANISATION_ERROR_TYPE";
export interface RemoveOrganisationErrorAction {
  type: typeof REMOVE_ORGANISATION_ERROR_TYPE;
  error: string;
}
export const removeOrganisationError = (error: string) => ({
  type: REMOVE_ORGANISATION_ERROR_TYPE,
  error: error,
});

type OrganisationDetailAction =
  | LoadOrganisationAction
  | LoadOrganisationErrorAction
  | LoadOrganisationSuccessAction
  | RemoveOrganisationAction
  | RemoveOrganisationErrorAction
  | RemoveOrganisationSuccessAction
  | LoadOrganisationAdminAction
  | AdminEditOrganisationAction
  | EditOrganisationSuccessAction
  | EditOrganisationErrorAction
  | EditOrganisationAction;
export default OrganisationDetailAction;
