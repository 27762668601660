import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import ConfirmRemoveContractorDialog from "./ConfirmRemoveContractorDialog";
import ContractorRemovedDialog from "./ContractorRemovedDialog";

interface Props extends WithT {
  parentOrganisationName: string | undefined;
  org: Organisation | undefined;
  loading: boolean;
  orgRemoved: boolean;

  removeContractorConfirmed: (org: Organisation) => void;
  returnToListClicked: () => void;
}

interface State {
  isConfirmOpen: boolean;
}

class RemoveContractorButton extends React.Component<Props, State> {
  state = {
    isConfirmOpen: false,
  };

  render() {
    const organisationName = this.props.parentOrganisationName;
    const org = this.props.org;
    if (!organisationName || !org) {
      return null;
    }

    const labelPlaceholder = this.props.t("removeContractorButton.labelPlaceholder");
    const label = labelPlaceholder.replace("%s", organisationName);

    return (
      <div>
        <PrimaryButton
          size="small"
          label={label}
          startIcon={<FontAwesomeIcon icon={faUserSlash} />}
          onClick={this.handleClicked}
          isLoading={this.props.loading}
        />

        <ConfirmRemoveContractorDialog
          organisationName={organisationName}
          org={org}
          open={this.state.isConfirmOpen}
          onCancelClicked={this.handleCancelClicked}
          onDeleteClicked={this.handleDeleteClicked}
        />

        <ContractorRemovedDialog
          open={this.props.orgRemoved}
          organisationName={organisationName}
          org={org}
          onOkClicked={this.props.returnToListClicked}
        />
      </div>
    );
  }

  handleClicked = () => {
    this.setState({ isConfirmOpen: true });
  };

  handleCancelClicked = () => {
    this.setState({ isConfirmOpen: false });
  };

  handleDeleteClicked = () => {
    if (this.props.org) {
      this.props.removeContractorConfirmed(this.props.org);
      this.handleCancelClicked();
    }
  };
}

export default withTranslation()(RemoveContractorButton);
