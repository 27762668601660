import PendingInvite from "../../../../rest/invites/model/PendingInvite";

// Resend invite
export const RESEND_INVITE_TYPE = "RESEND_INVITE_TYPE";
export interface ResendInviteAction {
  type: typeof RESEND_INVITE_TYPE;
  invite: PendingInvite;
}
export const resendInvite = (invite: PendingInvite): ResendInviteAction => ({
  type: RESEND_INVITE_TYPE,
  invite: invite,
});

// Resend invite success
export const RESEND_INVITE_SUCCESS_TYPE = "RESEND_INVITE_SUCCESS_TYPE";
export interface ResendInviteSuccessAction {
  type: typeof RESEND_INVITE_SUCCESS_TYPE;
  oldInvite: PendingInvite;
  newInvite: PendingInvite;
}
export const resendInviteSuccess = (
  oldInvite: PendingInvite,
  newInvite: PendingInvite
): ResendInviteSuccessAction => ({
  type: RESEND_INVITE_SUCCESS_TYPE,
  oldInvite: oldInvite,
  newInvite: newInvite,
});

// Resend invite error
export const RESEND_INVITE_ERROR_TYPE = "RESEND_INVITE_ERROR_TYPE";
export interface ResendInviteErrorAction {
  type: typeof RESEND_INVITE_ERROR_TYPE;
  error: string;
}
export const resendInviteError = (error: string): ResendInviteErrorAction => ({
  type: RESEND_INVITE_ERROR_TYPE,
  error: error,
});

// Revoke invite
export const REVOKE_INVITE_TYPE = "REVOKE_INVITE_TYPE";
export interface RevokeInviteAction {
  type: typeof REVOKE_INVITE_TYPE;
  invite: PendingInvite;
}
export const revokeInvite = (invite: PendingInvite): RevokeInviteAction => ({
  type: REVOKE_INVITE_TYPE,
  invite: invite,
});

// Revoke invite success
export const REVOKE_INVITE_SUCCESS_TYPE = "REVOKE_INVITE_SUCCESS_TYPE";
export interface RevokeInviteSuccessAction {
  type: typeof REVOKE_INVITE_SUCCESS_TYPE;
  invite: PendingInvite;
}
export const revokeInviteSuccess = (
  invite: PendingInvite
): RevokeInviteSuccessAction => ({
  type: REVOKE_INVITE_SUCCESS_TYPE,
  invite: invite,
});

// Revoke invite error
export const REVOKE_INVITE_ERROR_TYPE = "REVOKE_INVITE_ERROR_TYPE";
export interface RevokeInviteErrorAction {
  type: typeof REVOKE_INVITE_ERROR_TYPE;
  error: string;
}
export const revokeInviteError = (error: string): RevokeInviteErrorAction => ({
  type: REVOKE_INVITE_ERROR_TYPE,
  error: error,
});

// Reset menu
export const RESET_MENU_TYPE = "RESET_MENU_TYPE";
export interface ResetMenuAction {
  type: typeof RESET_MENU_TYPE;
}
export const resetMenu = (): ResetMenuAction => ({
  type: RESET_MENU_TYPE,
});

type PendingInviteMenuAction =
  | ResendInviteAction
  | ResendInviteSuccessAction
  | ResendInviteErrorAction
  | RevokeInviteAction
  | RevokeInviteSuccessAction
  | RevokeInviteErrorAction
  | ResetMenuAction;

export default PendingInviteMenuAction;
