import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../../redux/RootState";
import { useEffect, useState } from "react";
import {
  adminInitDestinationSearch,
  adminResetMoveState,
  initAdminMoveEntities,
} from "../../../../admin/AdminMoveEntity/redux/AdminMoveEntityAction";
import TSelectedMoveTab from "../../../../admin/AdminMoveEntity/types/TMoveTab";
import { buildPropertyDetailRoute } from "../../../../../routes/routes";
import { useHistory } from "react-router";
import { loadProperty } from "../../../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import { loadSenseDevices } from "../../../redux/SenseDevicesAction";
import { adminLoadOrganisation } from "../../../../organisations/details/redux/OrganisationDetailActions";
import {
  changePageType,
  resetList,
} from "../../../../properties/PropertyListPage/redux/PropertyListActions";

const tabList: TSelectedMoveTab[] = [
  "destination",
  "confirmation",
  "warning",
  "success",
  "failure",
];

const useMoveSenseDevice = () => {
  //Dispatch
  const dispatch = useDispatch();

  //History
  const history = useHistory();

  //Selector
  const senseDevice = useSelector(
    (state: RootState) => state.senseDevices.selectedDevice
  );
  const destinations = useSelector((state: RootState) =>
    state.adminMoveEntity.destinations.filter(
      (dest) => dest.id !== senseDevice?.device?.property.id
    )
  );

  const loading = useSelector(
    (state: RootState) =>
      state.adminMoveEntity.searchingDestination || state.adminMoveEntity.movingEntity
  );

  const moveResults = useSelector(
    (state: RootState) => state.adminMoveEntity.moveResults
  );

  const moved = moveResults && moveResults.movedEntities.length > 0;

  //State
  const [moveDeviceOpen, setMoveDeviceOpen] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>();
  const [curretTab, setCurrentTab] = useState(0);
  const [redirectOrg, setRedirectOrg] = useState<string>();

  useEffect(() => {
    if (moveResults && selectedDestination && redirectOrg) {
      if (moved) {
        setCurrentTab(3);
        window.history.pushState(
          {},
          "",
          buildPropertyDetailRoute(redirectOrg, selectedDestination)
        );
      } else {
        setCurrentTab(4);
      }
    }
  }, [moved, selectedDestination, redirectOrg, moveResults]);

  //Handlers
  const handleMoveDevice = () => {
    if (selectedDestination && senseDevice?.device?.id) {
      setRedirectOrg(senseDevice.device.organisation.id);
      dispatch(
        initAdminMoveEntities(
          "device",
          [senseDevice.device.id],
          selectedDestination,
          senseDevice.device.organisation.id,
          senseDevice.device.property.id
        )
      );
    }
  };

  const handleSearchPressed = () => {
    setSelectedDestination(undefined);
    dispatch(
      adminInitDestinationSearch(
        searchString.trim().toLowerCase(),
        "device",
        senseDevice?.device?.organisation.id
      )
    );
  };

  const handleOpenMoveDialog = () => {
    if (activeTab === "success") {
      return;
    }
    if (!moveDeviceOpen) {
      setSelectedDestination(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
    setMoveDeviceOpen(!moveDeviceOpen);
  };

  const handleSetSearchString = (search: string) => {
    setSearchString(search);
  };

  const handleSelectedDestination = (v: string) => {
    if (v === selectedDestination) {
      setSelectedDestination(undefined);
    } else {
      setSelectedDestination(v);
    }
  };

  const handleBackPressed = () => {
    setCurrentTab(curretTab - 1);
  };

  const handleNextPressed = () => {
    setCurrentTab(curretTab + 1);
  };

  const handleSuccessPressed = () => {
    setMoveDeviceOpen(false);
    if (redirectOrg && selectedDestination) {
      dispatch(changePageType("admin-org-detail"));
      dispatch(adminLoadOrganisation(redirectOrg));
      dispatch(resetList());
      dispatch(loadProperty(redirectOrg, selectedDestination));
      dispatch(loadSenseDevices(redirectOrg, selectedDestination));
      history.replace(buildPropertyDetailRoute(redirectOrg, selectedDestination));
      setSelectedDestination(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
  };

  const activeTab = tabList[curretTab];

  return {
    moveDeviceOpen,
    searchString,
    destinations,
    selectedDestination,
    activeTab,
    moveResults,
    loading,
    senseDevice,
    handleBackPressed,
    handleNextPressed,
    handleOpenMoveDialog,
    handleMoveDevice,
    handleSelectedDestination,
    handleSetSearchString,
    handleSearchPressed,
    handleSuccessPressed,
  };
};

export default useMoveSenseDevice;
