import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import Property from "../../../../../rest/properties/model/Property";
import { DASHBOARD_ROUTE } from "../../../../../routes/routes";
import { deleteProperty } from "../../redux/PropertyDetailActions";
import ConfirmDeletePropertyDialog from "./components/ConfirmDeletePropertyDialog";
import DeletePropertyButton from "./components/DeletePropertyButton";
import PropertyDeletedDialog from "./components/PropertyDeletedDialog";

interface ReduxStateProps {
  hasPermission: boolean;
  deletion: "loading" | "deleted" | undefined;
}

interface ReduxDispatchProps {
  deleteProperty: (property: Property, organisationId: string) => void;
}

interface ExternalProps {
  property: Property;
  organisationId: string;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps & RouteComponentProps;

interface State {
  confirmDialogOpen: boolean;
  deletionDialogOpen: boolean;
}

class DeletePropertyHandler extends React.Component<Props, State> {
  state = {
    confirmDialogOpen: false,
    deletionDialogOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.deletion === "deleted" && this.props.deletion !== prevProps.deletion) {
      this.setState({ deletionDialogOpen: true });
    }
  }

  render() {
    // If we don't have permission - don't show anything.
    if (!this.props.hasPermission) {
      return null;
    }

    return (
      <div>
        <DeletePropertyButton
          loading={this.props.deletion === "loading"}
          onClick={this.deleteButtonClicked}
        />

        <PropertyDeletedDialog
          open={this.state.deletionDialogOpen}
          onClose={this.navigateToDashboard}
        />

        <ConfirmDeletePropertyDialog
          property={this.props.property}
          open={this.state.confirmDialogOpen}
          onClose={this.handleConfirmDialogClosed}
          onDeleteConfirmed={this.deletePropertyConfirmed}
        />
      </div>
    );
  }

  deleteButtonClicked = () => {
    // If there are no products at a property, delete straight away.
    // but if there are, show a popup.
    // https://adeyinnovation.atlassian.net/browse/SPD-234
    if (this.props.property.metrics?.total_count === 0) {
      this.deletePropertyConfirmed();
    } else {
      this.setState({ confirmDialogOpen: true });
    }
  };

  handleConfirmDialogClosed = () => {
    this.setState({ confirmDialogOpen: false });
  };

  deletePropertyConfirmed = () => {
    this.handleConfirmDialogClosed();

    this.props.deleteProperty(this.props.property, this.props.organisationId);
  };

  navigateToDashboard = () => {
    this.props.history.replace(DASHBOARD_ROUTE);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  hasPermission: state.activeOrganisation.permissions.canDeleteProperties,
  deletion: state.propertyDetail.deletion,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  deleteProperty: (property, organisationId) =>
    dispatch(deleteProperty(property, organisationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeletePropertyHandler));
