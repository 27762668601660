import { AxiosInstance, AxiosRequestConfig } from "axios";
import CreateOrganisationForm from "../../pages/auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import MemberOrganisation from "./model/MemberOrganisation";
import Organisation from "./model/Organisation";
import OrganisationRepository from "./OrganisationRepository";

class RestOrganisationRepository implements OrganisationRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  registerOrganisation = (form: CreateOrganisationForm): Promise<Organisation> => {
    const params = new URLSearchParams();
    params.append("name", form.organisationName);
    params.append("phone_number", form.organisationNumber);
    params.append("vat_number", form.vatNumber || "");
    params.append("address_line_1", form.address.line1);
    params.append("address_line_2", form.address.line2 || "");
    params.append("address_line_3", form.address.line3 || "");
    params.append("address_city", form.address.city || "");
    params.append("address_postcode", form.address.postcode || "");
    params.append("address_country", form.address.country || "");

    return this.axios
      .post<DataWrapper<Organisation>>("/auth/register/organisation", params)
      .then(unwrapResponse);
  };

  getOrganisation = (id: string): Promise<Organisation> => {
    const endpoint = `/organisations/${id}`;
    return this.axios.get<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };

  setActiveOrganisation = (organisation: Organisation): Promise<void> => {
    const params = new URLSearchParams();
    params.append("organisation_id", organisation.id);

    return this.axios
      .put<DataWrapper<void>>("/me/switch-organisation", params)
      .then(unwrapResponse);
  };

  getActiveOrganisation = (): Promise<Organisation> => {
    return this.axios
      .get<DataWrapper<Organisation>>("/me/active-organisation")
      .then(unwrapResponse);
  };

  fetchOrganisationList = (): Promise<MemberOrganisation[]> => {
    const config: AxiosRequestConfig = {
      params: {
        withMetrics: 1,
      },
    };

    return this.axios
      .get<DataWrapper<MemberOrganisation[]>>("/me/organisations", config)
      .then(unwrapResponse);
  };

  updateOrganisation = (organisation: Organisation): Promise<Organisation> => {
    const url = `/organisations/${organisation.id}`;

    const params = new URLSearchParams();
    params.append("name", organisation.name);
    params.append("phone_number", organisation.phone_number);
    params.append("vat_number", organisation.vat_number || "");
    params.append("address_line_1", organisation.address_line_1);
    params.append("address_line_2", organisation.address_line_2 || "");
    params.append("address_line_3", organisation.address_line_3 || "");
    params.append("address_city", organisation.address_city);
    params.append("address_postcode", organisation.address_postcode);
    params.append("address_country", organisation.address_country.id);

    return this.axios.put<DataWrapper<Organisation>>(url, params).then(unwrapResponse);
  };
}

export default RestOrganisationRepository;
