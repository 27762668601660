import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { LARGE_SPACING } from "../theme/dimensions";

interface Props {
  title: string;
  message?: string;
}

const ErrorDisplay = (props: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={LARGE_SPACING}
    >
      <Typography variant="h2" align="center">
        <Trans>{props.title}</Trans>
      </Typography>
      <Typography variant="body1" align="center">
        <Trans>{props.message}</Trans>
      </Typography>
    </Box>
  );
};

export default ErrorDisplay;
