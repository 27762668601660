import { Box, FormControlLabel, Switch } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import UserDetails from "../../../../rest/user/model/UserDetails";
import ColoredText from "../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";
import EditableField from "../../../properties/PropertyDetailPage/components/EditableField/EditableField";

interface Props extends WithT {
  userDetails: UserDetails;
  loading: boolean;
  error: string | undefined;

  onDetailsUpdated: (user: UserDetails) => void;
}

type FieldName =
  | "first_name"
  | "last_name"
  | "email"
  | "phone_number"
  | "address_postcode"
  | "is_tester";

interface State {
  editingField: FieldName | undefined;
}

class EditableUserDetailsPanel extends React.Component<Props, State> {
  state = {
    editingField: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (!this.props.loading && prevProps.loading && this.props.error === undefined) {
      this.setState({ editingField: undefined });
    }
  }

  render() {
    const firstNameLabel = this.props.t("platformUserDetail.firstNameLabel");
    const surnameLabel = this.props.t("platformUserDetail.surnameLabel");
    const emailLabel = this.props.t("platformUserDetail.emailLabel");
    const numberLabel = this.props.t("platformUserDetail.numberLabel");
    const postcodeLabel = this.props.t("userDetailsPanel.postcodeHeader");
    const testerLabel = this.props.t("userDetailsPanel.testerHeader");

    return (
      <Box>
        {this.renderField(firstNameLabel, "first_name")}
        {this.renderField(surnameLabel, "last_name")}
        {this.renderField(emailLabel, "email")}
        {this.renderField(numberLabel, "phone_number")}
        {this.renderField(postcodeLabel, "address_postcode")}
        {this.renderField(testerLabel, "is_tester")}
      </Box>
    );
  }

  renderField = (label: string, fieldName: FieldName) => {
    // Don't allow editing a field when another is already being edited.
    // Also don't allow email
    const hoverEnabled = this.state.editingField === undefined && fieldName !== "email";

    if (fieldName === "is_tester") {
      const testerHint = this.props.t("userDetailsPanel.testerHint");

      return (
        <Box display="flex" flexDirection="column">
          <ColoredText
            textColor={BACKGROUND_ACCENT_COLOR}
            variant="button"
            style={{ marginLeft: "8px" }}
          >
            <strong>{label}</strong>
          </ColoredText>

          <FormControlLabel
            style={{ marginLeft: DEFAULT_SPACING, marginTop: DEFAULT_SPACING }}
            control={
              <Switch
                onChange={(e, checked) => {
                  this.handleSaveClicked(!this.props.userDetails.is_tester, fieldName);
                }}
                color="primary"
              />
            }
            label={testerHint}
            checked={this.props.userDetails.is_tester}
            color="primary"
          />
        </Box>
      );
    }

    return (
      <EditableField
        label={label}
        text={this.props.userDetails[fieldName]}
        hoverEnabled={hoverEnabled}
        isEditing={this.state.editingField === fieldName}
        onEditClicked={() => this.setState({ editingField: fieldName })}
        onCancelClicked={() => this.setState({ editingField: undefined })}
        onSaveClicked={(value) => this.handleSaveClicked(value, fieldName)}
        loading={this.props.loading}
        required
        gutterBottom
      />
    );
  };

  // Handlers

  handleSaveClicked = (value: string | number | boolean, field: FieldName) => {
    const newUserDetails = { ...this.props.userDetails, [field]: value };
    this.props.onDetailsUpdated(newUserDetails);
  };
}

export default withTranslation()(EditableUserDetailsPanel);
