import Organisation from "../../../../rest/organisation/model/Organisation";

// Load platform organisations
export const LOAD_PLATFORM_ORGANISATIONS_TYPE = "LOAD_PLATFORM_ORGANISATIONS_TYPE";
export interface LoadPlatformOrganisationsAction {
  type: typeof LOAD_PLATFORM_ORGANISATIONS_TYPE;
  page: number;
}
export const loadPlatformOrganisations = (
  page: number
): LoadPlatformOrganisationsAction => ({
  type: LOAD_PLATFORM_ORGANISATIONS_TYPE,
  page: page,
});

// Platform organisations loaded
export const PLATFORM_ORGANISATIONS_LOADED_TYPE = "PLATFORM_ORGANISATIONS_LOADED_TYPE";
export interface PlatformOrganisationsLoadedAction {
  type: typeof PLATFORM_ORGANISATIONS_LOADED_TYPE;
  organisations: Organisation[];
  page: number;
  maxPages: number;
}
export const platformOrganisationsLoaded = (
  organisations: Organisation[],
  page: number,
  maxPages: number
): PlatformOrganisationsLoadedAction => ({
  type: PLATFORM_ORGANISATIONS_LOADED_TYPE,
  organisations: organisations,
  page: page,
  maxPages: maxPages,
});

// Update an organisation within the list
export const PLATFORM_ORGANISATIONS_UPDATE_ENTRY_TYPE =
  "PLATFORM_ORGANISATIONS_UPDATE_ENTRY";
export interface PlatformOrganisationUpdateEntityAction {
  type: typeof PLATFORM_ORGANISATIONS_UPDATE_ENTRY_TYPE;
  organisation: Organisation;
}

export const updateOrgListEntry = (
  organisation: Organisation
): PlatformOrganisationUpdateEntityAction => ({
  type: PLATFORM_ORGANISATIONS_UPDATE_ENTRY_TYPE,
  organisation: organisation,
});

// Platform users error
export const PLATFORM_ORGANISATIONS_ERROR_TYPE = "PLATFORM_ORGANISATIONS_ERROR_TYPE";
export interface PlatformOrganisationsErrorAction {
  type: typeof PLATFORM_ORGANISATIONS_ERROR_TYPE;
  error: string;
}
export const platformOrganisationsError = (
  error: string
): PlatformOrganisationsErrorAction => ({
  type: PLATFORM_ORGANISATIONS_ERROR_TYPE,
  error: error,
});

// Search text changed
export const ORG_SEARCH_TEXT_CHANGED_TYPE = "ORG_SEARCH_TEXT_CHANGED_TYPE";
export interface SearchTextChangedAction {
  type: typeof ORG_SEARCH_TEXT_CHANGED_TYPE;
  text: string;
}
export const searchTextChanged = (text: string): SearchTextChangedAction => ({
  type: ORG_SEARCH_TEXT_CHANGED_TYPE,
  text: text,
});

type PlatformOrganisationListAction =
  | LoadPlatformOrganisationsAction
  | PlatformOrganisationsLoadedAction
  | PlatformOrganisationsErrorAction
  | SearchTextChangedAction
  | PlatformOrganisationUpdateEntityAction;

export default PlatformOrganisationListAction;
