import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import Spinner from "../../../ui/components/Spinner";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";
import useWindowSize from "../../../ui/hooks/useWindowSize";

interface ExternalProps {
  filePath: any;
  fullscreen?: boolean;
}

type Props = ExternalProps & WithT;

const DocumentPage = (props: Props) => {
  //Translated strings
  const error = props.t("error.status404");

  //Component State
  const [numPages, setNumPages] = useState<number>();

  //Custom Window Size
  const windowSize = useWindowSize();

  //Helpers
  const onDocumentsLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Box
      marginTop={DEFAULT_SPACING}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="white"
      height="100%"
      width="100%"
      overflow="hidden"
      justifyItems="center"
    >
      {/*Importing 'pdf.worker.js' - without it, it is impossible to launch PDF renderer on a non-blocking thread on production builds */}
      <Document
        file={`${window.location.origin}${props.filePath}`}
        onLoadSuccess={onDocumentsLoadSuccess}
        loading={
          <div>
            <Spinner />
          </div>
        }
        error={<ErrorDisplay title={error} />}
        onSourceError={(error) => console.log(error)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={windowSize.width ? windowSize.width - 5 : 200}
            scale={props.fullscreen ? 1 : 0.6}
            loading={""}
          />
        ))}
      </Document>
    </Box>
  );
};

export default withTranslation()(DocumentPage);
