import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Organisation from "../../../rest/organisation/model/Organisation";
import UserDetails from "../../../rest/user/model/UserDetails";
import {
  buildPlatformAgentDetailRoute,
  buildPlatformOrgDetailsRoute,
  PLATFORM_USER_LIST_ROUTE,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import Spinner from "../../../ui/components/Spinner";
import LoadedUserDetailsPage from "./components/LoadedUserDetailsPage";
import {
  loadPlatformUser,
  removeUserFromOrg,
  updateUserDetails,
} from "./redux/PlatformUserDetailAction";
import PlatformUserDetailState from "./redux/PlatformUserDetailState";

interface URLParams {
  userId: string;
}

type ReduxStateProps = PlatformUserDetailState;

interface ReduxDispatchProps {
  loadUser: (userId: string) => void;
  updateDetails: (userDetails: UserDetails) => void;
  removeUserFromOrg: (user: UserDetails, org: Organisation) => void;
}

type Props = RouteComponentProps<URLParams> & ReduxStateProps & ReduxDispatchProps;

class PlatformUserDetailPage extends React.Component<Props> {
  componentDidMount() {
    const userId = this.props.match.params.userId;
    if (this.props.userDetails?.id !== userId && !this.props.loading) {
      this.props.loadUser(userId);
    }
  }

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  renderBody() {
    if (this.props.userDetails) {
      return (
        <LoadedUserDetailsPage
          loading={this.props.loading}
          userDetails={this.props.userDetails}
          organisations={this.props.userOrganisations || []}
          error={this.props.error}
          userRemovedFromOrg={this.props.removedUserFromOrg}
          onUserListClicked={this.onUserListClicked}
          onDetailsUpdated={this.props.updateDetails}
          onOrganisationSelected={this.handleOrgSelected}
          onUserRemovedFromOrganisation={this.handleUserRemovedFromOrg}
        />
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  }

  // Handlers

  onUserListClicked = () => {
    this.props.history.replace(PLATFORM_USER_LIST_ROUTE);
  };

  handleOrgSelected = (org: Organisation) => {
    if (org.is_agent_for?.id) {
      const route = buildPlatformAgentDetailRoute(org.id);
      this.props.history.push(route);
    } else {
      const route = buildPlatformOrgDetailsRoute(org.id);
      this.props.history.push(route);
    }
  };

  handleUserRemovedFromOrg = (user: UserDetails, org: Organisation) => {
    this.props.removeUserFromOrg(user, org);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => state.platformUserDetail;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadUser: (id) => dispatch(loadPlatformUser(id)),
  updateDetails: (user) => dispatch(updateUserDetails(user)),
  removeUserFromOrg: (user, org) => dispatch(removeUserFromOrg(user, org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlatformUserDetailPage));
