import { Chip, TextField } from "@material-ui/core";
import Autocomplete, { AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";

export interface TagInputFieldProps {
  //Values
  tagValues: string[];
  inputValue: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  errorText?: string;
  options?: string[];
  //Handlers
  handleInputChanged: (v: string) => void;
  handleTagChange: (
    event: React.ChangeEvent<{}>,
    v: (string | string[])[],
    reason: AutocompleteChangeReason
  ) => void;
}

export class TagInputField extends Component<TagInputFieldProps> {
  render() {
    const hasError = this.props.errorText !== undefined && this.props.errorText !== "";
    const label = this.props.label;

    return (
      <Autocomplete
        inputValue={this.props.inputValue}
        value={this.props.tagValues}
        multiple
        freeSolo
        id="tags-filled"
        // @ts-ignore
        options={this.props.options || []}
        autoComplete
        renderTags={(value: string[][], getTagProps) => {
          return this.props.tagValues.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ));
        }}
        onInputChange={(event, newInputValue) => {
          this.props.handleInputChanged(newInputValue);
        }}
        onChange={this.props.handleTagChange}
        renderInput={(params) => (
          <TextField
            style={{ whiteSpace: "pre-line" }}
            {...params}
            color="secondary"
            variant="outlined"
            label={label}
            placeholder={this.props.placeholder}
            helperText={this.props.helperText}
            error={hasError}
          />
        )}
      />
    );
  }
}

export default TagInputField;
