import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../redux/RootState";
import Organisation from "../../../../rest/organisation/model/Organisation";
import BorderedBox from "../../../../ui/components/BorderedBox";
import EditableField from "../../../properties/PropertyDetailPage/components/EditableField/EditableField";
import { assignAgent } from "../redux/AdminOrganisationDetailsAction";

interface ExternalProps {
  organisation: Organisation;
}

interface ReduxStateProps {
  currentAgent: { id: string; name: string } | undefined;
  canEditAgents: boolean;
  availableAgents: Organisation[] | undefined;
  isLoading: boolean;
  error: string | undefined;
}

interface ReduxDispatchProps {
  updateAgent: (id: string | undefined) => void;
}

type Props = ReduxStateProps & WithT & ReduxDispatchProps & ExternalProps;

interface State {
  isEditing: boolean;
}

class OrganisationAgentPanel extends React.Component<Props, State> {
  state = { isEditing: false };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading && this.props.error === undefined) {
      this.setState({ isEditing: false });
    }
  }

  render() {
    const label = this.props.t("organisationAgentPanel.title");
    const noAgent = this.props.t("organisationAgentPanel.noAgent");

    const text = this.state.isEditing
      ? this.props.currentAgent?.id || noAgent
      : this.props.currentAgent?.name || noAgent;

    const handleSaved = (value: string) => {
      if (value === noAgent) {
        this.props.updateAgent(undefined);
      } else {
        this.props.updateAgent(value);
      }
    };

    return (
      <BorderedBox>
        <EditableField
          label={label}
          text={text}
          isEditing={this.state.isEditing}
          hoverEnabled={this.props.canEditAgents}
          onEditClicked={() => this.setState({ isEditing: true })}
          onCancelClicked={() => this.setState({ isEditing: false })}
          onSaveClicked={handleSaved}
          loading={this.props.isLoading}
          editorFieldProps={{
            selectOptions: this.createOptions(noAgent),
          }}
        />
      </BorderedBox>
    );
  }

  createOptions = (noAgentLabel: string) => {
    return [
      // Add a label for no agent
      { labelId: noAgentLabel, value: noAgentLabel },

      // Then map all the available agents to their own options.
      // If the list is undefined, then the page is viewed as a non-Adey Admin user

      ...(this.props.availableAgents !== undefined
        ? this.props.availableAgents.map((org) => ({
            labelId: org.name,
            value: org.id,
          }))
        : []),
    ];
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  currentAgent: state.orgDetail.organisation?.agent,
  canEditAgents: state.adeyAdmin.hasAdminAccess,
  availableAgents: state.platformAgentList.agentOrganisations,
  isLoading: state.adminOrganisationDetails.assigningAgent,
  error: state.adminOrganisationDetails.error,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: ExternalProps
): ReduxDispatchProps => ({
  updateAgent: (id) => dispatch(assignAgent(props.organisation, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OrganisationAgentPanel));
