import { Button } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import RemoveManagedOrganisationDialog from "./RemoveManagedOrganisationDialog";

interface Props extends WithT {
  organisation: Organisation | undefined;
  agentOrg: Organisation | undefined;
  removeManagedOrgConfirmed: (organisation: Organisation) => void;
}

interface State {
  isConfirmOpen: boolean;
}

class RemoveManagedOrganisationButton extends React.Component<Props, State> {
  state = {
    isConfirmOpen: false,
  };

  render() {
    const agentOrgName = this.props.agentOrg?.name || "";
    const org = this.props.organisation;
    const agentOrg = this.props.agentOrg;
    const removeButtonLabel = this.props.t("buttons.remove");
    if (!agentOrgName || !org || !agentOrg) {
      return null;
    }

    return (
      <div>
        <Button
          style={{ width: 80, height: 40 }}
          variant="contained"
          color="primary"
          onClick={this.handleClicked}
        >
          {removeButtonLabel}
        </Button>

        <RemoveManagedOrganisationDialog
          open={this.state.isConfirmOpen}
          agentName={agentOrg.name}
          organisationName={org.name}
          onRemoveClicked={this.handleRemoveClicked}
          onCancelClicked={this.handleCancelClicked}
        />
      </div>
    );
  }

  handleClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ isConfirmOpen: true });
    e.stopPropagation();
  };

  handleCancelClicked = () => {
    this.setState({ isConfirmOpen: false });
  };

  handleRemoveClicked = () => {
    if (this.props.organisation) {
      this.props.removeManagedOrgConfirmed(this.props.organisation);
      this.handleCancelClicked();
    }
  };
}

export default withTranslation()(RemoveManagedOrganisationButton);
