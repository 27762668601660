import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import NavigationRail from "../../../../ui/components/NavigationRail";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";
import getFullName from "../../../../utils/getFullName";

interface Props extends WithT {
  user: OrganisationUser;
  endComponent?: React.ReactElement;

  onNavLinkClicked: () => void;
}

const UserDetailPageNavRail = (props: Props) => {
  const orgUser = props.user;
  if (!orgUser) {
    return null;
  }

  const name = getFullName(orgUser.user);
  const links = [
    {
      key: "user-list",
      name: props.t("headerTabs.userList"),
      onClick: props.onNavLinkClicked,
    },
  ];

  return (
    <Box
      display="flex"
      paddingX={DEFAULT_SPACING}
      paddingTop={DEFAULT_SPACING}
      justifyContent="space-between"
    >
      <NavigationRail links={links} currentPageTitle={name} />

      {props.endComponent}
    </Box>
  );
};

export default withTranslation()(UserDetailPageNavRail);
