import { Box, Dialog, FormControlLabel, Switch, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../redux/RootState";
import AdeyLogo from "../../../ui/assets/AdeyLogo/AdeyLogo";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../ui/theme/dimensions";
import SystemDownCard from "./components/SystemDownCard";
import SystemMessageCard from "./components/SystemMessageCard";
import useSystemMessages from "./hooks/useSystemMessages";

const SystemMessagesContainer = () => {
  //Translation Hook
  const { t } = useTranslation();

  //Selectors
  const selectMessagesState = useSelector((state: RootState) => state.systemMessages);
  const isLoggingIn = useSelector(
    (state: RootState) => state.login.status === "logging_in"
  );

  //System Messages Hook
  const {
    currentPageIndex,
    isDialogOpen,
    isLastItem,
    hideMessageIndexes,
    nextPageClicked,
    prevPageClicked,
    hideCurrentMessage,
    onClose,
  } = useSystemMessages({
    systemDown: selectMessagesState.systemDown,
    messages: selectMessagesState.messages,
  });

  //Helper

  //Translated Strings
  const title = t("systemMessageContainer.title").toUpperCase();
  const messageCounterDisplay = `${currentPageIndex + 1}/${
    selectMessagesState.messages.length
  }`;
  const buttonLabel = isLastItem ? t("buttons.close") : t("buttons.next");
  const backLabel = t("buttons.back");
  const hideLabel = t("systemMessageContainer.hideLabel");

  //Renderers
  const showMaintenanceMessage = () => {
    if (selectMessagesState.systemDown) {
      return <SystemDownCard />;
    } else {
      return (
        <SystemMessageCard message={selectMessagesState.messages[currentPageIndex]} />
      );
    }
  };

  return (
    <Dialog open={!!isDialogOpen && !isLoggingIn} onClose={onClose}>
      <Box
        padding={DEFAULT_SPACING}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minWidth="400px"
      >
        <Typography variant="h2" gutterBottom paragraph align="center">
          {title} {selectMessagesState.messages.length > 1 && messageCounterDisplay}
        </Typography>
        {showMaintenanceMessage()}
        <Box
          paddingTop={SMALL_SPACING}
          paddingBottom={DEFAULT_SPACING}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <AdeyLogo height={50} color="blue" />
        </Box>

        {!selectMessagesState.systemDown && (
          <PrimaryButton
            fullWidth
            label={buttonLabel}
            onClick={isLastItem ? onClose : nextPageClicked}
          />
        )}
        {currentPageIndex > 0 && (
          <SecondaryButton
            fullWidth={false}
            label={backLabel}
            onClick={prevPageClicked}
          />
        )}
        {!selectMessagesState.systemDown && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <FormControlLabel
              style={{ marginLeft: DEFAULT_SPACING, marginTop: DEFAULT_SPACING }}
              control={
                <Switch
                  onChange={(e, checked) => {
                    hideCurrentMessage(!checked);
                  }}
                  color="primary"
                />
              }
              label={hideLabel}
              checked={hideMessageIndexes.includes(currentPageIndex)}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default SystemMessagesContainer;
