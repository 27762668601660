import { CardMedia } from "@material-ui/core";
import React from "react";

interface Props {
  url: string;
  diameter: number;
  children?: JSX.Element;
  backgroundColor?: string;
  border?: boolean;
}

const CircleImage = (props: Props) => {
  const style: React.CSSProperties = {
    height: props.diameter,
    width: props.diameter,
    borderRadius: props.diameter / 2,
    border: props.border ? "1px solid lightGrey" : undefined,
  };

  if (props.backgroundColor) {
    style.background = props.backgroundColor;
  }

  return (
    <CardMedia style={style} image={props.url}>
      {props.children}
    </CardMedia>
  );
};

export default CircleImage;
