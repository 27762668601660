import { LoqateFindItem } from "../../../../rest/loqate/model/LoqateFindResponse";
import AddressLookupAction from "./AddressLookupAction";
import AddressLookupState from "./AddressLookupState";

const initialState: AddressLookupState = {
  lastSearch: undefined,
  addresses: undefined,
  isLoading: false,
  error: undefined,
  loadingItem: undefined,
  loadedAddress: undefined,
};

const addressLookupReducer = (
  state = initialState,
  action: AddressLookupAction
): AddressLookupState => {
  switch (action.type) {
    case "SEARCH_ADDRESS_TYPE":
      return {
        lastSearch: {
          searchText: action.searchText,
          loqateCountry: action.country,
        },
        addresses: undefined,
        isLoading: true,
        error: undefined,
        loadingItem: undefined,
        loadedAddress: undefined,
      };

    case "SEARCH_ADDRESS_SUCCESS_TYPE":
      return {
        lastSearch: state.lastSearch,
        addresses: action.items,
        isLoading: false,
        error: undefined,
        loadingItem: undefined,
        loadedAddress: undefined,
      };

    case "SEARCH_ADDRESS_ERROR_TYPE":
      return {
        lastSearch: state.lastSearch,
        addresses: state.addresses,
        isLoading: false,
        error: action.error,
        loadingItem: undefined,
        loadedAddress: undefined,
      };

    case "CLEAR_SEARCH_TYPE":
      return {
        ...initialState,
      };

    case "ITEM_SELECTED_TYPE":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        loadingItem: action.item,
        loadedAddress: undefined,
      };

    case "ITEM_EXPANDED_TYPE":
      let containerIndex = state.addresses?.findIndex(
        (item) => item.Id === action.container.Id
      );
      let updatedAddresses: LoqateFindItem[];
      let currentAddresses = state.addresses || [];

      if (containerIndex === undefined || containerIndex < 0) {
        updatedAddresses = [...currentAddresses, ...action.replacement];
      } else {
        currentAddresses.splice(containerIndex, 1, ...action.replacement);
        updatedAddresses = currentAddresses;
      }

      return {
        lastSearch: state.lastSearch,
        addresses: updatedAddresses,
        isLoading: false,
        error: undefined,
        loadingItem: undefined,
        loadedAddress: undefined,
      };

    case "ADDRESS_DETAILS_LOADED_TYPE":
      return {
        ...initialState,
        loadedAddress: action.address,
      };

    default:
      return state;
  }
};

export default addressLookupReducer;
