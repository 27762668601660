import { AxiosInstance } from "axios";
import PendingInvite from "../invites/model/PendingInvite";
import OrganisationUser from "../organisation/model/OrganisationUser";
import UserDetails from "../user/model/UserDetails";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import OrganisationUserRepository from "./OrganisationUserRepository";

class RestOrganisationUserRepository implements OrganisationUserRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  fetchOrganisationUserList = (
    organisationId: string,
    keyword: string
  ): Promise<OrganisationUser[]> => {
    const params = { keyword: keyword };

    const endpoint = `organisations/${organisationId}/users`;
    return this.axios
      .get<DataWrapper<OrganisationUser[]>>(endpoint, { params: params })
      .then(unwrapResponse);
  };

  fetchOrganisationUser = (
    organisationId: string,
    userId: string
  ): Promise<OrganisationUser> => {
    const endpoint = `organisations/${organisationId}/users/${userId}`;
    return this.axios.get<DataWrapper<OrganisationUser>>(endpoint).then(unwrapResponse);
  };

  removeUserFromOrganisation = (
    user: UserDetails,
    organisationId: string
  ): Promise<void> => {
    const endpoint = `/organisations/${organisationId}/users/${user.id}`;

    return this.axios.delete<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };

  inviteOrganisationUser = (
    organisationId: string,
    userEmail: string,
    roleId: string,
    isAdmin?: boolean
  ): Promise<PendingInvite> => {
    const adminPrefix = isAdmin ? "/admin" : "";
    const params = new URLSearchParams();
    params.append("email", userEmail);
    params.append("role_id", roleId);
    const endpoint = `${adminPrefix}/organisations/${organisationId}/users/invite`;
    return this.axios
      .post<DataWrapper<PendingInvite>>(endpoint, params)
      .then(unwrapResponse);
  };
}

export default RestOrganisationUserRepository;
