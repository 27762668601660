import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import UserDetails from "../../../../rest/user/model/UserDetails";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import getFullName from "../../../../utils/getFullName";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  user: UserDetails;

  onCancelClicked: () => void;
  onDeleteClicked: () => void;
}

const ConfirmRemoveUserDialog = (props: Props) => {
  const title = props.t("confirmRemoveUserDialog.title");
  const bodyPlaceholder = props.t("confirmRemoveUserDialog.bodyPlaceholder");
  const removeButton = props.t("confirmRemoveUserDialog.removeButtonPlaceholder");
  const cancelButton = props.t("buttons.cancel");

  const body = bodyPlaceholder
    .replace("<user_name>", getFullName(props.user))
    .replace("<org_name>", props.organisationName);

  const cancelHandler = (e: any) => {
    e.stopPropagation();
    props.onCancelClicked();
  };

  const deleteHandler = (e: any) => {
    e.stopPropagation();
    props.onDeleteClicked();
  };

  const handleDialogClicked = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Dialog open={props.open} onClose={cancelHandler} onClick={handleDialogClicked}>
      <Box padding={SMALL_SPACING}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>{body}</Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" onClick={cancelHandler}>
            {cancelButton}
          </Button>
          <Button color="primary" onClick={deleteHandler}>
            {removeButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(ConfirmRemoveUserDialog);
