import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutlineButton from "../../../../../ui/components/OutlineButton";

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
}

const MovePropertiesButton = (props: IProps) => {
  return (
    <OutlineButton
      labelId="buttons.move"
      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

export default MovePropertiesButton;
