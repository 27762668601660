import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { Country } from "../../../rest/countries/model/Country";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OrganisationEditor from "../../../ui/components/OrganisationEditor/OrganisationEditor";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";
import CreateOrganisationForm from "../../auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";
import { createOrganisationSubmit } from "./redux/CreateOrganisationAction";
import CreateOrganisationState from "./redux/CreateOrganisationState";

interface ExternalProps {
  onBackPressed: () => void;
}

type ReduxStateProps = CreateOrganisationState & {
  countryList: Country[] | undefined;
};

interface ReduxDispatchProps {
  onOrganisationCreated: (form: CreateOrganisationForm) => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps & WithT;

interface State {
  success: boolean;
}

class CreateOrganisationPanel extends React.Component<Props, State> {
  state = {
    success: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.success && !prevProps.success) {
      // If we get any success state, keep track of it
      this.setState({ success: true });
    }
  }

  render() {
    const title = this.props.t("createOrganisationPanel.title");
    const backButton = this.props.t("createOrganisationPanel.backButton");
    const titleTooltip = this.props.t("createOrganisationPanel.tooltip");
    // If we've created an org, keep it loading because
    // this will automatically be dismissed when the org details
    // are loaded
    const loading = this.props.isLoading || this.state.success;

    return (
      <Grid container spacing={DEFAULT_SPACING} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <strong>{title.toUpperCase()}</strong>

            <Tooltip interactive title={titleTooltip}>
              <IconButton>
                <FontAwesomeIcon icon={faInfoCircle} size="xs" />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <OrganisationEditor
            countryList={this.props.countryList}
            isLoading={loading}
            onContinuePressed={this.props.onOrganisationCreated}
          />
        </Grid>

        <Grid item>
          <SecondaryButton
            label={backButton}
            disabled={loading}
            onClick={this.props.onBackPressed}
          />
        </Grid>

        <ErrorSnackbarHandler message={this.props.error} />
      </Grid>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return { ...state.createOrganisation, countryList: state.countryList.countryList };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onOrganisationCreated: (form) => dispatch(createOrganisationSubmit(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateOrganisationPanel));
