import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Organisation from "../../../rest/organisation/model/Organisation";
import ProCheckExport from "../../../rest/procheck/model/ProCheckExport";
import Role from "../../../rest/roles/model/Role";
import UserDetails from "../../../rest/user/model/UserDetails";
import {
  buildUserProcheckReportDetailRoute,
  USER_LIST_ROUTE,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import TabModel from "../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import Spinner from "../../../ui/components/Spinner";
import { EXTRA_LARGE_SPACING } from "../../../ui/theme/dimensions";
import ProductReportTableItem from "../../procheck/ProCheckPage/model/ProductReportTableItem";
import { downloadReport } from "../../procheck/redux/ProCheckAction";
import RemoveUserButton from "./components/RemoveUserButton";
import UserDetailPageNavRail from "./components/UserDetailPageNavRail";
import UserDetailPanel from "./components/UserDetailPanel";
import { loadUser, removeUser } from "./redux/UserDetailActions";
import UserDetailState from "./redux/UserDetailState";
import ProCheckDetailTab from "./tabs/ProCheckDetailTab";

interface URLParams {
  userId: string;
  organisationId: string;
}

type ReduxStateProps = {
  userDetailsState: UserDetailState;
};

type UserDetailPageTabs = "user-details" | "procheck";

interface ReduxDispatchProps {
  loadUser: (organisationId: string, userId: string) => void;
  removeUser: (user: UserDetails, org: Organisation) => void;
  downloadReport: (
    orgId: string,
    propertyId: string,
    exportDetails: ProCheckExport,
    languageHeader?: string
  ) => void;
}

type Props = RouteComponentProps<URLParams> &
  ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation;

interface State {
  userRemoved: boolean;
  activeTab: UserDetailPageTabs;
}

class UserDetailPage extends React.Component<Props, State> {
  state = {
    userRemoved: false,
    activeTab: "user-details" as UserDetailPageTabs,
  };

  componentDidMount() {
    const userId = this.props.match.params.userId;
    const organisationId = this.props.match.params.organisationId;
    this.props.loadUser(organisationId, userId);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.userDetailsState.userRemoved &&
      !prevProps.userDetailsState.userRemoved
    ) {
      this.setState({ userRemoved: true });
    }
  }

  render() {
    return (
      <DashboardLayout>
        <Box>{this.renderBody()}</Box>
      </DashboardLayout>
    );
  }

  renderTabs(user: UserDetails, role: Role, organisation?: Organisation) {
    switch (this.state.activeTab) {
      case "user-details":
        return <UserDetailPanel user={user} role={role} organisation={organisation} />;
      case "procheck":
        return <ProCheckDetailTab onReportClicked={this.handleReportClicked} />;
    }
  }

  renderBody() {
    if (this.props.userDetailsState.user) {
      return (
        <Box>
          <UserDetailPageNavRail
            user={this.props.userDetailsState.user}
            onNavLinkClicked={this.handleReturnToListClicked}
            endComponent={
              this.props.userDetailsState.canRemoveUser ? (
                <RemoveUserButton
                  organisationName={this.props.userDetailsState.organisation?.name}
                  user={this.props.userDetailsState.user.user}
                  removeUserConfirmed={this.handleRemoveUserClicked}
                  loading={this.props.userDetailsState.isRemovingUser}
                  userRemoved={this.state.userRemoved}
                  returnToListClicked={this.handleReturnToListClicked}
                />
              ) : undefined
            }
          />

          <PageHeaderTabs
            activeKey={this.state.activeTab}
            tabs={this.buildTabModels()}
            tabClicked={this.handleTabClicked}
          />

          <Box height={2 * EXTRA_LARGE_SPACING} />

          {this.renderTabs(
            this.props.userDetailsState.user.user,
            this.props.userDetailsState.user.role,

            this.props.userDetailsState.organisation
          )}

          <Box height={2 * EXTRA_LARGE_SPACING} />

          <ErrorSnackbarHandler message={this.props.userDetailsState.error} />
        </Box>
      );
    } else if (this.props.userDetailsState.error) {
      return <ErrorDisplay title={this.props.userDetailsState.error} />;
    } else {
      return <Spinner />;
    }
  }

  handleReturnToListClicked = () => {
    this.props.history.replace(USER_LIST_ROUTE);
  };

  handleRemoveUserClicked = (user: UserDetails) => {
    if (this.props.userDetailsState.organisation) {
      this.props.removeUser(user, this.props.userDetailsState.organisation);
    }
  };

  handleTabClicked = (key: string) => {
    this.setState({ activeTab: key as UserDetailPageTabs });
  };

  handleReportClicked = (report: ProductReportTableItem) => {
    // In all the possible cases, the data of ProductReportTableItem should contain the fields that are casted
    // as string, so it should be fine do it in such a way
    const orgId = report.orgId as string;
    const userId = report.userId as string;
    const procheckId = report.id;
    const propertyId = report.propertyId;
    const route = buildUserProcheckReportDetailRoute(
      orgId,
      procheckId,
      userId,
      propertyId
    );
    this.props.history.push(route);
  };

  buildTabModels = (): TabModel[] => {
    return [
      { title: "userDetailPageTabs.userDetailTab", navKey: "user-details" },
      { title: "userDetailPageTabs.proCheckTab", navKey: "procheck" },
    ];
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    userDetailsState: state.userDetail,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadUser: (organisationId, userId) => dispatch(loadUser(organisationId, userId)),

  removeUser: (user, org) => dispatch(removeUser(user, org)),
  downloadReport: (orgId, propertyId, exportDetails, languageHeader) =>
    dispatch(downloadReport(orgId, exportDetails, propertyId, languageHeader)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(UserDetailPage)));
