import { Description } from "@material-ui/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import OutlineButton from "../../../../ui/components/OutlineButton";
import { EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithTranslation {
  onClick?: () => void;
  disabled?: boolean;
}

const ExportReportButton = (props: Props) => {
  const label = props.t("buttons.export");

  return (
    <OutlineButton
      disabled={props.disabled}
      label={label}
      endIcon={<Description />}
      marginRight={EXTRA_LARGE_SPACING}
      onClick={props.onClick}
      filled
    />
  );
};

export default withTranslation()(ExportReportButton);
