import EditCurrentOrganisationAction from "./EditCurrentOrganisationAction";
import EditCurrentOrganisationState from "./EditCurrentOrganisationState";

const initialState: EditCurrentOrganisationState = {
  isLoading: false,
  updateSuccessful: false,
  error: undefined,
};

const editCurrentOrganisationReducer = (
  state = initialState,
  action: EditCurrentOrganisationAction
): EditCurrentOrganisationState => {
  switch (action.type) {
    case "EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE":
      return {
        isLoading: true,
        error: undefined,
        updateSuccessful: false,
      };

    case "EDIT_CURRENT_ORGANISATION_SUCCESS_TYPE":
      return {
        isLoading: false,
        error: undefined,
        updateSuccessful: true,
      };

    case "EDIT_CURRENT_ORGANISATION_ERROR_TYPE":
      return {
        isLoading: false,
        error: action.error,
        updateSuccessful: false,
      };

    default:
      return state;
  }
};

export default editCurrentOrganisationReducer;
