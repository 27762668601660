import { Box } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";
import SearchBar from "../../../../../ui/components/SearchBar";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../ui/theme/dimensions";
import PropertyListEmptyMessage from "../../../../properties/PropertyListPage/components/PropertyListEmptyMessage";
import mapToPropertyTableItem from "../../../../properties/PropertyListPage/utils/mapToPropertyTableItem";
import PropertyList from "../../../../properties/PropertyListPage/components/PropertyTable";
import Spinner from "../../../../../ui/components/Spinner";
import Property, {
  PropertyPageType,
} from "../../../../../rest/properties/model/Property";
import PropertyTableItem from "../../../../properties/PropertyListPage/model/PropertyTableItem";
import {
  changePageType,
  searchTextChanged,
} from "../../../../properties/PropertyListPage/redux/PropertyListActions";
import { buildPropertyDetailRoute } from "../../../../../routes/routes";
import { RouteComponentProps, withRouter } from "react-router";
import { OrganisationRelationship } from "../../redux/OrganisationDetailState";

interface ExternalProps {}

interface ReduxDispatchProps {
  onSearchTextChanged: (text: string) => void;
  onPageTypeChanged: (page: PropertyPageType) => void;
}

interface ReduxStateProps {
  searchText: string;
  properties: Property[] | undefined;
  errorMessage: string | undefined;
  page: PropertyPageType | undefined;
  currentOrgId: string | undefined;
  selectedOrgId: string | undefined;
  organisationRelationship: OrganisationRelationship | undefined;
}

type Props = RouteComponentProps &
  ExternalProps &
  WithTranslation &
  ReduxDispatchProps &
  ReduxStateProps;

class OrganisationPropertyList extends React.Component<Props> {
  componentDidMount() {
    switch (this.props.organisationRelationship) {
      case "contractor":
        return this.props.onPageTypeChanged("contractor-list");
      case "contractee":
        return this.props.onPageTypeChanged("contractee-list");
      default:
        return this.props.onPageTypeChanged("dashboard");
    }
  }

  componentDidUpdate() {
    switch (this.props.organisationRelationship) {
      case "contractor":
        if (this.props.page !== "contractor-list") {
          return this.props.onPageTypeChanged("contractor-list");
        }
        break;
      case "contractee":
        if (this.props.page !== "contractee-list") {
          return this.props.onPageTypeChanged("contractee-list");
        }
        break;
      default:
        return this.props.onPageTypeChanged("dashboard");
    }
  }

  render() {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="top"
        justifyContent="flex-start"
        marginX={EXTRA_LARGE_SPACING}
        paddingBottom={DEFAULT_SPACING}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          marginX={DEFAULT_SPACING}
          marginTop={SMALL_SPACING}
          marginLeft={SMALL_SPACING}
          marginRight={SMALL_SPACING}
        >
          <SearchBar
            placeholder={this.props.t("propertyListPage.searchBarPlaceholder")}
            value={this.props.searchText}
            onChange={this.props.onSearchTextChanged}
          />

          {this.renderBody()}
        </Box>
      </Box>
    );
  }

  renderBody = () => {
    if (this.props.errorMessage) {
      return (
        <ErrorDisplay
          title={this.props.t("propertyListPage.errorTitle")}
          message={this.props.errorMessage}
        />
      );
    } else if (this.props.properties?.length === 0) {
      return <PropertyListEmptyMessage />;
    } else if (this.props.properties) {
      const tableItems = this.props.properties.map((p) =>
        mapToPropertyTableItem(p, this.props.t)
      );

      return (
        <PropertyList
          tableItems={tableItems}
          onClick={this.handlePropertyClicked}
          showOwner={false}
        />
      );
    } else {
      return <Spinner />;
    }
  };

  //MARK: Handlers
  handlePropertyClicked = (item: PropertyTableItem) => {
    if (this.props.currentOrgId) {
      const route = buildPropertyDetailRoute(
        this.orgIdSwitcher(this.props.organisationRelationship),
        item.id
      );
      this.props.history.push(route);
    }
  };

  //If the selected property is of the associated organisation, substitude user 'orgId' with the selected organisation's 'orgId'
  orgIdSwitcher = (relationship: OrganisationRelationship | undefined): string => {
    if (relationship === "contractee") {
      return this.props.selectedOrgId as string;
    } else {
      return this.props.currentOrgId as string;
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    onSearchTextChanged: (text) => dispatch(searchTextChanged(text)),
    onPageTypeChanged: (pageType) => dispatch(changePageType(pageType)),
  };
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    searchText: state.propertyList.searchText,
    properties: state.propertyList.propertyList,
    errorMessage: state.propertyList.errorMessage,
    page: state.propertyList.page,
    currentOrgId: state.activeOrganisation.currentOrganisation?.id,
    selectedOrgId: state.orgDetail.organisation?.id,
    organisationRelationship: state.orgDetail.organisationRelationship,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OrganisationPropertyList)));
