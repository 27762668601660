// Admin access changed
export const ADMIN_ACCESS_CHANGED_TYPE = "ADMIN_ACCESS_CHANGED_TYPE";
export interface AdminAccessChangedAction {
  type: typeof ADMIN_ACCESS_CHANGED_TYPE;
  hasAdminAccess: boolean;
}
export const adminAccessChanged = (hasAccess: boolean): AdminAccessChangedAction => ({
  type: ADMIN_ACCESS_CHANGED_TYPE,
  hasAdminAccess: hasAccess,
});

// Union type
type AdeyAdminAction = AdminAccessChangedAction;
export default AdeyAdminAction;
