import { Box, Button, Chip, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../theme/dimensions";
import CircleAvatar from "../../CircleAvatar";
import createSwitcherSubtitle from "../utils/createSwitcherSubtitle";

const style = {
  paddingTop: EXTRA_LARGE_SPACING,
  paddingRight: EXTRA_LARGE_SPACING,
  paddingBottom: LARGE_SPACING,
  width: "100%",
};

interface Props extends WithT {
  //Selected Organisation
  selectedOrganisation: MemberOrganisation | undefined;

  availableOrgs: MemberOrganisation[];

  //Click handler for organisation details
  onDetailsClick: (organisation: Organisation | undefined) => void;

  //Organisation details button label
  buttonLabel: string;
}

const OrganizationSwitcherHeader = (props: Props) => {
  const displayRole = props.selectedOrganisation
    ? createSwitcherSubtitle(props.selectedOrganisation, props.availableOrgs, props.t)
    : undefined;

  //Getting localised country name
  const getCountryName = (agent: Organisation) => {
    return props.t(`countryCode.${agent.is_agent_for?.code || "missing"}`);
  };

  const agentLabel =
    props.selectedOrganisation !== undefined &&
    props.selectedOrganisation.is_agent_for?.code
      ? `${props.t("roles.agent")} ${getCountryName(props.selectedOrganisation)}`
      : undefined;

  return (
    <Box style={style} display="flex" flexDirection="column" alignItems="center">
      <CircleAvatar
        imageUrl={undefined}
        name={props.selectedOrganisation ? props.selectedOrganisation.name : ""}
        size="xlarge"
      />

      <Typography variant="subtitle1" align="center">
        <strong>
          {props.selectedOrganisation ? props.selectedOrganisation.name : ""}
        </strong>
      </Typography>

      {agentLabel !== undefined ? (
        <Chip size="small" color="primary" label={agentLabel} />
      ) : null}

      {displayRole ? <Typography variant="button">{displayRole}</Typography> : undefined}

      <Box height={LARGE_SPACING} />

      <Button
        color="secondary"
        variant="outlined"
        size="small"
        onClick={() => props.onDetailsClick(props.selectedOrganisation)}
      >
        {props.buttonLabel}
      </Button>
    </Box>
  );
};

export default withTranslation()(OrganizationSwitcherHeader);
