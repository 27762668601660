/* COUNTRY */

import { Country, CountryForm } from "../../../rest/countries/model/Country";

//Get Country
export const LOAD_COUNTRY_TYPE = "LOAD_COUNTRY_TYPE";

export interface LoadCountryAction {
  type: typeof LOAD_COUNTRY_TYPE;
  countryId: string;
}

export const loadCountry = (countryId: string): LoadCountryAction => ({
  type: LOAD_COUNTRY_TYPE,
  countryId: countryId,
});
//Get Country Success
export const COUNTRY_LOADED_TYPE = "COUNTRY_LOADED_TYPE";
export interface CountryLoadedAction {
  type: typeof COUNTRY_LOADED_TYPE;
  country: Country;
}

export const countryLoaded = (country: Country): CountryLoadedAction => ({
  type: COUNTRY_LOADED_TYPE,
  country: country,
});

//Get Country Failure
export const COUNTRY_LOAD_ERROR_TYPE = "COUNTRY_LOAD_ERROR_TYPE";

export interface CountryLoadErrorAction {
  type: typeof COUNTRY_LOAD_ERROR_TYPE;
  error: string;
}
export const countryLoadError = (error: string): CountryLoadErrorAction => ({
  type: COUNTRY_LOAD_ERROR_TYPE,
  error: error,
});

/* COUNTRY LIST */
//Get Country List
export const LOAD_COUNTRY_LIST_TYPE = "LOAD_COUNTRY_LIST_TYPE";

export interface LoadCountryListAction {
  type: typeof LOAD_COUNTRY_LIST_TYPE;
}

export const loadCountryList = (): LoadCountryListAction => ({
  type: LOAD_COUNTRY_LIST_TYPE,
});

//Get Country Success
export const COUNTRY_LIST_LOADED_TYPE = "COUNTRY_LIST_LOADED_TYPE";

export interface CountryListLoadedAction {
  type: typeof COUNTRY_LIST_LOADED_TYPE;
  countries: Country[];
}

export const countryListLoaded = (countries: Country[]): CountryListLoadedAction => ({
  type: COUNTRY_LIST_LOADED_TYPE,
  countries: countries,
});

//Get Country Failure
export const COUNTRY_LIST_ERROR_TYPE = "COUNTRY_LIST_ERROR_TYPE";

export interface CountryListErrorAction {
  type: typeof COUNTRY_LIST_ERROR_TYPE;
  error: string;
}

export const countryListLoadError = (error: string): CountryListErrorAction => ({
  type: COUNTRY_LIST_ERROR_TYPE,
  error: error,
});

/* CREATE COUNTRY */
//Create Country
export const CREATE_COUNTRY_TYPE = "CREATE_COUNTRY_TYPE";

export interface CreateCountryAction {
  type: typeof CREATE_COUNTRY_TYPE;
  countryForm: CountryForm;
}

export const createCountry = (countryForm: CountryForm): CreateCountryAction => ({
  type: CREATE_COUNTRY_TYPE,
  countryForm: countryForm,
});

//Create Country Success
export const COUNTRY_CREATE_SUCCESS_TYPE = "COUNTRY_CREATE_SUCCESS_TYPE";

export interface CountryCreateSuccessAction {
  type: typeof COUNTRY_CREATE_SUCCESS_TYPE;
  country: Country;
}

export const countryCreatedSuccess = (country: Country): CountryCreateSuccessAction => ({
  type: COUNTRY_CREATE_SUCCESS_TYPE,
  country: country,
});

//Create Country Failure
export const CREATE_COUNTRY_ERROR_TYPE = "CREATE_COUNTRY_ERROR_TYPE";

export interface CountryCreateErrorAction {
  type: typeof CREATE_COUNTRY_ERROR_TYPE;
  error: string;
}

export const createCountryError = (error: string): CountryCreateErrorAction => ({
  type: CREATE_COUNTRY_ERROR_TYPE,
  error: error,
});

/* UPDATE COUNTRY */
//Update Country
export const UPDATE_COUNTRY_TYPE = "UPDATE_COUNTRY_TYPE";

export interface UpdateCountryAction {
  type: typeof UPDATE_COUNTRY_TYPE;
  country: Country;
}

export const updateCountry = (country: Country): UpdateCountryAction => ({
  type: UPDATE_COUNTRY_TYPE,
  country: country,
});

//Update Country Success
export const COUNTRY_UPDATE_SUCCESS_TYPE = "COUNTRY_UPDATE_SUCCESS_TYPE";

export interface CountryUpdatedAction {
  type: typeof COUNTRY_UPDATE_SUCCESS_TYPE;
  country: Country;
}

export const countryUpdated = (country: Country): CountryUpdatedAction => ({
  type: COUNTRY_UPDATE_SUCCESS_TYPE,
  country: country,
});

//Update Country Failure
export const UPDATE_COUNTRY_ERROR_TYPE = "UPDATE_COUNTRY_ERROR_TYPE";

export interface CountryUpdateErrorAction {
  type: typeof UPDATE_COUNTRY_ERROR_TYPE;
  error: string;
}

export const countryUpdateError = (error: string): CountryUpdateErrorAction => ({
  type: UPDATE_COUNTRY_ERROR_TYPE,
  error: error,
});

type CountryAction =
  | LoadCountryAction
  | CountryLoadedAction
  | CountryLoadErrorAction
  | LoadCountryListAction
  | CountryListLoadedAction
  | CountryListErrorAction
  | CreateCountryAction
  | CountryCreateSuccessAction
  | CountryCreateErrorAction
  | UpdateCountryAction
  | CountryUpdatedAction
  | CountryUpdateErrorAction;

export default CountryAction;
