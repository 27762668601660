import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface Props extends WithT {
  open: boolean;
  contractorName: string;
  contracteeName: string;

  onCancelClicked: () => void;
  onDeleteClicked: () => void;
}

const AdminRemoveContractorDialog = (props: Props) => {
  const title = props.t("adminRemoveContractorDialog.title");
  const bodyPlaceholder = props.t("adminRemoveContractorDialog.bodyPlaceholder");
  const removeButton = props.t("adminRemoveContractorDialog.removeButtonPlaceholder");

  const body = bodyPlaceholder
    .replaceAll("<contractor_name>", props.contractorName)
    .replaceAll("<contractee_name>", props.contracteeName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={removeButton}
      onConfirmed={props.onDeleteClicked}
      onCancelClicked={props.onCancelClicked}
    />
  );
};

export default withTranslation()(AdminRemoveContractorDialog);
