import { AxiosInstance } from "axios";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import SenseDevice from "./model/SenseDevice";
import SenseDeviceReadings from "./model/SenseDeviceReadings";
import SenseRepository from "./SenseRepository";

class RestSenseRepository implements SenseRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  listAllSenseDevices = (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string
  ): Promise<SenseDevice[]> => {
    const url = `${
      isAdmin ? "admin" : ""
    }/organisations/${organisation_id}/properties/${property_id}/devices/sense`;

    return this.axios.get<DataWrapper<SenseDevice[]>>(url).then(unwrapResponse);
  };

  getSenseDevice = (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string,
    serial: string
  ): Promise<SenseDevice> => {
    const url = `${
      isAdmin ? "admin" : ""
    }/organisations/${organisation_id}/properties/${property_id}/devices/sense/${serial}`;

    return this.axios.get<DataWrapper<SenseDevice>>(url).then(unwrapResponse);
  };

  listAllSenseDeviceReadings = (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string,
    serial: string
  ): Promise<SenseDeviceReadings[]> => {
    const url = `${
      isAdmin ? "admin" : ""
    }/organisations/${organisation_id}/properties/${property_id}/devices/sense/${serial}/readings`;

    return this.axios.get<DataWrapper<SenseDeviceReadings[]>>(url).then(unwrapResponse);
  };

  getSenseDeviceReading = (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string,
    serial: string,
    reading: string
  ): Promise<SenseDeviceReadings> => {
    const url = `${
      isAdmin ? "admin" : ""
    }/organisations/${organisation_id}/properties/${property_id}/devices/sense/${serial}/readings/${reading}`;

    return this.axios.get<DataWrapper<SenseDeviceReadings>>(url).then(unwrapResponse);
  };

  setDeviceActivation = (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string,
    device_id: string,
    activate: boolean
  ): Promise<void> => {
    const adminParam = isAdmin ? "/admin" : "";
    const activateParam = activate ? "activate" : "deactivate"; //Nominally allowing for non-admin device activation in the future

    const url = `${adminParam}/organisations/${organisation_id}/properties/${property_id}/devices/sense/${device_id}/${activateParam}`;

    return this.axios.post<DataWrapper<void>>(url).then(unwrapResponse);
  };
}

export default RestSenseRepository;
