import { AxiosInstance, AxiosRequestConfig } from "axios";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import InviteRepository from "./InviteRepository";
import PendingInvite from "./model/PendingInvite";

class RestInviteRepository implements InviteRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  fetchPendingInvites = (): Promise<PendingInvite[]> => {
    return this.axios
      .get<DataWrapper<PendingInvite[]>>("/me/invitations")
      .then(unwrapResponse);
  };

  acceptInvite = (
    organisationId: string,
    inviteCode: string,
    inviteType: string
  ): Promise<void> => {
    const endpoint = `/organisations/${organisationId}/${inviteType}s/invite/accept/${inviteCode}`;

    return this.axios.put<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };

  rejectInvite = (inviteCode: string): Promise<void> => {
    const endpoint = `/me/invitations/${inviteCode}`;

    return this.axios.delete<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };

  fetchPendingInvitesForOrganisation = (
    organisationId: string,
    keyword: string | undefined
  ): Promise<PendingInvite[]> => {
    const endpoint = `organisations/${organisationId}/users/invite`;

    const config: AxiosRequestConfig = { params: { keyword: keyword } };

    return this.axios
      .get<DataWrapper<PendingInvite[]>>(endpoint, config)
      .then(unwrapResponse);
  };

  fetchPendingContractorInvites = (organisationID: string): Promise<PendingInvite[]> => {
    const endpoint = `/organisations/${organisationID}/contractors/invite`;
    return this.axios.get<DataWrapper<PendingInvite[]>>(endpoint).then(unwrapResponse);
  };

  resendInvitation = (
    isAdeyAdmin: boolean,
    organisationId: string,
    invitationId: string,
    inviteType: "user" | "contractor"
  ): Promise<PendingInvite> => {
    const endpointPrefix = isAdeyAdmin ? "/admin" : "";
    let endpoint: string;
    switch (inviteType) {
      case "user":
        endpoint = `${endpointPrefix}/organisations/${organisationId}/users/invite/${invitationId}/resend`;
        break;
      case "contractor":
        endpoint = `${endpointPrefix}/organisations/${organisationId}/contractors/invite/${invitationId}/resend`;
        break;
    }

    return this.axios.post<DataWrapper<PendingInvite>>(endpoint).then(unwrapResponse);
  };

  revokeInvitation = (
    isAdeyAdmin: boolean,
    organisationId: string,
    invitationId: string,
    inviteType: "user" | "contractor"
  ): Promise<void> => {
    const endpointPrefix = isAdeyAdmin ? "/admin" : "";
    let endpoint: string;
    switch (inviteType) {
      case "user":
        endpoint = `${endpointPrefix}/organisations/${organisationId}/users/invite/${invitationId}`;
        break;
      case "contractor":
        endpoint = `${endpointPrefix}/organisations/${organisationId}/contractors/invite/${invitationId}`;
        break;
    }
    return this.axios.delete<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };
}

export default RestInviteRepository;
