import { AxiosInstance, AxiosRequestConfig } from "axios";
import DataWrapper, {
  unwrapData,
  unwrapResponse,
} from "../../../../rest/utils/DataWrapper";
import AuthProxyService from "./AuthProxyService";
import IPasswordResetRequiredResponse from "./interfaces/IPasswordResetRequiredResponse";

class RestAuthProxyService implements AuthProxyService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }
  checkPasswordResetRequired = async (
    email: string
  ): Promise<IPasswordResetRequiredResponse> => {
    const url = "/auth/user-password-reset-required";

    const config: AxiosRequestConfig = {
      params: { email: email },
    };

    return await this.axios
      .get<IPasswordResetRequiredResponse>(url, config)
      .then(unwrapData);
  };

  forgotPasswordRequest = async (email: string): Promise<void> => {
    const url = "/auth/password/forgot";

    const params = new URLSearchParams();
    params.append("email", email);

    return await this.axios.post<DataWrapper<void>>(url, params).then(unwrapResponse);
  };
}

export default RestAuthProxyService;
