const isValidEmail = (email: string | undefined): boolean => {
  const trimmed = email?.trim();
  if (trimmed === undefined || trimmed.length === 0) {
    return false;
  }

  return trimmed.includes("@");
};

export default isValidEmail;
