import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import {
  buildPropertyDetailRoute,
  buildUserDetailRoute,
  DASHBOARD_ROUTE,
  PROCHECK_USER_RESULTS_ROUTE,
  SENSE_DEVICE_ROUTE,
  USER_LIST_ROUTE,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import Spinner from "../../../ui/components/Spinner";
import {
  changeSenseDeviceActivationStatus,
  loadSelectedSenseDevice,
} from "../redux/SenseDevicesAction";
import SenseDevicesState from "../redux/SenseDevicesState";
import LoadedSenseDeviceDetailsPage from "./components/LoadedSenseDeviceDetailsPage";

export type SenseDevicePageType = "user-page" | "property-page";

interface ReduxStateProps {
  deviceState: SenseDevicesState;
  isAdmin: boolean;
}

interface ReduxDispatchProps {
  loadDetails: (orgId: string, propertyId: string, deviceId: string) => void;
  changeDeviceActivationStatus: (
    organisation_id: string,
    property_id: string,
    serial: string,
    activation_status: boolean
  ) => void;
}

interface URLParams {
  organisationId: string;
  propertyId: string;
  deviceId: string;
  userId: string;
}

interface State {
  senseDevicePageType: SenseDevicePageType;
}

type Props = RouteComponentProps<URLParams> &
  ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation;

class SenseDeviceDetailsPage extends React.Component<Props, State> {
  state = {
    senseDevicePageType: "property-page" as SenseDevicePageType,
  };

  // Keep this callback so we can unregister the history path listener
  callback: (() => void) | undefined = undefined;

  componentDidMount() {
    if (
      this.props.deviceState?.selectedDevice?.device?.id !==
      this.props.match.params.deviceId
    ) {
      const orgId = this.props.match.params.organisationId;
      const propertyId = this.props.match.params.propertyId;
      const deviceId = this.props.match.params.deviceId;
      this.props.loadDetails(orgId, propertyId, deviceId);
    }
    //Adding listener
    this.handlePathChanged(this.props.location.pathname);
    this.callback = this.props.history.listen((location) =>
      this.handlePathChanged(location.pathname)
    );
  }

  // Stop listening when we unmount
  componentWillUnmount() {
    this.callback?.();
  }

  //Path change handler

  handlePathChanged = (path: string) => {
    if (matchPath<URLParams>(path, PROCHECK_USER_RESULTS_ROUTE)?.params.userId) {
      //User Sense Device route - contigency

      this.setState({ senseDevicePageType: "user-page" });
    } else if (matchPath<URLParams>(path, SENSE_DEVICE_ROUTE)?.params.propertyId) {
      //Normal Sense Device route
      this.setState({ senseDevicePageType: "property-page" });
    }
  };

  //Navigation Handlers
  handleDashboardClicked = () => {
    this.props.history.push(DASHBOARD_ROUTE);
  };

  handlePropertyClicked = () => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    const route = buildPropertyDetailRoute(orgId, propertyId);
    this.props.history.push(route);
  };

  handleUserClicked = () => {
    const userId = this.props.match.params.userId;
    const orgId = this.props.match.params.organisationId;
    const route = buildUserDetailRoute(orgId, userId);
    this.props.history.push(route);
  };

  handleUserTabClicked = () => {
    this.props.history.push(USER_LIST_ROUTE);
  };

  handleDeviceStatusSwitchClicked = () => {
    if (this.props.deviceState.selectedDevice?.device !== undefined) {
      const device = this.props.deviceState.selectedDevice?.device;
      this.props.changeDeviceActivationStatus(
        device.organisation.id,
        device.property.id,
        device.id,
        !(device.deactivated === 0)
      );
    }
  };

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  renderBody() {
    if (this.props.deviceState.selectedDevice !== undefined) {
      return (
        <LoadedSenseDeviceDetailsPage
          //Export dialog props
          errorMessage={this.props.deviceState.error}
          //Loaded details props
          isAdeyAdmin={this.props.isAdmin}
          statusChanging={this.props.deviceState.isActivationStatusChanging}
          device={this.props.deviceState.selectedDevice}
          //Device status
          onDeviceActivationChanged={this.handleDeviceStatusSwitchClicked}
          //Navigation
          pageType={this.state.senseDevicePageType}
          onDashboardClicked={this.handleDashboardClicked}
          onPropertyClicked={this.handlePropertyClicked}
          onUserClicked={this.handleUserClicked}
          onUserTabClicked={this.handleUserTabClicked}
        />
      );
    } else if (this.props.deviceState.error) {
      return <ErrorDisplay title={this.props.deviceState.error} />;
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  deviceState: state.senseDevices,
  isAdmin: state.adeyAdmin.hasAdminAccess,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadDetails: (orgId, propertyId, deviceId) => {
    dispatch(loadSelectedSenseDevice(orgId, propertyId, deviceId));
  },
  changeDeviceActivationStatus: (
    organisation_id,
    property_id,
    serial,
    activation_status
  ) => {
    dispatch(
      changeSenseDeviceActivationStatus(
        organisation_id,
        property_id,
        serial,
        activation_status
      )
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(SenseDeviceDetailsPage)));
