import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { OrganisationPermissions } from "../service/OrganisationPermissionsService";

// Load my organisations
export const LOAD_MY_ORGANISATIONS_TYPE = "LOAD_MY_ORGANISATIONS_TYPE";
export interface LoadMyOrganisationsAction {
  type: typeof LOAD_MY_ORGANISATIONS_TYPE;
}
export const loadMyOrganisations = (): LoadMyOrganisationsAction => ({
  type: LOAD_MY_ORGANISATIONS_TYPE,
});

// Switch organisation
export const SWITCH_ORGANISATION_TYPE = "SWITCH_ORGANISATION_CHANGED";
export interface SwitchOrganisationAction {
  type: typeof SWITCH_ORGANISATION_TYPE;
  currentOrganisation: MemberOrganisation;
}
export const switchOrganisationChanged = (
  organisation: MemberOrganisation
): SwitchOrganisationAction => ({
  type: SWITCH_ORGANISATION_TYPE,
  currentOrganisation: organisation,
});

// Organisation added
export const ORGANISATION_ADDED_TYPE = "ORGANISATION_ADDED_TYPE";
export interface OrganisationAddedAction {
  type: typeof ORGANISATION_ADDED_TYPE;
  organisation: Organisation;
}
export const organisationAdded = (
  organisation: Organisation
): OrganisationAddedAction => ({
  type: ORGANISATION_ADDED_TYPE,
  organisation: organisation,
});

// Active organisation changed
export const ACTIVE_ORGANISATION_CHANGED_TYPE = "ACTIVE_ORGANISATION_CHANGED_TYPE";
export interface ActiveOrganisationChangedAction {
  type: typeof ACTIVE_ORGANISATION_CHANGED_TYPE;
  organisation: MemberOrganisation;
  permissions: OrganisationPermissions;
  availableOrganisations: MemberOrganisation[] | undefined;
}
export const activeOrganisationChanged = (
  organisation: MemberOrganisation,
  permissions: OrganisationPermissions,
  availableOrganisations?: MemberOrganisation[]
): ActiveOrganisationChangedAction => ({
  type: ACTIVE_ORGANISATION_CHANGED_TYPE,
  organisation: organisation,
  permissions: permissions,
  availableOrganisations: availableOrganisations,
});

// Active organisation error
export const ACTIVE_ORGANISATION_ERROR_TYPE = "ACTIVE_ORGANISATION_ERROR_TYPE";
export interface ActiveOrganisationErrorAction {
  type: typeof ACTIVE_ORGANISATION_ERROR_TYPE;
  error: string;
}
export const activeOrganisationError = (
  error: string
): ActiveOrganisationErrorAction => ({
  type: ACTIVE_ORGANISATION_ERROR_TYPE,
  error: error,
});

// Active organisation updated
export const ACTIVE_ORGANISATION_UPDATED_TYPE = "ACTIVE_ORGANISATION_UPDATED_TYPE";
export interface ActiveOrganisationUpdatedAction {
  type: typeof ACTIVE_ORGANISATION_UPDATED_TYPE;
  org: Organisation;
}
export const activeOrganisationUpdated = (
  org: Organisation
): ActiveOrganisationUpdatedAction => ({
  type: ACTIVE_ORGANISATION_UPDATED_TYPE,
  org: org,
});

export const CLEAR_ORGANISATIONS_TYPE = "CLEAR_ORGANISATIONS_TYPE";
export interface ClearOrganisationsAction {
  type: typeof CLEAR_ORGANISATIONS_TYPE;
}
export const clearOrganisations = (): ClearOrganisationsAction => ({
  type: CLEAR_ORGANISATIONS_TYPE,
});

type ActiveOrganisationAction =
  | LoadMyOrganisationsAction
  | SwitchOrganisationAction
  | OrganisationAddedAction
  | ActiveOrganisationChangedAction
  | ActiveOrganisationErrorAction
  | ActiveOrganisationUpdatedAction
  | ClearOrganisationsAction;

export default ActiveOrganisationAction;
