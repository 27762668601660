import { AxiosInstance } from "axios";
import SystemMessage, {
  SystemMessageType,
} from "../../pages/auth/SystemMessagesDialogs/model/SystemMessage";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import SystemMessageRepository from "./SystemMessageRepository";

class RestSystemMessageRepository implements SystemMessageRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }
  getSystemMessages = (type?: SystemMessageType): Promise<SystemMessage[]> => {
    const params = new URLSearchParams();

    if (type) {
      params.append("type", type);
    }

    const url = "/system/messages";

    return this.axios
      .get<DataWrapper<SystemMessage[]>>(url, { params: params })
      .then(unwrapResponse);
  };
  getActiveSystemMessages = (type?: SystemMessageType): Promise<SystemMessage[]> => {
    const params = new URLSearchParams();

    if (type) {
      params.append("type", type);
    }

    const url = "/system/messages/active";

    return this.axios
      .get<DataWrapper<SystemMessage[]>>(url, { params: params })
      .then(unwrapResponse);
  };
}

export default RestSystemMessageRepository;
