import { Box, Chip, TableCell, TableRow, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import AdminRemoveUserButton from "../../../../pages/admin/AdminOrganisationDetailsPage/components/AdminRemoveUserButton";
import displayNameForRole from "../../../../pages/users/detail/utils/displayNameForRole";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import UserDetails from "../../../../rest/user/model/UserDetails";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../theme/dimensions";

interface Props extends WithT {
  key: string;
  org: MemberOrganisation;

  loading: boolean;
  user?: UserDetails;
  userRemoved?: boolean;
  roleName?: string;

  onOrgClicked?: (org: MemberOrganisation) => void;
  onRemoveClicked?: (user: UserDetails, org: Organisation) => void;
}

const OrgAndRoleTableRow = (props: Props) => {
  const style = {
    cursor: props.onOrgClicked !== undefined ? "pointer" : undefined,
  };

  const handleClick = () => {
    props.onOrgClicked?.(props.org);
  };

  const smallCellStyle = { width: SMALL_SPACING };

  return (
    <TableRow
      style={style}
      hover={props.onOrgClicked !== undefined}
      onClick={handleClick}
    >
      <TableCell>
        <Box display="flex" flexDirection="row">
          {props.org.is_agent_for?.id ? (
            <Chip
              size="small"
              color="primary"
              label="ADEY Agent"
              style={{ marginRight: DEFAULT_SPACING }}
            />
          ) : null}
          <Typography>{props.org.name}</Typography>
        </Box>
      </TableCell>

      <TableCell>
        <Typography>{props.org.phone_number || "-"}</Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>
          {props.roleName
            ? props.roleName
            : displayNameForRole(props.org.role.name, props.t)}
        </Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        {props.onRemoveClicked !== undefined &&
        props.org.role.name !== "agent" &&
        props.org.role.name !== "owner" &&
        props.org.role.name !== "contractor" ? (
          <AdminRemoveUserButton
            loading={props.loading}
            user={props.user!}
            organisation={props.org}
            removeUserConfirmed={props.onRemoveClicked}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(OrgAndRoleTableRow);
