import { PropertyAddress } from "../../../../rest/properties/model/Property";
import Address from "../model/Address";

const getAddress = (property: PropertyAddress): Address => {
  return {
    line1: property.address_line_1,
    line2: property.address_line_2,
    line3: property.address_line_3,
    city: property.address_city,
    postcode: property.address_postcode,
    country: property.address_country.code,
  };
};

export default getAddress;
