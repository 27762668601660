import React from "react";
import PrimaryTextField, { PrimaryTextFieldProps } from "./PrimaryTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const cursorStyle = {
  cursor: "pointer",
};

// omit the types that are set because it's a password field
type PasswordTextFieldProps = Omit<
  PrimaryTextFieldProps,
  "startIcon" | "endIcon" | "type"
>;

interface PasswordTextFieldState {
  isPasswordVisible: boolean;
}

// A component that manages it's password visibility state
class PasswordTextField extends React.Component<
  PasswordTextFieldProps,
  PasswordTextFieldState
> {
  state = {
    isPasswordVisible: false,
  };

  hidePasswordHandler = () => {
    this.setState({
      isPasswordVisible: false,
    });
  };

  showPasswordHandler = () => {
    this.setState({
      isPasswordVisible: true,
    });
  };

  render() {
    return (
      <PrimaryTextField
        {...this.props}
        type={this.state.isPasswordVisible ? "text" : "password"}
        endIcon={
          this.state.isPasswordVisible ? (
            <FontAwesomeIcon
              icon={faEye}
              onClick={this.hidePasswordHandler}
              style={cursorStyle}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              onClick={this.showPasswordHandler}
              style={cursorStyle}
            />
          )
        }
      />
    );
  }
}

export default PasswordTextField;
