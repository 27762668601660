import { TFunction } from "i18next";
import { StatusReason } from "../../../../rest/properties/model/Property";
import RAGStatus from "./RAGStatus";

export const titleForProCheckStatus = (status: StatusReason, t: TFunction) => {
  switch (status) {
    case "test_failed":
      return t("statusMessage.testFailed");

    case "test_missing":
      return t("statusMessage.testMissing");

    case "test_overdue":
      return t("statusMessage.testOverdue");

    case "test_passed":
      return t("statusMessage.testPassed");

    default:
      return "-";
  }
};

export const ragForProCheckStatus = (status: StatusReason): RAGStatus => {
  switch (status) {
    case "test_failed":
      return "red";

    case "test_missing":
      return "red";

    case "test_overdue":
      return "red";

    case "test_passed":
      return "green";

    default:
      return "red";
  }
};

export const colorForProCheckStatus = (status: StatusReason) => {
  switch (status) {
    case "test_failed":
      return "#FF0000";

    case "test_missing":
      return "#FF0000";

    case "test_overdue":
      return "#FF0000";

    case "test_passed":
      return "#069247";

    default:
      return "#FF0000";
  }
};
