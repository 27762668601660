import { AxiosInstance } from "axios";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import Role from "./model/Role";
import RoleRepository from "./RoleRepository";

class RestRoleRepository implements RoleRepository {
  /// Roles aren't going to change regularly at all.
  /// So it's safe to keep cached.
  private _cachedRoles: Role[] | undefined;

  constructor(private axios: AxiosInstance) {}

  getAllRoles = async (admin?: boolean): Promise<Role[]> => {
    if (this._cachedRoles) {
      return this._cachedRoles;
    }

    const isAdmin = admin ? "/admin" : "";

    return this.axios
      .get<DataWrapper<Role[]>>(`${isAdmin}/roles`)
      .then(unwrapResponse)
      .then(this.addToCache);
  };

  private addToCache = (response: Role[]): Role[] => {
    this._cachedRoles = response;
    return response;
  };
}

export default RestRoleRepository;
