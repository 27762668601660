import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import Property from "../../../../../rest/properties/model/Property";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import { updateContractor } from "../../redux/PropertyDetailActions";
import EditableField from "../EditableField/EditableField";

interface ExternalProps {
  organisation: Organisation;
  property: Property;
}

interface ReduxStateProps {
  currentContractor: { id: string; name: string } | undefined;
  canEditContractor: boolean;
  availableContractors: Organisation[];
  isLoading: boolean;
  error: string | undefined;
}

interface ReduxDispatchProps {
  updateContractor: (id: string | undefined) => void;
}

type Props = ReduxStateProps & WithT & ReduxDispatchProps & ExternalProps;

interface State {
  isEditing: boolean;
}

class PropertyContractorPanel extends React.Component<Props, State> {
  state = { isEditing: false };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading && this.props.error === undefined) {
      this.setState({ isEditing: false });
    }
  }

  render() {
    const label = this.props.t("propertyContractorPanel.title");
    const noContractor = this.props.t("propertyContractorPanel.noContractor");

    const text = this.state.isEditing
      ? this.props.currentContractor?.id || noContractor
      : this.props.currentContractor?.name || noContractor;

    const handleSaved = (value: string) => {
      if (value === noContractor) {
        this.props.updateContractor(undefined);
      } else {
        this.props.updateContractor(value);
      }
    };

    return (
      <BorderedBox>
        <EditableField
          label={label}
          text={text}
          isEditing={this.state.isEditing}
          hoverEnabled={this.props.canEditContractor}
          onEditClicked={() => this.setState({ isEditing: true })}
          onCancelClicked={() => this.setState({ isEditing: false })}
          onSaveClicked={handleSaved}
          loading={this.props.isLoading}
          editorFieldProps={{
            selectOptions: this.createOptions(noContractor),
          }}
        />
      </BorderedBox>
    );
  }

  createOptions = (noContractorLabel: string) => {
    return [
      // Add a label for no contractor
      { labelId: noContractorLabel, value: noContractorLabel },

      // Then map all the available contractors to their own options.
      ...this.props.availableContractors.map((org) => ({
        labelId: org.name,
        value: org.id,
      })),
    ];
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  currentContractor: state.propertyDetail.property?.contractor,
  canEditContractor:
    state.activeOrganisation.permissions.canAssignContractorsToProperties,
  availableContractors: state.propertyDetail.availableContractors,
  isLoading: state.propertyDetail.isUpdatingContractor,
  error: state.propertyDetail.error,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: ExternalProps
): ReduxDispatchProps => ({
  updateContractor: (id) =>
    dispatch(updateContractor(props.organisation, props.property, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PropertyContractorPanel));
