import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import TableHeaderCell from "./components/TableHeaderCell";

const smallCellWidth = "15vw";
const actionCellStyle = "40px";

interface ExternalProps {
  //Table Render
  itemRows: JSX.Element[];
  headerKeys: string[];
  headerTitle: string;
  withAction?: boolean;
}

export default class TableList extends React.Component<ExternalProps> {
  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell textId={this.props.headerTitle} />
            {this.props.headerKeys.map((key) => (
              <TableHeaderCell key={key} textId={key} style={{ width: smallCellWidth }} />
            ))}
            {this.props.withAction ? (
              <TableCell style={{ width: actionCellStyle }} />
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>{this.props.itemRows}</TableBody>
      </Table>
    );
  }
}
