import { DashboardMetrics } from "../../DashboardLayout/redux/DashboardState";
import { RAGCardType } from "../components/RAGCard";

const getRAGCardTotal = (type: RAGCardType, metrics: DashboardMetrics) => {
  switch (type) {
    case "total":
      return metrics.totalProperties;

    case "red":
      return metrics.redCount;

    case "amber":
      return metrics.amberCount;

    case "green":
      return metrics.greenCount;
  }
};

export default getRAGCardTotal;
