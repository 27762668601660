import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import AdeyLogo from "../../../assets/AdeyLogo/AdeyLogo";
import { DEFAULT_SPACING } from "../../../theme/dimensions";
import ColoredText from "../../ColoredText";

const logoHeight = 22;

interface Props {
  onClick: () => void;
  adminMode: boolean;
}

const HomeButton = (props: Props) => {
  const adminText = props.adminMode ? (
    <ColoredText textColor="white" variant="button" align="center">
      <strong>
        <Trans>homeButton.adminText</Trans>
      </strong>
    </ColoredText>
  ) : undefined;

  return (
    <Box paddingX={DEFAULT_SPACING} display="flex" flexDirection="column">
      <ButtonBase onClick={props.onClick}>
        <AdeyLogo height={logoHeight} fill color="white" />
      </ButtonBase>

      {adminText}
    </Box>
  );
};

export default HomeButton;
