import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import RootState from "../../redux/RootState";
import CenteredAuthCard from "../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../ui/components/PrimaryButton";
import SecondaryButton from "../../ui/components/SecondaryButton";
import { LARGE_SPACING, SMALL_SPACING } from "../../ui/theme/dimensions";
import { homeZoneUnsubscribe } from "./redux/HomeZoneAction";
import { Alert } from "@material-ui/lab";
import { DASHBOARD_ROUTE } from "../../routes/routes";

interface URLParams {
  propertyId: string;
}

interface ReduxStateProps {
  unsubscribing: boolean | undefined;
  unsubscribed: boolean | undefined;
  error: string | undefined;
}

interface ReduxDispatchProps {
  homezoneUnsubscribe: (token: string) => void;
}

type Props = WithTranslation &
  RouteComponentProps<URLParams> &
  ReduxDispatchProps &
  ReduxStateProps;

const HomezoneUnsubscribePage = (props: Props) => {
  //Translated Strings
  const title = props.t("homezoneUnsubscribePage.title");
  const unsubscribeButton = props.t("homezoneUnsubscribePage.unsubscribeButton");
  const description = props.t("homezoneUnsubscribePage.description");
  const backButton = props.t("homezoneUnsubscribePage.backButton");
  const success = props.t("homezoneUnsubscribePage.success");
  const error = props.t("homezoneUnsubscribePage.unsubscribeError");

  //Handlers
  const handleUnsubscribeClicked = () => {
    const token = props.match.params.propertyId;
    props.homezoneUnsubscribe(token);
  };

  const handleBackClicked = () => {
    props.history.push(DASHBOARD_ROUTE);
  };

  return (
    <CenteredAuthCard title={title}>
      <Grid container direction="column" spacing={SMALL_SPACING} alignItems="center">
        {!props.unsubscribed && !props.error && (
          <Grid item style={{ margin: SMALL_SPACING }}>
            <Typography>{description}</Typography>
          </Grid>
        )}
        {!props.unsubscribed && !props.error && (
          <Grid item>
            <PrimaryButton
              fullWidth
              label={unsubscribeButton}
              isLoading={props.unsubscribing}
              onClick={handleUnsubscribeClicked}
            />
          </Grid>
        )}

        {(props.unsubscribed || props.error) && (
          <Grid item style={{ width: "100%", marginTop: LARGE_SPACING }}>
            <Alert severity={props.unsubscribed ? "success" : "info"}>
              {props.error ? error : success}
            </Alert>
          </Grid>
        )}

        <Grid item>
          <SecondaryButton label={backButton} onClick={handleBackClicked} />
        </Grid>
      </Grid>
    </CenteredAuthCard>
  );
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    ...state.homezone,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    homezoneUnsubscribe: (token: string) => dispatch(homeZoneUnsubscribe(token)),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HomezoneUnsubscribePage))
);
