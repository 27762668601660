import Organisation from "../../../../rest/organisation/model/Organisation";
import CreateOrganisationForm from "../../../auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";

// Create organisation submit
export const CREATE_ORGANISATION_SUBMIT_TYPE = "CREATE_ORGANISATION_SUBMIT_TYPE";
export interface CreateOrganisationSubmitAction {
  type: typeof CREATE_ORGANISATION_SUBMIT_TYPE;
  form: CreateOrganisationForm;
}
export const createOrganisationSubmit = (
  form: CreateOrganisationForm
): CreateOrganisationSubmitAction => ({
  type: CREATE_ORGANISATION_SUBMIT_TYPE,
  form: form,
});

// Create organisation success
export const CREATE_ORGANISATION_SUCCESS_TYPE = "CREATE_ORGANISATION_SUCCESS_TYPE";
export interface CreateOrganisationSuccessAction {
  type: typeof CREATE_ORGANISATION_SUCCESS_TYPE;
  organisation: Organisation;
}
export const createOrganisationSuccess = (
  organisation: Organisation
): CreateOrganisationSuccessAction => ({
  type: CREATE_ORGANISATION_SUCCESS_TYPE,
  organisation: organisation,
});

// Create organisation error
export const CREATE_ORGANISATION_ERROR_TYPE = "CREATE_ORGANISATION_ERROR_TYPE";
export interface CreateOrganisationErrorAction {
  type: typeof CREATE_ORGANISATION_ERROR_TYPE;
  error: string;
}
export const createOrganisationError = (
  error: string
): CreateOrganisationErrorAction => ({
  type: CREATE_ORGANISATION_ERROR_TYPE,
  error: error,
});

// Union type
type CreateOrganisationAction =
  | CreateOrganisationSubmitAction
  | CreateOrganisationSuccessAction
  | CreateOrganisationErrorAction;

export default CreateOrganisationAction;
