import { Box, Typography, Divider } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import parseSenseProduct from "../../../../../rest/sense/helpers/parseSenseProduct";
import SenseDevice from "../../../../../rest/sense/model/SenseDevice";
import DeviceLogo from "../../../../../ui/assets/DeviceImages/DeviceLogo";

import BatteryPill from "../../../../../ui/components/BatteryPill";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import ColoredText from "../../../../../ui/components/ColoredText";
import LabelledText from "../../../../../ui/components/LabelledText";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import formatDayTime from "../../../../../utils/formatDayTime";
import RAGStatusPill from "../../../../properties/PropertyDetailPage/components/RAGStatusPill";
import getDeviceCardStatus from "../../../helpers/getDeviceCardStatus";

interface ExternalProps {
  device: SenseDevice;
  onMoreInfoClicked?: () => void;
  isAdmin?: boolean;
}

type Props = WithTranslation & ExternalProps;

const SenseDeviceResultsTile = (props: Props) => {
  const productTitleLabel = props.t("senseDeviceInfoCard.product");
  const latestReadingLabel = props.t("senseDeviceInfoCard.lastReading");
  const serialNumberLabel = props.t("senseDeviceInfoCard.serialNumber");
  const moreInfoButtonLabel = props.t("senseDeviceInfoCard.moreInfoButton");
  const statusLabel = props.t("senseDeviceInfoCard.status");

  //Product type
  const productType = parseSenseProduct(props.device.serial_number);

  //Date
  const lastReadingDate = formatDayTime(props.device.last_reading_date);

  //Device Rag
  const deviceRag = getDeviceCardStatus(props.device.notifications, props.t);

  return (
    <BorderedBox display="flex" flexDirection="column" height="100%">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
            <strong>{productTitleLabel}</strong>
          </ColoredText>

          <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
            <strong>{statusLabel}</strong>
          </ColoredText>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center">
            <DeviceLogo deviceType={productType} height={60} />
            <Typography variant="h5">
              {productType
                ? props.t(`senseDeviceNames.${productType}`)
                : "Unknown Device"}
            </Typography>
          </Box>

          <RAGStatusPill status={deviceRag.ragStatus} text={deviceRag.message} />
        </Box>
        <Divider
          style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: EXTRA_LARGE_SPACING }}
        />
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <LabelledText
          label={latestReadingLabel}
          text={props.device.last_reading_date ? `${lastReadingDate.date}` : "-"}
        />
        <LabelledText
          textAlign="right"
          label={serialNumberLabel}
          text={props.device.serial_number}
        />
      </Box>

      <Divider
        style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: EXTRA_LARGE_SPACING }}
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        minHeight="50px"
      >
        <Box display={"flex"} flexDirection={"row"} maxHeight={"36px"}>
          <OutlineButton label={moreInfoButtonLabel} onClick={props.onMoreInfoClicked} />
          <Box marginRight={SMALL_SPACING} />
        </Box>

        <BatteryPill batteryStatus={props.device.battery_level} />
      </Box>
    </BorderedBox>
  );
};

export default withTranslation()(SenseDeviceResultsTile);
