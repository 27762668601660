import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PlusIcon from "../../../../ui/assets/PlusIcon/PlusIcon";
import OutlineButton from "../../../../ui/components/OutlineButton";
import { EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithTranslation {
  onClick?: () => void;
}

const AddPropertyButton = (props: Props) => {
  const label = props.t("addPropertyButton.label");

  return (
    <OutlineButton
      label={label}
      endIcon={<PlusIcon />}
      marginRight={EXTRA_LARGE_SPACING}
      onClick={props.onClick}
      filled
    />
  );
};

export default withTranslation()(AddPropertyButton);
