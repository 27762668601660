// Log in

import SegmentationQuestion from "../../../../rest/user/model/SegmentationQuestion";
import { LoginStatus } from "./LoginState";

export const LOG_IN_START_TYPE = "LOG_IN_START_TYPE";

export interface LogInStartAction {
  type: typeof LOG_IN_START_TYPE;
  email: string;
  password: string;
}

export const logInAction = (email: string, password: string): LogInStartAction => {
  return {
    type: LOG_IN_START_TYPE,
    email: email,
    password: password,
  };
};

// Log in error

export const LOG_IN_ERROR_TYPE = "LOG_IN_ERROR_TYPE";

export interface LogInErrorAction {
  type: typeof LOG_IN_ERROR_TYPE;
  error: string;
}

export const logInError = (error: string): LogInErrorAction => {
  return {
    type: LOG_IN_ERROR_TYPE,
    error: error,
  };
};

// Log out

export const LOG_OUT_TYPE = "LOG_OUT_TYPE";

export interface LogOutAction {
  type: typeof LOG_OUT_TYPE;
}

export const logOut = (): LogOutAction => {
  return {
    type: LOG_OUT_TYPE,
  };
};

// Check login status

export const CHECK_LOGIN_STATUS_TYPE = "CHECK_LOGIN_STATUS_TYPE";

export interface CheckLoginStatusAction {
  type: typeof CHECK_LOGIN_STATUS_TYPE;
}

export const checkLoginStatus = (): CheckLoginStatusAction => {
  return {
    type: CHECK_LOGIN_STATUS_TYPE,
  };
};

// Login state changed

export const LOGIN_STATUS_CHANGED_TYPE = "LOGIN_STATUS_CHANGED_TYPE";

export interface LoginStateChangedAction {
  type: typeof LOGIN_STATUS_CHANGED_TYPE;
  loginStatus: LoginStatus;
  currentUserId: string | undefined;
}

export const loginStateChanged = (
  status: LoginStatus,
  currentUserId?: string
): LoginStateChangedAction => {
  return {
    type: LOGIN_STATUS_CHANGED_TYPE,
    loginStatus: status,
    currentUserId: currentUserId,
  };
};

// Password Reset Required

export const PASSWORD_RESET_REQUIRED_TYPE = "PASSWORD_RESET_REQUIRED_TYPE";

export interface PasswordResetRequiredAction {
  type: typeof PASSWORD_RESET_REQUIRED_TYPE;
  proClubUser?: boolean;
}

//This is in lieu of us receiving a specific response for the weak password - we can only rely on the existance of ProClub user for now
export const setPasswordResetRequired = (
  proClubUser?: boolean
): PasswordResetRequiredAction => {
  return {
    type: PASSWORD_RESET_REQUIRED_TYPE,
    proClubUser: proClubUser,
  };
};

// Load Segmentation Questions

export const LOAD_SEGMENTATION_QUESTIONS_TYPE = "LOAD_SEGMENTATION_QUESTIONS_TYPE";

export interface LoadSegmentationQuestionsAction {
  type: typeof LOAD_SEGMENTATION_QUESTIONS_TYPE;
}

export const loadSegmentationQuestions = (): LoadSegmentationQuestionsAction => {
  return {
    type: LOAD_SEGMENTATION_QUESTIONS_TYPE,
  };
};

// Segmentation Questions Loaded

export const SEGMENTATION_QUESTIONS_LOADED_TYPE = "SEGMENTATION_QUESTIONS_LOADED_TYPE";

export interface SegmentationQuestionsLoadedAction {
  type: typeof SEGMENTATION_QUESTIONS_LOADED_TYPE;
  segmentationQuestions: SegmentationQuestion[];
}

export const segmentationQuestionsLoaded = (
  segmentationQuestions: SegmentationQuestion[]
): SegmentationQuestionsLoadedAction => {
  return {
    type: SEGMENTATION_QUESTIONS_LOADED_TYPE,
    segmentationQuestions: segmentationQuestions,
  };
};

// Union type

export type LogInAction =
  | LogInStartAction
  | LogInErrorAction
  | LogOutAction
  | CheckLoginStatusAction
  | LoginStateChangedAction
  | PasswordResetRequiredAction
  | LoadSegmentationQuestionsAction
  | SegmentationQuestionsLoadedAction;

export default LogInAction;
