import { put, select, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import Role from "../../../../rest/roles/model/Role";
import Organisation from "../../../../rest/organisation/model/Organisation";
import {
  createOrgError,
  createOrgSuccess,
  SubmitOwnerInfoAction,
  SUBMIT_OWNER_INFO_TYPE,
} from "./AdminCreateOrganisationAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import buildOrgFormFromCode from "../../AdminOrganisationDetailsPage/utils/buildOrgFormFromCode";
import AdminInviteOrganisationErrorHandler from "../errors/AdminInviteOrganisationErrorHandler";

function* adminCreateOrganisationSaga() {
  yield takeLatest(SUBMIT_OWNER_INFO_TYPE, submitOrganisation);
}

function* submitOrganisation(action: SubmitOwnerInfoAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");
    const rootState: RootState = yield select();
    const orgDetails = rootState.adminCreateOrganisation.organisationDetails;
    if (!orgDetails) {
      throw new Error("Organisation details not set");
    }

    const orgForm = buildOrgFormFromCode(orgDetails, rootState.countryList.countryList);

    const createdOrganisation: Organisation = yield adminRepo.createOrganisation(orgForm);
    if (!createdOrganisation) {
      throw new Error("Organisation details not set");
    }

    const roles: Role[] = yield adminRepo.getRoles();

    const ownerRole = roles.filter((role) => role.name === "owner")[0];

    yield adminRepo.inviteUserToOrganisation(
      action.email,
      createdOrganisation.id,
      ownerRole.id
    );

    yield put(createOrgSuccess(createdOrganisation));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "organisationOwnerAdminForm.error",
      AdminInviteOrganisationErrorHandler
    );
    yield put(createOrgError(message));
  }
}

export default adminCreateOrganisationSaga;
