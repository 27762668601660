import { Box, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import UserDetails from "../../../rest/user/model/UserDetails";
import { buildPlatformUserDetailRoute } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import SearchBar from "../../../ui/components/SearchBar";
import Spinner from "../../../ui/components/Spinner";
import TableHeader from "../../../ui/components/TableList/components/TableHeader";
import UserTable from "../../../ui/components/UserTable/UserTable";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../ui/theme/dimensions";
import TablePageIndicator from "../../properties/PropertyListPage/components/TablePageIndicator";
import { loadPlatformUsers, searchTextChanged } from "./redux/PlatformUserListAction";
import PlatformUserListState from "./redux/PlatformUserListState";

type ReduxStateProps = PlatformUserListState;

interface ReduxDispatchProps {
  onPageChanged: (page: number) => void;
  searchTextChanged: (text: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithT & RouteComponentProps;

class PlatformUserListPage extends React.Component<Props> {
  render() {
    const searchBarPlaceholder = this.props.t("platformUsersList.searchBarPlaceholder");

    return (
      <DashboardLayout hideRagStatus>
        <Box padding={DEFAULT_SPACING} display="flex" flexDirection="column">
          <SearchBar
            placeholder={searchBarPlaceholder}
            value={this.props.searchText}
            onChange={this.props.searchTextChanged}
          />

          <Box height={2 * EXTRA_LARGE_SPACING} />

          {this.renderTable()}

          <Box height={2 * EXTRA_LARGE_SPACING} />

          <TablePageIndicator
            selectedPage={this.props.currentPage}
            maxPages={this.props.maxPage}
            onPageNumberChanged={this.props.onPageChanged}
          />
        </Box>
      </DashboardLayout>
    );
  }

  renderTable = () => {
    if (this.props.users !== undefined && this.props.users.length > 0) {
      return (
        <Box>
          <TableHeader text="platformUsersList.tableTitle" />
          <UserTable users={this.props.users} onUserClicked={this.handleUserClicked} />
        </Box>
      );
    } else if (this.props.users !== undefined && this.props.users.length === 0) {
      return (
        <Box padding={LARGE_SPACING}>
          <Typography variant="h2" color="textPrimary" align="center">
            <Trans>platformUsersList.emptyMessage</Trans>
          </Typography>
        </Box>
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  };

  // Handlers

  handleUserClicked = (user: UserDetails) => {
    const route = buildPlatformUserDetailRoute(user.id);
    this.props.history.push(route);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => state.platformUserList;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onPageChanged: (number) => dispatch(loadPlatformUsers(number)),
  searchTextChanged: (text) => dispatch(searchTextChanged(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PlatformUserListPage)));
