import { loadMyOrganisations } from "../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import { checkLoginStatus, logInError } from "../../pages/auth/Login/redux/LoginAction";
import RootState from "../../redux/RootState";
import { Store } from "redux";
import { createRestError } from "./RestError";

let rootStore: Store<RootState> | undefined;

/**
 * Use this to automatically start listening to error events,
 * and dispatch to the store.
 */
export const initializeErrorInterceptor = (store: Store<RootState>) => {
  rootStore = store;
};

const axiosErrorInterceptor = (e: any | undefined) => {
  if (e?.isAxiosError) {
    // Parse the response as a RestError
    const restError = createRestError(e);

    if (restError.statusCode === 401) {
      rootStore?.dispatch(logInError("error.status401"));
    }

    // If we get back a 403, refresh all of our orgs
    if (restError.statusCode === 403) {
      rootStore?.dispatch(loadMyOrganisations());
    }

    // If we get back a 422 on a GET, do the auth
    // flow because we may not have an org.
    // And exclude the /me endpoint because it's called
    // when checking login status
    if (
      restError.statusCode === 422 &&
      restError.request?.method?.toLowerCase() === "get" &&
      restError.request?.endpoint !== "/me"
    ) {
      rootStore?.dispatch(checkLoginStatus());
    }
  }

  // Rethrow so it doesn't treat
  // the request as successful
  throw e;
};

export default axiosErrorInterceptor;
