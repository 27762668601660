import ExportItemModel from "../models/ExportItemModel";

//Initialise media item download
export const INIT_ITEM_EXPORT_TYPE = "INIT_ITEM_EXPORT";

export interface InitItemExportAction {
  type: typeof INIT_ITEM_EXPORT_TYPE;
  org_id: string;
  properties_ids?: string[];
  startDate: Date;
  endDate: Date;
}

export const initItemExport = (
  org_id: string,
  startDate: Date,
  endDate: Date,
  properties_ids?: string[]
): InitItemExportAction => {
  return {
    type: INIT_ITEM_EXPORT_TYPE,
    org_id: org_id,
    properties_ids: properties_ids,
    startDate: startDate,
    endDate: endDate,
  };
};

//Init PDF report generation
export const INIT_REPORT_EXPORT_TYPE = "INIT_REPORT_EXPORT";

export interface InitReportExportAction {
  type: typeof INIT_REPORT_EXPORT_TYPE;
  org_id: string;
  result_ids?: string[];
  privacy?: boolean;
  languageHeader?: string;
}

export const initReportExport = (
  org_id: string,
  result_ids?: string[],
  privacy?: boolean,
  languageHeader?: string
): InitReportExportAction => {
  return {
    type: INIT_REPORT_EXPORT_TYPE,
    org_id: org_id,
    result_ids: result_ids,
    privacy: privacy,
    languageHeader: languageHeader,
  };
};

//Initialise global export
export const INIT_GLOBAL_EXPORT_TYPE = "INIT_GLOBAL_EXPORT";

export interface InitGlobalExportAction {
  type: typeof INIT_GLOBAL_EXPORT_TYPE;
}

export const initGlobalExport = (): InitGlobalExportAction => {
  return {
    type: INIT_GLOBAL_EXPORT_TYPE,
  };
};

//Export initialisation error
export const EXPORT_INIT_ERROR_TYPE = "EXPORT_INIT_ERROR";

export interface ExportInitErrorAction {
  type: typeof EXPORT_INIT_ERROR_TYPE;
  error: string;
}

export const exportInitError = (error: string): ExportInitErrorAction => {
  return {
    type: EXPORT_INIT_ERROR_TYPE,
    error: error,
  };
};

//Add exporting item action
export const ADD_EXPORTING_ITEM_TYPE = "ADD_EXPORTING_ITEM";

export interface AddExportingItemAction {
  type: typeof ADD_EXPORTING_ITEM_TYPE;
  item: ExportItemModel;
}

export const addExportingItem = (item: ExportItemModel): AddExportingItemAction => {
  return {
    type: ADD_EXPORTING_ITEM_TYPE,
    item: item,
  };
};

//Init item processing action
//Accepts 'id' separately for edge cases, where the id needs to be replaced in the future
export const INIT_ITEM_PPROCESSING_TYPE = "INIT_ITEM_PPROCESSING";

export interface InitItemProcessingAction {
  type: typeof INIT_ITEM_PPROCESSING_TYPE;
  item: ExportItemModel;
  export_id?: string;
}

export const initItemProcessing = (
  item: ExportItemModel,
  export_id?: string
): InitItemProcessingAction => {
  return {
    type: INIT_ITEM_PPROCESSING_TYPE,
    item: item,
    export_id: export_id,
  };
};

//Update exporting item action
//Accepts 'id' separately for edge cases, where the id needs to be replaced in the future

export const UPDATE_EXPORTING_ITEM_TYPE = "UPDATE_EXPORTING_ITEM";

export interface UpdateExportingItemAction {
  type: typeof UPDATE_EXPORTING_ITEM_TYPE;
  item: ExportItemModel;
  export_id?: string;
}

export const updateExportingItem = (
  item: ExportItemModel,
  export_id?: string
): UpdateExportingItemAction => {
  return {
    type: UPDATE_EXPORTING_ITEM_TYPE,
    item: item,
    export_id: export_id,
  };
};

//Cancel exporting item action
export const CANCEL_EXPORTING_ITEM_TYPE = "CANCEL_EXPORTING_ITEM";

export interface CancelExportingItemAction {
  type: typeof CANCEL_EXPORTING_ITEM_TYPE;
  item: ExportItemModel;
}

export const cancelExportingItem = (item: ExportItemModel): CancelExportingItemAction => {
  return {
    type: CANCEL_EXPORTING_ITEM_TYPE,
    item: item,
  };
};

//Remove resolved exporting item action
export const REMOVE_EXPORTING_ITEM_SUCCESS_TYPE = "REMOVE_EXPORTING_ITEM_SUCCESS";

export interface RemoveExportingSuccessItemAction {
  type: typeof REMOVE_EXPORTING_ITEM_SUCCESS_TYPE;
  item: ExportItemModel;
}

export const removeExportingSuccessItem = (
  item: ExportItemModel
): RemoveExportingSuccessItemAction => {
  return {
    type: REMOVE_EXPORTING_ITEM_SUCCESS_TYPE,
    item: item,
  };
};

//Remove failed exporting item action
export const REMOVE_EXPORTING_ITEM_FAILURE_TYPE = "REMOVE_EXPORTING_ITEM_FAILURE";

export interface RemoveExportingFailureItemAction {
  type: typeof REMOVE_EXPORTING_ITEM_FAILURE_TYPE;
  item: ExportItemModel;
}

export const removeExportingFailureItem = (
  item: ExportItemModel
): RemoveExportingFailureItemAction => {
  return {
    type: REMOVE_EXPORTING_ITEM_FAILURE_TYPE,
    item: item,
  };
};

type ExportBarAction =
  | InitItemExportAction
  | ExportInitErrorAction
  | AddExportingItemAction
  | InitItemProcessingAction
  | RemoveExportingSuccessItemAction
  | RemoveExportingFailureItemAction
  | UpdateExportingItemAction
  | CancelExportingItemAction
  | InitReportExportAction;

export default ExportBarAction;
