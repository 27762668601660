import { Box, Typography } from "@material-ui/core";
import { Cancel, WarningOutlined } from "@material-ui/icons";
import { DEFAULT_SPACING, LARGE_SPACING, SMALL_SPACING } from "../../theme/dimensions";
import PrimaryButton from "../PrimaryButton";
import { Trans, useTranslation } from "react-i18next";
import TEntityToMove from "../../../pages/admin/AdminMoveEntity/types/TEntityToMove";
import TMoveDestination from "../../../pages/admin/AdminMoveEntity/types/TMoveDestination";
import { BACKGROUND_ACCENT_COLOR } from "../../theme/createMaterialTheme";
import { IMoveActionResult } from "../../../pages/admin/AdminMoveEntity/services/AdminMoveEntityService";

interface IProps<T> {
  entityType: TEntityToMove;
  getItemName: (item: T) => string;
  destination: TMoveDestination;
  moveResults?: IMoveActionResult;
  items: T[];
  loading?: boolean;
  handleClosePressed: () => void;
}

const MoveEntityErrorPanel = <T extends unknown>(props: IProps<T>) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const closeLabel = t("moveEntityErrorPanel.closeButton");

  //Helpers
  const itemNames = props.items.map((item) => props.getItemName(item));

  const getDescription = () => {
    return (
      <Typography align="center">
        <Trans
          t={t}
          i18nKey={"moveEntityErrorPanel.description"}
          values={{ items: itemNames, destination: props.destination.description }}
        />
      </Typography>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} alignItems="center">
      <WarningOutlined
        style={{
          fontSize: "100px",
          marginTop: LARGE_SPACING,
          marginBottom: LARGE_SPACING,
          color: BACKGROUND_ACCENT_COLOR,
        }}
      />

      {getDescription()}
      <Box marginTop={DEFAULT_SPACING} />
      <PrimaryButton
        disabled={props.loading}
        isLoading={props.loading}
        label={closeLabel}
        endIcon={<Cancel />}
        onClick={props.handleClosePressed}
        fullWidth
      />
      <Box marginTop={SMALL_SPACING} />
    </Box>
  );
};

export default MoveEntityErrorPanel;
