import CreateOrganisationAction from "./CreateOrganisationAction";
import CreateOrganisationState from "./CreateOrganisationState";

const initialState: CreateOrganisationState = {
  success: false,
  isLoading: false,
  error: undefined,
};

const createOrganisationReducer = (
  state = initialState,
  action: CreateOrganisationAction
): CreateOrganisationState => {
  switch (action.type) {
    case "CREATE_ORGANISATION_SUBMIT_TYPE":
      return {
        success: false,
        isLoading: true,
        error: undefined,
      };

    case "CREATE_ORGANISATION_SUCCESS_TYPE":
      return {
        success: true,
        isLoading: false,
        error: undefined,
      };

    case "CREATE_ORGANISATION_ERROR_TYPE":
      return {
        success: false,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default createOrganisationReducer;
