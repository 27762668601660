import globalErrorHandler from "./globalErrorHandler";

// A method that handles errors.
// getErrorMessage can be given multiple ErrorHandlers
// which supersede it's 'global' error handling.
export type ErrorHandler = (e: any) => string | undefined;

const getErrorMessage = (
  e: any,
  fallbackError: string | undefined,
  ...errorHandlers: ErrorHandler[]
): string => {
  // Get an error message from each handler,
  // using the first if it's not empty.
  const handlerErrors = errorHandlers
    .map((f) => f(e))
    .filter((message) => message !== undefined && message !== "");
  const errorFromHandler = handlerErrors.length > 0 ? handlerErrors[0] : undefined;

  // Get the error from the global error handler.
  const globallyHandledError = globalErrorHandler(e);

  // Return the error in order of preference
  // 1. The first message from the errorHandlers
  // 2. The globally handled error
  // 3. The fallbackError
  // 4. A global "something went wrong" message.
  return errorFromHandler || globallyHandledError || fallbackError || "error.fallback";
};

export default getErrorMessage;
