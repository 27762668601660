import { TFunction } from "i18next";
import Property, {
  PropertyContractor,
  PropertyMetrics,
  PropertyNotification,
  PropertyStatus,
} from "../../../../rest/properties/model/Property";
import { formatPropertyAddress } from "../../../../utils/formatAddress";
import { formatDate } from "../../../../utils/formatDate";
import { titleForProCheckStatus } from "../model/ProCheckStatus";
import PropertyTableItem from "../model/PropertyTableItem";
import RAGStatus from "../model/RAGStatus";

const mapToPropertyTableItem = (
  property: Property,
  t: TFunction,
  withoutStatus?: boolean,
  selected?: boolean
): PropertyTableItem => {
  return {
    id: property.id,
    status:
      withoutStatus || property.status === null
        ? undefined
        : mapStatus(property.status.overall),
    statusMessage:
      withoutStatus || property.status === null
        ? undefined
        : mapPropertyStatusMessage(property.status, t),
    property: formatPropertyAddress(property, t),
    products: countProducts(property.metrics),
    contractorName: formatContractorName(property.contractor, t),
    ownerName: formatOwnerName(property, t),
    selected: selected,
  };
};

export const mapPropertyStatusMessage = (
  status: PropertyStatus | undefined,
  t: TFunction
): string => {
  if (status?.overall === undefined) {
    return "-";
  }
  switch (status.overall) {
    case "fail":
      return t("ragStatus.red");
    case "warning":
      return t("ragStatus.amber");
    case "pass":
      return t("ragStatus.green");
  }
};

export const mapStatusMessage = (
  status: PropertyNotification | undefined,
  t: TFunction
): string => {
  if (status?.reason === undefined) {
    return "-";
  }

  if (status.reason === "test_due") {
    const date = status.action_due ? new Date(status.action_due) : undefined;
    const formattedDate = date ? formatDate(date, "dd-MM-yyyy") : "";
    const reasonFormat = t("statusMessage.testDuePlaceholder");
    return reasonFormat.replace("%s", formattedDate);
  } else {
    return titleForProCheckStatus(status.reason, t);
  }
};

const mapStatus = (status: "pass" | "warning" | "fail"): RAGStatus => {
  switch (status) {
    case "pass":
      return "green";

    case "warning":
      return "amber";

    case "fail":
      return "red";
  }
};

const countProducts = (metrics: PropertyMetrics | undefined): string => {
  if (!!!metrics) {
    return "-";
  } else {
    return metrics.total_count + "";
  }
};

const formatContractorName = (
  contractor: PropertyContractor | undefined,
  t: TFunction
): string => {
  return contractor?.name || t("propertyTable.rowContractorNamePlaceholder");
};

const formatOwnerName = (property: Property, t: TFunction): string =>
  property.organisation?.name || t("propertyTable.rowOwnerNamePlaceholder");

export default mapToPropertyTableItem;
