import { put, select, takeLatest } from "redux-saga/effects";
import getErrorMessage from "../../../errors/messages/getErrorMessage";
import RootState from "../../../redux/RootState";
import getDependency from "../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../redux/utils/isAdeyAdmin";
import SenseDevice from "../../../rest/sense/model/SenseDevice";
import SenseRepository from "../../../rest/sense/SenseRepository";
import {
  LoadPropertyAction,
  LOAD_PROPERTY_TYPE,
} from "../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import SelectedSenseDevice from "../models/SelectedSenseDevice";
import SenseDeviceService from "../services/SenseDeviceService";
import {
  ChangeSenseDeviceActivationStatusAction,
  CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE,
  LoadSelectedSenseDeviceAction,
  loadSelectedSenseDeviceError,
  loadSenseDevices,
  LoadSenseDevicesAction,
  loadSenseDevicesError,
  LOAD_SELECTED_SENSE_DEVICE_TYPE,
  LOAD_SENSE_DEVICES_TYPE,
  selectedSenseDeviceLoaded,
  senseDeviceActivationStatusChanged,
  senseDeviceActivationStatusChangeError,
  senseDevicesLoaded,
} from "./SenseDevicesAction";

function* senseDeviceSagas() {
  yield takeLatest(LOAD_PROPERTY_TYPE, propertyLoaded);
  yield takeLatest(LOAD_SENSE_DEVICES_TYPE, loadSenseDevicesSaga);
  yield takeLatest(LOAD_SELECTED_SENSE_DEVICE_TYPE, loadSelectedSenseDeviceSaga);
  yield takeLatest(
    CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE,
    setDeviceActivationStatusSaga
  );
}

function* propertyLoaded(action: LoadPropertyAction) {
  const rootState: RootState = yield select();
  const propertyId = rootState.senseDevices.devices.find(
    (device) => device.property.id
  )?.id;

  if (propertyId === action.propertyId) {
    return;
  }
  yield put(loadSenseDevices(action.organisationId, action.propertyId));
}

function* setDeviceActivationStatusSaga(action: ChangeSenseDeviceActivationStatusAction) {
  const isAdmin: boolean = yield isAdeyAdmin();
  const rootState: RootState = yield select();

  const senseDeviceRepository: SenseRepository = yield getDependency(
    "senseDeviceRepository"
  );

  try {
    if (rootState.senseDevices.selectedDevice?.device === undefined) {
      throw new Error("No Selected Sense Device");
    }

    yield senseDeviceRepository.setDeviceActivation(
      isAdmin,
      action.organisation_id,
      action.property_id,
      action.serial,
      action.activation_status
    );

    yield put(
      senseDeviceActivationStatusChanged({
        ...rootState.senseDevices.selectedDevice.device,
        deactivated: action.activation_status ? 0 : 1,
      })
    );
  } catch (e) {
    const message = getErrorMessage(e, "senseDevices.activationError");
    yield put(senseDeviceActivationStatusChangeError(message));
  }
}

function* loadSenseDevicesSaga(action: LoadSenseDevicesAction) {
  const isAdmin: boolean = yield isAdeyAdmin();

  const senseDeviceRepository: SenseRepository = yield getDependency(
    "senseDeviceRepository"
  );

  try {
    const propertyDevices: SenseDevice[] =
      yield senseDeviceRepository.listAllSenseDevices(
        isAdmin,
        action.organisation_id,
        action.property_id
      );

    yield put(senseDevicesLoaded(propertyDevices));
  } catch (e) {
    const message = getErrorMessage(e, "senseDevices.loadError");
    yield put(loadSenseDevicesError(message));
  }
}

function* loadSelectedSenseDeviceSaga(action: LoadSelectedSenseDeviceAction) {
  const isAdmin: boolean = yield isAdeyAdmin();

  const senseDeviceService: SenseDeviceService = yield getDependency(
    "senseDeviceService"
  );

  try {
    const selectedDevice: SelectedSenseDevice =
      yield senseDeviceService.fetchDeviceDetails(
        isAdmin,
        action.organisation_id,
        action.property_id,
        action.serial
      );

    yield put(selectedSenseDeviceLoaded(selectedDevice));
  } catch (e) {
    const message = getErrorMessage(e, "selectedSenseDevice.loadError");
    yield put(loadSelectedSenseDeviceError(message));
  }
}

export default senseDeviceSagas;
