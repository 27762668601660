import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Role from "../../../../rest/roles/model/Role";

// Creates a unique key for a given organisation
// CB should have confirmed these values are unique
// https://adeyinnovation.atlassian.net/browse/SAD-172
const keyForOrganisation = (org: Organisation | MemberOrganisation): string => {
  const role = (org as any).role as Role | undefined;
  const keys = [org.id, role?.id, role?.via];

  return keys
    .filter((id) => !!id)
    .filter((id) => id?.trim() !== "")
    .join("-");
};

export default keyForOrganisation;
