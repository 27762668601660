import Property from "../properties/model/Property";
import UserDetails from "../user/model/UserDetails";
import Page from "../utils/Page";
import ProCheckExport from "./model/ProCheckExport";
import ProCheckResult from "./model/ProCheckResult";
import ProCheckResultDetails from "./model/ProCheckResultDetails";
import ProCheckRepository from "./ProCheckRepository";

const mockUser: UserDetails = {
  id: "1",
  email: "test@mail.com",
  first_name: "John",
  features: [],
  last_name: "Johnson",
  phone_number: "1-800-0000",
  address_postcode: "BS3 LD2",
  onboarding: {},
};

const mockProperty: Property = {
  id: "1",
  address_line_1: "Test",
  address_city: "Test",
  address_postcode: "Test",
  homezone_reporting_enabled: false,
  address_country: { id: "2", name: "United Kingdom", code: "UK" },
  status: { reason: "test_failed", overall: "fail", notifications: [] },
};

class MockProCheckRepository implements ProCheckRepository {
  listOrgProCheckResults = async (
    organisationId: string
  ): Promise<Page<ProCheckResult[]>> => {
    const mockProCheckResults = [];

    for (let i = 1; i < 40; i++) {
      mockProCheckResults.push({
        id: i.toString(),
        pass: true,
        tested_at: new Date().toISOString(),
        user: mockUser,
        property: { ...mockProperty, address_line_1: `Test ${i}` },
        organisation_id: "1",
      });
    }
    return {
      meta: {
        current_page: 1,
        last_page: 4,
        per_page: 10,
      },
      data: mockProCheckResults,
    };
  };

  listAllProCheckResults = async (
    organisationId: string,
    propertyId: string
  ): Promise<ProCheckResult[]> => {
    return [
      {
        id: "1",
        pass: true,
        tested_at: new Date().toISOString(),
        user: mockUser,
        property: mockProperty,
        organisation_id: "1",
      },
    ];
  };

  fetchProCheckResult = (
    organisationId: string,
    propertyId: string,
    resultId: string
  ): Promise<ProCheckResultDetails> => {
    throw new Error("Not mocked");
  };

  listUserProCheckResults = (
    organisationId: string,
    userId: string
  ): Promise<Page<ProCheckResult[]>> => {
    throw new Error("Not mocked");
  };

  sendProCheckResults = (
    isAdmin: boolean,
    exportDetails: ProCheckExport,
    organisationId: string,
    propertyId?: string
  ): Promise<void> => {
    throw new Error("Not mocked");
  };
}

export default MockProCheckRepository;
