import DashboardAction from "./DashboardAction";
import DashboardState from "./DashboardState";

const initialState: DashboardState = {
  metrics: undefined,
  organisationId: undefined,
};

const dashboardReducer = (
  state = initialState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case "DASHBOARD_METRICS_LOADED_TYPE":
      return {
        metrics: action.metrics,
        organisationId: action.organisationId,
      };

    case "CLEAR_DASHBOARD_METRICS_TYPE":
      return { ...initialState };

    default:
      return state;
  }
};

export default dashboardReducer;
