import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import UserDetails from "../../../rest/user/model/UserDetails";
import SuccessSnackbarHandler from "../SuccessSnackbar/SuccessSnackbarHandler";
import InviteTableRow from "./components/InviteTableRow";
import PendingInviteMenu from "../PendingInviteMenu/PendingInviteMenu";
import TableHeaderCell from "../TableList/components/TableHeaderCell";

const menuCellWidth = 120;

interface ReduxStateProps {
  inviteResent: boolean;
  currentUserId: string | undefined;
  canDeleteUsers: boolean;
}

interface ExternalProps {
  key?: string;
  role?: boolean;
  invites: PendingInvite[];
}

type Props = ReduxStateProps & ExternalProps;

interface State {
  menuUser: UserDetails | undefined;
  menuInvite: PendingInvite | undefined;
  anchorElement: Element | undefined;
}

class InviteTable extends React.Component<Props, State> {
  state = {
    menuUser: undefined,
    menuInvite: undefined,
    anchorElement: undefined,
  };

  render() {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell textId="inviteTable.emailHeader" />
              {this.props.role ? <TableHeaderCell textId="inviteTable.role" /> : null}

              <TableHeaderCell
                textId="inviteTable.actionsHeader"
                style={{ width: menuCellWidth }}
                align="center"
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.invites.map((invite) => (
              <InviteTableRow
                showRole={this.props.role}
                key={invite.id}
                invite={invite}
                menuCellWidth={menuCellWidth}
                onMenuPressed={this.onInviteMenuPressed}
              />
            ))}
          </TableBody>
        </Table>

        <PendingInviteMenu
          invite={this.state.menuInvite}
          anchorElement={this.state.anchorElement}
          onClose={this.onMenuClosed}
        />

        <SuccessSnackbarHandler
          message={this.props.inviteResent ? "pendingInviteMenu.emailResent" : undefined}
        />
      </div>
    );
  }

  onUserMenuPressed = (user: UserDetails, e: Element) => {
    this.setState({ menuUser: user, anchorElement: e, menuInvite: undefined });
  };

  onInviteMenuPressed = (invite: PendingInvite, e: Element) => {
    this.setState({ menuUser: undefined, anchorElement: e, menuInvite: invite });
  };

  onMenuClosed = () => {
    this.setState({
      menuUser: undefined,
      menuInvite: undefined,
      anchorElement: undefined,
    });
  };

  canBuildMenu = (user: UserDetails) => {
    return this.props.canDeleteUsers && user.id !== this.props.currentUserId;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  inviteResent: state.pendingInviteMenu.resendInvite.success,
  currentUserId: state.login.currentUserId,
  canDeleteUsers: state.activeOrganisation.permissions.canRemoveActiveOrganisationUsers,
});

export default connect(mapStateToProps)(InviteTable);
