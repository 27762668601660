import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DashboardLayout from "../../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../../ui/components/ErrorDisplay";
import PageHeaderTabs from "../../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import ProCheckReportsTab from "./components/ProCheckReportsTab";
import useReportDashboard from "./hooks/useReportDashboard";

const ReportsDashboardPage = () => {
  //Hooks
  const { selectedTab, tabs, canViewReports, onTabClicked } = useReportDashboard();
  const { t } = useTranslation();

  //Strings
  const error = t("error.unauthorizedPage");

  //Renderers
  const renderBody = (): JSX.Element => {
    switch (tabs) {
      default:
        return <ProCheckReportsTab></ProCheckReportsTab>;
    }
  };

  return (
    <DashboardLayout>
      {canViewReports ? (
        <Box>
          <Box paddingY={1}>
            <PageHeaderTabs
              tabs={tabs}
              activeKey={selectedTab}
              tabClicked={onTabClicked}
            />
          </Box>

          {renderBody()}
        </Box>
      ) : (
        <ErrorDisplay title={error} />
      )}
    </DashboardLayout>
  );
};

export default ReportsDashboardPage;
