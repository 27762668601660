import { put, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import InviteRepository from "../../../../rest/invites/InviteRepository";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { ACTIVE_ORGANISATION_CHANGED_TYPE } from "../../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import {
  ResendInviteSuccessAction,
  RESEND_INVITE_SUCCESS_TYPE,
  RevokeInviteSuccessAction,
  REVOKE_INVITE_SUCCESS_TYPE,
} from "../../../../ui/components/PendingInviteMenu/redux/PendingInviteMenuAction";
import { CURRENT_USER_LOADED_TYPE } from "../../../account/AccountSettingsPage/redux/CurrentUserAction";
import { ENTITY_INVITED_TYPE } from "../../../invites/InviteDialog/redux/CurrentInviteAction";
import { REMOVE_ORGANISATION_SUCCESS_TYPE } from "../../details/redux/OrganisationDetailActions";
import {
  loadOrganisations,
  loadOrganisationsError,
  loadOrganisationsSuccess,
  LOAD_ORGANISATIONS_TYPE,
} from "./OrganisationListAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* organisationListSagas() {
  // Refresh the list when...
  yield takeLatest(
    [
      ACTIVE_ORGANISATION_CHANGED_TYPE, // The organisation changes
      CURRENT_USER_LOADED_TYPE, // When the user updates their details
      REMOVE_ORGANISATION_SUCCESS_TYPE, //When the organisation has been removed
      ENTITY_INVITED_TYPE, // a new invite
    ],
    refreshListSaga
  );
  yield takeEvery(LOAD_ORGANISATIONS_TYPE, loadOrganisationsSaga);
  yield takeEvery(REVOKE_INVITE_SUCCESS_TYPE, onOrgInviteRevoked);
  yield takeEvery(RESEND_INVITE_SUCCESS_TYPE, inviteOrgResentSaga);
}

function* refreshListSaga() {
  yield put(loadOrganisations());
}

function* loadOrganisationsSaga() {
  try {
    const rootState: RootState = yield select();
    if (!rootState.activeOrganisation.permissions.canViewOrganisationContractors) {
      return;
    }

    const currentOrgId = rootState.activeOrganisation.currentOrganisation?.id;
    if (currentOrgId === undefined) {
      // If we don't have an org, stop
      throw new Error("No current organisation");
    }

    const contractorRepo: OrganisationContractorRepository = yield getDependency(
      "organisationContractorRepository"
    );
    const inviteRepo: InviteRepository = yield getDependency("inviteRepository");

    const contractors: Organisation[] =
      yield contractorRepo.fetchOrganisationContractorList(currentOrgId, undefined);

    const associatedOrgs: Organisation[] =
      yield contractorRepo.fetchContractingOrganisations(currentOrgId);

    const invites: PendingInvite[] = yield inviteRepo.fetchPendingContractorInvites(
      currentOrgId
    );
    yield put(loadOrganisationsSuccess(contractors, associatedOrgs, invites));
  } catch (e) {
    const message = getErrorMessage(e, "organisationListPage.loadError");
    yield put(loadOrganisationsError(message));
  }
}

function* onOrgInviteRevoked(action: RevokeInviteSuccessAction) {
  try {
    const state: RootState = yield select();
    const contractors = state.orgList.contractors;
    const associatedOrgs = state.orgList.associatedOrgs;

    const invites = state.orgList.invites.filter(
      (invite) => invite.id !== action.invite.id
    );

    yield put(loadOrganisationsSuccess(contractors, associatedOrgs, invites));
  } catch (e) {
    // Unable to refresh list
    console.error(e);
  }
}

function* inviteOrgResentSaga(action: ResendInviteSuccessAction) {
  try {
    const state: RootState = yield select();
    const contractors = state.orgList.contractors;
    const associatedOrgs = state.orgList.associatedOrgs;

    // Replace the old invite with the new one
    const invites = [...state.orgList.invites];
    const index = invites.findIndex((invite) => invite.id === action.oldInvite.id);
    if (index >= 0 && index < invites.length) {
      invites[index] = action.newInvite;
    }
    yield put(loadOrganisationsSuccess(contractors, associatedOrgs, invites));
  } catch (e) {
    // Unable to refresh list
    console.error(e);
  }
}

export default organisationListSagas;
