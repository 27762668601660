import { Box, Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { SMALL_SPACING } from "../theme/dimensions";

export interface AlertDialogAction {
  title: string;
  onClick: () => void;
  destructive?: boolean;
}

interface Props {
  open: boolean;
  title: string;
  body: string;
  actions: AlertDialogAction[];

  onClose?: () => void;
}

const AlertDialog = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Box padding={SMALL_SPACING}>
        <Typography variant="h2" gutterBottom>
          <Trans>{props.title}</Trans>
        </Typography>
        <Typography gutterBottom>
          <Trans>{props.body}</Trans>
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          {props.actions.map((action) => (
            <Button
              key={action.title}
              color={action.destructive ? "primary" : "secondary"}
              onClick={action.onClick}
            >
              <Trans>{action.title}</Trans>
            </Button>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AlertDialog;
