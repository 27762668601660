import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { getSystemMessagesAction } from "../../pages/auth/SystemMessagesDialogs/redux/SystemMessageAction";
import isDevServer from "../../utils/isDevServer";
import AdeyBackground from "../assets/AdeyBackground/AdeyBackground";
import AdeyLogo from "../assets/AdeyLogo/AdeyLogo";
import { BACKGROUND_ACCENT_COLOR } from "../theme/createMaterialTheme";
import { SMALL_SPACING } from "../theme/dimensions";
import LanguageSwitcher from "./LanguageSwitcher";
import SecondaryButton from "./SecondaryButton";
import SignOutButton from "./SignOutButton";

const _logoHeight = 60;
const _topPadding = 4;
const _bottomPadding = 4;
const _horizontalPadding = 8;
const _logoPadding = 24;
const _defaultCardWidth = "600px";
const _cardElevation = 24;

interface Props {
  children: ReactNode;
  title?: string;
  overflow?: string;
  width?: string;
  noMaxWidth?: boolean;
  includeSignOut?: boolean;
}

const CenteredAuthCard = (props: Props) => {
  const titleLabel = props.title ? (
    <Typography variant="h2" align="center">
      <strong>{props.title.toUpperCase()}</strong>
    </Typography>
  ) : null;

  // Default the overflow behavior to auto
  const overflow = props.overflow || "auto";
  const width = props.width || _defaultCardWidth;
  const containerMaxWidth = props.noMaxWidth ? false : "lg";

  //Dispatch - used for testing
  const dispatch = useDispatch();

  //Handlers
  const debugSystemDown = () => {
    dispatch(getSystemMessagesAction(undefined, undefined, true));
  };

  return (
    <Box
      position="fixed"
      bgcolor={BACKGROUND_ACCENT_COLOR}
      minWidth="100%"
      minHeight="100vh"
    >
      <AdeyBackground height="60vh" width="100%" />

      {props.includeSignOut ? (
        <Box
          position="absolute"
          padding={SMALL_SPACING}
          right={0}
          zIndex={5}
          display="flex"
        >
          <LanguageSwitcher styled={true} />
          <Box marginX="10px" />
          <SignOutButton />
        </Box>
      ) : undefined}

      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100%"
        zIndex="2"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          style={{ width: props.noMaxWidth ? "auto" : width }}
        >
          <AdeyLogo height={_logoHeight} color="white" fill />
          <Box height={_logoPadding} />

          <Container maxWidth={containerMaxWidth}>
            <Card elevation={_cardElevation}>
              <Box
                paddingX={_horizontalPadding}
                paddingTop={_topPadding}
                paddingBottom={_bottomPadding}
                alignContent="stretch"
                maxHeight="75vh"
                style={{ overflow: overflow }}
              >
                {titleLabel}

                {props.children}

                {!props.includeSignOut ? (
                  <Grid container direction="row" justify="flex-end">
                    <LanguageSwitcher />
                  </Grid>
                ) : undefined}
                {isDevServer() && (
                  <Grid container direction="row" justify="flex-end">
                    <SecondaryButton
                      onClick={debugSystemDown}
                      label="Simulate system down"
                    />
                    <SecondaryButton
                      onClick={() => localStorage.removeItem("systemMessages")}
                      label="Reset hidden messages"
                    />
                  </Grid>
                )}
              </Box>
            </Card>
          </Container>

          {/* This box at the bottom centeres the card vertically */}
          <Box height={_logoPadding + _logoHeight} />
        </Box>
      </Box>
    </Box>
  );
};

export default CenteredAuthCard;
