import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import { RoleName } from "../../../../rest/roles/model/Role";
import { OrganisationRelationship } from "../../../organisations/details/redux/OrganisationDetailState";

// Load contractors
export const LOAD_CONTRACTORS_TYPE = "LOAD_CONTRACTORS_TYPE";
export interface LoadContractorsAction {
  type: typeof LOAD_CONTRACTORS_TYPE;
  organisationId: string;
}
export const loadContractors = (organisationId: string): LoadContractorsAction => ({
  type: LOAD_CONTRACTORS_TYPE,
  organisationId: organisationId,
});

// Load organisation users
export const LOAD_ORG_USERS_TYPE = "LOAD_ORG_USERS_TYPE";
export interface LoadOrgUsersAction {
  type: typeof LOAD_ORG_USERS_TYPE;
  organisationId: string;
}
export const loadOrgUsers = (organisationId: string): LoadOrgUsersAction => ({
  type: LOAD_ORG_USERS_TYPE,
  organisationId: organisationId,
});

// Contractors loaded
export const CONTRACTORS_LOADED_TYPE = "CONTRACTORS_LOADED_TYPE";
export interface ContractorsLoadedAction {
  type: typeof CONTRACTORS_LOADED_TYPE;
  contractors: Organisation[];
  associatedOrgs: Organisation[];
  pendingInvites: PendingInvite[];
}
export const contractorsLoaded = (
  contractors: Organisation[],
  associatedOrgs: Organisation[],
  pendingInvites: PendingInvite[]
): ContractorsLoadedAction => ({
  type: CONTRACTORS_LOADED_TYPE,
  contractors: contractors,
  associatedOrgs: associatedOrgs,
  pendingInvites: pendingInvites,
});

//Org users and invites loaded
export const ORG_USERS_LOADED_TYPE = "ORG_USERS_LOADED_TYPE";
export interface OrgUsersLoadedAction {
  type: typeof ORG_USERS_LOADED_TYPE;
  users: OrganisationUser[];
  pendingInvites: PendingInvite[];
}

export const orgUsersLoaded = (
  users: OrganisationUser[],
  pendingInvites: PendingInvite[]
): OrgUsersLoadedAction => ({
  type: ORG_USERS_LOADED_TYPE,
  users: users,
  pendingInvites: pendingInvites,
});

// Contractors load error
export const CONTRACTORS_LOAD_ERROR_TYPE = "CONTRACTORS_LOAD_ERROR_TYPE";
export interface ContractorsLoadErrorAction {
  type: typeof CONTRACTORS_LOAD_ERROR_TYPE;
  error: string;
}
export const contractorsLoadError = (error: string): ContractorsLoadErrorAction => ({
  type: CONTRACTORS_LOAD_ERROR_TYPE,
  error: error,
});

// Users load error
export const ORG_USERS_LOAD_ERROR_TYPE = "ORG_USERS_LOAD_ERROR_TYPE";
export interface OrgUsersLoadErrorAction {
  type: typeof ORG_USERS_LOAD_ERROR_TYPE;
  error: string;
}
export const orgUsersLoadError = (error: string): OrgUsersLoadErrorAction => ({
  type: ORG_USERS_LOAD_ERROR_TYPE,
  error: error,
});

// Invite contractor
export const INVITE_CONTRACTOR_TYPE = "INVITE_CONTRACTOR_TYPE";
export interface InviteContractorAction {
  type: typeof INVITE_CONTRACTOR_TYPE;
  organisationId: string;
  email: string;
}
export const inviteContractor = (
  organisationId: string,
  email: string
): InviteContractorAction => ({
  type: INVITE_CONTRACTOR_TYPE,
  organisationId: organisationId,
  email: email,
});

// Invite user
export const INVITE_USER_TYPE = "INVITE_USER_TYPE";
export interface InviteUserAction {
  type: typeof INVITE_USER_TYPE;
  organisationId: string;
  role: RoleName;
  email: string;
}
export const inviteUser = (
  organisationId: string,
  email: string,
  role: RoleName
): InviteUserAction => ({
  type: INVITE_USER_TYPE,
  organisationId: organisationId,
  email: email,
  role: role,
});

// Invite contractor success
export const INVITE_CONTRACTOR_SUCCESS_TYPE = "INVITE_CONTRACTOR_SUCCESS_TYPE";
export interface InviteContractorSuccessAction {
  type: typeof INVITE_CONTRACTOR_SUCCESS_TYPE;
  invite: PendingInvite;
}
export const inviteContractorSuccess = (
  invite: PendingInvite
): InviteContractorSuccessAction => ({
  type: INVITE_CONTRACTOR_SUCCESS_TYPE,
  invite: invite,
});

// Invite user success
export const INVITE_USER_SUCCESS_TYPE = "INVITE_USER_SUCCESS_TYPE";
export interface InviteUserSuccessAction {
  type: typeof INVITE_USER_SUCCESS_TYPE;
  invite: PendingInvite;
}
export const inviteUserSuccess = (invite: PendingInvite): InviteUserSuccessAction => ({
  type: INVITE_USER_SUCCESS_TYPE,
  invite: invite,
});

// Invite contractor error
export const INVITE_CONTRACTOR_ERROR_TYPE = "INVITE_CONTRACTOR_ERROR_TYPE";
export interface InviteContractorErrorAction {
  type: typeof INVITE_CONTRACTOR_ERROR_TYPE;
  error: string;
}
export const inviteContractorError = (error: string): InviteContractorErrorAction => ({
  type: INVITE_CONTRACTOR_ERROR_TYPE,
  error: error,
});

// Invite contractor error
export const INVITE_USER_ERROR_TYPE = "INVITE_USER_ERROR_TYPE";
export interface InviteUserErrorAction {
  type: typeof INVITE_USER_ERROR_TYPE;
  error: string;
}
export const inviteUserError = (error: string): InviteUserErrorAction => ({
  type: INVITE_USER_ERROR_TYPE,
  error: error,
});

// Init user removal
export const USER_REMOVAL_INIT_TYPE = "USER_REMOVAL_INIT_TYPE";
export interface InitUserRemovalAction {
  type: typeof USER_REMOVAL_INIT_TYPE;
}

export const initUserRemoval = (): InitUserRemovalAction => ({
  type: USER_REMOVAL_INIT_TYPE,
});

//Remove user success
export const REMOVE_USER_SUCCESS_TYPE = "REMOVE_USER_SUCCESS_TYPE";
export interface UserRemovalSuccessAction {
  type: typeof REMOVE_USER_SUCCESS_TYPE;
}

export const userRemovalSuccess = (): UserRemovalSuccessAction => ({
  type: REMOVE_USER_SUCCESS_TYPE,
});

//Remove user failure
export const REMOVE_USER_FAILURE_TYPE = "REMOVE_USER_FAILURE_TYPE";
export interface UserRemovalFailureAction {
  type: typeof REMOVE_USER_FAILURE_TYPE;
}

export const userRemovalFailure = (): UserRemovalFailureAction => ({
  type: REMOVE_USER_FAILURE_TYPE,
});

// Linked org redux
// Init linked organisation removal
export const LINKED_ORG_REMOVAL_INIT_TYPE = "LINKED_ORG_REMOVAL_INIT_TYPE";
export interface InitLinkedOrgRemovalAction {
  type: typeof LINKED_ORG_REMOVAL_INIT_TYPE;
  organisationId: string;
  contractorId: string;
  organisationRelationship: OrganisationRelationship;
}

export const initLinkedOrgRemoval = (
  organisationId: string,
  contractorId: string,
  organisationRelationship: OrganisationRelationship
): InitLinkedOrgRemovalAction => ({
  type: LINKED_ORG_REMOVAL_INIT_TYPE,
  organisationId: organisationId,
  contractorId: contractorId,
  organisationRelationship: organisationRelationship,
});

//Remove linked organisation success
export const REMOVE_LINKED_ORG_SUCCESS_TYPE = "REMOVE_LINKED_ORG_SUCCESS_TYPE";
export interface LinkedOrgRemovalSuccessAction {
  type: typeof REMOVE_LINKED_ORG_SUCCESS_TYPE;
  removedOrg: Organisation;
  organisationRelationship: OrganisationRelationship;
}

export const linkedOrgRemovalSuccess = (
  removedOrg: Organisation,
  organisationRelationship: OrganisationRelationship
): LinkedOrgRemovalSuccessAction => ({
  type: REMOVE_LINKED_ORG_SUCCESS_TYPE,
  removedOrg: removedOrg,
  organisationRelationship: organisationRelationship,
});

//Remove linked organisation failure
export const REMOVE_LINKED_ORG_FAILURE_TYPE = "REMOVE_LINKED_ORG_FAILURE_TYPE";
export interface LinkedOrgRemovalFailureAction {
  type: typeof REMOVE_LINKED_ORG_FAILURE_TYPE;
  error: string;
}

export const linkedOrgRemovalFailure = (
  error: string
): LinkedOrgRemovalFailureAction => ({
  type: REMOVE_LINKED_ORG_FAILURE_TYPE,
  error: error,
});

//Grant Agent Access
export const GRANT_AGENT_ACCESS_TYPE = "GRANT_AGENT_ACCESS";
export interface GrantAgentAccessAction {
  type: typeof GRANT_AGENT_ACCESS_TYPE;
  org: Organisation;
}

export const grantAgentAccess = (org: Organisation): GrantAgentAccessAction => ({
  type: GRANT_AGENT_ACCESS_TYPE,
  org: org,
});

//Agent Access Granted
export const AGENT_ACCESS_GRANTED_TYPE = "AGENT_ACCESS_GRANTED";
export interface AgentAccessGrantedAction {
  type: typeof AGENT_ACCESS_GRANTED_TYPE;
  org: Organisation;
}

export const agentAccessGranted = (org: Organisation): AgentAccessGrantedAction => ({
  type: AGENT_ACCESS_GRANTED_TYPE,
  org: org,
});

//Agent Access Grant Error
export const GRANT_AGENT_ACCESS_ERROR_TYPE = "GRANT_AGENT_ACCESS_ERROR";
export interface GrantAgentAccessErrorAction {
  type: typeof GRANT_AGENT_ACCESS_ERROR_TYPE;
  error: string;
}

export const grantAgentAccessError = (error: string): GrantAgentAccessErrorAction => ({
  type: GRANT_AGENT_ACCESS_ERROR_TYPE,
  error: error,
});

//Assign Agent
export const ASSIGN_AGENT_TYPE = "ASSIGN_AGENT";
export interface AssignAgentAction {
  type: typeof ASSIGN_AGENT_TYPE;
  org: Organisation;
  agent_id: string | undefined;
}

export const assignAgent = (
  org: Organisation,
  agent_id: string | undefined
): AssignAgentAction => ({
  type: ASSIGN_AGENT_TYPE,
  org: org,
  agent_id: agent_id,
});
//Agent Assigned
export const AGENT_ASSIGNED_TYPE = "AGENT_ASSIGNED";
export interface AgentAssignedAction {
  type: typeof AGENT_ASSIGNED_TYPE;
  org: Organisation;
}

export const agentAssigned = (org: Organisation): AgentAssignedAction => ({
  type: AGENT_ASSIGNED_TYPE,
  org: org,
});
//Agent Assign Error
export const AGENT_ASSIGN_ERROR_TYPE = "AGENT_ASSIGN_ERROR";
export interface AgentAssignErrorAction {
  type: typeof AGENT_ASSIGN_ERROR_TYPE;
  error: string;
}

export const agentAssignError = (error: string): AgentAssignErrorAction => ({
  type: AGENT_ASSIGN_ERROR_TYPE,
  error: error,
});

type AdminOrganisationDetailsAction =
  | LoadContractorsAction
  | ContractorsLoadedAction
  | ContractorsLoadErrorAction
  | InviteContractorAction
  | InviteContractorSuccessAction
  | InviteContractorErrorAction
  | LoadOrgUsersAction
  | OrgUsersLoadedAction
  | OrgUsersLoadErrorAction
  | InviteUserAction
  | InviteUserSuccessAction
  | InviteUserErrorAction
  | InitUserRemovalAction
  | UserRemovalSuccessAction
  | UserRemovalFailureAction
  | LinkedOrgRemovalFailureAction
  | LinkedOrgRemovalSuccessAction
  | InitLinkedOrgRemovalAction
  | GrantAgentAccessAction
  | AgentAccessGrantedAction
  | GrantAgentAccessErrorAction
  | AssignAgentAction
  | AgentAssignedAction
  | AgentAssignErrorAction;

export default AdminOrganisationDetailsAction;
