import React from "react";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../redux/RootState";
import {
  ORGANISATION_PROPERTY_DETAIL_ROUTE,
  PLATFORM_ORGANISATION_DETAIL_ROUTE,
} from "../../../../routes/routes";
import { ragCardOrgIdChanged } from "../redux/DashboardAction";

interface URLParams {
  organisationId: string;
}

interface ExternalProps {
  children: React.ReactNode;
}

interface ReduxStateProps {
  isAdeyAdmin: boolean;
}

interface ReduxDispatchProps {
  organisationIdLoaded: (orgId: string | undefined) => void;
}

type Props = ReduxDispatchProps & RouteComponentProps & ReduxStateProps & ExternalProps;

class AdminRAGCardListener extends React.Component<Props> {
  // Keep this callback so we can unregister the listener
  callback: (() => void) | undefined = undefined;

  // On mounting, start listening to route changes.
  componentDidMount() {
    this.handlePathChanged(this.props.location.pathname);

    this.callback = this.props.history.listen((location) =>
      this.handlePathChanged(location.pathname)
    );
  }

  // Stop listening when we unmount
  componentWillUnmount() {
    this.callback?.();
  }

  render() {
    return this.props.children;
  }

  handlePathChanged = (path: string) => {
    // If we're on the org detail page or property page as Adey Admin, we need to show different rag cards.

    let orgId = matchPath<URLParams>(path, PLATFORM_ORGANISATION_DETAIL_ROUTE)?.params
      .organisationId;
    if (this.props.isAdeyAdmin && orgId == null) {
      orgId = matchPath<URLParams>(path, ORGANISATION_PROPERTY_DETAIL_ROUTE)?.params
        .organisationId;
    }

    this.props.organisationIdLoaded(orgId);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isAdeyAdmin: state.adeyAdmin.hasAdminAccess,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  organisationIdLoaded: (id) => dispatch(ragCardOrgIdChanged(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminRAGCardListener));
