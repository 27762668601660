import MovePropertiesButton from "./MovePropertiesButton";
import MovePropertiesDialog from "./MovePropertiesDialog";
import { useSelector } from "react-redux";
import RootState from "../../../../../redux/RootState";
import Property from "../../../../../rest/properties/model/Property";
import useMoveSelectedProperties from "./hooks/useMoveSelectedProperties";

interface IProps {
  properties: Property[];
}

const MovePropertiesHandler = ({ properties }: IProps) => {
  //Hooks
  const {
    destinations,
    searchString,
    activeTab,
    destinationId,
    moveDialogOpen,
    handleBackPressed,
    handleNextPressed,
    handleSelectedDestination,
    handleMoveDialogOpen,
    handleSetSearchString,
    handleInitSearch,
    handleMoveSelectedProperties,
    handleClosePressed,
    moveResults,
    loading,
  } = useMoveSelectedProperties();

  //Selector
  const visible = useSelector((state: RootState) => state.adeyAdmin.hasAdminAccess);

  const handleConfirmPressed = () => {
    const propertyIds = properties.map((property) => property.id);
    handleMoveSelectedProperties(propertyIds, properties[0].organisation?.id || "");
  };

  return (
    <>
      {visible && (
        <MovePropertiesButton
          disabled={properties.length === 0}
          onClick={handleMoveDialogOpen}
        />
      )}

      <MovePropertiesDialog
        open={moveDialogOpen || activeTab === "success"}
        handleOpen={handleMoveDialogOpen}
        properties={properties}
        isLoading={loading}
        moved={undefined}
        handleSearchPressed={handleInitSearch}
        handleSetSearchString={handleSetSearchString}
        setSelectedDestination={handleSelectedDestination}
        searchValue={searchString}
        destinations={
          properties.length > 0
            ? destinations.filter((dest) => dest.id !== properties[0].organisation?.id)
            : []
        }
        handleNextPressed={handleNextPressed}
        handleBackPressed={handleBackPressed}
        activeTab={activeTab}
        selectedDestination={destinationId}
        handleConfirmPressed={handleConfirmPressed}
        handleClosePressed={handleClosePressed}
        moveResults={moveResults}
      />
    </>
  );
};

export default MovePropertiesHandler;
