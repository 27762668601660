import PlatformUsersListAction from "./PlatformUserListAction";
import PlatformUserListState from "./PlatformUserListState";

const initialState: PlatformUserListState = {
  users: undefined,
  error: undefined,
  isLoading: false,
  currentPage: 1,
  maxPage: 1,
  searchText: "",
};

const platformUserListReducer = (
  state = initialState,
  action: PlatformUsersListAction
): PlatformUserListState => {
  switch (action.type) {
    case "LOAD_PLATFORM_USERS_TYPE":
      return {
        users: undefined,
        error: undefined,
        isLoading: true,
        currentPage: action.page,
        maxPage: state.maxPage,
        searchText: state.searchText,
      };

    case "PLATFORM_USERS_ERROR_TYPE":
      return {
        ...state,
        users: undefined,
        error: action.error,
        isLoading: false,
      };

    case "PLATFORM_USERS_LOADED_TYPE":
      return {
        users: action.users,
        error: undefined,
        isLoading: false,
        currentPage: action.page,
        maxPage: action.maxPages,
        searchText: state.searchText,
      };

    case "SEARCH_TEXT_CHANGED_TYPE":
      return {
        ...state,
        users: undefined,
        isLoading: true,
        currentPage: 1, // have to go back to page 1
        searchText: action.text,
      };

    default:
      return state;
  }
};

export default platformUserListReducer;
