import { formatDate } from "./formatDate";

const formatDayTime = (dateToConvert: string) => {
  let formattedTestDate: string;
  let formattedTestTime: string;

  const testDate = new Date(Date.parse(dateToConvert));

  // toLocaleTimeString returns "12:43:45 BST", so split by " "
  // and get the last entry in the array to get just the
  // timezone information (e.g. "BST")
  const timeZoneSplit = testDate
    .toLocaleTimeString("en-GB", { timeZoneName: "short" })
    .split(" ");
  const timeZone = timeZoneSplit[timeZoneSplit.length - 1];

  formattedTestDate = formatDate(testDate, "dd-MM-yyyy");
  formattedTestTime = `${
    testDate.getHours().toLocaleString().length > 1
      ? testDate.getHours()
      : "0" + testDate.getHours()
  }:${
    testDate.getMinutes().toLocaleString().length > 1
      ? testDate.getMinutes()
      : "0" + testDate.getMinutes()
  } (${timeZone})`;

  return { date: formattedTestDate, time: formattedTestTime };
};

export default formatDayTime;
