interface User {
  first_name: string | undefined;
  last_name: string | undefined;
}

const getFullName = (user: User) => {
  return [user.first_name, user.last_name]
    .map((name) => name?.trim())
    .map((name) => name || "")
    .filter((name) => name.length > 0)
    .join(" ");
};

export default getFullName;
