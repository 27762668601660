import Property from "../model/Property";

const urlEncodeProperty = (property: Property): URLSearchParams => {
  const params = new URLSearchParams();

  params.append("contact_name", property.contact_name || "");
  params.append("contact_email", property.contact_email || "");
  params.append("contact_phone", property.contact_phone || "");
  params.append("address_line_1", property.address_line_1);
  params.append("address_city", property.address_city);
  params.append("address_postcode", property.address_postcode);
  params.append("address_country", property.address_country.id);
  params.append("address_line_2", property.address_line_2 || "");
  params.append("address_line_3", property.address_line_3 || "");
  params.append("notes", property.notes || "");
  params.append(
    "homezone_reporting_enabled",
    property.homezone_reporting_enabled ? "1" : "0"
  );

  return params;
};

export default urlEncodeProperty;
