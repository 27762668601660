import { Box, FormControlLabel, Grid, Switch } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CreateOrganisationForm from "../../../pages/auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";
import Address from "../../../pages/properties/PropertyDetailPage/model/Address";
import { Country } from "../../../rest/countries/model/Country";
import Organisation from "../../../rest/organisation/model/Organisation";
import ArrowIcon from "../../assets/ArrowIcon/ArrowIcon";
import { BACKGROUND_ACCENT_COLOR } from "../../theme/createMaterialTheme";
import { SMALL_SPACING } from "../../theme/dimensions";
import ColoredText from "../ColoredText";
import CountryPicker from "../CountryPicker";
import PhoneNumberTextField from "../PhoneNumberTextField";
import PrimaryButton from "../PrimaryButton";
import PrimaryTextField, { FieldState } from "../PrimaryTextField";
import validateOrganisationEditor from "./validateOrganisationEditor";

interface Props extends WithTranslation {
  isAdmin?: boolean;
  isLoading: boolean;
  organisation?: Organisation;
  countryList: Country[] | undefined;
  onContinuePressed: (form: CreateOrganisationForm) => void;
}

export interface OrganisationEditorState {
  organisationName: FieldState;
  organisationContactNumber: FieldState;
  addressLine1: FieldState;
  addressLine2: FieldState;
  addressLine3: FieldState;
  city: FieldState;
  postcode: FieldState;
  country: FieldState;
  vatNumber: FieldState;
}

export interface State {
  editorState: OrganisationEditorState;
  isAgent: boolean;
}

class OrganisationEditor extends React.Component<Props, State> {
  state = {
    editorState: {
      organisationName: { value: this.props.organisation?.name || "" },
      organisationContactNumber: {
        value: this.props.organisation?.phone_number || "",
      },
      addressLine1: { value: this.props.organisation?.address_line_1 || "" },
      addressLine2: { value: this.props.organisation?.address_line_2 || "" },
      addressLine3: { value: this.props.organisation?.address_line_3 || "" },
      city: { value: this.props.organisation?.address_city || "" },
      postcode: { value: this.props.organisation?.address_postcode || "" },
      country: {
        value: this.props.organisation?.address_country.id || "",
      },
      vatNumber: { value: this.props.organisation?.vat_number || "" },
    },
    isAgent: false,
  };

  render() {
    const orgNameHint = this.props.t("organisationEditor.nameFieldHint");
    const orgNumberHint = this.props.t("organisationEditor.numberFieldHint");
    const vatNumberHint = this.props.t("organisationEditor.vatNumberHint");
    const continueButton = this.props.t("organisationEditor.continueButton");
    const line1Hint = this.props.t("organisationEditor.addressLine1Hint");
    const line2Hint = this.props.t("organisationEditor.addressLine2Hint");
    const line3Hint = this.props.t("organisationEditor.addressLine3Hint");
    const postcodeHint = this.props.t("organisationEditor.addressPostcodeHint");
    const cityHint = this.props.t("organisationEditor.addressCityHint");
    const countryHint = this.props.t("organisationEditor.addressCountryHint");
    const agentHint = this.props.t("organisationEditor.agentHint");
    const agentLabel = this.props.t("organisationEditor.agentLabel");
    const addressLabel = this.props.t("organisationEditor.addressLabel");

    return (
      <Grid container spacing={SMALL_SPACING}>
        <Grid item xs={12}>
          <PrimaryTextField
            type="text"
            disabled={this.props.isLoading}
            {...this.state.editorState.organisationName}
            hint={orgNameHint}
            onChange={(v) => this.onFieldChanged("organisationName", v)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneNumberTextField
            {...this.state.editorState.organisationContactNumber}
            hint={orgNumberHint}
            disabled={this.props.isLoading}
            onChange={(v) => this.onFieldChanged("organisationContactNumber", v)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.editorState.vatNumber}
            hint={vatNumberHint}
            disabled={this.props.isLoading}
            onChange={(v) => this.onFieldChanged("vatNumber", v)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
            <strong>{addressLabel}</strong>
          </ColoredText>
        </Grid>

        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.editorState.addressLine1}
            disabled={this.props.isLoading}
            hint={line1Hint}
            onChange={(v) => this.onFieldChanged("addressLine1", v)}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.editorState.addressLine2}
            disabled={this.props.isLoading}
            hint={line2Hint}
            onChange={(v) => this.onFieldChanged("addressLine2", v)}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.editorState.addressLine3}
            disabled={this.props.isLoading}
            hint={line3Hint}
            onChange={(v) => this.onFieldChanged("addressLine3", v)}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <PrimaryTextField
            {...this.state.editorState.city}
            disabled={this.props.isLoading}
            hint={cityHint}
            onChange={(v) => this.onFieldChanged("city", v)}
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <PrimaryTextField
            {...this.state.editorState.postcode}
            disabled={this.props.isLoading}
            hint={postcodeHint}
            onChange={(v) => this.onFieldChanged("postcode", v)}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <CountryPicker
            {...this.state.editorState.country}
            disabled={this.props.isLoading}
            hint={countryHint}
            onChange={(v) => this.onFieldChanged("country", v)}
            type="text"
          />
        </Grid>

        {this.props.isAdmin ? (
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
                <strong>{agentLabel}</strong>
              </ColoredText>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isAgent}
                    onChange={() => this.onSwitcherChange()}
                    color="primary"
                  />
                }
                label={agentHint}
              />
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12} />

        <Grid item xs={12}>
          <PrimaryButton
            label={continueButton}
            endIcon={<ArrowIcon />}
            onClick={this.onContinuePressed}
            isLoading={this.props.isLoading}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  // MARK: Handlers

  onFieldChanged = (field: keyof OrganisationEditorState, value: string) => {
    const newState = { ...this.state };
    newState.editorState[field] = { value: value };
    this.setState(newState);
  };

  onSwitcherChange = () => {
    const newState = {
      ...this.state,
      isAgent: !this.state.isAgent,
    };
    this.setState(newState);
  };

  onContinuePressed = () => {
    if (this.validate()) {
      const address: Address = {
        line1: this.state.editorState.addressLine1.value,
        line2: this.state.editorState.addressLine2.value,
        line3: this.state.editorState.addressLine3.value,
        city: this.state.editorState.city.value,
        postcode: this.state.editorState.postcode.value,
        country: this.state.editorState.country.value,
      };

      const form: CreateOrganisationForm = {
        organisationName: this.state.editorState.organisationName.value,
        organisationNumber: this.state.editorState.organisationContactNumber.value,
        vatNumber: this.state.editorState.vatNumber.value,
        address: address,
        isAgent: this.state.isAgent,
      };

      this.props.onContinuePressed(form);
    }
  };

  // MARK: Validation

  validate = (): boolean => {
    const result = validateOrganisationEditor(this.state.editorState, this.props.t);
    if (!result.isValid) {
      this.setState({
        ...this.state,
        editorState: result.newState,
      });
    }

    return result.isValid;
  };
}

export default withTranslation()(OrganisationEditor);
