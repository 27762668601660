const translations = {
  translations: {
    languages: {
      en: "English",
      fr: "Français",
      de: "Deutsche",
    },

    language: {
      code: "FR",
    },
    buttons: {
      download: "Download ProCheck PDF",
      export: "Exporter",
      save: "Enregistrer",
      cancel: "Annuler",
      close: "OK",
      back: "Retour",
      confirm: "Confirmer",
      retry: "Essayer à nouveau",
      signOut: "Se déconnecter ",
      yes: "Oui",
      remove: "Effacer",
      no: "Non",
      next: "Suivant",
      move: "Move",
      clear: "Clear",
      search: "Search",
    },

    divider: {
      option: "or",
    },

    countryCode: {
      AU: "Australie",
      GB: "Royaume-Uni",
      IE: "République d'Irlande",
      AT: "Autriche",
      FR: "France",
      MISSING: "Pays non renseigné",
    },
    error: {
      requiredPlaceholder: "%s est requis ",
      unauthorizedPage: "Vous n'êtes pas autorisé à visiter cette page",
      noActiveOrganisation: "Sélectionner une organisation",
      noCountryError: "Pays renseigné introuvable",
      fallback: "Une erreur est apparue, merci de réessayer ultérieurement ",
      status401: "Votre session a expiré, merci de vous reconnecter à nouveau",
      status403: "Vous n'êtes pas autorisé à accéder à cette page ",
      status404: "Nous n'avons rien trouvé",
      status429:
        "Nous avons reçu un trop grand nombre de requêtes sur votre compte. veuillez attendre quelques instants avant de vous reconnecter.",
      status422Terms: "Merci d'accepter les termes et conditions ",
      status422Profile: "Veuillez compléter votre profil d'utilisateur",
      status422Organisation: "Vous devez au moins appartenir à l'administration. ",
      status422RequiredField: "Merci d'entrer des coordonnées valides",
      inviteStatus404: "Le compte n'existe pas ",
      inviteStatus409: "Cet utilisateur a déjà été invité",
      inviteStatus400: "Vous ne pouvez pas vous inviter",

      propertyStatus404: "Propriété introuvable",
      userDetail404: "utilisateur introuvable",
      organisationDetail404: "Organisation introuvable",
      countryList404: "Aucun pays n'a été trouvé",
      updateCountry404: "Pays introuvable",
      updateCountry422: "Erreur de mise à jour pays",
      createCountry422: "Erreur création pays ",
      adminCreateOrg422: "Un agent a déja été créé pour ce pays",
    },
    notificationMessageCode: {
      3000: "The batteries are in good condition.",
      3001: "The battery levels are low, replacements required in next 2 months. ",
      3002: "The battery levels are very low, replacements are required immediately.",
      4000: "Filter is currently connected and reporting",
      4001: "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5000: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      5001: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5002: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
      6000: "Service next due: {{date}}",
      6001: "Service overdue: {{date}}",
      6002: "Service due: {{date}}",
    },

    connectionMessage: {
      GSM: "Is there a good signal present?",
      WIFI: "Has Wi-Fi router or password changed?",
    },
    loginPage: {
      title: "Connecter",
      emailFieldLabel: "Adresse mail",
      passwordFieldLabel: "Mot de passe",
      forgotPasswordButton: "Mot de passe oublié ?",
      continueButton: "Continuer ",
      registerPrompt: "Vous n'avez pas de compte ? ",
      registerButton: "S'enregister ici",
      emailRequired: "Adresse mail requise ",
      passwordRequired: "Mot de passe requis ",
      loginError:
        "Connexion impossible. merci de rentrer à nouveau vos identifiants et essayez à nouveau",
    },

    QuestionPageOne: {
      title: "Which of the following best describes you?",
      choiceOne: "Heating Engineer (Independent)",
      choiceTwo: "Plumber",
      choiceThree: "Residential Contractor",
      choiceFour: "Residential Contractor (50+ contractors)",
      choiceFive: "Other",
      questionOneButton: "continue",
    },
    QuestionPageTwo: {
      title: "Where do you do most of your work?",
      choiceOne: "Residential (private Residence)",
      choiceTwo: "New Build",
      choiceThree: "Social Housing/HA/LA",
      choiceFour: "Utility",
      choiceFive: "Boiler Manufacturer/OEM",
      choiceSix: "Other",
      questionTwoButton: "Proceed To Dashboard",
    },

    homezoneUnsubscribePage: {
      title: "HomeZone Subscription",
      description: "Are you sure you want to unsubscribe from HomeZone?",
      unsubscribeButton: "Unsubscribe",
      backButton: "To Homepage",
      success: "You have successfully unsubscribed from the HomeZone",
      unsubscribeError:
        "You have already unsubscribed from the HomeZone, or the token is invalid",
    },

    addPropertyButton: {
      label: "Ajouter propriété",
    },
    addPropertyDialog: {
      title: "Ajouter une propriété",
      addressSectionTitle: "Adresse",
      nameFieldHint: "Nom de l'organisation",
      numberFieldHint: "Numéro de téléphone de l'organisation",
      addressLine1Hint: "Adresse ligne 1",
      notesHint: "Informations optionnelles",
      addressLine2Hint: "Adresse ligne 2 ",
      addressLookupButton: "Vérifier adresse ",
      addressCityHint: "Ville",
      countyHint: "Pays",
      addressPostcodeHint: "Code postal",
      addressCountryHint: "Pays",
      contactDetailsTitle: "Informations complémentaires du propriétaire",
      firstNameHint: "Prénom",
      lastNameHint: "Nom",
      contactNumberHint: "Numéro du contact ",
      emailHint: "Adresse mail",
      saveButton: "Sauvegarder",
      skipButton: "Passer ",
      backButton: "Retour",
      nextButton: "Suivant",
      addError: "Impossible d'ajouter une propriété",
    },
    addPropertyAddressForm: {
      line1Error: "Ligne 1 requise",
      cityError: "Ville requise ",
      postcodeError: "Code postal requis",
      countryError: "Sélectionnez un pays ",
    },
    addPropertyContactForm: {
      contactFirstNameError: "Prénom requis ",
      contactLastNameError: "Nom requis",
      contactNumberError: "Numéro du contact requis",
      emailError: "Email requis",
    },
    propertySearchBar: {
      placeholder: "Chercher une propriété",
    },
    registrationPage: {
      title: "Enregistrement ",
      emailFieldHint: "Email",
      passwordFieldHint: "Mot de passe",
      smartphoneHint:
        "Veuillez noter : si vous visualisez ceci depuis votre smartphone, assurez-vous de fermer l’écran lorsque vous acceptez le lien de vérification et poursuivez l’enregistrement via l’application. ",
      registerButton: "Enregister",
      emailRequired: "Email requis",
      passwordRequired: "Mot de passe requis",
      emailInvalidError: "Merci d'entrer une adresse mail valide",
      passwordValidationError: "Mot de passe incorrect",
      returnSignInButton: "Retour à la connexion",
    },
    validationHelper: {
      password:
        "Votre mot de passe doit au moins contenir 8 caractères, avec majuscules, minuscules, un chiffre et un caractère spécial.",
      passwordChange:
        "Votre mot de passe doit au moins contenir 8 caractères, avec majuscules, minuscules, un chiffre et un caractère spécial.",
      passwordReset:
        "Votre mot de passe doit au moins contenir 8 caractères, avec majuscules, minuscules, un chiffre et un caractère spécial.",
    },
    termsAndConditionsPage: {
      title: "Termes et conditions",
      acceptButton: "Accepter",
      termsAndConditions: "Termes et conditions",
    },
    accountCreatedPage: {
      title: "Compte créé",
      message:
        "Votre compte a été créé avec succès. Un email de vérification vous a été envoyé , veuillez s’il vous plaît l’ouvrir et cliquez sur le lien pour activer votre compte.",
      signInButton: "Connexion",
    },
    accountActivationPage: {
      title: "Activer compte",
      successPageTitle: "Activation du compte effectuée avec succès",
      successMessage:
        "Votre compte a été activé avec succès, veillez vous connecter pour accéder à votre compte",
      signInButton: "Se connecter",
      errorMessage:
        "Impossible d'activer votre compte. veillez vous assurer que le lien est correct. ",
    },
    forgotPassword: {
      requestPageTitle: "Mot de passe oublié",
      emailHint: "Adresse mail",
      requestButton: "Renouveler le mot de passe",
      requestPageSubtitle: "Mot de passe ADEY Sense® oublié?",
      content:
        "Le saviez-vous ? Vous disposez désormais d’un seul identifiant pour tous les services ADEY. Par conséquent, toutes les modifications que vous faites ici s’appliqueront automatiquement à vos comptes ADEY ProCheck et ADEY Sense.\n\nEntrez votre email ci-dessous afin de pouvoir recevoir le mail de réinitialisation de votre mot de passe.",
      signInPage: "Se connecter",
      emailRequiredError: "Adresse mail requise",
      emailInvalidError: "Adresse mail non valide",
    },
    forgotPasswordTokenExpired: {
      title: "Forgot Password",
      message:
        "Your reset password link has expired, please go to the reset password page and try again.",
      homepageButton: "Return to sign in",
      passwordResetButton: "To password reset",
    },
    forgotPasswordSent: {
      pageTitle: "Renvoyer l'email",
      message:
        "Nous avons envoyé un email avec les instructions pour renouveler votre mot de passe",
      signInButton: "Se connecter",
    },

    passwordResetRequired: {
      resetRequiredTitel: "Réinitialiser le mot de passe",
      resetRequiredMessage:
        "La réinitialisation du mot de passe de cet utilisateur est nécessaire pour continuer à utiliser le portail. Veuillez réinitialiser votre mot de passe en appuyant sur le bouton ci-dessous",
      resetRequiredProClubMessage:
        "Nous avons amélioré notre sécurité pour protéger vos informations. Pour continuer à utiliser les services ADEY, veuillez réinitialiser votre mot de passe maintenant. Votre mot de passe doit comporter au moins 8 caractères et contenir au moins 1 minuscule, majuscule, chiffre et caractère spécial.",
    },

    proClub: {
      status412:
        "As you are already an existing ADEY ProClub user, you do not need to register again. Please login using your existing ProClub email and password and follow instruction on screen. \n \n Forgotten your password? /forgot-password to reset",
      status412Link: "Cliquez ici",
    },

    registrationErrors: {
      exists:
        "Comme vous déjà un utilisateur ADEY Sense , vous n’avez pas besoin de vous réinscrire.  Veuillez vous connecter en utilisant vos identifiants Sense existants et suivez les instructions à l’écran. \n \nSi vous avez oublié votre mot de passe, vous pouvez le réinitialiser.",
    },

    forgotPasswordSubmit: {
      pageTitle: "Renouveler le mot de passe",
      passwordHint: "Nouveau mot de passe ",
      resetButton: "Renouveler le mot de passe ",
      smartphoneHint:
        "Veuillez noter : si vous visualisez ceci depuis votre smartphone , assurez-vous de fermer l’écran après avoir réinitialisé votre mot de passe et continuez de vous connecter via l’application. ",
      successTitle: "Renouvellement du mot de passe effectué avec succès",
      successMessage: "Veuillez vous connecter pour accéder à votre compte",
      successSignInButton: "Se connecter",
      requiredError: "Nouveau mot de passe requis",
      invalidError: "Mot de passe non valide ",
    },
    personalInfoPage: {
      title: "Informations personnelles",
      firstNameHint: "Prénom",
      surnameHint: "Nom",
      contactNumberHint: "Numéro du contact ",
      continueButton: "Continuer ",
      postcodeHint: "Code postal",
      confirmNumberHint: "Confirmer à nouveau le numéro de contact ",
      firstNameRequiredError: "Prénom requis",
      surnameRequiredError: "Nom requis",
      postcodeRequiredError: "Code postal requis",
      contactNumberRequiredError: "Numéro du contact requis",
      confirmNumberRequiredError: "Confirmer à nouveau le numéro de contact",
      confirmNumberIncorrectError: "Le numéro initial et sa confirmation sont différents",
    },
    createOrganisationPage: {
      title: "Créer une organisation",
      fallbackError: "Impossible de créer une organisation",
    },
    organisationEditor: {
      agentLabel: "Agent Adey ",
      addressLabel: "Adresse",
      agentHint: "Créer une organisation en tant qu'agent ",
      nameFieldHint: "Nom de l'organisation",
      numberFieldHint: "Téléphone de l'organisation",
      vatNumberHint: "Numéro TVA ",
      addressLine1Hint: "Adresse ligne 1",
      addressLine2Hint: "Adresse ligne 2",
      addressLine3Hint: "Adresse ligne 3",
      addressCityHint: "Ville",
      addressPostcodeHint: "Code postal ",
      addressCountryHint: "Pays",
      continueButton: "Continuer ",
    },
    organisationLogoUploader: {
      addButton: "Ajouter",
      subtitle: "Logo de l'organisation ",
    },
    createOrganisationAdminForm: {
      title: "Créer une organisation ",
      nameFieldHint: "Nom de l'organisation ",
      nameRequiredError: "Nom de l'organisation requis ",
      numberFieldHint: "Numéro du contact de l'organisation",
      numberRequiredError: "Numéro du contact de l'organisation requis",
      invitePrompt: "Vous n'avez pas tous les détails ?",
      inviteButton: "Envoyer une invitation pour créer une organisation ",
      continueButton: "Continuer",
    },
    organisationOwnerAdminForm: {
      title: "Propriétaire de l'organisation",
      emailHint: "Adresse email",
      emailRequiredError: "Adresse mail requise ",
      emailInvalidError: "Adresse mail non valide",
      confirmEmailHint: "Confirmer l'adresse mail",
      confirmEmailRequiredError: "Confirmation de l'adresse mail requise ",
      confirmEmailError: "L'adresse mail et sa confirmation ne correspondent pas ",
      finishButton: "Terminer ",
      backButton: "Retour ",
      error: "Impossible d'inviter l'utilisateur",
    },
    createOrganisationAdminEmailSent: {
      title: "Email d'invitation envoyé",
      body: "Nous avons envoyé une invitation par email à l'organisateur principal avec les instructions pour créer un compte ",
      returnButton: "Retour",
    },
    inviteOrganisationAdminForm: {
      title: "Inviter une organisation",
      emailHint: "Adresse email",
      emailRequiredError: "Adresse email requise",
      confirmEmailHint: "Confirmer l'adresse email",
      confirmEmailRequiredError: "Confirmation de l'adresse email requise",
      emailMatchError:
        "La confirmation de l'adresse email ne correspond pas avec avec l'email original",
      sendInviteButton: "Envoyer une invitation",
      backButton: "Retour",
      error: "Impossible de soumettre une invitation ",
    },
    inviteOrgDialog: {
      errorTitle: "Erreur d'invitation ",
    },
    inviteEntityPanel: {
      inviteContractorTitle: "Inviter prestataire",
      inviteUserTitle: "Inviter utilisateur",
      emailHint: "Adresse email",
      emailError: "Adresse email requise ",
      confirmEmailHint: "Confirmer l'adresse email",
      confirmEmailError: "Confirmation de l'adresse email requise ",
      emailMatchError: "Les email ne correspondent pas ",
      submitButton: "Envoyer une invitation",
      error: "Échec d'envoi de l'invitation ",
      userTypeHint: "Sélectionner le type d'utilisateur ",
      userTypeRequiredError: "Type d'utilisateur requis",
      descriptionText:
        "Pour inviter le prestataire désigné, veuillez vous assurez d’utiliser l’adresse du compte « Propriétaire/Super Admin »",
      contactHint: "Pour plus d'informations veuillez nous contacter ",
      contactEmail: "info.fr@adey.com",
      inviteAgentTitle: "Inviter agent",
      premiumInviteSummary:
        "Vous souhaitez que quelqu'un utilise les produits compatibles Sense en votre nom ? Aucun problème! Vous pouvez lier leur compte en ajoutant leur adresse e-mail sur l'écran suivant.",
      premiumAccessSubtitle: "This is a premium feature",
      infoSummary: "Veuillez contacter info.fr@adey.com pour plus d'informations",
      premiumAccessSummary:
        "En invitant des utilisateurs supplémentaires à rejoindre votre compte, le statut de votre compte passera à un compte de niveau entreprise, ce qui augmentera votre facturation mensuelle après l'expiration des périodes d'essai préétablies.  Veuillez contacter info.fr@adey.com pour plus d'informations",
      premiumAccessContractorSummary:
        "Vous souhaitez qu’un prestataire utilise les produits compatibles Sense à votre nom? Aucun problème ! Vous pouvez lier leur compte en ajoutant leur adresse e-mail sur l’écran suivant.",
    },
    organisationSwitcher: {
      createOrganisationButton: "Créer une organisation",
      organisationTitle: "Organisations ",
      organisationDetails: "Détail de l'organisation",
      accountTitle: "Compte ",
      signOut: "Se déconnecter ",
      accountButton: "Mon compte ",
      accountSettings: "Paramètres du compte ",
      fetchError: "Impossible d'afficher les détails de l'organisation ",
      subtitleViaFormat: "<ROLE> via <ORG>",
      agentButton: "Retour au tableau de bord de l'agent <ORG_NAME>",
    },
    userList: {
      ownerTab: "Propriétaire/ super admin ",
      adminTab: "Administrateurs",
      engineersTab: "Techniciens chauffage",
      invitesTab: "Invitations en attente ",
      developersTab: "Developers",
      searchPlaceholder: "Recherche d'un utilisateur ",
      inviteButton: "Inviter un utilisateur ",
      emptyText: "Pas d'utilisateur trouvé",
    },
    copyrightFooter: {
      text: "\nCopyright ADEY Innovation Ltd © {{date}}. Tous droits réservés",
      termsLink: "Termes et conditions ",
      privacyLink: "Politique de confidentialité",
      cookiesLink: "Politique de cookies",
      developer: "Developer",
    },
    propertyTable: {
      headerStatus: "Statuts",
      headerStatusMessage: "Statut des messages",
      headerProperty: "Adresse de propriété",
      headerEmail: "Email",
      headerMove: "Select",
      headerProducts: "Produits",
      headerOwner: "Propriétaire de l'organisation ",
      headerContractorName: "Nom du prestataire ",
      rowContractorNamePlaceholder: "Pas de prestataire assigné ",
      rowOwnerNamePlaceholder: "Nom du propriétaire manquant",
    },
    statusMessage: {
      testDuePlaceholder: "Test à %s",
      testFailed: "Recommandations ",
      testMissing: "Pas de tests",
      testOverdue: "Test en retard",
      testPassed: "Niveau de Qualité Acceptable",
    },
    propertyListPage: {
      errorTitle: "Impossible de charger les propriétés",
      errorRetryButton: "Essayer à nouveau",
      emptyMessage: "Pas de propriété trouvée ",
      searchBarPlaceholder: "Chercher une propriété",
    },
    selectedPropertiesPanel: {
      switchLabel: "Show only selected",
      countDescription: "{{count}} property selected",
      countDescription_plural: "{{count}} properties selected",
    },

    moveDeviceDialog: {
      searchPlaceholder: "Search properties",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your device has been moved successfully",
      successTitle_plural: "Your devices have been moved successfully",
      failureTitle: "Failed to move device",
      failureTitle_plural: "Failed to move your devices",
      title: "Confirm Move Device",
      title_plural: "Confirm Move Devices",
    },

    movePropertyDialog: {
      destinationTitle: "Find destination organisation",
      confirmationTitle: "Confirmation",
      successTitle: "Your property has been moved successfully",
      successTitle_plural: "Your properties have been moved successfully",
      failureTitle: "Failed to move property",
      failureTitle_plural: "Failed to move your properties",
      title: "Confirm Move Property",
      title_plural: "Confirm Move Properties",
      searchPlaceholder: "Search organisations",
    },
    moveProCheckDialog: {
      title: "Confirm Move Test",
      title_plural: "Confirm Move Tests",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your test has been moved successfully",
      successTitle_plural: "Your tests have been moved successfully",
      failureTitle: "Failed to move test",
      failureTitle_plural: "Failed to move your tests",
      searchPlaceholder: "Search properties",
    },

    moveEntitySuccessPanel: {
      description:
        "Successfully moved {{items, emphasizeList}} to <strong>{{destination}}</strong>. Closing this window will redirect you to the destination page.",
      descriptionPartial: "Following item has not been moved: {{items, emphasizeList}}",
      descriptionPartial_plural:
        "Following items have not been moved: {{items, emphasizeList}}",
      closeButton: "Close this dialog",
    },

    moveEntityErrorPanel: {
      description:
        "Failed to move {{items, emphasizeList}} to <strong>{{destination}}</strong>. Please try again later, or, if the error persists, contact administration.",
      closeButton: "Close this dialog",
    },

    moveEntityReviewPanel: {
      title: "Review selected items",
      entityTitle: {
        proCheck: "Move test",
        proCheck_plural: "Move tests",
        device: "Move device",
        device_plural: "Move devices",
        property: "Move property",
        property_plural: "Move properties",
      },

      moveEntityWarningPanel: {
        title: "Confirmation",
        descriptionProperty:
          "Are you sure you want to move {{items, emphasizeList}} to <strong>{{destionation}}</strong>?",
        descriptionOther:
          "Are you sure you want to move {{deviceType, emphasizeList}} from <strong>{{origin}}</strong> to <strong>{{destination}}<strong/>?",
      },
      destinationTitle: {
        property: "To property",
        organisation: "To organisation",
      },
    },

    filterMenu: {
      title: "Filtrer la vue",
      propertyStatusTitle: "Statut des propriétés",
      overdueStatusTitle: "Service Status",
      deviceStatusTitle: "Des produits",
    },
    adminOrgDetailTabs: {
      orgOwnerTab: "Propriétaire/ super admin",
      orgDetailTab: "Détails ",
      orgUserTab: "Utilisateurs",
      propertiesTab: "Propriétés",
      relatedOrgsTab: "Prestataires et organisations associées ",
      proCheckReports: "ProCheck Reports",
    },
    ragStatus: {
      green: "Bon",
      amber: "Nécessite une attention",
      red: "Nécessite une attention immédiate",
    },
    overdueStatus: {
      overdue: "Properties with service overdue",
      upcoming: "Properties due service in the next 30 days",
    },
    deviceStatus: {
      batteryLow: "Battery Low",
      batteryCritical: "Battery Critical",
      magnetiteLevelMedium: "Magnetite Level Medium",
      magnetiteLevelHigh: "Magnetite Level High",
      noContactWithDevice: "No Contact With Device",
    },
    ragStatusCard: {
      totalTitle: "Total ",
      propertiesSubtitle: "Propriétés",
      greenInfo:
        "La qualité d'eau de ce système de chauffage est satisfaisante - aucune action requise ",
      amberInfo:
        "La qualité d'eau de ce système de chauffage nécessite d'être surveillée - action corrective requise prochainement ",
      redInfo:
        "La quallité d'eau de ce système de chauffage nécessite une action immédiate - une visite d'intervention est requise ",
    },
    accountSettingsPage: {
      userSettingsLink: "Tableau de bord ",
      userSettingsTitle: "Paramètres du compte ",
      errorTitle: "Impossible de charger les données ",
      updateError: "Impossible de charger les informations du compte ",
      changePasswordSuccess: "Mot de passe changé avec succès ",
    },
    userDetailsPanel: {
      postcodeHeader: "Code postal",
      firstNameHeader: "Prénom",
      lastNameHeader: "Nom",
      testerHeader: "Tester",
      testerHint: "Enable testing functionality",
      contactNumberHeader: "Numéro du contact ",
      emailHeader: "Email",
      passwordHeader: "Mot de passe ",
    },
    changePasswordPanel: {
      oldPasswordFieldHint: "Mot de passe actuel ",
      newPasswordFieldHint: "Nouveau mot de passe ",
      oldPasswordRequired: "Mot de passe actuel requis",
      newPasswordRequired: "Nouveau de mot de passe requis",
      updatePasswordButtonLabel: "Mettre à jour le mot de passe ",
    },
    propertyDetailPage: {
      propertyLink: "Propriétés ",
      errorTitle: "Impossible de charger la propriété",
      updateError: "Impossible de charger la propriété",
      ownerOrgLabel: "Organisation du propriétaire ",
    },
    addressPanel: {
      addressHeader: "Adresse ",
      contactHeader: "Nom du contact ",
      contactNumberHeader: "Numéro du contact ",
      emailHeader: "Email",
      notesHeader: "Notes ",
    },
    productGrid: {
      title: "Produits",
    },
    productSummary: {
      productTitle: "Produit",
      productTypeTitle: "Type de produit",
      statusMessageTitle: "Statut du message",
    },
    productReportTable: {
      title: "Rapports du produit",
      headerContractor: "Prestataire",
      headerTester: "Tester",
      headerStatus: "Statut",
      headerReportee: "Email du testeur",
      headerProperty: "Propriété",
      headerEmail: "Email",
      headerDate: "Date",
      downloadReportLabel: "Télécharger le rapport",
      emailReportLabel: "Exporter",
      noReportsTaken: "Aucun rapport pris",
      noReportsFound: "Aucun rapport trouvé",
    },
    resendActivationEmailRequestPage: {
      title: "Activation du compte requise",
      message:
        "Vous devez activer votre compte afin d'accéder au portail Sense. un email d'activation vous été envoyé, veuillez cliquer sur le lien pour continuer ",
      resendEmailButton: "Renvoyer l'email d'activation",
      signInPrompt: "Avez vous déjà activé votre compte ?",
      signInButton: "Connectez-vous à votre compte",
      error: "Impossible de renvoyer en email. Veuillez essayer ultérieurement",
    },
    resendActivationEmailSuccessPage: {
      title: "Mail d'activation de votre compte envoyé",
      message:
        "Nous vous avons envoyé un email d'activation, veuillez suivre les instructions pour activer votre compte",
      signInPrompt: "Compte activé ? ",
      signInButton: "Se connecter à votre compte",
    },
    addressEditor: {
      line1: "Ligne 1",
      line2: "Ligne 2",
      line3: "Ligne 3",
      city: "Ville",
      postCode: "Code postal ",
      country: "Pays",
      line1RequiredError: "Ligne 1 requise ",
      cityRequiredError: "Ville requise",
      postCodeRequiredError: "Code postal requis",
      countryRequiredError: "Pays requis ",
      addressLookupButton: "Confirmation de l'adresse ",
    },

    limboAddress: {
      message:
        "This property is missing the full address. Please complete it before you could fully access and edit other property fields",
    },

    limboSignpost: {
      inviteTitle: "Joindre une société existante ",
      createTitle: "Créer un compte",
      createAction: "Créer une organisation ",
      inviteBody:
        "Vous devriez recevoir une invitation d'une société existante pour précéder à votre enregistrement. veuillez demander une invitation d'un administrateur auprès de la société en charge du compte pour continuer. au besoins merci de nous contacter. ",
      createBody:
        "Veuillez compléter les sections suivantes pour enregistrer le compte de votre société pour la première fois. pour plus d'informations veuillez nous contacter",
      contactLink: "info.fr@adey.com",
    },
    headerTabs: {
      dashboard: "Tableau de bord",
      userList: "Utilisateurs",
      reports: "Reports",
      organisations: "Organisations ",
      agents: "Agents Adey",
      memberUserList: "Utilisateurs ",
      properties: "Propriétés",
      totalOrganisationList: "Organisations",
    },
    organisationListPage: {
      searchPlaceholder: "Rechercher une organisation",
      addButton: "Inviter un sous traitant",
      loadError: "Impossible de charger les organisations",
      emptyMessage: "Organisations introuvables ",
      emptyInviteMessage: "Pas d'invité trouvé",
      contractorTab: "Sous traitants",
      associatedOrgsTab: "Organisations associées ",
      invitesTab: "Invités en attente",
      inviteTooltip:
        "Liste d'invitations qui n'ont pas encore été acceptées par les utilisateurs",
      contractorTooltip: "Liste de entreprises travaillant pour mon organisation",
      associatesTooltip:
        "La liste de entreprises travaillant pour mon organisation y travaille",
    },
    userTable: {
      nameHeader: "Nom",
      emailHeader: "Email",
      phoneHeader: "Numéro du contact",
    },
    orgTable: {
      nameHeader: "Nom",
      propertiesHeader: "Propriétés",
      managedPropertiesHeader: "Propriétés gérées",
      phoneHeader: "Numéro du contact",
    },
    agentTable: {
      nameHeader: "Nom",
      phoneHeader: "Numéro du contact",
      countryHeader: "Pays",
    },
    agentSwitch: {
      labelTitle: "Agent Adey",
      switchTitle: "Accès agent Adey",
      agentExistsHint:
        "Vous ne pouvez pas faire de cette organisation un Adey ADEY car celui-ci existe déjà pour ce pays",
    },
    agentDialog: {
      title: "Accès agent Adey",
      removeAgentText:
        "Etes vous sûr de révoquer cet agent Adey ? Cela contribuera à transformer cette organisation habituelle.",
      removeAgentWarning:
        "Note : Les organisations gérées par %s ne seront plus assignées à l'agent Adey",
      grantAgentAccessText:
        "Etes vous sûr de vouloir attribuer l'accès à l'agent Adey à %s ? ",
      grantAgentAccessWarning:
        "Note : Ils perdront l'accès aux fonctionnalités habituelles de l'organisation",
    },
    agentAssignmentDialog: {
      agentRemovedTitle: "Agent Adey révoqué",
      agentAssignedTitle: "Accès agent Adey accordé",
      agentRemovedText:
        "L'accès de l'Agent Adey de % est révoqué  avec succès. Appuyez sur confirmer pour aller à la page détaillée de l'organisation. ",
      agentAssignedText:
        "L'accès de l'agent Adey à % est accordé avec succès. Appuyez sur confirmer pour aller à la page des détails de l'agent Adey",
    },
    inviteTable: {
      emailHeader: "Email",
      role: "Rôle",
      actionsHeader: "Actions",
    },
    acceptOrganisationInvitePanel: {
      title: "Rejoindre l'organisation",
      messageFormat:
        "Vous avez été invité à rejoindre l'organisation avec un role de %s. veuillez accepeter l'accès.",
      acceptButton: "Accepter l'invitation ",
      acceptError: "Impossible d'accepter l'invitation",
      rejectError: "Impossible de rejeter l'invitation ",
      declineButton: "Refuser ",
    },
    acceptContractorInvitePanel: {
      title: "Invitation du sous traitant",
      messageFormat:
        "Votre organisation a été invitée à devenir sous traitant. Veuillez accepter pour y avoir accès. ",
      acceptButton: "Accepter l'invitation ",
      acceptError: "Impossible d'accéder à l'invitation ",
      rejectError: "Impossible de rejecter l'invitation",
      declineButton: "Refuser",
    },
    userTableMenu: {
      removeUser: "Retirer l'utilisateur",
      confirmActionText: "Etes vous sûr ? ",
    },
    orgTableMenu: {
      removeOrg: "Enlever l'organisation ",
      confirmActionText: "Etes vous sûr ? ",
      removeOrgError: "Impossible d'effacer l'organisation ",
    },
    itemTableMenu: {
      confirmActionText: "Etes vous sûr ? ",
    },
    invitePendingFlag: {
      label: "Invitation en attente",
    },
    pendingInviteMenu: {
      resendEmail: "Renvoyer l'email d'invitation",
      emailResent: "Email d'invitation renvoyé",
      resendError: "Impossible de renvoyer l'invitation",
      revokeError: "Impossible de révoquer invitation",
      revokeInvite: "Révoquer l'invitation",
    },
    userDetailPage: {
      firstNameLabel: "Prénom",
      lastNameLabel: "Nom",
      emailLabel: "Email",
      numberLabel: "Numéro du contact",
      roleLabel: "Rôle",
      organisationLabel: "Organisation",
      loadError: "Impossible d'accéder aux coordonées de l'utlisateur",
      removeError: "Impossible d'enlever l'utlisateur",
    },

    userDetailPageTabs: {
      userDetailTab: "Détails de l'utilisateur",
      proCheckTab: "ProCheck Results",
    },

    orgDetailPage: {
      orgNameLabel: "Nom de l'organisation",
      addressLabel: "Adresse",
      numberLabel: "Numéro du contact",
      vatLabel: "Numéro de TVA",
      removeError: "Impossible d'enlever l'organisation ",
      agentError:
        "Cette organisation est un agent. Vous ne pouvez pas le voir comme une organisation en tant que telle",
    },
    orgDetailsSwitcher: {
      detailsLabel: "Détails",
      ownerLabel: "Propriétaire/ super admin",
      propertiesLabel: "Propriétés",
    },
    roles: {
      owner: "Super admin",
      admin: "Admin",
      developer: "Developer",
      installer: "Technicien chauffage",
      contractor: "Sous traitant",
      agent: "Agent Adey",
    },
    removeUserButton: {
      labelPlaceholder: "Retirer depuis %s",
    },
    confirmRemoveUserDialog: {
      title: "Retirer l'utilisateur",
      bodyPlaceholder:
        "Etes vous sûr de vouloir effacer <user_name> ? ils perdront l'accès à toutes les propriétés au sein de <org_name>",
      removeButtonPlaceholder: "Retirer",
    },
    userRemovedDialog: {
      title: "Utilisateur retiré",
      bodyPlaceholder: "<user_name> a été retiré de <org_name>",
      confirmButton: "Retourner à la liste",
    },
    removeContractorButton: {
      labelPlaceholder: "Retiré de %s",
    },
    adminRemoveContractorDialog: {
      title: "Enlever le sous traitant",
      bodyPlaceholder:
        "Etes vous sûr de vouloir retirer le <contractor_name> de <contractee_name>? <contractor_name> perdra accès à toutes les propriétés de <contractee_name>",
      removeButtonPlaceholder: "Supprimer",
    },
    confirmRemoveContractorDialog: {
      title: "Supprimer le sous traitant",
      bodyPlaceholder:
        "Etes vous sûr de vouloir supprimer <contractor_name> ? ils perdront accès à toutes les propriétés au sein de <org_name>",
      removeButtonPlaceholder: "Supprimer",
    },
    contractorRemovedDialog: {
      title: "Sous traitant supprimé",
      bodyPlaceholder:
        "<contractor_name> a été supprimé de <org_name> comme sous traitant",
      confirmButton: "Retourner à la liste",
    },
    currentOrganisationPage: {
      organisationNameLabel: "Nom de l'organisation ",
      addressLabel: "Adresse",
      contactNumberLabel: "Numéro du contact ",
      vatNumberLabel: "TVA intracommunautaire",
      pageTitle: "Organisation actuelle ",
      dashboardLink: "Tableau de bord ",
      editError: "Impossible d'éditer ces détails",
    },
    createOwnerOrganisationPromptPanel: {
      title: "Invitation du sous traitant",
      messageFormat:
        "Vous avez été invité comme sous traitant pour %s. pour accepter Veuillez créer une organisation",
      createOrganisationButton: "Créer l'organisation",
      declineButton: "Refuser",
    },
    createOrganisationPanel: {
      title: "Créer l'organisation",
      backButton: "Retour",
      error: "Impossible de créer une organisation ",
      tooltip:
        "Créer une nouvelle organisation vous séparera des autres organisations avec lesquelles vous êtes actuellement lié",
    },
    stopContractingButton: {
      title: "Arrêter le contrat avec %s",
    },
    confirmStopConfirmingDialog: {
      title: "Arrêter le contrat ",
      bodyPlaceholder:
        "Si vous arrêtez le contrat avec %s vous perdrez accès aux propriétés qui vous ont été assignées. ",
      stopContractingButton: "Arrêter le contrat",
    },
    adminRemoveContracteeDialog: {
      title: "Supprimer le sous traitant",
      bodyPlaceholder:
        "Etes vous certain de vouloir supprimer <contractee_org>? <contractor_org> perdra accès aux propriétés <contractee_org> qui lui ont été assignées.",
      stopContractingButton: "Supprimer",
    },
    stopContractingCompletedDialog: {
      title: "Arrêter le contrat",
      bodyPlaceholder: "Vous avez été effacé du contrat pour %s",
      returnToListButton: "Retourner à la liste",
    },
    addressLookup: {
      countryField: "Pays",
      countryRequiredError: "Pays requis",
      title: "Rechercher adresse",
      searchAgainButton: "Rechercher à nouveau",
      fetchError: "Impossible de trouver l'adresse",
      emptyError: "Aucune adresse n'a été trouvée pour ces mots de recherche",
      retrieveError: "Impossible de charger les détails de l'adresse",
      searchButton: "Rechercher",
      searchPlaceholder: "Rechercher une adresse ou un code postal",
      requiredError: "Veuillez entrer un test de recherche",
    },
    homeButton: {
      adminText: "Portail administrateur",
    },
    platformUsersList: {
      tableTitle: "Utilisateur",
      searchBarPlaceholder: "Recherche d'un utilisateur",
      loadError: "Impossible d'afficher la liste des utlisateurs",
      emptyMessage: "Aucun utilisateur trouvé avec ces mots de recherche",
    },
    platformAgentList: {
      tableTitle: "Agents Adey ",
      searchBarPlaceholder: "Recherche d'un agent Adey ",
      loadError: "Impossible de trouver un agent ",
      emptyMessage: "Aucun agent trouvé avec ces mots de recherche",
    },
    platformOrganisationsList: {
      tableTitle: "Organisations ",
      addOrganisationButton: "Ajouter organisation",
      searchBarPlaceholder: "Recherche d'une organisation ",
      loadError: "Impossible d'afficher une liste d'organisation ",
      emptyMessage: "Aucune organisation trouvée avec ces mots de recherche",
    },
    inviteSentPanel: {
      title: "Invitation envoyée",
      bodyPlaceholder: "Un email d'invitation a été envoyé à %s",
      continueButton: "Continuer",
    },

    senseDeviceInfoCard: {
      lastReading: "Last Reading",
      product: "Product",
      status: "Status",
      totalStatus: "Overall Status",
      serialNumber: "Serial Number",
      moreInfoButton: "More Info",
    },

    senseDeviceReadingsCard: {
      magnetiteLevel: "Magnetite Level",
      statusMessage: "Last Notifications",
      service: "Service",
      lastReading: "Last reading",
      connection: "Connection",
      lastCleaned: "Last cleaned",
    },

    senseDeviceStatus: {
      green: "Good",
      amber: "Needs Attention",
      red: "Needs Immediate Attention",
    },

    senseDevices: {
      loadError: "Unable to load Sense Devices",
      activationError: "Unable to change device activation status",
    },

    selectedSenseDevice: {
      loadError: "Unable to load selected Sense Device",
    },

    senseDeviceBatteryIndicator: {
      title: "Battery level",
      low: "The battery levels are very low, replacements are required immediately.",
      medium: "The battery levels are low, replacements required in next 2 months. ",
      good: "The batteries are in good condition.",
      noSignal: "Could not get battery level",
    },

    senseDeviceContactIndicator: {
      noContact:
        "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      contactOk: "Filter is currently connected and reporting",
    },

    senseDeviceMagnetiteIndicator: {
      noLevel:
        "Could not get device magnetite level. If the problem persists, please contact a member of the ADEY support team info@adey.com",
      1: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      2: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      3: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      4: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
    },

    proCheck: {
      reportName: "Rapport %s",
      name: "ProCheck",
      loadError: "Impossible de charger les résultats ProCheck",
      statusTitle: "Statuts",
      latestTestTitle: "Dernier test en date",
      moreInfoButton: "Plus d'informations",
      downloadLatestTestButton: "Télécharger le dernier test ",
      noTestsLabel: "Aucun test effectué",
      pass: "Niveau de Qualité Acceptable",
      recommendation: "Recommandations",
      productTitle: "Produit",
      downloadError: "Impossible d'exporter le rapport",
      productType: "Test d'eau",
      exportSuccess: "Exporté avec succès ! ",
    },
    proCheckTabs: {
      detailsTab: "Détails du rapport",
      resultsTab: "Résultats du test",
    },
    proCheckDetailsTab: {
      timezone: "Fuseau horaire",
      date: "Date",
      time: "Heure",
      email: "Email",
      engineer: "Technicien",
      reason: "Conditions / raison du test",
      address: "Adresse",
      type: "Type",
      filter: "Filtre installé",
      boilerNumber: "Numéro de série de la chaudière ou de la PAC",
      jobReference: "Référence de chantier",
    },
    ProCheckResultsTab: {
      title: "Résultas du test",
      summary: "Résumé",
      note: "Notes complémentaires",
      waterSample: "Echantillon d'eau",
      testCard: "Carte de test",
      location: "Lieu",
      waterTest: "Turbidité",
    },
    ProCheckWaterTest: {
      pass: "Test d'eau validé",
      fail: "Echec du test d'eau",
      recommendation: "Echec du test de l'eau - veuillez consulter les recommandations",
    },
    ProCheckMaps: {
      locationMissing: "Le lieu du test procheck est manquant",
    },
    ProCheckSummary: {
      colourTestMissing:
        "Le contrôle visuel a échoué, confirmation de la présence de corrosion. Il est recommandé d'effectué une analyse laboratoire approfondie ou un nettoyage complet du réseau.",
      molyPassRec:
        "Niveau d'inhibiteur acceptable: L'installation et la qualité d’eau sont protégées par un Inhibiteur de corrosion. Assurez-vous de la pose d'un filtre ADEY sur le retour de l'installation et du contrôle annuel de l'Inhibiteur de corrosion MC1+. \n\nVeuillez noter: L'ajout du MC10+ antialgues sur plancher chauffant ou système basse température sera recommandé. ",
      molyLowRec:
        "Inhibiteur insuffisant détecté: la qualité d’eau de cette installation est à risque de dégradation rapide. Assurez-vous d'effectuer un contrôle du niveau d'embouage à l'aide du Demo Jar ADEY (si disponible). Refaire un dosage de l'Inhibiteur de corrosion  MC1+. Une circulation de 30 minutes minimum sera recommandée avant un nouveau contrôle. \n\nVeuillez noter: L'ajout du MC10+ antialgues sur plancher chauffant ou système basse température sera recommandé.",
      ironHighRecCopperPass:
        "Iron level is high. If left untreated it can cause performance deterioration and, ultimately, failure. Full system clean is advised.",
      ironHighRec:
        "Le niveau de corrosion est élevé: la qualité d’eau de cette installation est dégradée et le risque d'embouage est avéré.  Assurez-vous d'effectuer un contrôle du niveau d'embouage à l'aide du Demo Jar ADEY (si disponible). Un test en laboratoire peut-être recommandé ou un nettoyage complet du système selon les Meilleures Pratiques ADEY.  (Filtre / MC3+ / MC10+ / MC1+). Un test ProCheck en fin de chantier sera recommandé afin de valider la protection du système et l'obligation de résultat.",
      ironModerateRec:
        "Le niveau de corrosion est modéré: tout en restant encore acceptable, la qualité d’eau de cette installation se dégrade. Assurez-vous d'effectuer un contrôle du niveau d'embouage à l'aide du Demo Jar ADEY (si disponible). assurez-vous de la pose d'un filtre ADEY sur le retour de l'installation.",
      ironPassRec:
        "Le niveau de corrosion est faible: la qualité d’eau de cette installation est encore acceptable. Assurez-vous toutefois d'effectuer un contrôle du niveau d'embouage à l'aide du Demo Jar ADEY (si disponible). Assurez-vous de la pose d'un filtre ADEY sur le retour de l'installation.",
      ironModerateCopperPresentRec:
        "Iron level is moderate. No further action required at this stage.",
      ironPassCopperPresentRec: "Iron level is low. No further action required",
      phPassRec:
        "Niveau de PH: Ce paramètre est satisfaisant. \n\nAfin de maintenir un niveau de pH satisfaisant, assurez-vous du bon dosage de l'inhibiteur de corrosion MC1+.",
      phLowRec:
        "Le Ph est bas: la qualité d’eau de cette installation est dégradée et l'ensemble des métaux  (aluminium/cuivre/laiton/acier) court un risque de corrosion généralisée. Un test en laboratoire peut-être recommandé ou un nettoyage complet du système selon les Meilleures Pratiques ADEY. (Filtre / MC3+ / MC10+ / MC1+).",
      phLowLowMoly:
        "Le niveau de Ph est bas: la qualité d’eau de cette installation est dégradée et l'ensemble des métaux  (aluminium/cuivre/laiton/acier) court un risque de corrosion généralisée. Un test en laboratoire peut-être recommandé ou un nettoyage complet du système selon les Meilleures Pratiques ADEY. (Filtre / MC3+ / MC10+ / MC1+). \n\nPar ailleurs, le niveau d'inhbiteur de corrosion détecté étant insuffisant, il est dans tous les cas recommandé de réajuster le niveau de concentration du  MC1+ qui pourra contribuer à rétablir le pH à son niveau optimal..Laissez circuler l'inhibiteur un minimum de 30 minutes avant contrôle.",
      phHighRec:
        "Le Ph est élevé: la qualité d’eau de cette installation est dégradée et les métaux non ferreux (aluminium/cuivre/laiton/acier) courent un risque important de corrosion. Un test en laboratoire peut-être recommandé ou un nettoyage complet du système selon les Meilleures Pratiques ADEY. (Filtre / MC3+ / MC10+ / MC1+). ",
      phHighRecLowMoly:
        "Le Ph est élevé: la qualité d’eau de cette installation est dégradée et les métaux non ferreux (aluminium/cuivre/laiton/acier) courent un risque important de corrosion. Un test en laboratoire peut-être recommandé ou un nettoyage complet du système selon les Meilleures Pratiques ADEY. (Filtre / MC3+ / MC10+ / MC1+). \n\nPar ailleurs, le niveau d'inhbiteur de corrosion détecté étant insuffisant, il est dans tous les cas recommandé de réajuster le niveau de concentration du  MC1+ qui pourra contribuer à rétablir le pH à son niveau optimal. Laissez circuler l'inhibiteur un minimum de 30 minutes avant contrôle. ",
      copperHighRec:
        "Copper level is high. If left untreated it can cause pitting corrosion of aluminum and steel. Full system clean is advised. ",
      copper_pass: "Copper level ok",
      copper_low:
        "copper level is very low  indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
    },
    proCheckTable: {
      searchBarPlaceholder: "Filtrer par le testeur ",
      expandedSearchBarPlaceholder: "Search for a heating engineer or property address",
      exportSelectedButtonLabel: "Exporter/Download sélectionné ",
      resultSelectorCounter: "<result_counter> de 5 rapports sélectionnés",
    },
    proCheckResultTable: {
      phLevel: "Niveau de PH",
      corrosionLevel: "Niveau de corrosion",
      inhibitorLevel: "Niveau d'inhibiteur",
      overallLevel: "Vue générale",
      recommendation: "Recommandations",
      pass: "Niveau de Qualité Acceptable",
      fail: "Echec",
      typeHeader: "Type",
      resultsHeader: "Résultats",
    },
    proCheckExportDialog: {
      title: "Exporte séléctionné pour le rapport ",
      privacySwitchLabel: "Options de confidentialité du testeur ",
      privacySwitchHint: "Cacher les informations personnelles du testeur",
      forgetEmailHint: "Forget email(s)",
      rememberEmailHint: "Remember email(s)",
      exportButtonLabel: "Envoyé",
      emailFieldLabel: "Envoyer à ",
      emailFieldPlaceholder: "Email",
      emailFieldHint:
        'Vous pouvez ajouter plusieurs email en appuyant sur "entrer" ou en cliquant sur le bouton "ajouter"',
      addEmailButtonLabel: "Ajouter",
      validationError: "Veuillez fournir un email valide",
      notesFieldLabel: "Notes (optionnel)",
    },

    emailValidator: {
      label: "The email you have provided has several issues",
      empty: "This email address is empty",
      trailingDot: "Trailing dot is present",
      leadingDot: "Leading dot is present",
      spaces: "Extra spaces are present",
      noAt: "This email address does not contain an asperand (@)",
      multipleAt: "This email address contains multiple asperands (@)",
      noLocalPart: "Local part is missing",
      doubleDot: "Multiple successive dots are present",
      unicodeChars: "Email should not contain non-alphanumeric values (Aa-Zz, 0-9)",
      noTopLevelDomain: "Top level domain is missing",
      extraDots: "Unnecessary extra dots",
      extraChars: "Please remove extra characters after the address",
      specialChars: "Email address should not contain any special characters",
      invalidEmail: "This email address is invalid",
    },
    propertyContractorPanel: {
      noContractor: "Pas de sous traitant",
      title: "Sous traitant",
    },
    platformUserDetail: {
      noDevelopers: "No developers found",
      usersLink: "Utilisateurs",
      userDetailsTab: "Détails des utlisateurs ",
      orgsTab: "Orgnanisations",
      loadError: "Impossible de charger les détails de l'utilisateur ",
      updateError: "Impossible de mettre à jour les détails de l'utilisateur ",
      removeError: "Impossible d'effacer l'utilisateur de l'organisation",
      firstNameLabel: "Prénom",
      surnameLabel: "Nom",
      emailLabel: "Email",
      numberLabel: "Numéro du contact",
      userSearch: "Rechercher utlisateur ",
      inviteUserButton: "Inviter un utilisateur ",
      noOrganisations: "Cet utilisateur ne fait partie d'aucune organisation",
      noOwner: "Pas de propriétaire trouvé ",
      noEngineers: "Pas de technicien chauffage trouvé ",
      noAdmins: "Aucun admin trouvé",
      noUsers: "Aucun utilisateur trouvé",
      noPendingInvites: "Aucune invitation trouvée",
      removedSuccess: "Retiré de l'organisation avec succès",
    },
    orgAndRoleTable: {
      nameHeader: "Nom de l'organisation",
      numberHeader: "Numéro du contact",
      roleHeader: "Rôle de l'utilisateur",
    },
    platformOrganisationDetail: {
      orgSearch: "Rechercher organisations ",
      inviteContractorButton: "Inviter un sous traitant",
      contractorLoadError: "Impossible de charger les sous traitant",
      contractorsTableTitle: "Sous traitant",
      associatedOrgsTableTitle: "Organisations associées ",
      invitesTableTitle: "Invitations en attente",
      noContractors: "Aucun prestataire trouvé",
      noAssociatedOrgs: "Aucune organisation associée trouvée",
      noInvites: "Aucune invitation en attente trouvée ",
      organisationRemoveSuccess: "Organisation effacée avec succès",
      agentAccessGrantError: "Echec de l'accord d'accès agent Adey ",
      agentAssignmentError: "Échec d'assignation de l'agent",
    },
    platformAgentDetails: {
      notAgentError: "Cette organisation n'est pas un agent ",
      agentDetailsLoadError: "Impossible de charger les détails de l'agent ",
      agentUpdateError: "Impossible de mettre à jour l'agent ",
      managedOrganisationLoadError: "Impossible de charger les organisations gérées ",
      removeManagedOrganisationError: "Impossible d'effacer les organisations gérées ",
      revokeAgentAccessError: "Impossible de révoquer l'ccès de l'agent ",
      removeManagedOrgSuccess: "Organisation gérée supprimée",
      agentUsersLoadError: "Impossible de charger les utilisateurs de l'organisation",
      removeAgentUserError: "Impossible de supprimer l'utilisateur",
      inviteError: "Impossible d'inviter l'utilisateur",
    },
    platformAgentDetailTabs: {
      agentDetailsTab: "Détails ",
      managedOrganisationsTab: "Organisations gérées",
      agentUsersTab: "Utilisateurs ",
    },
    adminInviteContractorDialog: {
      titlePlaceholder: "Inviter un prestataire à %s",
      emailHint: "Adresse email",
      emailRequiredError: "Veuillez entrer un email valide",
      confirmEmailHint: "Confirmer email",
      confirmEmailRequired: "Confirmation de l'email requise ",
      confirmEmailMatchError: "Les emails doivent correspondre ",
      inviteButton: "Envoyer une invitation ",
      inviteError: "Impossible d'envoyer une invitation",
      inviteSentMessage: "Invitation envoyée avec succès",
      descriptionText:
        "Veuillez vous assurer que vous invitez l'adresse de Owner/SuperAdmin liée au compte du prestataire",
      contactHint: "Pour plus d'information, veuillez nous contacter ",
      contactEmail: "info.fr@adey.com",
    },
    adminInviteUserDialog: {
      titlePlaceholder: "Inviter un utilisateur à %s",
      emailHint: "Adresse email",
      emailRequiredError: "Veiullez entrer une adresse mail valide",
      roleHint: "Rôle de l'utilisateur",
      roleRequired: "Rôle de l'utlisateur requis",
      confirmEmailHint: "Confirmer l'email",
      confirmEmailRequired: "La confirmation de l'email est requise ",
      confirmEmailMatchError: "Les emails doivent correspondre",
      inviteButton: "Envoyer une invitation",
      inviteError: "Impossible d'envoyer une invitation",
      inviteSentMessage: "Invitation envoyée avec succès",
      developerContext:
        "Developers will be able to view existing properties and create new properties for the organisations that they are a part of, as well as access the Developer Zone",

      adminContext:
        "Les administrateurs d'organisation pourront afficher les propriétés existantes et créer de nouvelles propriétés pour les organisations dont ils font partie.\n Ils seront également en mesure de visualiser les utilisateurs et de gérer les relations avec les sous-traitants et les organisations associées.",
      heatingEngineerContext:
        "Les techniciens chauffagistes pourront visualiser les propriétés existantes et créer de nouvelles propriétés pour les organisations dont ils font partie.",
    },
    deletePropertyHandler: {
      buttonTitle: "Effacer propriété",
      confirmDialogTitlePlaceholder: "Effacer %s",
      confirmDialogBody:
        "Si vous souhaitez effacer cette propriété, vous ne pourrez plus accéder à ses données. cela inclus les tests effectués et tous les produits installés dans cette propriétés",
      confirmDialogCancelButton: "Annuler",
      confirmDialogDeleteButton: "Effacer",
      deleteError: "Impossible d'effacer la propriété",
      propertyDeletedTitle: "Propriété effacée",
      propertyDeletedBody:
        "Cette propriété a été effacée avec succès. Vous ne pourrez plus accéder à ses données. ",
      propertyDeletedDashButton: "Retourner au tableau de bord",
    },
    agentUserList: {
      ownerTab: "Propriétaire/ Super Admin",
      agentTab: "Agents",
      developersTab: "Developers",
      invitesTab: "Inivtations en attente",
      searchPlaceholder: "Rechercher un utilisateur",
      inviteButton: "Inviter un utilisateur",
      emptyText: "Aucun utilisateur trouvé",
    },
    agentOrganisationListPage: {
      searchPlaceholder: "Rechercher une organisation gérée",
      noManagedOrganisations: "Aucune organisation gérée trouvée\n",
      switchingOrgTitle: "Changer à  <org_name>...\n",
    },
    agentInfoCard: {
      labelTitle: "Agent Adey",
      organisationLabel: "Organisations",
      countryLabel: "Pays",
    },
    adminRemoveManagedOrgDialog: {
      title: "Supprimer l'organisation gérée",
      bodyPlaceholder:
        "Annuler l'attribution de l'<organisation_gérée> de l'<adey_agent> ? <adey_agent> ne pourra pas gérer l'organisation.",
      removeButtonPlaceholder: "Supprimer",
    },
    organisationAgentPanel: {
      noAgent: "Pas d'agent ADEY",
      title: "Agent Adey",
    },

    exportBar: {
      title: "Exporter des éléments",
    },

    exportItem: {
      initializing: "Initialising report generation",
      generating: "Génération de rapport",
      generated: "Rapport exporté",
      failure: "Échec du rapport",
      cancelled: "Cancelling export...",
    },

    senseDetailsPageHeaders: {
      details: "Informations",
      readings: "Readings",
    },

    senseActivationConfirmationDialog: {
      title: "Device Activation",
      contentActivate:
        "Are you sure you want to activate this device? {{- organisation_name}} will gain access to this device.",
      contentDeactivate:
        "Are you sure you want to deactivate this device? {{- organisation_name}} will lose access to this device until it is reactivated.",
      confirm: "Confirm",
      switchTitle: "Device Activation",
      switchLabelActive: "Device is Active",
      switchLabelDisabled: "Device is Disabled",
    },

    homeZoneSwitchPanel: {
      switchLabel: "HomeZone Notification",
      infoText:
        "Please enter homeowner details before activation of the HomeZone notification",
      title: "HomeZone",
    },

    senseDeviceDetailsTab: {
      productType: "Type de produit",
      productNumber: "Numéro de produit",
      serialNumber: "Serial Number",
      identifier: "MAC/IMEI",
      firmwareVersion: "Firmware Version",
      serviceInfo: "Service Information",
      organisation: "l'organisation",
      contractor: "Sous traitant",
      heatingEngineer: "Heating Engineer",
      deviceInstalled: "Device Installed",
      serviceDue: "Service Due",
      warrantyExpiry: "Warranty Expiry",
      notes: "Notes",
      lastReading: "Last reading",
      lastContact: "Last contact",
      lastCleaned: "Last cleaned",
    },

    senseDeviceReadingsTab: {
      title: "Latest Readings",
    },

    senseDeviceReadingsHeaders: {
      reading: "Reading",
      date: "Date",
      time: "Time",
    },

    senseDeviceNames: {
      PROCHECK: "ProCheck",
      PRO3: "Pro3",
      M3SM: "Micro3 SIM",
      M3WF: "Micro3 Wi-Fi",
    },

    systemMessageContainer: {
      title: "Message système",
      hideLabel: "Ne plus afficher",
    },

    maintenanceDateMessage: {
      content:
        "La maintenance se déroulera: \nDu {{from}} jusqu’au {{to}} \nNous espérons être de nouveau opérationnels dès que possible",
    },

    systemDownMessage: {
      title: "Maintenance Prévue",
      content:
        "Nous sommes en train d’effectuer des modifications ce qui signifie que vous ne pouvez pas utiliser le portail pour le moment. \nNous espérons être de nouveau opérationnels dès que possible. \nNous nous excusons pour la gêne occasionnée.",
    },

    scheduledMaintenanceMessage: {
      title: "Maintenance Prévue",
      content:
        "Nous sommes en train d’effectuer des modifications ce qui signifie que vous ne pourrez pas utiliser le portail pendant un certain temps.",
    },

    promotionMessage: {
      title: "PROMOTION",
      content:
        "[NOT TRANSLATED] Sample promotion text. \nThe promotions lasts: \n{{from}} – {{to}} \nSome extra text",
    },

    developerZonePopup: {
      title: "Developer Zone",
      subTitle: "Welcome to the ADEY Sense Developer Zone",
      description:
        "A central hub for developers to access Sense APIs and support information",
      inviteButtonLabel: "Invite Developer",
      redirectButtonLabel: "Get in the Zone!",
    },

    userSegmentation: {
      updateError: "Error submitting segmentation details",
      questionTitle: {
        "question-1": "Lequel des énoncés suivants vous décrit le mieux?",
        "question-2": "Où effectuez-vous la plupart de votre travail?",
      },
      answerDescription: {
        "heating-engineer-independent": "Chauffagiste (indépendant)",
        plumber: "Plombier",
        "residential-contractor": "Entrepreneur résidentiel",
        "residential-contractor-50": "Entrepreneur résidentiel (50+ ingénieurs)",
        other: "Autre",
        residential: "Résidentiel (résidence privée)",
        "new-build": "Nouvelle construction",
        "social-housing": "Logement Social/HA/LA",
        utility: "Utilitaire",
        "boiler-manufacturer-oem": "Fabricant de chaudière/OEM",
      },
    },
  },
};

export default translations;
