import PlatformAgentListAction from "./PlatformAgentListAction";
import PlatformOrganisationListState from "./PlatformAgentListState";

const initialState: PlatformOrganisationListState = {
  agentOrganisations: undefined,
  error: undefined,
  isLoading: false,
  currentPage: 1,
  maxPage: 1,
  searchText: "",
};

const platformAgentListReducer = (
  state = initialState,
  action: PlatformAgentListAction
): PlatformOrganisationListState => {
  switch (action.type) {
    case "LOAD_PLATFORM_AGENTS_TYPE":
      return {
        agentOrganisations: undefined,
        error: undefined,
        isLoading: true,
        currentPage: action.page,
        maxPage: state.maxPage,
        searchText: state.searchText,
      };

    case "PLATFORM_AGENTS_ERROR_TYPE":
      return {
        ...state,
        agentOrganisations: undefined,
        error: action.error,
        isLoading: false,
      };

    case "PLATFORM_AGENTS_LOADED_TYPE":
      return {
        agentOrganisations: action.agents,
        error: undefined,
        isLoading: false,
        currentPage: action.page,
        maxPage: action.maxPages,
        searchText: state.searchText,
      };

    case "PLATFORM_AGENTS_UPDATE_ENTRY":
      let updatedList = state.agentOrganisations?.map((org) =>
        org.id === action.agent.id ? action.agent : org
      );
      return {
        ...state,
        agentOrganisations: updatedList,
      };

    case "AGENT_SEARCH_TEXT_CHANGED_TYPE":
      return {
        ...state,
        agentOrganisations: undefined,
        isLoading: true,
        currentPage: 1, // have to go back to page 1
        searchText: action.text,
      };

    default:
      return state;
  }
};

export default platformAgentListReducer;
