import Page from "./Page";

export const fetchAllPages = async <T>(
  getPage: (page: number) => Promise<Page<T[]>>
): Promise<T[]> => {
  const allResults: T[] = [];
  let currentPage = 1;
  let lastPage = 2;

  while (currentPage <= lastPage) {
    const newPage = await getPage(currentPage);
    if (Array.isArray(newPage.data)) {
      allResults.push(...newPage.data);
    } else {
      allResults.push(newPage.data);
    }

    currentPage++;
    lastPage = newPage.meta.last_page;
  }

  return allResults;
};
