import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithT {
  loading: boolean;
  onRequestEmailClicked: () => void;
  onSignInClicked: () => void;
}

const ResendActivationEmailRequestPanel = (props: Props) => {
  const message = props.t("resendActivationEmailRequestPage.message");
  const signInPrompt = props.t("resendActivationEmailRequestPage.signInPrompt");
  const requestButton = props.t("resendActivationEmailRequestPage.resendEmailButton");
  const returnToSignInButton = props.t("resendActivationEmailRequestPage.signInButton");

  return (
    <Grid container spacing={DEFAULT_SPACING} justify="center">
      <Grid item />

      <Grid item>
        <Typography align="center" variant="body2">
          {message}
        </Typography>
      </Grid>

      <Grid item>
        <PrimaryButton
          label={requestButton}
          isLoading={props.loading}
          onClick={props.onRequestEmailClicked}
        />
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body2">
            <strong>{signInPrompt}</strong>
          </Typography>
          <SecondaryButton label={returnToSignInButton} onClick={props.onSignInClicked} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ResendActivationEmailRequestPanel);
