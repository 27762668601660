import OrganisationListAction, {
  LOAD_ORGANISATIONS_ERROR_TYPE,
  LOAD_ORGANISATIONS_SUCCESS_TYPE,
  LOAD_ORGANISATIONS_TYPE,
} from "./OrganisationListAction";
import OrganisationListState from "./OrganisationListState";

const initialState: OrganisationListState = {
  contractors: [],
  associatedOrgs: [],
  invites: [],
  isLoading: false,
  error: undefined,
  removingOrganisation: false,
  removingOrganisationError: undefined,
};

const organisationListReducer = (
  state = initialState,
  action: OrganisationListAction
): OrganisationListState => {
  switch (action.type) {
    case LOAD_ORGANISATIONS_TYPE:
      return {
        contractors: [],
        associatedOrgs: [],
        invites: [],
        isLoading: true,
        error: undefined,
        removingOrganisation: false,
        removingOrganisationError: undefined,
      };

    case LOAD_ORGANISATIONS_SUCCESS_TYPE:
      return {
        contractors: action.contractors,
        associatedOrgs: action.associatedOrgs,
        invites: action.invites,
        isLoading: false,
        error: undefined,
        removingOrganisation: false,
        removingOrganisationError: undefined,
      };

    case LOAD_ORGANISATIONS_ERROR_TYPE:
      return {
        contractors: [],
        associatedOrgs: [],
        invites: [],
        isLoading: true,
        error: undefined,
        removingOrganisation: false,
        removingOrganisationError: undefined,
      };

    default:
      return state;
  }
};

export default organisationListReducer;
