import { Grid, Box } from "@material-ui/core";
import React from "react";
import { SMALL_SPACING } from "../../theme/dimensions";
import OutlineButton from "../OutlineButton";
import PrimaryButton from "../PrimaryButton";
import PrimaryTextField from "../PrimaryTextField";
import ScrollListItem from "../ScrollListItem/ScrollListItem";
import ScrollListPanel from "../ScrollListPanel/ScrollListPanel";
import SecondaryButton from "../SecondaryButton";
import TMoveDestination from "../../../pages/admin/AdminMoveEntity/types/TMoveDestination";
import { useTranslation } from "react-i18next";

interface IProps {
  searchValue: string;
  isLoading?: boolean;
  searchPlaceholder?: string;
  handleSearchChange: (v: string) => void;
  handleSearchButtonClicked: () => void;
  handleBackClicked: () => void;
  handleNextClicked: () => void;
  handleSelectDestination: (destinationId: string) => void;
  destinations: TMoveDestination[];
  selectedDestinationId?: string;
}

const SearchDestinationPanel = ({
  searchValue,
  isLoading,
  searchPlaceholder,
  handleSearchChange,
  handleSearchButtonClicked,
  handleBackClicked,
  handleNextClicked,
  handleSelectDestination,
  destinations,
  selectedDestinationId,
}: IProps) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const nextButton = t("buttons.next");
  const closeButton = t("buttons.cancel");
  const searchButton = t("buttons.search");

  return (
    <Box width={"100%"}>
      <Grid container spacing={SMALL_SPACING}>
        <Grid item xs={12}>
          <Box></Box>
        </Grid>
        <Grid item xs={8}>
          <PrimaryTextField
            value={searchValue}
            disabled={isLoading}
            hint={searchPlaceholder}
            onChange={(v) => handleSearchChange(v)}
            onEnter={handleSearchButtonClicked}
          />
        </Grid>
        <Grid item xs={4}>
          <OutlineButton
            isLoading={isLoading}
            onClick={handleSearchButtonClicked}
            style={{ width: "100%", height: "100%" }}
            label={searchButton}
          />
        </Grid>
        {destinations.length > 0 && (
          <Grid item xs={12}>
            <ScrollListPanel
              items={destinations}
              renderItem={(item) => (
                <ScrollListItem
                  label={item.description}
                  selected={item.id === selectedDestinationId}
                  onClick={() => handleSelectDestination(item.id)}
                />
              )}
              keyExtractor={(item) => item.id}
            />
          </Grid>
        )}
      </Grid>
      <Box width={"100%"}>
        <Box marginTop={SMALL_SPACING} />
        {destinations.length > 0 && (
          <PrimaryButton
            disabled={!selectedDestinationId}
            label={nextButton}
            onClick={handleNextClicked}
            fullWidth
          />
        )}

        <Box marginTop={SMALL_SPACING} />
        <SecondaryButton label={closeButton} onClick={handleBackClicked} fullWidth />
      </Box>
    </Box>
  );
};

export default SearchDestinationPanel;
