import { Box, LinearProgress } from "@material-ui/core";
import React from "react";
import AdeyBackground from "../../ui/assets/AdeyBackground/AdeyBackground";
import AdeyLogo from "../../ui/assets/AdeyLogo/AdeyLogo";
import { BACKGROUND_ACCENT_COLOR } from "../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING } from "../../ui/theme/dimensions";
import LoadingBackgroundService from "./service/LoadingBackgroundService";

const _logoHeight = 100;

/// A splash page for when the app is starting up.
/// (determining if the user is logged in and anything else
/// that's required).
const LoadingPage = () => {
  // There are two types of loading page. one for logged in
  // and one for logged out. Use a `LoadingBackgroundService` to
  // check which to display to avoid a flash of white background before
  // a dark screen
  const service = new LoadingBackgroundService();
  const loggedIn = service.getBackgroundType() === "logged_in";

  const backgroundColor = loggedIn ? "white" : BACKGROUND_ACCENT_COLOR;
  const logoColor = loggedIn ? "blue" : "white";
  const includeFlecks = !loggedIn;

  return (
    <Box position="fixed" bgcolor={backgroundColor} minWidth="100%" minHeight="100vh">
      {includeFlecks ? <AdeyBackground height="60vh" width="100%" /> : undefined}

      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100%"
        zIndex="2"
        flexDirection="column"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <AdeyLogo height={_logoHeight} color={logoColor} />
        </Box>

        <Box width="30vw" marginTop={DEFAULT_SPACING}>
          <LinearProgress />
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingPage;
