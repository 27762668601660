import { Box, Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";

interface Props extends WithTranslation {
  open: boolean | undefined;
  organisationName: string;
  isAgent: boolean;

  onOkClicked: () => void;
}

const AgentAssignedDialog = (props: Props) => {
  //Labels
  const assignTitle = props.t("agentAssignmentDialog.agentAssignedTitle");
  const revokeTitle = props.t("agentAssignmentDialog.agentRemovedTitle");
  const confirmButton = props.t("buttons.confirm");
  const agentRemovedText = props
    .t("agentAssignmentDialog.agentRemovedText")
    .replace("%s", props.organisationName);
  const agentAssignedText = props
    .t("agentAssignmentDialog.agentAssignedText")
    .replace("%s", props.organisationName);

  const okClickHandler = (e: any) => {
    e.stopPropagation();
    props.onOkClicked();
  };

  return (
    <Dialog open={props.open || false}>
      <Box
        padding={DEFAULT_SPACING}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h2" gutterBottom paragraph align="center">
          {props.isAgent ? revokeTitle : assignTitle}
        </Typography>
        <Typography gutterBottom align="center">
          {props.isAgent ? agentRemovedText : agentAssignedText}
        </Typography>
        <Box marginTop={SMALL_SPACING} />

        <Box display="flex" justifyContent="flex-end" marginTop={SMALL_SPACING}>
          <Button color="primary" onClick={okClickHandler}>
            {confirmButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(AgentAssignedDialog);
