import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { LARGE_SPACING } from "../theme/dimensions";

interface Props {
  message: string;
  padding?: number;
}

const EmptyStateDisplay = (props: Props) => {
  const padding = props.padding ? props.padding : LARGE_SPACING;
  return (
    <Box padding={padding}>
      <Typography variant="h2" color="textPrimary" align="center">
        <Trans>{props.message}</Trans>
      </Typography>
    </Box>
  );
};

export default EmptyStateDisplay;
