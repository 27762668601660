import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  agentName: string;

  onCancelClicked: () => void;
  onRemoveClicked: () => void;
}

const RemoveManagedOrganisationDialog = (props: Props) => {
  const title = props.t("adminRemoveManagedOrgDialog.title");
  const bodyPlaceholder = props.t("adminRemoveManagedOrgDialog.bodyPlaceholder");
  const removeButton = props.t("adminRemoveManagedOrgDialog.removeButtonPlaceholder");

  const body = bodyPlaceholder
    .replaceAll("<managed_organisation>", props.organisationName)
    .replaceAll("<adey_agent>", props.agentName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={removeButton}
      onConfirmed={props.onRemoveClicked}
      onCancelClicked={props.onCancelClicked}
    />
  );
};

export default withTranslation()(RemoveManagedOrganisationDialog);
