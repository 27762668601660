import PlatformOrganisationsListAction from "./PlatformOrganisationListAction";
import PlatformOrganisationListState from "./PlatformOrganisationListState";

const initialState: PlatformOrganisationListState = {
  organisations: undefined,
  error: undefined,
  isLoading: false,
  currentPage: 1,
  maxPage: 1,
  searchText: "",
  removingOrganisation: false,
  removingOrganisationError: undefined,
};

const platformOrganisationListReducer = (
  state = initialState,
  action: PlatformOrganisationsListAction
): PlatformOrganisationListState => {
  switch (action.type) {
    case "LOAD_PLATFORM_ORGANISATIONS_TYPE":
      return {
        organisations: undefined,
        error: undefined,
        isLoading: true,
        currentPage: action.page,
        maxPage: state.maxPage,
        searchText: state.searchText,
        removingOrganisation: false,
        removingOrganisationError: undefined,
      };

    case "PLATFORM_ORGANISATIONS_ERROR_TYPE":
      return {
        ...state,
        organisations: undefined,
        error: action.error,
        isLoading: false,
      };

    case "PLATFORM_ORGANISATIONS_LOADED_TYPE":
      return {
        organisations: action.organisations,
        error: undefined,
        isLoading: false,
        currentPage: action.page,
        maxPage: action.maxPages,
        searchText: state.searchText,
        removingOrganisation: false,
        removingOrganisationError: undefined,
      };

    case "PLATFORM_ORGANISATIONS_UPDATE_ENTRY":
      let updatedList = state.organisations?.map((org) =>
        org.id === action.organisation.id ? action.organisation : org
      );
      return {
        ...state,
        organisations: updatedList,
      };

    case "ORG_SEARCH_TEXT_CHANGED_TYPE":
      return {
        ...state,
        organisations: undefined,
        isLoading: true,
        currentPage: 1, // have to go back to page 1
        searchText: action.text,
      };

    default:
      return state;
  }
};

export default platformOrganisationListReducer;
