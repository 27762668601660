import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  BoxProps,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import ColoredText from "../../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING } from "../../../../../ui/theme/dimensions";
import HomeZoneConfirmationDialog from "./components/HomeZoneConfirmationDialog";
import { useState } from "react";

interface ExternalProps {
  loading: boolean;
  disabled: boolean;
  homezoneEnabled: boolean;

  onSwitchToggle: (homezoneStatus: boolean) => void;
}

type Props = ExternalProps & WithTranslation & BoxProps;

const HomeZoneSwitchPanel = (props: Props) => {
  //Translated Strings
  const switchLabel = props.t("homeZoneSwitchPanel.switchLabel");
  const infoText = props.t("homeZoneSwitchPanel.infoText");
  const title = props.t("homeZoneSwitchPanel.title");

  const boxProps = props as BoxProps;

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleChangeConfirmed = () => {
    setConfirmationDialogOpen(false);
    props.onSwitchToggle(!props.homezoneEnabled);
  };

  return (
    <BorderedBox {...boxProps}>
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
        <strong>{title}</strong>
      </ColoredText>
      <Box display="flex" flexDirection="row">
        <FormControlLabel
          style={{ marginLeft: DEFAULT_SPACING, marginTop: DEFAULT_SPACING }}
          control={
            <Switch
              checked={props.homezoneEnabled}
              disabled={props.loading || props.disabled}
              color="primary"
              onChange={() => setConfirmationDialogOpen(true)}
            />
          }
          label={switchLabel}
          checked={props.homezoneEnabled}
          color="primary"
        />
        <Tooltip interactive title={infoText}>
          <IconButton>
            <FontAwesomeIcon icon={faInfoCircle} size="xs" />
          </IconButton>
        </Tooltip>
      </Box>

      <HomeZoneConfirmationDialog
        homeZoneCurrentlyEnabled={props.homezoneEnabled}
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirmed={handleChangeConfirmed}
      />
    </BorderedBox>
  );
};

export default withTranslation()(HomeZoneSwitchPanel);
