import { delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import LocalizedError from "../../../../errors/LocalizedError";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import RootState from "../../../../redux/RootState";
import checkCountry from "../../../../redux/utils/checkCountry";
import getDependency from "../../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationUserRepository from "../../../../rest/organisation-user/OrganisationUserRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Role from "../../../../rest/roles/model/Role";
import RoleRepository from "../../../../rest/roles/RoleRepository";
import {
  ResendInviteSuccessAction,
  RESEND_INVITE_SUCCESS_TYPE,
  RevokeInviteSuccessAction,
  REVOKE_INVITE_SUCCESS_TYPE,
} from "../../../../ui/components/PendingInviteMenu/redux/PendingInviteMenuAction";
import { OrgUserDetails } from "../../AdminOrganisationDetailsPage/redux/AdminOrganisationDetailsState";
import buildOrganisationFromCode from "../../AdminOrganisationDetailsPage/utils/buildOrganisationFromCode";
import fetchOrgUserDetails from "../../AdminOrganisationDetailsPage/utils/fetchOrgUserDetails";
import { updateAgentListEntry } from "../../PlatformAgentListPage/redux/PlatformAgentListAction";
import { updateOrgListEntry } from "../../PlatformOrganisationListPage/redux/PlatformOrganisationListAction";
import {
  agentAccesRevoked,
  agentDetailsLoaded,
  agentOrgUsersLoaded,
  agentOrgUsersLoadError,
  agentUserRemoved,
  InviteAgentUserAction,
  inviteAgentUserError,
  inviteAgentUserSuccess,
  INVITE_AGENT_USER_TYPE,
  LoadAgentDetailsAction,
  loadAgentDetailsError,
  loadAgentOrgUsers,
  LoadAgentOrgUsersAction,
  loadManagedOrganisations,
  loadManagedOrganisationsError,
  LOAD_AGENT_DETAILS_TYPE,
  LOAD_AGENT_ORG_USERS_TYPE,
  LOAD_MANAGED_ORGANISATION_TYPE,
  managedOrganisationRemoved,
  managedOrganisationsLoaded,
  MANAGED_ORGANISATIONS_SEARCH_TYPE,
  RemoveAgentUserAction,
  removeAgentUserError,
  RemoveManagedOrganisationAction,
  REMOVE_AGENT_USER_TYPE,
  REMOVE_MANAGED_ORGANISATION_TYPE,
  RevokeAgentAccessAction,
  revokeAgentAccessError,
  REVOKE_AGENT_ACCESS_TYPE,
  UpdateAgentDetailsAction,
  UPDATE_AGENT_DETAILS_TYPE,
} from "./PlatformAgentDetailsActions";

function* platformAgentDetailsSagas() {
  //Load Agent Details
  yield takeLatest(LOAD_AGENT_DETAILS_TYPE, loadAgentDetailsSaga);
  yield takeLatest(MANAGED_ORGANISATIONS_SEARCH_TYPE, managedOrgsSearchChangedSaga);
  //Update Agent Details
  yield takeLatest(UPDATE_AGENT_DETAILS_TYPE, updateAgentDetailsSaga);
  //Load Managed Orgs
  yield takeLatest(LOAD_MANAGED_ORGANISATION_TYPE, loadManagedOrgsSaga);
  //Remove Managed Org
  yield takeLatest(REMOVE_MANAGED_ORGANISATION_TYPE, removeManagedOrgSaga);
  //Revoke Agent Access
  yield takeLatest(REVOKE_AGENT_ACCESS_TYPE, revokeAgentAccessSaga);
  //Load Agent Users
  yield takeLatest(LOAD_AGENT_ORG_USERS_TYPE, loadAgentUsersSaga);
  //Remove User
  yield takeLatest(REMOVE_AGENT_USER_TYPE, removeAgentUserSaga);
  //Invite Agent User
  yield takeLatest(INVITE_AGENT_USER_TYPE, inviteAgentUserSaga);
  //Resend invite sagas
  yield takeEvery(RESEND_INVITE_SUCCESS_TYPE, resendAgentInviteSaga);
  yield takeEvery(REVOKE_INVITE_SUCCESS_TYPE, revokeAgentInviteSaga);
}

//Load Agent Details Saga
function* loadAgentDetailsSaga(action: LoadAgentDetailsAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    if (isAdmin) {
      const adminRepo: AdminRepository = yield getDependency("adminRepository");
      const agentDetails: Organisation = yield adminRepo.getOrganisation(
        action.agentOrgId
      );

      if (agentDetails.is_agent_for) {
        yield checkCountry(agentDetails.id);
        yield put(agentDetailsLoaded(agentDetails));
        yield put(loadManagedOrganisations());
        yield put(loadAgentOrgUsers(agentDetails.id));
      } else {
        throw new LocalizedError("platformAgentDetails.notAgentError");
      }
    }
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.agentDetailsLoadError");
    yield put(loadAgentDetailsError(message));
  }
}

//Load Managed Orgs Saga
function* loadManagedOrgsSaga() {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const rootState: RootState = yield select();
    if (isAdmin && rootState.platformAgentDetails.agentDetails?.id) {
      const adminRepo: AdminRepository = yield getDependency("adminRepository");
      const managedOrgs: Organisation[] = yield adminRepo.fetchAgentManagedOrganisations(
        rootState.platformAgentDetails.orgSearchText,
        rootState.platformAgentDetails.agentDetails.id
      );

      yield put(managedOrganisationsLoaded(managedOrgs));
    }
  } catch (e) {
    const message = getErrorMessage(
      e,
      "platformAgentDetails.managedOrganisationLoadError"
    );
    yield put(loadManagedOrganisationsError(message));
  }
}

//Search Text Changed Saga
function* managedOrgsSearchChangedSaga() {
  // Wait a short delay before loading so that we don't
  // search for every keystroke
  yield delay(150);
  const rootState: RootState = yield select();

  if (rootState.platformAgentDetails.agentDetails?.id) {
    yield loadManagedOrgsSaga();
  }
}

//Remove Managed Org Saga
function* removeManagedOrgSaga(action: RemoveManagedOrganisationAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    if (isAdmin) {
      const adminRepo: AdminRepository = yield getDependency("adminRepository");
      const managedOrganisation: Organisation = {
        ...action.org,
      };
      delete managedOrganisation.agent;
      const deletedOrg: Organisation = yield adminRepo.updateOrganisation(
        managedOrganisation
      );

      yield put(managedOrganisationRemoved(deletedOrg));
    }
  } catch (e) {
    const message = getErrorMessage(
      e,
      "platformAgentDetails.removeManagedOrganisationError"
    );
    yield put(loadManagedOrganisationsError(message));
  }
}

//Revoke Agent Access Saga
function* revokeAgentAccessSaga(action: RevokeAgentAccessAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    if (isAdmin) {
      const adminRepo: AdminRepository = yield getDependency("adminRepository");
      const agentOrg: Organisation = { ...action.agentOrg };
      delete agentOrg.is_agent_for;
      const demotedAgent: Organisation = yield adminRepo.updateOrganisation(agentOrg);

      yield put(agentAccesRevoked(demotedAgent));
      yield put(updateOrgListEntry(demotedAgent));
      yield put(updateAgentListEntry(demotedAgent));
    }
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.revokeAgentAccessError");
    yield put(revokeAgentAccessError(message));
  }
}

//Update Agent Details Saga
function* updateAgentDetailsSaga(action: UpdateAgentDetailsAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const rootState: RootState = yield select();

    if (isAdmin) {
      const adminRepo: AdminRepository = yield getDependency("adminRepository");

      const updateOrg = buildOrganisationFromCode(
        action.updatedAgentDetails,
        rootState.countryList.countryList
      );

      const orgResponse: Organisation = yield adminRepo.updateOrganisation(updateOrg);

      yield checkCountry(orgResponse.address_country.id);
      yield put(agentDetailsLoaded(orgResponse));
    }
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.agentDetailsLoadError");
    yield put(loadAgentDetailsError(message));
  }
}

//Load Agent Users
function* loadAgentUsersSaga(action: LoadAgentOrgUsersAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const details: OrgUserDetails = yield fetchOrgUserDetails(
      action.organisationId,
      adminRepo
    );

    yield put(agentOrgUsersLoaded(details.users, details.invites));
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.agentUsersLoadError");
    yield put(agentOrgUsersLoadError(message));
  }
}

//Send agent invite
function* inviteAgentUserSaga(action: InviteAgentUserAction) {
  try {
    const repo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );
    const roleRepo: RoleRepository = yield getDependency("roleRepository");

    const isAdmin: boolean = yield isAdeyAdmin();

    const roles: Role[] = yield roleRepo.getAllRoles(isAdmin);
    const role = roles.filter((role) => role.name === action.role)[0];

    const invite: PendingInvite = yield repo.inviteOrganisationUser(
      action.organisationId,
      action.email,
      role.id,
      true
    );
    yield put(inviteAgentUserSuccess(invite));
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.inviteError");
    yield put(inviteAgentUserError(message));
  }
}

//Remove Agent user
function* removeAgentUserSaga(action: RemoveAgentUserAction) {
  const adminRepo: AdminRepository = yield getDependency("adminRepository");

  const rootState: RootState = yield select();
  const removedUser = rootState.platformAgentDetails.userDetails?.users.filter(
    (user) => user.user.id === action.user.id
  )[0];
  try {
    if (removedUser !== undefined) {
      yield adminRepo.removeUserFromOrganisation(action.organisation.id, action.user.id);
      yield put(agentUserRemoved(removedUser));
    }
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentDetails.removeAgentUserError");
    yield put(removeAgentUserError(message));
  }
}

//Revoke Agent Invite
function* revokeAgentInviteSaga(action: RevokeInviteSuccessAction) {
  const rootState: RootState = yield select();
  const details = rootState.platformAgentDetails.userDetails;
  const invites = details?.invites?.filter((i) => i.id !== action.invite.id);
  yield put(agentOrgUsersLoaded(details?.users || [], invites || []));
}

//Resend Agent Invite
function* resendAgentInviteSaga(action: ResendInviteSuccessAction) {
  const rootState: RootState = yield select();

  const details = rootState.platformAgentDetails.userDetails;
  const index = details?.invites?.findIndex((i) => i.id === action.oldInvite.id);
  if (!details || index === undefined || index < 0) {
    return;
  }

  const invites = [...(details.invites || [])];
  invites[index] = action.newInvite;

  yield put(agentOrgUsersLoaded(details?.users || [], invites || []));
}

export default platformAgentDetailsSagas;
