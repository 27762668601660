import { put, select, takeLatest } from "redux-saga/effects";
import {
  LoginStateChangedAction,
  LOGIN_STATUS_CHANGED_TYPE,
} from "../../../../pages/auth/Login/redux/LoginAction";
import RootState from "../../../../redux/RootState";
import getCurrentOrganisation from "../../../../redux/utils/getCurrentOrganisation";
import getDependency from "../../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import MetricsRepository from "../../../../rest/metrics/MetricsRepository";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import { ACTIVE_ORGANISATION_CHANGED_TYPE } from "../../OrganisationSwitcher/redux/ActiveOrganisationActions";
import calculateDashboardMetrics from "../utils/calculateDashboardMetrics";
import {
  clearDashboardMetrics,
  dashboardMetricsLoaded,
  RagCardOrgIdChangedAction,
  RAG_CARD_ORG_ID_CHANGED_TYPE,
} from "./DashboardAction";
import { DashboardMetrics } from "./DashboardState";

function* dashboardSagas() {
  yield takeLatest(ACTIVE_ORGANISATION_CHANGED_TYPE, activeOrgChangedSaga);
  yield takeLatest(LOGIN_STATUS_CHANGED_TYPE, logoutSaga);
  yield takeLatest(RAG_CARD_ORG_ID_CHANGED_TYPE, ragCardOrgIdChangedSaga);
}

function* logoutSaga(action: LoginStateChangedAction) {
  if (action.loginStatus !== "logged_in") {
    yield put(clearDashboardMetrics());
  }
}

function* activeOrgChangedSaga() {
  // If we're not adey, show the cards for the current org
  const isAdmin: boolean = yield isAdeyAdmin();

  if (!isAdmin) {
    const currentOrg: MemberOrganisation = yield getCurrentOrganisation();
    yield loadOrganisationMetrics(currentOrg.id);
  }
}

function* ragCardOrgIdChangedSaga(action: RagCardOrgIdChangedAction) {
  const rootState: RootState = yield select();
  const hasData = rootState.dashboard.metrics !== undefined;
  const currentCardOrg = rootState.dashboard.organisationId;
  if (currentCardOrg === action.orgId && hasData) {
    // Don't do anything if unchanged
    return;
  }

  if (action.orgId) {
    yield loadOrganisationMetrics(action.orgId);
  }
}

function* loadOrganisationMetrics(organisationId: string) {
  try {
    const repo: MetricsRepository = yield getDependency("metricsRepository");
    const isAdmin: boolean = yield isAdeyAdmin();

    const metrics: DashboardMetrics = yield repo
      .getOrganisationMetrics(isAdmin, organisationId)
      .then(calculateDashboardMetrics);

    yield put(dashboardMetricsLoaded(metrics, organisationId));
  } catch (e) {
    // The RAG Cards can't display an error, just display nothing
    yield put(clearDashboardMetrics());
    console.error(e);
  }
}

export default dashboardSagas;
