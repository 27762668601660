import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import UserDetails from "../../../../rest/user/model/UserDetails";

// Load user
export const LOAD_USER_TYPE = "LOAD_USER_TYPE";
export interface LoadUserAction {
  type: typeof LOAD_USER_TYPE;
  userId: string;
  organisationId: string;
}
export const loadUser = (organisationId: string, userId: string): LoadUserAction => ({
  type: LOAD_USER_TYPE,
  userId: userId,
  organisationId: organisationId,
});

// Load user success
export const LOAD_USER_SUCCESS_TYPE = "LOAD_USER_SUCCESS_TYPE";
export interface LoadUserSuccessAction {
  type: typeof LOAD_USER_SUCCESS_TYPE;
  user: OrganisationUser;
  organisation: Organisation;
  canRemoveUser: boolean;
}
export const loadUserSuccess = (
  user: OrganisationUser,
  organisation: Organisation,
  canRemoveUser: boolean
): LoadUserSuccessAction => ({
  type: LOAD_USER_SUCCESS_TYPE,
  user: user,
  organisation: organisation,
  canRemoveUser: canRemoveUser,
});

// Load user error
export const LOAD_USER_ERROR_TYPE = "LOAD_USER_ERROR_TYPE";
export interface LoadUserErrorAction {
  type: typeof LOAD_USER_ERROR_TYPE;
  error: string;
}
export const loadUserError = (error: string): LoadUserErrorAction => ({
  type: LOAD_USER_ERROR_TYPE,
  error: error,
});

// Remove user
export const REMOVE_USER_TYPE = "REMOVE_USER_TYPE";
export interface RemoveUserAction {
  type: typeof REMOVE_USER_TYPE;
  user: UserDetails;
  organisation: Organisation;
}
export const removeUser = (
  user: UserDetails,
  organisation: Organisation
): RemoveUserAction => {
  return {
    type: REMOVE_USER_TYPE,
    user: user,
    organisation: organisation,
  };
};

// Remove user success
export const REMOVE_USER_SUCCESS_TYPE = "REMOVE_USER_SUCCESS_TYPE";
export interface RemoveUserSuccessAction {
  type: typeof REMOVE_USER_SUCCESS_TYPE;
  user: UserDetails;
}
export const removeUserSuccess = (user: UserDetails): RemoveUserSuccessAction => {
  return {
    type: REMOVE_USER_SUCCESS_TYPE,
    user: user,
  };
};

// Remove user error
export const REMOVE_USER_ERROR_TYPE = "REMOVE_USER_ERROR_TYPE";
export interface RemoveUserErrorAction {
  type: typeof REMOVE_USER_ERROR_TYPE;
  error: string;
}
export const removeUserError = (error: string): RemoveUserErrorAction => {
  return {
    type: REMOVE_USER_ERROR_TYPE,
    error: error,
  };
};

type UserDetailAction =
  | LoadUserAction
  | LoadUserErrorAction
  | LoadUserSuccessAction
  | RemoveUserAction
  | RemoveUserSuccessAction
  | RemoveUserErrorAction;

export default UserDetailAction;
