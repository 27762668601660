import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import PasswordTextField from "../../../../ui/components/PasswordTextField";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { FieldState } from "../../../../ui/components/PrimaryTextField";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";

interface ExternalProps {
  password: FieldState;
  isLoading: boolean;

  handlePasswordChanged: (value: string) => void;
  handleSubmit: () => void;
}

type Props = ExternalProps & WithTranslation;

const ResetPasswordForm = (props: Props) => {
  const title = props.t("forgotPasswordSubmit.pageTitle");
  const hint = props.t("forgotPasswordSubmit.passwordHint");
  const submitButton = props.t("forgotPasswordSubmit.resetButton");
  const helperText = props.t("validationHelper.passwordReset");
  const smartphoneHint = props.t("forgotPasswordSubmit.smartphoneHint");

  return (
    <CenteredAuthCard title={title}>
      <Grid container spacing={DEFAULT_SPACING} direction="column">
        <Grid item />

        <Grid item>
          <PasswordTextField
            {...props.password}
            hint={hint}
            disabled={props.isLoading}
            onChange={props.handlePasswordChanged}
            helperText={helperText}
          />

          <Box marginTop={SMALL_SPACING} />

          <Typography align="center" variant="body1" gutterBottom color="secondary">
            {smartphoneHint}
          </Typography>
        </Grid>

        <Grid item>
          <PrimaryButton
            label={submitButton}
            endIcon={<ArrowIcon />}
            isLoading={props.isLoading}
            onClick={props.handleSubmit}
            fullWidth
          />
        </Grid>
      </Grid>
    </CenteredAuthCard>
  );
};

export default withTranslation()(ResetPasswordForm);
