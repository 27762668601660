import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Role from "../../../../rest/roles/model/Role";
import UserDetails from "../../../../rest/user/model/UserDetails";
import BorderedBox from "../../../../ui/components/BorderedBox";
import LabelledText from "../../../../ui/components/LabelledText";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../../ui/theme/dimensions";
import displayNameForRole from "../utils/displayNameForRole";

interface Props extends WithTranslation {
  //user: OrganisationUser;
  user: UserDetails;
  role?: Role;
  organisation: Organisation | undefined;
  noRoleDisplay?: boolean;
}

const UserDetailPanel = (props: Props) => {
  const user = props.user;

  const firstNameLabel = props.t("userDetailPage.firstNameLabel");
  const lastNameLabel = props.t("userDetailPage.lastNameLabel");
  const emailLabel = props.t("userDetailPage.emailLabel");
  const contactNumberLabel = props.t("userDetailPage.numberLabel");
  const roleLabel = props.t("userDetailPage.roleLabel");
  const organisationLabel = props.t("userDetailPage.organisationLabel");
  const postcodeLabel = props.t("userDetailsPanel.postcodeHeader");

  const organisationText = props.organisation ? (
    <LabelledText label={organisationLabel} text={props.organisation.name} gutterBottom />
  ) : undefined;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="top"
      paddingX={LARGE_SPACING}
      justifyContent="flex-start"
      marginX={EXTRA_LARGE_SPACING}
    >
      <Box flexGrow={1}>
        <LabelledText label={firstNameLabel} text={user.first_name} gutterBottom />
        <LabelledText label={lastNameLabel} text={user.last_name} gutterBottom />
        <LabelledText label={emailLabel} text={user.email} gutterBottom />
        <LabelledText label={contactNumberLabel} text={user.phone_number} gutterBottom />
        <LabelledText label={postcodeLabel} text={user.address_postcode} gutterBottom />
      </Box>
      {props.role === undefined ? null : (
        <Box flexGrow={1}>
          <BorderedBox>
            {organisationText}
            <LabelledText
              label={roleLabel}
              text={displayNameForRole(props.role.name, props.t)}
            />
          </BorderedBox>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(UserDetailPanel);
