import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import OrganisationUser from "../../../rest/organisation/model/OrganisationUser";
import UserDetails from "../../../rest/user/model/UserDetails";
import { USER_LIST_ROUTE, buildUserDetailRoute } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import EmptyStateDisplay from "../../../ui/components/EmptyStateDisplay";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import InviteTable from "../../../ui/components/InviteTable/InviteTable";
import OutlineButton from "../../../ui/components/OutlineButton";
import TabModel from "../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import { resetMenu } from "../../../ui/components/PendingInviteMenu/redux/PendingInviteMenuAction";
import SearchBar from "../../../ui/components/SearchBar";
import Spinner from "../../../ui/components/Spinner";
import TableHeader from "../../../ui/components/TableList/components/TableHeader";
import UserTable from "../../../ui/components/UserTable/UserTable";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../ui/theme/dimensions";
import InviteDialog from "../../invites/InviteDialog/InviteDialog";
import { userSearchTextChanged } from "./redux/UserListAction";
import UserListState from "./redux/UserListState";
import isOrgDevFeatureEnabled from "../../../redux/utils/isOrgDevFeatureEnabled";
import { RoleName } from "../../../rest/roles/model/Role";

interface ReduxStateProps extends UserListState {
  currentOrgId: string | undefined;
  receivedUserInvite: PendingInvite | undefined;
  isOrgDevFeatureEnabled: boolean;
  error: string | undefined;
  users: OrganisationUser[] | undefined;
  invites: PendingInvite[] | undefined;
}

interface ReduxDispatchProps {
  onSearchTextChanged: (value: string) => void;
  resetInviteMenu: () => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & RouteComponentProps & WithT;

type UserListPageTab = "admins" | "engineers" | "invites" | "developers" | "owners";

interface State {
  tab: UserListPageTab;
  forceDialogRole: RoleName | undefined;
  addDialogOpen: boolean;
}

class UserListPage extends React.Component<Props, State> {
  state = {
    tab: "owners" as UserListPageTab,
    forceDialogRole: undefined,
    addDialogOpen: false,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      this.props.location.search.includes("inviteUser=true") &&
      prevProps.location.search !== this.props.location.search
    ) {
      this.props.history.replace(USER_LIST_ROUTE);
      this.setState({ addDialogOpen: true, forceDialogRole: "developer" });
    }
  }

  componentDidMount(): void {
    if (this.props.location.search.includes("inviteUser=true")) {
      this.props.history.replace(USER_LIST_ROUTE);
      this.setState({ addDialogOpen: true, forceDialogRole: "developer" });
    }
  }

  render() {
    const searchPlaceholder = this.props.t("userList.searchPlaceholder");
    const addButtonText = this.props.t("userList.inviteButton");

    return (
      <DashboardLayout>
        <Box>
          <Box paddingY={1}>
            <PageHeaderTabs
              tabs={this.buildTabs()}
              activeKey={this.state.tab}
              tabClicked={this.handleTabSelected}
            />
          </Box>

          <Box display="flex" flexDirection="row" paddingX={DEFAULT_SPACING}>
            <Box display="flex" flexGrow={1} marginRight={SMALL_SPACING}>
              <SearchBar
                placeholder={searchPlaceholder}
                value={this.props.searchText}
                onChange={this.props.onSearchTextChanged}
              />
            </Box>

            <OutlineButton
              label={addButtonText}
              endIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={this.openDialog}
              filled
            />
          </Box>

          <Box padding={DEFAULT_SPACING}>
            <TableHeader text={this.getTableHeaderText()} />
            {this.renderContent()}
          </Box>

          <InviteDialog
            pageType="user"
            forcedUserRole={this.state.forceDialogRole}
            open={this.state.addDialogOpen}
            onClose={this.onDialogClose}
            warningPopup
          />
        </Box>
      </DashboardLayout>
    );
  }

  buildTabs = (): TabModel[] => {
    return [
      {
        title: "userList.ownerTab",
        navKey: "owners",
      },
      {
        title: "userList.adminTab",
        navKey: "admins",
      },
      {
        title: "userList.engineersTab",
        navKey: "engineers",
      },
      ...(this.props.isOrgDevFeatureEnabled
        ? [
            {
              title: "userList.developersTab",
              navKey: "developers",
            },
          ]
        : []),
      {
        title: "userList.invitesTab",
        navKey: "invites",
      },
    ];
  };

  getTableHeaderText = (): string => {
    switch (this.state.tab) {
      case "owners":
        return "userList.ownerTab";
      case "admins":
        return "userList.adminTab";
      case "engineers":
        return "userList.engineersTab";
      case "developers":
        return "userList.developersTab";
      case "invites":
        return "userList.invitesTab";
    }
  };

  renderContent = () => {
    const users = this.props.users || [];
    const invites = this.props.invites || [];

    if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else if (this.props.isLoading || users === undefined || invites === undefined) {
      return <Spinner />;
    } else {
      return this.renderTable(users, invites);
    }
  };

  renderTable = (users: OrganisationUser[], invites: PendingInvite[]) => {
    if (this.state.tab === "invites") {
      if (invites.length === 0) {
        return <EmptyStateDisplay message="userList.emptyText" />;
      } else {
        return <InviteTable invites={invites} role={true} />;
      }
    }

    let tableUsers: UserDetails[];
    if (this.state.tab === "admins") {
      tableUsers = users
        .filter((user) => user.role.name === "administrator")
        .map((user) => user.user);
    } else if (this.state.tab === "owners") {
      tableUsers = users
        .filter((user) => user.role.name === "owner")
        .map((user) => user.user);
    } else if (this.state.tab === "developers") {
      tableUsers = users
        .filter((user) => user.role.name === "developer")
        .map((user) => user.user);
    } else {
      tableUsers = users
        .filter((user) => user.role.name === "installer")
        .map((user) => user.user);
    }

    if (tableUsers.length === 0) {
      return <EmptyStateDisplay message="userList.emptyText" />;
    } else {
      return <UserTable users={tableUsers} onUserClicked={this.handleUserClicked} />;
    }
  };

  //Handlers

  onDialogClose = () => {
    this.setState({
      forceDialogRole: undefined,
      addDialogOpen: false,
    });
  };

  openDialog = () => {
    this.setState({
      forceDialogRole: undefined,
      addDialogOpen: true,
    });
  };

  handleUserClicked = (user: UserDetails) => {
    if (this.props.currentOrgId) {
      const route = buildUserDetailRoute(this.props.currentOrgId, user.id);
      this.props.history.push(route);
    }
  };

  handleTabSelected = (tab: string) => {
    this.setState({ tab: tab as UserListPageTab });

    // The "Invite resent" SB can keep appearing too long
    // unless it's cleared here
    this.props.resetInviteMenu();
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    ...state.userList,
    currentOrgId: state.activeOrganisation.currentOrganisation?.id,
    receivedUserInvite: state.inviteEntity.receivedInvite,
    isOrgDevFeatureEnabled: isOrgDevFeatureEnabled(
      state.activeOrganisation.currentOrganisation?.features || []
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    onSearchTextChanged: (text) => dispatch(userSearchTextChanged(text)),
    resetInviteMenu: () => dispatch(resetMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(UserListPage)));
