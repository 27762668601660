import React from "react";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import UserDetails from "../../../rest/user/model/UserDetails";
import TableList from "../TableList/TableList";
import UserTableRow from "./components/UserTableRow";

const smallCellWidth = "15vw";

interface ReduxStateProps {
  inviteResent: boolean;
  isAdeyAdmin: boolean;
  currentUserId: string | undefined;
  canDeleteUsers: boolean;
}

interface ExternalProps {
  users: UserDetails[];
  onUserClicked?: (user: UserDetails) => void;
}

type Props = ReduxStateProps & ExternalProps;

interface State {
  menuUser: UserDetails | undefined;
  menuInvite: PendingInvite | undefined;
  anchorElement: Element | undefined;
}

class UserTable extends React.Component<Props, State> {
  state = {
    menuUser: undefined,
    menuInvite: undefined,
    anchorElement: undefined,
  };

  render() {
    //JSX Elements
    const items = () => {
      return this.props.users?.map((user) => (
        <UserTableRow
          key={user.id}
          user={user}
          smallCellWidth={smallCellWidth}
          onClick={this.props.onUserClicked}
        />
      ));
    };

    const headerKeys: string[] = ["userTable.emailHeader", "userTable.phoneHeader"];
    const headerTitle: string = "userTable.nameHeader";

    return (
      <TableList itemRows={items()} headerKeys={headerKeys} headerTitle={headerTitle} />
    );
  }

  onUserMenuPressed = (user: UserDetails, e: Element) => {
    this.setState({ menuUser: user, anchorElement: e, menuInvite: undefined });
  };

  inviteMenuPressHandler = (invite: PendingInvite, e: Element) => {
    this.setState({ menuUser: undefined, anchorElement: e, menuInvite: invite });
  };

  onMenuClosed = () => {
    this.setState({
      menuUser: undefined,
      menuInvite: undefined,
      anchorElement: undefined,
    });
  };

  canBuildMenu = (user: UserDetails) => {
    return this.props.canDeleteUsers && user.id !== this.props.currentUserId;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  isAdeyAdmin: state.adeyAdmin.hasAdminAccess,
  inviteResent: state.pendingInviteMenu.resendInvite.success,
  currentUserId: state.login.currentUserId,
  canDeleteUsers: state.activeOrganisation.permissions.canRemoveActiveOrganisationUsers,
});

export default connect(mapStateToProps)(UserTable);
