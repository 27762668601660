import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { LoqateFindItem } from "../../../../rest/loqate/model/LoqateFindResponse";
import BorderedBox from "../../BorderedBox";
import SecondaryButton from "../../SecondaryButton";
import AddressLookupSelectItem from "./AddressLookupSelectItem";

interface ExternalProps {
  items: LoqateFindItem[];
  loadingItem: LoqateFindItem | undefined;

  onSearchAgainClicked: () => void;
  onItemSelected: (item: LoqateFindItem) => void;
}

type Props = ExternalProps & WithTranslation;

const AddressLookupSelect = (props: Props) => {
  const label = props.t("addressLookup.searchAgainButton");

  const items = props.items.map((item) => (
    <AddressLookupSelectItem
      key={item.Id}
      item={item}
      loading={item.Id === props.loadingItem?.Id}
      onItemSelected={props.onItemSelected}
    />
  ));

  return (
    <Box>
      <BorderedBox padding={0} maxHeight={300} marginBottom={1} overflow="auto">
        {items}
      </BorderedBox>

      <Box display="flex" justifyContent="center">
        <SecondaryButton label={label} onClick={props.onSearchAgainClicked} />
      </Box>
    </Box>
  );
};

export default withTranslation()(AddressLookupSelect);
