import { isLocalizedError } from "../LocalizedError";
import { ErrorHandler } from "./getErrorMessage";
import axiosErrorHandler from "./axiosErrorHandler";

/**
 * An errorHandler that is used for every call
 * The more specific ErrorHandlers should be prioritized over this.
 */
const globalErrorHandler: ErrorHandler = (e: any): string | undefined => {
  if (isLocalizedError(e)) {
    // If this is a LocalizedError then use it's error message
    return e.messageKey;
  }

  const axiosError = axiosErrorHandler(e);
  if (axiosError) {
    return axiosError;
  }

  return undefined;
};

export default globalErrorHandler;
