import AdminMoveEntityAction from "./AdminMoveEntityAction";
import AdminMoveEntityState from "./AdminMoveEntityState";

const initialState: AdminMoveEntityState = {
  movingEntity: false,
  searchingDestination: false,
  foundDestination: undefined,
  destinations: [],
  moveResults: undefined,
  error: undefined,
};

const adminMoveEntityReducer = (
  state = initialState,
  action: AdminMoveEntityAction
): AdminMoveEntityState => {
  switch (action.type) {
    case "ADMIN_INIT_DESTINATION_SEARCH":
      return { ...state, searchingDestination: true, error: undefined };
    case "ADMIN_DESTINATION_SEARCH_SUCCESS":
      return {
        ...state,
        searchingDestination: false,
        foundDestination: true,
        destinations: action.destinationItems,
      };
    case "ADMIN_DESTINATION_SEARCH_ERROR":
      return {
        ...state,
        searchingDestination: false,
        foundDestination: false,
        error: action.error,
      };
    case "INIT_ADMIN_MOVE_ENTITIES":
      return {
        ...state,
        movingEntity: true,
        moveResults: undefined,
        error: undefined,
      };
    case "ADMIN_MOVE_ENTITIES_ERROR": {
      return {
        ...state,
        movingEntity: false,
        moveResults: action.moveResults,
        error: action.error,
      };
    }
    case "ADMIN_MOVE_ENTITIES_SUCCESS": {
      return {
        ...state,
        movingEntity: false,
        moveResults: action.moveResults,
        error: undefined,
      };
    }
    case "ADMIN_RESET_MOVE_STATE": {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default adminMoveEntityReducer;
