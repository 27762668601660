import CountryAction from "./CountryAction";
import CountryState from "./CountryState";

const initialState: CountryState = {
  countryList: undefined,
  isLoading: undefined,
  error: undefined,
};

const countryReducer = (state = initialState, action: CountryAction): CountryState => {
  switch (action.type) {
    case "CREATE_COUNTRY_TYPE":
      return {
        countryList: state.countryList,
        isLoading: true,
        error: undefined,
      };
    case "COUNTRY_CREATE_SUCCESS_TYPE":
      return {
        countryList: [...(state.countryList || []), action.country],
        isLoading: false,
        error: undefined,
      };
    case "CREATE_COUNTRY_ERROR_TYPE":
      return {
        countryList: state.countryList,
        isLoading: false,
        error: action.error,
      };
    case "LOAD_COUNTRY_TYPE":
      return {
        countryList: state.countryList,
        isLoading: true,
        error: undefined,
      };
    case "COUNTRY_LOADED_TYPE":
      return {
        countryList: !state.countryList?.some(
          (country) => country.id === action.country.id
        )
          ? [...(state.countryList || []), action.country]
          : state.countryList,
        isLoading: false,
        error: undefined,
      };
    case "COUNTRY_LOAD_ERROR_TYPE":
      return {
        countryList: state.countryList,
        isLoading: false,
        error: action.error,
      };
    case "LOAD_COUNTRY_LIST_TYPE":
      return {
        countryList: state.countryList,
        isLoading: true,
        error: undefined,
      };
    case "COUNTRY_LIST_LOADED_TYPE":
      return {
        countryList: action.countries,
        isLoading: false,
        error: undefined,
      };
    case "COUNTRY_LIST_ERROR_TYPE":
      return {
        countryList: undefined,
        isLoading: false,
        error: action.error,
      };
    case "UPDATE_COUNTRY_TYPE":
      return {
        countryList: state.countryList,
        isLoading: true,
        error: undefined,
      };
    case "COUNTRY_UPDATE_SUCCESS_TYPE":
      return {
        countryList: state.countryList?.map((country) =>
          country.id === action.country.id ? action.country : country
        ),
        isLoading: false,
        error: undefined,
      };
    case "UPDATE_COUNTRY_ERROR_TYPE":
      return {
        countryList: undefined,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default countryReducer;
