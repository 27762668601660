import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import InvitationForm from "../../../../rest/organisation/model/InvitationForm";

//Entity Invitation Initialisation
export const INVITE_ENTITY_TYPE = "INVITE_ENTITY";

export interface InviteEntityAction {
  type: typeof INVITE_ENTITY_TYPE;
  invite: InvitationForm;
}

export const inviteEntity = (form: InvitationForm) => ({
  type: INVITE_ENTITY_TYPE,
  invite: form,
});

//Entity Successfully Invited
export const ENTITY_INVITED_TYPE = "ENTITY_INVITED";

export interface EntityInvitedAction {
  type: typeof ENTITY_INVITED_TYPE;
  details: PendingInvite;
}

export const entityInvited = (details: PendingInvite) => ({
  type: ENTITY_INVITED_TYPE,
  details: details,
});

//Entity Invitation Error
export const ENTITY_INVITE_ERROR_TYPE = "ENTITY_INVITE_ERROR";

export interface EntityInviteErrorAction {
  type: typeof ENTITY_INVITE_ERROR_TYPE;
  error: string;
}

export const inviteEntityError = (error: string) => ({
  type: ENTITY_INVITE_ERROR_TYPE,
  error: error,
});

//Union type
type CurrentInviteAction =
  | InviteEntityAction
  | EntityInvitedAction
  | EntityInviteErrorAction;

export default CurrentInviteAction;
