import { Box, Grid } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import UserDetails from "../../../../rest/user/model/UserDetails";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail from "../../../../ui/components/NavigationRail";
import OrgAndRoleTable from "../../../../ui/components/OrgAndRoleTable/OrgAndRoleTable";
import TabModel from "../../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import SuccessSnackbarHandler from "../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../ui/theme/dimensions";
import getFullName from "../../../../utils/getFullName";
import EditableUserDetailsPanel from "./EditableUserDetailsPanel";
import NoOrgsDisplay from "./NoOrgsDisplay";

type TabKey = "details" | "organisations";

interface Props extends WithT {
  userDetails: UserDetails;
  organisations: MemberOrganisation[];
  loading: boolean;
  error: string | undefined;

  userRemovedFromOrg?: boolean;

  onUserListClicked: () => void;
  onDetailsUpdated: (user: UserDetails) => void;
  onOrganisationSelected: (org: Organisation) => void;
  onUserRemovedFromOrganisation: (user: UserDetails, org: Organisation) => void;
}

interface State {
  activeTab: TabKey;
  removeSuccess: boolean;
}

class LoadedUserDetailsPage extends React.Component<Props, State> {
  state = {
    activeTab: "details" as TabKey,
    removeSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.userRemovedFromOrg !== prevProps.userRemovedFromOrg) {
      this.setState({ removeSuccess: this.props.userRemovedFromOrg !== undefined });
    }
  }

  render() {
    const usersLabel = this.props.t("platformUserDetail.usersLink");

    return (
      <Box>
        <Box paddingX={DEFAULT_SPACING} paddingTop={DEFAULT_SPACING}>
          <NavigationRail
            currentPageTitle={getFullName(this.props.userDetails)}
            links={[
              {
                key: "users",
                name: usersLabel,
                onClick: this.props.onUserListClicked,
              },
            ]}
          />
        </Box>

        <PageHeaderTabs
          activeKey={this.state.activeTab}
          tabs={this.buildTabModels()}
          tabClicked={this.handleTabClicked}
        />

        <Box
          marginX={EXTRA_LARGE_SPACING}
          marginBottom={LARGE_SPACING}
          marginTop={DEFAULT_SPACING}
        >
          {this.renderTabBody()}
        </Box>

        <ErrorSnackbarHandler message={this.props.error} />

        <SuccessSnackbarHandler
          message={
            this.state.removeSuccess ? "platformUserDetail.removedSuccess" : undefined
          }
        />
      </Box>
    );
  }

  renderTabBody = () => {
    switch (this.state.activeTab) {
      case "details":
        return (
          <Grid container>
            <Grid item sm={12} md={6}>
              <EditableUserDetailsPanel
                loading={this.props.loading}
                error={this.props.error}
                userDetails={this.props.userDetails}
                onDetailsUpdated={this.props.onDetailsUpdated}
              />
            </Grid>
          </Grid>
        );

      case "organisations":
        return this.props.organisations.length > 0 ? (
          <OrgAndRoleTable
            loading={this.props.loading}
            user={this.props.userDetails}
            organisations={this.props.organisations}
            onOrgClicked={this.props.onOrganisationSelected}
            onRemoveClicked={this.props.onUserRemovedFromOrganisation}
            userRemoved={this.props.userRemovedFromOrg}
          />
        ) : (
          <NoOrgsDisplay />
        );
    }
  };

  buildTabModels = (): TabModel[] => {
    return [
      { title: "platformUserDetail.userDetailsTab", navKey: "details" },
      { title: "platformUserDetail.orgsTab", navKey: "organisations" },
    ];
  };

  // Handlers

  handleTabClicked = (key: string) => {
    this.setState({ activeTab: key as TabKey });
  };
}

export default withTranslation()(LoadedUserDetailsPage);
