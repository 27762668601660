import { Box, Grid } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import AdeyBackground from "../../../../../ui/assets/AdeyBackground/AdeyBackground";
import AdeyLogo from "../../../../../ui/assets/AdeyLogo/AdeyLogo";
import LanguageSwitcher from "../../../../../ui/components/LanguageSwitcher";
import SignOutButton from "../../../../../ui/components/SignOutButton";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import SignpostCard from "./components/SignpostCard";

const _logoHeight = 100;

interface Props extends WithT {
  onCreateOrgClicked: () => void;
}

const LimboSignpostPage = (props: Props) => {
  const inviteTitle = props.t("limboSignpost.inviteTitle");
  const createTitle = props.t("limboSignpost.createTitle");
  const createAction = props.t("limboSignpost.createAction");
  const inviteBody = props.t("limboSignpost.inviteBody");
  const createBody = props.t("limboSignpost.createBody");
  const contactLink = props.t("limboSignpost.contactLink");

  return (
    <Box minWidth="100%" minHeight="100vh" bgcolor={BACKGROUND_ACCENT_COLOR}>
      <AdeyBackground height="60vh" width="100%" />

      <Box
        position="absolute"
        zIndex={5}
        right={0}
        padding={SMALL_SPACING}
        display="flex"
      >
        <LanguageSwitcher styled={true} />
        <Box marginX="10px" />
        <SignOutButton />
      </Box>

      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100%"
        zIndex="2"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          marginBottom={DEFAULT_SPACING}
        >
          <AdeyLogo height={_logoHeight} color="white" />
        </Box>

        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={1} md={2} />

          <Grid item xs={5} md={4}>
            <SignpostCard
              title={inviteTitle}
              body={inviteBody}
              contactLink={contactLink}
            />
          </Grid>

          <Grid item xs={5} md={4}>
            <SignpostCard
              title={createTitle}
              body={createBody}
              contactLink={contactLink}
              actionTitle={createAction}
              onActionPressed={props.onCreateOrgClicked}
            />
          </Grid>

          <Grid item xs={1} md={2} />
        </Grid>

        <Box height={_logoHeight + DEFAULT_SPACING} />
      </Box>
    </Box>
  );
};

export default withTranslation()(LimboSignpostPage);
