import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React from "react";
import Env from "../../../app/environment/Env";
import Location from "./model/Location";

interface ExternalProps {
  location: Location;
  zoom: number;
}

//Due to how Google Maps API is implemented width and height serve as maximum dimensions possible.
//Map View will shrink to fill the container.

const containerStyle = {
  width: "100%",

  height: "500px",
};

const MapView = (props: ExternalProps) => {
  return (
    <LoadScript googleMapsApiKey={Env.googleMapsApiKey}>
      <GoogleMap
        center={props.location}
        zoom={props.zoom}
        mapContainerStyle={containerStyle}
      >
        <Marker position={props.location} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapView;
