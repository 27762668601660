import { Dialog } from "@material-ui/core";
import React from "react";
import RootState from "../../../redux/RootState";
import { connect } from "react-redux";
import CreateOrganisationPanel from "./CreateOrganisationPanel";
import { withTranslation } from "react-i18next";
import { DEFAULT_SPACING, LARGE_SPACING } from "../../../ui/theme/dimensions";
import { Box } from "@material-ui/core";

//Props
interface ReduxStateProps {
  isLoading: boolean;
  errorMessage: string | undefined;
}

interface ExternalProps {
  isCreatingOrg: boolean;
  onBackPressed: () => void;
}

type Props = ReduxStateProps & ExternalProps;

class CreateOrganisationDialog extends React.Component<Props> {
  render() {
    return (
      <Dialog open={this.props.isCreatingOrg} onClose={this.handleBackPressed}>
        <Box paddingX={LARGE_SPACING} paddingY={DEFAULT_SPACING}>
          <CreateOrganisationPanel onBackPressed={this.handleBackPressed} />
        </Box>
      </Dialog>
    );
  }

  //Handlers

  handleBackPressed = () => {
    this.props.onBackPressed();
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isLoading: state.pendingInvite.isLoading,
    errorMessage: state.pendingInvite.error,
  };
};

export default connect(mapStateToProps)(withTranslation()(CreateOrganisationDialog));
