import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import CreateOrganisationPage from "./pages/CreateOrganisation/CreateOrganisationPage";
import PersonalInfoFormData from "../../../rest/user/model/PersonalInfoFormData";
import PersonalInfoPage from "./pages/PersonalInfo/PersonalInfoPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage/TermsAndConditionsPage";
import {
  createOrganisationSubmitted,
  personalInfoSubmitted,
} from "./redux/AccountSetupActions";
import { SetupStage } from "./redux/AccountSetupState";
import CreateOrganisationForm from "./pages/CreateOrganisation/model/CreateOrganisationForm";
import LimboSignpostPage from "./pages/LimboSignpost/LimboSignpostPage";
import { OrganisationUserInvitation } from "../../../rest/invites/model/PendingInvite";
import LoadingPage from "../../loading/LoadingPage";
import AcceptOrganisationInvitePage from "./pages/AcceptOrganisationInvite/AcceptOrganisationInvitePage";
import { Country } from "../../../rest/countries/model/Country";
import CurrentUserDetails from "../../account/AccountSettingsPage/model/CurrentUserDetails";
import CategorisationPage from "./pages/CategorisationPage/CategorisationPage";

interface ReduxStateProps {
  organisation: SetupStage;
  personalInfo: SetupStage;
  segmentation: SetupStage;
  userDetails: CurrentUserDetails | undefined;
  accountSetupError: string | undefined;

  pendingInvites: OrganisationUserInvitation[] | undefined;
  hasAcceptedInvite: boolean;

  countryList: Country[] | undefined;
}

interface ReduxDispatchProps {
  personalInfoSubmitted: (form: PersonalInfoFormData) => void;
  organisationSubmitted: (form: CreateOrganisationForm) => void;
}

interface ExternalProps {
  children: ReactNode;
}

type Props = ReduxStateProps & ReduxDispatchProps & ExternalProps;

interface State {
  acceptTermsClicked: boolean;
  createOrgSelected: boolean;
  segmentationCompleted: boolean;
}

/**
 * If your account isn't fully set up (no org, or personal details set or segmentation completed)
 * This component will show the relevant screen.
 * Otherwise it'll return it's children.
 */
class AccountSetupNavigator extends React.Component<Props, State> {
  state = {
    acceptTermsClicked: false,
    createOrgSelected: false,
    segmentationCompleted: false,
  };

  render() {
    if (this.props.personalInfo !== "set-up") {
      if (this.state.acceptTermsClicked) {
        return (
          <PersonalInfoPage
            error={this.props.accountSetupError}
            onSubmit={this.props.personalInfoSubmitted}
            isLoading={this.props.personalInfo === "loading"}
          />
        );
      } else {
        return <TermsAndConditionsPage onTermsAccepted={this.termsAcceptedHandler} />;
      }
    }

    if (this)
      if (this.props.organisation !== "set-up") {
        if (this.props.pendingInvites === undefined || this.props.hasAcceptedInvite) {
          // If we haven't loaded pending invites yet, or if the invite has been accepted
          // and we're loading the personal details now.
          return <LoadingPage />;
        } else if (this.props.pendingInvites.length > 0) {
          // If we do have any pending invites, show them first
          return (
            <AcceptOrganisationInvitePage pendingInvite={this.props.pendingInvites[0]} />
          );
        } else if (this.state.createOrgSelected) {
          // If the user has said they want to create an org show them
          // the page to do it
          return (
            <CreateOrganisationPage
              countryList={this.props.countryList}
              isLoading={this.props.organisation === "loading"}
              onOrgCreated={this.props.organisationSubmitted}
              error={this.props.accountSetupError}
            />
          );
        } else {
          // Otherwise prompt them to the signpost page
          return (
            <LimboSignpostPage
              onCreateOrgClicked={() => this.setState({ createOrgSelected: true })}
            />
          );
        }
      }
    if (this.props.segmentation !== "set-up") {
      if (this.props.userDetails === undefined) {
        return <LoadingPage />;
      } else {
        return <CategorisationPage />;
      }
    }
    // If this isn't needed then just return the child
    return this.props.children;
  }

  termsAcceptedHandler = () => {
    this.setState({ acceptTermsClicked: true });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    countryList: state.countryList.countryList,
    pendingInvites: state.pendingInvite.invites?.user,
    hasAcceptedInvite: state.pendingInvite.hasAcceptedAnInvite,
    organisation: state.accountSetup.organisation,
    personalInfo: state.accountSetup.personalDetails,
    accountSetupError: state.accountSetup.error,
    segmentation: state.accountSetup.segmentation,
    userDetails: state.currentUser.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    personalInfoSubmitted: (form) => dispatch(personalInfoSubmitted(form)),
    organisationSubmitted: (form) => dispatch(createOrganisationSubmitted(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetupNavigator);
