import { TFunction } from "react-i18next";
import SenseDeviceStatus from "../../../rest/sense/model/SenseDeviceStatus";
import SenseDeviceCardStatus from "../models/SenseDeviceCardStatus";

const getDeviceCardStatus = (
  notifications: SenseDeviceStatus[],
  t: TFunction
): SenseDeviceCardStatus => {
  let cardStatus: SenseDeviceCardStatus = {
    ragStatus: "green",
    message: t("senseDeviceStatus.green"),
  };

  let priority = 3;

  notifications.forEach((notification) => {
    if (priority > notification.status.priority) {
      priority = notification.status.priority;
    }
  });

  switch (priority) {
    case 1:
      cardStatus = {
        ragStatus: "red",
        message: t("senseDeviceStatus.red"),
      };
      break;
    case 2:
      cardStatus = {
        ragStatus: "amber",
        message: t("senseDeviceStatus.amber"),
      };
      break;
    case 3:
      cardStatus = {
        ragStatus: "green",
        message: t("senseDeviceStatus.green"),
      };
      break;
  }

  return cardStatus;
};

export default getDeviceCardStatus;
