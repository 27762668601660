import { Box, Dialog, Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import Organisation from "../../../rest/organisation/model/Organisation";
import {
  DEFAULT_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../ui/theme/dimensions";
import Address from "../PropertyDetailPage/model/Address";
import AddPropertyAddressForm from "./components/AddPropertyAddressForm";
import AddPropertyContactForm from "./components/AddPropertyContactForm";
import AddPropertyFormModel from "./model/AddPropertyFormModel";
import ContactFormState from "./model/ContactFormState";

const styles = (theme: any) => ({
  dialogPaper: {},
});

interface StateProps extends WithStyles<typeof styles>, WithTranslation {
  isPropertyAdded: boolean | undefined;
  isLoading: boolean;
  error: string | undefined;
  open: boolean;
  onClose: () => void;
  onPropertyFormSubmitted: (propertyData: AddPropertyFormModel) => void;
}

interface ReduxStateProps {
  currentOrganisation: Organisation | undefined;
  organisationList: Organisation[];
  homeZoneAvailable: boolean;
}

type Props = StateProps & ReduxStateProps;

enum PageContent {
  propertyAddress,
  contactAndOwner,
}

export interface AddPropertyDialogState {
  pageContent: PageContent;
  address: Address;
  property: ContactFormState;
}

class AddPropertyDialog extends React.Component<Props, AddPropertyDialogState> {
  state = {
    pageContent: PageContent.propertyAddress,
    address: {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      postcode: "",
      country: "",
    },
    property: {
      contactFirstName: "",
      contactLastName: "",
      contactNumber: "",
      email: "",
      owner: "",
      notes: "",
      homezoneStatus: false,
    },
  };

  render() {
    const title = this.props.t("addPropertyDialog.title");
    const classes = this.props.classes;

    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={this.props.open}
        onClose={this.onDialogClosed}
      >
        <Box
          paddingLeft={LARGE_SPACING}
          paddingRight={LARGE_SPACING}
          paddingTop={DEFAULT_SPACING}
          paddingBottom={SMALL_SPACING}
        >
          <Grid container spacing={SMALL_SPACING} alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                <strong>{title.toUpperCase()}</strong>
              </Typography>
            </Grid>
            {this.renderContent()}
          </Grid>

          <ErrorSnackbarHandler message={this.props.error} />
        </Box>
      </Dialog>
    );
  }

  renderContent = (): React.ReactElement => {
    const lookupEnabled: boolean =
      this.props.currentOrganisation?.address_country.code !== "FR";

    switch (this.state.pageContent) {
      case PageContent.propertyAddress:
        return (
          <AddPropertyAddressForm
            lookupEnabled={lookupEnabled}
            address={this.state.address}
            onNextClicked={this.onAddressSubmitted}
          />
        );
      case PageContent.contactAndOwner:
        return (
          <AddPropertyContactForm
            organisationList={this.props.organisationList}
            addingProperty={this.props.isLoading}
            setContactProps={this.onContactPropsSet}
            onFormSubmitted={this.onFormSubmitted}
            homezoneAvailable={this.props.homeZoneAvailable}
            property={this.state.property}
          />
        );
    }
  };

  // MARK: React lifecycle methods

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.isPropertyAdded !== prevProps.isPropertyAdded &&
      this.props.isPropertyAdded === true
    ) {
      this.onDialogClosed();
    }
  }

  // MARK: Handlers
  onAddressSubmitted = (address: Address) => {
    this.setState({
      ...this.state,
      address: address,
      pageContent: PageContent.contactAndOwner,
    });
  };

  onFormSubmitted = (contactProps: ContactFormState) => {
    this.setState(
      {
        ...this.state,
        property: contactProps,
      },
      () => {
        this.props.onPropertyFormSubmitted(this.createPropertyFormObject());
      }
    );
    //this.onDialogClosed();
  };

  onContactPropsSet = (contactProps: ContactFormState) => {
    this.setState({
      ...this.state,
      pageContent: this.state.pageContent - 1,
      property: contactProps,
    });
  };

  onDialogClosed = () => {
    if (this.props.isLoading !== true) {
      this.setState(
        {
          pageContent: PageContent.propertyAddress,
          address: {
            line1: "",
            line2: "",
            line3: "",
            city: "",
            postcode: "",
            country: "",
          },
          property: {
            contactFirstName: "",
            contactLastName: "",
            contactNumber: "",
            email: "",
            notes: "",
            homezoneStatus: false,
          },
        },
        () => {
          this.props.onClose();
        }
      );
    }
  };

  //Helpers
  createPropertyFormObject = () => {
    let propertyForm: AddPropertyFormModel = {
      contact_name: `${this.state.property.contactFirstName.trim()} ${this.state.property.contactLastName.trim()}`,
      contact_email: `${this.state.property.email.trim()}`,
      contact_phone: `${this.state.property.contactNumber.trim()}`,
      address_line_1: `${this.state.address.line1.trim()}`,
      address_line_2: `${this.state.address.line2.trim()}`,
      address_line_3: `${this.state.address.line3.trim()}`,
      address_city: `${this.state.address.city.trim()}`,
      address_country: `${this.state.address.country.trim()}`,
      address_postcode: `${this.state.address.postcode.trim()}`,
      notes: `${this.state.property.notes.trim()}`,
      homezone_reporting_enabled:
        this.state.property.homezoneStatus && this.state.address.country !== "AU", //Always set HomeZone to 'false' for Australian properties
    };
    return propertyForm;
  };
}
const mapStateToProps = (state: RootState): ReduxStateProps => {
  const isHomeZoneAvailable = () => {
    if (state.adeyAdmin.hasAdminAccess) {
      return (
        state.orgDetail.organisation?.address_country.code === "IE" ||
        state.orgDetail.organisation?.address_country.code === "GB"
      );
    } else {
      return (
        state.activeOrganisation?.currentOrganisation?.address_country.code === "IE" ||
        state.activeOrganisation?.currentOrganisation?.address_country.code === "GB"
      );
    }
  };

  return {
    currentOrganisation: state.activeOrganisation.currentOrganisation,
    organisationList: state.activeOrganisation.organisationList,
    homeZoneAvailable: isHomeZoneAvailable(),
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(withTranslation()(AddPropertyDialog))
);
