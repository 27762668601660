import { Box, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { LOGIN_ROUTE } from "../../../../routes/routes";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING, LARGE_SPACING } from "../../../../ui/theme/dimensions";

type Props = WithTranslation & RouteComponentProps;

const AccountActivationSuccessCard = (props: Props) => {
  const title = props.t("accountActivationPage.successPageTitle");
  const message = props.t("accountActivationPage.successMessage");
  const button = props.t("accountActivationPage.signInButton");

  const signInButtonHandler = () => {
    props.history.push(LOGIN_ROUTE);
  };

  return (
    <CenteredAuthCard title={title}>
      <Box marginTop={DEFAULT_SPACING} marginBottom={DEFAULT_SPACING}>
        <Typography align="center">{message}</Typography>
      </Box>
      <Box marginLeft={LARGE_SPACING} marginRight={LARGE_SPACING}>
        <PrimaryButton
          fullWidth
          endIcon={<ArrowIcon />}
          label={button}
          onClick={signInButtonHandler}
        />
      </Box>
    </CenteredAuthCard>
  );
};

export default withTranslation()(withRouter(AccountActivationSuccessCard));
