import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

interface ExternalProps {
  onSignInClicked: () => void;
}

type Props = ExternalProps & WithTranslation;

const ResetPasswordSuccessBody = (props: Props) => {
  const title = props.t("forgotPasswordSubmit.successTitle");
  const message = props.t("forgotPasswordSubmit.successMessage");
  const signInButton = props.t("forgotPasswordSubmit.successSignInButton");

  return (
    <CenteredAuthCard title={title}>
      <Grid container spacing={DEFAULT_SPACING} direction="column">
        <Grid item />

        <Grid item>
          <Typography align="center">{message}</Typography>
        </Grid>

        <Grid item>
          <PrimaryButton
            label={signInButton}
            endIcon={<ArrowIcon />}
            onClick={props.onSignInClicked}
            fullWidth
          />
        </Grid>
      </Grid>
    </CenteredAuthCard>
  );
};

export default withTranslation()(ResetPasswordSuccessBody);
