import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import UserDetails from "../../../../rest/user/model/UserDetails";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import getFullName from "../../../../utils/getFullName";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  user: UserDetails;

  onOkClicked: () => void;
}

const UserRemovedDialog = (props: Props) => {
  const title = props.t("userRemovedDialog.title");
  const bodyPlaceholder = props.t("userRemovedDialog.bodyPlaceholder");
  const confirmButton = props.t("userRemovedDialog.confirmButton");

  const body = bodyPlaceholder
    .replace("<user_name>", getFullName(props.user))
    .replace("<org_name>", props.organisationName);

  const okClickHandler = (e: any) => {
    e.stopPropagation();
    props.onOkClicked();
  };

  return (
    <Dialog open={props.open}>
      <Box padding={SMALL_SPACING}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>{body}</Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" onClick={okClickHandler}>
            {confirmButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(UserRemovedDialog);
