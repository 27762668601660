import { Box, Button, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactLinkify from "react-linkify";
import { InviteType } from "../../../../rest/organisation/model/InvitationForm";
import InvitationPopupImage from "../../../../ui/assets/InvitationPopupImage/InvitationPopupImage";
import ColoredText from "../../../../ui/components/ColoredText";
import { SECONDARY_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import CountryCode from "../../../../utils/CountryCode";

interface Props extends WithT {
  onNextClicked: () => void;
  onCancelClicked: () => void;
  type: InviteType;
  country?: CountryCode;
}

class InviteWarningPanel extends Component<Props> {
  render() {
    const titleKey = this.getTitle();
    const summaryKey = this.getTextSummary();

    const title = this.props.t(`inviteEntityPanel.${titleKey}`);
    const nextButton = this.props.t("buttons.confirm");
    const premiumAccessSummary = this.props.t(`inviteEntityPanel.${summaryKey}`);
    const infoSummary = this.props.t("inviteEntityPanel.infoSummary");

    const cancelButton = this.props.t("buttons.cancel");

    return (
      <Grid container direction="column" spacing={SMALL_SPACING}>
        <Grid item>
          <Typography variant="h2" align="center">
            <strong>{title.toUpperCase()}</strong>
          </Typography>
        </Grid>
        <Grid item />

        <Grid item>
          <Box paddingX={DEFAULT_SPACING}>
            <Typography align="center">{premiumAccessSummary}</Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box paddingX={DEFAULT_SPACING} paddingBottom={SMALL_SPACING}>
            <ReactLinkify>
              <Typography align="center">{infoSummary}</Typography>
            </ReactLinkify>
          </Box>
        </Grid>

        <Grid item>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={SMALL_SPACING}
          >
            <Button color="secondary" onClick={this.props.onCancelClicked}>
              {cancelButton}
            </Button>
            <Button
              color="primary"
              onClick={this.props.onNextClicked}
              variant="contained"
            >
              {nextButton}
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

  getTextSummary = () => {
    switch (this.props.type) {
      case "user":
        return "premiumInviteSummary";
      case "agent":
        return "premiumAccessSummary";
      case "contractor":
        return "premiumAccessContractorSummary";
    }
  };

  getTitle = () => {
    switch (this.props.type) {
      case "user":
        return "inviteUserTitle";
      case "agent":
        return "inviteAgentTitle";
      case "contractor":
        return "inviteContractorTitle";
    }
  };
}

export default withTranslation()(InviteWarningPanel);
