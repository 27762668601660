import React from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import RootState from "../../../../redux/RootState";
import { ORGANISATION_PROPERTY_DETAIL_ROUTE } from "../../../../routes/routes";
import { adminLoadOrganisation } from "../../../organisations/details/redux/OrganisationDetailActions";
import { changePageType } from "../../PropertyListPage/redux/PropertyListActions";
import { PropertyPageType } from "../../../../rest/properties/model/Property";

interface URLParams {
  organisationId: string;
}

interface ExternalProps {
  children: React.ReactNode;
}

interface ReduxStateProps {
  isAdeyAdmin: boolean;
  pageType: PropertyPageType | undefined;
  loadedPropertyOrgId: string | undefined;
}

interface ReduxDispatchProps {
  organisationIdLoaded: (orgId: string | undefined) => void;
  setAdminPropPageType: () => void;
}

type Props = RouteComponentProps & ExternalProps & ReduxStateProps & ReduxDispatchProps;

// This component exists for the purpose of listening to how the user has entered the property page.
// If they do it via 'all-organisations/org/property', we need to check if the state related to organisation
// is populated properly. If it is empty, we do all the necessary Redux and API calls (To render correct Navigation Breadcrumps)
class PropertyPageTypeListener extends React.Component<Props> {
  // Keep this callback so we can unregister the listener
  callback: (() => void) | undefined = undefined;

  // On mounting, start listening to route changes.
  componentDidMount() {
    this.handlePathChanged(this.props.location.pathname);
    this.callback = this.props.history.listen((location) =>
      this.handlePathChanged(location.pathname)
    );
  }

  // Stop listening when we unmount
  componentWillUnmount() {
    this.callback?.();
  }

  render() {
    return this.props.children;
  }

  handlePathChanged = (path: string) => {
    if (this.props.isAdeyAdmin) {
      const orgId = matchPath<URLParams>(path, ORGANISATION_PROPERTY_DETAIL_ROUTE)?.params
        .organisationId;

      /* We check for two conditions: 
          1. If orgID is equal to this.props.loadedPropertyOrgId, 
          it means that organisation has been already loaded and we are on the correct page
          2. if orgID is not undefined (if the matchPath function resolved successfully)
      */
      if (orgId !== this.props.loadedPropertyOrgId && orgId !== undefined) {
        this.props.organisationIdLoaded(orgId);
        this.props.setAdminPropPageType();
      }
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isAdeyAdmin: state.adeyAdmin.hasAdminAccess,
    loadedPropertyOrgId: state.propertyList.organisationId,
    pageType: state.propertyList.page,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  setAdminPropPageType: () => dispatch(changePageType("admin-org-detail")),
  organisationIdLoaded: (id) => dispatch(adminLoadOrganisation(id as string)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PropertyPageTypeListener));
