import { createRestError } from "../../../../rest/utils/RestError";

const AdminInviteOrganisationErrorHandler = (e: any): string | undefined => {
  if (e === undefined || !e.isAxiosError) {
    return undefined;
  }

  const restError = createRestError(e);

  const errors = restError.errors || {};

  const includesError = (name: string): boolean => {
    return Object.keys(errors).includes(name);
  };

  // Agent already exists error
  if (includesError("is_agent_for")) {
    return "error.adminCreateOrg422";
  } else {
    return undefined;
  }
};
export default AdminInviteOrganisationErrorHandler;
