import { Box } from "@material-ui/core";
import React from "react";
import { PRIMARY_COLOR } from "../theme/createMaterialTheme";
import CircleImage from "./CircleImage";
import ColoredText from "./ColoredText";

interface Props {
  imageUrl: string | undefined;
  name: string;
  size: "small" | "large" | "xlarge";
  backgroundColor?: string;
  textColor?: string;
}

const CircleAvatar = (props: Props) => {
  const diameter = props.size === "small" ? 20 : props.size === "large" ? 40 : 60;

  if (props.imageUrl?.length) {
    return <CircleImage url={props.imageUrl} diameter={diameter} />;
  } else {
    const characters = props.name.split("");
    const initial = characters.length > 0 ? characters[0] : "";
    const textSize = props.size === "small" ? undefined : "h5";
    const backgroundColor = props.backgroundColor || PRIMARY_COLOR;
    const textColor = props.textColor || "#FFF";

    return (
      <CircleImage url="" diameter={diameter} backgroundColor={backgroundColor}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={diameter}
          width={diameter}
        >
          <ColoredText
            textColor={textColor}
            variant={textSize}
            align="center"
            gutterBottom={false}
          >
            <strong>{initial}</strong>
          </ColoredText>
        </Box>
      </CircleImage>
    );
  }
};

export default CircleAvatar;
