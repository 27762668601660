import { TableCell, Typography } from "@material-ui/core";
import React from "react";
import { CSSProperties } from "react";
import { Trans } from "react-i18next";

interface Props {
  textId?: string;
  style?: CSSProperties;
  align?: "center" | "center" | "right" | "justify";
}

const TableHeaderCell = (props: Props) => {
  return (
    <TableCell style={props.style} align={props.align}>
      <Typography variant="button" color="secondary">
        <Trans>{props.textId}</Trans>
      </Typography>
    </TableCell>
  );
};

export default TableHeaderCell;
