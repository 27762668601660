import { Box, Dialog } from "@material-ui/core";
import React from "react";
import { LARGE_SPACING } from "../../../ui/theme/dimensions";
import CreateOrganisationAdminForm from "./components/CreateOrganisationAdminForm/CreateOrganisationAdminForm";
import CreateOrganisationAdminInviteSent from "./components/CreateOrganisationAdminInviteSent/CreateOrganisationAdminInviteSentPanel";
import InviteOrganisationAdminForm from "./components/InviteOrganisationAdminForm/InviteOrganisationAdminForm";
import OrganisationOwnerAdminForm from "./components/OrganisationOwnerAdminForm/OrganisationOwnerAdminForm";

interface Props {
  open: boolean;
  onClose: () => void;
}

enum PageContent {
  createOrg,
  inviteOrg,
  ownerInfo,
  inviteSent,
}

interface State {
  pageContent: PageContent;
}

/**
 * This component will handle the navigation between each page
 * within this dialog.
 */
class AdminInviteOrganisationDialog extends React.Component<Props, State> {
  state = {
    pageContent: PageContent.createOrg,
  };

  componentDidUpdate(prevProps: Props) {
    // If we open (aren't weren't already open) then go to
    // the first page.
    if (this.props.open && !prevProps.open) {
      this.setState({ pageContent: PageContent.createOrg });
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Box padding={LARGE_SPACING} minWidth={440}>
          {this.renderContent()}
        </Box>
      </Dialog>
    );
  }

  renderContent = (): React.ReactElement => {
    switch (this.state.pageContent) {
      case PageContent.createOrg:
        return (
          <CreateOrganisationAdminForm
            onFormCompleted={this.handleCreateOrgFormSubmitted}
            onInviteClicked={this.handleCreateOrgInviteClicked}
          />
        );

      case PageContent.inviteOrg:
        return (
          <InviteOrganisationAdminForm
            onFormSubmitted={this.handleInviteFormSubmitted}
            onBackPressed={this.handleBackPressed}
          />
        );

      case PageContent.ownerInfo:
        return (
          <OrganisationOwnerAdminForm
            onBackPressed={this.handleBackPressed}
            onSuccess={this.handleInviteFormSubmitted}
          />
        );

      case PageContent.inviteSent:
        return (
          <CreateOrganisationAdminInviteSent
            onReturnClicked={this.handleInviteSentReturnClicked}
          />
        );
    }
  };

  // MARK: Handlers

  handleCreateOrgFormSubmitted = () => {
    this.setState({ pageContent: PageContent.ownerInfo });
  };

  handleCreateOrgInviteClicked = () => {
    this.setState({ pageContent: PageContent.inviteOrg });
  };

  handleOwnerInfoFormSubmitted = () => {
    this.setState({ pageContent: PageContent.inviteSent });
  };

  handleInviteSentReturnClicked = () => {
    this.props.onClose();
  };

  handleBackPressed = () => {
    this.setState({ pageContent: PageContent.createOrg });
  };

  handleInviteFormSubmitted = () => {
    this.setState({ pageContent: PageContent.inviteSent });
  };
}

export default AdminInviteOrganisationDialog;
