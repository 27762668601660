export type DateFormat = "dd-MM-yyyy";

export const formatDate = (date: Date, format: DateFormat): string => {
  switch (format) {
    case "dd-MM-yyyy":
      return formatDateDashedDMY(date);
  }
};

// format a date with dd-MM-yyyy
const formatDateDashedDMY = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // months go 0 - 11.
  const day = date.getDate();

  // enforce 2 chars for months and 2 chars for days.
  // Years before 1000 AD aren't typically written 0456 so
  // they're not padded.
  return `${zeroPad(day, 2)}-${zeroPad(month, 2)}-${year}`;
};

// From here: https://stackoverflow.com/a/2998874/11528182
const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");
