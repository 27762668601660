import { Alert } from "@material-ui/lab";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactLinkify from "react-linkify";
import { SenseProductType } from "../../../../../rest/sense/model/SenseDevice";
import SenseDeviceStatus, {
  DeviceNotificationType,
} from "../../../../../rest/sense/model/SenseDeviceStatus";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import getAlertSeverity from "../helpers/getAlertSeverity";
import getSenseNotification from "../helpers/getSenseNotification";

interface ExternalProps {
  notificationType: DeviceNotificationType;
  notifications: SenseDeviceStatus[];
  productType?: SenseProductType;
  date?: string;
}

type Props = ExternalProps & WithTranslation;

const InfoCardAlert = ({
  notifications,
  notificationType,
  productType,
  date,
  t,
}: Props) => {
  const notification =
    notifications.find((notification) => notification.type === notificationType)
      ?.status || getSenseNotification(notificationType);

  return (
    <Alert
      style={{
        marginTop: SMALL_SPACING,
        marginBottom: SMALL_SPACING,
        whiteSpace: "pre-line",
      }}
      icon={false}
      severity={getAlertSeverity(notification.priority)}
    >
      <ReactLinkify>
        {t(`notificationMessageCode.${notification.code}`, {
          ...(notificationType === "service" && date && { date: date }),
          ...(notification.code === 4001 &&
            productType && {
              device: productType === "PRO3" || productType === "M3WF" ? "Wi-Fi" : "GSM",
              deviceNote:
                productType === "PRO3" || productType === "M3WF"
                  ? t("connectionMessage.WIFI")
                  : t("connectionMessage.GSM"),
            }),
        })}
      </ReactLinkify>
    </Alert>
  );
};

export default withTranslation()(InfoCardAlert);
