import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { LARGE_SPACING, SMALL_SPACING } from "../../theme/dimensions";
import PageHeaderItem from "./components/PageHeaderItem";
import TabModel from "./models/TabModel";

interface ExternalProps extends WithTranslation {
  tabs: TabModel[];
  activeKey: string;
  tabClicked?: (key: string) => void;
  noPadding?: boolean;
}

const PageHeaderTabs = (props: ExternalProps) => {
  //PageHeaderTabs expects Tab Items title to be a 'translate.ts' key
  const tabs = props.tabs.map((m) => (
    <PageHeaderItem
      key={m.navKey}
      navKey={m.navKey}
      activeKey={props.activeKey}
      onTabClick={props.tabClicked}
    >
      <strong>{props.t(m.title)}</strong>
    </PageHeaderItem>
  ));

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="baseline"
      flexGrow={1}
      paddingLeft={props.noPadding ? 0 : LARGE_SPACING}
      paddingTop={SMALL_SPACING}
      paddingBottom={SMALL_SPACING}
    >
      {tabs}
    </Box>
  );
};

export default withTranslation()(PageHeaderTabs);
