import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControlLabel, IconButton, Switch, Tooltip } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DEFAULT_SPACING } from "../../../../../ui/theme/dimensions";
import EditableFieldLabel from "../../../../properties/PropertyDetailPage/components/EditableField/components/EditableFieldLabel";

interface ExternalProps {
  agentExists?: boolean;
  isAgent: boolean;
  switchAgentState: (isAgent: boolean) => void;
}

type Props = ExternalProps & WithTranslation;

const AgentSwitch = (props: Props) => {
  //Labels
  const agentTitleLabel = props.t("agentSwitch.labelTitle");
  const agentSwitchLabel = props.t("agentSwitch.switchTitle");
  const agentHintLabel = props.t("agentSwitch.agentExistsHint");

  //Handlers
  const handleEnableAgentFeatures = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.switchAgentState(e.target.checked);
  };

  return (
    <Box display="flex" flexDirection="column">
      <EditableFieldLabel label={agentTitleLabel} />
      <Box display="flex" flexDirection="row">
        <FormControlLabel
          style={{ marginLeft: DEFAULT_SPACING }}
          control={
            <Switch
              disabled={props.agentExists || props.isAgent}
              onChange={handleEnableAgentFeatures}
              color="primary"
            />
          }
          label={agentSwitchLabel}
          checked={props.isAgent}
          color="primary"
        />
        {props.agentExists ? (
          <Tooltip interactive title={agentHintLabel}>
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} size="xs" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};

export default withTranslation()(AgentSwitch);
