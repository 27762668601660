import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface ExternalProps {
  open: boolean;
  organisationName: string;

  returnToListClicked: () => void;
}

type Props = ExternalProps & WithT;

const StopContractingCompletedDialog = (props: Props) => {
  const title = props.t("stopContractingCompletedDialog.title");
  const bodyPlaceholder = props.t("stopContractingCompletedDialog.bodyPlaceholder");
  const returnToListButton = props.t("stopContractingCompletedDialog.returnToListButton");

  const body = bodyPlaceholder.replace("%s", props.organisationName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={returnToListButton}
      onConfirmed={props.returnToListClicked}
      onCancelClicked={() => {}}
      removeCancelButton
    />
  );
};

export default withTranslation()(StopContractingCompletedDialog);
