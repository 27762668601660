import { useEffect, useState } from "react";
import usePrev from "../../../../utils/hooks/usePrev";
import SystemMessage from "../model/SystemMessage";

interface Props {
  messages: SystemMessage[];
  systemDown: boolean;
}

const useSystemMessages = (props: Props) => {
  //States
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hideMessageIndexes, setHideMessageIndexes] = useState<number[]>([]);

  //Prev Open
  const prevOpen = usePrev(props.messages.length > 0 || props.systemDown);

  //Effects
  useEffect(() => {
    if (props.messages.length > 0) {
      setCurrentPageIndex(0);
    }
  }, [props.messages]);

  useEffect(() => {
    if (props.messages.length > 0 || props.systemDown) {
      setIsOpen(true);
      setHideMessageIndexes([]);
    }
  }, [prevOpen, props.messages.length, props.systemDown]);

  //Handlers
  const prevPageClicked = () => {
    if (currentPageIndex !== undefined && currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const nextPageClicked = () => {
    if (currentPageIndex !== undefined && currentPageIndex < props.messages.length) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const hideCurrentMessage = (hide: boolean) => {
    let updatedHideMessageIndexes: number[] = [...hideMessageIndexes];
    if (hide) {
      updatedHideMessageIndexes = updatedHideMessageIndexes.filter(
        (v) => v !== currentPageIndex
      );
    } else {
      updatedHideMessageIndexes.push(currentPageIndex);
    }
    setHideMessageIndexes(updatedHideMessageIndexes);
  };

  const onClose = () => {
    if (!props.systemDown) {
      setIsOpen(false);
      let messages: SystemMessage[] = JSON.parse(
        localStorage.getItem("systemMessages") || "[]"
      );
      hideMessageIndexes.forEach((i) => {
        messages.push(props.messages[i]);
      });
      localStorage.setItem("systemMessages", JSON.stringify(messages));
    }
  };

  //Dialog Open
  const isDialogOpen = isOpen && !!prevOpen;

  const isLastItem = props.systemDown || currentPageIndex + 1 === props.messages.length;

  return {
    currentPageIndex,
    isDialogOpen,
    isLastItem,
    nextPageClicked,
    prevPageClicked,
    hideCurrentMessage,
    onClose,
    hideMessageIndexes,
  };
};

export default useSystemMessages;
