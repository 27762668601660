import proClubErrorHandler from "../../../../errors/messages/proClubErrorHandler";
import ProClubRepository from "../../../../rest/proclub/ProClubRepository";

const isProCheckUser = async (
  proClubService: ProClubRepository,
  email: string,
  password: string
): Promise<boolean> => {
  try {
    return await proClubService.checkProclubUser(email, password);
  } catch (e) {
    const message = proClubErrorHandler(e);
    if (message !== undefined) {
      return true;
    } else {
      return false;
    }
  }
};

export default isProCheckUser;
