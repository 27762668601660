import { Box, Divider } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import parseSenseProduct from "../../../../../rest/sense/helpers/parseSenseProduct";
import SenseDevice from "../../../../../rest/sense/model/SenseDevice";
import ColoredText from "../../../../../ui/components/ColoredText";
import LabelledText from "../../../../../ui/components/LabelledText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import {
  LARGE_SPACING,
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
} from "../../../../../ui/theme/dimensions";
import { formatDate } from "../../../../../utils/formatDate";
import formatDayTime from "../../../../../utils/formatDayTime";

interface ExternalProps {
  device: SenseDevice;
}

type Props = ExternalProps & WithTranslation;

//TODO: Replace placeholders once API is updated
const SenseDeviceDetailsTab = (props: Props) => {
  //Translated Strings
  const productTypeLabel = props.t("senseDeviceDetailsTab.productType");
  const serialNumberLabel = props.t("senseDeviceDetailsTab.serialNumber");
  const identifierLabel = props.t("senseDeviceDetailsTab.identifier");
  const firmwareVersionLabel = props.t("senseDeviceDetailsTab.firmwareVersion");

  const serviceInfoSubtitle = props.t("senseDeviceDetailsTab.serviceInfo");

  const organisationLabel = props.t("senseDeviceDetailsTab.organisation");
  const heatingEngineerLabel = props.t("senseDeviceDetailsTab.heatingEngineer");
  const deviceInstalledLabel = props.t("senseDeviceDetailsTab.deviceInstalled");
  const serviceDueLabel = props.t("senseDeviceDetailsTab.serviceDue");

  const lastReadingLabel = props.t("senseDeviceDetailsTab.lastReading");
  const lastContactLabel = props.t("senseDeviceDetailsTab.lastContact");
  const lastCleanedLabel = props.t("senseDeviceDetailsTab.lastCleaned");

  //Dates
  const deviceInstalledDate = formatDayTime(props.device.installed_date as string);
  const serviceDueDate = formatDate(
    new Date(props.device.service_due_date),
    "dd-MM-yyyy"
  );

  const lastReadingDate = formatDayTime(props.device.last_reading_date as string);
  const lastCleanedDate = formatDayTime(props.device.last_cleaned_date as string);
  const lastContactDate = formatDayTime(props.device.last_contact_date as string);
  //Product type
  const productType = parseSenseProduct(props.device.serial_number);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="top"
      justifyContent="flex-start"
      maxWidth="100%"
      paddingX={EXTRA_LARGE_SPACING}
      paddingBottom={DEFAULT_SPACING}
    >
      <Box display="flex" flexDirection="row" width="100%">
        <Box display="flex" flexDirection="column" width="50%" minWidth="150px">
          <LabelledText
            label={productTypeLabel}
            text={
              productType ? props.t(`senseDeviceNames.${productType}`) : "Unknown Product"
            }
            gutterBottom
          />

          <LabelledText
            label={serialNumberLabel}
            text={props.device.serial_number || "-"}
            gutterBottom
          />
        </Box>
        <Box display="flex" flexDirection="column" width="50%" minWidth="150px">
          <LabelledText
            label={identifierLabel}
            text={props.device.identifier || "-"}
            gutterBottom
          />
          <LabelledText
            label={firmwareVersionLabel}
            text={props.device.firmware_version || "-"}
            gutterBottom
          />
        </Box>
      </Box>
      <Divider
        style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: EXTRA_LARGE_SPACING }}
      />
      <ColoredText
        textColor={BACKGROUND_ACCENT_COLOR}
        variant="button"
        gutterBottom
        style={{ fontSize: "16px", marginBottom: LARGE_SPACING }}
      >
        <strong>{serviceInfoSubtitle}</strong>
      </ColoredText>
      <Box display="flex" flexDirection="row" width="100%">
        <Box display="flex" flexDirection="column" width="50%" minWidth="150px">
          <LabelledText
            label={organisationLabel}
            text={props.device.organisation?.name || "ORG_PLACEHOLDER"}
            gutterBottom
          />

          <LabelledText
            label={heatingEngineerLabel}
            text={`${props.device.user.first_name} ${props.device.user.last_name}`}
            gutterBottom
          />

          <LabelledText
            label={deviceInstalledLabel}
            text={`${deviceInstalledDate.date} ${deviceInstalledDate.time}`}
            gutterBottom
          />

          <LabelledText label={serviceDueLabel} text={serviceDueDate} gutterBottom />
        </Box>
        <Box display="flex" flexDirection="column" width="50%" minWidth="150px">
          <LabelledText
            label={lastReadingLabel}
            text={
              props.device.last_reading_date
                ? `${lastReadingDate.date} ${lastReadingDate.time}`
                : "-"
            }
            gutterBottom
          />
          <LabelledText
            label={lastCleanedLabel}
            text={
              props.device.last_cleaned_date
                ? `${lastCleanedDate.date} ${lastCleanedDate.time}`
                : "-"
            }
            gutterBottom
          />
          <LabelledText
            label={lastContactLabel}
            text={
              props.device.last_contact_date
                ? `${lastContactDate.date} ${lastContactDate.time}`
                : "-"
            }
            gutterBottom
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(SenseDeviceDetailsTab);
