import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminInitDestinationSearch,
  adminResetMoveState,
  initAdminMoveEntities,
} from "../../../../admin/AdminMoveEntity/redux/AdminMoveEntityAction";
import RootState from "../../../../../redux/RootState";
import TSelectedMoveTab from "../../../../admin/AdminMoveEntity/types/TMoveTab";
import { buildPropertyDetailRoute } from "../../../../../routes/routes";
import { useHistory } from "react-router";
import { loadProperty } from "../../../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import { loadSenseDevices } from "../../../../devices/redux/SenseDevicesAction";
import { adminLoadOrganisation } from "../../../../organisations/details/redux/OrganisationDetailActions";
import { changePageType } from "../../../../properties/PropertyListPage/redux/PropertyListActions";

const tabList: TSelectedMoveTab[] = [
  "destination",
  "confirmation",
  "warning",
  "success",
  "failure",
];

const useMoveReports = () => {
  //Hooks
  const history = useHistory();

  //Selectors
  const destinations = useSelector(
    (state: RootState) => state.adminMoveEntity.destinations
  );

  const loading = useSelector(
    (state: RootState) =>
      state.adminMoveEntity.searchingDestination || state.adminMoveEntity.movingEntity
  );

  const moveResults = useSelector(
    (state: RootState) => state.adminMoveEntity.moveResults
  );

  const moved = moveResults && moveResults.movedEntities.length > 0;

  //State
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [destinationId, setSelectedDestinationId] = useState<string>();
  const [searchString, setSearchString] = useState<string>("");
  const [curretTab, setCurrentTab] = useState(0);
  const [redirectOrg, setRedirectOrg] = useState<string>();

  //Hooks
  const dispatch = useDispatch();

  //Effects
  useEffect(() => {
    if (moveResults && destinationId && redirectOrg) {
      if (moved) {
        setCurrentTab(3);
        window.history.pushState(
          {},
          "",
          buildPropertyDetailRoute(redirectOrg, destinationId)
        );
      } else {
        setCurrentTab(4);
      }
    }
  }, [moved, destinationId, redirectOrg, moveResults]);

  //Handlers
  const handleMoveDialogOpen = () => {
    if (activeTab === "success") {
      return;
    }
    if (!moveDialogOpen) {
      setSelectedDestinationId(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
    setMoveDialogOpen((state) => !state);
  };

  const moveSelectedReports = (
    ids: string[],
    orgOriginId: string,
    propertyOriginId: string
  ) => {
    if (destinationId) {
      setRedirectOrg(orgOriginId);
      dispatch(
        initAdminMoveEntities(
          "procheck",
          ids,
          destinationId,
          orgOriginId,
          propertyOriginId
        )
      );
    }
  };

  const handleSetSelectedDestination = (destinationId: string) => {
    setSelectedDestinationId(destinationId);
  };

  const handleInitSearch = (orgId?: string) => {
    dispatch(
      adminInitDestinationSearch(searchString.trim().toLowerCase(), "device", orgId)
    );
  };

  const handleSetSearchString = (search: string) => {
    setSearchString(search);
  };

  const handleBackPressed = () => {
    setCurrentTab(curretTab - 1);
  };

  const handleNextPressed = () => {
    setCurrentTab(curretTab + 1);
  };

  const handleSuccessPressed = () => {
    setMoveDialogOpen(false);
    if (redirectOrg && destinationId) {
      dispatch(changePageType("admin-org-detail"));
      dispatch(adminLoadOrganisation(redirectOrg));
      dispatch(loadProperty(redirectOrg, destinationId));
      dispatch(loadSenseDevices(redirectOrg, destinationId));
      history.replace(buildPropertyDetailRoute(redirectOrg, destinationId));
      setSelectedDestinationId(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
  };

  const activeTab = tabList[curretTab];

  return {
    moveSelectedReports,
    moveDialogOpen,
    moveResults,
    searchString,
    destinationId,
    destinations,
    activeTab,
    loading,
    handleMoveDialogOpen,
    handleInitSearch,
    handleSetSelectedDestination,
    handleSetSearchString,
    handleBackPressed,
    handleNextPressed,
    handleSuccessPressed,
  };
};

export default useMoveReports;
