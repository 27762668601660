import React from "react";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import { Box, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { WithT } from "i18next";

const placeholderStyle: React.CSSProperties = {
  display: "flex",
  width: "1200px",
  height: "500px",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};

const iconStyle: React.CSSProperties = {
  fontSize: 100,
};

const MapPlaceholder = (props: WithT) => {
  const title = props.t("ProCheckMaps.locationMissing");

  return (
    <Box style={placeholderStyle}>
      <Typography variant="h2" align="center">
        <strong>{title.toUpperCase()}</strong>
      </Typography>
      <LocationOffIcon color="disabled" style={iconStyle} />
    </Box>
  );
};

export default withTranslation()(MapPlaceholder);
