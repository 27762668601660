import AdminRepository from "../../../../rest/admin/AdminRepository";
import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import Property from "../../../../rest/properties/model/Property";
import PropertiesRepository from "../../../../rest/properties/PropertiesRepository";

export interface PropertyDetails {
  property: Property;
  organisation: Organisation;
  contractors: Organisation[];
}

/**
 * Handles communication with the repository layer for
 * the property detail sagas.
 * This uses either the normal set of repositories,
 * or the admin ones.
 */
class PropertyDetailService {
  constructor(
    private propertyRepo: PropertiesRepository,
    private orgRepo: OrganisationRepository,
    private contractorRepo: OrganisationContractorRepository,
    private adminRepo: AdminRepository
  ) {}

  /**
   * Loads the initial data for the property list.
   * @returns PropertyDetails
   */
  loadProperty = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string,
    canViewContractors: boolean
  ): Promise<PropertyDetails> => {
    // Await both futures at once, so that we don't have to wait for one
    // API call to finish before making the next
    const [property, organisation, contractors] = await Promise.all([
      this._getProperty(isAdeyAdmin, organisationId, propertyId),
      this._getOrg(isAdeyAdmin, organisationId),
      this._getContractors(isAdeyAdmin, canViewContractors, organisationId),
    ]);

    return {
      property: property,
      organisation: organisation,
      contractors: contractors,
    };
  };

  /**
   * Updates the property details
   * @returns The updated property
   */
  updateProperty = async (
    isAdeyAdmin: boolean,
    orgId: string,
    property: Property
  ): Promise<Property> => {
    const repo = isAdeyAdmin ? this.adminRepo : this.propertyRepo;
    return repo.updateProperty(orgId, property);
  };

  /**
   * Updates the contractor details for a property.
   * If newContractorId is undefined, it'll remove
   * the contractor all together
   * @returns The property with an updated contractor.
   */
  updateContractor = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    property: Property,
    newContractorId: string | undefined
  ): Promise<Property> => {
    // If unchanged, don't do anything.
    if (property.contractor?.id === newContractorId) {
      return property;
    }

    if (newContractorId && newContractorId !== property.contractor?.id) {
      // If the contractor id is defined, and changed, then set
      // the new contractor.
      const newContractor = await this._updateContractor(
        isAdeyAdmin,
        organisationId,
        property.id,
        newContractorId
      );
      return {
        ...property,
        contractor: newContractor,
      };
    } else if (newContractorId === undefined && property.contractor) {
      // If the new contractor id is undefined, but the property
      // has a contractor, then remove it
      await this._removeContractor(
        isAdeyAdmin,
        organisationId,
        property.id,
        property.contractor.id
      );
      return {
        ...property,
        contractor: undefined,
      };
    } else {
      // If nothing has changed, don't do anything.
      return property;
    }
  };

  // Private methods

  private _getOrg = (isAdeyAdmin: boolean, orgId: string): Promise<Organisation> => {
    const repo = isAdeyAdmin ? this.adminRepo : this.orgRepo;
    return repo.getOrganisation(orgId);
  };

  private _getProperty = (
    isAdeyAdmin: boolean,
    orgId: string,
    propertyId: string
  ): Promise<Property> => {
    const repo = isAdeyAdmin ? this.adminRepo : this.propertyRepo;
    return repo.getProperty(orgId, propertyId, true);
  };

  private _getContractors = async (
    isAdeyAdmin: boolean,
    canViewContractors: boolean,
    orgId: string
  ) => {
    if (!canViewContractors) {
      return [];
    }
    const repo = isAdeyAdmin ? this.adminRepo : this.contractorRepo;
    return repo.fetchOrganisationContractorList(orgId, undefined);
  };

  private _updateContractor = (
    isAdeyAdmin: boolean,
    orgId: string,
    propertyId: string,
    newContractorId: string
  ): Promise<Organisation> => {
    const repo = isAdeyAdmin ? this.adminRepo : this.propertyRepo;
    return repo.updateContractor(orgId, propertyId, newContractorId);
  };

  private _removeContractor = (
    isAdeyAdmin: boolean,
    orgId: string,
    propertyId: string,
    currentContractorId: string
  ): Promise<Organisation> => {
    const repo = isAdeyAdmin ? this.adminRepo : this.propertyRepo;
    return repo.removeContractor(orgId, propertyId, currentContractorId);
  };
}

export default PropertyDetailService;
