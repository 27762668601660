import { getContext } from "redux-saga/effects";
import SagaContext from "../SagaContext";

// Gets an item from getContext but doesn't allow typos.
const getDependency = (key: keyof SagaContext) => {
  const context = getContext(key);
  if (context === undefined) {
    throw new Error("No dependency with key: " + key);
  }
  return context;
};

export default getDependency;
