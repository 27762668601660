import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { TestOutcome } from "../../../../rest/procheck/model/ProCheckResultDetails";
import ColoredText from "../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { BORDER_RADIUS, EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";
import { ProCheckDetailsTestResult } from "../model/ProCheckDetailsPageState";
import TestResultIcon from "./utils/TestResultIcon";
import getCombinedTestOutcome from "../utils/getCombinedTestOutcome";

interface Props extends WithT {
  results?: {
    pH1: ProCheckDetailsTestResult;
    molybdate: ProCheckDetailsTestResult;
    iron: ProCheckDetailsTestResult;
    copper?: ProCheckDetailsTestResult;
  };
  pass?: boolean;
  waterTestStatus: TestOutcome;
}

const ProCheckResultTable = (props: Props) => {
  //Styled components
  const StyledTable = withStyles({
    root: {
      borderCollapse: "separate",
      borderSpacing: "0 8px",
      width: "100%",
    },
  })(Table);

  const HeaderCell = (props: { textId?: string; style?: React.CSSProperties }) => {
    const StyledHeaderCell = withStyles({
      root: {
        border: "none",
        paddingBottom: "0",
      },
    })(TableCell);

    return (
      <StyledHeaderCell align="left" padding="none" style={props.style}>
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
          <strong>
            <Trans>{props.textId}</Trans>
          </strong>
        </ColoredText>
      </StyledHeaderCell>
    );
  };

  const StyledRowCell = withStyles({
    root: {
      border: "solid 1px #0000001F",
      borderStyle: "solid none",
      padding: "8px",
      "&:first-child": {
        borderLeftStyle: "solid",
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,
      },
      "&:last-child": {
        borderRightStyle: "solid",
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      },
    },
  })(TableCell);

  //Labels
  const phLevelLabel = props.t("proCheckResultTable.phLevel");
  const corrosionLevelLabel = props.t("proCheckResultTable.corrosionLevel");
  const inhibitorLevelLabel = props.t("proCheckResultTable.inhibitorLevel");
  const turbidityLabel = props.t("ProCheckResultsTab.waterTest");

  const overallLevelLabel = props.t("proCheckResultTable.overallLevel");

  const recommendationLabel = props.t("proCheckResultTable.recommendation");
  const passLabel = props.t("proCheckResultTable.pass");

  //Header labels are keys instead of translated string because this is what component expects
  const headerTypeLabel = "proCheckResultTable.typeHeader";
  const headerResultsLabel = "proCheckResultTable.resultsHeader";

  //Builders
  const buildResultRow = (label: string, result: TestOutcome) => {
    return (
      <TableRow>
        <StyledRowCell>{label}</StyledRowCell>

        <StyledRowCell align="center">
          <TestResultIcon result={result} />
        </StyledRowCell>
      </TableRow>
    );
  };

  const buildTotalResult = (): TestOutcome => {
    let result: TestOutcome = "pass";
    if (!props.pass) {
      return "fail";
    }

    return result;
  };

  const buildResultLabel = (result: TestOutcome): string => {
    switch (result) {
      case "fail":
        return recommendationLabel;
      case "out of range":
        return recommendationLabel;
      case "pass":
        return passLabel;
    }
  };

  const buildNote = () => {
    let occuringResults: TestOutcome[] = [];
    if (props.results) {
      for (const value of Object.values(props.results)) {
        if (!occuringResults.includes(value.outcome)) {
          occuringResults.push(value.outcome);
        }
      }
    } else {
      occuringResults.push("fail");
    }

    const resultItems = occuringResults.map((result, index) => (
      <Box
        height={EXTRA_LARGE_SPACING * 2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box paddingRight={1}>
          <TestResultIcon result={result} />
        </Box>
        <Typography>{`= ${buildResultLabel(result)}`}</Typography>
        {index === occuringResults.length - 1 || (
          <Box display="flex" paddingX={1} height="80%">
            <Divider orientation="vertical" />
          </Box>
        )}
      </Box>
    ));

    return (
      <Box display="flex" flexDirection="row">
        {resultItems}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <StyledTable>
        <TableHead>
          <TableRow>
            <HeaderCell textId={headerTypeLabel} />
            <HeaderCell
              style={{
                paddingLeft: 10,
                width: 30,
                paddingRight: 10,
              }}
              textId={headerResultsLabel}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.results && (
            <>
              {buildResultRow(inhibitorLevelLabel, props.results.molybdate.outcome)}
              {buildResultRow(
                corrosionLevelLabel,
                props.results.copper !== undefined
                  ? getCombinedTestOutcome([
                      props.results.iron.outcome,
                      props.results.copper.outcome,
                    ])
                  : props.results.iron.outcome
              )}
              {buildResultRow(phLevelLabel, props.results.pH1.outcome)}
            </>
          )}
          {buildResultRow(turbidityLabel, props.waterTestStatus)}
          {buildResultRow(overallLevelLabel, buildTotalResult())}
        </TableBody>
      </StyledTable>

      <Grid container>{buildNote()}</Grid>
    </Box>
  );
};

export default withTranslation()(ProCheckResultTable);
