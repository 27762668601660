import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import ArrowIcon from "../../../../../ui/assets/ArrowIcon/ArrowIcon";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import PrimaryTextField, {
  FieldState,
} from "../../../../../ui/components/PrimaryTextField";
import SecondaryButton from "../../../../../ui/components/SecondaryButton";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import { submitOwnerInfo } from "../../redux/AdminCreateOrganisationAction";
import validateOrganisationOwnerAdminForm from "./validateOrganisationOwnerAdminForm";

interface ExternalProps {
  onSuccess: () => void;
  onBackPressed: () => void;
}

interface ReduxStateProps {
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
}

interface ReduxDispatchProps {
  submitOwnerInfo: (email: string) => void;
}

type OrganisationOwnerAdminFormProps = ExternalProps &
  WithT &
  ReduxDispatchProps &
  ReduxStateProps;

export interface OrganisationOwnerAdminFormState {
  emailAddress: FieldState;
  confirmEmailAddress: FieldState;
}

class OrganisationOwnerAdminForm extends React.Component<
  OrganisationOwnerAdminFormProps,
  OrganisationOwnerAdminFormState
> {
  state = {
    emailAddress: { value: "" },
    confirmEmailAddress: { value: "" },
  };

  componentDidUpdate(prevProps: OrganisationOwnerAdminFormProps) {
    if (this.props.success && !prevProps.success) {
      this.props.onSuccess();
    }
  }

  render() {
    const title = this.props.t("organisationOwnerAdminForm.title");
    const emailHint = this.props.t("organisationOwnerAdminForm.emailHint");
    const confirmEmailHint = this.props.t("organisationOwnerAdminForm.confirmEmailHint");
    const finishButton = this.props.t("organisationOwnerAdminForm.finishButton");
    const backButton = this.props.t("organisationOwnerAdminForm.backButton");

    return (
      <Grid container direction="column" spacing={SMALL_SPACING}>
        <Grid item>
          <Typography variant="h2" align="center">
            <strong>{title.toUpperCase()}</strong>
          </Typography>
        </Grid>

        <Grid item />

        <Grid item>
          <PrimaryTextField
            type="email"
            {...this.state.emailAddress}
            hint={emailHint}
            onChange={this.handleEmailChanged}
            disabled={this.props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item>
          <PrimaryTextField
            type="email"
            {...this.state.confirmEmailAddress}
            hint={confirmEmailHint}
            onChange={this.handleConfirmEmailChanged}
            disabled={this.props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item />

        <Grid item>
          <PrimaryButton
            endIcon={<ArrowIcon />}
            label={finishButton}
            onClick={this.handleFinishClicked}
            isLoading={this.props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item>
          <Box display="flex" justifyContent="center">
            <SecondaryButton label={backButton} onClick={this.props.onBackPressed} />
          </Box>
        </Grid>

        <ErrorSnackbarHandler message={this.props.error} />
      </Grid>
    );
  }

  // MARK: Handlers

  handleEmailChanged = (value: string) => {
    this.setState({ emailAddress: { value: value } });
  };

  handleConfirmEmailChanged = (value: string) => {
    this.setState({ confirmEmailAddress: { value: value } });
  };

  handleFinishClicked = () => {
    const result = validateOrganisationOwnerAdminForm(this.props.t, this.state);
    if (result.isValid) {
      this.props.submitOwnerInfo(this.state.emailAddress.value);
    } else {
      this.setState(result.newState);
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  isLoading: state.adminCreateOrganisation.isLoading,
  error: state.adminCreateOrganisation.error,
  success: state.adminCreateOrganisation.createdOrganisation !== undefined,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  submitOwnerInfo: (email) => dispatch(submitOwnerInfo(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OrganisationOwnerAdminForm));
