import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  org: Organisation;

  onOkClicked: () => void;
}

const ContractorRemovedDialog = (props: Props) => {
  const title = props.t("contractorRemovedDialog.title");
  const bodyPlaceholder = props.t("contractorRemovedDialog.bodyPlaceholder");
  const confirmButton = props.t("contractorRemovedDialog.confirmButton");

  const body = bodyPlaceholder
    .replace("<contractor_name>", props.org.name)
    .replace("<org_name>", props.organisationName);

  return (
    <Dialog open={props.open}>
      <Box padding={SMALL_SPACING}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>{body}</Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" onClick={props.onOkClicked}>
            {confirmButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(ContractorRemovedDialog);
