import UserListAction from "./UserListAction";
import UserListState from "./UserListState";

const initialState: UserListState = {
  users: [],
  invites: [],
  isLoading: true,
  error: undefined,
  searchText: "",
  removingUser: false,
  removingUserError: undefined,
};

const userListReducer = (state = initialState, action: UserListAction): UserListState => {
  switch (action.type) {
    case "LOAD_USERS_TYPE":
      return {
        users: [],
        invites: [],
        isLoading: true,
        error: undefined,
        searchText: state.searchText,
        removingUser: false,
        removingUserError: undefined,
      };

    case "LOAD_USERS_SUCCESS_TYPE":
      return {
        users: action.users,
        invites: action.invites,
        isLoading: false,
        error: undefined,
        searchText: state.searchText,
        removingUser: false,
        removingUserError: undefined,
      };

    case "LOAD_USERS_ERROR_TYPE":
      return {
        users: [],
        invites: [],
        isLoading: false,
        error: action.error,
        searchText: state.searchText,
        removingUser: false,
        removingUserError: undefined,
      };

    case "USER_SEARCH_TEXT_CHANGED_TYPE":
      return {
        ...state,
        searchText: action.searchText,
      };

    default:
      return state;
  }
};

export default userListReducer;
