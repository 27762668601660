import { Box, Button } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import Organisation from "../../../../rest/organisation/model/Organisation";
import {
  ORGANISATION_LIST_ROUTE,
  PLATFORM_ORGANISATIONS_LIST_ROUTE,
} from "../../../../routes/routes";
import NavigationRail from "../../../../ui/components/NavigationRail";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";
import { OrganisationRelationship } from "../redux/OrganisationDetailState";

interface ExternalProps {
  organisation: Organisation;
  navigateToRoute: (route: string) => void;
  endComponent?: React.ReactElement;
  relationship: OrganisationRelationship | undefined;
  isAdmin?: boolean;
  exportProperties?: (org_id: string) => void;
}

type Props = ExternalProps & WithT & RouteComponentProps;

const OrganisationDetailNavRail = (props: Props) => {
  const buttonLabel = props.t("buttons.export");

  const org = props.organisation;
  if (!org || (!props.relationship && !props.isAdmin)) {
    return null;
  }

  const handleRailLinkClicked = () => {
    if (props.isAdmin) {
      props.navigateToRoute(PLATFORM_ORGANISATIONS_LIST_ROUTE);
    } else {
      props.navigateToRoute(ORGANISATION_LIST_ROUTE);
    }
  };

  const name = props.organisation.name;
  const links = [
    {
      key: "org-list",
      name: props.t("headerTabs.organisations"),
      onClick: handleRailLinkClicked,
    },
  ];

  return (
    <Box
      paddingX={DEFAULT_SPACING}
      paddingTop={DEFAULT_SPACING}
      display="flex"
      justifyContent="space-between"
    >
      <NavigationRail links={links} currentPageTitle={name} />
      {props.endComponent}
      {props.exportProperties !== undefined ? (
        <Button
          onClick={() =>
            props.exportProperties !== undefined
              ? props.exportProperties(props.organisation.id)
              : null
          }
          variant="contained"
          color="secondary"
          startIcon={<Description />}
        >
          {buttonLabel}
        </Button>
      ) : undefined}
    </Box>
  );
};

export default withRouter(withTranslation()(OrganisationDetailNavRail));
