import { delay, put, select, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Page from "../../../../rest/utils/Page";
import {
  AdminAccessChangedAction,
  ADMIN_ACCESS_CHANGED_TYPE,
} from "../../redux/AdeyAdminAction";
import {
  loadPlatformOrganisations,
  LOAD_PLATFORM_ORGANISATIONS_TYPE,
  ORG_SEARCH_TEXT_CHANGED_TYPE,
  platformOrganisationsError,
  platformOrganisationsLoaded,
} from "./PlatformOrganisationListAction";
import { CREATE_ORG_SUCCESS_TYPE } from "../../AdminInviteOrganisationDialog/redux/AdminCreateOrganisationAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* platformOrganisationListSagas() {
  yield takeLatest(ADMIN_ACCESS_CHANGED_TYPE, adminAccessChangedSaga);

  // Load the current page if...
  yield takeLatest(
    [
      LOAD_PLATFORM_ORGANISATIONS_TYPE, // the page loads
      CREATE_ORG_SUCCESS_TYPE, // a new org is created by adey
    ],
    loadPlatformOrganisationsSaga
  );
  yield takeLatest(ORG_SEARCH_TEXT_CHANGED_TYPE, searchTextChangedSaga);
}

function* adminAccessChangedSaga(action: AdminAccessChangedAction) {
  if (action.hasAdminAccess) {
    yield put(loadPlatformOrganisations(1));
  }
}

function* searchTextChangedSaga() {
  // Wait a short delay before loading so that we don't
  // search for every keystroke
  yield delay(150);
  yield loadPlatformOrganisationsSaga();
}

function* loadPlatformOrganisationsSaga() {
  try {
    const rootState: RootState = yield select();
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const page = rootState.platformOrganisationList.currentPage;
    const searchText = rootState.platformOrganisationList.searchText;

    const orgResponse: Page<Organisation[]> = yield adminRepo.fetchAllOrganisations(
      searchText,
      page
    );

    const organisations = orgResponse.data;
    const currentPage = orgResponse.meta.current_page;
    const maxPage = orgResponse.meta.last_page;

    yield put(platformOrganisationsLoaded(organisations, currentPage, maxPage));
  } catch (e) {
    const message = getErrorMessage(e, "platformOrganisationsList.loadError");
    yield put(platformOrganisationsError(message));
  }
}

export default platformOrganisationListSagas;
