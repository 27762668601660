import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@material-ui/lab";

import { Box, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  BACKGROUND_ACCENT_COLOR,
  BORDER_COLOR,
  RAG_AMBER,
  RAG_GREEN,
  RAG_RED,
  SECONDARY_TEXT_COLOR_DARK,
} from "../theme/createMaterialTheme";
import { LARGE_SPACING, SMALL_SPACING } from "../theme/dimensions";
import ColoredText from "./ColoredText";
import getAlertSeverity from "../../pages/devices/SenseDeviceDetailsPage/components/helpers/getAlertSeverity";

interface ExternalProps {
  batteryStatus: number;
  displayMode?: boolean;
  hidden?: boolean;
  width?: number;
  height?: number;
}

type Props = ExternalProps & WithTranslation;

const BatteryPill = (props: Props) => {
  //Default props
  props = { width: 52, height: 14, ...props };

  //Strings
  const batteryLevelLabel = props.t("senseDeviceBatteryIndicator.title");

  //Helpers
  const getInfoText = (): string => {
    if (props.batteryStatus === 1) {
      return props.t("senseDeviceBatteryIndicator.low");
    } else if (props.batteryStatus === 2) {
      return props.t("senseDeviceBatteryIndicator.medium");
    } else if (props.batteryStatus === 3) {
      return props.t("senseDeviceBatteryIndicator.good");
    } else {
      return props.t("senseDeviceBatteryIndicator.noSignal");
    }
  };

  const getColorForBatteryStatus = () => {
    if (props.batteryStatus === 1) {
      return RAG_RED;
    } else if (props.batteryStatus === 2) {
      return RAG_AMBER;
    } else if (props.batteryStatus === 3) {
      return RAG_GREEN;
    } else {
      return SECONDARY_TEXT_COLOR_DARK;
    }
  };

  const getPillWidth = () => {
    if (props.batteryStatus === 1) {
      return "40%";
    } else if (props.batteryStatus === 2) {
      return "70%";
    } else if (props.batteryStatus === 3) {
      return "100%";
    } else {
      return "100%";
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={!props.displayMode ? "auto" : "100%"}
    >
      <Box
        display="flex"
        width={!props.displayMode ? "auto" : "100%"}
        flexDirection={!props.displayMode ? "column" : "row"}
        justifyContent={!props.displayMode ? "flex-start" : "space-between"}
      >
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
          <strong>{batteryLevelLabel}</strong>
        </ColoredText>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {!props.displayMode && (
            <Tooltip interactive title={getInfoText()}>
              <IconButton style={{ padding: "4px", marginRight: "2px" }}>
                <FontAwesomeIcon
                  color={getColorForBatteryStatus()}
                  icon={faInfoCircle}
                  size="xs"
                />
              </IconButton>
            </Tooltip>
          )}
          <Box
            display="flex"
            flexDirection="row"
            width={props.width}
            borderRadius={30}
            padding="2px"
            height={props.height}
            border={"1px solid " + BORDER_COLOR}
            marginRight={SMALL_SPACING / 2}
          >
            <Box
              maxWidth="100%"
              width={props.hidden ? "100%" : getPillWidth()}
              borderRadius={30}
              bgcolor={getColorForBatteryStatus()}
            />
          </Box>
        </Box>
      </Box>
      {props.displayMode && (
        <Alert
          style={{ marginTop: LARGE_SPACING, marginBottom: SMALL_SPACING }}
          icon={false}
          severity={getAlertSeverity(props.batteryStatus)}
        >
          {getInfoText()}
        </Alert>
      )}
    </Box>
  );
};

export default withTranslation()(BatteryPill);
