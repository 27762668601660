import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Organisation from "../../../rest/organisation/model/Organisation";
import {
  buildPlatformAgentDetailRoute,
  buildPlatformOrgDetailsRoute,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import OrganisationTable from "../../../ui/components/OrganisationTable/OrganisationTable";
import OutlineButton from "../../../ui/components/OutlineButton";
import SearchBar from "../../../ui/components/SearchBar";
import Spinner from "../../../ui/components/Spinner";
import TableHeader from "../../../ui/components/TableList/components/TableHeader";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../ui/theme/dimensions";
import TablePageIndicator from "../../properties/PropertyListPage/components/TablePageIndicator";
import AdminInviteOrganisationDialog from "../AdminInviteOrganisationDialog/AdminInviteOrganisationDialog";
import {
  loadPlatformOrganisations,
  searchTextChanged,
} from "./redux/PlatformOrganisationListAction";
import PlatformOrganisationListState from "./redux/PlatformOrganisationListState";

type ReduxStateProps = PlatformOrganisationListState;

interface ReduxDispatchProps {
  onPageChanged: (page: number) => void;
  searchTextChanged: (text: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithT & RouteComponentProps;

interface State {
  inviteOrgDialogOpen: boolean;
}

class PlatformOrganisationListPage extends React.Component<Props, State> {
  state = { inviteOrgDialogOpen: false };

  render() {
    const searchBarPlaceholder = this.props.t(
      "platformOrganisationsList.searchBarPlaceholder"
    );

    return (
      <DashboardLayout hideRagStatus>
        <Box padding={DEFAULT_SPACING} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <SearchBar
              placeholder={searchBarPlaceholder}
              value={this.props.searchText}
              onChange={this.props.searchTextChanged}
            />

            <Box width={EXTRA_LARGE_SPACING} />

            <OutlineButton
              style={{ minWidth: 200 }}
              labelId="platformOrganisationsList.addOrganisationButton"
              endIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={this.handleAddOrgClicked}
              filled
            />
          </Box>

          <Box height={2 * EXTRA_LARGE_SPACING} />
          {this.renderTable()}
          <Box height={2 * EXTRA_LARGE_SPACING} />
          <TablePageIndicator
            selectedPage={this.props.currentPage}
            maxPages={this.props.maxPage}
            onPageNumberChanged={this.props.onPageChanged}
          />

          <AdminInviteOrganisationDialog
            open={this.state.inviteOrgDialogOpen}
            onClose={this.handleCloseAddOrgDialog}
          />
        </Box>
      </DashboardLayout>
    );
  }

  renderTable = () => {
    if (this.props.organisations !== undefined && this.props.organisations.length > 0) {
      return (
        <Box>
          <TableHeader text="platformOrganisationsList.tableTitle" />
          <OrganisationTable
            organisations={this.props.organisations}
            onOrgClicked={this.onOrganisationClicked}
          />
        </Box>
      );
    } else if (
      this.props.organisations !== undefined &&
      this.props.organisations.length === 0
    ) {
      return (
        <Box padding={LARGE_SPACING}>
          <Typography variant="h2" color="textPrimary" align="center">
            <Trans>platformOrganisationsList.emptyMessage</Trans>
          </Typography>
        </Box>
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  };

  //MARK: Handlers
  onOrganisationClicked = (org: Organisation) => {
    const route =
      org.is_agent_for?.id === undefined
        ? buildPlatformOrgDetailsRoute(org.id)
        : buildPlatformAgentDetailRoute(org.id);
    this.props.history.push(route);
  };

  handleCloseAddOrgDialog = () => {
    this.setState({ inviteOrgDialogOpen: false });
  };

  handleAddOrgClicked = () => {
    this.setState({ inviteOrgDialogOpen: true });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps =>
  state.platformOrganisationList;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onPageChanged: (number) => dispatch(loadPlatformOrganisations(number)),
  searchTextChanged: (text) => dispatch(searchTextChanged(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PlatformOrganisationListPage)));
