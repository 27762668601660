import React from "react";
import Organisation from "../../../rest/organisation/model/Organisation";
import TableList from "../TableList/TableList";
import OrgTableRow, { LinkedOrgType } from "./components/OrgTableRow";

//MARK: Style Const
const smallCellWidth = "15vw";

interface Props {
  organisations: Organisation[];
  onOrgClicked?: (org: Organisation) => void;

  onRemoveClicked?: (org: Organisation) => void;
  linkedOrg?: Organisation;
  linkType?: LinkedOrgType;

  agentOrg?: Organisation;
}

const OrganisationTable = (props: Props) => {
  // Localization keys for headers
  // NOTE: Temporary measure - hiding properties header should not be unnecessary once the api is be provided
  const propertiesLabel =
    props.linkType === "contractee"
      ? "orgTable.managedPropertiesHeader"
      : "orgTable.propertiesHeader";

  const headerKeys: string[] =
    props.linkType === "contractor"
      ? ["", "orgTable.phoneHeader"]
      : [propertiesLabel, "orgTable.phoneHeader"];

  const headerTitle: string = "orgTable.nameHeader";

  //Declarations for the row and menu objects
  const items = () => {
    return props.organisations?.map((org) => (
      <OrgTableRow
        key={org.id}
        org={org}
        smallCellWidth={smallCellWidth}
        onClick={props.onOrgClicked}
        onRemoveClick={props.onRemoveClicked}
        linkedOrg={props.linkedOrg}
        linkType={props.linkType}
        agentOrg={props.agentOrg}
      />
    ));
  };

  return (
    <TableList
      itemRows={items()}
      headerKeys={headerKeys}
      headerTitle={headerTitle}
      withAction={props.onRemoveClicked !== undefined}
    />
  );
};

export default OrganisationTable;
