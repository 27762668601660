import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import TabModel from "../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import Spinner from "../../../ui/components/Spinner";
import AgentDetailsNavRail from "./components/AgentDetailsNavRail";
import AgentManagedOrganisationsTab from "./components/tabs/AgentManagedOrganisationsTab";
import PlatformAgentDetailsTab from "./components/tabs/PlatformAgentDetailsTab";
import PlatformAgentUserList from "./components/tabs/PlatformAgentUserList";
import { loadAgentDetails } from "./redux/PlatformAgentDetailsActions";
import PlatformAgentDetailsState from "./redux/PlatformAgentDetailsState";

//Types definition
type PlatformAgentDetailsTabType =
  | "agent-details"
  | "agent-users"
  | "managed-organisations";
//Component State
interface State {
  tab: PlatformAgentDetailsTabType;
}

//Component Props
interface ReduxStateProps {
  agentDetails: PlatformAgentDetailsState;
}

interface ReduxDispatchProps {
  loadAgentDetails: (agentId: string) => void;
}

interface URLParams {
  organisationId: string;
}

type Props = ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation &
  RouteComponentProps<URLParams>;

class PlatformAgentDetailsPage extends React.Component<Props, State> {
  state = {
    tab: "agent-details" as PlatformAgentDetailsTabType,
  };

  componentDidMount() {
    // On first loading this component, load the org
    const agentId = this.props.match.params.organisationId;
    this.props.loadAgentDetails(agentId);
  }

  componentDidUpdate() {}

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  //Handlers
  handleRailLinkClicked = (route: string) => {
    this.props.history.replace(route);
  };

  handleTabClicked = (key: string) => {
    this.setState({ ...this.state, tab: key as PlatformAgentDetailsTabType });
  };

  //Renderers
  renderBody = () => {
    if (
      this.props.agentDetails.isLoadingAgentDetails &&
      this.props.agentDetails.isLoadingManagedOrganisations
    ) {
      return <Spinner />;
    } else if (
      this.props.agentDetails.agentDetails &&
      this.props.agentDetails.managedOrganisations &&
      this.props.agentDetails.agentDetailsLoaded &&
      this.props.agentDetails.managedOrganisationLoaded
    ) {
      return (
        <Box>
          <AgentDetailsNavRail
            organisation={this.props.agentDetails.agentDetails}
            navigateToRoute={this.handleRailLinkClicked}
          />

          <Box paddingY={1}>
            <PageHeaderTabs
              tabs={this.buildTabs()}
              activeKey={this.state.tab}
              tabClicked={this.handleTabClicked}
            />
          </Box>
          {this.renderTabs()}
        </Box>
      );
    } else if (this.props.agentDetails.error) {
      return <ErrorDisplay title={this.props.agentDetails.error} />;
    } else {
      return <Spinner />;
    }
  };

  renderTabs = () => {
    switch (this.state.tab) {
      case "agent-details":
        return <PlatformAgentDetailsTab />;
      case "managed-organisations":
        return <AgentManagedOrganisationsTab />;
      case "agent-users":
        return <PlatformAgentUserList />;
    }
  };

  //Builders
  buildTabs = (): TabModel[] => {
    return [
      {
        title: "platformAgentDetailTabs.agentDetailsTab",
        navKey: "agent-details" as PlatformAgentDetailsTabType,
      },
      {
        title: "platformAgentDetailTabs.managedOrganisationsTab",
        navKey: "managed-organisations" as PlatformAgentDetailsTabType,
      },
      {
        title: "platformAgentDetailTabs.agentUsersTab",
        navKey: "agent-users" as PlatformAgentDetailsTabType,
      },
    ];
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    agentDetails: state.platformAgentDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadAgentDetails: (agentId) => dispatch(loadAgentDetails(agentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PlatformAgentDetailsPage)));
