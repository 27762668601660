import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import Property, { StatusReason } from "../../../../rest/properties/model/Property";
import PropertiesRepository from "../../../../rest/properties/PropertiesRepository";
import {
  AddPropertyAction,
  addPropertyError,
  ADD_PROPERTY_TYPE,
  PAGE_CHANGED_TYPE,
  PAGE_TYPE_CHANGED_TYPE,
  propertyAdded,
  propertyListError,
  propertyListLoaded,
  PROPERTY_ADDED_TYPE,
  resetList,
  SEARCH_TEXT_CHANGED_TYPE,
  SELECTED_DEVICE_STATUS_CHANGED_TYPE,
  SELECTED_OVERDUE_STATUS_CHANGED_TYPE,
  SELECTED_RAG_STATUS_CHANGED_TYPE,
} from "./PropertyListActions";
import { ACTIVE_ORGANISATION_CHANGED_TYPE } from "../../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import Page from "../../../../rest/utils/Page";
import {
  PropertyLoadedAction,
  PROPERTY_LOADED_TYPE,
  PROPERTY_UPDATED_SUCCESS_TYPE,
} from "../../PropertyDetailPage/redux/PropertyDetailActions";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import NoActiveOrganisationError from "../../../../errors/NoActiveOrganisationError";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import RAGStatus from "../model/RAGStatus";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import OverdueStatus from "../model/OverdueStatus";
import checkCountry from "../../../../redux/utils/checkCountry";
import buildPropFormFromCode from "../utils/buildPropFormFromCode";
import { SenseProductType } from "../../../../rest/sense/model/SenseDevice";
import DeviceStatus from "../model/DeviceStatus";
import { REMOVE_LINKED_ORG_SUCCESS_TYPE } from "../../../admin/AdminOrganisationDetailsPage/redux/AdminOrganisationDetailsAction";

function* propertyListSagas() {
  // This will listen to every action with type in the array,
  // and run fetchPropertyList on each.
  // If an action arrives while it's still running, the the running one is
  // cancelled and replaced with the new one.
  yield takeLatest(
    [
      SELECTED_RAG_STATUS_CHANGED_TYPE,
      SELECTED_OVERDUE_STATUS_CHANGED_TYPE,
      SELECTED_DEVICE_STATUS_CHANGED_TYPE,
      PAGE_CHANGED_TYPE,
      PAGE_TYPE_CHANGED_TYPE,
      ACTIVE_ORGANISATION_CHANGED_TYPE,
      PROPERTY_ADDED_TYPE,
      PROPERTY_UPDATED_SUCCESS_TYPE,
      REMOVE_LINKED_ORG_SUCCESS_TYPE,
    ],
    fetchPropertyList
  );
  yield takeLatest(SEARCH_TEXT_CHANGED_TYPE, searchTextChangedSaga);
  yield takeLatest(ADD_PROPERTY_TYPE, addProperty);

  yield takeLatest(PROPERTY_LOADED_TYPE, propertyLoadedSaga);
}

function* searchTextChangedSaga() {
  // Short delay to prevent hammering the network
  yield delay(150);
  yield fetchPropertyList();
}

function* addProperty(action: AddPropertyAction) {
  try {
    const rootState: RootState = yield select();
    const isAdmin: boolean = yield isAdeyAdmin();

    const orgId = isAdmin
      ? rootState.orgDetail.organisation?.id
      : rootState.activeOrganisation.currentOrganisation?.id;
    if (orgId === undefined) {
      throw new NoActiveOrganisationError();
    }

    const propertiesRepo: PropertiesRepository = yield getDependency(
      "propertiesRepository"
    );

    const currentRole = isAdmin
      ? undefined
      : rootState.activeOrganisation.currentOrganisation?.role;

    const propertyData = buildPropFormFromCode(
      action.propertyData,
      rootState.countryList.countryList
    );

    const response: Property = yield propertiesRepo.addProperty(
      isAdmin,
      orgId,
      propertyData,
      currentRole?.via
    );
    yield put(propertyAdded(response));
  } catch (e) {
    const message = getErrorMessage(e, "addPropertyDialog.addError");
    yield put(addPropertyError(message));
  }
}

function* fetchPropertyList() {
  try {
    yield put(resetList());
    const state: RootState = yield select();
    const selectedStatusFilters = state.propertyList.selectedStatusFilters;
    const selectedOverdueFilters = state.propertyList.selectedOverdueFilters;
    const selectedDeviceFilters = state.propertyList.selectedDeviceFilters;
    const page = state.propertyList.currentPage;
    const searchText = state.propertyList.searchText;

    if (state.propertyList.page === "admin-all-properties") {
      yield fetchAllProperties(
        searchText,
        page,
        selectedStatusFilters,
        selectedOverdueFilters,
        selectedDeviceFilters
      );
    } else {
      yield fetchAnOrganisationsProperties(
        state,
        searchText,
        page,
        selectedStatusFilters,
        selectedOverdueFilters,
        selectedDeviceFilters
      );
    }
  } catch (e) {
    const message = getErrorMessage(e, "propertyListPage.errorTitle");
    yield put(propertyListError(message));
  }
}

function* propertyLoadedSaga(action: PropertyLoadedAction) {
  // When a property is updated, this action is called.
  // so replace any local copy of that property
  // with the newly updated one.

  const rootState: RootState = yield select();
  const properties = rootState.propertyList.propertyList;

  // Check if the list of properties contains the changed one,
  // if not, return
  const index = properties?.findIndex((p) => p.id === action.property.id);
  if (properties === undefined || index === undefined || index < 0) {
    return;
  }

  const updatedProperties = [...properties];
  updatedProperties[index] = action.property;

  const currentPage = rootState.propertyList.currentPage;
  const maxPages = rootState.propertyList.maxPages;
  const orgId = rootState.propertyList.organisationId;

  yield put(
    propertyListLoaded(updatedProperties, currentPage, maxPages, orgId as string)
  );
}

//Helpers
//Function that is used to get appropriate contractor ID, based on the organisation relationships
const getContractorId = (state: RootState): string | undefined => {
  let orgID = undefined;
  if (state.propertyList.page === "contractor-list") {
    orgID = state.orgDetail.organisation?.id;
  } else if (state.propertyList.page === "contractee-list") {
    orgID = state.activeOrganisation.currentOrganisation?.id;
  }
  return orgID;
};

//Function that is used to get appropriate organisation ID, based on the organisation relationships
const getOrgId = (state: RootState): string | undefined => {
  if (
    state.propertyList.page === "contractor-list" ||
    state.propertyList.page === "dashboard"
  ) {
    return state.activeOrganisation.currentOrganisation?.id;
  } else {
    return state.orgDetail.organisation?.id;
  }
};

// Fetches all the properties on the platform
function* fetchAllProperties(
  searchText: string,
  page: number,
  ragFilters: Set<RAGStatus>,
  overdueFilters: Set<OverdueStatus>,
  deviceFilters: [SenseProductType, Set<DeviceStatus> | Set<StatusReason>] | undefined
) {
  const adminRepo: AdminRepository = yield getDependency("adminRepository");

  //MARK: Disable metrics, adjust view
  const response: Page<Property[]> = yield adminRepo.getAllProperties(
    searchText,
    page,
    false,
    ragFilters,
    overdueFilters,
    deviceFilters
  );

  yield response.data.forEach((property) => {
    call(checkCountry, property.address_country.id);
  });

  yield put(
    propertyListLoaded(
      response.data,
      response.meta.current_page,
      response.meta.last_page,
      ""
    )
  );
}

function* fetchAnOrganisationsProperties(
  state: RootState,
  searchText: string,
  page: number,
  ragFilters: Set<RAGStatus>,
  overdueFilters: Set<OverdueStatus>,
  deviceFilters: [SenseProductType, Set<DeviceStatus> | Set<StatusReason>] | undefined
) {
  const propertyRepo: PropertiesRepository = yield getDependency("propertiesRepository");

  const isAdmin = state.propertyList.page === "admin-org-detail";
  const orgId = getOrgId(state);
  if (orgId === undefined) {
    throw new Error();
  }

  const response: Page<Property[]> = yield propertyRepo.getProperties(
    isAdmin,
    orgId,
    searchText,
    page,
    getContractorId(state),
    true,
    ragFilters,
    overdueFilters,
    deviceFilters
  );

  yield response.data.forEach((property) => {
    call(checkCountry, property.address_country.id);
  });

  yield put(
    propertyListLoaded(
      response.data,
      response.meta.current_page,
      response.meta.last_page,
      orgId
    )
  );
}

export default propertyListSagas;
