import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import IResultsById from "../interfaces/IResultsById";

const getProCheckDisplayItems = (
  resultsById: IResultsById,
  displayItemIds: string[]
): ProCheckResult[] => {
  const proCheckResult = displayItemIds.map((id) => {
    return resultsById[id];
  });
  return proCheckResult;
};

export default getProCheckDisplayItems;
