import { AxiosError } from "axios";
import { createRestError } from "../../../../rest/utils/RestError";

class ProfileIncompleteError {
  constructor(
    public profileComplete: boolean,
    public termsAccepted: boolean,
    public hasOrganisation: boolean
  ) {}
}

/// Check if the error is because the profile is incomplete.
/// Will return the error if it is, otherwise undefined.
export const getProfileIncompleteError = (
  e: any | undefined
): ProfileIncompleteError | undefined => {
  if (e === undefined || !e.isAxiosError) {
    return undefined;
  }

  const axiosError = e as AxiosError;
  const restError = createRestError(axiosError);
  const errorKeys = restError.errors ? Object.keys(restError.errors) : [];

  // These are set up if the error body doesn't include these keys.
  const profileComplete = !errorKeys.includes("profile");
  const termsAccepted = !errorKeys.includes("terms_conditions");
  const hasOrganisation = !errorKeys.includes("organisation");

  if (!profileComplete || !termsAccepted || !hasOrganisation) {
    return new ProfileIncompleteError(profileComplete, termsAccepted, hasOrganisation);
  } else {
    return undefined;
  }
};

export default ProfileIncompleteError;
