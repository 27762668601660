import { faSignOutAlt, faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import { LARGE_SPACING } from "../../../theme/dimensions";
import CircleAvatar from "../../CircleAvatar";
import areSameOrganisation from "../utils/areSameOrganisation";
import createSwitcherSubtitle from "../utils/createSwitcherSubtitle";
import keyForOrganisation from "../utils/keyForOrganisation";
import OrganisationSwitcherListItem from "./OrganisationSwitcherListItem";
import OrganizationSwitcherHeader from "./OrganizationSwitcherHeader";

const minWidth = 280;

interface Props extends WithTranslation {
  availableOrganisations: MemberOrganisation[];
  selectedOrganisation: MemberOrganisation | undefined;
  switchingToOrganisation: MemberOrganisation | undefined;
  canCreateOrganisation: boolean;

  onLogoutClicked: () => void;
  onOrganisationClicked: (organisation: MemberOrganisation) => void;
  onAccountSettingsClicked: () => void;
  onDetailsClicked: (organisation: MemberOrganisation | undefined) => void;
  onCreateOrganisationClicked: () => void;
}

const OrganisationSwitcherPopoverContent = (props: Props) => {
  const organisationTitle = props.t("organisationSwitcher.organisationTitle");
  const accountTitle = props.t("organisationSwitcher.accountTitle");
  const signOutItem = props.t("organisationSwitcher.signOut");
  const accountSettingsItem = props.t("organisationSwitcher.accountSettings");
  const organisationDetails = props.t("organisationSwitcher.organisationDetails");
  const createOrganisationButton = props.t(
    "organisationSwitcher.createOrganisationButton"
  );
  const agentButtonLabel = props.t("organisationSwitcher.agentButton");

  const agentOrganisation = props.availableOrganisations.find(
    (org) => org.id === props.selectedOrganisation?.agent?.id
  );

  /*
    Removes all organisation in which the user has a role of 'agent' from the switcher
   */
  const getFilteredOrgs = () => {
    let filteredOrgs: MemberOrganisation[] = [];

    filteredOrgs = props.availableOrganisations.filter(
      (org) => org.role.name !== "agent" && org !== agentOrganisation
    );

    filteredOrgs = filteredOrgs.filter(
      (org) => !areSameOrganisation(org, props.selectedOrganisation)
    );

    return filteredOrgs;
  };

  const orgListItems = getFilteredOrgs().map((org) => (
    <Grid key={keyForOrganisation(org)} item>
      <OrganisationSwitcherListItem
        text={org.name}
        onClick={() => props.onOrganisationClicked(org)}
        icon={<CircleAvatar name={org.name} imageUrl={undefined} size="small" />}
        loading={areSameOrganisation(org, props.switchingToOrganisation)}
        disabled={props.switchingToOrganisation !== undefined}
        subtext={createSwitcherSubtitle(org, props.availableOrganisations, props.t)}
      />
    </Grid>
  ));

  if (props.canCreateOrganisation) {
    orgListItems.push(
      <Grid key={"create-organisation"} item>
        <OrganisationSwitcherListItem
          text={createOrganisationButton}
          onClick={() => props.onCreateOrganisationClicked()}
          icon={<FontAwesomeIcon size="lg" icon={faPlus} />}
          disabled={props.switchingToOrganisation !== undefined}
        />
      </Grid>
    );
  }

  return (
    <Grid
      style={{ minWidth: minWidth }}
      container
      direction="column"
      alignItems="stretch"
    >
      <Grid item>
        <OrganizationSwitcherHeader
          availableOrgs={props.availableOrganisations}
          onDetailsClick={() => props.onDetailsClicked(props.selectedOrganisation)}
          selectedOrganisation={props.selectedOrganisation}
          buttonLabel={organisationDetails}
        />
      </Grid>

      {agentOrganisation !== undefined ? <Divider /> : undefined}

      {agentOrganisation !== undefined ? (
        <Grid item justify="center">
          <Box display="flex" width="100%" flexDirection="column" alignItems="center">
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              style={{
                marginTop: LARGE_SPACING,
                marginBottom: LARGE_SPACING,
                maxWidth: "220px",
              }}
              onClick={() => props.onOrganisationClicked(agentOrganisation)}
            >
              {agentButtonLabel.replace("<ORG_NAME>", agentOrganisation.name)}
            </Button>
          </Box>
        </Grid>
      ) : undefined}

      {orgListItems.length > 0 ? <Divider /> : undefined}

      {orgListItems.length > 0 ? (
        <Grid item>
          <OrganisationSwitcherListItem isTitle text={organisationTitle} />
        </Grid>
      ) : undefined}

      {orgListItems}

      <Divider />

      <Grid item>
        <OrganisationSwitcherListItem isTitle text={accountTitle} />
      </Grid>

      <Grid item>
        <OrganisationSwitcherListItem
          text={accountSettingsItem}
          onClick={props.onAccountSettingsClicked}
          icon={<FontAwesomeIcon size="lg" icon={faCog} />}
        />
      </Grid>

      <Grid item>
        <OrganisationSwitcherListItem
          text={signOutItem}
          onClick={props.onLogoutClicked}
          icon={<FontAwesomeIcon size="lg" icon={faSignOutAlt} />}
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(OrganisationSwitcherPopoverContent);
