import { Box, Theme, Tooltip, withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { TFunction, WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import RAGStatus from "../../../../pages/properties/PropertyListPage/model/RAGStatus";
import { EXTRA_LARGE_SPACING } from "../../../theme/dimensions";
import { RAGCardType } from "./RAGCard";

interface Props extends WithT {
  type: RAGCardType;
}

const RAGInfoButton = (props: Props) => {
  if (props.type === "total") {
    return null;
  }

  return (
    <Box position="absolute" right={0} top={0} zIndex={10}>
      <LightTooltip interactive title={getInfoMessage(props.type, props.t)}>
        <Box margin={1}>
          <InfoOutlined style={{ cursor: "pointer" }} color="disabled" fontSize="small" />
        </Box>
      </LightTooltip>
    </Box>
  );
};

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: EXTRA_LARGE_SPACING,
  },
}))(Tooltip);

const getInfoMessage = (ragStatus: RAGStatus, t: TFunction): string => {
  switch (ragStatus) {
    case "red":
      return t("ragStatusCard.redInfo");
    case "amber":
      return t("ragStatusCard.amberInfo");
    case "green":
      return t("ragStatusCard.greenInfo");
  }
};

export default withTranslation()(RAGInfoButton);
