import { AxiosInstance, AxiosRequestConfig } from "axios";
import DataWrapper, { unwrapData, unwrapResponse } from "../utils/DataWrapper";
import Page from "../utils/Page";
import ProCheckExport from "./model/ProCheckExport";
import ProCheckResult from "./model/ProCheckResult";
import ProCheckResultDetails from "./model/ProCheckResultDetails";
import ProCheckRepository from "./ProCheckRepository";

class RestProCheckResult implements ProCheckRepository {
  constructor(private axios: AxiosInstance) {}

  listAllProCheckResults = (
    organisationId: string,
    propertyId: string
  ): Promise<ProCheckResult[]> => {
    const url = `/organisations/${organisationId}/properties/${propertyId}/devices/procheck`;
    return this.axios.get<DataWrapper<ProCheckResult[]>>(url).then(unwrapResponse);
  };

  listOrgProCheckResults = (
    organisationId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ): Promise<Page<ProCheckResult[]>> => {
    const url = `/organisations/${organisationId}/procheck`;
    const config: AxiosRequestConfig = {
      params: {
        ...(page && { page: page }),
        ...(limit && { limit: limit }),
        ...(searchString && { keyword: searchString }),
      },
    };

    return this.axios
      .get<Page<ProCheckResult[]>>(
        url,
        page || limit || searchString ? config : undefined
      )
      .then(unwrapData);
  };

  fetchProCheckResult = (
    organisationId: string,
    propertyId: string,
    resultId: string
  ): Promise<ProCheckResultDetails> => {
    const url = `/organisations/${organisationId}/properties/${propertyId}/devices/procheck/${resultId}`;
    return this.axios.get<DataWrapper<ProCheckResultDetails>>(url).then(unwrapResponse);
  };

  listUserProCheckResults = (
    organisationId: string,
    userId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ): Promise<Page<ProCheckResult[]>> => {
    const url = `/organisations/${organisationId}/users/${userId}/procheck`;
    const config: AxiosRequestConfig = {
      params: {
        ...(page && { page: page }),
        ...(limit && { limit: limit }),
        ...(searchString && { keyword: searchString }),
      },
    };

    return this.axios
      .get<Page<ProCheckResult[]>>(
        url,
        page || limit || searchString ? config : undefined
      )
      .then(unwrapData);
  };

  sendProCheckResults = (
    isAdmin: boolean,
    exportDetails: ProCheckExport,
    organisationId: string,
    propertyId?: string,
    languageHeader?: string
  ): Promise<void> => {
    const admin = isAdmin ? "/admin" : "";
    const property = propertyId !== undefined ? `properties/${propertyId}/devices/` : "";
    const url = `${admin}/organisations/${organisationId}/${property}procheck/send`;
    const params = new URLSearchParams();
    params.append("result_ids", exportDetails.resultIds.join(","));
    params.append("email_addresses", exportDetails.emailAddresses.join(","));
    if (exportDetails.removePersonalInformation) {
      params.append("remove_personal_information", "1");
    }

    if (exportDetails.notes) {
      params.append("notes", exportDetails.notes);
    }

    const emailLanguageInterceptor = !!languageHeader
      ? this.axios.interceptors.request.use(async (request) => {
          request.headers["Accept-Language"] = languageHeader;
          return request;
        })
      : undefined;

    return this.axios
      .post<DataWrapper<void>>(url, params)
      .then((value) => {
        if (!!emailLanguageInterceptor) {
          this.axios.interceptors.request.eject(emailLanguageInterceptor);
        }
        return value;
      })
      .then(unwrapResponse);
  };
}

export default RestProCheckResult;
