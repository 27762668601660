import SuccessSnackbar from "./SuccessSnackbar";
import React from "react";

interface Props {
  message: string | undefined;
}

interface State {
  successMessage: string | undefined;
}

/**
 * Copies the success from the props into this components state.
 * This will allow the notification to be dismissed without the redux state
 * having to be updated.
 */
class SuccessSnackbarHandler extends React.Component<Props, State> {
  state = { successMessage: this.props.message };

  componentDidUpdate(prevProps: Props) {
    if (this.props.message !== prevProps.message) {
      this.setState({ successMessage: this.props.message });
    }
  }

  render() {
    return (
      <SuccessSnackbar
        message={this.state.successMessage}
        onClose={this.handleSnackbarClosed}
      />
    );
  }

  handleSnackbarClosed = () => {
    this.setState({ successMessage: undefined });
  };
}

export default SuccessSnackbarHandler;
