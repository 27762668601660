import { Box, Dialog } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import { DEFAULT_SPACING, LARGE_SPACING } from "../../../ui/theme/dimensions";
import AcceptContractorInvitePanel from "./AcceptContractorInvitePanel";
import AcceptOrganisationInvitePanel from "./AcceptOrganisationInvitePanel";
import CreateOwnerOrganisationPromptPanel from "./CreateOwnerOrganisationPromptPanel";

// This is outside the component state. We want the dialog
// to stay dismissed until the user reloads the page
let hasBeenDismissed = false;

interface ReduxStateProps {
  pendingInvites: PendingInvite[];
  canAcceptContractorInvites: boolean;
}

type Props = ReduxStateProps;

class PendingInviteDialog extends React.Component<Props> {
  render() {
    // If there are no invites, hide me
    if (this.props.pendingInvites.length === 0) {
      return null;
    }

    const invite = this.props.pendingInvites[0];

    return (
      <Dialog open={!hasBeenDismissed} onClose={this.handleDialogClosed}>
        <Box paddingX={LARGE_SPACING} paddingY={DEFAULT_SPACING}>
          {this.renderDialogContent(invite)}
        </Box>
      </Dialog>
    );
  }

  renderDialogContent = (invite: PendingInvite) => {
    if (invite.type === "user") {
      return <AcceptOrganisationInvitePanel pendingInvite={invite} />;
    } else if (this.props.canAcceptContractorInvites) {
      return <AcceptContractorInvitePanel pendingInvite={invite} />;
    } else {
      return <CreateOwnerOrganisationPromptPanel pendingInvite={invite} />;
    }
  };

  handleDialogClosed = () => {
    hasBeenDismissed = true;
    this.setState({});
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  const allInvites: PendingInvite[] = [
    ...(state.pendingInvite.invites?.user || []),
    ...(state.pendingInvite.invites?.contractor || []),
  ];
  return {
    pendingInvites: allInvites,
    canAcceptContractorInvites: state.pendingInvite.canAcceptContractorInvites,
  };
};

export default connect(mapStateToProps)(PendingInviteDialog);
