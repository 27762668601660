import IPagination from "../../../utils/interfaces/IPagination";

const buildPagination = (
  ids: string[],
  itemsPerPage: number,
  currentPage?: number
): IPagination => {
  let pagination: IPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    maxPages: 1,
    displayItems: ids,
  };

  if (ids.length === 0) {
    return pagination;
  }

  pagination.itemsPerPage = itemsPerPage;
  const remainder = ids.length % itemsPerPage;
  pagination.maxPages = Math.floor(ids.length / itemsPerPage);
  if (remainder !== 0) {
    pagination.maxPages = pagination.maxPages + 1;
  }

  //Improved handling of pagination
  if (currentPage) {
    if (currentPage > pagination.maxPages) {
      pagination.currentPage = pagination.maxPages;
    } else {
      pagination.currentPage = currentPage;
    }
  }

  if (ids.length > itemsPerPage) {
    let startSliceIndex = 0;
    let endSliceIndex = itemsPerPage;

    startSliceIndex = itemsPerPage * (currentPage ? currentPage - 1 : 0);
    endSliceIndex = itemsPerPage * (currentPage ? currentPage : 1);

    pagination.displayItems = ids.slice(startSliceIndex, endSliceIndex);
  }

  return pagination;
};

export default buildPagination;
