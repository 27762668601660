import { takeEvery } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import {
  ActiveOrganisationChangedAction,
  ACTIVE_ORGANISATION_CHANGED_TYPE,
} from "../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import { agentAccessChanged } from "./AdeyAgentAction";

function* adeyAgentSagas() {
  yield takeEvery(ACTIVE_ORGANISATION_CHANGED_TYPE, activeOrganisationChangedSaga);
}

function* activeOrganisationChangedSaga(action: ActiveOrganisationChangedAction) {
  try {
    if (action.organisation !== undefined) {
      const isAgent = action.organisation.is_agent_for?.id;

      if (isAgent !== undefined) {
        yield put(agentAccessChanged(true));
      } else {
        yield put(agentAccessChanged(false));
      }
    } else {
      // default to no access
      yield put(agentAccessChanged(false));
    }
  } catch (e) {
    // Default to no access
    yield put(agentAccessChanged(false));
  }
}

export default adeyAgentSagas;
