import AuthAction, {
  LOG_IN_START_TYPE,
  LOG_IN_ERROR_TYPE,
  LOGIN_STATUS_CHANGED_TYPE,
  PASSWORD_RESET_REQUIRED_TYPE,
  SEGMENTATION_QUESTIONS_LOADED_TYPE,
  LOAD_SEGMENTATION_QUESTIONS_TYPE,
} from "./LoginAction";
import LoginState from "./LoginState";

const initialState: LoginState = {
  status: "unknown",
  error: undefined,
  currentUserId: undefined,
};

const loginReducer = (
  state: LoginState = initialState,
  action: AuthAction
): LoginState => {
  switch (action.type) {
    case LOG_IN_START_TYPE:
      return {
        status: "logging_in",
        error: undefined,
        currentUserId: undefined,
        onboardingQuestions: [],
      };

    case LOGIN_STATUS_CHANGED_TYPE:
      return {
        status: action.loginStatus,
        error: undefined,
        currentUserId: action.currentUserId,
        onboardingQuestions:
          action.loginStatus === "logged_out" ? [] : state.onboardingQuestions,
      };

    case LOG_IN_ERROR_TYPE:
      return {
        status: "logged_out",
        error: action.error,
        currentUserId: undefined,
        onboardingQuestions: [],
      };

    case PASSWORD_RESET_REQUIRED_TYPE:
      return {
        status: "logged_out",
        error: undefined,
        currentUserId: undefined,
        resetRequired: true,
        proClubUser: action.proClubUser,
        onboardingQuestions: [],
      };

    case SEGMENTATION_QUESTIONS_LOADED_TYPE:
      return {
        ...state,
        onboardingQuestions: action.segmentationQuestions,
      };
    case LOAD_SEGMENTATION_QUESTIONS_TYPE:
      return {
        ...state,
        onboardingQuestions: [],
      };
    default:
      return state;
  }
};

export default loginReducer;
