import { AxiosInstance, AxiosRequestConfig } from "axios";
import CreateOrganisationForm from "../../pages/auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";
import Organisation from "../organisation/model/Organisation";
import AdminRepository from "./AdminRepository";
import Role from "../roles/model/Role";
import DataWrapper, { unwrapData, unwrapResponse } from "../utils/DataWrapper";
import UserDetails from "../user/model/UserDetails";
import Page from "../utils/Page";
import MemberOrganisation from "../organisation/model/MemberOrganisation";
import Property, { StatusReason } from "../properties/model/Property";
import urlEncodeProperty from "../properties/utils/urlEncodeProperty";
import ProCheckResult from "../procheck/model/ProCheckResult";
import PendingInvite from "../invites/model/PendingInvite";
import OrganisationUser from "../organisation/model/OrganisationUser";
import RAGStatus from "../../pages/properties/PropertyListPage/model/RAGStatus";
import { convertRAGToStatus } from "../properties/utils/convertRAGToStatus";
import { convertOverdueStatusToReason } from "../properties/utils/convertOverdueStatusToReason";
import OverdueStatus from "../../pages/properties/PropertyListPage/model/OverdueStatus";
import ProCheckResultDetails from "../procheck/model/ProCheckResultDetails";
import DeviceStatus from "../../pages/properties/PropertyListPage/model/DeviceStatus";
import { SenseProductType } from "../sense/model/SenseDevice";
import { fetchAllPages } from "../utils/fetchAllPages";

class RestAdminRepository implements AdminRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }
  moveProCheckTest = (
    originPropertyId: string,
    originOrgId: string,
    proCheckId: string,
    targetPropertyId: string
  ): Promise<void> => {
    const url = `/admin/organisations/${originOrgId}/properties/${originPropertyId}/devices/procheck/${proCheckId}/move`;

    const params = new URLSearchParams();

    params.append("property_id", targetPropertyId);

    return this.axios.put<DataWrapper<void>>(url, params).then(unwrapResponse);
  };

  moveProperty = (
    originOrgId: string,
    propertyId: string,
    targetOrgId: string
  ): Promise<void> => {
    const url = `/admin/organisations/${originOrgId}/properties/${propertyId}/move`;

    const params = new URLSearchParams();

    params.append("organisation_id", targetOrgId);

    return this.axios.put<DataWrapper<void>>(url, params).then(unwrapResponse);
  };
  moveSenseDevice = (
    originPropertyId: string,
    originOrgId: string,
    deviceId: string,
    targetPropertyId: string
  ): Promise<void> => {
    const url = `/admin/organisations/${originOrgId}/properties/${originPropertyId}/devices/sense/${deviceId}/move`;

    const params = new URLSearchParams();

    params.append("property_id", targetPropertyId);

    return this.axios.put<DataWrapper<void>>(url, params).then(unwrapResponse);
  };

  listOrgProCheckResults = (
    organisationId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ): Promise<Page<ProCheckResult[]>> => {
    const url = `/admin/organisations/${organisationId}/procheck`;

    const config: AxiosRequestConfig = {
      params: {
        ...(page && { page: page }),
        ...(limit && { limit: limit }),
        ...(searchString && { keyword: searchString }),
      },
    };

    return this.axios
      .get<Page<ProCheckResult[]>>(url, page || limit ? config : undefined)
      .then(unwrapData);
  };

  inviteUser = (email: string): Promise<void> => {
    const params = new URLSearchParams();
    params.append("email", email);

    return this.axios
      .post<DataWrapper<void>>("/admin/users/invite", params)
      .then(unwrapResponse);
  };

  createOrganisation = (form: CreateOrganisationForm): Promise<Organisation> => {
    const params = new URLSearchParams();
    params.append("name", form.organisationName);
    params.append("phone_number", form.organisationNumber);
    params.append("address_line_1", form.address.line1);
    params.append("address_line_2", form.address.line2 || "");
    params.append("address_line_3", form.address.line3 || "");
    params.append("address_city", form.address.city || "");
    params.append("address_postcode", form.address.postcode || "");
    params.append("address_country", form.address.country || "");
    params.append("vat_number", form.vatNumber || "");
    if (form.isAgent) {
      params.append("is_agent_for", form.address.country);
    }

    return this.axios
      .post<DataWrapper<Organisation>>("/admin/organisations", params)
      .then(unwrapResponse);
  };

  getRoles = (): Promise<Role[]> => {
    return this.axios.get<DataWrapper<Role[]>>("/admin/roles").then(unwrapResponse);
  };

  inviteUserToOrganisation = (
    email: string,
    organisationId: string,
    roleId: string
  ): Promise<void> => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("role_id", roleId);

    const endpoint = `/admin/organisations/${organisationId}/users/invite`;

    return this.axios.post<DataWrapper<void>>(endpoint, params).then(unwrapResponse);
  };

  fetchAllUsers = (keyword: string, page: number): Promise<Page<UserDetails[]>> => {
    const config: AxiosRequestConfig = { params: { keyword: keyword, page: page } };

    return this.axios.get<Page<UserDetails[]>>("/admin/users", config).then(unwrapData);
  };

  fetchUserDetails = (userId: string): Promise<UserDetails> => {
    const url = `/admin/users/${userId}`;

    return this.axios.get<DataWrapper<UserDetails>>(url).then(unwrapResponse);
  };

  updateUserDetails = (user: UserDetails): Promise<UserDetails> => {
    const url = `/admin/users/${user.id}`;

    const params = new URLSearchParams();
    params.append("first_name", user.first_name || "");
    params.append("last_name", user.last_name || "");
    params.append("phone_number", user.phone_number);
    params.append("address_postcode", user.address_postcode);
    if (user.is_tester !== undefined) {
      params.append("is_tester", user.is_tester ? "1" : "0");
    }

    return this.axios.put<DataWrapper<UserDetails>>(url, params).then(unwrapResponse);
  };

  fetchAllOrganisations = (
    keyword: string,
    page: number
  ): Promise<Page<Organisation[]>> => {
    const config: AxiosRequestConfig = {
      params: { keyword: keyword, page: page, withMetrics: 1 },
    };

    return this.axios
      .get<Page<Organisation[]>>("/admin/organisations", config)
      .then(unwrapData);
  };

  fetchAllAgents = (keyword: string, page: number): Promise<Page<Organisation[]>> => {
    const config: AxiosRequestConfig = {
      params: { keyword: keyword, page: page, withMetrics: 1, agentsOnly: 1 },
    };

    return this.axios
      .get<Page<Organisation[]>>("/admin/organisations", config)
      .then(unwrapData);
  };

  fetchAgentManagedOrganisations = (
    keyword: string,
    agentId: string
  ): Promise<Organisation[]> => {
    const fetchPage = (page: number): Promise<Page<Organisation[]>> => {
      // Setting the limit to 10000 because it's the highest we can hit
      // without causing an error on the API.
      // https://adeyinnovation.atlassian.net/browse/SPD-562
      const config: AxiosRequestConfig = {
        params: {
          page: page,
          keyword: keyword,
          agent_id: agentId,
          limit: 1000,
          withMetrics: 1,
        },
      };

      return this.axios
        .get<Page<Organisation[]>>("/admin/organisations", config)
        .then((response) => response.data);
    };

    return fetchAllPages(fetchPage);
  };

  getOrganisation = (id: string): Promise<Organisation> => {
    const config: AxiosRequestConfig = {
      params: { withMetrics: 1 },
    };

    return this.axios
      .get<DataWrapper<Organisation>>(`/admin/organisations/${id}`, config)
      .then(unwrapResponse);
  };

  updateOrganisation = (organisation: Organisation): Promise<Organisation> => {
    const url = `/admin/organisations/${organisation.id}`;

    const params = new URLSearchParams();
    params.append("name", organisation.name);
    params.append("phone_number", organisation.phone_number);
    params.append("vat_number", organisation.vat_number || "");
    params.append("address_line_1", organisation.address_line_1);
    params.append("address_line_2", organisation.address_line_2 || "");
    params.append("address_line_3", organisation.address_line_3 || "");
    params.append("address_city", organisation.address_city);
    params.append("address_postcode", organisation.address_postcode);
    params.append("address_country", organisation.address_country.id);
    if (organisation.agent) {
      params.append("agent_id", organisation.agent.id);
    }
    if (organisation.is_agent_for) {
      params.append("is_agent_for", organisation.is_agent_for.id);
    }

    return this.axios.put<DataWrapper<Organisation>>(url, params).then(unwrapResponse);
  };

  fetchUserOrganisations = (userId: string): Promise<MemberOrganisation[]> => {
    const url = `/admin/users/${userId}/organisations`;

    return this.axios.get<DataWrapper<MemberOrganisation[]>>(url).then(unwrapResponse);
  };

  getProperty = (
    organisationId: string,
    propertyId: string,
    withMetrics: boolean
  ): Promise<Property> => {
    const url = `/admin/organisations/${organisationId}/properties/${propertyId}`;
    const config = { params: { withMetrics: withMetrics ? 1 : 0 } };

    return this.axios.get<DataWrapper<Property>>(url, config).then(unwrapResponse);
  };

  updateProperty = (orgId: string, property: Property): Promise<Property> => {
    const url = `/admin/organisations/${orgId}/properties/${property.id}`;
    const params = urlEncodeProperty(property);

    return this.axios.put<DataWrapper<Property>>(url, params).then(unwrapResponse);
  };

  updateContractor = (
    organisationId: string,
    propertyId: string,
    contractorId: string | undefined
  ): Promise<Organisation> => {
    const endpoint = `/admin/organisations/${organisationId}/properties/${propertyId}/contractor`;
    const params = new URLSearchParams();
    if (contractorId) {
      params.append("contractor_id", contractorId);
    }
    return this.axios
      .put<DataWrapper<Organisation>>(endpoint, params)
      .then(unwrapResponse);
  };

  removeContractor = (
    organisationId: string,
    propertyId: string,
    currentContractorId: string
  ): Promise<Organisation> => {
    const endpoint = `/admin/organisations/${organisationId}/properties/${propertyId}/contractor/${currentContractorId}`;

    return this.axios.delete<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };

  removeContractorFromOrganisation = (
    organisationId: string,
    contractorId: string
  ): Promise<Organisation> => {
    const endpoint = `/admin/organisations/${organisationId}/contractors/${contractorId}`;

    return this.axios.delete<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };

  removeUserFromOrganisation = (
    organisationId: string,
    userId: string
  ): Promise<OrganisationUser> => {
    const endpoint = `/admin/organisations/${organisationId}/users/${userId}`;

    return this.axios
      .delete<DataWrapper<OrganisationUser>>(endpoint)
      .then(unwrapResponse);
  };

  fetchOrganisationContractorList = (
    organisationId: string,
    keyword: string | undefined
  ): Promise<Organisation[]> => {
    const endpoint = `/admin/organisations/${organisationId}/contractors`;

    const config: AxiosRequestConfig = { params: { keyword: keyword, withMetrics: 1 } };

    return this.axios
      .get<DataWrapper<Organisation[]>>(endpoint, config)
      .then(unwrapResponse);
  };

  listAllProCheckResults = (
    organisationId: string,
    propertyId: string
  ): Promise<ProCheckResult[]> => {
    const url = `/admin/organisations/${organisationId}/properties/${propertyId}/devices/procheck`;
    return this.axios.get<DataWrapper<ProCheckResult[]>>(url).then(unwrapResponse);
  };

  listUserProCheckResults = (
    ///
    organisationId: string,
    userId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ): Promise<Page<ProCheckResult[]>> => {
    const url = `/admin/organisations/${organisationId}/users/${userId}/procheck`;
    const config: AxiosRequestConfig = {
      params: {
        ...(page && { page: page }),
        ...(limit && { limit: limit }),
        ...(searchString && { keyword: searchString }),
      },
    };
    return this.axios
      .get<Page<ProCheckResult[]>>(
        url,
        page || limit || searchString ? config : undefined
      )
      .then(unwrapData);
  };

  fetchProCheckResult = (
    organisationId: string,
    propertyId: string,
    resultId: string
  ): Promise<ProCheckResultDetails> => {
    const url = `/admin/organisations/${organisationId}/properties/${propertyId}/devices/procheck/${resultId}`;
    return this.axios.get<DataWrapper<ProCheckResultDetails>>(url).then(unwrapResponse);
  };

  fetchContractingOrganisations = (organisationId: string): Promise<Organisation[]> => {
    const url = `/admin/organisations/${organisationId}/contracting`;
    return this.axios.get<DataWrapper<Organisation[]>>(url).then(unwrapResponse);
  };

  fetchContractorInvites = (organisationId: string): Promise<PendingInvite[]> => {
    const url = `/admin/organisations/${organisationId}/contractors/invite`;
    return this.axios.get<DataWrapper<PendingInvite[]>>(url).then(unwrapResponse);
  };

  fetchOrganisationUsers = (organisationId: string): Promise<OrganisationUser[]> => {
    const url = `/admin/organisations/${organisationId}/users`;
    return this.axios.get<DataWrapper<OrganisationUser[]>>(url).then(unwrapResponse);
  };

  fetchOrganisationUserInvites = (organisationId: string): Promise<PendingInvite[]> => {
    const url = `/admin/organisations/${organisationId}/users/invite`;
    return this.axios.get<DataWrapper<PendingInvite[]>>(url).then(unwrapResponse);
  };

  getAllProperties = (
    keyword: string,
    page: number,
    withMetrics: boolean,
    ragStatusFilters: Set<RAGStatus>,
    overdueFilters: Set<OverdueStatus>,
    deviceFilters: [SenseProductType, Set<DeviceStatus> | Set<StatusReason>] | undefined
  ): Promise<Page<Property[]>> => {
    const url = `/admin/properties`;
    // Convert the RAG status into "pass" / "warning" / "fail"
    // and provide to backed as CSV.
    const ragStatusParams = Array.from(ragStatusFilters.values())
      .map(convertRAGToStatus)
      .join(",");

    // Parses overdue status (reason) into request parameters
    const overdueStatusParams = Array.from(overdueFilters.values()).map((arr) =>
      convertOverdueStatusToReason(arr).join(",")
    );

    const reasonsParam =
      deviceFilters !== undefined
        ? [...overdueStatusParams, ...Array.from(deviceFilters[1])].join(",")
        : overdueStatusParams.join(",");

    const config: AxiosRequestConfig = {
      params: {
        keyword: keyword,
        page: page,
        withMetrics: withMetrics ? 1 : 0,
        // Don't give anything if this is empty, or the BE will
        // return nothing.
        status: ragStatusParams.length > 0 ? ragStatusParams : undefined,
        product: deviceFilters?.[0].toLocaleLowerCase(),
        statusReason: reasonsParam.length > 0 ? reasonsParam : undefined,
      },
    };

    return this.axios.get<Page<Property[]>>(url, config).then(unwrapData);
  };
}

export default RestAdminRepository;
