import { select } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import { loadCountryList } from "../../pages/countries/redux/CountryAction";
import RootState from "../RootState";

// Checks if the country of organisation or property is present in the country list. If not, re-fetch the list
// This function assumes that the country already exists in the database
// If required, admin users can be allowed to automatically add the country to the DB if it is not present in the list after refetching
function* checkCountry(id: string) {
  const rootState: RootState = yield select();
  const country = rootState.countryList.countryList?.find((country) => country.id === id);
  if (!country) {
    yield put(loadCountryList());
  }
}

export default checkCountry;
