//HomeZone Unsubscribing
export const HOMEZONE_UNSUBSCRIBE_TYPE = "HOMEZONE_UNSUBSCRIBE_TYPE";

export interface HomeZoneUnsubscribeAction {
  type: typeof HOMEZONE_UNSUBSCRIBE_TYPE;
  token: string;
}

export const homeZoneUnsubscribe = (token: string): HomeZoneUnsubscribeAction => {
  return {
    type: HOMEZONE_UNSUBSCRIBE_TYPE,
    token: token,
  };
};

//HomeZone Unsubscribed
export const HOMEZONE_UNSUBSCRIBED_TYPE = "HOMEZONE_UNSUBSCRIBED_TYPE";

export interface HomeZoneUnsubscribedAction {
  type: typeof HOMEZONE_UNSUBSCRIBED_TYPE;
}

export const homeZoneUnsubscribed = (): HomeZoneUnsubscribedAction => {
  return {
    type: HOMEZONE_UNSUBSCRIBED_TYPE,
  };
};

//HomeZone Unsubscribing Error
export const HOMEZONE_UNSUBSCRIBE_ERROR_TYPE = "HOMEZONE_UNSUBSCRIBE_ERROR_TYPE";

export interface HomeZoneUnsubscribeErrorAction {
  type: typeof HOMEZONE_UNSUBSCRIBE_ERROR_TYPE;
  error: string;
}

export const homeZoneUnsubscribeError = (
  error: string
): HomeZoneUnsubscribeErrorAction => {
  return {
    type: HOMEZONE_UNSUBSCRIBE_ERROR_TYPE,
    error: error,
  };
};

export type HomeZoneAction =
  | HomeZoneUnsubscribeAction
  | HomeZoneUnsubscribedAction
  | HomeZoneUnsubscribeErrorAction;
