import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography, IconButton } from "@material-ui/core";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { EXTRA_LARGE_SPACING } from "../../../theme/dimensions";

type Props = {
  key?: string;
  tooltip?: string | undefined;
  text: string;
};

class TableHeader extends React.Component<Props> {
  render() {
    return (
      <Typography
        color="secondary"
        variant="h5"
        style={{ textTransform: "uppercase", marginLeft: EXTRA_LARGE_SPACING }}
      >
        <strong>
          <Trans>{this.props.text}</Trans>
        </strong>
        {this.props.tooltip ? (
          <Tooltip interactive title={this.props.tooltip}>
            <IconButton>
              <FontAwesomeIcon icon={faInfoCircle} size="xs" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Typography>
    );
  }
}

export default withTranslation()(TableHeader);
