import React from "react";
import { Button, IconButton, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";

interface Props {
  message: string | undefined;
  onClose: () => void;
}

const SuccessSnackbar = (props: Props) => {
  const open = !!props.message;

  // Full width snackbar
  const alertStyle = { width: "100%" };

  return (
    <Snackbar
      key={props.message}
      message={props.message}
      open={open}
      onClose={props.onClose}
      autoHideDuration={null}
      action={<Button>Close</Button>}
    >
      <Alert
        style={alertStyle}
        severity="success"
        variant="filled"
        action={
          <IconButton size="small" onClick={props.onClose}>
            <FontAwesomeIcon color="white" icon={faTimes} />
          </IconButton>
        }
        onClick={props.onClose}
      >
        {/* 
          Wrap this in a <Trans> tag so that if it's a key it'll be localized
          If it's not a translation it'll be left as is.
        */}
        <Trans>{props.message}</Trans>
      </Alert>
    </Snackbar>
  );
};

export default SuccessSnackbar;
