import { Box, Divider, Typography } from "@material-ui/core";
import { Cancel, ThumbUpAltOutlined } from "@material-ui/icons";
import { DEFAULT_SPACING, LARGE_SPACING, SMALL_SPACING } from "../../theme/dimensions";
import PrimaryButton from "../PrimaryButton";
import { Trans, useTranslation } from "react-i18next";
import TEntityToMove from "../../../pages/admin/AdminMoveEntity/types/TEntityToMove";
import TMoveDestination from "../../../pages/admin/AdminMoveEntity/types/TMoveDestination";
import { BACKGROUND_ACCENT_COLOR } from "../../theme/createMaterialTheme";
import { IMoveActionResult } from "../../../pages/admin/AdminMoveEntity/services/AdminMoveEntityService";
import { Alert } from "@material-ui/lab";

interface IProps<T> {
  entityType: TEntityToMove;
  getItemName: (item: T) => string;
  destination: TMoveDestination;
  moveResults?: IMoveActionResult;
  items: T[];
  rejectedItems?: T[];
  loading?: boolean;
  handleClosePressed: () => void;
}

const MoveEntitySuccessPanel = <T extends unknown>(props: IProps<T>) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const closeLabel = t("moveEntitySuccessPanel.closeButton");

  //Helpers
  const itemNames = props.items.map((item) => props.getItemName(item));
  const rejectedItemNames = props.rejectedItems?.map((item) => props.getItemName(item));

  const getDescription = () => {
    if (props.rejectedItems && props.rejectedItems.length > 0) {
      return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Typography align="center">
            <Trans
              t={t}
              i18nKey={"moveEntitySuccessPanel.description"}
              values={{ items: itemNames, destination: props.destination.description }}
            />
          </Typography>
          <Box width={"100%"} my={SMALL_SPACING}>
            <Divider style={{ width: "100%" }} />
          </Box>
          <Alert
            style={{
              marginTop: SMALL_SPACING,
              marginBottom: SMALL_SPACING,
              whiteSpace: "pre-line",
              width: "100%",
            }}
            icon={false}
            severity="warning"
          >
            <Trans
              t={t}
              i18nKey={"moveEntitySuccessPanel.descriptionPartial"}
              count={itemNames.length}
              values={{ items: rejectedItemNames }}
            />
          </Alert>
        </Box>
      );
    }

    return (
      <Typography align="center">
        <Trans
          t={t}
          i18nKey={"moveEntitySuccessPanel.description"}
          values={{ items: itemNames, destination: props.destination.description }}
        />
      </Typography>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} alignItems="center">
      <ThumbUpAltOutlined
        style={{
          fontSize: "100px",
          marginTop: LARGE_SPACING,
          marginBottom: LARGE_SPACING,
          color: BACKGROUND_ACCENT_COLOR,
        }}
      />

      {getDescription()}
      <Box marginTop={DEFAULT_SPACING} />
      <PrimaryButton
        disabled={props.loading}
        isLoading={props.loading}
        label={closeLabel}
        endIcon={<Cancel />}
        onClick={props.handleClosePressed}
        fullWidth
      />
      <Box marginTop={SMALL_SPACING} />
    </Box>
  );
};

export default MoveEntitySuccessPanel;
