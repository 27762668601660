import CountryCode from "./CountryCode";

const getLanguageHeader = (code: CountryCode): string | undefined => {
  switch (code) {
    case "GB":
      return "en";
    case "IE":
      return "en";
    case "AT":
      return "de";
    case "FR":
      return "fr";
    case "AU":
      return "en_AU";
    default:
      return undefined;
  }
};

export default getLanguageHeader;
