import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import INormalisedResults from "../interfaces/INormalisedResults";

const getProCheckReportArray = (
  normalisedResult: INormalisedResults
): ProCheckResult[] => {
  const proCheckResult = normalisedResult.allIds.map((id) => {
    return normalisedResult.resultsById[id];
  });
  return proCheckResult;
};

export default getProCheckReportArray;
