import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../redux/RootState";
import { cancelForgotPasswordActivation } from "../redux/ForgotPasswordAction";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { activationEmailRequested } from "../../ResendActivationEmail/redux/ResendActivationEmailAction";
import ErrorSnackbar from "../../../../ui/components/ErrorSnackbar/ErrorSnackbar";
import SuccessSnackbar from "../../../../ui/components/SuccessSnackbar/SuccessSnackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";

const ResetPasswordAccountActivationPopup = () => {
  const dispatch = useDispatch();
  const email = useSelector(
    (state: RootState) => state.forgotPassword.emailActivationRequired
  );
  const isResending = useSelector(
    (state: RootState) => state.resendActivationEmail.isLoading
  );
  const resendSuccess = useSelector(
    (state: RootState) => state.resendActivationEmail.resendSuccessful
  );
  const resendErrorMessage = useSelector(
    (state: RootState) => state.resendActivationEmail.errorMessage
  );

  // Storing messages in local state so they can be easily dismissed.
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  //todo: localize this
  const title = "Account activation required";
  const content = "You need to activate your account before you can reset your password.";
  const contentTwo = "This is done by clicking the link in the email we sent you.";
  const resendButton = "Resend activation link";
  const cancelButton = "Cancel";
  const resendSuccessMessage = "Activation email sent successfully";

  const handleCancel = () => {
    dispatch(cancelForgotPasswordActivation());
  };

  const handleResendEmail = () => {
    dispatch(activationEmailRequested(email));
  };

  // Close the dialog on success
  useEffect(() => {
    if (resendSuccess) {
      dispatch(cancelForgotPasswordActivation());
      setSuccessMessage(resendSuccessMessage);
    }
  }, [resendSuccess, dispatch]);

  // Load the error message into local state when it changes
  useEffect(() => {
    setErrorMessage(resendErrorMessage);
  }, [resendErrorMessage]);

  return (
    <div>
      <Dialog open={!!email}>
        <Box minWidth={440}>
          <DialogTitle>
            <Typography variant="h2" align="center">
              <strong>{title.toUpperCase()}</strong>
            </Typography>
          </DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogContent>{contentTwo}</DialogContent>
          <DialogActions>
            <SecondaryButton
              disabled={isResending}
              label={cancelButton}
              onClick={handleCancel}
            />
            <Button
              variant="contained"
              endIcon={
                isResending ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <FontAwesomeIcon icon={faShareSquare} />
                )
              }
              onClick={handleResendEmail}
              color="primary"
              disabled={isResending}
            >
              {resendButton}
            </Button>
          </DialogActions>
        </Box>

        <ErrorSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(undefined)}
        />
      </Dialog>

      <SuccessSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(undefined)}
      />
    </div>
  );
};

export default ResetPasswordAccountActivationPopup;
