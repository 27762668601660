import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import displayNameForRole from "../../pages/users/detail/utils/displayNameForRole";
import { RoleName } from "../../rest/roles/model/Role";
import PrimaryTextField from "./PrimaryTextField";

interface Props extends WithT {
  role: RoleName | undefined;
  availableRoles: RoleName[];
  hint?: string;
  helperText?: string;
  errorText: string | undefined;

  onRoleChanged: (role: RoleName) => void;
}

const RolePicker = (props: Props) => {
  const selectOptions = props.availableRoles.map((role) => ({
    value: role,
    labelId: displayNameForRole(role, props.t),
  }));

  return (
    <PrimaryTextField
      hint={props.hint}
      helperText={props.helperText}
      value={props.role || ""}
      selectOptions={selectOptions}
      onChange={(v) => props.onRoleChanged(v as RoleName)}
      errorText={props.errorText}
    />
  );
};

export default withTranslation()(RolePicker);
