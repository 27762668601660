import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Organisation from "../../../rest/organisation/model/Organisation";
import UserDetails from "../../../rest/user/model/UserDetails";
import { ORGANISATION_LIST_ROUTE } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import TabModel from "../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import Spinner from "../../../ui/components/Spinner";
import { LARGE_SPACING, SMALL_SPACING } from "../../../ui/theme/dimensions";
import UserDetailPanel from "../../users/detail/components/UserDetailPanel";
import StopContractingButton from "./components/contractee/StopContractingButton";
import ContractorPropertyList from "./components/properties/OrganisationPropertyList";
import RemoveContractorButton from "./components/contractor/RemoveContractorButton";
import OrganisationDetailNavRail from "./components/OrganisationDetailNavRail";
import OrganisationDetailPanel from "./components/OrganisationDetailPanel";
import { loadOrganisation, removeOrganisation } from "./redux/OrganisationDetailActions";
import OrganisationDetailState from "./redux/OrganisationDetailState";

interface URLParams {
  organisationId: string;
}

interface ReduxStateProps {
  parentOrganisation: Organisation | undefined;
  organisationDetailsState: OrganisationDetailState;
  orgOwner: UserDetails | undefined;
  canRemoveOrganisationContractors: boolean;
}

interface ReduxDispatchProps {
  loadOrganisation: (organisationID: string) => void;
  removeOrganisation: (contractor: Organisation, org: Organisation) => void;
}

type Props = RouteComponentProps<URLParams> &
  ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation;

type OrgDetailTabType = "org-details" | "contractor-properties" | "org-owner";

interface State {
  activeKey: OrgDetailTabType;
  tabs: TabModel[];
  orgRemoved: boolean;
}

class OrganisationDetailPage extends React.Component<Props, State> {
  //Titles should correspond to keys in 'translate.ts' file
  state = {
    orgRemoved: false,
    activeKey: "org-details" as OrgDetailTabType,
    tabs: [
      {
        title: "orgDetailsSwitcher.detailsLabel",
        navKey: "org-details",
      },
      {
        title: "orgDetailsSwitcher.ownerLabel",
        navKey: "org-owner",
      },
      {
        title: "orgDetailsSwitcher.propertiesLabel",
        navKey: "contractor-properties",
      },
    ],
  };

  componentDidMount() {
    const organisationId = this.props.match.params.organisationId;
    this.props.loadOrganisation(organisationId);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.parentOrganisation?.id && !prevProps.parentOrganisation?.id) {
      const organisationId = this.props.match.params.organisationId;
      this.props.loadOrganisation(organisationId);
    }

    if (
      this.props.organisationDetailsState.organisationRemoved &&
      !prevProps.organisationDetailsState.organisationRemoved
    ) {
      this.setState({ ...this.state, orgRemoved: true });
    }
  }

  render() {
    return (
      <DashboardLayout>
        <Box>{this.renderBody()}</Box>
      </DashboardLayout>
    );
  }

  renderBody = () => {
    if (this.props.organisationDetailsState.organisation) {
      return (
        <Box paddingBottom={LARGE_SPACING}>
          <OrganisationDetailNavRail
            organisation={this.props.organisationDetailsState.organisation}
            navigateToRoute={this.handleRailLinkClicked}
            relationship={this.props.organisationDetailsState.organisationRelationship}
            endComponent={this.renderNavRailEndComponent()}
          />
          <PageHeaderTabs
            tabs={this.state.tabs}
            activeKey={this.state.activeKey}
            tabClicked={this.handleTabClicked}
          />
          {this.renderTabs()}

          <ErrorSnackbarHandler message={this.props.organisationDetailsState.error} />
        </Box>
      );
    } else if (this.props.organisationDetailsState.error) {
      return <ErrorDisplay title={this.props.organisationDetailsState.error} />;
    } else {
      return <Spinner />;
    }
  };

  renderTabs = () => {
    switch (this.state.activeKey) {
      case "org-details":
        return this.props.organisationDetailsState.organisation ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="top"
            padding={SMALL_SPACING}
            justifyContent="flex-start"
          >
            <Box display="flex" flexDirection="column">
              <OrganisationDetailPanel
                organisation={this.props.organisationDetailsState.organisation}
              />
            </Box>
          </Box>
        ) : null;
      case "contractor-properties":
        return <ContractorPropertyList />;
      case "org-owner":
        return this.props.orgOwner ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="top"
            padding={SMALL_SPACING}
            justifyContent="flex-start"
          >
            <UserDetailPanel
              organisation={this.props.organisationDetailsState.organisation}
              user={this.props.orgOwner}
            />
          </Box>
        ) : null;
    }
  };

  renderNavRailEndComponent = () => {
    const relationship = this.props.organisationDetailsState.organisationRelationship;

    if (!this.props.canRemoveOrganisationContractors) {
      return undefined;
    }

    if (relationship === "contractor") {
      return (
        <RemoveContractorButton
          parentOrganisationName={this.props.parentOrganisation?.name}
          org={this.props.organisationDetailsState.organisation}
          removeContractorConfirmed={this.handleRemoveOrgClicked}
          loading={this.props.organisationDetailsState.isRemovingOrganisation}
          orgRemoved={this.state.orgRemoved}
          returnToListClicked={this.handleReturnToListClicked}
        />
      );
    } else {
      return (
        <StopContractingButton
          organisationName={this.props.organisationDetailsState.organisation?.name || ""}
          orgRemoved={this.state.orgRemoved}
          returnToListClicked={this.handleReturnToListClicked}
          loading={this.props.organisationDetailsState.isRemovingOrganisation}
          stopContractingConfirmed={this.handleStopContractingClicked}
        />
      );
    }
  };

  handleTabClicked = (key: string) => {
    this.setState({ ...this.state, activeKey: key as OrgDetailTabType });
  };

  handleRailLinkClicked = (route: string) => {
    this.props.history.replace(route);
  };

  handleRemoveOrgClicked = (org: Organisation) => {
    if (this.props.parentOrganisation) {
      this.props.removeOrganisation(org, this.props.parentOrganisation);
    }
  };

  handleStopContractingClicked = () => {
    const parentOrg = this.props.organisationDetailsState.organisation;
    const contractorOrg = this.props.parentOrganisation;

    if (contractorOrg && parentOrg) {
      this.props.removeOrganisation(contractorOrg, parentOrg);
    }
  };

  handleReturnToListClicked = () => {
    this.props.history.replace(ORGANISATION_LIST_ROUTE);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    organisationDetailsState: state.orgDetail,
    parentOrganisation: state.activeOrganisation.currentOrganisation,
    orgOwner: state.orgDetail.organisation?.owner,
    canRemoveOrganisationContractors:
      state.activeOrganisation.permissions.canRemoveOrganisationContractors &&
      state.orgDetail.organisationRelationship !== undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadOrganisation: (organisationId) => dispatch(loadOrganisation(organisationId)),
  removeOrganisation: (contractor, org) => dispatch(removeOrganisation(contractor, org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OrganisationDetailPage)));
