import { Box } from "@material-ui/core";
import React from "react";
import { BACKGROUND_ACCENT_COLOR } from "../../theme/createMaterialTheme";
import background from "./adey_background.png";

interface Props {
  width: String;
  height: String;
}

const AdeyBackground = (props: Props) => {
  return (
    <Box
      position="fixed"
      width={props.width}
      height={props.height}
      bottom="0"
      zIndex="1"
      bgcolor={BACKGROUND_ACCENT_COLOR}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPositionY: "top -50px",
      }}
    />
  );
};

export default AdeyBackground;
