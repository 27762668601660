import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { TFunction, withTranslation, WithTranslation } from "react-i18next";
import ColoredText from "../../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import formatDayTime from "../../../../../utils/formatDayTime";
import {
  BORDER_RADIUS,
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../../ui/theme/dimensions";
import SenseDeviceReadings from "../../../../../rest/sense/model/SenseDeviceReadings";

interface ExternalProps {
  readings: SenseDeviceReadings[];
}

type Props = ExternalProps & WithTranslation;

//Styled components
const StyledTable = withStyles({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    width: "100%",
  },
})(Table);

const HeaderCell = (
  props: {
    textId?: string;
    t: TFunction;
  } & TableCellProps
) => {
  const StyledHeaderCell = withStyles({
    root: {
      border: "none",
      paddingBottom: "0",
    },
  })(TableCell);

  return (
    <StyledHeaderCell {...props} padding="none">
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
        <strong>{props.t(props.textId || "")}</strong>
      </ColoredText>
    </StyledHeaderCell>
  );
};

const StyledRowCell = withStyles({
  root: {
    border: "solid 1px #0000001F",
    borderStyle: "solid none",
    padding: "8px",
    "&:first-child": {
      borderLeftStyle: "solid",
      borderTopLeftRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    "&:last-child": {
      borderRightStyle: "solid",
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
})(TableCell);

const SenseDeviceReadingsTab = (props: Props) => {
  //Localised strings
  const readingsLabel = props.t("senseDeviceReadingsTab.title");

  //String keys
  const readingHeader = "senseDeviceReadingsHeaders.reading";
  const dateHeader = "senseDeviceReadingsHeaders.date";
  const timeHeader = "senseDeviceReadingsHeaders.time";

  //Renderers
  const buildResultRow = (reading: string, dateTime: string) => {
    const parsedDayTime = formatDayTime(dateTime);

    return (
      <TableRow>
        <StyledRowCell>{reading}</StyledRowCell>
        <StyledRowCell>{parsedDayTime.date}</StyledRowCell>
        <StyledRowCell align="right">{parsedDayTime.time}</StyledRowCell>
      </TableRow>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="top"
      justifyContent="flex-start"
      maxWidth="100%"
      paddingX={EXTRA_LARGE_SPACING}
      paddingBottom={DEFAULT_SPACING}
    >
      <ColoredText
        textColor={BACKGROUND_ACCENT_COLOR}
        variant="button"
        gutterBottom
        style={{ fontSize: "16px", marginBottom: LARGE_SPACING }}
      >
        <strong>{readingsLabel}</strong>
      </ColoredText>
      <StyledTable>
        <TableHead>
          <TableRow>
            <HeaderCell align="left" textId={readingHeader} t={props.t} />
            <HeaderCell
              align="left"
              style={{
                paddingLeft: 10,
                width: 150,
                paddingRight: 10,
              }}
              textId={dateHeader}
              t={props.t}
            />
            <HeaderCell
              align="right"
              style={{
                paddingLeft: 10,
                width: 100,
                paddingRight: 10,
              }}
              textId={timeHeader}
              t={props.t}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.readings
            .slice(0, 9)
            .map((reading) => buildResultRow(reading.type, reading.timestamp))}
        </TableBody>
      </StyledTable>
    </Box>
  );
};

export default withTranslation()(SenseDeviceReadingsTab);
