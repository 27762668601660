import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../../../redux/RootState";
import TSelectedMoveTab from "../../../../../admin/AdminMoveEntity/types/TMoveTab";
import {
  adminInitDestinationSearch,
  adminResetMoveState,
  initAdminMoveEntities,
} from "../../../../../admin/AdminMoveEntity/redux/AdminMoveEntityAction";
import { buildPlatformOrgDetailsRoute } from "../../../../../../routes/routes";
import { useHistory } from "react-router";
import { adminLoadOrganisation } from "../../../../../organisations/details/redux/OrganisationDetailActions";
import { resetProperty } from "../../../../PropertyDetailPage/redux/PropertyDetailActions";
import { changePageType } from "../../../redux/PropertyListActions";

const tabList: TSelectedMoveTab[] = [
  "destination",
  "confirmation",
  "warning",
  "success",
  "failure",
];

const useMoveSelectedProperties = () => {
  //Dispatch
  const dispatch = useDispatch();

  //History
  const history = useHistory();

  //Selector
  const destinations = useSelector(
    (state: RootState) => state.adminMoveEntity.destinations
  );

  const loading = useSelector(
    (state: RootState) =>
      state.adminMoveEntity.searchingDestination || state.adminMoveEntity.movingEntity
  );

  const moveResults = useSelector(
    (state: RootState) => state.adminMoveEntity.moveResults
  );

  const moved = moveResults && moveResults.movedEntities.length > 0;

  //State
  const [searchString, setSearchString] = useState<string>("");
  const [curretTab, setCurrentTab] = useState(0);
  const [destinationId, setSelectedDestinationId] = useState<string>();
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  //Effects
  useEffect(() => {
    if (moveResults && destinationId) {
      if (moved) {
        setCurrentTab(3);
        window.history.pushState({}, "", buildPlatformOrgDetailsRoute(destinationId));
      } else {
        setCurrentTab(4);
      }
    }
  }, [destinationId, moved, moveResults]);

  const handleMoveSelectedProperties = (propertyIds: string[], orgId: string) => {
    if (destinationId) {
      dispatch(initAdminMoveEntities("property", propertyIds, destinationId, orgId));
    }
    return;
  };

  const handleInitSearch = () => {
    setSelectedDestinationId(undefined);
    dispatch(adminInitDestinationSearch(searchString.trim().toLowerCase(), "property"));
  };

  const handleMoveDialogOpen = () => {
    if (activeTab === "success") {
      return;
    }
    if (!moveDialogOpen) {
      setSelectedDestinationId(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
    setMoveDialogOpen(!moveDialogOpen);
  };

  const handleSetSearchString = (search: string) => {
    setSearchString(search);
  };

  const handleSelectedDestination = (v: string) => {
    if (v === destinationId) {
      setSelectedDestinationId(undefined);
    } else {
      setSelectedDestinationId(v);
    }
  };

  const handleBackPressed = () => {
    setCurrentTab(curretTab - 1);
  };

  const handleNextPressed = () => {
    setCurrentTab(curretTab + 1);
  };

  const handleClosePressed = () => {
    setMoveDialogOpen(false);
    if (destinationId) {
      dispatch(changePageType("admin-org-detail"));
      dispatch(resetProperty());
      dispatch(adminLoadOrganisation(destinationId));
      history.replace(buildPlatformOrgDetailsRoute(destinationId));
      setSelectedDestinationId(undefined);
      setSearchString("");
      setCurrentTab(0);
      dispatch(adminResetMoveState());
    }
  };

  const activeTab = tabList[curretTab];

  return {
    destinations,
    searchString,
    activeTab,
    moveDialogOpen,
    moveResults,
    loading,
    destinationId,
    handleBackPressed,
    handleNextPressed,
    handleSelectedDestination,
    handleMoveDialogOpen,
    handleSetSearchString,
    handleInitSearch,
    handleMoveSelectedProperties,
    handleClosePressed,
  };
};

export default useMoveSelectedProperties;
