import React from "react";
import icon from "./arrow_icon.svg";

const defaultWidth = 32;

interface ArrowIconProps {
  width?: number;
  backwards?: boolean;
}

const ArrowIcon = (props: ArrowIconProps) => {
  const width = props.width || defaultWidth;
  const rotationDegrees = props.backwards ? 180 : 0;

  const style = {
    maxWidth: width,
    height: "auto",
    transform: `rotate(${rotationDegrees}deg)`,
  };

  return <img style={style} src={icon} alt="" />;
};

export default ArrowIcon;
