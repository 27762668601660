import { Button } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { LinkedOrgType } from "../../../../ui/components/OrganisationTable/components/OrgTableRow";
import AdminRemoveContracteeDialog from "./contractee/AdminRemoveContracteeDialog";
import AdminRemoveContractorDialog from "./contractor/AdminRemoveContractorDialog";

interface Props extends WithT {
  organisation: Organisation | undefined;
  linkedOrg: Organisation | undefined;
  linkType: LinkedOrgType | undefined;
  removeContractorConfirmed: (organisation: Organisation) => void;
}

interface State {
  isConfirmOpen: boolean;
}

class AdminRemoveOrgConnectionButton extends React.Component<Props, State> {
  state = {
    isConfirmOpen: false,
  };

  render() {
    const parentOrganisationName = this.props.linkedOrg?.name || "";
    const org = this.props.organisation;
    const linkedOrg = this.props.linkedOrg;
    const removeButtonLabel = this.props.t("buttons.remove");
    if (!parentOrganisationName || !org || !linkedOrg) {
      return null;
    }

    return (
      <div>
        <Button
          style={{ width: 80, height: 40 }}
          variant="contained"
          color="primary"
          onClick={this.handleClicked}
        >
          {removeButtonLabel}
        </Button>

        {this.renderRemovalDialog(org, linkedOrg)}
      </div>
    );
  }

  renderRemovalDialog = (
    organisation: Organisation,
    linkedOrganisation: Organisation
  ) => {
    switch (this.props.linkType) {
      case "contractor":
        return (
          <AdminRemoveContracteeDialog
            open={this.state.isConfirmOpen}
            contracteeName={organisation.name}
            contractorName={linkedOrganisation.name}
            onStopContractingConfirmed={this.handleDeleteClicked}
            onCancelClicked={this.handleCancelClicked}
          />
        );
      case "contractee":
        return (
          <AdminRemoveContractorDialog
            onCancelClicked={this.handleCancelClicked}
            onDeleteClicked={this.handleDeleteClicked}
            open={this.state.isConfirmOpen}
            contracteeName={linkedOrganisation.name}
            contractorName={organisation.name}
          />
        );
      default:
        return null;
    }
  };

  handleClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ isConfirmOpen: true });
    e.stopPropagation();
  };

  handleCancelClicked = () => {
    this.setState({ isConfirmOpen: false });
  };

  handleDeleteClicked = () => {
    if (this.props.organisation) {
      this.props.removeContractorConfirmed(this.props.organisation);
      this.handleCancelClicked();
    }
  };
}

export default withTranslation()(AdminRemoveOrgConnectionButton);
