import ExportBarAction, {
  ADD_EXPORTING_ITEM_TYPE,
  EXPORT_INIT_ERROR_TYPE,
  INIT_ITEM_EXPORT_TYPE,
  INIT_ITEM_PPROCESSING_TYPE,
  REMOVE_EXPORTING_ITEM_FAILURE_TYPE,
  REMOVE_EXPORTING_ITEM_SUCCESS_TYPE,
  UPDATE_EXPORTING_ITEM_TYPE,
} from "./ExportBarAction";
import ExportBarState from "./ExportBarState";

const initialState: ExportBarState = {
  exportItems: [],
  error: undefined,
};

const exportBarReducer = (
  state = initialState,
  action: ExportBarAction
): ExportBarState => {
  switch (action.type) {
    case INIT_ITEM_EXPORT_TYPE:
      return { ...state };
    case ADD_EXPORTING_ITEM_TYPE:
      return { ...state, exportItems: [...state.exportItems, action.item] };
    case INIT_ITEM_PPROCESSING_TYPE:
      return {
        ...state,
        exportItems: state.exportItems.map((item) =>
          item.id === (action.export_id ?? action.item.id) ? action.item : item
        ),
      };
    case UPDATE_EXPORTING_ITEM_TYPE:
      return {
        ...state,
        exportItems: state.exportItems.map((item) =>
          item.id === (action.export_id ?? action.item.id) ? action.item : item
        ),
      };
    case REMOVE_EXPORTING_ITEM_SUCCESS_TYPE:
      return {
        ...state,
        exportItems: state.exportItems.filter((item) => item.id !== action.item.id),
      };
    //Not sure how to handle error in this one - I'm inclined to let it fail silently.
    //This is just future-proofing for more elaborate error handling and/or retry function
    case REMOVE_EXPORTING_ITEM_FAILURE_TYPE:
      return {
        ...state,
        exportItems: state.exportItems.filter((item) => item.id !== action.item.id),
      };
    case EXPORT_INIT_ERROR_TYPE:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default exportBarReducer;
