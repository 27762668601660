// Agent access changed
export const AGENT_ACCESS_CHANGED_TYPE = "AGENT_ACCESS_CHANGED_TYPE";
export interface AgentAccessChangedAction {
  type: typeof AGENT_ACCESS_CHANGED_TYPE;
  hasAgentAccess: boolean;
}
export const agentAccessChanged = (hasAccess: boolean): AgentAccessChangedAction => ({
  type: AGENT_ACCESS_CHANGED_TYPE,
  hasAgentAccess: hasAccess,
});

// Union type
type AdeyAgentAction = AgentAccessChangedAction;
export default AdeyAgentAction;
