import React, { useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { LoginStatus } from "../pages/auth/Login/redux/LoginState";
import RootState from "../redux/RootState";
import LoadingPage from "../pages/loading/LoadingPage";
import MemberOrganisation from "../rest/organisation/model/MemberOrganisation";
import AuthenticatedRouter from "./components/AuthenticatedRouter";
import NotAuthenticatedRouter from "./components/NotAuthenticatedRouter";
import AdeyAdminRouter from "./components/AdeyAdminRouter";
import AdeyAgentRouter from "./components/AdeyAgentRouter";
import { Box } from "@material-ui/core";
import SystemMessagesContainer from "../pages/auth/SystemMessagesDialogs/SystemMessagesContainer";





interface ReduxStateProps {
  loginStatus: LoginStatus;
  activeOrganisation: MemberOrganisation | undefined;
  orgSwitcherError: string | undefined;
  isAdeyAdmin: boolean;
  isAdeyAgent: boolean;
}

type Props = ReduxStateProps;

const AppRouter = (props: Props) => {
 

  if (
    props.loginStatus === "unknown" ||
    (props.loginStatus === "logged_in" && props.activeOrganisation === undefined) ||
    props.orgSwitcherError !== undefined
  ) {
    return <LoadingPage />;
  }

  const isLoggedIn = props.loginStatus === "logged_in";


  let router: JSX.Element;

  if (isLoggedIn && props.isAdeyAdmin) {
    router = <AdeyAdminRouter />;
  } else if (isLoggedIn && props.isAdeyAgent) {
    router = <AdeyAgentRouter />;
  } else if (isLoggedIn) {
    router = <AuthenticatedRouter />;
  } else {
    router = <NotAuthenticatedRouter />;
  }

  return (
    <Box>
      <SystemMessagesContainer />
      <BrowserRouter>
   
          {router}
      
      </BrowserRouter>
    </Box>
  );
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    loginStatus: state.login.status,
    activeOrganisation: state.activeOrganisation.currentOrganisation,
    orgSwitcherError: state.activeOrganisation.error,
    isAdeyAdmin: state.adeyAdmin.hasAdminAccess,
    isAdeyAgent: state.adeyAgent.hasAgentAccess,
  };
};

export default connect(mapStateToProps)(AppRouter);

