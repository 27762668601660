import { Link, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import AdminRemoveUserButton from "../../../../pages/admin/AdminOrganisationDetailsPage/components/AdminRemoveUserButton";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import UserDetails from "../../../../rest/user/model/UserDetails";

interface Props {
  user: OrganisationUser;
  smallCellWidth: string;
  withMargins?: boolean;
  key: string;
  loading?: boolean;
  org?: Organisation;
  onClick?: (user: UserDetails) => void;
  removeUser?: (user: UserDetails, org: Organisation) => void;
}

const OrgUserTableRow = (props: Props) => {
  const user = props.user;
  const name = [user.user.first_name, user.user.last_name]
    .filter((word) => typeof word === "string")
    .map((word) => word?.trim() || "")
    .filter((line) => line.length > 0)
    .join(" ");

  const clickable = props.onClick !== undefined;
  const smallCellStyle = { width: props.smallCellWidth };
  const actionCellStyle = { width: "80px" };
  const rowStyle = clickable ? { cursor: "pointer" } : undefined;

  const handleClick = () => {
    if (clickable) {
      props.onClick?.(props.user.user);
    }
  };

  return (
    <TableRow key={user.user.id} hover={clickable} style={rowStyle} onClick={handleClick}>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Link href={"mailto:" + user.user.email} color="textPrimary">
          <Typography>{user.user.email}</Typography>
        </Link>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>{user.user.phone_number}</Typography>
      </TableCell>
      {props.withMargins ? (
        <TableCell style={actionCellStyle}>
          {props.removeUser !== undefined &&
          props.org !== undefined &&
          props.user.role.name !== "owner" &&
          props.user.role.name !== "contractor" ? (
            <AdminRemoveUserButton
              loading={props.loading!}
              user={props.user.user!}
              organisation={props.org}
              removeUserConfirmed={props.removeUser}
            />
          ) : null}
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default OrgUserTableRow;
