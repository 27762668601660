import AddPropertyFormModel from "../../pages/properties/AddPropertyDialog/model/AddPropertyFormModel";
import PropertiesRepository from "./PropertiesRepository";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import Property, { StatusReason } from "./model/Property";
import DataWrapper, { unwrapData, unwrapResponse } from "../utils/DataWrapper";
import Page from "../utils/Page";
import RAGStatus from "../../pages/properties/PropertyListPage/model/RAGStatus";
import { convertRAGToStatus } from "./utils/convertRAGToStatus";
import { convertOverdueStatusToReason } from "./utils/convertOverdueStatusToReason";

import urlEncodeProperty from "./utils/urlEncodeProperty";
import Organisation from "../organisation/model/Organisation";
import OverdueStatus from "../../pages/properties/PropertyListPage/model/OverdueStatus";
import DeviceStatus from "../../pages/properties/PropertyListPage/model/DeviceStatus";
import { SenseProductType } from "../sense/model/SenseDevice";

class RestPropertiesRepository implements PropertiesRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  addProperty = (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyData: AddPropertyFormModel,
    contractorId: string | undefined
  ) => {
    const params = new URLSearchParams();
    params.append("contact_name", propertyData.contact_name);
    params.append("contact_phone", propertyData.contact_phone);
    params.append("address_line_1", propertyData.address_line_1);
    if (propertyData.address_line_2) {
      params.append("address_line_2", propertyData.address_line_2);
    }
    if (propertyData.address_line_3) {
      params.append("address_line_3", propertyData.address_line_3);
    }
    params.append("contact_email", propertyData.contact_email);
    params.append("address_city", propertyData.address_city);
    params.append("address_postcode", propertyData.address_postcode);
    params.append("address_country", propertyData.address_country);

    if (contractorId) {
      params.append("contractor_id", contractorId);
    }
    if (propertyData.notes) {
      params.append("notes", propertyData.notes);
    }
    params.append(
      "homezone_reporting_enabled",
      propertyData.homezone_reporting_enabled ? "1" : "0"
    );

    const urlPrefix = isAdeyAdmin ? "/admin" : "";
    const url = `${urlPrefix}/organisations/${organisationId}/properties`;

    return this.axios.post<DataWrapper<Property>>(url, params).then(unwrapResponse);
  };

  getProperties = (
    isAdeyAdmin: boolean,
    organisationId: string,
    keyword: string,
    page: number,
    contractorId: string | undefined,
    withMetrics: boolean,
    ragStatusFilters: Set<RAGStatus>,
    overdueFilters: Set<OverdueStatus>,
    deviceFilters: [SenseProductType, Set<DeviceStatus> | Set<StatusReason>] | undefined
  ): Promise<Page<Property[]>> => {
    const urlPrefix = isAdeyAdmin ? "/admin" : "";
    const url = `${urlPrefix}/organisations/${organisationId}/properties`;

    // Convert the RAG status into "pass" / "warning" / "fail"
    // and provide to backed as CSV.
    const ragStatusParams = Array.from(ragStatusFilters.values())
      .map(convertRAGToStatus)
      .join(",");

    // Parses overdue status (reason) into request parameters
    const overdueStatusParams = Array.from(overdueFilters.values()).map((arr) =>
      convertOverdueStatusToReason(arr).join(",")
    );

    const reasonsParam =
      deviceFilters !== undefined
        ? [...overdueStatusParams, ...Array.from(deviceFilters[1])].join(",")
        : overdueStatusParams.join(",");

    const config: AxiosRequestConfig = {
      params: {
        keyword: keyword,
        page: page,
        contractor: contractorId,
        withMetrics: withMetrics ? 1 : 0,
        // Don't give anything if this is empty, or the BE will
        // return nothing.
        status: ragStatusParams.length > 0 ? ragStatusParams : undefined,
        product: deviceFilters?.[0].toLowerCase(),
        statusReason: reasonsParam.length > 0 ? reasonsParam : undefined,
      },
    };

    return this.axios.get<Page<Property[]>>(url, config).then(unwrapData);
  };

  getProperty = (
    organisationId: string,
    propertyId: string,
    withMetrics: boolean
  ): Promise<Property> => {
    const endpoint = `/organisations/${organisationId}/properties/${propertyId}`;
    const config = { params: { withMetrics: withMetrics ? 1 : 0 } };
    return this.axios.get<DataWrapper<Property>>(endpoint, config).then(unwrapResponse);
  };

  updateProperty = (orgId: string, property: Property): Promise<Property> => {
    const url = `/organisations/${orgId}/properties/${property.id}`;
    const params = urlEncodeProperty(property);

    return this.axios.put<DataWrapper<Property>>(url, params).then(unwrapResponse);
  };

  updateContractor = (
    organisationId: string,
    propertyId: string,
    contractorId: string | undefined
  ): Promise<Organisation> => {
    const endpoint = `/organisations/${organisationId}/properties/${propertyId}/contractor`;
    const params = new URLSearchParams();
    if (contractorId) {
      params.append("contractor_id", contractorId);
    }
    return this.axios
      .put<DataWrapper<Organisation>>(endpoint, params)
      .then(unwrapResponse);
  };

  unsubscribeFromHomezone = (token: string): Promise<void> => {
    const endpoint = `/homezone/${token}/unsubscribe`;

    return this.axios.put<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };

  removeContractor = (
    organisationId: string,
    propertyId: string,
    currentContractorId: string
  ): Promise<Organisation> => {
    const endpoint = `/organisations/${organisationId}/properties/${propertyId}/contractor/${currentContractorId}`;

    return this.axios.delete<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };

  deleteProperty = (
    admin: boolean,
    organisationId: string,
    propertyId: string
  ): Promise<Property> => {
    const urlPrefix = admin ? "/admin" : "";
    const endpoint = `${urlPrefix}/organisations/${organisationId}/properties/${propertyId}`;

    return this.axios.delete<DataWrapper<Property>>(endpoint).then(unwrapResponse);
  };
}

export default RestPropertiesRepository;
