import SystemMessageAction, {
  GET_SYSTEM_MESSAGES_ERROR,
  GET_SYSTEM_MESSAGES_SUCCESS,
  INIT_GET_SYSTEM_MESSAGES,
  SYSTEM_DOWN,
} from "./SystemMessageAction";
import SystemMessageState from "./SystemMessageState";

const initialState: SystemMessageState = {
  isLoading: false,
  systemDown: false,
  error: undefined,
  messages: [],
};

const systemMessageReducer = (
  state: SystemMessageState = initialState,
  action: SystemMessageAction
): SystemMessageState => {
  switch (action.type) {
    case INIT_GET_SYSTEM_MESSAGES:
      return { ...initialState };
    case GET_SYSTEM_MESSAGES_SUCCESS:
      return { ...state, isLoading: false, messages: action.messages, error: undefined };
    case GET_SYSTEM_MESSAGES_ERROR:
      return { ...initialState, isLoading: false, error: action.error };
    case SYSTEM_DOWN:
      return { ...initialState, isLoading: false, systemDown: true };
    default:
      return state;
  }
};

export default systemMessageReducer;
