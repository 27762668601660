const registrationErrorHandler = (e: any): string | undefined => {
  if (!e.code && !e.message) {
    return undefined;
  }

  const statusCode = e.code;
  if (statusCode === "UsernameExistsException") {
    return "registrationErrors.exists";
  }

  return undefined;
};

export default registrationErrorHandler;
