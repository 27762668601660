import { Box, Divider } from "@material-ui/core";
import { WithT } from "i18next";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import DeviceLogo from "../../../../ui/assets/DeviceImages/DeviceLogo";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import SuccessSnackbarHandler from "../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../ui/theme/dimensions";
import ExportProcheckResultsDialog from "../../../procheck/ProCheckPage/components/ExportProcheckResultsDialog";
import ProductReportTable from "../../../procheck/ProCheckPage/components/ProductReportTable";
import ProductSummary from "../../../procheck/ProCheckPage/components/ProductSummary";
import ProductReportTableItem from "../../../procheck/ProCheckPage/model/ProductReportTableItem";
import useReportPagination from "../../../reports/dashboard/ReportsDashboardPage/hooks/useReportsPagination";
import useReportsTab from "../../../reports/dashboard/ReportsDashboardPage/hooks/useReportsTab";
import TablePageIndicator from "../../../properties/PropertyListPage/components/TablePageIndicator";
import useReportsSelection from "../../../reports/dashboard/ReportsDashboardPage/hooks/useReportSelection";

interface Props extends WithT {
  onReportClicked: (report: ProductReportTableItem) => void;
}

export interface ExportDialogForm {
  emails: string[];
  hidePersonalDetails: boolean;
  notes?: string;
}

const areResultsEqual = (prevResults: {}, nextResults: {}) => {
  return false;
};

const ProCheckDetailTab = (props: Props) => {
  // Using useState function
  // to retain searchBar value

  const {
    proCheckState,
    searchBarValue,
    handleSearchBarChanged,
    tableItems,
    resetSelectedReports,
  } = useReportsSelection();

  const {
    handleSingleReportExport,
    handleExportButtonClicked,
    handleSingleReportDownload,
    isDialogOpen,
    isLoading,
  } = useReportsTab("user-reports", resetSelectedReports);

  const { currentPage, maxPages, onPageNumberChanged } = useReportPagination();

  //Will not enter infinite render loop - handled by using React.Memo with 'areResultsEqual' custom function.
  //When adding props, do not forget to expand comparator to rerender when necessary

  const title = props.t("proCheck.name");
  const productType = props.t("proCheck.productType");
  const successMessage = props.t("proCheck.exportSuccess");

  //Dialog Export Function(s) State
  const [exportFunc, setExportFunc] = useState<(dialogForm: ExportDialogForm) => void>(
    () => (item: ProductReportTableItem) => {}
  );

  const [downloadFunc, setDownloadFunc] = useState<
    (hidePersonalDetails: boolean) => void
  >(() => (item: ProductReportTableItem) => {});

  //Dialog Export Functions Stubbs
  //TODO: Add Redux
  const exportSingleReport = (
    item: ProductReportTableItem,
    dialogForm: ExportDialogForm
  ) => {
    handleSingleReportExport(
      item,
      dialogForm.emails,
      [item.id],
      dialogForm.hidePersonalDetails,
      dialogForm.notes
    );
  };

  //Handlers
  //Handlers set the export function to accept dialog parameters, and,
  //in case of a single report export, pass selected item to the function directly

  const handleReportExportClicked = (item: ProductReportTableItem) => {
    const selectedItem = item;
    setExportFunc(() => (dialogForm: ExportDialogForm) => {
      exportSingleReport(selectedItem, dialogForm);
    });
    setDownloadFunc(() => (dialogForm: ExportDialogForm) => {
      handleSingleReportDownload(selectedItem, [item.id], dialogForm.hidePersonalDetails);
    });
    handleExportButtonClicked();
  };

  const handleReportClicked = (report: ProductReportTableItem) => {
    props.onReportClicked(report);
  };

  return (
    <Box>
      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Box display="flex" flexDirection="row">
          <Box flex={1} marginRight={LARGE_SPACING}>
            <ProductSummary
              title={title}
              productType={productType}
              logo={<DeviceLogo deviceType="PROCHECK" height={60} />}
            />
          </Box>
        </Box>

        <Box marginY={DEFAULT_SPACING}>
          <Divider />
        </Box>

        <Box>
          <ProductReportTable
            searchBarValue={searchBarValue}
            tableItems={tableItems}
            isLoading={proCheckState.isLoading}
            onExportClicked={handleReportExportClicked}
            onReportClicked={handleReportClicked}
            onSearchChange={handleSearchBarChanged}
            selectedReports={[]}
            selectableRows={false}
            propertyAddress={true}
          />
          <TablePageIndicator
            selectedPage={currentPage}
            maxPages={maxPages}
            onPageNumberChanged={onPageNumberChanged}
          />
        </Box>

        <ExportProcheckResultsDialog
          exported={proCheckState.reportExported}
          open={isDialogOpen}
          isLoading={isLoading}
          onDialogClose={handleExportButtonClicked}
          handleReportsDownload={(hidePersonalDetails: boolean) => {
            downloadFunc(hidePersonalDetails);
          }}
          handleReportsExport={(
            emails: string[],
            hidePersonalDetails: boolean,
            notes?: string
          ) => {
            exportFunc({
              emails: emails,
              hidePersonalDetails: hidePersonalDetails,
              notes: notes,
            });
          }}
        />
      </Box>
      <SuccessSnackbarHandler
        message={proCheckState.reportExported ? successMessage : undefined}
      />
      <ErrorSnackbarHandler message={proCheckState.error} />
    </Box>
  );
};

export default withTranslation()(React.memo(ProCheckDetailTab, areResultsEqual));
