import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import displayNameForRole from "../../../../pages/users/detail/utils/displayNameForRole";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";

interface Props extends WithT {
  key: string;
  invite: PendingInvite;
  menuCellWidth: number;
  showRole: boolean | undefined;

  onMenuPressed: (invite: PendingInvite, element: Element) => void;
}

const InviteTableRow = (props: Props) => {
  const menuCellStyle = { width: props.menuCellWidth };

  const onMenuPressed = (e: React.MouseEvent<Element>) => {
    props.onMenuPressed(props.invite, e.currentTarget);
  };

  const roleName = props.invite.role?.name;
  const roleTableEntry = roleName ? displayNameForRole(roleName, props.t) : "-";

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary">{props.invite.email}</Typography>
        </Box>
      </TableCell>

      {/*Role entry and role parameter. If absent, assume this type of invite couldn't have a role  */}
      {props.invite.role && props.showRole ? (
        <TableCell>
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary">{roleTableEntry}</Typography>
          </Box>
        </TableCell>
      ) : null}

      {/* The menu button */}
      <TableCell style={menuCellStyle} align="center">
        <IconButton style={{ width: 40, height: 40 }} onClick={onMenuPressed}>
          <FontAwesomeIcon icon={faEllipsisV} size="sm" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(InviteTableRow);
