import { createRestError } from "../../utils/RestError";

const propertyErrorHandler = (e: any): string | undefined => {
  if (e === undefined || !e.isAxiosError) {
    return undefined;
  }

  const restError = createRestError(e);
  if (restError.statusCode === 404) {
    return "error.propertyStatus404";
  } else {
    return undefined;
  }
};

export default propertyErrorHandler;
