import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface ExternalProps {
  open: boolean;
  organisationName: string;

  onCancelClicked: () => void;
  onStopContractingConfirmed: () => void;
}

type Props = ExternalProps & WithT;

const ConfirmStopContractingDialog = (props: Props) => {
  const title = props.t("confirmStopConfirmingDialog.title");
  const bodyPlaceholder = props.t("confirmStopConfirmingDialog.bodyPlaceholder");
  const stopContractingButton = props.t(
    "confirmStopConfirmingDialog.stopContractingButton"
  );

  const body = bodyPlaceholder.replace("%s", props.organisationName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={stopContractingButton}
      onCancelClicked={props.onCancelClicked}
      onConfirmed={props.onStopContractingConfirmed}
    />
  );
};

export default withTranslation()(ConfirmStopContractingDialog);
