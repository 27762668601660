import { Box, CircularProgress, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";

interface ExternalProps extends WithTranslation {
  isOpen: boolean;
  organisationName: string;
}

const SwitchingManagedOrgPopup = (props: ExternalProps) => {
  //Labels
  const switchingOrgPlaceholder = props
    .t("agentOrganisationListPage.switchingOrgTitle")
    .replace("<org_name>", props.organisationName);

  return (
    <Dialog open={props.isOpen}>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        padding={DEFAULT_SPACING}
      >
        <Typography style={{ textTransform: "uppercase" }} color="secondary" variant="h5">
          <strong>{switchingOrgPlaceholder}</strong>
        </Typography>
        <Box marginY={SMALL_SPACING} />
        <CircularProgress color="secondary" />
      </Box>
    </Dialog>
  );
};

export default withTranslation()(SwitchingManagedOrgPopup);
