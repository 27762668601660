import { colorForStatus } from "../../../../pages/properties/PropertyListPage/model/RAGStatus";
import { DashboardMetrics } from "../../DashboardLayout/redux/DashboardState";
import { RAGCardType } from "../components/RAGCard";

const createRAGCardData = (type: RAGCardType, metrics: DashboardMetrics) => {
  switch (type) {
    case "total":
      return [
        { value: metrics.redPercentage || 0, color: colorForStatus("red") },
        {
          value: metrics.amberPercentage || 0,
          color: colorForStatus("amber"),
        },
        {
          value: metrics.greenPercentage || 0,
          color: colorForStatus("green"),
        },
      ];

    case "red":
      return { value: metrics.redPercentage || 0, color: colorForStatus("red") };

    case "amber":
      return {
        value: metrics.amberPercentage || 0,
        color: colorForStatus("amber"),
      };

    case "green":
      return {
        value: metrics.greenPercentage || 0,
        color: colorForStatus("green"),
      };
  }
};

export default createRAGCardData;
