import { DateTime } from "luxon";
import ProCheckResultDetails, {
  SystemInfo,
  TestImages,
} from "../../../../rest/procheck/model/ProCheckResultDetails";
import ProCheckRecSummaryProvider from "../../utils/ProCheckRecSummaryProvider";
import ProCheckDetailsPageState from "../model/ProCheckDetailsPageState";
import ProCheckTestOutcomeProvider from "./ProCheckTestOutcomeProvider";

const convertProCheckDetailsToState = (
  reportData: ProCheckResultDetails
): ProCheckDetailsPageState => {
  let formattedTestDate: string;
  let formattedTestTime: string;

  const testDate = DateTime.fromISO(reportData.tested_at, { setZone: true });

  // Switched to Luxon to get UTC at the time of measurement
  const timeZone = testDate.zoneName;

  //formattedTestDate = formatDate(testDate, "dd-MM-yyyy");
  formattedTestDate = testDate.toLocaleString(
    { ...DateTime.DATE_SHORT },
    { locale: "en-gb" }
  );
  formattedTestTime = `${
    testDate.get("hour").toLocaleString().length > 1
      ? testDate.get("hour")
      : "0" + testDate.get("hour")
  }:${
    testDate.get("minute").toLocaleString().length > 1
      ? testDate.get("minute")
      : "0" + testDate.get("minute")
  } (${timeZone})`;

  return {
    id: reportData.id,
    date: formattedTestDate,
    time: formattedTestTime,
    user: reportData.user,
    system_info: reportData.system_info ?? construnctSystemInfoPlaceholder(),
    job_reference_id: reportData.results.job_reference_id,
    pass: reportData.pass,
    notes: reportData.notes,
    location: reportData.user_location
      ? {
          lat: reportData.user_location.latitude,
          lng: reportData.user_location.longitude,
        }
      : undefined,
    results: reportData.results.colourTest
      ? {
          pH1: {
            outcome: ProCheckTestOutcomeProvider.getPhOutcome(reportData.recommendations),
            value: reportData.results.colourTest.results.pH1.value,
            snapshot_url: retrieveImageUrl(reportData.images, "snapshot"),
          },
          molybdate: {
            outcome: ProCheckTestOutcomeProvider.getMolyOutcome(
              reportData.recommendations
            ),
            value: reportData.results.colourTest.results.molybdate.value,
            snapshot_url: retrieveImageUrl(reportData.images, "snapshot"),
          },
          iron: {
            outcome: ProCheckTestOutcomeProvider.getIronOutcome(
              reportData.recommendations
            ),

            value: reportData.results.colourTest.results.iron.value,
            snapshot_url: retrieveImageUrl(reportData.images, "snapshot"),
          },
          ...(reportData.results.colourTest.results.copper && {
            copper: {
              outcome: ProCheckTestOutcomeProvider.getCopperOutcome(
                reportData.recommendations
              ),
              value: reportData.results.colourTest.results.copper.value,
              snapshot_url: retrieveImageUrl(reportData.images, "snapshot"),
            },
          }),
        }
      : undefined,
    waterSample: {
      image: retrieveImageUrl(reportData.images, "sample"),
      outcome: reportData.results.waterSample.outcome,
      appearance: reportData.results.waterSample.appearance,
    },
    summary: reportData.recommendations
      ? ProCheckRecSummaryProvider(reportData.recommendations, {
          withCopper: reportData.results.colourTest?.results.copper !== undefined,
        })
      : ["ProCheckSummary.colourTestMissing"],
  };
};

const construnctSystemInfoPlaceholder = (): SystemInfo => {
  return {
    filter: undefined,
    reason: "-",
    type: "-",
    serialBoiler: "-",
  };
};

const retrieveImageUrl = (images: TestImages, imageName: string): string => {
  let urlName = "";

  Object.keys(images).forEach((key) => {
    if (key.includes(imageName)) {
      urlName = images[key];
    }
  });

  return urlName;
};

export default convertProCheckDetailsToState;
