import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";

interface Props {
  onCancelClicked: () => void;
  onYesClicked: () => void;
}

const PopoverMenuConfirmItem = (props: Props) => {
  return (
    <Box display="flex" padding={1} alignItems="center">
      <Typography color="primary">
        <Trans>itemTableMenu.confirmActionText</Trans>
      </Typography>

      <Box flexGrow={1} />

      <Button size="small" color="secondary" onClick={props.onCancelClicked}>
        <Trans>buttons.cancel</Trans>
      </Button>
      <Button size="small" color="primary" onClick={props.onYesClicked}>
        <Trans>buttons.yes</Trans>
      </Button>
    </Box>
  );
};

export default PopoverMenuConfirmItem;
