import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import INormalisedResults from "../interfaces/INormalisedResults";
import IResultsById from "../interfaces/IResultsById";

/**
 * Used to normalise data to enable better paginations
 * @param results ProCheck Test Results
 * @returns map of ProCheck results and array of IDs
 */
const normaliseProCheckResults = (results: ProCheckResult[]): INormalisedResults => {
  const allIds: string[] = [];
  let resultsById: IResultsById = {};

  results.forEach((result) => {
    allIds.push(result.id);
    resultsById[result.id] = result;
  });

  return {
    allIds,
    resultsById,
  };
};

export default normaliseProCheckResults;
