import React, { useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { DASHBOARD_ROUTE } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import UserDetailsLoadingPanel from "./components/UserDetailsLoadingPanel";
import UserPanel from "./components/UserPanel";
import ChangePassword from "./model/ChangePassword";
import CurrentUserDetails from "./model/CurrentUserDetails";
import {
  currentUserDetailsUpdated,
  loadCurrentUser,
  currentUserChangePassword,
} from "./redux/CurrentUserAction";
import CurrentUserState from "./redux/CurrentUserState";

interface ReduxDispatchProps {
  loadCurrentUser: () => void;
  onUserUpdated: (user: CurrentUserDetails) => void;
  onPasswordChanged: (password: ChangePassword, message: string) => void;
}

type ReduxStateProps = CurrentUserState;

type Props = RouteComponentProps & WithTranslation & ReduxDispatchProps & ReduxStateProps;

const AccountSettingsPage: React.FC<Props> = ({
  t,
  user,
  error,
  isLoading,
  success,
  loadCurrentUser,
  onPasswordChanged,
  onUserUpdated,
  history,
}) => {
  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  const handleNavLinkClicked = (_: string) => {
    history.replace(DASHBOARD_ROUTE);
  };

  const renderBody = () => {
    if (user) {
      const links = [
        {
          key: "dashboard",
          name: t("accountSettingsPage.userSettingsLink"),
          startIcon: faArrowLeft,
          onClick: handleNavLinkClicked,
        },
      ];

      return (
        <Box>
          <UserPanel
            pageTitle={t("accountSettingsPage.userSettingsTitle")}
            navLinks={links}
            onPasswordChanged={onPasswordChanged}
            onUserUpdated={onUserUpdated}
            user={user}
            error={error}
            loading={isLoading}
            success={success}
          />
        </Box>
      );
    } else if (error) {
      return <ErrorDisplay title={t("accountSettingsPage.errorTitle")} message={error} />;
    } else {
      return <UserDetailsLoadingPanel />;
    }
  };

  return <DashboardLayout>{renderBody()}</DashboardLayout>;
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return state.currentUser;
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadCurrentUser: () => dispatch(loadCurrentUser()),
  onPasswordChanged: (password, message) => dispatch(currentUserChangePassword(password, message)),
  onUserUpdated: (currentUser) => dispatch(currentUserDetailsUpdated(currentUser)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AccountSettingsPage)));
