import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import { Trans } from "react-i18next";
import {
  SECONDARY_COLOR,
  SELECTABLE_ITEM_COLOR,
  SELECTABLE_ITEM_HOVER_COLOR,
} from "../theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING } from "../theme/dimensions";

interface OutlineButtonProps {
  label?: string;
  labelId?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  filled?: boolean;
  marginRight?: number;
  isLoading?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

type Props = OutlineButtonProps;

const OutlineButton = (props: Props) => {
  const StyledButton = withStyles({
    root: {
      marginRight: props.marginRight,
      borderColor: SECONDARY_COLOR,
      backgroundColor: props.filled ? SELECTABLE_ITEM_COLOR : undefined,
      "&:hover": {
        backgroundColor: props.filled ? SELECTABLE_ITEM_HOVER_COLOR : undefined,
      },
    },
  })(Button);

  const loadingIndicator = props.isLoading ? (
    <CircularProgress
      style={{ marginLeft: EXTRA_LARGE_SPACING }}
      color="secondary"
      size={14}
    />
  ) : undefined;

  return (
    <StyledButton
      style={props.style}
      variant="outlined"
      color="secondary"
      onClick={props.onClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={props.isLoading || props.disabled}
    >
      <strong>
        <Trans>{props.labelId}</Trans>
        {props.label}
      </strong>

      {loadingIndicator}
    </StyledButton>
  );
};

export default OutlineButton;
