import { Color } from "@material-ui/lab";

const getAlertSeverity = (priority: number) => {
  let severity: Color = "info";

  switch (priority) {
    case 3:
      severity = "success";
      break;
    case 2:
      severity = "warning";
      break;
    case 1:
      severity = "error";
      break;
  }
  return severity;
};

export default getAlertSeverity;
