import { Country } from "../../../../rest/countries/model/Country";
import Organisation from "../../../../rest/organisation/model/Organisation";

const buildOrganisationFromCode = (
  organisation: Organisation,
  countryList: Country[] | undefined
): Organisation => {
  const newOrg: Organisation = {
    ...organisation,
    address_country: countryList?.find(
      (country) => country.code === organisation.address_country.code
    ) || {
      id: "",
      code: "",
      name: "",
    },
  };
  return newOrg;
};

export default buildOrganisationFromCode;
