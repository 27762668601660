import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import parseSenseProduct from "../../../../rest/sense/helpers/parseSenseProduct";
import SenseDevice from "../../../../rest/sense/model/SenseDevice";
import SenseDeviceReadings from "../../../../rest/sense/model/SenseDeviceReadings";
import DeviceLogo from "../../../../ui/assets/DeviceImages/DeviceLogo";
import BorderedBox from "../../../../ui/components/BorderedBox";
import ColoredText from "../../../../ui/components/ColoredText";
import ConfirmDialog from "../../../../ui/components/ConfirmDialog";
import NavigationRail, { RailLink } from "../../../../ui/components/NavigationRail";
import TabModel from "../../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import { ProCheckPageType } from "../../../procheck/ProCheckDetailsPage/ProCheckDetailsPage";
import RAGStatusPill from "../../../properties/PropertyDetailPage/components/RAGStatusPill";
import RAGSymbol from "../../../properties/PropertyListPage/components/RAGSymbol";
import getDeviceCardStatus from "../../helpers/getDeviceCardStatus";
import SelectedSenseDevice from "../../models/SelectedSenseDevice";
import SenseDeviceInfoCard from "./../components/SenseDeviceInfoCard";
import SenseDeviceInfoCardModel from "./../models/SenseDeviceInfoCardModel";
import SenseDeviceDetailsTab from "./tabs/SenseDeviceDetailsTab";
import SenseDeviceReadingsTab from "./tabs/SenseDeviceReadingsTab";
import MovePropertiesButton from "../../../properties/PropertyListPage/components/MovePropertiesHandler/MovePropertiesButton";
import SenseDeviceMoveHandler from "./SenseDeviceMoveHandler";

export type SenseDeviceTabType = "readings" | "details";

interface ExternalProps {
  pageType: ProCheckPageType; //Reusable
  device: SelectedSenseDevice;
  username?: string;
  isAdeyAdmin: boolean;
  statusChanging: boolean;
  errorMessage: string | undefined;
  //Handlers
  onDashboardClicked: () => void;
  onPropertyClicked: () => void;
  onDeviceActivationChanged: () => void;
  //Futureproofing for inevitable request for devices on user page
  onUserTabClicked: () => void;
  onUserClicked: () => void;
  //Admin actions as optional functions
  handleDeviceDisabled?: () => void;
}

type Props = ExternalProps & WithT & RouteComponentProps;

interface State {
  selectedTab: SenseDeviceTabType;
  activationDialogOpen: boolean;
}

class LoadedSenseDeviceDetailsPage extends React.Component<Props, State> {
  state = {
    selectedTab: "details" as SenseDeviceTabType,
    activationDialogOpen: false,
  };

  propertyType = parseSenseProduct(
    (this.props.device.device as SenseDevice).serial_number
  );

  infoCard: SenseDeviceInfoCardModel = {
    productType: this.propertyType,
    serviceDue: (this.props.device.device as SenseDevice).service_due_date,
    magnetiteLevel: (this.props.device.device as SenseDevice).magnetite_level,
    notifications: (this.props.device.device as SenseDevice).notifications,
    batteryLevel: (this.props.device.device as SenseDevice).battery_level,
  };

  deviceRag = getDeviceCardStatus(
    (this.props.device.device as SenseDevice).notifications,
    this.props.t
  );

  setActiveTab = (tab: string) => {
    this.setState({ ...this.state, selectedTab: tab as SenseDeviceTabType });
  };

  //Links
  constructLinks = () => {
    let links: RailLink[] = [];
    if (this.props.pageType === "property-page") {
      links = [
        {
          key: "properties",
          name: this.props.t("propertyDetailPage.propertyLink"),
          onClick: this.props.onDashboardClicked,
        },
        {
          key: "address",
          name: this.props.device.device?.property?.address_line_1 || "",
          onClick: this.props.onPropertyClicked,
        },
      ];
    } else if (this.props.pageType === "user-page") {
      links = [
        {
          key: "users",
          name: this.props.t("headerTabs.userList"),
          onClick: this.props.onUserTabClicked,
        },
        {
          key: "user",
          name: this.props.username || "",
          onClick: this.props.onUserClicked,
        },
      ];
    }
    return links;
  };

  renderTabs = () => {
    switch (this.state.selectedTab) {
      case "details":
        return <SenseDeviceDetailsTab device={this.props.device.device as SenseDevice} />;
      case "readings":
        return (
          <SenseDeviceReadingsTab
            readings={this.props.device.readings as SenseDeviceReadings[]}
          />
        );
    }
  };

  //Tabs
  buildTabs = (): TabModel[] => {
    return [
      {
        title: this.props.t("senseDetailsPageHeaders.details"), //Details
        navKey: "details" as SenseDeviceTabType,
      },
      {
        title: this.props.t("senseDetailsPageHeaders.readings"), //Readings
        navKey: "readings" as SenseDeviceTabType,
      },
    ];
  };

  //Handlers
  onDialogClose = () => {
    this.setState({ ...this.state, activationDialogOpen: false });
  };

  onDialogConfirm = () => {
    this.setState({ ...this.state, activationDialogOpen: false });
    this.props.onDeviceActivationChanged();
  };

  render() {
    //Strings
    const title = this.props.device.device?.serial_number || "-";

    //Dialog strings
    const dialogTitle = this.props.t("senseActivationConfirmationDialog.title");
    const dialogContent =
      this.props.device.device?.deactivated === 1
        ? this.props.t("senseActivationConfirmationDialog.contentActivate", {
            organisation_name:
              this.props.device.device?.organisation.name.toString() || "-",
          })
        : this.props.t("senseActivationConfirmationDialog.contentDeactivate", {
            organisation_name:
              this.props.device.device?.organisation.name.toString() || "-",
          });
    const dialogConfirm = this.props.t("senseActivationConfirmationDialog.confirm");
    const dialogBoxLabel = this.props.t("senseActivationConfirmationDialog.switchTitle");
    const dialogSwitchLabel =
      this.props.device.device?.deactivated === 1
        ? this.props.t("senseActivationConfirmationDialog.switchLabelDisabled")
        : this.props.t("senseActivationConfirmationDialog.switchLabelActive");

    return (
      <Box display="flex" flexDirection="column" paddingBottom={EXTRA_LARGE_SPACING}>
        <Box padding={DEFAULT_SPACING} display="flex" justifyContent="space-between">
          <NavigationRail currentPageTitle={title} links={this.constructLinks()} />
        </Box>
        <Box
          padding={SMALL_SPACING}
          paddingX={EXTRA_LARGE_SPACING}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <DeviceLogo deviceType={this.propertyType} height={60} />

          <Typography variant="h4">
            <strong>{this.props.device.device?.serial_number}</strong>
          </Typography>
          {this.deviceRag ? (
            <Box paddingX={1} display="flex" alignItems="center">
              <RAGSymbol status={this.deviceRag.ragStatus} />
            </Box>
          ) : undefined}
          {this.props.isAdeyAdmin && (
            <Box marginLeft={"auto"}>
              <SenseDeviceMoveHandler
                senseDevices={this.props.device.device ? [this.props.device.device] : []}
              />
            </Box>
          )}
        </Box>

        {this.props.isAdeyAdmin && (
          <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
            <PageHeaderTabs
              noPadding={true}
              tabs={this.buildTabs()}
              activeKey={this.state.selectedTab}
              tabClicked={this.setActiveTab}
            />
          </Box>
        )}

        <Box display="flex" flexDirection="row" justifyItems="space-around">
          <Box
            marginBottom={SMALL_SPACING}
            width={"65%"}
            paddingX={SMALL_SPACING}
            justifyContent="flex-start"
          >
            {this.renderTabs()}
          </Box>
          <Box width={"35%"} paddingX={SMALL_SPACING}>
            <BorderedBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              padding={SMALL_SPACING}
              marginBottom={SMALL_SPACING}
            >
              <ColoredText
                textColor={BACKGROUND_ACCENT_COLOR}
                variant="button"
                style={{ fontSize: "16px" }}
              >
                <strong>{this.props.t("senseDeviceInfoCard.totalStatus")}</strong>
              </ColoredText>
              <RAGStatusPill
                status={this.deviceRag.ragStatus}
                text={this.deviceRag.message}
              />
            </BorderedBox>
            <SenseDeviceInfoCard {...this.infoCard} />
            {this.props.isAdeyAdmin && (
              <BorderedBox
                display="flex"
                flexDirection="column"
                padding={SMALL_SPACING}
                marginTop={DEFAULT_SPACING}
                marginBottom={SMALL_SPACING}
              >
                <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
                  <strong>{dialogBoxLabel}</strong>
                </ColoredText>
                <FormControlLabel
                  style={{ marginLeft: DEFAULT_SPACING, marginTop: DEFAULT_SPACING }}
                  control={
                    <Switch
                      disabled={!this.props.isAdeyAdmin || this.props.statusChanging}
                      color="primary"
                      onChange={() =>
                        this.setState({ ...this.state, activationDialogOpen: true })
                      }
                    />
                  }
                  label={dialogSwitchLabel}
                  checked={this.props.device.device?.deactivated === 0}
                  color="primary"
                />
              </BorderedBox>
            )}
          </Box>
          <ConfirmDialog
            open={this.state.activationDialogOpen}
            onCancelClicked={this.onDialogClose}
            onConfirmed={this.onDialogConfirm}
            title={dialogTitle}
            body={dialogContent}
            confirmButton={dialogConfirm}
          />
        </Box>
      </Box>
    );
  }
}

export default withTranslation()(withRouter(LoadedSenseDeviceDetailsPage));
