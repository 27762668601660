import { AxiosInstance } from "axios";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import CountryRepository from "./CountryRepository";
import { Country, CountryForm } from "./model/Country";

class RestCountryRepository implements CountryRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  fetchCountryList = (isAdmin: boolean): Promise<Country[]> => {
    const endpoitPrefix = isAdmin ? "/admin" : "";
    const endpoint = `${endpoitPrefix}/countries`;

    return this.axios.get<DataWrapper<Country[]>>(endpoint).then(unwrapResponse);
  };

  fetchCountry = (isAdmin: boolean): Promise<Country> => {
    const endpoitPrefix = isAdmin ? "/admin" : "";
    const endpoint = `${endpoitPrefix}/countries`;

    return this.axios.get<DataWrapper<Country>>(endpoint).then(unwrapResponse);
  };

  adminCreateCountry = (country: CountryForm): Promise<Country> => {
    const endpoint = "admin/countries";

    const params = new URLSearchParams();
    params.append("name", country.name);
    params.append("code", country.code);

    return this.axios.post<DataWrapper<Country>>(endpoint, params).then(unwrapResponse);
  };

  adminUpdateCountry = (country: CountryForm, countryId: string): Promise<Country> => {
    const endpoint = `admin/countries/${countryId}`;

    const params = new URLSearchParams();
    params.append("name", country.name);
    params.append("code", country.code);

    return this.axios.put<DataWrapper<Country>>(endpoint, params).then(unwrapResponse);
  };
}

export default RestCountryRepository;
