import { takeEvery } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import getDependency from "../../../redux/utils/getDependency";
import {
  LoginStateChangedAction,
  LOGIN_STATUS_CHANGED_TYPE,
} from "../../auth/Login/redux/LoginAction";
import AuthService from "../../auth/Login/service/AuthService";
import { adminAccessChanged } from "./AdeyAdminAction";

function* adeyAdminSagas() {
  yield takeEvery(LOGIN_STATUS_CHANGED_TYPE, loginChangedSaga);
}

function* loginChangedSaga(action: LoginStateChangedAction) {
  try {
    if (action.loginStatus === "logged_in") {
      const authService: AuthService = yield getDependency("authService");
      const isAdeyAdmin: boolean = yield authService.isAdeyAdmin();
      yield put(adminAccessChanged(isAdeyAdmin));
    } else {
      // default to no access
      yield put(adminAccessChanged(false));
    }
  } catch (e) {
    // Default to no access
    yield put(adminAccessChanged(false));
  }
}

export default adeyAdminSagas;
