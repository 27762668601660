// Params
const propertyIdParam = ":propertyId";
const userIdParam = ":userId";
const organisationIdParam = ":organisationId";
const procheckIdParam = ":procheckId";
const deviceIdParam = ":deviceId";

//Public routes
export const TERMS_AND_CONDITION_ROUTE = "/terms-and-conditions";

export const COOKIES_POLICY_EN_ROUTE = "/cookies-policy";

export const PRIVACY_POLICY_EN_ROUTE = "/privacy-policy";

// Routes
export const HOMEZONE_UNSUBSCRIBE_ROUTE = `/homezone-unsubscribe/${propertyIdParam}`;
export const LOGIN_ROUTE = "/sign-in";
export const REGISTRATION_ROUTE = "/register";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const FORGOT_PASSWORD_SUBMIT_ROUTE = "/forgot-password-submit";
export const DASHBOARD_ROUTE = "/dashboard";
export const QUESTION_ONE_ROUTE = "/question-one";
export const QUESTION_TWO_ROUTE = "/question-two";
export const ACCOUNT_SETTINGS_ROUTE = "/account-settings";
export const PROPERTY_DETAIL_ROUTE = `/dashboard/${organisationIdParam}/${propertyIdParam}`;
export const ORGANISATION_PROPERTY_DETAIL_ROUTE = `/all-organisations/${organisationIdParam}/${propertyIdParam}`;
export const PROCHECK_ROUTE = `${PROPERTY_DETAIL_ROUTE}/procheck`;
export const PROCHECK_RESULT_ROUTE = `${PROPERTY_DETAIL_ROUTE}/procheck/${procheckIdParam}`;
export const SENSE_DEVICE_ROUTE = `${PROPERTY_DETAIL_ROUTE}/device/${deviceIdParam}`;
export const ACCOUNT_CREATED_ROUTE = "/account-created";
export const ACCOUNT_ACTIVATION_ROUTE = "/activation";
export const FORGOT_PASSWORD_EMAIL_SENT_ROUTE = "/forgot-password-sent";
export const RESEND_ACTIVATION_EMAIL_ROUTE = "/account-activation";
export const USER_LIST_ROUTE = "/users";
export const USER_DETAIL_ROUTE = `${USER_LIST_ROUTE}/${organisationIdParam}/${userIdParam}`;
export const PROCHECK_USER_RESULTS_ROUTE = `${USER_DETAIL_ROUTE}/procheck/${propertyIdParam}/${procheckIdParam}`;
export const SENSE_DEVICE_USER_ROUTE = `${PROPERTY_DETAIL_ROUTE}/device/${deviceIdParam}/${userIdParam}`;

export const ORGANISATION_DETAIL_ROUTE = `/organisations/${organisationIdParam}`;
export const CURRENT_ORG_ROUTE = "/current-organisation";
export const ORGANISATION_LIST_ROUTE = "/organisations";
export const REPORTING_ROUTE = "/reports";
export const PROCHECK_REPORTING_ROUTE = `${REPORTING_ROUTE}/procheck/${propertyIdParam}/${procheckIdParam}`;
export const PLATFORM_USER_LIST_ROUTE = "/admin/users";
export const PLATFORM_USER_DETAIL_ROUTE = `/admin/users/${userIdParam}`;
export const PLATFORM_ORGANISATION_DETAIL_ROUTE = `/all-organisations/${organisationIdParam}`;
export const ADMIN_ORG_PROCHECK_REPORTING_ROUTE = `${PLATFORM_ORGANISATION_DETAIL_ROUTE}/procheck/${propertyIdParam}/${procheckIdParam}`;
export const PLATFORM_ORGANISATIONS_LIST_ROUTE = "/all-organisations";
export const PLATFORM_AGENT_DETAILS_ROUTE = `/agents/${organisationIdParam}`;

export const PLATFORM_AGENT_LIST_ROUTE = "/agents";

// Route Params
export const ACCOUNT_ACTIVATION_TOKEN_PARAM = "token";
export const ACCOUNT_ACTIVATION_USERNAME_PARAM = "username";
export const RESET_PASSWORD_TOKEN_PARAM = "code";
export const RESET_PASSWORD_USERNAME_PARAM = "username";

// Builders

export const buildPropertyDetailRoute = (organisationId: string, propertyId: string) => {
  return PROPERTY_DETAIL_ROUTE.replace(organisationIdParam, organisationId).replace(
    propertyIdParam,
    propertyId
  );
};

export const buildProcheckReportDetailRoute = (
  organisationId: string,
  propertyId: string,
  procheckId: string
) => {
  return PROCHECK_RESULT_ROUTE.replace(organisationIdParam, organisationId)
    .replace(propertyIdParam, propertyId)
    .replace(procheckIdParam, procheckId);
};

export const buildSenseDeviceDetailRoute = (
  organisationId: string,
  propertyId: string,
  deviceId: string
) => {
  return SENSE_DEVICE_ROUTE.replace(organisationIdParam, organisationId)
    .replace(propertyIdParam, propertyId)
    .replace(deviceIdParam, deviceId);
};

export const buildSenseDeviceUserDetailRoute = (
  organisationId: string,
  propertyId: string,
  deviceId: string,
  userId: string
) => {
  return SENSE_DEVICE_USER_ROUTE.replace(organisationIdParam, organisationId)
    .replace(propertyIdParam, propertyId)
    .replace(deviceIdParam, deviceId)
    .replace(userIdParam, userId);
};

export const buildUserProcheckReportDetailRoute = (
  organisationId: string,
  procheckId: string,
  userId: string,
  propertyId: string
) => {
  return PROCHECK_USER_RESULTS_ROUTE.replace(organisationIdParam, organisationId)
    .replace(userIdParam, userId)
    .replace(propertyIdParam, propertyId)
    .replace(procheckIdParam, procheckId);
};

export const buildOrgProcheckReportDetailRoute = (
  propertyId: string,
  procheckId: string
) => {
  return PROCHECK_REPORTING_ROUTE.replace(propertyIdParam, propertyId).replace(
    procheckIdParam,
    procheckId
  );
};

export const buildAdminOrgProcheckReportDetailRoute = (
  propertyId: string,
  procheckId: string,
  orgId: string
) => {
  return ADMIN_ORG_PROCHECK_REPORTING_ROUTE.replace(organisationIdParam, orgId)
    .replace(propertyIdParam, propertyId)
    .replace(procheckIdParam, procheckId);
};

export const buildOrganisationPropertyDetailRoute = (
  organisationId: string,
  propertyId: string
) => {
  return ORGANISATION_PROPERTY_DETAIL_ROUTE.replace(
    organisationIdParam,
    organisationId
  ).replace(propertyIdParam, propertyId);
};

export const buildProCheckRoute = (organisationId: string, propertyId: string) => {
  return PROCHECK_ROUTE.replace(organisationIdParam, organisationId).replace(
    propertyIdParam,
    propertyId
  );
};

export const buildUserDetailRoute = (organisationId: string, userId: string) => {
  return USER_DETAIL_ROUTE.replace(userIdParam, userId).replace(
    organisationIdParam,
    organisationId
  );
};

export const buildOrgDetailsRoute = (organisationID: string) => {
  return ORGANISATION_DETAIL_ROUTE.replace(organisationIdParam, organisationID);
};

export const buildPlatformOrgDetailsRoute = (organisationID: string) => {
  return PLATFORM_ORGANISATION_DETAIL_ROUTE.replace(organisationIdParam, organisationID);
};

export const buildPlatformAgentDetailRoute = (agentID: string) => {
  return PLATFORM_AGENT_DETAILS_ROUTE.replace(organisationIdParam, agentID);
};

export const buildPlatformUserDetailRoute = (userId: string) => {
  return PLATFORM_USER_DETAIL_ROUTE.replace(userIdParam, userId);
};

export const buildHomezoneUnsubscribeRoute = (propertyId: string) => {
  return HOMEZONE_UNSUBSCRIBE_ROUTE.replace(propertyIdParam, propertyId);
};
