import { Box, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

type Props = WithTranslation;

const AccountActivationErrorCard = (props: Props) => {
  const title = props.t("accountActivationPage.title");
  const message = props.t("accountActivationPage.errorMessage");

  return (
    <CenteredAuthCard title={title}>
      <Box marginTop={DEFAULT_SPACING}>
        <Typography align="center">{message}</Typography>
      </Box>
    </CenteredAuthCard>
  );
};

export default withTranslation()(AccountActivationErrorCard);
