import RAGStatus from "../../../pages/properties/PropertyListPage/model/RAGStatus";
import { StatusPass } from "../model/Property";

export const convertRAGToStatus = (rag: RAGStatus): StatusPass => {
  switch (rag) {
    case "red":
      return "fail";

    case "amber":
      return "warning";

    case "green":
      return "pass";
  }
};

export const convertStatusToRAG = (status: StatusPass): RAGStatus => {
  switch (status) {
    case "fail":
      return "red";

    case "warning":
      return "amber";

    case "pass":
      return "green";
  }
};

export const convertPriorityToRAG = (priority: number): RAGStatus => {
  let ragStatus: RAGStatus = "green";

  switch (priority) {
    case 1:
      ragStatus = "red";
      break;
    case 2:
      ragStatus = "amber";
      break;
    case 3:
      ragStatus = "green";
      break;
  }

  return ragStatus;
};
