import {
  Box,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { DEFAULT_SPACING, EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { ClearAll } from "@material-ui/icons";
import ColoredText from "../../../../ui/components/ColoredText";
import {
  BACKGROUND_ACCENT_COLOR,
  SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR_DARK,
} from "../../../../ui/theme/createMaterialTheme";

interface IProps {
  showSelected: boolean;
  selectedCount: number;
  onClearPressed: () => void;
  switchShowItems: () => void;
}

const SelectedPropertiesPanel = (props: IProps) => {
  //Hooks
  const { t } = useTranslation();

  //String
  const switchLabel = t("selectedPropertiesPanel.switchLabel");
  const countDescription = t("selectedPropertiesPanel.countDescription", {
    count: props.selectedCount,
  });
  const buttonLabel = t("buttons.clear");

  const StyledAlert = withStyles({
    root: {
      display: "flex",
      flexDireaction: "row",
      "& .MuiAlert-message": {
        width: "100%",
      },
    },
  })(Alert);

  return (
    <StyledAlert severity="info" icon={<></>}>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <FormControlLabel
          style={{ marginLeft: DEFAULT_SPACING }}
          control={<Switch onChange={props.switchShowItems} color="secondary" />}
          label={<Typography variant="body1">{switchLabel}</Typography>}
          checked={props.showSelected}
          color="secondary"
        />{" "}
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: EXTRA_LARGE_SPACING }}
        />
        <ColoredText
          variant="button"
          textColor={SECONDARY_COLOR}
          style={{ marginRight: "auto", flex: 1 }}
        >
          <strong>{countDescription}</strong>
        </ColoredText>
        <SecondaryButton
          label={buttonLabel}
          endIcon={<ClearAll />}
          onClick={props.onClearPressed}
        />
      </Box>
    </StyledAlert>
  );
};

export default SelectedPropertiesPanel;
