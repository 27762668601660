import MetricsResponse from "../../../../rest/metrics/model/MetricsResponse";
import { DashboardMetrics } from "../redux/DashboardState";

const calculateDashboardMetrics = (response: MetricsResponse): DashboardMetrics => {
  const redPercentage = calculatePercentage(
    response.total_bad_properties,
    response.total_properties
  );

  const amberPercentage = calculatePercentage(
    response.total_warning_properties,
    response.total_properties
  );

  const greenPercentage = calculatePercentage(
    response.total_good_properties,
    response.total_properties
  );

  return {
    totalProperties: response.total_properties,

    redCount: response.total_bad_properties,
    redPercentage: redPercentage,

    amberCount: response.total_warning_properties,
    amberPercentage: amberPercentage,

    greenCount: response.total_good_properties,
    greenPercentage: greenPercentage,
  };
};

const calculatePercentage = (num: number, total: number): number => {
  if (total === 0) {
    return 0;
  } else {
    return (100 * num) / total;
  }
};

export default calculateDashboardMetrics;
