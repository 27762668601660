import { Popover } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { logOut } from "../../../pages/auth/Login/redux/LoginAction";
import RootState from "../../../redux/RootState";
import MemberOrganisation from "../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../rest/organisation/model/Organisation";
import { DASHBOARD_ROUTE } from "../../../routes/routes";
import OrganisationSwitcherButton from "./components/OrganisationSwitcherButton";
import OrganisationSwitcherPopoverContent from "./components/OrganisationSwitcherPopoverContent";
import { switchOrganisationChanged } from "./redux/ActiveOrganisationActions";
import areSameOrganisation from "./utils/areSameOrganisation";

interface ReduxStateProps {
  activeOrganisation: MemberOrganisation | undefined;
  organisationList: MemberOrganisation[];
  switchingToOrganisation: MemberOrganisation | undefined;
  canCreateOrganisation: boolean;
}

interface ReduxDispatchProps {
  signOut: () => void;
  switchOrganisation: (organisation: MemberOrganisation) => void;
}

interface ExternalProps {
  onAccountSettingsClicked: () => void;
  onDetailsClicked: (organisation: Organisation | undefined) => void;
  onCreateOrganisationClicked: () => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & ExternalProps & RouteComponentProps;

interface State {
  switcherOpen: boolean;
  anchorElement: Element | undefined;
}

class OrganisationSwitcher extends React.Component<Props, State> {
  state = {
    switcherOpen: false,
    anchorElement: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.activeOrganisation &&
      !areSameOrganisation(prevProps.activeOrganisation, this.props.activeOrganisation)
    ) {
      // If we have switched organisation, move to dashboard
      this.props.history.replace(DASHBOARD_ROUTE);
      this.setState({ switcherOpen: false });
    }
  }

  render() {
    return [
      <OrganisationSwitcherButton
        selectedOrganisation={this.props.activeOrganisation}
        key="button"
        onClick={this.handleOpenPopover}
      />,

      <Popover
        key="popover"
        open={this.state.switcherOpen}
        onClose={this.handleClosePopover}
        anchorEl={this.state.anchorElement}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <OrganisationSwitcherPopoverContent
          canCreateOrganisation={this.canCreateOrganisationCheck()}
          onCreateOrganisationClicked={this.handleCreateOrganisationClicked}
          selectedOrganisation={this.props.activeOrganisation}
          availableOrganisations={this.props.organisationList}
          switchingToOrganisation={this.props.switchingToOrganisation}
          onLogoutClicked={this.props.signOut}
          onAccountSettingsClicked={this.handleAccountSettingsClicked}
          onOrganisationClicked={this.handleOrganisationClicked}
          onDetailsClicked={this.handleDetailsClicked}
        />
      </Popover>,
    ];
  }

  // MARK: Handlers

  handleOpenPopover = (e: React.MouseEvent<Element>) => {
    // Keep a ref to the target so we can match the popover to it.
    this.setState({ switcherOpen: true, anchorElement: e.currentTarget });
  };

  handleClosePopover = () => {
    this.setState({ switcherOpen: false });
  };

  handleAccountSettingsClicked = () => {
    this.handleClosePopover();
    this.props.onAccountSettingsClicked();
  };

  handleOrganisationClicked = (organisation: MemberOrganisation) => {
    // Only raise an action if the org has changed
    if (!areSameOrganisation(organisation, this.props.activeOrganisation)) {
      this.props.switchOrganisation(organisation);
    }
  };

  handleDetailsClicked = (organisation: Organisation | undefined) => {
    this.handleClosePopover();
    this.props.onDetailsClicked(organisation);
  };

  handleCreateOrganisationClicked = () => {
    this.props.onCreateOrganisationClicked();
  };

  canCreateOrganisationCheck = (): boolean => {
    let canCreateCheck = true;
    this.props.organisationList.forEach((org) => {
      if (org.role.name === "owner" || org.role.name === "developer") {
        canCreateCheck = false;
      }
    });
    return canCreateCheck;
  };
}

const mapStateToProps = (rootState: RootState): ReduxStateProps => ({
  activeOrganisation: rootState.activeOrganisation.currentOrganisation,
  organisationList: rootState.activeOrganisation.organisationList,
  switchingToOrganisation: rootState.activeOrganisation.switchingToOrganisation,
  canCreateOrganisation: rootState.adeyAdmin.hasAdminAccess,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  signOut: () => dispatch(logOut()),
  switchOrganisation: (organisation: MemberOrganisation) =>
    dispatch(switchOrganisationChanged(organisation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganisationSwitcher));
