const translations = {
  translations: {
    ProCheckSummary: {
      colourTestMissing:
        "Water sample failed visual inspection, presence of corrosion detected, full system clean is advised. ADEY MagnaCleanse - Features and Benefits on https://youtu.be/vS92E-Jq1uY",
      molyPassRec: "Inhibitor level ok system is protected, no further action required",
      molyLowRec:
        "Insufficient Inhibitor detected top up required. Use Adey MC1+ (or equivalent). Ensure to circulate for 30 minutes in the system and test again.",
      ironHighRecCopperPass:
        "Iron level is high. If left untreated it can cause performance deterioration and, ultimately, failure. Full system clean is advised.",
      ironHighRec:
        "Corrosion level is high, full system clean is advised. \n ADEY MagnaCleanse - Features and Benefits on https://youtu.be/vS92E-Jq1uY",
      ironModerateRec:
        "Corrosion level is moderate, no further action required at this stage",
      ironPassRec: "Corrosion level is low, no further action required",
      ironModerateCopperPresentRec:
        "Iron level is moderate. No further action required at this stage.",
      ironPassCopperPresentRec: "Iron level is low. No further action required",
      phPassRec: "pH level ok - \nno further action required",
      phLowRec:
        "pH level is low indicating poor system health, full system clean is advised. \n ADEY MagnaCleanse - Features and Benefits on https://youtu.be/vS92E-Jq1uY",
      phLowLowMoly:
        "pH level is low indicating poor system health, full system clean is advised \n Note: If 'Insufficient Inhibitor' also detected, pH may adjust within range following an inhibitor top up. Ensure to circulate for 30 minutes in the system and test again",
      phHighRec:
        "pH level is high indicating poor system health, full system clean is advised. \n ADEY MagnaCleanse - Features and Benefits on https://youtu.be/vS92E-Jq1uY",
      phHighRecLowMoly:
        "pH level is high indicating poor system health, full system clean is advised. \n Note: If 'Insufficient Inhibitor' also detected, pH may adjust within range following an inhibitor top up. Ensure to circulate for 30 minutes in the system and test again",
      copperHighRec:
        "Copper level is high. If left untreated it can cause pitting corrosion of aluminum and steel. Full system clean is advised. ",
      copper_pass: "Copper level ok",
      copper_low:
        "Copper level is very low indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
    },
  },
};

export default translations;
