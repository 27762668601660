import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import OutlineButton from "../../../ui/components/OutlineButton";
import SearchBar from "../../../ui/components/SearchBar";
import Spinner from "../../../ui/components/Spinner";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../../ui/theme/dimensions";
import InviteDialog from "../../invites/InviteDialog/InviteDialog";
import { loadOrganisations } from "./redux/OrganisationListAction";
import OrganisationListState from "./redux/OrganisationListState";
import Organisation from "../../../rest/organisation/model/Organisation";
import { buildOrgDetailsRoute } from "../../../routes/routes";
import { RouteComponentProps, withRouter } from "react-router";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import InviteTable from "../../../ui/components/InviteTable/InviteTable";
import EmptyStateDisplay from "../../../ui/components/EmptyStateDisplay";
import OrganisationTable from "../../../ui/components/OrganisationTable/OrganisationTable";
import { WithT } from "i18next";
import { withTranslation } from "react-i18next";
import TableHeader from "../../../ui/components/TableList/components/TableHeader";
import inviteMatchesSearchText from "../../../rest/invites/utils/inviteMatchesSearchText";
import organisationMatchesSearchText from "../../../rest/organisation/utils/organisationMatchesSearchText";

type OrganisationListPageTab = "contractors" | "associated-organisations" | "invites";

//MARK: Props
interface ReduxStateProps extends OrganisationListState {
  receivedOrganisationInvite: PendingInvite | undefined;
}

interface ReduxDispatchProps {
  loadOrganisations: () => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & RouteComponentProps & WithT;

//MARK: State
interface State {
  addDialogOpen: boolean;
  currentTab: OrganisationListPageTab;
  searchText: string;
}

class OrganisationListPage extends React.Component<Props, State> {
  state = {
    addDialogOpen: false,
    currentTab: "contractors" as OrganisationListPageTab,
    searchText: "",
  };

  render() {
    const searchPlaceholder = this.props.t("organisationListPage.searchPlaceholder");
    const addButton = this.props.t("organisationListPage.addButton");

    return (
      <DashboardLayout>
        <Box>
          <Box paddingY={1}>
            <PageHeaderTabs
              tabs={this.buildTabs()}
              activeKey={this.state.currentTab}
              tabClicked={this.handleTabSelected}
            />
          </Box>

          <Box display="flex" flexDirection="row" paddingX={DEFAULT_SPACING}>
            <Box display="flex" flexGrow={1} marginRight={SMALL_SPACING}>
              <SearchBar
                placeholder={searchPlaceholder}
                value={this.state.searchText}
                onChange={this.onSearchTextChanged}
              />
            </Box>

            {this.state.currentTab === "contractors" ? (
              <OutlineButton
                label={addButton}
                endIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={this.openDialog}
                filled
              />
            ) : null}
          </Box>

          <Box paddingX={DEFAULT_SPACING} paddingY={SMALL_SPACING}>
            <Box height={EXTRA_LARGE_SPACING} />

            <TableHeader
              text={this.getTableHeaderText()}
              tooltip={this.getTableTooltipText()}
            />

            {this.renderContent()}
          </Box>

          <InviteDialog
            pageType="contractor"
            open={this.state.addDialogOpen}
            onClose={this.onDialogClose}
            warningPopup
          />
        </Box>
      </DashboardLayout>
    );
  }

  renderContent = () => {
    if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else if (this.props.isLoading) {
      return <Spinner />;
    } else {
      return this.renderTable();
    }
  };

  renderTable = () => {
    const searchText = this.state.searchText;
    const invites = this.props.invites.filter((i) =>
      inviteMatchesSearchText(i, searchText)
    );
    const contractors = this.props.contractors.filter((o) =>
      organisationMatchesSearchText(o, searchText)
    );
    const associatedOrgs = this.props.associatedOrgs.filter((o) =>
      organisationMatchesSearchText(o, searchText)
    );

    if (this.state.currentTab === "invites") {
      if (invites.length === 0) {
        return <EmptyStateDisplay message="organisationListPage.emptyInviteMessage" />;
      }

      return <InviteTable invites={invites} />;
    }

    const orgs = this.state.currentTab === "contractors" ? contractors : associatedOrgs;

    if (orgs.length === 0) {
      return <EmptyStateDisplay message="organisationListPage.emptyMessage" />;
    } else {
      return (
        <OrganisationTable
          organisations={orgs}
          onOrgClicked={this.handleOrganisationClicked}
        />
      );
    }
  };

  onSearchTextChanged = (newText: string) => {
    this.setState({ searchText: newText });
  };

  buildTabs = () => {
    return [
      {
        title: "organisationListPage.contractorTab",
        navKey: "contractors",
      },
      {
        title: "organisationListPage.associatedOrgsTab",
        navKey: "associated-organisations",
      },
      {
        title: "organisationListPage.invitesTab",
        navKey: "invites",
      },
    ];
  };

  getTableHeaderText = () => {
    switch (this.state.currentTab) {
      case "associated-organisations":
        return this.props.t("organisationListPage.associatedOrgsTab");

      case "contractors":
        return this.props.t("organisationListPage.contractorTab");

      case "invites":
        return this.props.t("organisationListPage.invitesTab");
    }
  };

  getTableTooltipText = () => {
    switch (this.state.currentTab) {
      case "associated-organisations":
        return this.props.t("organisationListPage.associatesTooltip");

      case "contractors":
        return this.props.t("organisationListPage.contractorTooltip");

      case "invites":
        return this.props.t("organisationListPage.inviteTooltip");

      default:
        return undefined;
    }
  };

  handleTabSelected = (tab: string) => {
    this.setState({ currentTab: tab as OrganisationListPageTab });
  };

  openDialog = () => {
    this.setState({ addDialogOpen: true });
  };

  handleOrganisationClicked = (org: Organisation) => {
    const route = buildOrgDetailsRoute(org.id);
    this.props.history.push(route);
  };

  onDialogClose = () => {
    this.setState({
      addDialogOpen: false,
    });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    ...state.orgList,
    receivedOrganisationInvite: state.inviteEntity.receivedInvite,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    loadOrganisations: () => dispatch(loadOrganisations()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OrganisationListPage)));
