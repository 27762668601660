import { Box, Chip, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import AdminRemoveOrgConnectionButton from "../../../../pages/admin/AdminOrganisationDetailsPage/components/AdminRemoveOrgConnectionButton";
import RemoveManagedOrganisationButton from "../../../../pages/admin/PlatformAgentDetailsPage/components/RemoveManagedOrganisationButton/RemoveManagedOrganisationButton";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { DEFAULT_SPACING } from "../../../theme/dimensions";

export type LinkedOrgType = "contractor" | "contractee";

interface Props {
  org: Organisation;
  smallCellWidth: string;
  key: string;

  onClick?: (org: Organisation) => void;
  onRemoveClick?: (org: Organisation) => void;

  linkedOrg?: Organisation;
  linkType?: LinkedOrgType;

  agentOrg?: Organisation;
}

const OrgTableRow = (props: Props) => {
  const org = props.org;

  const smallCellStyle = { width: props.smallCellWidth };
  const clickable = props.onClick !== undefined;
  const rowStyle = clickable ? { cursor: "pointer" } : undefined;
  const actionCellStyle = { width: "80px" };

  const handleClick = () => {
    if (clickable) {
      props.onClick?.(props.org);
    }
  };

  //Handles rendering remove button.
  // If 'agentOrg' parameter is sepcified, display remove button for unassigning managed organisation
  // If 'linkedOrg' and 'linkType' are specified, show buttton for unassigning contractee/contractors
  const renderButton = () => {
    if (props.onRemoveClick) {
      if (props.linkedOrg && props.linkType) {
        return (
          <TableCell style={actionCellStyle}>
            <AdminRemoveOrgConnectionButton
              organisation={props.org}
              removeContractorConfirmed={props.onRemoveClick}
              linkedOrg={props.linkedOrg}
              linkType={props.linkType}
            />
          </TableCell>
        );
      } else if (props.agentOrg) {
        return (
          <TableCell style={actionCellStyle}>
            <RemoveManagedOrganisationButton
              organisation={props.org}
              removeManagedOrgConfirmed={props.onRemoveClick}
              agentOrg={props.agentOrg}
            />
          </TableCell>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <TableRow key={org.id} hover={clickable} style={rowStyle} onClick={handleClick}>
      <TableCell>
        <Box display="flex" flexDirection="row">
          {org.is_agent_for?.id ? (
            <Chip
              size="small"
              color="primary"
              label="ADEY Agent"
              style={{ marginRight: DEFAULT_SPACING }}
            />
          ) : null}
          <Typography>{org.name}</Typography>
        </Box>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>
          {org.metrics ? (org.is_agent_for ? "-" : org.metrics.total_properties) : ""}
        </Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>{org.phone_number}</Typography>
      </TableCell>

      {renderButton()}
    </TableRow>
  );
};

export default OrgTableRow;
