import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";

interface ExternalProps {
  showResetDialog: boolean;
  proClubUser: boolean;
  handleResetClicked: () => void;
}

type Props = ExternalProps & WithT;

const PasswordResetRequiredDialog = (props: Props) => {
  //Translated Strings
  const title = props.t("passwordResetRequired.resetRequiredTitel").toUpperCase();
  const message = props.proClubUser
    ? props.t("passwordResetRequired.resetRequiredProClubMessage")
    : props.t("passwordResetRequired.resetRequiredMessage");
  const resetButtonLabel = props.t("forgotPasswordSubmit.resetButton");

  const [showDialogState, setShowDialogState] = useState<boolean>(false);

  const prevDialogProps = useRef<boolean>(props.showResetDialog);

  useEffect(() => {
    setShowDialogState((prevState) => {
      if (!!props.showResetDialog && prevDialogProps.current !== true) {
        prevDialogProps.current = true;
        return true;
      } else {
        prevDialogProps.current = false;
        return false;
      }
    });
  }, [props.showResetDialog]);

  return (
    <Dialog open={showDialogState} onClose={() => setShowDialogState(false)}>
      <Box padding={DEFAULT_SPACING}>
        <Grid container direction="column" spacing={SMALL_SPACING} alignContent="center">
          <Grid item>
            <Typography variant="h2" gutterBottom paragraph align="center">
              {title}
            </Typography>
          </Grid>

          <Grid item />

          <Grid item>
            <Typography gutterBottom align="center">
              {message}
            </Typography>
          </Grid>

          <Grid item />

          <Grid item>
            <PrimaryButton
              fullWidth
              label={resetButtonLabel}
              onClick={() => {
                setShowDialogState(false);
                props.handleResetClicked();
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(PasswordResetRequiredDialog);
