/// The error format for backend errors.
/// We can't rely on this, so all properties
/// are optional, apart from `isRestError` which is

import { AxiosError } from "axios";

/// used to do a type check.
interface RestError {
  statusCode?: number;
  errors?: Record<string, string[]>;
  request?: {
    method?: string;
    endpoint?: string;
  };
  isRestError: true;
}

export const createRestError = (error: AxiosError): RestError => {
  const body = error.response?.data;

  return {
    statusCode: error.response?.status,
    errors: body?.errors,
    request: {
      method: error.config?.method,
      endpoint: error.config?.url,
    },
    isRestError: true,
  };
};

export default RestError;
