import OverdueStatus from "../model/OverdueStatus";
import RAGStatus from "../model/RAGStatus";
import PropertyListAction from "./PropertyListActions";
import PropertyListState from "./PropertyListState";

const initialState: PropertyListState = {
  selectedStatusFilters: new Set<RAGStatus>(),
  selectedOverdueFilters: new Set<OverdueStatus>(),
  selectedDeviceFilters: undefined,
  propertyList: undefined,
  currentPage: 1,
  maxPages: 1,
  errorMessage: undefined,
  searchText: "",
  addingProperty: false,
  isPropertyAdded: undefined,
  addPropertyError: undefined,
  page: "dashboard",
  organisationId: undefined,
};

const propertyListReducer = (
  state: PropertyListState = initialState,
  action: PropertyListAction
): PropertyListState => {
  switch (action.type) {
    case "SELECTED_RAG_STATUS_CHANGED":
      return {
        ...state,
        selectedStatusFilters: action.selectedStatuses,
        propertyList: undefined,
        // Go back to the first page
        maxPages: 1,
        currentPage: 1,
      };

    case "SELECTED_OVERDUE_STATUS_CHANGED":
      return {
        ...state,
        selectedOverdueFilters: action.selectedStatuses,
        propertyList: undefined,
        // Go back to the first page
        maxPages: 1,
        currentPage: 1,
      };

    case "SELECTED_DEVICE_STATUS_CHANGED":
      return {
        ...state,
        selectedDeviceFilters: action.deviceType
          ? [action.deviceType, action.selectedStatuses]
          : undefined,
        propertyList: undefined,
        // Go back to the first page
        maxPages: 1,
        currentPage: 1,
      };

    case "PROPERTY_LIST_LOADED":
      return {
        page: state.page,
        selectedStatusFilters: state.selectedStatusFilters,
        selectedOverdueFilters: state.selectedOverdueFilters,
        selectedDeviceFilters: state.selectedDeviceFilters,
        propertyList: action.list,
        maxPages: action.maxPages,
        currentPage: action.currentPage,
        errorMessage: undefined,
        searchText: state.searchText,
        addingProperty: false,
        isPropertyAdded: undefined,
        addPropertyError: undefined,
        organisationId: action.organisationId,
      };

    case "PAGE_CHANGED":
      return {
        ...state,
        propertyList: undefined,
        currentPage: Math.min(action.currentPage, state.maxPages),
        errorMessage: undefined,
      };

    case "PROPERTY_LIST_ERROR":
      return {
        ...state,
        propertyList: state.propertyList || [],
        errorMessage: action.message,
      };

    case "SEARCH_TEXT_CHANGED":
      return {
        page: state.page,
        searchText: action.text,
        selectedStatusFilters: state.selectedStatusFilters,
        selectedOverdueFilters: state.selectedOverdueFilters,
        selectedDeviceFilters: state.selectedDeviceFilters,
        currentPage: 1,
        maxPages: 1,
        errorMessage: undefined,
        propertyList: undefined,
        addingProperty: false,
        isPropertyAdded: undefined,
        addPropertyError: undefined,
        organisationId: state.organisationId,
      };

    case "ADD_PROPERTY":
      return {
        ...state,
        addingProperty: true,
        isPropertyAdded: undefined,
      };

    case "ADD_PROPERTY_ERROR":
      return {
        ...state,
        addingProperty: false,
        addPropertyError: action.message,
      };

    case "PROPERTY_ADDED":
      return {
        ...state,
        propertyList: undefined,
        addingProperty: false,
        isPropertyAdded: true,
      };
    case "PAGE_TYPE_CHANGED":
      return {
        ...state,
        page: action.page,
        errorMessage: undefined,
      };
    case "LIST_RESET":
      return {
        ...state,
        errorMessage: undefined,
        propertyList: undefined,
      };
    default:
      return state;
  }
};

export default propertyListReducer;
