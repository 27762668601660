import { createMuiTheme } from "@material-ui/core";
import { BORDER_RADIUS } from "./dimensions";

export const PRIMARY_COLOR = "#D50032";
export const SECONDARY_COLOR = "#485CC7";
export const BACKGROUND_ACCENT_COLOR = "#280071";
export const PAGE_BACKGROUND_COLOR = "#F4F6F8";
export const SECONDARY_TEXT_COLOR = "#ACACAC";
export const SECONDARY_TEXT_COLOR_DARK = "#858585";
export const BORDER_COLOR = "rgba(0, 0, 0, 0.12)";
export const SELECTABLE_ITEM_COLOR = "#F4F2F8";
export const SELECTABLE_ITEM_HOVER_COLOR = "#E0DEE4";

//Rag colours
export const RAG_GREEN = "#069247";
export const RAG_AMBER = "#FF7F00";
export const RAG_RED = "#FF0000";

const createMaterialTheme = () => {
  return createMuiTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },

      secondary: {
        main: SECONDARY_COLOR,
      },

      background: {
        default: PAGE_BACKGROUND_COLOR,
      },

      text: {
        secondary: SECONDARY_TEXT_COLOR,
      },
    },

    typography: {
      fontFamily: "Weissenhof Grotesk",
      h1: {
        fontSize: 45,
      },

      h2: {
        color: PRIMARY_COLOR,
        fontSize: 28,
      },

      body2: {
        fontSize: 18,
      },
    },

    props: {
      MuiSnackbar: {
        autoHideDuration: 4000,
      },
    },

    shape: {
      borderRadius: BORDER_RADIUS,
    },

    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 16,
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: 14,
          color: SECONDARY_TEXT_COLOR_DARK,
        },
      },
    },
  });
};

export default createMaterialTheme;
