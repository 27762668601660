import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import MemberOrganisation from "../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../rest/organisation/model/Organisation";
import UserDetails from "../../../rest/user/model/UserDetails";
import createSwitcherSubtitle from "../OrganisationSwitcher/utils/createSwitcherSubtitle";
import keyForOrganisation from "../OrganisationSwitcher/utils/keyForOrganisation";
import TableList from "../TableList/TableList";
import OrgAndRoleTableRow from "./components/OrgAndRoleTableRow";

interface Props extends WithTranslation {
  organisations: MemberOrganisation[];
  loading: boolean;
  onOrgClicked?: (org: MemberOrganisation) => void;
  user?: UserDetails;
  onRemoveClicked?: (user: UserDetails, org: Organisation) => void;
  userRemoved?: boolean;
}

const OrgAndRoleTable = (props: Props) => {
  const rows = props.organisations.map((org) => (
    <OrgAndRoleTableRow
      key={keyForOrganisation(org)}
      loading={props.loading}
      org={org}
      user={props.user}
      onOrgClicked={props.onOrgClicked}
      userRemoved={props.userRemoved}
      roleName={createSwitcherSubtitle(org, props.organisations, props.t)}
      onRemoveClicked={props.onRemoveClicked}
    />
  ));

  return (
    <TableList
      headerTitle="orgAndRoleTable.nameHeader"
      headerKeys={["orgAndRoleTable.numberHeader", "orgAndRoleTable.roleHeader"]}
      itemRows={rows}
      withAction={true}
    />
  );
};

export default withTranslation()(OrgAndRoleTable);
