import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";
import displayNameForRole from "../../users/detail/utils/displayNameForRole";
import { acceptInvite, rejectInvite } from "../redux/PendingInviteAction";

interface ExternalProps {
  pendingInvite: PendingInvite;
}

interface ReduxStateProps {
  isLoading: boolean;
  errorMessage: string | undefined;
}

interface ReduxDispatchProps {
  acceptInvite: (invite: PendingInvite) => void;
  rejectInvite: (invite: PendingInvite) => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps & WithT;

class AcceptContractorInvitePanel extends React.Component<Props> {
  render() {
    const title = this.props.t("acceptContractorInvitePanel.title");
    const messageFormat = this.props.t("acceptContractorInvitePanel.messageFormat");
    const acceptButton = this.props.t("acceptContractorInvitePanel.acceptButton");
    const declineButton = this.props.t("acceptContractorInvitePanel.declineButton");

    const roleName = this.props.pendingInvite.role
      ? displayNameForRole(this.props.pendingInvite.role.name, this.props.t)
      : this.props.pendingInvite.type;

    const message = messageFormat.replace("%s", roleName);

    return (
      <Grid container spacing={DEFAULT_SPACING} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <Trans>
              <strong>{title.toUpperCase()}</strong>
            </Trans>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Trans>
              <strong>{this.props.pendingInvite.organisation.name?.toUpperCase()}</strong>
            </Trans>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">
            <Trans>{message}</Trans>
          </Typography>
        </Grid>

        <Grid item />

        <Grid item xs={12}>
          <PrimaryButton
            label={acceptButton}
            endIcon={<FontAwesomeIcon icon={faCheck} />}
            isLoading={this.props.isLoading}
            onClick={() => this.props.acceptInvite(this.props.pendingInvite)}
            fullWidth
          />
        </Grid>

        <Grid item>
          <SecondaryButton
            label={declineButton}
            disabled={this.props.isLoading}
            onClick={() => this.props.rejectInvite(this.props.pendingInvite)}
          />
        </Grid>

        <ErrorSnackbarHandler message={this.props.errorMessage} />
      </Grid>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isLoading: state.pendingInvite.isLoading,
    errorMessage: state.pendingInvite.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  acceptInvite: (invite) => dispatch(acceptInvite(invite)),
  rejectInvite: (invite) => dispatch(rejectInvite(invite)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AcceptContractorInvitePanel));
