import { RouteComponentProps } from "react-router-dom";
import { parse } from "query-string";

/**
 * Extract a query param from the props. If null, will return an empty string.
 *
 * @param paramName the name of query parameter wanted
 * @param props the props of the page
 */
const extractQueryParam = (paramName: string, props: RouteComponentProps): string => {
  const params = parse(props.location.search.toLowerCase());
  const param = params[paramName.toLowerCase()];

  if (Array.isArray(param)) {
    // If it's an array, join into a string.
    return param.join();
  } else {
    // otherwise return the param, or an empty string if null.
    return param || "";
  }
};

export default extractQueryParam;
