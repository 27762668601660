import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import RAGStatus, {
  colorForStatus,
  titleForRAGStatus,
} from "../../PropertyListPage/model/RAGStatus";

const height = 24;

interface Props extends WithT {
  status: RAGStatus;
  text?: string;
}

const RAGStatusPill = (props: Props) => {
  const color = colorForStatus(props.status);
  const text = props.text || titleForRAGStatus(props.status, props.t);

  return (
    <Box
      bgcolor={color}
      height={height}
      borderRadius={height / 2}
      paddingX={SMALL_SPACING}
    >
      <ColoredText textColor="white" variant="button">
        <strong>{text}</strong>
      </ColoredText>
    </Box>
  );
};

export default withTranslation()(RAGStatusPill);
