import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { EXTRA_LARGE_SPACING } from "../theme/dimensions";

const Spinner = () => {
  return (
    <Box
      padding={EXTRA_LARGE_SPACING}
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default Spinner;
