import { useEffect, useRef, useState } from "react";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import DateRangePicker from "@wojtekmaj/react-daterange-picker/";
import "./DateRangePicker.css";
import "./Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SECONDARY_COLOR } from "../../theme/createMaterialTheme";
import { Box, Portal } from "@material-ui/core";
import React from "react";

interface IProps {
  dateRangeState: Date[];
  setDateRange: (dates: Date[]) => void;
}

const DataRangePicker = (props: IProps) => {
  //State
  const [initDateSelected, setInitDateSelected] = useState(false);
  const [minMaxDate, setMinMaxDate] = useState<undefined[] | Date[]>([
    undefined,
    undefined,
  ]);
  const [displayType, setDisplayType] = useState<"year" | undefined>(undefined);

  //Refs
  const container = useRef(null);

  const boxRef = React.useRef<HTMLDivElement>(null);

  // Will be useful in the near future
  // const getMaxDate = (date: Date): Date => {
  //   const currentDate = new Date();
  //   return date <= currentDate ? date : currentDate;
  // };

  //Effects
  useEffect(() => {
    if (!!boxRef.current) {
      const inputs = boxRef.current.getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs.item(i) !== null) {
          inputs.item(i)!.disabled = true;
        }
      }
    }
  }, [boxRef]);

  //Helpers
  const minMaxDateSetter = (date: Date) => {
    const startDate = new Date(date);
    // startDate.setDate(date.getDate() - 30); //Will be useful in the new future
    // const endDate = new Date(date);
    // endDate.setDate(date.getDate() + 60);
    // setMinMaxDate([startDate, getMaxDate(endDate)]);
    setMinMaxDate([startDate, startDate]);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <div ref={boxRef} style={{ height: "100%" }}>
        <DateRangePicker
          className={"react-daterange-picker"}
          calendarClassName={"react-calendar"}
          minDate={minMaxDate[0]}
          maxDate={minMaxDate[1] ?? new Date()}
          format={"dd-MM-y"}
          onCalendarOpen={() => setDisplayType("year")}
          onChange={(date) => {
            let changeDate = date as unknown as Date[];
            const currentDate = new Date();
            if (changeDate?.length === 2 && changeDate[1] >= currentDate) {
              changeDate[1] = currentDate;
            } else if (changeDate?.length === 1) {
              changeDate.push(
                new Date(changeDate[0].getFullYear(), changeDate[0].getMonth() + 1, 0)
              );
            }
            props.setDateRange(changeDate);
          }}
          onCalendarClose={() => {
            setDisplayType(undefined);
            setInitDateSelected(false);
            setMinMaxDate([undefined, undefined]);
          }}
          onFocus={(e) => {
            return;
          }}
          value={props.dateRangeState}
          minDetail={displayType}
          maxDetail={displayType}
          // This needs to be there as the typescript definition does
          // not define the props which are part of react-calendar and are being passed through
          // @ts-ignore
          selectRange={false}
          returnValue={"range"}
          allowPartialRange={true}
          portalContainer={document.getElementById("calendar-container")}
          onClickMonth={(date: Date) => {
            if (!initDateSelected) {
              setInitDateSelected(true);
              minMaxDateSetter(date);
            }
          }}
          nextLabel={<FontAwesomeIcon color={SECONDARY_COLOR} icon={faAngleRight} />}
          next2Label={
            <FontAwesomeIcon color={SECONDARY_COLOR} icon={faAngleDoubleRight} />
          }
          prevLabel={<FontAwesomeIcon color={SECONDARY_COLOR} icon={faAngleLeft} />}
          prev2Label={
            <FontAwesomeIcon color={SECONDARY_COLOR} icon={faAngleDoubleLeft} />
          }
        />
      </div>

      <Portal container={container.current}>
        <div id="calendar-container"></div>
      </Portal>
      <div
        ref={container}
        style={{ position: "absolute", marginTop: "80px", zIndex: 999 }}
      />
    </Box>
  );
};

export default DataRangePicker;
