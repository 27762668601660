import { Box } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import { Country } from "../../../../../rest/countries/model/Country";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import { buildPlatformAgentDetailRoute } from "../../../../../routes/routes";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../../ui/theme/dimensions";
import { adminUpdateOrganisation } from "../../../../organisations/details/redux/OrganisationDetailActions";
import EditableAddress from "../../../../properties/PropertyDetailPage/components/EditableField/EditableAddress";
import EditableField from "../../../../properties/PropertyDetailPage/components/EditableField/EditableField";
import Address from "../../../../properties/PropertyDetailPage/model/Address";
import AgentAssignmentDialog from "../../../PlatformAgentDetailsPage/components/AgentAssignmentDialog/AgentAssignmentDialog";
import AgentDialog from "../../../PlatformAgentDetailsPage/components/AgentDialog/AgentDialog";
import AgentSwitch from "../../../PlatformAgentDetailsPage/components/AgentSwitch/AgentSwitch";
import { grantAgentAccess } from "../../redux/AdminOrganisationDetailsAction";
import OrganisationAgentPanel from "../OrganisationAgentPanel";

//Types
type FieldName = "organisationName" | "address" | "contactNumber" | "vatNumber";

//Props
interface ReduxDispatchProps {
  adminUpdateOrganisation: (org: Organisation) => void;
  grantAgentAccess: (org: Organisation) => void;
}

interface ReduxStateProps {
  selectedOrganisation: Organisation | undefined;
  isLoading: boolean;
  error: string | undefined;
  updateSuccess: boolean;
  agentAccessGranted: boolean | undefined;
  agentAccessError: string | undefined;
  agentExists: boolean;
}

//State
interface State {
  editingField: FieldName | undefined;
  showAgentDialog: boolean;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

class AdminOrganisationDetailsTab extends Component<Props, State> {
  state = {
    editingField: undefined,
    showAgentDialog: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.updateSuccess && !prevProps.updateSuccess) {
      this.setState({ editingField: undefined });
    }
  }

  render() {
    return (
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="top"
          paddingY={DEFAULT_SPACING}
          justifyContent="flex-start"
          marginX={EXTRA_LARGE_SPACING}
          flexGrow={1}
        >
          {this.renderEditableField(
            "currentOrganisationPage.organisationNameLabel",
            this.props.selectedOrganisation?.name,
            "organisationName"
          )}

          <EditableAddress
            country={this.buildCountry()}
            label={this.props.t("currentOrganisationPage.addressLabel")}
            address={this.buildAddress()}
            isEditing={this.state.editingField === "address"}
            onEditClicked={() => this.editClickedHandler("address")}
            hoverEnabled={this.canHover()}
            onCancelClicked={this.cancelClickedHandler}
            onSaveClicked={this.saveAddressClickedHandler}
            loading={this.props.isLoading}
          />

          {this.renderEditableField(
            "currentOrganisationPage.contactNumberLabel",
            this.props.selectedOrganisation?.phone_number,
            "contactNumber"
          )}

          {this.renderEditableField(
            "currentOrganisationPage.vatNumberLabel",
            this.props.selectedOrganisation?.vat_number,
            "vatNumber"
          )}

          <AgentSwitch
            agentExists={this.props.agentExists}
            isAgent={this.props.selectedOrganisation?.is_agent_for === undefined}
            switchAgentState={this.handleAgentAccessSwitch}
          />

          <AgentDialog
            isAgent={this.props.selectedOrganisation?.is_agent_for === undefined}
            isOpen={this.state.showAgentDialog}
            organisationName={this.props.selectedOrganisation?.name || ""}
            onCancel={this.handleAgentDialogCancel}
            onSubmit={this.handleGrantAccessClicked}
          />

          <AgentAssignmentDialog
            isAgent={false}
            onOkClicked={this.handleAccessGrantedClicked}
            organisationName={this.props.selectedOrganisation?.name || ""}
            open={this.props.agentAccessGranted}
          />

          <ErrorSnackbarHandler
            message={this.props.error || this.props.agentAccessError}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="top"
          paddingY={DEFAULT_SPACING}
          marginX={LARGE_SPACING}
          flexGrow={1}
        >
          {this.props.selectedOrganisation ? (
            <OrganisationAgentPanel organisation={this.props.selectedOrganisation} />
          ) : null}
        </Box>
      </Box>
    );
  }
  buildCountry = (): Country => {
    return {
      id: this.props.selectedOrganisation?.address_country.id || "",
      code: this.props.selectedOrganisation?.address_country.code || "",
      name: this.props.selectedOrganisation?.address_country.name || "",
    };
  };

  buildAddress = (): Address => {
    return {
      line1: this.props.selectedOrganisation?.address_line_1 || "",
      line2: this.props.selectedOrganisation?.address_line_2,
      line3: this.props.selectedOrganisation?.address_line_3,
      city: this.props.selectedOrganisation?.address_city || "",
      postcode: this.props.selectedOrganisation?.address_postcode || "",
      country: this.props.selectedOrganisation?.address_country.code || "",
    };
  };

  renderEditableField = (
    labelId: string,
    value: string | undefined,
    fieldName: FieldName
  ) => {
    const label = this.props.t(labelId);
    return (
      <EditableField
        label={label}
        text={value}
        required={true}
        isEditing={this.state.editingField === fieldName}
        onEditClicked={() => this.editClickedHandler(fieldName)}
        hoverEnabled={this.canHover()}
        onCancelClicked={this.cancelClickedHandler}
        onSaveClicked={(value) => this.saveClickedHandler(fieldName, value)}
        loading={this.props.isLoading}
        gutterBottom
      />
    );
  };

  // Handlers
  handleGrantAccessClicked = () => {
    if (this.props.selectedOrganisation) {
      this.props.grantAgentAccess(this.props.selectedOrganisation);
    }
  };

  handleAccessGrantedClicked = () => {
    if (this.props.selectedOrganisation?.id) {
      const route = buildPlatformAgentDetailRoute(this.props.selectedOrganisation?.id);
      this.props.history.replace(route);
    }
  };

  handleAgentAccessSwitch = (showAgentDialog: boolean) => {
    this.setState({ showAgentDialog: true });
  };

  handleAgentDialogCancel = () => {
    this.setState({ showAgentDialog: false });
  };

  canHover = (): boolean => {
    return this.state.editingField === undefined && !this.props.isLoading;
  };

  editClickedHandler = (field: FieldName) => {
    this.setState({ editingField: field });
  };

  cancelClickedHandler = () => {
    this.setState({ editingField: undefined });
  };

  saveClickedHandler = (field: FieldName, value: string) => {
    if (this.props.selectedOrganisation === undefined) {
      return;
    }

    const updatedOrg = { ...this.props.selectedOrganisation };

    switch (field) {
      case "contactNumber":
        updatedOrg.phone_number = value;
        break;

      case "organisationName":
        updatedOrg.name = value;
        break;

      case "vatNumber":
        updatedOrg.vat_number = value;
        break;
    }

    this.props.adminUpdateOrganisation(updatedOrg);
  };

  saveAddressClickedHandler = (address: Address) => {
    if (this.props.selectedOrganisation === undefined) {
      return;
    }

    const updatedOrg: Organisation = {
      ...this.props.selectedOrganisation,
      address_line_1: address.line1,
      address_line_2: address.line2,
      address_line_3: address.line3,
      address_city: address.city,
      address_country: { id: "", name: "", code: address.country }, //Name and code do not matter
      address_postcode: address.postcode,
    };

    this.props.adminUpdateOrganisation(updatedOrg);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  agentExists:
    state.platformAgentList.agentOrganisations?.find(
      (agent) =>
        agent.is_agent_for?.id === state.orgDetail.organisation?.address_country.id
    ) !== undefined,
  selectedOrganisation: state.orgDetail.organisation,
  isLoading: state.orgDetail.loading,
  error: state.orgDetail.error,
  updateSuccess: state.orgDetail.updateSuccessful,
  agentAccessGranted: state.adminOrganisationDetails.agentAccessGranted,
  agentAccessError: state.adminOrganisationDetails.error,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  adminUpdateOrganisation: (org) => dispatch(adminUpdateOrganisation(org)),
  grantAgentAccess: (org) => dispatch(grantAgentAccess(org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AdminOrganisationDetailsTab)));
