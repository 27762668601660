import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import { OrganisationRelationship } from "../redux/OrganisationDetailState";

class OrganisationDetailService {
  constructor(private contractorRepository: OrganisationContractorRepository) {}

  getOrganisationRelationship = async (
    organisationId: string,
    activeOrganisationId: string
  ): Promise<OrganisationRelationship | undefined> => {
    // Check the list of associated orgs to see if organisationId
    // is present
    const associatedOrgs = await this.contractorRepository.fetchContractingOrganisations(
      activeOrganisationId
    );
    // If so, we're a contractee
    const isAssociatedOrg = associatedOrgs.find((o) => o.id === organisationId);
    if (isAssociatedOrg) {
      return "contractee";
    }

    // Do the same for contractors
    const contractorOrgs =
      await this.contractorRepository.fetchOrganisationContractorList(
        activeOrganisationId,
        undefined
      );
    const contractorOrg = contractorOrgs.find((org) => org.id === organisationId);
    if (contractorOrg !== undefined) {
      return "contractor";
    }

    return undefined;
  };
}

export default OrganisationDetailService;
