import { Box, Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { SMALL_SPACING } from "../theme/dimensions";

interface Props {
  open: boolean;
  title: string;
  body: string;
  confirmButton: string;
  removeCancelButton?: boolean;

  onConfirmed: () => void;
  onCancelClicked: () => void;
}

const ConfirmDialog = (props: Props) => {
  const cancelButton = props.removeCancelButton ? undefined : (
    <Button color="secondary" onClick={props.onCancelClicked}>
      <Trans>buttons.cancel</Trans>
    </Button>
  );

  const handleDialogClicked = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancelClicked}
      onClick={handleDialogClicked}
    >
      <Box padding={SMALL_SPACING}>
        <Typography variant="h2" gutterBottom>
          {props.title}
        </Typography>
        <Typography gutterBottom>{props.body}</Typography>

        <Box display="flex" justifyContent="flex-end">
          {cancelButton}

          <Button color="primary" onClick={props.onConfirmed}>
            {props.confirmButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
