import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithT {
  email: string | undefined;
  onContinuePressed: () => void;
}

const InviteSentPanel = (props: Props) => {
  const title = props.t("inviteSentPanel.title");
  const doneButton = props.t("inviteSentPanel.continueButton");
  const messagePlaceholder = props.t("inviteSentPanel.bodyPlaceholder");
  const message = messagePlaceholder.replace("%s", props.email || "");

  return (
    <Grid container direction="column" spacing={SMALL_SPACING}>
      <Grid item>
        <Typography variant="h2" align="center">
          <strong>{title.toUpperCase()}</strong>
        </Typography>
      </Grid>

      <Grid item />

      <Grid item>
        <Box paddingX={DEFAULT_SPACING}>
          <Typography align="center">{message}</Typography>
        </Box>
      </Grid>

      <Grid item />

      <Grid item>
        <PrimaryButton
          endIcon={<FontAwesomeIcon icon={faCheck} />}
          label={doneButton}
          onClick={props.onContinuePressed}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(InviteSentPanel);
