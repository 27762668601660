import { Box, Card } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import PendingInviteDialog from "../../../pages/invites/components/PendingInviteDialog";
import { fetchPendingInvites } from "../../../pages/invites/redux/PendingInviteAction";
import RootState from "../../../redux/RootState";
import { ACCOUNT_SETTINGS_ROUTE, CURRENT_ORG_ROUTE } from "../../../routes/routes";
import {
  BACKGROUND_ACCENT_COLOR,
  PAGE_BACKGROUND_COLOR,
} from "../../theme/createMaterialTheme";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../theme/dimensions";
import ErrorSnackbarHandler from "../ErrorSnackbar/ErrorSnackbarHandler";
import HeaderTabs from "../HeaderTabs/HeaderTabs";
import OrganisationSwitcher from "../OrganisationSwitcher/OrganisationSwitcher";
import CopyrightFooter from "./components/CopyrightFooter";
import HomeButton from "./components/HomeButton";
import RAGCards from "../RAGCards/RAGCards";
import CreateOrganisationDialog from "../../../pages/organisations/create/CreateOrganisationDialog";
import ExportBar from "../ExportBar/ExportBar";

const overlapHeight = 50;
const contentBottomMargin = 24;

interface ExternalProps {
  hideRagStatus?: boolean;
  children?: JSX.Element;
}

interface ReduxStateProps {
  organisationError: string | undefined;
  hasAdeyAdminAccess: boolean;
  hasAdeyAgentAccess: boolean;
  createOrganisationSuccess: boolean;
}

interface ReduxDispatchProps {
  fetchInvites: () => void;
}

interface State {
  isCreateOrganisationOpen: boolean;
}

type Props = RouteComponentProps &
  ReduxDispatchProps &
  ExternalProps &
  WithTranslation &
  ReduxStateProps;

class DashboardLayout extends React.Component<Props, State> {
  state = {
    isCreateOrganisationOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.createOrganisationSuccess && !prevProps.createOrganisationSuccess) {
      // If we get any success state, close org creation dialog
      this.setState({ isCreateOrganisationOpen: false });
    }
  }

  componentDidMount() {
    // Each time this page loads, check for pending invites
    this.props.fetchInvites();
  }

  render() {
    return (
      <div>
        <Box bgcolor={BACKGROUND_ACCENT_COLOR} minWidth="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            paddingTop={1}
          >
            <HomeButton
              onClick={this.handleLogoClicked}
              adminMode={this.props.hasAdeyAdminAccess}
            />

            <HeaderTabs />

            <Box paddingX={DEFAULT_SPACING}>
              <OrganisationSwitcher
                onCreateOrganisationClicked={this.handleCreateOrganisationClicked}
                onDetailsClicked={this.handleDetailsClicked}
                onAccountSettingsClicked={this.handleAccountSettingsClicked}
              />
            </Box>
          </Box>

          <Box height={DEFAULT_SPACING} />
          {(this.props.hasAdeyAgentAccess && !this.props.hasAdeyAdminAccess) ||
          this.props.hideRagStatus ? (
            <Box marginY={LARGE_SPACING} />
          ) : (
            <RAGCards />
          )}
          <Box height={EXTRA_LARGE_SPACING} />
        </Box>

        <Box bgcolor={PAGE_BACKGROUND_COLOR}>
          <Box bgcolor={BACKGROUND_ACCENT_COLOR} height={overlapHeight} width="100%" />

          <Box
            marginX={DEFAULT_SPACING}
            minHeight={overlapHeight * 2}
            marginTop={`${-1 * overlapHeight}px`}
          >
            <Card elevation={0}>{this.props.children}</Card>
          </Box>

          <Box height={contentBottomMargin} />

          <CopyrightFooter />
        </Box>

        <CreateOrganisationDialog
          isCreatingOrg={this.state.isCreateOrganisationOpen}
          onBackPressed={this.handleCreateOrganisationDialogClosed}
        />

        <ExportBar />

        <PendingInviteDialog />

        <ErrorSnackbarHandler message={this.props.organisationError} />
      </div>
    );
  }

  // MARK: Handlers

  handleAccountSettingsClicked = () => {
    const isAlreadyOnRoute = matchPath(
      this.props.location.pathname,
      ACCOUNT_SETTINGS_ROUTE
    );
    if (!isAlreadyOnRoute) {
      this.props.history.push(ACCOUNT_SETTINGS_ROUTE);
    }
  };

  handleDetailsClicked = () => {
    const isAlreadyOnRoute = matchPath(this.props.location.pathname, CURRENT_ORG_ROUTE);
    if (!isAlreadyOnRoute) {
      this.props.history.push(CURRENT_ORG_ROUTE);
    }
  };

  handleCreateOrganisationClicked = () => {
    this.setState({ isCreateOrganisationOpen: true });
  };

  handleCreateOrganisationDialogClosed = () => {
    this.setState({ isCreateOrganisationOpen: false });
  };

  handleLogoClicked = () => {
    // Push an empty route so we're redirected to the home page
    this.props.history.push("/");
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    createOrganisationSuccess: state.createOrganisation.success,
    organisationError: state.activeOrganisation.error,
    hasAdeyAdminAccess: state.adeyAdmin.hasAdminAccess,
    hasAdeyAgentAccess: state.adeyAgent.hasAgentAccess,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  fetchInvites: () => dispatch(fetchPendingInvites()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(DashboardLayout)));
