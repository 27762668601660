import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { LOGIN_ROUTE } from "../../../routes/routes";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";

type Props = WithTranslation & RouteComponentProps;

class AccountCreatedPage extends React.Component<Props> {
  render() {
    const title = this.props.t("accountCreatedPage.title");
    const message = this.props.t("accountCreatedPage.message");
    const signInButton = this.props.t("accountCreatedPage.signInButton");

    return (
      <CenteredAuthCard title={title}>
        <Grid container spacing={DEFAULT_SPACING} justify="center">
          <Grid item />

          <Grid item>
            <Typography align="center">{message}</Typography>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleSignInClicked}
            >
              {signInButton}
            </Button>
          </Grid>
        </Grid>
      </CenteredAuthCard>
    );
  }

  handleSignInClicked = () => {
    this.props.history.push(LOGIN_ROUTE);
  };
}

export default withTranslation()(withRouter(AccountCreatedPage));
