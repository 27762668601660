import User from "../model/UserDetails";

const userMatchesSearchText = (user: User, searchText: string | undefined): boolean => {
  const search = searchText?.toLocaleLowerCase()?.trim() || "";
  if (search === "") {
    return true;
  }
  let values = "";
  if (
    user.first_name?.toLocaleLowerCase !== undefined &&
    user.last_name?.toLocaleLowerCase()
  ) {
    values = `${
      user.first_name?.toLocaleLowerCase
    } ${user.last_name?.toLocaleLowerCase()} ${user.email?.toLowerCase()}`;
  }
  return values.includes(search);
};

export default userMatchesSearchText;
