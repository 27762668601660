import { useEffect } from "react";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import AccountSettingsPage from "../../pages/account/AccountSettingsPage/AccountSettingsPage";
import AccountActivationPage from "../../pages/auth/AccountActivation/AccountActivationPage";
import SenseDeviceDetailsPage from "../../pages/devices/SenseDeviceDetailsPage/SenseDeviceDetailsPage";
import TermsAndConditionsDocumentPage from "../../pages/documents/TermsAndConditionsDocumentPage";
import HomezoneUnsubscribePage from "../../pages/homezone/HomezoneUnsubscribePage";
import CurrentOrganisationPage from "../../pages/organisations/current/CurrentOrganisationPage";
import OrganisationDetailPage from "../../pages/organisations/details/OrganisationDetailPage";
import OrganisationListPage from "../../pages/organisations/list/OrganisationListPage";
import ProCheckDetailsPage from "../../pages/procheck/ProCheckDetailsPage/ProCheckDetailsPage";
import ProCheckPage from "../../pages/procheck/ProCheckPage/ProCheckPage";
import PropertyDetailPage from "../../pages/properties/PropertyDetailPage/PropertyDetailPage";
import PropertyListPage from "../../pages/properties/PropertyListPage/PropertyListPage";
import ReportsDashboardPage from "../../pages/reports/dashboard/ReportsDashboardPage/ReportsDashboardPage";
import UserDetailPage from "../../pages/users/detail/UserDetailPage";
import UserListPage from "../../pages/users/list/UserListPage";
import {
  ACCOUNT_ACTIVATION_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  CURRENT_ORG_ROUTE,
  DASHBOARD_ROUTE,
  ORGANISATION_LIST_ROUTE,
  ORGANISATION_DETAIL_ROUTE,
  PROPERTY_DETAIL_ROUTE,
  USER_DETAIL_ROUTE,
  USER_LIST_ROUTE,
  PROCHECK_ROUTE,
  PROCHECK_RESULT_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
  PROCHECK_USER_RESULTS_ROUTE,
  SENSE_DEVICE_ROUTE,
  HOMEZONE_UNSUBSCRIBE_ROUTE,
  REPORTING_ROUTE,
  PROCHECK_REPORTING_ROUTE,
} from "../routes";
/*
 * All of the pages available to logged in users
 * who aren't adey admins
 */
// ... (other imports)

const AuthenticatedRouter = () => {
  return (
    <Switch>
      {/*Public routes first */}
      <Route path={TERMS_AND_CONDITION_ROUTE}>
        <TermsAndConditionsDocumentPage />
      </Route>

      <Route exact path={HOMEZONE_UNSUBSCRIBE_ROUTE}>
        <HomezoneUnsubscribePage />
      </Route>

      <Route exact path={DASHBOARD_ROUTE}>
        <PropertyListPage />
      </Route>

      <Route exact path={PROPERTY_DETAIL_ROUTE}>
        <PropertyDetailPage />
      </Route>

      <Route
        path={[
          PROCHECK_RESULT_ROUTE,
          PROCHECK_USER_RESULTS_ROUTE,
          PROCHECK_REPORTING_ROUTE,
        ]}
      >
        <ProCheckDetailsPage />
      </Route>

      <Route exact path={PROCHECK_ROUTE}>
        <ProCheckPage />
      </Route>

      <Route exact path={ACCOUNT_SETTINGS_ROUTE}>
        <AccountSettingsPage />
      </Route>

      <Route exact path={USER_LIST_ROUTE}>
        <UserListPage />
      </Route>

      <Route exact path={ORGANISATION_LIST_ROUTE}>
        <OrganisationListPage />
      </Route>

      <Route exact path={REPORTING_ROUTE}>
        <ReportsDashboardPage />
      </Route>

      <Route exact path={USER_DETAIL_ROUTE}>
        <UserDetailPage />
      </Route>

      <Route exact path={ORGANISATION_DETAIL_ROUTE}>
        <OrganisationDetailPage />
      </Route>

      <Route exact path={CURRENT_ORG_ROUTE}>
        <CurrentOrganisationPage />
      </Route>

      <Route exact path={ACCOUNT_ACTIVATION_ROUTE}>
        <AccountActivationPage />
      </Route>

      <Route exact path={SENSE_DEVICE_ROUTE}>
        <SenseDeviceDetailsPage />
      </Route>

      {/* Redirect any unknown routes to the dash */}
      <Route>
        <Redirect to={DASHBOARD_ROUTE} />
      </Route>
    </Switch>
  );
};

export default AuthenticatedRouter;
