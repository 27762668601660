import { useState } from "react";
import { useSelector } from "react-redux";
import RootState from "../../../../../redux/RootState";
import TabModel from "../../../../../ui/components/PageHeaderTabs/models/TabModel";
import TReportTab from "../types/TReportTab";

const useReportDashboard = () => {
  //Selector
  const canViewReports = useSelector(
    (state: RootState) =>
      state.activeOrganisation.permissions.canViewActiveOrganisationUsers ||
      state.activeOrganisation.currentOrganisation?.role.via
  ); //Corresponds to the level of priveleges required to access Reports according to the specs

  //Tabs
  const tabs: TabModel[] = [
    { title: "senseDeviceNames.PROCHECK", navKey: "procheck" as TReportTab },
  ];

  //State
  const [selectedTab, setSelectedTab] = useState<TReportTab>("procheck");

  //Helpers
  const onTabClicked = (key: string) => {
    setSelectedTab(key as TReportTab);
  };

  return { selectedTab, tabs, canViewReports, onTabClicked };
};

export default useReportDashboard;
