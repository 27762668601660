import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import inviteMatchesSearchText from "../../../../../rest/invites/utils/inviteMatchesSearchText";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import organisationMatchesSearchText from "../../../../../rest/organisation/utils/organisationMatchesSearchText";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import SearchBar from "../../../../../ui/components/SearchBar";
import Spinner from "../../../../../ui/components/Spinner";
import SuccessSnackbarHandler from "../../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../ui/theme/dimensions";
import { OrganisationRelationship } from "../../../../organisations/details/redux/OrganisationDetailState";
import {
  initLinkedOrgRemoval,
  inviteContractor,
} from "../../redux/AdminOrganisationDetailsAction";
import AdminOrganisationDetailsState, {
  OrgContractorDetails,
} from "../../redux/AdminOrganisationDetailsState";
import AdminInviteContractorDialog from "../AdminInviteContractorDialog";
import OrganisationContractorTables from "../OrganisationContractorTables";

interface ExternalProps {
  organisationName: string;
  organisationId: string;
  onOrgClicked: (org: Organisation) => void;
}

type ReduxStateProps = {
  adminOrgDetails: AdminOrganisationDetailsState;
  selectedOrgDetails: Organisation | undefined;
};

interface ReduxDispatchProps {
  inviteContractor: (orgId: string, email: string) => void;
  removeLinkedOrganisation: (
    organisationId: string,
    contractorId: string,
    relationship: OrganisationRelationship
  ) => void;
}

type Props = ExternalProps & ReduxStateProps & WithT & ReduxDispatchProps;

interface State {
  searchText: string;
  inviteDialogOpen: boolean;
  inviteSuccess: boolean;
  removeSuccess: boolean;
}

class AdminOrgContractorsTab extends React.Component<Props, State> {
  state = {
    searchText: "",
    inviteDialogOpen: false,
    inviteSuccess: false,
    removeSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.adminOrgDetails.invitedContractor !==
      prevProps.adminOrgDetails.invitedContractor
    ) {
      this.setState({
        inviteSuccess: this.props.adminOrgDetails.invitedContractor !== undefined,
      });
    }
    if (
      this.props.adminOrgDetails.isLinkedOrgRemoved !==
      prevProps.adminOrgDetails.isLinkedOrgRemoved
    ) {
      this.setState({
        removeSuccess: this.props.adminOrgDetails.isLinkedOrgRemoved !== undefined,
      });
    }
  }

  render() {
    if (this.props.adminOrgDetails.contractorDetails) {
      const contractorDetails = this.filterBySearch(
        this.props.adminOrgDetails.contractorDetails
      );

      return (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            paddingX={LARGE_SPACING}
            paddingTop={SMALL_SPACING}
            paddingBottom={DEFAULT_SPACING}
          >
            <SearchBar
              value={this.state.searchText}
              onChange={this.handleSearchTextChanged}
              placeholder={this.props.t("platformOrganisationDetail.orgSearch")}
            />

            <Box width={EXTRA_LARGE_SPACING} />

            <OutlineButton
              style={{ minWidth: 200 }}
              labelId="platformOrganisationDetail.inviteContractorButton"
              endIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={this.handleInviteButtonClicked}
              filled
            />
          </Box>

          <OrganisationContractorTables
            details={contractorDetails}
            selectedOrg={this.props.selectedOrgDetails}
            onOrgClicked={this.props.onOrgClicked}
            onRemoveClicked={this.removeOrganisationContractor}
          />

          <AdminInviteContractorDialog
            organisationName={this.props.organisationName}
            open={this.state.inviteDialogOpen}
            isLoading={this.props.adminOrgDetails.isInvitingContractor}
            inviteSent={this.props.adminOrgDetails.invitedContractor !== undefined}
            onClose={this.handleDialogClosed}
            sendInvite={this.handleContractorInvited}
          />

          <ErrorSnackbarHandler message={this.props.adminOrgDetails.error} />

          <SuccessSnackbarHandler message={this.handleSuccessMessage()} />
        </Box>
      );
    } else if (this.props.adminOrgDetails.error) {
      return <ErrorDisplay title={this.props.adminOrgDetails.error} />;
    } else {
      return <Spinner />;
    }
  }

  // Handlers

  handleSuccessMessage = (): string | undefined => {
    if (this.state.inviteSuccess) {
      return this.props.t("adminInviteContractorDialog.inviteSentMessage");
    } else if (this.state.removeSuccess) {
      return this.props.t("platformOrganisationDetail.organisationRemoveSuccess");
    } else {
      return undefined;
    }
  };

  handleSearchTextChanged = (value: string) => {
    this.setState({ searchText: value });
  };

  handleInviteButtonClicked = () => {
    this.setState({ inviteDialogOpen: true });
  };

  handleDialogClosed = () => {
    this.setState({ inviteDialogOpen: false });
  };

  handleContractorInvited = (email: string) => {
    this.props.inviteContractor(this.props.organisationId, email);
  };

  removeOrganisationContractor = (
    org: Organisation,
    contractor: Organisation,
    relationship: OrganisationRelationship
  ) => {
    this.props.removeLinkedOrganisation(org.id, contractor.id, relationship);
  };

  // Filters

  filterBySearch = (details: OrgContractorDetails): OrgContractorDetails => {
    return {
      contractors: details.contractors.filter((o) =>
        organisationMatchesSearchText(o, this.state.searchText)
      ),
      associatedOrgs: details.associatedOrgs.filter((o) =>
        organisationMatchesSearchText(o, this.state.searchText)
      ),
      invites: details.invites.filter((i) =>
        inviteMatchesSearchText(i, this.state.searchText)
      ),
    };
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    selectedOrgDetails: state.orgDetail.organisation,
    adminOrgDetails: state.adminOrganisationDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inviteContractor: (orgId, email) => dispatch(inviteContractor(orgId, email)),
  removeLinkedOrganisation: (orgId, contractorId, relationship) =>
    dispatch(initLinkedOrgRemoval(orgId, contractorId, relationship)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminOrgContractorsTab));
