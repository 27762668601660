import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { logOut } from "../../pages/auth/Login/redux/LoginAction";
import PrimaryButton from "./PrimaryButton";

interface ReduxDispatchProps {
  signOut: () => void;
}

type Props = WithTranslation & ReduxDispatchProps;

const SignOutButton = (props: Props) => {
  const label = props.t("buttons.signOut");

  return (
    <PrimaryButton
      startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
      label={label}
      size="small"
      onClick={props.signOut}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    signOut: () => dispatch(logOut()),
  };
};

export default connect(undefined, mapDispatchToProps)(withTranslation()(SignOutButton));
