import { AxiosError } from "axios";

const proClubErrorHandler = (e: any): string | undefined => {
  if (!e?.isAxiosError) {
    return undefined;
  }

  const statusCode = (e as AxiosError).response?.status;
  if (statusCode === 412) {
    return "proClub.status412";
  }

  return undefined;
};

export default proClubErrorHandler;
