import { TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import Organisation from "../../../../rest/organisation/model/Organisation";

interface Props {
  agent: Organisation;
  countryText: string;
  smallCellWidth: string;
  key: string;

  onClick?: (agent: Organisation) => void;
}

const AgentTableRow = (props: Props) => {
  const agent = props.agent;
  const countryText = props.countryText;

  const clickable = props.onClick !== undefined;

  const smallCellStyle = { width: props.smallCellWidth };
  const rowStyle = clickable ? { cursor: "pointer" } : undefined;

  const handleClick = () => {
    if (clickable) {
      props.onClick?.(agent);
    }
  };

  return (
    <TableRow key={agent.id} hover={clickable} style={rowStyle} onClick={handleClick}>
      <TableCell>
        <Typography>{agent.name}</Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>{agent.phone_number}</Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>{countryText}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default AgentTableRow;
