import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import inviteMatchesSearchText from "../../../../../rest/invites/utils/inviteMatchesSearchText";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import { RoleName } from "../../../../../rest/roles/model/Role";
import UserDetails from "../../../../../rest/user/model/UserDetails";
import userMatchesSearchText from "../../../../../rest/user/utils/userMatchesSearchText";
import { buildPlatformUserDetailRoute } from "../../../../../routes/routes";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import SearchBar from "../../../../../ui/components/SearchBar";
import Spinner from "../../../../../ui/components/Spinner";
import SuccessSnackbarHandler from "../../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../ui/theme/dimensions";
import { OrgUserDetails } from "../../../AdminOrganisationDetailsPage/redux/AdminOrganisationDetailsState";
import {
  inviteAgentUser,
  removeAgentUser,
} from "../../redux/PlatformAgentDetailsActions";
import PlatformAgentDetailsState from "../../redux/PlatformAgentDetailsState";
import AgentUserTables from "../AgentUserTables/AgentUserTables";
import InviteAgentUserDialog from "../InviteAgentUserDialog/InviteAgentUserDialog";

//Props

type ReduxStateProps = PlatformAgentDetailsState;

interface ReduxDispatchProps {
  inviteUser: (orgId: string, email: string, role: RoleName) => void;
  removeAgentUser: (user: UserDetails, org: Organisation) => void;
}

type Props = ReduxStateProps & WithT & ReduxDispatchProps & RouteComponentProps;

//State
interface State {
  searchText: string;
  inviteDialogOpen: boolean;
  inviteSuccess: boolean | undefined;
  removeSuccess: boolean | undefined;
}

class AdminOrgUsersTab extends React.Component<Props, State> {
  state = {
    searchText: "",
    inviteDialogOpen: false,
    inviteSuccess: false,
    removeSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.userInvited !== prevProps.userInvited) {
      this.setState({ inviteSuccess: this.props.userInvited });
    }
    if (this.props.userRemoved !== prevProps.userRemoved) {
      this.setState({ removeSuccess: this.props.userRemoved });
    }
  }

  render() {
    if (this.props.userDetails !== undefined && this.props.agentDetails !== undefined) {
      const userDetails = this.filterBySearch(this.props.userDetails);
      return (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            paddingX={LARGE_SPACING}
            paddingTop={SMALL_SPACING}
            paddingBottom={DEFAULT_SPACING}
          >
            <SearchBar
              value={this.state.searchText}
              onChange={this.handleSearchTextChanged}
              placeholder={this.props.t("platformUserDetail.userSearch")}
            />

            <Box width={EXTRA_LARGE_SPACING} />

            <OutlineButton
              style={{ minWidth: 200 }}
              labelId="platformUserDetail.inviteUserButton"
              endIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={this.handleInviteButtonClicked}
              disabled={this.hasOwnerInvites()}
              filled
            />
          </Box>
          <AgentUserTables
            org={this.props.agentDetails}
            users={userDetails}
            onUserClicked={this.handleUserClicked}
            onRemoveUserClicked={this.props.removeAgentUser}
          />

          <InviteAgentUserDialog
            hasOwner={this.hasOwner() || this.hasOwnerInvites()}
            organisationName={this.props.agentDetails.name}
            open={this.state.inviteDialogOpen}
            isLoading={this.props.isInvitingUser}
            inviteSent={this.props.userInvited}
            onClose={this.handleDialogClosed}
            sendInvite={this.handleUserInvited}
          />

          <ErrorSnackbarHandler message={this.props.error} />

          <SuccessSnackbarHandler message={this.handleSuccessMessage()} />
        </Box>
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  }

  // Handlers
  handleSuccessMessage = (): string | undefined => {
    if (this.state.inviteSuccess) {
      return this.props.t("adminInviteUserDialog.inviteSentMessage");
    } else if (this.state.removeSuccess) {
      return this.props.t("platformUserDetail.removedSuccess");
    } else {
      return undefined;
    }
  };

  handleUserClicked = (user: UserDetails) => {
    const route = buildPlatformUserDetailRoute(user.id);
    this.props.history.push(route);
  };

  handleSearchTextChanged = (value: string) => {
    this.setState({ searchText: value });
  };

  handleInviteButtonClicked = () => {
    this.setState({ inviteDialogOpen: true });
  };

  handleDialogClosed = () => {
    this.setState({ inviteDialogOpen: false });
  };

  handleUserInvited = (email: string, role: RoleName) => {
    if (this.props.agentDetails)
      this.props.inviteUser(this.props.agentDetails.id, email, role);
  };

  // Filters

  filterBySearch = (details: OrgUserDetails): OrgUserDetails => {
    return {
      users: details.users.filter((o) =>
        userMatchesSearchText(o.user, this.state.searchText)
      ),
      invites: details.invites.filter((i) =>
        inviteMatchesSearchText(i, this.state.searchText)
      ),
    };
  };

  //Helpers
  hasOwner = (): boolean => {
    let owner: boolean = false;

    //Check if owner exists as a part of org property
    if (
      this.props.agentDetails?.owner !== undefined &&
      this.props.agentDetails?.owner !== null
    ) {
      owner = true;
    }

    //Check if owner exists in the user list
    const ownerFromList = this.props.userDetails?.users.find(
      (orgUser) => orgUser.role.name === "owner"
    );

    if (ownerFromList !== undefined) {
      owner = true;
    }

    return owner;
  };

  hasOwnerInvites = (): boolean => {
    const ownerInvite = this.props.userDetails?.invites.find(
      (invite) => invite.role?.name === "owner"
    );

    return ownerInvite !== undefined;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => state.platformAgentDetails;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inviteUser: (orgId, email, role) => dispatch(inviteAgentUser(orgId, email, role)),
  removeAgentUser: (user, org) => dispatch(removeAgentUser(user, org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AdminOrgUsersTab)));
