import React from "react";
import { Redirect, Route, Switch } from "react-router";
import AccountActivationPage from "../../pages/auth/AccountActivation/AccountActivationPage";
import AccountCreatedPage from "../../pages/auth/AccountCreated/AccountCreatedPage";
import ForgotPasswordEmailSentPage from "../../pages/auth/ForgotPassword/ForgotPasswordEmailSentPage";
import ForgotPasswordRequestPage from "../../pages/auth/ForgotPassword/ForgotPasswordRequestPage";
import ForgotPasswordSubmitPage from "../../pages/auth/ForgotPassword/ForgotPasswordSubmitPage";
import LoginPage from "../../pages/auth/Login/LoginPage";
import RegistrationPage from "../../pages/auth/Registration/RegistrationPage";
import ResendActivationEmailPage from "../../pages/auth/ResendActivationEmail/ResendActivationEmailPage";
import TermsAndConditionsDocumentPage from "../../pages/documents/TermsAndConditionsDocumentPage";
import HomezoneUnsubscribePage from "../../pages/homezone/HomezoneUnsubscribePage";
import {
  ACCOUNT_ACTIVATION_ROUTE,
  ACCOUNT_CREATED_ROUTE,
  FORGOT_PASSWORD_EMAIL_SENT_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORGOT_PASSWORD_SUBMIT_ROUTE,
  HOMEZONE_UNSUBSCRIBE_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  RESEND_ACTIVATION_EMAIL_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
} from "../routes";

/*
 * All of the pages available to logged out users
 */
const NotAuthenticatedRouter = () => {
  return (
    <Switch>
      {/*Public routes first */}
      <Route exact path={TERMS_AND_CONDITION_ROUTE}>
        <TermsAndConditionsDocumentPage />
      </Route>

      <Route exact path={HOMEZONE_UNSUBSCRIBE_ROUTE}>
        <HomezoneUnsubscribePage />
      </Route>

      <Route path={LOGIN_ROUTE}>
        <LoginPage />
      </Route>

      <Route path={REGISTRATION_ROUTE}>
        <RegistrationPage />
      </Route>

      <Route path={FORGOT_PASSWORD_ROUTE}>
        <ForgotPasswordRequestPage />
      </Route>

      <Route path={FORGOT_PASSWORD_SUBMIT_ROUTE}>
        <ForgotPasswordSubmitPage />
      </Route>

      <Route path={ACCOUNT_CREATED_ROUTE}>
        <AccountCreatedPage />
      </Route>

      <Route path={FORGOT_PASSWORD_EMAIL_SENT_ROUTE}>
        <ForgotPasswordEmailSentPage />
      </Route>

      <Route path={RESEND_ACTIVATION_EMAIL_ROUTE}>
        <ResendActivationEmailPage />
      </Route>

      <Route path={ACCOUNT_ACTIVATION_ROUTE}>
        <AccountActivationPage />
      </Route>

      <Route>
        <Redirect to={LOGIN_ROUTE} />
      </Route>
    </Switch>
  );
};

export default NotAuthenticatedRouter;
