//Error enum
export enum EEmailValidationError {
  EMPTY = "empty",
  TRAILING_DOT = "trailingDot",
  LEADING_DOT = "leadingDot",
  SPACES = "spaces",
  NO_AT = "noAt",
  MULTIPLE_AT = "multipleAt",
  NO_LOCAL_PART = "noLocalPart",
  DOUBLE_DOT = "doubleDot",
  UNICODE_CHARS = "unicodeChars",
  NO_TLD = "noTopLevelDomain",
  EXTRA_DOTS = "extraDots",
  EXTRA_CHARS = "extraChars",
  SPECIAL_CHARS = "specialChars",
  INVALID_EMAIL = "invalidEmail",
}

//Interface
interface IEmailValidation {
  isValid: boolean;
  validationErrors: EEmailValidationError[];
}

const exportEmailValidator = (email: string): IEmailValidation => {
  const emailValidation: IEmailValidation = {
    isValid: true,
    validationErrors: [],
  };

  const emailString = email.trim();

  if (emailString === "") {
    return {
      ...emailValidation,
      isValid: false,
      validationErrors: [EEmailValidationError.EMPTY],
    };
  }

  if (!genericEmailValidation.test(emailString)) {
    emailValidation.isValid = false;

    if (hasNoAt(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.NO_AT);
    }

    if (multipleAt(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.MULTIPLE_AT);
    }

    if (noLocalPart(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.NO_LOCAL_PART);
    }

    if (multipleDots(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.DOUBLE_DOT);
    }

    if (noTld(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.NO_TLD);
    }

    if (trailingDot(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.TRAILING_DOT);
    }

    if (extraDots(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.EXTRA_DOTS);
    }

    if (extraSpaces(emailString)) {
      emailValidation.validationErrors.push(EEmailValidationError.SPACES);
    }

    if (emailValidation.validationErrors.length === 0) {
      emailValidation.validationErrors.push(EEmailValidationError.INVALID_EMAIL);
    }
  }

  if (!noUnicodeChars.test(emailString)) {
    emailValidation.isValid = false;
    emailValidation.validationErrors.push(EEmailValidationError.UNICODE_CHARS);
  }

  return emailValidation;
};

//Test functions
const hasNoAt = (email: string) => {
  return email.split("@").length < 2;
};

const multipleAt = (email: string) => {
  return email.split("@").length > 2;
};

const trailingDot = (email: string) => {
  return email.charAt(email.length - 1) === ".";
};

const extraSpaces = (email: string) => {
  return email.split(" ").length > 1;
};

const noLocalPart = (email: string) => {
  return email.split("@").length < 2 || email.split("@")[0] === "";
};

const multipleDots = (email: string) => {
  return email.split("..").length > 1;
};

const noTld = (email: string) => {
  return email.split(".").length < 2;
};

const extraDots = (email: string) => {
  const emailArray = email.split("@");
  return (
    emailArray.length === 2 &&
    (email[0].charAt(email[0].length - 1) === "." || email[0].charAt(0) === ".")
  );
};

//Regex list
const noUnicodeChars: RegExp = /^[\x20-\x7E]*$/;
const genericEmailValidation: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default exportEmailValidator;
