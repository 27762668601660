import { put, select, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import UserDetails from "../../../../rest/user/model/UserDetails";
import {
  LoadPlatformUserAction,
  loadPlatformUserSuccess,
  LOAD_PLATFORM_USER_TYPE,
  platformUserError,
  RemoveUserFromOrgAction,
  removeUserFromOrgError,
  RemoveUserFromOrgErrorAction,
  removeUserFromOrgSuccess,
  RemoveUserFromOrgSuccessAction,
  REMOVE_USER_FROM_ORG_ERROR_TYPE,
  REMOVE_USER_FROM_ORG_SUCCESS_TYPE,
  REMOVE_USER_FROM_ORG_TYPE,
  UpdateUserDetailsAction,
  UPDATE_USER_DETAILS_TYPE,
} from "./PlatformUserDetailAction";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* platformUserDetailSagas() {
  yield takeLatest(LOAD_PLATFORM_USER_TYPE, loadPlatformUserSaga);
  yield takeLatest(UPDATE_USER_DETAILS_TYPE, updateUserDetailsSaga);
  yield takeLatest(REMOVE_USER_FROM_ORG_TYPE, removeUserFromOrgSaga);
  yield takeLatest(REMOVE_USER_FROM_ORG_SUCCESS_TYPE, removeUserFromOrgSuccessSaga);
  yield takeLatest(REMOVE_USER_FROM_ORG_ERROR_TYPE, removeUserFromOrgErrorSaga);
}






function* loadPlatformUserSaga(action: LoadPlatformUserAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const userDetails: UserDetails = yield adminRepo.fetchUserDetails(action.userId);

    const orgs: MemberOrganisation[] = yield adminRepo.fetchUserOrganisations(
      action.userId
    );

    yield put(loadPlatformUserSuccess(userDetails, orgs));
  } catch (e) {
    const message = getErrorMessage(e, "platformUserDetail.loadError");
    yield put(platformUserError(message));
  }
}

function* updateUserDetailsSaga(action: UpdateUserDetailsAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const updatedDetails: UserDetails = yield adminRepo.updateUserDetails(action.details);
    const rootState: RootState = yield select();
    let orgs = rootState.platformUserDetail.userOrganisations;

    if (orgs === undefined) {
      orgs = yield adminRepo.fetchUserOrganisations(action.details.id);
    }

    yield put(
      loadPlatformUserSuccess(
        { ...updatedDetails, is_tester: (updatedDetails.is_tester as any) === "1" },
        orgs!
      )
    );
  } catch (e) {
    const message = getErrorMessage(e, "platformUserDetail.updateError");
    yield put(platformUserError(message));
  }
}

/*
Three following generator functions handle removing a user from an organisation.
Instead of using a reducer for changing the state on taking the success or error,
we listen for the actions and put 'loadPlatformUserSuccess' or 'loadPlatformUserError',
to avoid double-calling the methods and reducing interdependancies (as other parts of the application
also listen for 'loadPlatformUserSuccess' or 'loadPlatformUserError')
*/

function* removeUserFromOrgSaga(action: RemoveUserFromOrgAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const removedUser: OrganisationUser = yield adminRepo.removeUserFromOrganisation(
      action.organisation.id,
      action.user.id
    );
    yield put(removeUserFromOrgSuccess(removedUser.user, action.organisation));
  } catch (e) {
    const message = getErrorMessage(e, "platformUserDetail.removeError");
    yield put(removeUserFromOrgError(message));
  }
}

function* removeUserFromOrgSuccessSaga(action: RemoveUserFromOrgSuccessAction) {
  const rootState: RootState = yield select();
  let orgs = rootState.platformUserDetail.userOrganisations;

  if (orgs !== undefined) {
    orgs = orgs.filter((o) => {
      if (o.id === action.organisation.id || o.role.via === action.organisation.id) {
        return false;
      } else {
        return true;
      }
    });
  }
  yield put(loadPlatformUserSuccess(action.user, orgs!));
}

function* removeUserFromOrgErrorSaga(action: RemoveUserFromOrgErrorAction) {
  const message = action.error;
  yield put(platformUserError(message));
}

export default platformUserDetailSagas;
