import { Box, Grid } from "@material-ui/core";
import React from "react";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail, { RailLink } from "../../../../ui/components/NavigationRail";
import SuccessSnackbarHandler from "../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import { DEFAULT_SPACING, EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";
import ChangePassword from "../model/ChangePassword";
import CurrentUserDetails from "../model/CurrentUserDetails";
import ChangePasswordPanel from "./ChangePasswordPanel";
import UserDetailsPanel from "./UserDetailsPanel";

interface Props {
  pageTitle: string;
  navLinks: RailLink[];
  user: CurrentUserDetails;
  loading: boolean;
  error: string | undefined;
  success: string | undefined;
  onUserUpdated: (user: CurrentUserDetails) => void;
  onPasswordChanged: (password: ChangePassword, message: string) => void;
}

const UserPanel = (props: Props) => {
  return (
    <Box>
      <Box padding={DEFAULT_SPACING} display="flex" justifyContent="space-between">
        <NavigationRail currentPageTitle={props.pageTitle} links={props.navLinks} />
      </Box>
      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Grid container justify="center">
          <Grid item xs={5}>
            <UserDetailsPanel
              user={props.user}
              loading={props.loading}
              error={props.error}
              onUserUpdated={props.onUserUpdated}
            />
          </Grid>

          <Grid item xs={5}>
            <ChangePasswordPanel
              loading={props.loading}
              onPasswordChanged={props.onPasswordChanged}
            />
          </Grid>
        </Grid>
      </Box>

      <ErrorSnackbarHandler message={props.error} />
      <SuccessSnackbarHandler message={props.success} />
    </Box>
  );
};

export default UserPanel;
