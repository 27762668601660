import RegistrationAction from "./RegistrationAction";
import RegistrationState from "./RegistrationState";

const initialState: RegistrationState = {
  isRegistering: false,
  registeredSuccessfully: false,
  error: undefined,
};

const registrationReducer = (
  state: RegistrationState = initialState,
  action: RegistrationAction
): RegistrationState => {
  switch (action.type) {
    case "REGISTER_START_TYPE":
      return {
        isRegistering: true,
        registeredSuccessfully: false,
        error: undefined,
      };

    case "REGISTER_SUCCESS_TYPE":
      return {
        isRegistering: false,
        registeredSuccessfully: true,
        error: undefined,
      };

    case "REGISTER_ERROR_TYPE":
      return {
        isRegistering: false,
        registeredSuccessfully: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default registrationReducer;
