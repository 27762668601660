import PersonalInfoFormData from "../../../../rest/user/model/PersonalInfoFormData";
import SegmentationAnswer from "../../../../rest/user/model/SegmentationAnswer";
import CreateOrganisationForm from "../pages/CreateOrganisation/model/CreateOrganisationForm";

export const USER_NOT_SETUP_TYPE = "USER_NOT_SETUP_TYPE";

export interface UserNotSetupAction {
  type: typeof USER_NOT_SETUP_TYPE;
  hasOrganisation: boolean;
  hasPersonalDetails: boolean;
  hasFinishedSegmentation: boolean;
}

export const userNotSetup = (args: {
  hasOrganisation: boolean;
  hasPersonalDetails: boolean;
  hasFinishedSegmentation: boolean;
}): UserNotSetupAction => {
  return {
    ...args,
    type: USER_NOT_SETUP_TYPE,
  };
};

export const USER_SETUP_TYPE = "USER_SETUP_TYPE";

export interface UserSetupAction {
  type: typeof USER_SETUP_TYPE;
}

export const userSetup = (): UserSetupAction => {
  return {
    type: USER_SETUP_TYPE,
  };
};

export const PERSONAL_INFO_SUBMITTED_TYPE = "PERSONAL_INFO_SUBMITTED_TYPE";

export interface PersonalInfoSubmittedAction {
  type: typeof PERSONAL_INFO_SUBMITTED_TYPE;
  form: PersonalInfoFormData;
}

export const personalInfoSubmitted = (
  form: PersonalInfoFormData
): PersonalInfoSubmittedAction => {
  return {
    type: PERSONAL_INFO_SUBMITTED_TYPE,
    form: form,
  };
};

export const SEGMENTATION_DATA_SUBMITTED_TYPE = "SEGMENTATION_DATA_SUBMITTED_TYPE";

export interface SegmentationDataSubmittedAction {
  type: typeof SEGMENTATION_DATA_SUBMITTED_TYPE;
  segmentationData: SegmentationAnswer[];
}

export const segmentationDataSubmitted = (
  segmentationData: SegmentationAnswer[]
): SegmentationDataSubmittedAction => {
  return {
    type: SEGMENTATION_DATA_SUBMITTED_TYPE,
    segmentationData: segmentationData,
  };
};

export const ACCOUNT_SETUP_ERROR_TYPE = "ACCOUNT_SETUP_ERROR_TYPE";

export interface AccountSetupErrorAction {
  type: typeof ACCOUNT_SETUP_ERROR_TYPE;
  errorMessage: string;
}

export const accountSetupError = (message: string): AccountSetupErrorAction => {
  return {
    type: ACCOUNT_SETUP_ERROR_TYPE,
    errorMessage: message,
  };
};

export const CREATE_ORGANISATION_SUBMITTED_TYPE = "CREATE_ORGANISATION_SUBMITTED_TYPE";

export interface CreateOrganisationSubmittedAction {
  type: typeof CREATE_ORGANISATION_SUBMITTED_TYPE;
  form: CreateOrganisationForm;
}

export const createOrganisationSubmitted = (form: CreateOrganisationForm) => {
  return {
    type: CREATE_ORGANISATION_SUBMITTED_TYPE,
    form: form,
  };
};

type AccountSetupAction =
  | UserNotSetupAction
  | PersonalInfoSubmittedAction
  | AccountSetupErrorAction
  | CreateOrganisationSubmittedAction
  | UserSetupAction
  | SegmentationDataSubmittedAction;

export default AccountSetupAction;
