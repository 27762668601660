import { Box, Divider } from "@material-ui/core";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Property from "../../../../rest/properties/model/Property";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail, { RailLink } from "../../../../ui/components/NavigationRail";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import AddressPanel from "./AddressPanel";
import DeletePropertyHandler from "./DeletePropertyHandler/DeletePropertyHandler";
import HomeZoneSwitchPanel from "./HomeZoneSwitchPanel/HomeZoneSwitchPanel";
import ProductGrid from "./ProductGrid";
import PropertyContractorPanel from "./PropertyContractorPanel/PropertyContractorPanel";
import PropertyOwnerOrganisationPanel from "./PropertyOwnerOrganisationPanel";
import MovePropertiesHandler from "../../PropertyListPage/components/MovePropertiesHandler/MovePropertiesHandler";

interface Props {
  pageTitle: string;
  navLinks: RailLink[];
  property: Property;
  loading: boolean;
  error: string | undefined;
  owner: Organisation | undefined;
  contractorOrgName: string | undefined;
  organisationId: string;
  lookupEnabled?: boolean;
  homezoneAvailable?: boolean;
  isValidProperty?: boolean;
  proCheckReportIds?: string[];
  onMoreInfoClicked: () => void;
  onSenseDetailsClicked: (deviceId: string) => void;
  onPropertyUpdated: (property: Property) => void;
}

const PropertyDetailPanel = ({ ...props }: Props) => {
  const homezoneAvailable = props.homezoneAvailable ?? true;

  return (
    <Box>
      <Box padding={DEFAULT_SPACING} display="flex" justifyContent="space-between">
        <NavigationRail currentPageTitle={props.pageTitle} links={props.navLinks} />
        <Box display="flex" flexDirection="row">
          <MovePropertiesHandler properties={[props.property]} />
          <Box marginRight={SMALL_SPACING} />
          <DeletePropertyHandler
            property={props.property}
            organisationId={props.organisationId}
          />
        </Box>
      </Box>

      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Box display="flex" flexDirection="row">
          <Box flex={1} marginRight={LARGE_SPACING}>
            <AddressPanel
              lookupEnabled={props.lookupEnabled}
              property={props.property}
              onPropertyUpdated={props.onPropertyUpdated}
              loading={props.loading}
              error={props.error}
              isValidProperty={!!props.isValidProperty}
            />
          </Box>
          <Box flex={1} marginLeft={LARGE_SPACING}>
            {props.owner && (
              <>
                <PropertyOwnerOrganisationPanel
                  marginButton={SMALL_SPACING}
                  ownerOrganisationName={props.owner?.name}
                  ownerOrgId={props.owner.id}
                />

                <PropertyContractorPanel
                  organisation={props.owner}
                  property={props.property}
                />
              </>
            )}
            {homezoneAvailable && (
              <HomeZoneSwitchPanel
                marginTop={props.owner ? SMALL_SPACING : undefined}
                loading={props.loading}
                disabled={!props.property.contact_email}
                homezoneEnabled={props.property.homezone_reporting_enabled}
                onSwitchToggle={(homezoneStatus: boolean) => {
                  props.onPropertyUpdated({
                    ...props.property,
                    homezone_reporting_enabled: homezoneStatus,
                  });
                }}
              />
            )}
          </Box>
        </Box>

        <Box marginY={DEFAULT_SPACING}>
          <Divider />
        </Box>

        <ProductGrid
          onMoreInfoClicked={props.onMoreInfoClicked}
          onProductDetailsClicked={props.onSenseDetailsClicked}
        />
      </Box>

      <ErrorSnackbarHandler message={props.error} />
    </Box>
  );
};

export default PropertyDetailPanel;
