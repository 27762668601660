import { AxiosInstance, AxiosRequestConfig } from "axios";
import { unwrapData } from "../utils/DataWrapper";
import LoqateRepository, { LoqateCountryCode } from "./LoqateRepository";
import LoqateFindResponse from "./model/LoqateFindResponse";
import LoqateRetrieveResponse from "./model/LoqateRetrieveResponse";

class RestLoqateRepository implements LoqateRepository {
  constructor(private axios: AxiosInstance) {}

  findAddresses = (
    searchText: string,
    country: LoqateCountryCode
  ): Promise<LoqateFindResponse> => {
    const url = `/api/v1/find/${searchText}`;
    const config: AxiosRequestConfig = { params: { countries: country } };

    return this.axios.get<LoqateFindResponse>(url, config).then(unwrapData);
  };

  expandContainer = (
    searchText: string,
    containerId: string,
    country: LoqateCountryCode
  ): Promise<LoqateFindResponse> => {
    const url = `/api/v1/find/${searchText}/container/${encodeURIComponent(containerId)}`;
    const config: AxiosRequestConfig = { params: { countries: country } };

    return this.axios.get<LoqateFindResponse>(url, config).then(unwrapData);
  };

  retrieveAddress = (addressId: string): Promise<LoqateRetrieveResponse> => {
    const url = `/api/v1/retrieve/${encodeURIComponent(addressId)}`;

    return this.axios.get<LoqateRetrieveResponse>(url).then(unwrapData);
  };
}

export default RestLoqateRepository;
