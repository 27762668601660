import whiteLogo from "./adey_sense_logo_white.png";
import blueLogo from "./adey_sense_logo_blue.png";
import fillLogo from "./adey_sense_logo_white_fill.png";
import React from "react";

const defaultHeight = 30;

interface Props {
  height?: number;
  fill?: boolean;
  color: "white" | "blue";
}

const AdeyLogo = (props: Props) => {
  const height = props.height || defaultHeight;
  const logo = props.color === "white" ? whiteLogo : blueLogo;

  return (
    <img
      style={{ maxHeight: height, width: "auto" }}
      src={props.fill ? fillLogo : logo}
      alt=""
    />
  );
};

export default AdeyLogo;
