import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { Check, Clear, Description } from "@material-ui/icons";
import { useEffect } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../../theme/createMaterialTheme";
import { LARGE_SPACING, SMALL_SPACING } from "../../theme/dimensions";
import ExportItemModel from "./models/ExportItemModel";

interface ExternalProps {
  exportItem: ExportItemModel;
  onCancelClicked?: () => void;
}

type Props = ExternalProps & WithTranslation;

const fontSize = "14px";

//Props destructuring, to stop React complaining about props mutability
const ExportItem = ({ exportItem, ...props }: Props) => {
  //Translated strings
  const initialisingGeneration = props.t("exportItem.initializing");
  const generatingTitle = props.t("exportItem.generating");
  const fileReadyTitle = props.t("exportItem.generated");
  const fileErrorTitle = props.t("exportItem.failure");
  const cancelledTitle = props.t("exportItem.cancelled");

  //NOTE: Not sure about those, would have to change it if it breaks the logic
  //Handlers

  //If report generation resulted in an error, dispatch error handler and cleanup
  useEffect(() => {
    if (exportItem.processed === 1 && exportItem.url !== null) {
      const link = document.createElement("a");
      link.href = exportItem.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [exportItem.processed, exportItem.url]);

  const renderExportText = () => {
    if (exportItem.cancelled) {
      return cancelledTitle;
    }
    if (exportItem.processed === null && exportItem.processed === null) {
      return initialisingGeneration;
    }
    if (
      exportItem.url === null &&
      exportItem.failed !== 1 &&
      exportItem.processed !== 1
    ) {
      return generatingTitle;
    } else if (exportItem.url !== null && exportItem.processed === 1) {
      return fileReadyTitle;
    } else if (exportItem.url === null && exportItem.failed === 1) {
      return fileErrorTitle;
    } else {
      return undefined;
    }
  };

  //Renderers
  const renderStatusIcon = () => {
    if (
      exportItem.url === null &&
      exportItem.failed !== 1 &&
      exportItem.processed !== 1
    ) {
      return <CircularProgress size={fontSize} color="secondary" />;
    } else if (exportItem.url !== null && exportItem.processed === 1) {
      return <Check style={{ color: "#069247" }} fontSize="small" />;
    } else if (exportItem.url === null && exportItem.failed === 1) {
      return <Clear style={{ color: "#FF0000" }} fontSize="small" />;
    } else {
      return undefined;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding={SMALL_SPACING}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row">
        <Description color="secondary" style={{ marginRight: LARGE_SPACING }} />
        <Typography variant="body1">{renderExportText()}</Typography>
      </Box>
      <Box paddingX={SMALL_SPACING}>{renderStatusIcon()}</Box>
      {props.onCancelClicked !== undefined && (
        <IconButton size="small" onClick={props.onCancelClicked}>
          <FontAwesomeIcon color={PRIMARY_COLOR} icon={faTimes} />
        </IconButton>
      )}
    </Box>
  );
};

export default withTranslation()(ExportItem);
