import { Box, Divider, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ProCheckResult from "../../../../../rest/procheck/model/ProCheckResult";
import { PropertyNotification } from "../../../../../rest/properties/model/Property";
import { convertPriorityToRAG } from "../../../../../rest/properties/utils/convertRAGToStatus";
import DeviceLogo from "../../../../../ui/assets/DeviceImages/DeviceLogo";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import ColoredText from "../../../../../ui/components/ColoredText";
import LabelledText from "../../../../../ui/components/LabelledText";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING } from "../../../../../ui/theme/dimensions";
import { formatDate } from "../../../../../utils/formatDate";
import RAGStatusPill from "../../../../properties/PropertyDetailPage/components/RAGStatusPill";
import { mapStatusMessage } from "../../../../properties/PropertyListPage/utils/mapToPropertyTableItem";

interface Props extends WithT {
  results: ProCheckResult[];

  onMoreInfoClicked: () => void;
  notification: PropertyNotification | undefined;
}

const ProCheckResultsTile = (props: Props) => {
  const latestTest = props.results.length > 0 ? props.results[0] : undefined;

  let formattedTestDate: string;
  if (latestTest) {
    // Dates that contain a dash aren't supported by safari,
    // replace them with slashes to fix this.
    // https://stackoverflow.com/a/5646753/11528182
    // https://adeyinnovation.atlassian.net/browse/SPD-279
    formattedTestDate = formatDate(new Date(latestTest.tested_at), "dd-MM-yyyy");
  } else {
    formattedTestDate = props.t("proCheck.noTestsLabel");
  }

  const statusMessage = mapStatusMessage(props.notification, props.t);
  const rag =
    props.notification && !!props.notification.priority
      ? convertPriorityToRAG(props.notification.priority)
      : "green";

  const productTitle = props.t("proCheck.productTitle");
  const statusTitle = props.t("proCheck.statusTitle");
  const title = props.t("proCheck.name");
  const latestTestTitle = props.t("proCheck.latestTestTitle");
  const moreInfoButton = props.t("proCheck.moreInfoButton");

  return (
    <BorderedBox display="flex" flexDirection="column" height="100%">
      <Box width="100%" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
            <strong>{productTitle}</strong>
          </ColoredText>

          {latestTest ? (
            <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
              <strong>{statusTitle}</strong>
            </ColoredText>
          ) : undefined}
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center">
            <DeviceLogo deviceType="PROCHECK" height={60} />
            <Typography variant="h5">{title}</Typography>
          </Box>

          {latestTest ? <RAGStatusPill status={rag} text={statusMessage} /> : undefined}
        </Box>
        <Divider
          style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: EXTRA_LARGE_SPACING }}
        />
      </Box>

      <Box width="100%" display="flex" flexDirection="column" flexGrow={2}>
        <LabelledText label={latestTestTitle} text={formattedTestDate} />
      </Box>

      <Box width="100%">
        <Divider
          style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: EXTRA_LARGE_SPACING }}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          minHeight="50px"
        >
          <Box>
            <OutlineButton label={moreInfoButton} onClick={props.onMoreInfoClicked} />
          </Box>
        </Box>
      </Box>
    </BorderedBox>
  );
};

export default withTranslation()(ProCheckResultsTile);
