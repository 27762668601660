import UserDetailAction from "./UserDetailActions";
import UserDetailState from "./UserDetailState";

const initialState: UserDetailState = {
  user: undefined,
  error: undefined,
  loading: false,
  organisation: undefined,
  canRemoveUser: false,
  isRemovingUser: false,
  userRemoved: false,
};

const userDetailReducer = (
  state = initialState,
  action: UserDetailAction
): UserDetailState => {
  switch (action.type) {
    case "LOAD_USER_TYPE":
      return {
        user: undefined,
        error: undefined,
        loading: true,
        organisation: undefined,
        isRemovingUser: false,
        userRemoved: false,
        canRemoveUser: false,
      };

    case "LOAD_USER_SUCCESS_TYPE":
      return {
        user: action.user,
        error: undefined,
        loading: true,
        organisation: action.organisation,
        isRemovingUser: false,
        userRemoved: false,
        canRemoveUser: action.canRemoveUser,
      };

    case "LOAD_USER_ERROR_TYPE":
      return {
        user: undefined,
        error: action.error,
        loading: false,
        organisation: undefined,
        isRemovingUser: false,
        userRemoved: false,
        canRemoveUser: false,
      };

    case "REMOVE_USER_TYPE":
      return {
        ...state,
        isRemovingUser: true,
        userRemoved: false,
        error: undefined,
      };

    case "REMOVE_USER_SUCCESS_TYPE":
      return {
        ...state,
        isRemovingUser: false,
        userRemoved: true,
        error: undefined,
      };

    case "REMOVE_USER_ERROR_TYPE":
      return {
        ...state,
        isRemovingUser: false,
        userRemoved: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default userDetailReducer;
