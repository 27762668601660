import { Button, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  SELECTABLE_ITEM_COLOR,
  SELECTABLE_ITEM_HOVER_COLOR,
} from "../theme/createMaterialTheme";
import { LARGE_SPACING } from "../theme/dimensions";

interface SecondaryButtonProps {
  label?: string;
  labelId?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  filled?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

type Props = SecondaryButtonProps & WithTranslation;

const SecondaryButton = (props: Props) => {
  // If filled is true, use withStyles to create a
  // button that has a different background color
  const ButtonComponent = props.filled
    ? withStyles({
        root: {
          backgroundColor: SELECTABLE_ITEM_COLOR,
          "&:hover": {
            backgroundColor: SELECTABLE_ITEM_HOVER_COLOR,
          },
        },
      })(Button)
    : Button;

  return (
    <ButtonComponent
      variant="text"
      disableElevation
      color="secondary"
      onClick={props.onClick}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    >
      <strong>
        {props.labelId}
        {props.label}
      </strong>

      {props.loading ? (
        <CircularProgress
          style={{ marginLeft: LARGE_SPACING }}
          color="secondary"
          size={14}
        />
      ) : undefined}
    </ButtonComponent>
  );
};

export default withTranslation()(SecondaryButton);
