import PlatformUserDetailAction from "./PlatformUserDetailAction";
import PlatformUserDetailState from "./PlatformUserDetailState";

const initialState: PlatformUserDetailState = {
  userDetails: undefined,
  userOrganisations: undefined,
  loading: false,
  error: undefined,
  removingUserFromOrg: undefined,
  removedUserFromOrg: undefined,
};

const platformUserReducer = (
  state = initialState,
  action: PlatformUserDetailAction
): PlatformUserDetailState => {
  switch (action.type) {
    case "LOAD_PLATFORM_USER_TYPE":
      return { ...initialState, loading: true, removedUserFromOrg: undefined };

    case "LOAD_PLATFORM_USER_SUCCESS_TYPE":
      return {
        userDetails: action.userDetails,
        userOrganisations: action.organisations,
        loading: false,
        error: undefined,
        removingUserFromOrg: undefined,
        removedUserFromOrg: state.removedUserFromOrg,
      };

    case "PLATFORM_USER_ERROR_TYPE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "REMOVE_USER_FROM_ORG_TYPE":
      return {
        ...state,
        removingUserFromOrg: true,
      };

    case "REMOVE_USER_FROM_ORG_SUCCESS_TYPE":
      return {
        ...state,
        removedUserFromOrg: true,
        removingUserFromOrg: false,
      };

    case "REMOVE_USER_FROM_ORG_ERROR_TYPE":
      return {
        ...state,
        removedUserFromOrg: false,
        removingUserFromOrg: false,
      };

    case "UPDATE_USER_DETAILS_TYPE":
      return {
        ...state,
        loading: true,
      };


  

    default:
      return state;
  }
};

export default platformUserReducer;
