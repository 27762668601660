import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import {
  SECONDARY_COLOR,
  SELECTABLE_ITEM_COLOR,
} from "../../../../../../ui/theme/createMaterialTheme";
import { BORDER_RADIUS, DEFAULT_SPACING } from "../../../../../../ui/theme/dimensions";

const padding = 1;
const width = 200;

interface Props {
  title: string;
  isOpen: boolean;
  children: JSX.Element;
  didChangeOpen: (open: boolean) => void;
}

const FilterMenuItem = (props: Props) => {
  const content = props.isOpen ? (
    <Box paddingX={padding} paddingBottom={padding}>
      {props.children}
    </Box>
  ) : undefined;

  return (
    <Box
      display="flex"
      width={width}
      bgcolor={SELECTABLE_ITEM_COLOR}
      borderRadius={BORDER_RADIUS}
      flexDirection="column"
    >
      <FilterMenuItemHeaderSection {...props} />

      <Box paddingX="4px">{content}</Box>
    </Box>
  );
};

const FilterMenuItemHeaderSection = (props: Props) => {
  const icon = props.isOpen ? faChevronUp : faChevronDown;

  const onClick = () => {
    props.didChangeOpen(!props.isOpen);
  };

  return (
    <Button style={{ textTransform: "initial" }} onClick={onClick}>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="button" color="secondary">
          <strong>{props.title}</strong>
        </Typography>

        <FontAwesomeIcon
          color={SECONDARY_COLOR}
          icon={icon}
          style={{ marginRight: DEFAULT_SPACING }}
        />
      </Box>
    </Button>
  );
};

export default FilterMenuItem;
