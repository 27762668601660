import React from "react";
import MovePropertiesButton from "../../../properties/PropertyListPage/components/MovePropertiesHandler/MovePropertiesButton";
import useMoveSenseDevice from "./hooks/useMoveSenseDevice";
import MoveDeviceDialog from "./ui/MoveDeviceDialog";
import SenseDevice from "../../../../rest/sense/model/SenseDevice";

interface IProps {
  senseDevices: SenseDevice[];
}

const SenseDeviceMoveHandler = (props: IProps) => {
  const {
    moveDeviceOpen,
    searchString,
    destinations,
    selectedDestination,
    activeTab,
    loading,
    moveResults,
    handleOpenMoveDialog,
    handleMoveDevice,
    handleSelectedDestination,
    handleSetSearchString,
    handleSearchPressed,
    handleBackPressed,
    handleNextPressed,
    handleSuccessPressed,
  } = useMoveSenseDevice();

  return (
    <>
      <MovePropertiesButton onClick={handleOpenMoveDialog} />
      <MoveDeviceDialog
        activeTab={activeTab}
        destinations={destinations}
        searchValue={searchString}
        open={moveDeviceOpen || activeTab === "success"}
        handleSetSearchString={handleSetSearchString}
        handleSearchPressed={handleSearchPressed}
        handleOpen={handleOpenMoveDialog}
        isLoading={loading}
        moved={undefined}
        selectedDestination={selectedDestination}
        setSelectedDestination={handleSelectedDestination}
        handleBackPressed={handleBackPressed}
        handleNextPressed={handleNextPressed}
        handleMovePressed={handleMoveDevice}
        handleSuccessPressed={handleSuccessPressed}
        selectedDevices={props.senseDevices}
        moveResults={moveResults}
      />
    </>
  );
};

export default SenseDeviceMoveHandler;
