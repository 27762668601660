import React from "react";
import { PrimaryTextFieldProps } from "../../../../../ui/components/PrimaryTextField";
import FieldEditor from "./components/FieldEditor";
import HoverableField from "./components/HoverableField";

interface Props {
  label: string;
  text: string | undefined;
  isEditing: boolean;
  hoverEnabled: boolean;
  loading?: boolean;
  required?: boolean;
  gutterBottom?: boolean;
  editorFieldProps?: Omit<PrimaryTextFieldProps, "value">;
  onEditClicked: () => void;
  onCancelClicked: () => void;
  onSaveClicked: (value: string) => void;
}

const EditableField = (props: Props) => {
  if (props.isEditing) {
    return (
      <FieldEditor
        {...props}
        textFieldProps={props.editorFieldProps}
        onCancel={props.onCancelClicked}
        onSave={props.onSaveClicked}
        required={props.required || false}
        loading={props.loading || false}
      />
    );
  } else {
    return (
      <HoverableField
        {...props}
        onClick={props.onEditClicked}
        hoverEnabled={props.hoverEnabled}
        paddingBottom={props.gutterBottom}
      />
    );
  }
};

export default EditableField;
