import { select } from "@redux-saga/core/effects";
import RootState from "../RootState";

// Convenience method to check if you're an admin.
function* isAdeyAdmin() {
  const rootState: RootState = yield select();
  return rootState.adeyAdmin.hasAdminAccess;
}

export default isAdeyAdmin;
