import { put, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../../../redux/utils/getDependency";
import AdminRepository from "../../../../../../rest/admin/AdminRepository";
import getErrorMessage from "../../../../../../errors/messages/getErrorMessage";
import {
  AdminInviteSubmittedAction,
  adminInviteSubmittedError,
  adminInviteSubmittedSuccess,
  ADMIN_INVITE_SUBMITTED_TYPE,
} from "./InviteOrganisationAdminAction";

function* inviteOrganisationAdminSagas() {
  yield takeLatest(ADMIN_INVITE_SUBMITTED_TYPE, submitInvite);
}

function* submitInvite(action: AdminInviteSubmittedAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");
    yield adminRepo.inviteUser(action.email);
    yield put(adminInviteSubmittedSuccess());
  } catch (e) {
    const message = getErrorMessage(e, "inviteOrganisationAdminForm.error");
    yield put(adminInviteSubmittedError(message));
  }
}

export default inviteOrganisationAdminSagas;
