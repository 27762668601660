import { Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { InviteType } from "../../../../rest/organisation/model/InvitationForm";
import { RoleName } from "../../../../rest/roles/model/Role";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import RolePicker from "../../../../ui/components/RolePicker";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";

//MARK: Props
interface Props extends WithT {
  isLoading: boolean;
  type: InviteType;
  canInviteDevelopers?: boolean;
  sendInvite: (email: string, type: InviteType, roleName?: RoleName) => void;
  forcedUserRole?: RoleName;
}

//MARK: State
interface State {
  email: FieldState;
  confirmEmail: FieldState;
  selectedRole: RoleName | undefined;
  roleError: string | undefined;
}

class InviteEntityPanel extends React.Component<Props, State> {
  state = {
    email: { value: "" },
    confirmEmail: { value: "" },
    selectedRole: this.props.forcedUserRole || undefined,
    roleError: undefined,
  };

  render() {
    //MARK: Translated Strings
    const titleKey = this.getTitle();

    const title = this.props.t(`inviteEntityPanel.${titleKey}`);
    const emailHint = this.props.t("inviteEntityPanel.emailHint");
    const confirmEmailHint = this.props.t("inviteEntityPanel.confirmEmailHint");
    const submitButton = this.props.t("inviteEntityPanel.submitButton");
    const rolePickerHint = this.props.t("inviteEntityPanel.userTypeHint");
    const contactHint = this.props.t("inviteEntityPanel.contactHint");
    const contactEmail = this.props.t("inviteEntityPanel.contactEmail");
    const descriptionText = this.props.t("inviteEntityPanel.descriptionText");
    const heatingEngineerContext = this.props.t(
      "adminInviteUserDialog.heatingEngineerContext"
    );
    const adminContext = this.props.t("adminInviteUserDialog.adminContext");
    const developerContext = this.props.t("adminInviteUserDialog.developerContext");

    //Helpers
    const showContextText = (role: RoleName | undefined) => {
      switch (role) {
        case "administrator":
          return adminContext;
        case "installer":
          return heatingEngineerContext;
        case "developer":
          return developerContext;
        default:
          return undefined;
      }
    };

    const getAvailableRoles = (): RoleName[] => {
      if (this.props.canInviteDevelopers) {
        return ["administrator", "installer", "developer"];
      } else {
        return ["administrator", "installer"];
      }
    };

    const rolePicker =
      this.props.type === "user" ? (
        <Grid item xs={12}>
          <RolePicker
            role={this.state.selectedRole}
            availableRoles={getAvailableRoles()}
            hint={rolePickerHint}
            onRoleChanged={this.handleRoleChanged}
            errorText={this.state.roleError}
            helperText={showContextText(this.state.selectedRole)}
          />
        </Grid>
      ) : undefined;

    const contactBlock =
      this.props.type === "contractor" ? (
        <Grid item xs={12} alignItems="center">
          <Typography variant="body2" align="center">
            {descriptionText}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              textTransform: "uppercase",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
            color="primary"
          >
            {contactHint}
            <a href={"mailto:" + contactEmail}>{contactEmail}</a>
          </Typography>
        </Grid>
      ) : undefined;

    return (
      <Grid container spacing={SMALL_SPACING} alignContent="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <strong>{title.toUpperCase()}</strong>
          </Typography>
        </Grid>

        <Grid item />

        {contactBlock}

        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.email}
            disabled={this.props.isLoading}
            hint={emailHint}
            onChange={(v) => this.onFieldChanged("email", v)}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryTextField
            {...this.state.confirmEmail}
            disabled={this.props.isLoading}
            hint={confirmEmailHint}
            onChange={(v) => this.onFieldChanged("confirmEmail", v)}
            type="email"
          />
        </Grid>

        {rolePicker}

        <Grid item xs={12}>
          <PrimaryButton
            onClick={this.handleSubmitClicked}
            fullWidth
            label={submitButton}
            endIcon={<ArrowIcon />}
            isLoading={this.props.isLoading}
          />
        </Grid>
      </Grid>
    );
  }

  getTitle = () => {
    switch (this.props.type) {
      case "user":
        return "inviteUserTitle";
      case "agent":
        return "inviteAgentTitle";
      case "contractor":
        return "inviteContractorTitle";
    }
  };

  //Validator
  validate = () => {
    const email = this.state.email.value.trim();
    const confirmEmail = this.state.confirmEmail.value.trim();
    const role = this.state.selectedRole;
    let valid = true;

    if (email.length === 0) {
      valid = false;
      this.setState({
        email: {
          value: email,
          errorText: this.props.t("inviteEntityPanel.emailError"),
        },
      });
    }

    if (confirmEmail.length === 0) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: email,
          errorText: this.props.t("inviteEntityPanel.confirmEmailError"),
        },
      });
    } else if (confirmEmail !== email) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: confirmEmail,
          errorText: this.props.t("inviteEntityPanel.emailMatchError"),
        },
      });
    }

    if (role === undefined && this.props.type === "user") {
      valid = false;
      this.setState({
        roleError: this.props.t("inviteEntityPanel.userTypeRequiredError"),
      });
    }

    return { valid: valid, email: email, confirmEmail: confirmEmail };
  };

  //Handlers
  onFieldChanged = (
    field: keyof Omit<State, "selectedRole" | "roleError">,
    value: string
  ) => {
    const newState = { ...this.state };
    newState[field] = { value: value };
    this.setState(newState);
  };

  handleRoleChanged = (role: RoleName) => {
    this.setState({ selectedRole: role, roleError: undefined });
  };

  handleSubmitClicked = () => {
    const validation = this.validate();
    if (validation.valid) {
      this.props.sendInvite(
        validation.email,
        this.props.type,
        this.props.type === "agent" ? "administrator" : this.state.selectedRole
      );
    }
  };
}

export default withTranslation()(InviteEntityPanel);
