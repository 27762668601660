import AuthService from "../pages/auth/Login/service/AuthService";
import CognitoAuthService from "../pages/auth/Login/service/CognitoAuthService";
import LoadingBackgroundService from "../pages/loading/service/LoadingBackgroundService";
import AdminRepository from "../rest/admin/AdminRepository";
import RestAdminRepository from "../rest/admin/RestAdminRepository";
import buildAxiosInstance from "../rest/buildAxiosInstance";
import InviteRepository from "../rest/invites/InviteRepository";
import RestInviteRepository from "../rest/invites/RestInviteRepository";
import OrganisationContractorRepository from "../rest/organisation-contractor/OrganisationContractorRepository";
import OrganisationUserRepository from "../rest/organisation-user/OrganisationUserRepository";
import RestOrganisationUserRepository from "../rest/organisation-user/RestOrganisationUserRepository";
import OrganisationRepository from "../rest/organisation/OrganisationRepository";
import RestOrganisationRepository from "../rest/organisation/RestOrganisationRepository";
import PermissionsRepository from "../rest/permissions/PermissionsRepository";
import RestPermissionsRepository from "../rest/permissions/RestPermissionsRepository";
import PropertiesRepository from "../rest/properties/PropertiesRepository";
import RestPropertiesRepository from "../rest/properties/RestPropertiesRepository";
import RestRoleRepository from "../rest/roles/RestRoleRepository";
import RoleRepository from "../rest/roles/RoleRepository";
import RestUserRepository from "../rest/user/RestUserRepository";
import UserRepository from "../rest/user/UserRepository";
import RestOrganisationContractorRepository from "../rest/organisation-contractor/RestOrganisationContractorRepository";
import OrganisationPermissionsService from "../ui/components/OrganisationSwitcher/service/OrganisationPermissionsService";
import PendingInviteService from "../pages/invites/service/PendingInviteService";
import OrganisationDetailService from "../pages/organisations/details/service/OrganisationDetailService";
import Env from "../app/environment/Env";
import LoqateRepository from "../rest/loqate/LoqateRepository";
import RestLoqateRepository from "../rest/loqate/RestLoqateRepository";
import ProCheckRepository from "../rest/procheck/ProCheckRepository";
import RestProCheckRepository from "../rest/procheck/RestProCheckRepository";
import PropertyDetailService from "../pages/properties/PropertyDetailPage/service/PropertyDetailService";
import ProCheckService from "../pages/procheck/service/ProCheckService";
import MetricsRepository from "../rest/metrics/MetricsRepository";
import RestMetricsRepository from "../rest/metrics/RestMetricsRepository";
import CountryRepository from "../rest/countries/CountryRepository";
import RestCountryRepository from "../rest/countries/RestCountryRepository";
import RestExportRepository from "../rest/export/RestExportRepository";
import RestSenseRepository from "../rest/sense/RestSenseRepository";
import SenseDeviceService from "../pages/devices/services/SenseDeviceService";
import ProClubRepository from "../rest/proclub/ProClubRepository";
import RestProClubRepository from "../rest/proclub/RestProClubRepository";
import RestSystemMessageRepository from "../rest/system/RestSystemMessageRepository";
import AuthProxyService from "../pages/auth/Login/service/AuthProxyService";
import RestAuthProxyService from "../pages/auth/Login/service/RestAuthProxyService";
import MockProCheckRepository from "../rest/procheck/MockProCheckRepository";
import AdminMoveEntityService from "../pages/admin/AdminMoveEntity/services/AdminMoveEntityService";

// These are all the dependencies that are made available
const authService: AuthService = new CognitoAuthService();
const loadingBackgroundService = new LoadingBackgroundService();
const axios = buildAxiosInstance(Env.apiBaseUrl, authService, true);
const loqateAxios = buildAxiosInstance(Env.loqateBaseUrl, authService, false);
const proClubAxios = buildAxiosInstance(Env.proClubBaseUrl, authService, true);

const authProxyService: AuthProxyService = new RestAuthProxyService(axios);
const userRepository: UserRepository = new RestUserRepository(axios);
const organisationRepository: OrganisationRepository = new RestOrganisationRepository(
  axios
);
const proClubRepository: ProClubRepository = new RestProClubRepository(proClubAxios);
const adminRepository: AdminRepository = new RestAdminRepository(axios);
const permissionsRepository: PermissionsRepository = new RestPermissionsRepository(axios);
const inviteRepository: InviteRepository = new RestInviteRepository(axios);
const organisationUserRepository: OrganisationUserRepository =
  new RestOrganisationUserRepository(axios);
const propertiesRepository: PropertiesRepository = new RestPropertiesRepository(axios);
const roleRepository: RoleRepository = new RestRoleRepository(axios);
const organisationContractorRepository: OrganisationContractorRepository =
  new RestOrganisationContractorRepository(axios);
const proCheckRepository: ProCheckRepository = new RestProCheckRepository(axios);
const loqateRepository: LoqateRepository = new RestLoqateRepository(loqateAxios);
const metricsRepository: MetricsRepository = new RestMetricsRepository(axios);

const countryRepository: CountryRepository = new RestCountryRepository(axios);

const organisationPermissionsService = new OrganisationPermissionsService(
  permissionsRepository
);

const pendingInviteService = new PendingInviteService(inviteRepository);

const organisationDetailService = new OrganisationDetailService(
  organisationContractorRepository
);

const exportRepository = new RestExportRepository(axios);

const propertyDetailService = new PropertyDetailService(
  propertiesRepository,
  organisationRepository,
  organisationContractorRepository,
  adminRepository
);
const mockProCheckRepository = new MockProCheckRepository();

const proCheckService = new ProCheckService(
  proCheckRepository,
  propertiesRepository,
  mockProCheckRepository,
  adminRepository
);

const senseDevicesRepository = new RestSenseRepository(axios);

const senseDevicesService = new SenseDeviceService(
  senseDevicesRepository,
  propertiesRepository,
  adminRepository
);

const systemMessagesRepository = new RestSystemMessageRepository(axios);

const adminMoveEntityService = new AdminMoveEntityService(
  adminRepository,
  propertiesRepository
);

// App wide context that is passed down to the sagas.
export const defaultSagaContext = {
  authService: authService,
  loadingBackgroundService: loadingBackgroundService,
  userRepository: userRepository,
  organisationRepository: organisationRepository,
  adminRepository: adminRepository,
  permissionsRepository: permissionsRepository,
  inviteRepository: inviteRepository,
  organisationUserRepository: organisationUserRepository,
  propertiesRepository: propertiesRepository,
  roleRepository: roleRepository,
  proClubRepository: proClubRepository,
  organisationContractorRepository: organisationContractorRepository,
  organisationPermissionsService: organisationPermissionsService,
  pendingInviteService: pendingInviteService,
  organisationDetailService: organisationDetailService,
  loqateRepository: loqateRepository,
  proCheckRepository: proCheckRepository,
  propertyDetailService: propertyDetailService,
  proCheckService: proCheckService,
  metricsRepository: metricsRepository,
  countryRepository: countryRepository,
  exportRepository: exportRepository,
  senseDeviceRepository: senseDevicesRepository,
  senseDeviceService: senseDevicesService,
  systemMessagesRepository: systemMessagesRepository,
  authProxyService: authProxyService,
  adminMoveEntityService: adminMoveEntityService,
};

type SagaContext = typeof defaultSagaContext;

export default SagaContext;
