import { put, select, takeLatest } from "@redux-saga/core/effects";
import { takeEvery } from "redux-saga/effects";
import RootState from "../../../../redux/RootState";
import getDependency from "../../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import OrganisationUserRepository from "../../../../rest/organisation-user/OrganisationUserRepository";
import Role from "../../../../rest/roles/model/Role";
import RoleRepository from "../../../../rest/roles/RoleRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import {
  ResendInviteSuccessAction,
  RESEND_INVITE_SUCCESS_TYPE,
  RevokeInviteSuccessAction,
  REVOKE_INVITE_SUCCESS_TYPE,
} from "../../../../ui/components/PendingInviteMenu/redux/PendingInviteMenuAction";
import {
  LoadOrganisationAction,
  LOAD_ORGANISATION_ADMIN_TYPE,
  updateOrganisationSuccess,
} from "../../../organisations/details/redux/OrganisationDetailActions";
import {
  RemoveUserFromOrgErrorAction,
  RemoveUserFromOrgSuccessAction,
  REMOVE_USER_FROM_ORG_ERROR_TYPE,
  REMOVE_USER_FROM_ORG_SUCCESS_TYPE,
  REMOVE_USER_FROM_ORG_TYPE,
} from "../../PlatformUserDetailPage/redux/PlatformUserDetailAction";
import fetchOrgContractorDetails from "../utils/fetchOrgContractorDetails";
import fetchOrgUserDetails from "../utils/fetchOrgUserDetails";
import {
  agentAccessGranted,
  agentAssigned,
  agentAssignError,
  AssignAgentAction,
  ASSIGN_AGENT_TYPE,
  contractorsLoaded,
  contractorsLoadError,
  GrantAgentAccessAction,
  grantAgentAccessError,
  GRANT_AGENT_ACCESS_TYPE,
  InitLinkedOrgRemovalAction,
  initUserRemoval,
  InviteContractorAction,
  inviteContractorError,
  inviteContractorSuccess,
  InviteUserAction,
  inviteUserError,
  inviteUserSuccess,
  INVITE_CONTRACTOR_TYPE,
  INVITE_USER_TYPE,
  linkedOrgRemovalFailure,
  linkedOrgRemovalSuccess,
  LINKED_ORG_REMOVAL_INIT_TYPE,
  loadContractors,
  LoadContractorsAction,
  loadOrgUsers,
  LoadOrgUsersAction,
  LOAD_CONTRACTORS_TYPE,
  LOAD_ORG_USERS_TYPE,
  orgUsersLoaded,
  orgUsersLoadError,
  userRemovalFailure,
  userRemovalSuccess,
} from "./AdminOrganisationDetailsAction";
import { OrgContractorDetails, OrgUserDetails } from "./AdminOrganisationDetailsState";
import inviteErrorHandler from "../../../../rest/invites/errors/inviteErrorHandler";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { updateOrgListEntry } from "../../PlatformOrganisationListPage/redux/PlatformOrganisationListAction";
import { resetProperty } from "../../../properties/PropertyDetailPage/redux/PropertyDetailActions";

function* adminOrganisationDetailsSagas() {
  yield takeLatest(LOAD_ORGANISATION_ADMIN_TYPE, loadOrgSaga);
  //Load contractor sagas
  yield takeLatest(LOAD_CONTRACTORS_TYPE, loadContractorsSaga);
  yield takeLatest(LOAD_ORG_USERS_TYPE, loadUsersSaga);
  //Resend invite sagas
  yield takeEvery(RESEND_INVITE_SUCCESS_TYPE, resendInviteSaga);
  yield takeEvery(REVOKE_INVITE_SUCCESS_TYPE, revokeInviteSaga);
  //Invite contractor sagas
  yield takeLatest(INVITE_CONTRACTOR_TYPE, inviteContractorSaga);
  yield takeLatest(INVITE_USER_TYPE, inviteUserSaga);
  //Remove user sagas
  yield takeLatest(REMOVE_USER_FROM_ORG_TYPE, userRemoveInitSaga);
  yield takeLatest(REMOVE_USER_FROM_ORG_SUCCESS_TYPE, userRemoveSuccessSaga);
  yield takeLatest(REMOVE_USER_FROM_ORG_ERROR_TYPE, userRemoveFailureSaga);
  //Remove linked organisation sagas
  yield takeLatest(LINKED_ORG_REMOVAL_INIT_TYPE, removeLinkedOrgSaga);
  //Agent access saga
  yield takeLatest(GRANT_AGENT_ACCESS_TYPE, grantAgentAccessSaga);
  //Assign agent saga
  yield takeLatest(ASSIGN_AGENT_TYPE, assignAgentSaga);
}

function* loadOrgSaga(action: LoadOrganisationAction) {
  const isAdmin: boolean = yield isAdeyAdmin();
  if (isAdmin) {
    yield put(loadContractors(action.organisationId));
    yield put(loadOrgUsers(action.organisationId));
  }
}

function* loadContractorsSaga(action: LoadContractorsAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const details: OrgContractorDetails = yield fetchOrgContractorDetails(
      action.organisationId,
      adminRepo
    );

    yield put(
      contractorsLoaded(details.contractors, details.associatedOrgs, details.invites)
    );
  } catch (e) {
    const message = getErrorMessage(e, "platformOrganisationDetail.contractorLoadError");
    yield put(contractorsLoadError(message));
  }
}

function* loadUsersSaga(action: LoadOrgUsersAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const details: OrgUserDetails = yield fetchOrgUserDetails(
      action.organisationId,
      adminRepo
    );

    yield put(orgUsersLoaded(details.users, details.invites));
  } catch (e) {
    const message = getErrorMessage(e, "platformOrganisationDetail.contractorLoadError");
    yield put(orgUsersLoadError(message));
  }
}

function* userRemoveInitSaga() {
  yield put(initUserRemoval());
}

function* userRemoveSuccessSaga(action: RemoveUserFromOrgSuccessAction) {
  const rootState: RootState = yield select();
  let users = rootState.adminOrganisationDetails.userDetails?.users;
  let invites = rootState.adminOrganisationDetails.userDetails?.invites;

  users = users?.filter((u) => u.user.id !== action.user.id);

  if (users && invites) {
    yield put(userRemovalSuccess());
    yield put(orgUsersLoaded(users, invites));
  }
}

function* userRemoveFailureSaga(action: RemoveUserFromOrgErrorAction) {
  yield put(userRemovalFailure());
  yield put(orgUsersLoadError(action.error));
}

function* revokeInviteSaga(action: RevokeInviteSuccessAction) {
  const rootState: RootState = yield select();
  const details =
    action.invite.type === "contractor"
      ? rootState.adminOrganisationDetails.contractorDetails
      : rootState.adminOrganisationDetails.userDetails;
  if (!details) {
    return;
  }

  const invites = details.invites.filter((i) => i.id !== action.invite.id);
  if (action.invite.type === "contractor") {
    yield put(
      contractorsLoaded(
        (details as OrgContractorDetails).contractors,
        (details as OrgContractorDetails).associatedOrgs,
        invites
      )
    );
  } else if (action.invite.type === "user") {
    yield put(orgUsersLoaded((details as OrgUserDetails).users, invites));
  }
}

function* resendInviteSaga(action: ResendInviteSuccessAction) {
  const rootState: RootState = yield select();

  const details =
    action.oldInvite.type === "contractor"
      ? rootState.adminOrganisationDetails.contractorDetails
      : rootState.adminOrganisationDetails.userDetails;
  const index = details?.invites.findIndex((i) => i.id === action.oldInvite.id);
  if (!details || index === undefined || index < 0) {
    return;
  }

  const invites = [...details.invites];
  invites[index] = action.newInvite;

  if (action.oldInvite.type === "contractor") {
    yield put(
      contractorsLoaded(
        (details as OrgContractorDetails).contractors,
        (details as OrgContractorDetails).associatedOrgs,
        invites
      )
    );
  } else if (action.oldInvite.type === "user") {
    yield put(orgUsersLoaded((details as OrgUserDetails).users, invites));
  }
}

function* inviteContractorSaga(action: InviteContractorAction) {
  try {
    const repo: OrganisationContractorRepository = yield getDependency(
      "organisationContractorRepository"
    );

    const invite: PendingInvite = yield repo.inviteOrganisationContractor(
      true,
      action.organisationId,
      action.email
    );
    yield put(inviteContractorSuccess(invite));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "adminInviteContractorDialog.inviteError",
      inviteErrorHandler
    );
    yield put(inviteContractorError(message));
  }
}

function* inviteUserSaga(action: InviteUserAction) {
  try {
    const repo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );
    const roleRepo: RoleRepository = yield getDependency("roleRepository");
    const isAdmin: boolean = yield isAdeyAdmin();

    const roles: Role[] = yield roleRepo.getAllRoles(isAdmin);
    const role = roles.filter((role) => role.name === action.role)[0];

    const invite: PendingInvite = yield repo.inviteOrganisationUser(
      action.organisationId,
      action.email,
      role.id,
      true
    );
    yield put(inviteUserSuccess(invite));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "adminInviteContractorDialog.inviteError",
      inviteErrorHandler
    );
    yield put(inviteUserError(message));
  }
}

function* removeLinkedOrgSaga(action: InitLinkedOrgRemovalAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");
    const rootState: RootState = yield select();

    const removedOrg: Organisation = yield adminRepo.removeContractorFromOrganisation(
      action.organisationId,
      action.contractorId
    );

    let organisations =
      action.organisationRelationship === "contractee"
        ? rootState.adminOrganisationDetails.contractorDetails?.associatedOrgs
        : rootState.adminOrganisationDetails.contractorDetails?.contractors;

    organisations = organisations?.filter((o) =>
      action.organisationRelationship === "contractee"
        ? o.id !== action.organisationId
        : o.id !== action.contractorId
    );

    let allOrganisations = rootState.adminOrganisationDetails.contractorDetails;

    if (allOrganisations !== undefined && organisations !== undefined) {
      action.organisationRelationship === "contractee"
        ? (allOrganisations.associatedOrgs = organisations)
        : (allOrganisations.contractors = organisations);
      yield put(
        contractorsLoaded(
          allOrganisations.contractors,
          allOrganisations.associatedOrgs,
          allOrganisations.invites
        )
      );
      yield put(resetProperty());
      yield put(linkedOrgRemovalSuccess(removedOrg, action.organisationRelationship));
    }
  } catch (e) {
    const message = getErrorMessage(e, undefined);
    yield put(linkedOrgRemovalFailure(message));
  }
}

function* grantAgentAccessSaga(action: GrantAgentAccessAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");
    const isAdmin: boolean = yield isAdeyAdmin();

    if (isAdmin) {
      const updateOrganisation: Organisation = {
        ...action.org,
        is_agent_for: { ...action.org.address_country },
      };

      const agentOrganisation: Organisation = yield adminRepo.updateOrganisation(
        updateOrganisation
      );

      yield put(updateOrganisationSuccess(agentOrganisation));
      yield put(agentAccessGranted(agentOrganisation));
      yield put(updateOrgListEntry(agentOrganisation));
    }
  } catch (e) {
    const message = getErrorMessage(
      e,
      "platformOrganisationDetail.agentAccessGrantError"
    );
    yield put(grantAgentAccessError(message));
  }
}

function* assignAgentSaga(action: AssignAgentAction) {
  try {
    const adminRepo: AdminRepository = yield getDependency("adminRepository");
    const isAdmin: boolean = yield isAdeyAdmin();

    if (isAdmin) {
      const updateOrganisation: Organisation = {
        ...action.org,
        agent: action.agent_id
          ? {
              ...action.org,
              id: action.agent_id,
              name: "",
            }
          : undefined,
      };

      const updatedOrg: Organisation = yield adminRepo.updateOrganisation(
        updateOrganisation
      );

      yield put(agentAssigned(updatedOrg));
      yield put(updateOrganisationSuccess(updatedOrg));
      yield put(updateOrgListEntry(updatedOrg));
    }
  } catch (e) {
    const message = getErrorMessage(e, "platformOrganisationDetail.agentAssignmentError");
    yield put(agentAssignError(message));
  }
}

export default adminOrganisationDetailsSagas;
