import { TFunction } from "i18next";
import { InviteOrganisationAdminFormState } from "./InviteOrganisationAdminForm";

const validateInviteOrganisationAdminForm = (
  t: TFunction,
  state: InviteOrganisationAdminFormState
) => {
  let isValid = true;
  let newState = { ...state };

  if (state.emailAddress.value.trim().length === 0) {
    isValid = false;
    newState.emailAddress = {
      ...newState.emailAddress,
      errorText: t("inviteOrganisationAdminForm.emailRequiredError"),
    };
  }

  if (state.confirmEmailAddress.value.trim().length === 0) {
    isValid = false;
    newState.confirmEmailAddress = {
      ...newState.confirmEmailAddress,
      errorText: t("inviteOrganisationAdminForm.confirmEmailRequiredError"),
    };
  } else if (state.confirmEmailAddress.value.trim() !== state.emailAddress.value.trim()) {
    isValid = false;
    newState.confirmEmailAddress = {
      ...newState.confirmEmailAddress,
      errorText: t("inviteOrganisationAdminForm.emailMatchError"),
    };
  }

  return {
    isValid: isValid,
    newState: newState,
  };
};

export default validateInviteOrganisationAdminForm;
