import React from "react";
import { OrganisationUserInvitation } from "../../../../../rest/invites/model/PendingInvite";
import CenteredAuthCard from "../../../../../ui/components/CenteredAuthCard";
import AcceptOrganisationInvitePanel from "../../../../invites/components/AcceptOrganisationInvitePanel";

interface Props {
  pendingInvite: OrganisationUserInvitation;
}

const AcceptOrganisationInvitePage = (props: Props) => {
  return (
    <CenteredAuthCard includeSignOut>
      <AcceptOrganisationInvitePanel pendingInvite={props.pendingInvite} />
    </CenteredAuthCard>
  );
};

export default AcceptOrganisationInvitePage;
