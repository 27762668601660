import {
  DeviceNotificationType,
  SenseDeviceCodeStatus,
} from "../../../../../rest/sense/model/SenseDeviceStatus";

const getSenseNotification = (type: DeviceNotificationType): SenseDeviceCodeStatus => {
  switch (type) {
    case "battery":
      return {
        code: 3000,
        description: "Battery OK",
        priority: 3,
      };
    case "contact":
      return {
        code: 4000,
        description: "Contact Nominal",
        priority: 3,
      };
    case "magnetite":
      return {
        code: 5000,
        description: "Magnetite Level OK",
        priority: 3,
      };
    case "service":
      return {
        code: 6000,
        description: "Service Passed",
        priority: 3,
      };
  }
};

export default getSenseNotification;
