import AdeyAgentAction from "./AdeyAgentAction";
import AdeyAgentState from "./AdeyAgentState";

const initialState: AdeyAgentState = {
  hasAgentAccess: false,
};

const adeyAgentReducer = (
  state = initialState,
  action: AdeyAgentAction
): AdeyAgentState => {
  switch (action.type) {
    case "AGENT_ACCESS_CHANGED_TYPE":
      return {
        hasAgentAccess: action.hasAgentAccess,
      };

    default:
      return state;
  }
};

export default adeyAgentReducer;
