import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, TableCell, TableRow, withStyles } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { BORDER_RADIUS, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import RAGSymbol from "../../../properties/PropertyListPage/components/RAGSymbol";
import ProductReportTableItem from "../model/ProductReportTableItem";

interface Props extends WithTranslation {
  item: ProductReportTableItem;
  isSelected: boolean;
  isSelectingDisabled: boolean;
  onExportClicked?: (report: ProductReportTableItem) => void;
  onReportChange?: (report: ProductReportTableItem, selected: boolean) => void;
  onReportClicked: (report: ProductReportTableItem) => void;
  selectable?: boolean;
  showPropertyAddress?: boolean;
  showTesterName?: boolean;
  showContractorName?: boolean;
}

const ProductReportTableRow = (props: Props) => {
  const StyledCell = withStyles({
    root: {
      border: "solid 1px #0000001F",
      borderStyle: "solid none",
      padding: "8px",
      "&:first-child": {
        borderLeftStyle: "solid",
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,
      },
      "&:last-child": {
        borderRightStyle: "solid",
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      },
    },
  })(TableCell);

  const handleCheckmarkSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onReportChange) {
      props.onReportChange(props.item, event.target.checked);
    }
  };

  const handleReportClicked = () => {
    props.onReportClicked(props.item);
  };

  const handleExportClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (props.onExportClicked) {
      props.onExportClicked(props.item);
    }
  };

  return (
    <TableRow
      selected={props.isSelected}
      onClick={handleReportClicked}
      style={{ cursor: "pointer" }}
      hover
    >
      {props.selectable && !props.showTesterName && !props.showContractorName && (
        <StyledCell
          padding="checkbox"
          style={{ ...(props.isSelectingDisabled && { pointerEvents: "none" }) }}
        >
          <Checkbox
            onChange={handleCheckmarkSelected}
            onClick={(event) => event.stopPropagation()}
            checked={props.isSelected}
            disabled={props.isSelectingDisabled}
          />
        </StyledCell>
      )}

      <StyledCell>
        <Box display="flex" paddingX={SMALL_SPACING}>
          <RAGSymbol status={props.item.status} />
          <Box marginLeft="6px">{props.item.statusMessage}</Box>
        </Box>
      </StyledCell>
      <StyledCell>{props.item.date}</StyledCell>
      {props.showContractorName && <StyledCell>{props.item.contractor}</StyledCell>}
      {props.showTesterName && <StyledCell>{props.item.tester}</StyledCell>}

      {props.showPropertyAddress ? (
        <StyledCell>{props.item.propertyAddress}</StyledCell>
      ) : (
        <StyledCell>{props.item.email}</StyledCell>
      )}
      {props.selectable && props.showTesterName && props.showContractorName && (
        <StyledCell
          padding="checkbox"
          style={{ ...(props.isSelectingDisabled && { pointerEvents: "none" }) }}
        >
          <Checkbox
            onChange={handleCheckmarkSelected}
            onClick={(event) => event.stopPropagation()}
            checked={props.isSelected}
            disabled={props.isSelectingDisabled}
          />
        </StyledCell>
      )}

      {props.onExportClicked && (
        <StyledCell align="right">
          <SecondaryButton
            labelId={props.t("productReportTable.emailReportLabel")}
            endIcon={<FontAwesomeIcon icon={faShareSquare} />}
            onClick={handleExportClicked}
          />
        </StyledCell>
      )}
    </TableRow>
  );
};

export default withTranslation()(ProductReportTableRow);
