import React, { ReactNode, KeyboardEvent } from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import { Trans } from "react-i18next";

// all form input types
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
type InputType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export interface FieldState {
  value: string;
  errorText?: string | undefined;
}

export interface SelectOption {
  value: string;
  labelId: string;
}

export interface PrimaryTextFieldProps extends FieldState {
  hint?: string;
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  type?: InputType;
  disabled?: boolean;
  autocomplete?: string;
  multiline?: boolean;
  helperText?: string;
  selectOptions?: SelectOption[];
  onChange?: (value: string) => void;
  onEnter?: () => void;
  onFocus?: () => void;
}

class PrimaryTextField extends React.Component<PrimaryTextFieldProps> {
  render() {
    // default fullWidth to true
    const fullWidth = this.props.fullWidth === undefined ? true : this.props.fullWidth;

    const hasError = this.props.errorText !== undefined && this.props.errorText !== "";
    const label = hasError ? this.props.errorText : this.props.hint;
    const isMultiline = this.props.type === "text" && this.props.multiline;

    const startAdornment = this.props.startIcon ? (
      <InputAdornment position="start">{this.props.startIcon}</InputAdornment>
    ) : undefined;

    const endAdornment = this.props.endIcon ? (
      <InputAdornment position="end">{this.props.endIcon}</InputAdornment>
    ) : undefined;

    return (
      <TextField
        value={this.props.value}
        onChange={this.changeHandler}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        color="secondary"
        label={label}
        variant="outlined"
        fullWidth={fullWidth}
        type={this.props.type}
        onKeyPress={this.keyPressHandler}
        error={hasError}
        multiline={isMultiline}
        select={this.props.selectOptions !== undefined}
        autoComplete={this.props.autocomplete}
        helperText={this.props.helperText}
        FormHelperTextProps={{
          style: {
            whiteSpace: "pre-line",
          },
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
      >
        {this.props.selectOptions?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Trans>{option.labelId}</Trans>
          </MenuItem>
        ))}
      </TextField>
    );
  }

  keyPressHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      this.props.onEnter?.();
    }
  };

  changeHandler = (event: React.ChangeEvent<Element>) => {
    // check if onChange is undefined, and also
    // that the typecast won't fail
    const value = (event.target as any).value;
    if (this.props.onChange !== undefined && typeof value === "string") {
      this.props.onChange?.(value);
    }
  };
}

export default PrimaryTextField;
