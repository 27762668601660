import { Button, IconButton, Snackbar, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ReactLinkify from "react-linkify";
import linkifyUrlMatcher from "../../../utils/linkifyUrlMatcher";

interface Props {
  message: string | undefined;
  onClose: () => void;
}

const ErrorSnackbar = (props: Props) => {
  const open = !!props.message;

  // Full width snackbar
  const alertStyle = { width: "100%" };

  //Translation
  const { t } = useTranslation();

  return (
    <Snackbar
      key={props.message}
      message={props.message}
      open={open}
      onClose={props.onClose}
      autoHideDuration={null}
      style={{}}
      action={<Button>Close</Button>}
    >
      <Alert
        style={alertStyle}
        severity="error"
        variant="filled"
        action={
          <IconButton size="small" onClick={props.onClose}>
            <FontAwesomeIcon color="white" icon={faTimes} />
          </IconButton>
        }
      >
        {/* 
          Wrap this in a <Trans> tag so that if it's a key it'll be localized
          If it's not a translation it'll be left as is.
        */}
        <ReactLinkify
          componentDecorator={(href, text, key) => (
            <a href={href} style={{ color: "white" }}>
              {text}
            </a>
          )}
          matchDecorator={(text) => linkifyUrlMatcher(text, t)}
        >
          <Typography align="center" style={{ whiteSpace: "pre-line" }}>
            {t(props.message || "")}
          </Typography>
        </ReactLinkify>
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
