import AccountActivationAction from "./AccountActivationAction";
import AccountActivationState from "./AccountActivationState";

const initialState: AccountActivationState = {
  type: "idle",
  error: undefined,
};

const accountActivationReducer = (
  state: AccountActivationState = initialState,
  action: AccountActivationAction
): AccountActivationState => {
  switch (action.type) {
    case "START_ACTIVATION_TYPE":
      return {
        type: "loading",
        error: undefined,
      };

    case "ACTIVATION_ERROR_TYPE":
      return {
        type: "error",
        error: action.error,
      };

    case "ACTIVATION_SUCCESS_TYPE":
      return {
        type: "success",
        error: undefined,
      };
  }

  return state;
};

export default accountActivationReducer;
