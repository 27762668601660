import { Box, Typography, withStyles } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { colorForStatus } from "../../../../pages/properties/PropertyListPage/model/RAGStatus";
import { SECONDARY_COLOR } from "../../../theme/createMaterialTheme";
import ColoredText from "../../ColoredText";
import { RAGCardType } from "./RAGCard";
import { titleForRAGStatus } from "../../../../pages/properties/PropertyListPage/model/RAGStatus";

interface Props extends WithT {
  type: RAGCardType;
  total: number | undefined;
  fullWidth: boolean;
}

const RAGCardText = (props: Props) => {
  const propertiesLabel = props.t("ragStatusCard.propertiesSubtitle");
  const title =
    props.type === "total"
      ? props.t("ragStatusCard.totalTitle")
      : titleForRAGStatus(props.type, props.t);

  const color = props.type === "total" ? SECONDARY_COLOR : colorForStatus(props.type);
  const propertiesNumber = props.total ? `${props.total}` : "-";

  const StyledTypography = withStyles({
    root: {
      position: "relative",
      width: "100%",
      marginTop: 16,
      lineHeight: 1.25,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      cursor: "pointer",
      WebkitLineClamp: 3,
      "&::after": {
        content: "''",
        width: "4em",
        height: "2ch",
        position: "absolute",
        bottom: 0,
        right: 0,
        pointerEvents: "none",
        background: "white",
        maskImage: "linear-gradient(to left, black, transparent)",
      },
      "&:hover": {
        overflow: "visible",
        display: "block",
        "&::after": {
          content: "''",
          width: "0px",
          height: "2ch",
          position: "absolute",
          bottom: 0,
          right: 0,
          pointerEvents: "none",
          background: "white",
          maskImage: "linear-gradient(to left, black, transparent)",
        },
      },
    },
  })(Typography);

  return (
    <Box
      width={props.fullWidth ? "30%" : "100%"}
      display="flex"
      flexDirection="column"
      paddingX={2}
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <StyledTypography variant="body2" color="secondary">
          {title}
        </StyledTypography>
      </div>

      <Box marginBottom={1} lineHeight={1}>
        <ColoredText variant="h1" textColor={color}>
          <strong>{propertiesNumber}</strong>
        </ColoredText>
        <Typography variant="caption" color="textSecondary">
          {propertiesLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default withTranslation()(RAGCardText);
