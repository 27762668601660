import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../../redux/RootState";
import SegmentationAnswer from "../../../../../rest/user/model/SegmentationAnswer";
import SegmentationQuestionPanel from "./components/SegmentationQuestionPanel";
import { segmentationDataSubmitted } from "../../redux/AccountSetupActions";
import LoadingPage from "../../../../loading/LoadingPage";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";

const CategorisationPage = () => {
  //Dispatch
  const dispatch = useDispatch();

  //Hooks
  const { t } = useTranslation();

  //Selector
  const segmentationQuestions = useSelector(
    (state: RootState) => state.login.onboardingQuestions ?? []
  );
  const userDetails = useSelector((state: RootState) => state.currentUser.user);
  const loading = useSelector(
    (state: RootState) => state.accountSetup.segmentation === "loading"
  );
  const error = useSelector((state: RootState) => state.accountSetup.error);

  //States
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [selectedAnswers, setSelectedAnswers] = useState<SegmentationAnswer[]>([]);

  //Strings
  const nextButtonLabel = t("buttons.next");
  const confirmButtonLabel = t("buttons.confirm");

  //Handlers
  const onBackPressed = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      const answers = [...selectedAnswers].filter(
        (answer) => answer.question_id !== segmentationQuestions[currentQuestion].id
      );
      setSelectedAnswers(answers);
    }
  };

  const onNextPressed = (selectedAnswer: SegmentationAnswer) => {
    if (currentQuestion < segmentationQuestions.length) {
      setCurrentQuestion(currentQuestion + 1);
      const answers = [...selectedAnswers].filter(
        (answer) => answer.question_id !== segmentationQuestions[currentQuestion].id
      );
      answers.push(selectedAnswer);
      setSelectedAnswers(answers);
    }
  };

  const onSubmitPressed = (lastSelectedAnswer: SegmentationAnswer) => {
    if (userDetails) {
      const answers = [...selectedAnswers];
      answers.push(lastSelectedAnswer);
      dispatch(segmentationDataSubmitted(answers));
    }
  };

  //Helpers
  const getActionLabel = () => {
    return segmentationQuestions.length === currentQuestion + 1
      ? confirmButtonLabel
      : nextButtonLabel;
  };

  const getActionFunction = () => {
    return segmentationQuestions.length === currentQuestion + 1
      ? onSubmitPressed
      : onNextPressed;
  };

  const getSelectedAnswer = () => {
    return selectedAnswers.find(
      (answer) => answer.question_id === segmentationQuestions[currentQuestion].id
    );
  };

  //Builders
  const buildQuestionPanel = () => {
    return (
      <SegmentationQuestionPanel
        categoryQuestion={segmentationQuestions[currentQuestion]}
        onBackPressed={onBackPressed}
        onActionPressed={getActionFunction()}
        actionLabel={getActionLabel()}
        loading={loading}
        selectedAnswer={getSelectedAnswer()}
        showBackButton={currentQuestion !== 0}
      />
    );
  };

  return (
    <>
      {segmentationQuestions.length === 0 && <LoadingPage />}
      {currentQuestion in segmentationQuestions && buildQuestionPanel()}
      <ErrorSnackbarHandler message={error} />
    </>
  );
};

export default CategorisationPage;
