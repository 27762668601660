// Start activation

export const START_ACTIVATION_TYPE = "START_ACTIVATION_TYPE";

export interface StartActivationEvent {
  type: typeof START_ACTIVATION_TYPE;
  token: string;
  username: string;
}

export const startActivation = (args: {
  username: string;
  token: string;
}): StartActivationEvent => ({
  type: START_ACTIVATION_TYPE,
  token: args.token,
  username: args.username,
});

// Activation success

export const ACTIVATION_SUCCESS_TYPE = "ACTIVATION_SUCCESS_TYPE";

export interface ActivationSuccessAction {
  type: typeof ACTIVATION_SUCCESS_TYPE;
}

export const activationSuccess = (): ActivationSuccessAction => ({
  type: ACTIVATION_SUCCESS_TYPE,
});

// Activation error

export const ACTIVATION_ERROR_TYPE = "ACTIVATION_ERROR_TYPE";

export interface ActivationErrorAction {
  type: typeof ACTIVATION_ERROR_TYPE;
  error: string;
}

export const activationError = (error: string): ActivationErrorAction => ({
  type: ACTIVATION_ERROR_TYPE,
  error: error,
});

// Union type

type AccountActivationAction =
  | StartActivationEvent
  | ActivationSuccessAction
  | ActivationErrorAction;

export default AccountActivationAction;
