import { put, takeLatest } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import InviteRepository from "../../../../rest/invites/InviteRepository";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import {
  ResendInviteAction,
  resendInviteError,
  resendInviteSuccess,
  RESEND_INVITE_TYPE,
  RevokeInviteAction,
  revokeInviteError,
  revokeInviteSuccess,
  REVOKE_INVITE_TYPE,
} from "./PendingInviteMenuAction";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* pendingInviteMenuSagas() {
  yield takeLatest(RESEND_INVITE_TYPE, resendInviteSaga);
  yield takeLatest(REVOKE_INVITE_TYPE, revokeInviteSaga);
}

function* resendInviteSaga(action: ResendInviteAction) {
  try {
    const inviteRepo: InviteRepository = yield getDependency("inviteRepository");
    const isAdmin: boolean = yield isAdeyAdmin();
    const orgId = action.invite.organisation.id;

    let response: PendingInvite = yield inviteRepo.resendInvitation(
      isAdmin,
      orgId,
      action.invite.id,
      action.invite.type
    );

    yield put(resendInviteSuccess(action.invite, response));
  } catch (e) {
    const message = getErrorMessage(e, "pendingInviteMenu.resendError");
    yield put(resendInviteError(message));
  }
}

function* revokeInviteSaga(action: RevokeInviteAction) {
  try {
    const inviteRepo: InviteRepository = yield getDependency("inviteRepository");
    const isAdmin: boolean = yield isAdeyAdmin();
    const orgId = action.invite.organisation.id;

    yield inviteRepo.revokeInvitation(
      isAdmin,
      orgId,
      action.invite.id,
      action.invite.type
    );

    yield put(revokeInviteSuccess(action.invite));
  } catch (e) {
    const message = getErrorMessage(e, "pendingInviteMenu.revokeError");
    yield put(revokeInviteError(message));
  }
}

export default pendingInviteMenuSagas;
