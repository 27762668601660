import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CircleAvatar from "../../CircleAvatar";
import ColoredText from "../../ColoredText";
import { DEFAULT_SPACING, LARGE_SPACING } from "../../../theme/dimensions";
import Organisation from "../../../../rest/organisation/model/Organisation";

interface Props {
  selectedOrganisation: Organisation | undefined;
  onClick: (e: React.MouseEvent<Element>) => void;
  textColor?: string;
}

const OrganisationSwitcherButton = (props: Props) => {
  const { t } = useTranslation();

  // default to white text
  const textColor = props.textColor || "#FFF";

  return (
    <ButtonBase onClick={props.onClick}>
      <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
        {props.selectedOrganisation ? (
          <CircleAvatar
            imageUrl={undefined}
            name={props.selectedOrganisation?.name ?? ""}
            size="large"
          />
        ) : undefined}

        <Box width={LARGE_SPACING} />

        <Box display="flex" flexDirection="column" justifyContent="center">
          <ColoredText textColor={textColor} align="left">
            <strong>{props.selectedOrganisation?.name ?? ""}</strong>
          </ColoredText>

          <Box display="flex" flexDirection="row" marginTop="-4px" alignItems="center">
            <ColoredText textColor={textColor} align="left" variant="button">
              {t("organisationSwitcher.accountButton")}
            </ColoredText>

            <Box width={DEFAULT_SPACING} />

            <FontAwesomeIcon color="#FFF" icon={faChevronDown} />
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default OrganisationSwitcherButton;
