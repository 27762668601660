import { takeLatest } from "@redux-saga/core/effects";
import { put, select, debounce, call, delay, race, take } from "redux-saga/effects";
import getDependency from "../../../redux/utils/getDependency";
import ProCheckResultFiles from "../../../rest/procheck/model/ProCheckResultFiles";
import {
  LoadPropertyAction,
  LOAD_PROPERTY_TYPE,
} from "../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import {
  DownloadReportAction,
  downloadReportError,
  downloadReportSuccess,
  DOWNLOAD_REPORT_TYPE,
  LoadOrgProCheckResultsAction,
  LoadProCheckDetailsAction,
  loadProCheckResults,
  LoadProCheckResultsAction,
  LoadUserProCheckResultsAction,
  LOAD_ORG_PROCHECK_RESULTS_TYPE,
  LOAD_PROCHECK_DETAILS_TYPE,
  LOAD_PROCHECK_RESULTS_TYPE,
  LOAD_USER_PROCHECK_RESULTS_TYPE,
  proCheckDetailsLoaded,
  proCheckDetailsLoadError,
  ProcheckFilterResultsAction,
  ProcheckNavigateToPageAction,
  proCheckResultsLoaded,
  proCheckResultsLoadError,
  PROCHECK_FILTER_RESULTS_TYPE,
  PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE,
} from "./ProCheckAction";
import ProCheckService from "../service/ProCheckService";
import isAdeyAdmin from "../../../redux/utils/isAdeyAdmin";
import RootState from "../../../redux/RootState";
import getErrorMessage from "../../../errors/messages/getErrorMessage";
import ProCheckResultDetails from "../../../rest/procheck/model/ProCheckResultDetails";
import convertProCheckDetailsToState from "../ProCheckDetailsPage/utils/convertProCheckDetailsToState";
import ProCheckRepository from "../../../rest/procheck/ProCheckRepository";
import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import normaliseProCheckResults from "../utils/normaliseProCheckResults";
import getProCheckReportArray from "../utils/getProCheckReportArray";
import buildPagination from "../utils/buildPagination";
import Page from "../../../rest/utils/Page";
import IPagination from "../../../utils/interfaces/IPagination";
import INormalisedResults from "../interfaces/INormalisedResults";

const _debounceTimeMilliseconds = 100;

function* proCheckSagas() {
  yield takeLatest(LOAD_PROPERTY_TYPE, propertyLoadSaga);
  yield takeLatest(LOAD_PROCHECK_RESULTS_TYPE, loadResultsSaga);

  /// https://adeyinnovation.atlassian.net/browse/SPD-559
  /// `debounce` will wait for a time before kicking off the saga. If another event
  /// occurs within that time then it restarts the timer. This stops the
  /// saga from being fired (and hence hitting the API) too many times.
  yield debounce(
    _debounceTimeMilliseconds,
    [LOAD_ORG_PROCHECK_RESULTS_TYPE, LOAD_USER_PROCHECK_RESULTS_TYPE],
    loadProCheckResultsSaga
  );

  yield takeLatest(
    [PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE, PROCHECK_FILTER_RESULTS_TYPE],
    loadProCheckFilterOrPaginationHandlerSaga
  );

  yield takeLatest(LOAD_PROCHECK_DETAILS_TYPE, loadDetailsSaga);
  yield takeLatest(DOWNLOAD_REPORT_TYPE, downloadReportSaga);
}

// Whenever we load property details, load the product details too.
function* propertyLoadSaga(action: LoadPropertyAction) {
  // If we've already loaded these details (and results are not undefined) don't do it again
  const rootState: RootState = yield select();

  const propertyId = getProCheckReportArray({
    resultsById: rootState.proCheck.results || {},
    allIds: rootState.proCheck.resultIds,
  }).find((result) => result.property.id)?.id;

  if (propertyId === action.propertyId && rootState.proCheck.results !== undefined) {
    return;
  }

  yield put(loadProCheckResults(action.organisationId, action.propertyId));
}

function* loadResultsSaga(action: LoadProCheckResultsAction) {
  try {
    const adeyAdmin: boolean = yield isAdeyAdmin();
    const service: ProCheckService = yield getDependency("proCheckService");

    const results: ProCheckResult[] = yield service.listResults(
      adeyAdmin,
      action.organisationId,
      action.propertyId
    );

    const normalisedResult = normaliseProCheckResults(results);
    const pagination = buildPagination(normalisedResult.allIds, 15);

    yield put(proCheckResultsLoaded(normalisedResult, pagination));
  } catch (e) {
    const message = getErrorMessage(e, "proCheck.loadError");
    yield put(proCheckResultsLoadError(message));
  }
}

function* loadProCheckResultsSaga(
  action:
    | LoadOrgProCheckResultsAction
    | ProcheckNavigateToPageAction
    | ProcheckFilterResultsAction
    | LoadUserProCheckResultsAction
) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const rootState: RootState = yield select();

    /// ProcheckFilterResultsAction doesn't have a `page` property
    /// so default to 1 if it's not there.
    const actionPage = (action as any).page;
    const page = typeof actionPage === "number" ? actionPage : 1;

    const service: ProCheckService = yield getDependency("proCheckService");

    const results: Page<ProCheckResult[]> =
      action.type === "LOAD_USER_PROCHECK_RESULTS_TYPE"
        ? yield service.loadUserResults(
            action.organisationId,
            isAdmin,
            action.userId,
            page,
            15,
            rootState.proCheck.searchString && rootState.proCheck.searchString?.length > 2
              ? rootState.proCheck.searchString
              : undefined
          )
        : yield service.loadOrgResults(
            action.organisationId,
            isAdmin,
            page,
            10,
            rootState.proCheck.searchString && rootState.proCheck.searchString?.length > 2
              ? rootState.proCheck.searchString
              : undefined
          );

    const normalisedResult = normaliseProCheckResults(results.data);

    const pagination: IPagination = {
      currentPage: results.meta.current_page,
      itemsPerPage: results.meta.per_page,
      displayItems: normalisedResult.allIds,
      maxPages: results.meta.last_page,
    };

    yield put(proCheckResultsLoaded(normalisedResult, pagination));
  } catch (e) {
    const message = getErrorMessage(e, "proCheck.loadError");
    yield put(proCheckResultsLoadError(message));
  }
}

function* loadProCheckFilterOrPaginationHandlerSaga(
  action: ProcheckNavigateToPageAction | ProcheckFilterResultsAction
) {
  while (true) {
    const { debounced, _action } = yield race({
      debounced: delay(500),
      _action: take(action.type),
    });

    if (debounced) {
      yield call(loadProCheckFilterOrPaginationSaga, action);
      break;
    }

    action = _action;
  }
}

function* loadProCheckFilterOrPaginationSaga(
  action: ProcheckNavigateToPageAction | ProcheckFilterResultsAction
) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const rootState: RootState = yield select();

    /// ProcheckFilterResultsAction doesn't have a `page` property
    /// so default to 1 if it's not there.
    const actionPage = (action as ProcheckNavigateToPageAction).page;
    const page = typeof actionPage === "number" ? actionPage : 1;

    const service: ProCheckService = yield getDependency("proCheckService");

    let pagination: IPagination;
    let normalisedResult: INormalisedResults;

    //We do not want to fetch results again in that case - this should happen only when a user revisits the page
    if (rootState.proCheck.proCheckResultsType === "property-reports") {
      normalisedResult = {
        resultsById: rootState.proCheck.results || {},
        allIds: rootState.proCheck.resultIds,
      };

      let extraIds: string[] = (action as ProcheckFilterResultsAction).extraIds || [];

      const filterResults = () => {
        let filteredResultIds: string[] = [];
        if ((action as any).value) {
          normalisedResult.allIds.forEach((id) => {
            if (
              normalisedResult.resultsById[id].user.email
                .toLowerCase()
                .trim()
                .includes(
                  (action as ProcheckFilterResultsAction).value.toLowerCase().trim()
                )
            ) {
              filteredResultIds.push(id);
            }
          });
        }

        return filteredResultIds;
      };

      let filteredResultIds = (action as any).value
        ? filterResults()
        : normalisedResult.allIds;

      const areResultsEqual =
        filteredResultIds.join("") === normalisedResult.allIds.join("");

      extraIds.forEach((id) => {
        filteredResultIds = filteredResultIds.filter((filteredId) => filteredId !== id);
      });

      pagination = buildPagination(
        filteredResultIds,
        15,
        areResultsEqual ? rootState.proCheck.pagination.currentPage : page
      );
      pagination.displayItems = [...(extraIds || []), ...pagination.displayItems];
    } else {
      const cachedSelectedResults: INormalisedResults = {
        resultsById: {},
        allIds: action.extraIds || [],
      };

      cachedSelectedResults.allIds.forEach((id) => {
        if (rootState.proCheck.results?.[id]) {
          cachedSelectedResults.resultsById[id] = rootState.proCheck.results?.[id];
        }
      });

      const results: Page<ProCheckResult[]> = action.userId
        ? yield service.loadUserResults(
            action.organisationId,
            isAdmin,
            action.userId,
            page,
            15,
            rootState.proCheck.searchString && rootState.proCheck.searchString?.length > 2
              ? rootState.proCheck.searchString
              : undefined
          )
        : yield service.loadOrgResults(
            action.organisationId,
            isAdmin,
            page,
            10,
            rootState.proCheck.searchString && rootState.proCheck.searchString?.length > 2
              ? rootState.proCheck.searchString
              : undefined
          );

      normalisedResult = normaliseProCheckResults(results.data);

      normalisedResult.allIds = [
        ...cachedSelectedResults.allIds.filter(
          (id) => !normalisedResult.allIds.includes(id)
        ),
        ...normalisedResult.allIds,
      ];
      normalisedResult.resultsById = {
        ...normalisedResult.resultsById,
        ...cachedSelectedResults.resultsById,
      };

      pagination = {
        currentPage: results.meta.current_page,
        itemsPerPage: results.meta.per_page,
        displayItems: normalisedResult.allIds,
        maxPages: results.meta.last_page,
      };
    }

    yield put(proCheckResultsLoaded(normalisedResult, pagination));
  } catch (e) {
    const message = getErrorMessage(e, "proCheck.loadError");
    yield put(proCheckResultsLoadError(message));
  }
}

function* loadDetailsSaga(action: LoadProCheckDetailsAction) {
  try {
    const adeyAdmin: boolean = yield isAdeyAdmin();
    const service: ProCheckService = yield getDependency("proCheckService");

    const resultResponse: ProCheckResultDetails = yield service.getResult(
      adeyAdmin,
      action.organisationId,
      action.propertyId,
      action.procheckId
    );

    yield put(
      proCheckDetailsLoaded(
        convertProCheckDetailsToState(resultResponse),
        resultResponse.property
      )
    );
  } catch (e) {
    const message = getErrorMessage(e, "proCheck.loadError");
    yield put(proCheckDetailsLoadError(message));
  }
}

function* downloadReportSaga(action: DownloadReportAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const service: ProCheckRepository = yield getDependency("proCheckRepository");

    const report: ProCheckResultFiles = yield service.sendProCheckResults(
      isAdmin,
      action.exportDetails,
      action.orgId,
      action.propertyId,
      action.languageHeader
    );

    yield put(downloadReportSuccess(report.file_location));
  } catch (e) {
    const message = getErrorMessage(e, "proCheck.downloadError");
    yield put(downloadReportError(message));
  }
}

export default proCheckSagas;
