import Organisation from "../model/Organisation";

const organisationMatchesSearchText = (
  organisation: Organisation,
  searchText: string | undefined
): boolean => {
  const search = searchText?.toLocaleLowerCase()?.trim() || "";
  if (search === "") {
    return true;
  }

  const name = organisation.name?.toLocaleLowerCase() || "";
  return name.includes(search);
};

export default organisationMatchesSearchText;
