import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LARGE_SPACING } from "../../../../../../ui/theme/dimensions";
import RAGStatus, { titleForRAGStatus } from "../../../model/RAGStatus";
import RAGSymbol from "../../RAGSymbol";

const ragSymbolDiameter = 14;

// Setting a fixed width to the text is fine here
// It'll get bigger in height.
const textWidth = 122;

interface Props extends WithTranslation {
  status: RAGStatus;
  isChecked: boolean;
  onCheckChanged: (status: RAGStatus, checked: boolean) => void;
}

const RAGStatusCheckbox = (props: Props) => {
  const onChange = (_: any, isChecked: boolean) => {
    props.onCheckChanged(props.status, isChecked);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <RAGSymbol diameter={ragSymbolDiameter} status={props.status} />
        <Box width={LARGE_SPACING} />

        <Typography style={{ width: textWidth }}>
          {titleForRAGStatus(props.status, props.t)}
        </Typography>
      </Box>
      <Checkbox checked={props.isChecked} onChange={onChange} />
    </Box>
  );
};

export default withTranslation()(RAGStatusCheckbox);
