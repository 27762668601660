import { AxiosInstance } from "axios";
import { unwrapData } from "../utils/DataWrapper";
import ProClubRepository from "./ProClubRepository";

class RestProClubRepository implements ProClubRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  checkProclubUser = (email: string, password: string): Promise<boolean> => {
    const url = `/check-user`;
    //TODO: Will have to be replaced if we are provided
    //with a correct method to check if a password is weak or not - could also check password strength on front-end
    return this.axios
      .post(url, { email: email, password: password })
      .then(unwrapData)
      .then((value) => value !== undefined);
  };
}

export default RestProClubRepository;
