import ProCheckRecType from "../../../rest/procheck/model/ProCheckRecType";

export interface ISummaryProvideOptions {
  withCopper?: boolean;
}

const ProCheckRecSummaryProvider = (
  recs: ProCheckRecType[],
  options?: ISummaryProvideOptions
): string[] => {
  let displayRecs: string[] = [];

  recs.forEach((rec) => {
    switch (rec) {
      case "molybdate_pass":
        displayRecs.push("ProCheckSummary.molyPassRec");
        break;

      case "molybdate_low":
        displayRecs.push("ProCheckSummary.molyLowRec");
        break;

      case "iron_high": {
        options?.withCopper
          ? displayRecs.push("ProCheckSummary.ironHighRecCopperPass")
          : displayRecs.push("ProCheckSummary.ironHighRec");
        break;
      }
      case "iron_moderate": {
        options?.withCopper
          ? displayRecs.push("ProCheckSummary.ironModerateCopperPresentRec")
          : displayRecs.push("ProCheckSummary.ironModerateRec");
        break;
      }
      case "iron_pass": {
        options?.withCopper
          ? displayRecs.push("ProCheckSummary.ironPassCopperPresentRec")
          : displayRecs.push("ProCheckSummary.ironPassRec");
        break;
      }
      case "ph_pass": {
        displayRecs.push("ProCheckSummary.phPassRec");
        break;
      }
      case "ph_low": {
        displayRecs.push("ProCheckSummary.phLowRec");
        break;
      }
      case "ph_low_moly_low": {
        displayRecs.push("ProCheckSummary.phLowLowMoly");
        break;
      }
      case "ph_high": {
        displayRecs.push("ProCheckSummary.phHighRec");
        break;
      }
      case "ph_high_moly_low": {
        displayRecs.push("ProCheckSummary.phHighRecLowMoly");
        break;
      }
      case "copper_pass": {
        displayRecs.push("ProCheckSummary.copper_pass");
        break;
      }
      case "copper_low": {
        displayRecs.push("ProCheckSummary.copper_low");
        break;
      }
      case "copper_high": {
        displayRecs.push("ProCheckSummary.copperHighRec");
        break;
      }
    }
  });

  return displayRecs;
};

export default ProCheckRecSummaryProvider;
