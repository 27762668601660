import { DashboardMetrics } from "./DashboardState";

// Dashboard metrics loaded
export const DASHBOARD_METRICS_LOADED_TYPE = "DASHBOARD_METRICS_LOADED_TYPE";
export interface DashboardMetricsLoadedAction {
  type: typeof DASHBOARD_METRICS_LOADED_TYPE;
  metrics: DashboardMetrics;
  organisationId: string | undefined;
}
export const dashboardMetricsLoaded = (
  metrics: DashboardMetrics,
  organisationId: string | undefined
): DashboardMetricsLoadedAction => ({
  type: DASHBOARD_METRICS_LOADED_TYPE,
  metrics: metrics,
  organisationId: organisationId,
});

// Clear dashboard metrics
export const CLEAR_DASHBOARD_METRICS_TYPE = "CLEAR_DASHBOARD_METRICS_TYPE";
export interface ClearDashboardMetricsAction {
  type: typeof CLEAR_DASHBOARD_METRICS_TYPE;
}
export const clearDashboardMetrics = (): ClearDashboardMetricsAction => ({
  type: CLEAR_DASHBOARD_METRICS_TYPE,
});

// RAG card org id changed
export const RAG_CARD_ORG_ID_CHANGED_TYPE = "RAG_CARD_ORG_ID_CHANGED_TYPE";
export interface RagCardOrgIdChangedAction {
  type: typeof RAG_CARD_ORG_ID_CHANGED_TYPE;
  orgId: string | undefined;
}
export const ragCardOrgIdChanged = (
  id: string | undefined
): RagCardOrgIdChangedAction => ({
  type: RAG_CARD_ORG_ID_CHANGED_TYPE,
  orgId: id,
});

type DashboardAction =
  | DashboardMetricsLoadedAction
  | ClearDashboardMetricsAction
  | RagCardOrgIdChangedAction;

export default DashboardAction;
