import { Button, withStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import {
  BACKGROUND_ACCENT_COLOR,
  SECONDARY_COLOR,
} from "../../../theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING } from "../../../theme/dimensions";

interface Props {
  activeKey: string;
  navKey: string;
  children: ReactNode;

  onTabClick?: (navKey: string) => void;
}

const PageHeaderItem = (props: Props) => {
  let tabStyle: any = {
    paddingBottom: "1px",
    fontSize: 14,
    borderRadius: 0,
    marginRight: EXTRA_LARGE_SPACING,
  };

  const isActive = props.activeKey === props.navKey;

  if (isActive) {
    tabStyle = {
      ...tabStyle,
      color: BACKGROUND_ACCENT_COLOR,
      borderBottom: "1px solid " + BACKGROUND_ACCENT_COLOR,
    };
  } else {
    tabStyle = {
      ...tabStyle,
      color: BACKGROUND_ACCENT_COLOR,
      opacity: 0.8,
      "&:hover": {
        borderBottom: "1px solid " + SECONDARY_COLOR,
      },
    };
  }

  const Tab = withStyles({
    root: tabStyle as any,
  })(Button);

  const onClick = () => {
    props.onTabClick?.(props.navKey);
  };

  return <Tab onClick={onClick}>{props.children}</Tab>;
};

export default PageHeaderItem;
