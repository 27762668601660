import AccountSetupAction from "./AccountSetupActions";
import AccountSetupState from "./AccountSetupState";

// Default to being set up unless the /me endpoint
// confirms they aren't
const initialState: AccountSetupState = {
  organisation: "set-up",
  personalDetails: "set-up",
  segmentation: "set-up",
  error: undefined,
};

const accountSetupReducer = (
  state = initialState,
  action: AccountSetupAction
): AccountSetupState => {
  switch (action.type) {
    case "USER_NOT_SETUP_TYPE":
      return {
        organisation: action.hasOrganisation ? "set-up" : "missing",
        personalDetails: action.hasPersonalDetails ? "set-up" : "missing",
        segmentation: action.hasFinishedSegmentation ? "set-up" : "missing",
        error: undefined,
      };

    case "PERSONAL_INFO_SUBMITTED_TYPE":
      return {
        ...state,
        error: undefined,
        personalDetails: "loading",
      };

    case "SEGMENTATION_DATA_SUBMITTED_TYPE": {
      return {
        ...state,
        error: undefined,
        segmentation: "loading",
      };
    }
    case "ACCOUNT_SETUP_ERROR_TYPE":
      return {
        personalDetails: state.personalDetails === "loading" ? "missing" : "set-up",
        organisation: state.organisation === "loading" ? "missing" : "set-up",
        segmentation: state.segmentation === "loading" ? "missing" : "set-up",
        error: action.errorMessage,
      };

    case "CREATE_ORGANISATION_SUBMITTED_TYPE":
      return {
        ...state,
        error: undefined,
        organisation: "loading",
      };

    case "USER_SETUP_TYPE":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default accountSetupReducer;
