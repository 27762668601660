import Organisation from "../../../../rest/organisation/model/Organisation";

// Edit current organisation submit
export const EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE =
  "EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE";
export interface EditCurrentOrganisationSubmitAction {
  type: typeof EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE;
  organisationDetails: Organisation;
}
export const editCurrentOrganisationSubmit = (
  organisationDetails: Organisation
): EditCurrentOrganisationSubmitAction => ({
  type: EDIT_CURRENT_ORGANISATION_SUBMIT_TYPE,
  organisationDetails: organisationDetails,
});

// Edit current organisation submit
export const EDIT_CURRENT_ORGANISATION_SUCCESS_TYPE =
  "EDIT_CURRENT_ORGANISATION_SUCCESS_TYPE";
export interface EditCurrentOrganisationSuccessAction {
  type: typeof EDIT_CURRENT_ORGANISATION_SUCCESS_TYPE;
  updatedDetails: Organisation;
}
export const editCurrentOrganisationSuccess = (
  updatedDetails: Organisation
): EditCurrentOrganisationSuccessAction => ({
  type: EDIT_CURRENT_ORGANISATION_SUCCESS_TYPE,
  updatedDetails: updatedDetails,
});

// Edit current organisation error
export const EDIT_CURRENT_ORGANISATION_ERROR_TYPE =
  "EDIT_CURRENT_ORGANISATION_ERROR_TYPE";
export interface EditCurrentOrganisationErrorAction {
  type: typeof EDIT_CURRENT_ORGANISATION_ERROR_TYPE;
  error: string;
}
export const editCurrentOrganisationError = (
  error: string
): EditCurrentOrganisationErrorAction => ({
  type: EDIT_CURRENT_ORGANISATION_ERROR_TYPE,
  error: error,
});

// Union type
type EditCurrentOrganisationAction =
  | EditCurrentOrganisationSubmitAction
  | EditCurrentOrganisationSuccessAction
  | EditCurrentOrganisationErrorAction;

export default EditCurrentOrganisationAction;
