import RAGStatus from "../model/RAGStatus";
import AddPropertyFormModel from "../../AddPropertyDialog/model/AddPropertyFormModel";
import Property, {
  PropertyPageType,
  StatusReason,
} from "../../../../rest/properties/model/Property";
import OverdueStatus from "../model/OverdueStatus";
import { SenseProductType } from "../../../../rest/sense/model/SenseDevice";
import DeviceStatus from "../model/DeviceStatus";

// MARK: Selected rag statuses changed
export const SELECTED_RAG_STATUS_CHANGED_TYPE = "SELECTED_RAG_STATUS_CHANGED";
export interface SelectedRagStatusesChangedAction {
  type: typeof SELECTED_RAG_STATUS_CHANGED_TYPE;
  selectedStatuses: Set<RAGStatus>;
}
export const selectedRagStatusesChanged = (
  selectedStatuses: Set<RAGStatus>
): SelectedRagStatusesChangedAction => ({
  type: SELECTED_RAG_STATUS_CHANGED_TYPE,
  selectedStatuses: selectedStatuses,
});

// Selected overdue statuses changed
export const SELECTED_OVERDUE_STATUS_CHANGED_TYPE = "SELECTED_OVERDUE_STATUS_CHANGED";
export interface SelectedOverdueStatusesChangedAction {
  type: typeof SELECTED_OVERDUE_STATUS_CHANGED_TYPE;
  selectedStatuses: Set<OverdueStatus>;
}
export const selectedOverdueStatusesChanged = (
  selectedStatuses: Set<OverdueStatus>
): SelectedOverdueStatusesChangedAction => ({
  type: SELECTED_OVERDUE_STATUS_CHANGED_TYPE,
  selectedStatuses: selectedStatuses,
});

// Selected device statuses changed
export const SELECTED_DEVICE_STATUS_CHANGED_TYPE = "SELECTED_DEVICE_STATUS_CHANGED";
export interface SelectedDeviceStatusesChangedAction {
  type: typeof SELECTED_DEVICE_STATUS_CHANGED_TYPE;
  deviceType: SenseProductType | undefined;
  selectedStatuses: Set<DeviceStatus> | Set<StatusReason>;
}
export const selectedDeviceStatusesChanged = (
  selectedStatuses: Set<DeviceStatus> | Set<StatusReason>,
  deviceType: SenseProductType | undefined
): SelectedDeviceStatusesChangedAction => ({
  type: SELECTED_DEVICE_STATUS_CHANGED_TYPE,
  deviceType: deviceType,
  selectedStatuses: selectedStatuses,
});

// Page changed
export const PAGE_CHANGED_TYPE = "PAGE_CHANGED";
export interface PageChangedAction {
  type: typeof PAGE_CHANGED_TYPE;
  currentPage: number;
}
export const pageChanged = (page: number): PageChangedAction => ({
  type: PAGE_CHANGED_TYPE,
  currentPage: page,
});

// Search text changed
export const SEARCH_TEXT_CHANGED_TYPE = "SEARCH_TEXT_CHANGED";
export interface SearchTextChangedAction {
  type: typeof SEARCH_TEXT_CHANGED_TYPE;
  text: string;
}
export const searchTextChanged = (text: string): SearchTextChangedAction => ({
  type: SEARCH_TEXT_CHANGED_TYPE,
  text: text,
});

// MARK: Property list loaded
export const PROPERTY_LIST_LOADED_TYPE = "PROPERTY_LIST_LOADED";
export interface PropertyListLoadedAction {
  type: typeof PROPERTY_LIST_LOADED_TYPE;
  list: Property[];
  currentPage: number;
  maxPages: number;
  organisationId: string;
}
export const propertyListLoaded = (
  list: Property[],
  currentPage: number,
  maxPages: number,
  organisationId: string
): PropertyListLoadedAction => ({
  type: PROPERTY_LIST_LOADED_TYPE,
  list: list,
  organisationId: organisationId,
  currentPage: currentPage,
  maxPages: maxPages,
});

// MARK: Property list error
export const PROPERTY_LIST_ERROR_TYPE = "PROPERTY_LIST_ERROR";
export interface PropertyListErrorAction {
  type: typeof PROPERTY_LIST_ERROR_TYPE;
  message: string;
}
export const propertyListError = (message: string): PropertyListErrorAction => ({
  type: PROPERTY_LIST_ERROR_TYPE,
  message: message,
});

// Adding a property

export const ADD_PROPERTY_TYPE = "ADD_PROPERTY";
export interface AddPropertyAction {
  type: typeof ADD_PROPERTY_TYPE;
  propertyData: AddPropertyFormModel;
}
export const addProperty = (propertyData: AddPropertyFormModel): AddPropertyAction => ({
  type: ADD_PROPERTY_TYPE,
  propertyData: propertyData,
});

// Property added

export const PROPERTY_ADDED_TYPE = "PROPERTY_ADDED";
export interface PropertyAddedAction {
  type: typeof PROPERTY_ADDED_TYPE;
  property: Property;
}
export const propertyAdded = (property: Property): PropertyAddedAction => ({
  type: PROPERTY_ADDED_TYPE,
  property: property,
});

// Add property error
export const ADD_PROPERTY_ERROR_TYPE = "ADD_PROPERTY_ERROR";
export interface AddPropertyErrorAction {
  type: typeof ADD_PROPERTY_ERROR_TYPE;
  message: string;
}
// Add property error
export const addPropertyError = (message: string): AddPropertyErrorAction => ({
  type: ADD_PROPERTY_ERROR_TYPE,
  message: message,
});

// Page type changed
export const PAGE_TYPE_CHANGED_TYPE = "PAGE_TYPE_CHANGED";
export interface PageTypeChangedAction {
  type: typeof PAGE_TYPE_CHANGED_TYPE;
  page: PropertyPageType;
}
export const changePageType = (page: PropertyPageType): PageTypeChangedAction => ({
  type: PAGE_TYPE_CHANGED_TYPE,
  page: page,
});

//List reset (Dispatch on fetching list initialisation to show spinner properly)
export const LIST_RESET_TYPE = "LIST_RESET";
export interface ListResetAction {
  type: typeof LIST_RESET_TYPE;
}
export const resetList = (): ListResetAction => ({
  type: LIST_RESET_TYPE,
});

// Union type
type PropertyListAction =
  | SelectedRagStatusesChangedAction
  | SelectedOverdueStatusesChangedAction
  | SelectedDeviceStatusesChangedAction
  | PropertyListLoadedAction
  | PageChangedAction
  | PropertyListErrorAction
  | SearchTextChangedAction
  | AddPropertyAction
  | AddPropertyErrorAction
  | PropertyAddedAction
  | PageTypeChangedAction
  | ListResetAction;

export default PropertyListAction;
