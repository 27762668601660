import { Box } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { SenseProductType } from "../../../../../rest/sense/model/SenseDevice";
import SenseDeviceStatus, {
  DeviceNotificationType,
} from "../../../../../rest/sense/model/SenseDeviceStatus";
import ColoredText from "../../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import InfoCardAlert from "./InfoCardAlert";

interface ExternalProps {
  productType?: SenseProductType;
  notifications: SenseDeviceStatus[];
  label: string;
  notificationType: DeviceNotificationType;
  date?: string;
}

type Props = ExternalProps & WithTranslation;

const LabeledNotification = ({
  notifications,
  notificationType,
  label,
  productType,
  t,
  date,
}: React.PropsWithChildren<Props>) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <ColoredText
        textColor={BACKGROUND_ACCENT_COLOR}
        variant="button"
        style={{ marginBottom: SMALL_SPACING }}
      >
        <strong>{label}</strong>
      </ColoredText>
      <InfoCardAlert
        productType={productType}
        notifications={notifications}
        notificationType={notificationType}
        date={date}
      />
      <Box height={EXTRA_LARGE_SPACING} />
    </Box>
  );
};

export default withTranslation()(LabeledNotification);
