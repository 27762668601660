import { put, takeLatest } from "@redux-saga/core/effects";
import { all } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import OrganisationUserRepository from "../../../../rest/organisation-user/OrganisationUserRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import UserDetails from "../../../../rest/user/model/UserDetails";
import UserRepository from "../../../../rest/user/UserRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import {
  LoadUserAction,
  loadUserError,
  loadUserSuccess,
  LOAD_USER_TYPE,
  RemoveUserAction,
  removeUserError,
  removeUserSuccess,
  REMOVE_USER_TYPE,
} from "./UserDetailActions";
import userDetailErrorHandler from "../../../../rest/organisation-user/error/userDetailErrorHandler";

function* userDetailSagas() {
  yield takeLatest(LOAD_USER_TYPE, loadUserSaga);
  yield takeLatest(REMOVE_USER_TYPE, removeUserSaga);
}

function* loadUserSaga(action: LoadUserAction) {
  try {
    const userRepo: UserRepository = yield getDependency("userRepository");
    const orgUserRepo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );
    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");

    const userFuture = orgUserRepo.fetchOrganisationUser(
      action.organisationId,
      action.userId
    );

    const orgFuture = orgRepo.getOrganisation(action.organisationId);

    const meFuture = userRepo.getMe();

    // This runs both requests in parallel using `all` - which returns an
    // array of responses. Tread carefully here as the type info is lost.
    const responses: any[] = yield all([userFuture, orgFuture, meFuture]);
    const userResponse = responses[0] as OrganisationUser;
    const organisationResponse = responses[1] as Organisation;
    const meResponse = responses[2] as UserDetails;

    // Allow removing a user it's not a super admin, and if it's not you
    const canRemoveUser =
      userResponse.role.name !== "owner" && userResponse.user.id !== meResponse.id;

    yield put(loadUserSuccess(userResponse, organisationResponse, canRemoveUser));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "userDetailPage.loadError",
      userDetailErrorHandler
    );
    yield put(loadUserError(message));
  }
}

function* removeUserSaga(action: RemoveUserAction) {
  try {
    const orgUserRepo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );

    yield orgUserRepo.removeUserFromOrganisation(action.user, action.organisation.id);

    // If we get here without throwing then it's a success
    yield put(removeUserSuccess(action.user));
  } catch (e) {
    const message = getErrorMessage(e, "userDetailPage.removeError");
    yield put(removeUserError(message));
  }
}

export default userDetailSagas;
