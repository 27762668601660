import { Amplify } from "aws-amplify";
import Env from "../app/environment/Env";

const initCognito = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: Env.awsRegion,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: Env.awsUserPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: Env.awsClientId,
    },
  });
};

export default initCognito;
