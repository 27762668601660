import React from "react";
import MoveProCheckTestButton from "./MoveProCheckTestButton";
import ProCheckResult from "../../../../../rest/procheck/model/ProCheckResult";
import MoveProcheckReportDialog from "./MoveProcheckReportDialog";
import useMoveReports from "../../../../reports/dashboard/ReportsDashboardPage/hooks/useMoveReports";

interface IProps {
  selectedReports: ProCheckResult[];
  orgId?: string;
}

const MoveProCheckTestHandler = (props: IProps) => {
  //Hooks
  const {
    moveDialogOpen,
    searchString,
    destinations,
    destinationId,
    activeTab,
    loading,
    moveResults,
    handleMoveDialogOpen,
    moveSelectedReports,
    handleSetSelectedDestination,
    handleSetSearchString,
    handleInitSearch,
    handleBackPressed,
    handleNextPressed,
    handleSuccessPressed,
  } = useMoveReports();

  const handleMoveReports = () => {
    const ids = props.selectedReports.map((test) => test.id);
    moveSelectedReports(
      ids,
      props.selectedReports[0].property.organisation?.id || "",
      props.selectedReports[0].property.id
    );
  };

  return (
    <>
      <MoveProCheckTestButton
        onClick={handleMoveDialogOpen}
        disabled={props.selectedReports.length === 0 ? true : false}
      />

      <MoveProcheckReportDialog
        activeTab={activeTab}
        destinations={destinations.filter(
          (dest) => props.selectedReports[0].property.id !== dest.id
        )}
        searchValue={searchString}
        open={moveDialogOpen || activeTab === "success"}
        handleSetSearchString={handleSetSearchString}
        handleSearchPressed={() => handleInitSearch(props.orgId)}
        handleOpen={handleMoveDialogOpen}
        isLoading={loading}
        moved={undefined}
        selectedDestination={destinationId}
        setSelectedDestination={handleSetSelectedDestination}
        handleBackPressed={handleBackPressed}
        handleNextPressed={handleNextPressed}
        handleMovePressed={handleMoveReports}
        handleSuccessPressed={handleSuccessPressed}
        selectedTests={props.selectedReports}
        moveResults={moveResults}
      />
    </>
  );
};

export default MoveProCheckTestHandler;
