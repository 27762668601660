import { Box, CardMedia, Grid, Paper, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import ColoredText from "../../../../../ui/components/ColoredText";
import LabelledText from "../../../../../ui/components/LabelledText";
import MapPlaceholder from "../../../../../ui/components/MapView/MapPlaceholder";
import MapView from "../../../../../ui/components/MapView/MapView";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import Linkify from "react-linkify";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../ui/theme/dimensions";
import ProCheckDetailsPageState from "../../model/ProCheckDetailsPageState";
import ProCheckResultTable from "../ProCheckResultTable";

interface Props extends WithT {
  resultDetails: ProCheckDetailsPageState;
  isAdeyAdmin: boolean;
  propertyCountry?: string;
}

const ProCheckResultsTab = (props: Props) => {
  //Labels
  const titleLabel = props.t("ProCheckResultsTab.title");
  const summaryLabel = props.t("ProCheckResultsTab.summary");
  const noteLabel = props.t("ProCheckResultsTab.note");
  const waterSampleLabel = props.t("ProCheckResultsTab.waterSample");
  const testCardLabel = props.t("ProCheckResultsTab.testCard");
  const locationLabel = props.t("ProCheckResultsTab.location");

  //Styles
  const cardStyle: React.CSSProperties = {
    display: "flex",
    height: "300px",
    //maxWidth: "220px",
    paddingRight: "5%",
    paddingLeft: "5%",
    alignItems: "center",
  };

  const mapStyle: React.CSSProperties = {
    display: "flex",
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingTop: "3%",
    paddingBottom: "3%",
  };

  const imageStyle: React.CSSProperties = {
    objectFit: "contain",
    maxHeight: "90%",
  };

  //Restricted hints display - currently aplicable to Australia & English.
  //If similar functionality is required for other countries, a helper function would be required

  const language = useTranslation().i18n.language;

  const restrictedHints = language === "en" && props.propertyCountry === "AU";

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="top"
      paddingX={SMALL_SPACING}
      justifyContent="flex-start"
    >
      <Grid container spacing={SMALL_SPACING} alignContent="center">
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row">
            {" "}
            <ColoredText
              textColor={BACKGROUND_ACCENT_COLOR}
              variant="h2"
              align="left"
              style={{ paddingRight: EXTRA_LARGE_SPACING }}
            >
              <strong>{titleLabel.toUpperCase()}</strong>
            </ColoredText>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <ProCheckResultTable
            pass={props.resultDetails.pass}
            results={props.resultDetails.results}
            waterTestStatus={props.resultDetails.waterSample.outcome}
          />

          <Box flexGrow={1} paddingY={DEFAULT_SPACING}>
            <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
              <strong>{summaryLabel}</strong>
            </ColoredText>
            {props.resultDetails.summary.map((summary) => (
              <Box
                marginBottom={DEFAULT_SPACING}
                paddingRight={DEFAULT_SPACING}
                paddingLeft={0}
                display="flex"
              >
                <Typography
                  variant="h5"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  <Linkify>
                    {props.t(summary, restrictedHints ? { lng: "en-AU" } : undefined)}
                  </Linkify>
                </Typography>
              </Box>
            ))}

            {props.resultDetails.notes ? (
              <LabelledText
                label={noteLabel}
                text={props.resultDetails.notes}
                gutterBottom
              />
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12} lg={1} />
        <Grid item xs={12} lg={5}>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: DEFAULT_SPACING,
              display: "flex",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <Grid item xs={6}>
              <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
                <strong>{waterSampleLabel}</strong>
              </ColoredText>
              <Paper style={cardStyle}>
                <CardMedia
                  component="img"
                  height="90%"
                  style={imageStyle}
                  image={props.resultDetails.waterSample.image}
                />
              </Paper>
            </Grid>
            {props.isAdeyAdmin && props.resultDetails.results ? (
              <Grid item xs={6}>
                <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
                  <strong>{testCardLabel}</strong>
                </ColoredText>

                <Paper style={cardStyle}>
                  <CardMedia
                    component="img"
                    style={imageStyle}
                    image={props.resultDetails.results.pH1.snapshot_url}
                  />
                </Paper>
              </Grid>
            ) : null}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
                <strong>{locationLabel}</strong>
              </ColoredText>
              <Paper style={mapStyle}>
                {props.resultDetails.location &&
                props.resultDetails.location.lat !== 0 &&
                props.resultDetails.location.lng !== 0 ? (
                  <MapView location={props.resultDetails.location} zoom={10} />
                ) : (
                  <MapPlaceholder />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(ProCheckResultsTab);
