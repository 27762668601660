import Address from "../../../../pages/properties/PropertyDetailPage/model/Address";
import { LoqateCountryCode } from "../../../../rest/loqate/LoqateRepository";
import { LoqateFindItem } from "../../../../rest/loqate/model/LoqateFindResponse";

// Search address
export const SEARCH_ADDRESS_TYPE = "SEARCH_ADDRESS_TYPE";
export interface SearchAddressAction {
  type: typeof SEARCH_ADDRESS_TYPE;
  searchText: string;
  country: LoqateCountryCode;
}
export const searchAddress = (
  searchText: string,
  country: LoqateCountryCode
): SearchAddressAction => ({
  type: SEARCH_ADDRESS_TYPE,
  searchText: searchText,
  country: country,
});

// Search address success
export const SEARCH_ADDRESS_SUCCESS_TYPE = "SEARCH_ADDRESS_SUCCESS_TYPE";
export interface SearchAddressSuccessAction {
  type: typeof SEARCH_ADDRESS_SUCCESS_TYPE;
  items: LoqateFindItem[];
}
export const searchAddressSuccess = (
  items: LoqateFindItem[]
): SearchAddressSuccessAction => ({
  type: SEARCH_ADDRESS_SUCCESS_TYPE,
  items: items,
});

// Search address error
export const SEARCH_ADDRESS_ERROR_TYPE = "SEARCH_ADDRESS_ERROR_TYPE";
export interface SearchAddressErrorAction {
  type: typeof SEARCH_ADDRESS_ERROR_TYPE;
  error: string;
}
export const searchAddressError = (error: string): SearchAddressErrorAction => ({
  type: SEARCH_ADDRESS_ERROR_TYPE,
  error: error,
});

// Clear search
export const CLEAR_SEARCH_TYPE = "CLEAR_SEARCH_TYPE";
export interface ClearSearchAction {
  type: typeof CLEAR_SEARCH_TYPE;
}
export const clearSearch = (): ClearSearchAction => ({
  type: CLEAR_SEARCH_TYPE,
});

// item selected
export const ITEM_SELECTED_TYPE = "ITEM_SELECTED_TYPE";
export interface ItemSelectedAction {
  type: typeof ITEM_SELECTED_TYPE;
  item: LoqateFindItem;
}
export const itemSelected = (item: LoqateFindItem) => ({
  type: ITEM_SELECTED_TYPE,
  item: item,
});

// Item expanded
export const ITEM_EXPANDED_TYPE = "ITEM_EXPANDED_TYPE";
export interface ItemExpandedAction {
  type: typeof ITEM_EXPANDED_TYPE;
  container: LoqateFindItem;
  replacement: LoqateFindItem[];
}
export const itemExpanded = (
  container: LoqateFindItem,
  replacement: LoqateFindItem[]
) => ({
  type: ITEM_EXPANDED_TYPE,
  container: container,
  replacement: replacement,
});

// Address details loaded
export const ADDRESS_DETAILS_LOADED_TYPE = "ADDRESS_DETAILS_LOADED_TYPE";
export interface AddressDetailsLoadedAction {
  type: typeof ADDRESS_DETAILS_LOADED_TYPE;
  address: Address;
}
export const addressDetailsLoaded = (address: Address): AddressDetailsLoadedAction => ({
  type: ADDRESS_DETAILS_LOADED_TYPE,
  address: address,
});

type AddressLookupAction =
  | SearchAddressAction
  | SearchAddressErrorAction
  | SearchAddressSuccessAction
  | ClearSearchAction
  | ItemSelectedAction
  | ItemExpandedAction
  | AddressDetailsLoadedAction;

export default AddressLookupAction;
