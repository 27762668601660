import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Box, Divider } from "@material-ui/core";
import { WithT } from "i18next";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ProCheckResult from "../../../../rest/procheck/model/ProCheckResult";
import Property from "../../../../rest/properties/model/Property";
import DeviceLogo from "../../../../ui/assets/DeviceImages/DeviceLogo";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail from "../../../../ui/components/NavigationRail";
import SuccessSnackbarHandler from "../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../ui/theme/dimensions";
import RAGStatus from "../../../properties/PropertyListPage/model/RAGStatus";
import ProductReportTableItem from "../model/ProductReportTableItem";
import mapProCheckReportToProductTableItem from "../../utils/mapProCheckReportToProductTableItem";
import EmptyReportTable from "./EmptyReportTable";
import ExportProcheckResultsDialog from "./ExportProcheckResultsDialog";
import ProductReportTable from "./ProductReportTable";
import ProductSummary from "./ProductSummary";
import TablePageIndicator from "../../../properties/PropertyListPage/components/TablePageIndicator";
import useReportPagination from "../../../reports/dashboard/ReportsDashboardPage/hooks/useReportsPagination";

interface Props extends WithT {
  property: Property | undefined;
  results: ProCheckResult[];
  errorMessage: string | undefined;
  isExported: boolean | undefined;
  exporting: boolean;
  onSearchBarChanged: (searchValue: string, extraIds: string[]) => void;
  onDashboardClicked: () => void;
  onPropertyClicked: () => void;
  onEmailReportClicked: (
    emails: string[],
    results: string[],
    privacy: boolean,
    notes?: string
  ) => void;
  onDownloadReportClicked: (results: string[], privacy: boolean) => void;
  onReportClicked: (report: ProductReportTableItem) => void;
  platformReports?: ProCheckResult[];
}

export interface ExportDialogForm {
  emails: string[];
  hidePersonalDetails: boolean;
  notes?: string;
}

const areResultsEqual = (prevResults: Props, nextResults: Props) => {
  return (
    prevResults.results === nextResults.results &&
    prevResults.isExported === nextResults.isExported
  );
};

const LoadedProCheckPage = (props: Props) => {
  // Using useState function
  // to hold the list of selected reports
  //TODO: Refactor using hooks

  const [selectedReports, setSelectedReports] = useState<ProCheckResult[]>([]);

  // Using useState function
  // to retain searchBar value

  const [searchBarValue, setSearchBarValue] = useState<string>("");

  //Dialog State
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  //Dialog Export Function State
  const [exportFunc, setExportFunc] = useState<(dialogForm: ExportDialogForm) => void>(
    () => (item: ProductReportTableItem) => {}
  );

  //Dialog Download Function State
  const [downloadFunc, setDownloadFunc] = useState<(privacy: boolean) => void>(
    () => (item: ProductReportTableItem) => {}
  );

  const { currentPage, maxPages, onPageNumberChanged } =
    useReportPagination("property-reports");

  //Helpers
  const getProCheckRag = (): RAGStatus => {
    if (props.platformReports?.[0] && props.platformReports[0]?.pass) {
      return "green";
    } else {
      return "red";
    }
  };

  const getProCheckStatus = (): string => {
    if (props.platformReports?.[0]) {
      if (props.platformReports?.[0].pass) {
        return props.t("proCheck.pass");
      } else {
        return props.t("proCheck.recommendation");
      }
    } else {
      return props.t("proCheck.noTestsLabel");
    }
  };

  //Will not enter infinite render loop - handled by using React.Memo with 'areResultsEqual' custom function.
  //When adding props, do not forget to expand comparator to rerender when necessary

  useEffect(() => {
    if (props.isExported) {
      setDialogOpen(false);
      setSelectedReports([]);
    }
  }, [props.isExported]);

  const title = props.t("proCheck.name");
  const productType = props.t("proCheck.productType");
  const successMessage = props.t("proCheck.exportSuccess");

  const links = [
    {
      key: "properties",
      name: props.t("propertyDetailPage.propertyLink"),
      startIcon: faArrowLeft,
      onClick: props.onDashboardClicked,
    },
    {
      key: "address",
      name: props.property?.address_line_1 || "",
      startIcon: faArrowLeft,
      onClick: props.onPropertyClicked,
    },
  ];

  const ragStatus = getProCheckRag();

  const statusMessage = getProCheckStatus();

  const tableItems = props.results.map((r) =>
    mapProCheckReportToProductTableItem(r, props.t)
  );

  //Dialog Export Functions Stubbs
  const emailSingleReport = (
    item: ProductReportTableItem,
    dialogForm: ExportDialogForm
  ) => {
    props.onEmailReportClicked(
      dialogForm.emails,
      [item.id],
      dialogForm.hidePersonalDetails,
      dialogForm.notes
    );
  };

  const downloadSingleReport = (item: ProductReportTableItem, privacy: boolean) => {
    props.onDownloadReportClicked([item.id], privacy);
  };

  const exportSelectedReports = (dialogForm: ExportDialogForm) => {
    const reportIds = selectedReports.map((report) => report.id);

    props.onEmailReportClicked(
      dialogForm.emails,
      reportIds,
      dialogForm.hidePersonalDetails,
      dialogForm.notes
    );
  };

  const downloadSelectedReports = (privacy: boolean) => {
    const reportIds = selectedReports.map((report) => report.id);

    props.onDownloadReportClicked(reportIds, privacy);
  };

  //Handlers
  //Handlers set the export function to accept dialog parameters, and,
  //in case of a single report export, pass selected item to the function directly

  const handleReportExportClicked = (item: ProductReportTableItem) => {
    const selectedItem = item;
    setExportFunc(() => (dialogForm: ExportDialogForm) => {
      emailSingleReport(selectedItem, dialogForm);
    });
    setDownloadFunc(() => (hidePersonalDetails: boolean) => {
      downloadSingleReport(selectedItem, hidePersonalDetails);
      setDialogOpen(false);
    });
    setDialogOpen(!isDialogOpen);
  };

  const handleSelectedExportClicked = () => {
    setExportFunc(() => (dialogForm: ExportDialogForm) => {
      exportSelectedReports(dialogForm);
    });
    setDownloadFunc(() => (hidePersonalDetails: boolean) => {
      downloadSelectedReports(hidePersonalDetails);
      setDialogOpen(false);
    });
    setDialogOpen(!isDialogOpen);
  };

  const handleReportCheckmarkClicked = (
    reportTableItem: ProductReportTableItem,
    selected: boolean
  ) => {
    const result = props.results.find((r) => r.id === reportTableItem.id);
    selected && result !== undefined
      ? (() => {
          setSelectedReports([...selectedReports, result]);
          props.onSearchBarChanged(
            searchBarValue,
            [...selectedReports, result].map((result) => result.id)
          );
        })()
      : (() => {
          const reportsToSet = selectedReports.filter((report) => report !== result);
          props.onSearchBarChanged(
            searchBarValue,
            reportsToSet.map((report) => report.id)
          );
          setSelectedReports(reportsToSet);
        })();
  };

  const handleSearchBarChanged = (value: string) => {
    setSearchBarValue(value);
    props.onSearchBarChanged(
      value,
      selectedReports.map((result) => result.id)
    );
  };

  const handleReportClicked = (report: ProductReportTableItem) => {
    props.onReportClicked(report);
  };

  return (
    <Box>
      <Box padding={DEFAULT_SPACING} display="flex" justifyContent="space-between">
        <NavigationRail currentPageTitle={title} links={links} />
      </Box>

      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Box display="flex" flexDirection="row">
          <Box flex={1} marginRight={LARGE_SPACING}>
            <ProductSummary
              title={title}
              productType={productType}
              status={ragStatus}
              logo={<DeviceLogo deviceType="PROCHECK" height={60} />}
              statusMessage={statusMessage}
            />
          </Box>
        </Box>

        <Box marginY={DEFAULT_SPACING}>
          <Divider />
        </Box>
        {props.platformReports?.length === 0 ? (
          <EmptyReportTable />
        ) : (
          <Box>
            <ProductReportTable
              searchBarValue={searchBarValue}
              tableItems={tableItems}
              onExportClicked={handleReportExportClicked}
              onSelectedExportClicked={handleSelectedExportClicked}
              onReportClicked={handleReportClicked}
              onReportChange={handleReportCheckmarkClicked}
              onSearchChange={handleSearchBarChanged}
              selectedReports={selectedReports}
            />
            <TablePageIndicator
              selectedPage={currentPage}
              maxPages={maxPages}
              onPageNumberChanged={(pageNo) => {
                onPageNumberChanged(
                  pageNo,
                  selectedReports.map((report) => report.id)
                );
              }}
            />
          </Box>
        )}

        <ExportProcheckResultsDialog
          exported={props.isExported}
          open={isDialogOpen}
          isLoading={props.exporting}
          onDialogClose={() => {
            setDialogOpen(!isDialogOpen);
          }}
          handleReportsDownload={(hidePersonalDetails) => {
            downloadFunc(hidePersonalDetails);
          }}
          handleReportsExport={(
            emails: string[],
            hidePersonalDetails: boolean,
            notes?: string
          ) => {
            exportFunc({
              emails: emails,
              hidePersonalDetails: hidePersonalDetails,
              notes: notes,
            });
          }}
        />
      </Box>
      <SuccessSnackbarHandler message={props.isExported ? successMessage : undefined} />
      <ErrorSnackbarHandler message={props.errorMessage} />
    </Box>
  );
};

export default withTranslation()(React.memo(LoadedProCheckPage, areResultsEqual));
