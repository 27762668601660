import { Box, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

const EmptyReportTable = (props: WithTranslation) => {
  //Translated strings
  const title = props.t("productReportTable.title");
  const noReportsTaken = props.t("productReportTable.noReportsTaken");

  return (
    <Box paddingBottom={DEFAULT_SPACING}>
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
        <strong>{title}</strong>
      </ColoredText>

      <Typography variant="h5">{noReportsTaken}</Typography>
    </Box>
  );
};

export default withTranslation()(EmptyReportTable);
