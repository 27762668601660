import { Box, Card, Typography } from "@material-ui/core";
import React from "react";
import ArrowIcon from "../../../../../../ui/assets/ArrowIcon/ArrowIcon";
import PrimaryButton from "../../../../../../ui/components/PrimaryButton";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../../ui/theme/dimensions";

interface Props {
  title: string;
  body: string;
  contactLink: string;
  actionTitle?: string | undefined;
  onActionPressed?: () => void;
}

const SignpostCard = (props: Props) => {
  const action =
    props.actionTitle || props.onActionPressed ? (
      <PrimaryButton
        label={props.actionTitle}
        onClick={props.onActionPressed}
        fullWidth
        endIcon={<ArrowIcon />}
      />
    ) : undefined;

  return (
    <Card style={{ margin: EXTRA_LARGE_SPACING, height: "100%" }}>
      <Box
        display="flex"
        flexDirection="column"
        minHeight={300}
        padding={LARGE_SPACING}
        paddingBottom={SMALL_SPACING}
      >
        <Typography variant="h2" align="center">
          <strong>{props.title?.toUpperCase()}</strong>
        </Typography>

        <Box flexGrow={1} marginTop={DEFAULT_SPACING} marginBottom={DEFAULT_SPACING}>
          <Typography align="center">
            <strong>
              {props.body}
              <a href={"mailto:" + props.contactLink}>{props.contactLink}</a>
            </strong>
          </Typography>
        </Box>

        {action}
      </Box>
    </Card>
  );
};

export default SignpostCard;
