import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import { LanguageOutlined } from "@material-ui/icons";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BACKGROUND_ACCENT_COLOR } from "../theme/createMaterialTheme";
import ColoredText from "./ColoredText";

interface ExternalProps {
  styled?: boolean;
}

type Props = WithTranslation & ExternalProps;

const languages = ["en", "de", "fr"];

const LanguageSwitcher = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //Current Language
  const currentLanguage = props.i18n.language;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClicked = (index: number) => {
    props.i18n.changeLanguage(languages[index]);
    handleMenuClose();
  };

  return (
    <Box>
      <Button
        variant={props.styled ? "contained" : undefined}
        style={{ textTransform: "initial" }}
        onClick={handleMenuOpen}
        startIcon={<LanguageOutlined style={{ color: BACKGROUND_ACCENT_COLOR }} />}
      >
        <ColoredText variant="body1" textColor={BACKGROUND_ACCENT_COLOR}>
          {props.t(`languages.${currentLanguage}`)}
        </ColoredText>
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {languages.map((lan, i) => (
          <MenuItem key={lan} onClick={() => handleItemClicked(i)}>
            {props.t(`languages.${lan}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default withTranslation()(LanguageSwitcher);
