import { Link, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import UserDetails from "../../../../rest/user/model/UserDetails";

interface Props {
  user: UserDetails;
  smallCellWidth: string;
  key: string;

  onClick?: (user: UserDetails) => void;
  removeUser?: (user: UserDetails) => void;
}

const UserTableRow = (props: Props) => {
  const user = props.user;
  const name = [user.first_name, user.last_name]
    .filter((word) => typeof word === "string")
    .map((word) => word?.trim() || "")
    .filter((line) => line.length > 0)
    .join(" ");

  const clickable = props.onClick !== undefined;
  const smallCellStyle = { width: props.smallCellWidth };
  const rowStyle = clickable ? { cursor: "pointer" } : undefined;

  const handleClick = () => {
    if (clickable) {
      props.onClick?.(props.user);
    }
  };
  return (
    <TableRow key={user.id} hover={clickable} style={rowStyle} onClick={handleClick}>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Link href={"mailto:" + user.email} color="textPrimary">
          <Typography>{user.email}</Typography>
        </Link>
      </TableCell>

      <TableCell style={smallCellStyle}>
        <Typography>{user.phone_number}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;
