import { Box, Checkbox, Typography } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { StatusReason } from "../../../../../../rest/properties/model/Property";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../../../ui/theme/dimensions";
import {
  ragForProCheckStatus,
  titleForProCheckStatus,
} from "../../../model/ProCheckStatus";
import RAGSymbol from "../../RAGSymbol";

// Setting a fixed width to the text is fine here
// It'll get bigger in height.
const textWidth = 90;
const ragSymbolDiameter = 14;

interface Props extends WithTranslation {
  status: StatusReason;
  isChecked: boolean;
  onCheckChanged: (status: StatusReason, checked: boolean) => void;
}

const ProCheckFilterCheckbox = (props: Props) => {
  const onChange = (_: any, isChecked: boolean) => {
    props.onCheckChanged(props.status, isChecked);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{ marginRight: SMALL_SPACING }}
    >
      <Box display="flex" alignItems="center">
        <RAGSymbol
          diameter={ragSymbolDiameter}
          status={ragForProCheckStatus(props.status)}
        />

        <Box width={LARGE_SPACING} />

        <Typography style={{ width: textWidth }}>
          {titleForProCheckStatus(props.status, props.t)}
        </Typography>
      </Box>
      <Checkbox checked={props.isChecked} onChange={onChange} />
    </Box>
  );
};

export default withTranslation()(ProCheckFilterCheckbox);
