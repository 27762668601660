import AdminRepository from "../../../../rest/admin/AdminRepository";
import PropertiesRepository from "../../../../rest/properties/PropertiesRepository";
import { formatPropertyAddress } from "../../../../utils/formatAddress";
import TEntityToMove from "../types/TEntityToMove";
import TMoveDestination from "../types/TMoveDestination";

export interface IMoveActionResult {
  movedEntities: string[];
  rejectedEntities: string[];
}

interface IMoveCallStatus {
  id: string;
  resolved: boolean;
  error?: string;
}

class AdminMoveEntityService {
  constructor(
    private adminRepository: AdminRepository,
    private propertyRepository: PropertiesRepository
  ) {}

  /**
   * Gets the list of viable destinations
   * @param entityToMove - Type of entity to move. Device/Test/Property
   * @param queryString - Search string. Address/Name/Etc
   * @param entityScopeId - Optional parameter. If set, will search for destinations scoped to group (organisation), if applicable
   * @returns
   */
  fetchDestinationList = async (
    entityToMove: TEntityToMove,
    queryString: string,
    entityScopeId?: string
  ): Promise<TMoveDestination[]> => {
    let destinations: TMoveDestination[] = [];

    switch (entityToMove) {
      case "device":
        destinations = (
          entityScopeId
            ? await this.propertyRepository.getProperties(
                true,
                entityScopeId,
                queryString,
                1,
                undefined,
                false,
                new Set(),
                new Set(),
                undefined
              )
            : await this.adminRepository.getAllProperties(
                queryString,
                1,
                false,
                new Set(),
                new Set(),
                undefined
              )
        ).data.map((property) => {
          return {
            id: property.id,
            description: formatPropertyAddress(property),
          };
        });
        break;
      case "property":
        destinations = (
          await this.adminRepository.fetchAllOrganisations(queryString, 1)
        ).data.map((org) => {
          return {
            id: org.id,
            description: `${org.name}, ${formatPropertyAddress(org)}`,
          };
        });
    }
    return destinations;
  };

  moveEntity = async (
    entityType: TEntityToMove,
    originOrganisationId: string,
    entityIds: string[],
    destinationId: string,
    originId?: string
  ): Promise<IMoveActionResult> => {
    let promises: (() => Promise<IMoveCallStatus>)[];
    const moveResults: IMoveActionResult = {
      movedEntities: [],
      rejectedEntities: [],
    };
    switch (entityType) {
      case "device": {
        promises = entityIds.map((id) => {
          return async () => {
            try {
              await this.adminRepository.moveSenseDevice(
                originId || "",
                originOrganisationId,
                id,
                destinationId
              );
              return { id, resolved: true };
            } catch (e) {
              return { id, resolved: false };
            }
          };
        });
        break;
      }
      case "property":
        promises = entityIds.map((id) => {
          return async () => {
            try {
              await this.adminRepository.moveProperty(
                originOrganisationId,
                id,
                destinationId
              );
              return { id, resolved: true };
            } catch (e) {
              return { id, resolved: false };
            }
          };
        });
        break;
      case "procheck":
        promises = entityIds.map((id) => {
          return async () => {
            try {
              await this.adminRepository.moveProCheckTest(
                originId || "",
                originOrganisationId,
                id,
                destinationId
              );
              return { id, resolved: true };
            } catch (e) {
              return { id, resolved: false };
            }
          };
        });
        break;
    }

    const results = await Promise.all(promises.map((func) => func()));

    results.forEach((result) => {
      if (result.resolved) {
        moveResults.movedEntities.push(result.id);
      } else {
        moveResults.rejectedEntities.push(result.id);
      }
    });

    return moveResults;
  };
}

export default AdminMoveEntityService;
