import { TFunction } from "react-i18next";
import displayNameForRole from "../../../../pages/users/detail/utils/displayNameForRole";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";

const createSwitcherSubtitle = (
  org: MemberOrganisation,
  allOrgs: MemberOrganisation[],
  t: TFunction
): string | undefined => {
  const role = org.role;
  const roleName = role?.name;
  if (!role || !roleName) {
    return undefined;
  }

  const roleDisplayName = displayNameForRole(roleName, t);
  const viaOrg = allOrgs.find((o) => o.id === role.via);
  if (role.via && viaOrg) {
    const placeholder = t("organisationSwitcher.subtitleViaFormat");
    return placeholder.replace("<ROLE>", roleDisplayName).replace("<ORG>", viaOrg.name);
  }

  return roleDisplayName;
};

export default createSwitcherSubtitle;
