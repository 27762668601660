import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { LOGIN_ROUTE } from "../../../routes/routes";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import ResendActivationEmailRequestPanel from "./components/ResendActivationEmailRequestPanel";
import ResendActivationEmailSuccessPanel from "./components/ResendActivationEmailSuccessPanel";
import {
  activationEmailRequested,
  activationEmailResendComplete,
} from "./redux/ResendActivationEmailAction";

interface ReduxStateProps {
  emailAddress: string | undefined;
  isLoading: boolean;
  success: boolean;
  errorMessage: string | undefined;
}

interface ReduxDispatchProps {
  activationEmailRequested: () => void;
  resetActivationEmailState: () => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & RouteComponentProps & WithT;

class ResendActivationEmailPage extends React.Component<Props> {
  componentDidMount() {
    this.ensureReduxState();
  }

  componentDidUpdate() {
    this.ensureReduxState();
  }

  render() {
    const body = this.props.success ? (
      <ResendActivationEmailSuccessPanel onSignInClicked={this.onSignInClicked} />
    ) : (
      <ResendActivationEmailRequestPanel
        loading={this.props.isLoading}
        onRequestEmailClicked={this.onRequestEmailClicked}
        onSignInClicked={this.onSignInClicked}
      />
    );

    const title = this.props.success
      ? this.props.t("resendActivationEmailSuccessPage.title")
      : this.props.t("resendActivationEmailRequestPage.title");

    return (
      <CenteredAuthCard title={title}>
        {body}

        <ErrorSnackbarHandler message={this.props.errorMessage} />
      </CenteredAuthCard>
    );
  }

  // Navigation

  ensureReduxState = () => {
    // If email address isn't set, just go back to the sign in page
    if (this.props.emailAddress === undefined) {
      this.props.history.replace(LOGIN_ROUTE);
    }
  };

  // MARK: Handlers

  onRequestEmailClicked = () => {
    this.props.activationEmailRequested();
  };

  onSignInClicked = () => {
    this.props.resetActivationEmailState();
  };
}

const mapStateToProps = (state: RootState) => {
  return {
    emailAddress: state.resendActivationEmail.emailAddress,
    isLoading: state.resendActivationEmail.isLoading,
    success: state.resendActivationEmail.resendSuccessful,
    errorMessage: state.resendActivationEmail.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    activationEmailRequested: () => dispatch(activationEmailRequested()),
    resetActivationEmailState: () => dispatch(activationEmailResendComplete()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ResendActivationEmailPage)));
