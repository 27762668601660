import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import RAGSymbol from "../../../pages/properties/PropertyListPage/components/RAGSymbol";
import RAGStatus from "../../../pages/properties/PropertyListPage/model/RAGStatus";
import { BACKGROUND_ACCENT_COLOR } from "../../theme/createMaterialTheme";
import ColoredText from "../ColoredText";

interface IProps {
  productName: string;
  productType: string;
  statusMessage: string;
  status?: RAGStatus;
  logo?: React.ReactNode;
}

const ProductVerticalCard = ({
  productName,
  productType,
  statusMessage,
  status,
  logo,
}: IProps) => {
  //Translation Hook
  const { t } = useTranslation();
  //Strings
  const productNameLabel = t("productSummary.productTitle");
  const productTypeLabel = t("productSummary.productTypeTitle");
  const systemMessageLabel = t("productSummary.statusMessageTitle");

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
          <strong>{productNameLabel}</strong>
        </ColoredText>

        {status ? (
          <Box paddingX={1}>
            <RAGSymbol status={status} />
          </Box>
        ) : undefined}
      </Box>
      <Box>{logo}</Box>
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant={"h2"}>
        <strong>{productName}</strong>
      </ColoredText>
      <ColoredText variant="button" textColor={BACKGROUND_ACCENT_COLOR}>
        <strong>{productTypeLabel}</strong>
      </ColoredText>
      <Typography variant={"h6"}>
        <strong>{productType}</strong>
      </Typography>
      <ColoredText variant="button" textColor={BACKGROUND_ACCENT_COLOR}>
        <strong>{systemMessageLabel}</strong>
      </ColoredText>
      <Typography variant={"h6"}>
        <strong>{statusMessage}</strong>
      </Typography>
    </Box>
  );
};

export default ProductVerticalCard;
