const translations = {
  translations: {
    language: {
      code: "de",
    },

    languages: {
      en: "English",
      fr: "Français",
      de: "Deutsche",
    },

    buttons: {
      download: "Download ProCheck PDF",
      export: "Export",
      save: "Speichern",
      cancel: "Löschen",
      close: "OK",
      back: "Zurück",
      confirm: "Bestätigen",
      retry: "Wiederholen",
      signOut: "Abmelden",
      yes: "Ja",
      remove: "Entfernen",
      no: "Nein",
      next: "Weiter",
      move: "Move",
      clear: "Clear",
      search: "Search",
    },

    divider: {
      option: "or",
    },

    countryCode: {
      AU: "Australien",
      GB: "Großbritannien",
      IE: "Irland",
      AT: "Österreich",
      FR: "Frankreich",
      MISSING: "Land unbekannt",
    },
    error: {
      requiredPlaceholder: "%s wird benötigt",
      unauthorizedPage: "Sie haben keine Berechtigung für diese Seite",
      noActiveOrganisation: "Bitte wählen Sie ein Unternehmen",
      noCountryError: "Land konnte nicht gefunden werden",
      fallback: "Etwas lief falsch. Versuchen Sie es später noch einmal",
      status401: "Ihre Sitzung wurde beendet, bitte loggen Sie sich erneut ein",
      status403: "Sie haben keine Zugangsberechtigung",
      status404: "Nichts gefunden",
      status429:
        "Wir haben zu viele Anfragen von Ihrem Gerät erhalten. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
      status422Terms: "Bitte stimmen Sie den Geschäftsbedingungen zu",
      status422Profile: "Bitte vervollständigen Sie Ihr Nutzerprofil",
      status422Organisation: "Sie müssen mindestens zu einem Unternehmen zugeordnet sein",
      status422RequiredField: "Bitte geben Sie einen gültigen %s ein",
      inviteStatus404: "Diese Einladung existiert nicht",
      inviteStatus409: "Dieser Benutzer wurde bereits eingeladen",
      propertyStatus404: "Anlage wurde nicht gefunden",
      userDetail404: "Benutzer wurde nicht gefunden",
      organisationDetail404: "Unternehmen wurde nicht gefunden",
      countryList404: "Keine Länder gefunden",
      updateCountry404: "Kein Land gefunden",
      updateCountry422: "Fehler beim beim Aktualisieren des Landes",
      createCountry422: "Fehler beim Erstellen des Landes",
      adminCreateOrg422: "Für dieses Land wurde bereits ein Partner registriert",
      inviteStatus400: "Sie können sich nicht selbst einladen",
    },
    loginPage: {
      title: "Anmelden",
      emailFieldLabel: "E-Mail-Adresse",
      passwordFieldLabel: "Passwort",
      forgotPasswordButton: "Passwort vergessen ?",

      continueButton: "Weiter",
      registerPrompt: "Sie haben kein Konto?",
      registerButton: "Hier registrieren",
      emailRequired: "E-Mail-Adresse erforderlich",
      passwordRequired: "Passwort erforderlich",
      loginError:
        "Anmeldung nicht möglich. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es noch einmal",
    },

    QuestionPageOne: {
      title: "Which of the following best describes you?",
      choiceOne: "Heating Engineer (Independent)",
      choiceTwo: "Plumber",
      choiceThree: "Residential Contractor",
      choiceFour: "Residential Contractor (50+ contractors)",
      choiceFive: "Other",
      questionOneButton: "continue",
    },
    QuestionPageTwo: {
      title: "Where do you do most of your work?",
      choiceOne: "Residential (private Residence)",
      choiceTwo: "New Build",
      choiceThree: "Social Housing/HA/LA",
      choiceFour: "Utility",
      choiceFive: "Boiler Manufacturer/OEM",
      choiceSix: "Other",
      questionTwoButton: "Proceed To Dashboard",
    },

    homezoneUnsubscribePage: {
      title: "HomeZone Subscription",
      description: "Are you sure you want to unsubscribe from HomeZone?",
      unsubscribeButton: "Unsubscribe",
      backButton: "To Homepage",
      success: "You have successfully unsubscribed from the HomeZone",
      unsubscribeError:
        "You have already unsubscribed from the HomeZone, or the token is invalid",
    },

    addPropertyButton: {
      label: "Gebäude hinzufügen",
    },
    addPropertyDialog: {
      title: "Ein Gebäude hinzufügen",
      addressSectionTitle: "Adresse",
      nameFieldHint: "Name des Unternehmens",
      numberFieldHint: "Telefonnummer des Unternehmens",
      addressLine1Hint: "Adresszeile 1",
      notesHint: "Anmerkungen (optional)",
      addressLine2Hint: "Adresszeile 2",
      addressLookupButton: "Adresse suchen",
      addressCityHint: "Stadt",
      countyHint: "Land",
      addressPostcodeHint: "Postleitzahl",
      addressCountryHint: "Land",
      contactDetailsTitle: "Kontaktdaten Gebäudeinhaber*in",
      firstNameHint: "Vorname",
      lastNameHint: "Nachname",
      contactNumberHint: "Telefonnummer",
      emailHint: "E-mail",
      saveButton: "Speichern",
      skipButton: "Überspringen",
      backButton: "Zurück",
      nextButton: "Weiter",

      addError: "Gebäude kann nicht zugefügt werden",
    },
    addPropertyAddressForm: {
      line1Error: "Zeile 1 erforderlich",
      cityError: "Stadt erforderlich",
      postcodeError: "Postleitzahl wird benötigt",
      countryError: "Wählen Sie ein Land",
    },
    addPropertyContactForm: {
      contactFirstNameError: "Vorname erforderlich",
      contactLastNameError: "Nachname erforderlich",
      contactNumberError: "Telefonnummer erforderlich",
      emailError: "E-Mail erforderlich",
    },
    propertySearchBar: {
      placeholder: "Suche nach Gebäude",
    },
    registrationPage: {
      title: "Registrierung",
      emailFieldHint: "E-Mail",
      passwordFieldHint: "Passwort",
      smartphoneHint:
        "Bitte beachten Sie: Wenn Sie dies auf Ihrem Smartphone sehen, schließen Sie bitte den Bildschirm nach dem Akzeptieren des Verifizierungslinks und setzen Sie die Registrierung über die App fort",
      registerButton: "Registrieren",
      emailRequired: "E-Mail erforderlich",
      passwordRequired: "Passwort erforderlich",
      emailInvalidError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      passwordValidationError: "Ungültiges Passwort",
      returnSignInButton: "Zurück zur Anmeldung",
    },
    validationHelper: {
      password:
        "Ihr Passwort muss mindestens 8 Zeichen haben, sowie mindestens einen Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten.",
      passwordChange:
        "Ihr Passwort muss mindestens 8 Zeichen haben, sowie mindestens einen Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten.",
      passwordReset:
        "Ihr Passwort muss mindestens 8 Zeichen haben, sowie mindestens einen Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten.",
    },
    termsAndConditionsPage: {
      title: "Geschäftsbedingungen",
      acceptButton: "Annehmen",
      termsAndConditions: "Geschäftsbedingungen",
    },
    accountCreatedPage: {
      title: "Konto erstellt",
      message:
        "Ihr Konto wurde erfolgreich erstellt und wir haben Ihnen eine Bestätigungs-E-Mail geschickt. Bitte öffnen Sie diese E-Mail und folgen Sie dem dort bereitgestellten Link, um Ihr Konto zu aktivieren.",
      signInButton: "Gehen Sie zur Anmeldung",
    },
    accountActivationPage: {
      title: "Kontoaktivierung",
      successPageTitle: "Kontoaktivierung erfolgreich",
      successMessage:
        "Ihr Konto wurde erfolgreich aktiviert. Bitte melden Sie sich an um Zugang zu bekommen",
      signInButton: "Gehen Sie zur Anmeldung",
      errorMessage:
        "Konto kann nicht aktiviert werden. Versichern Sie sich, dass der Link korrekt genutzt wurde.",
    },
    forgotPassword: {
      requestPageTitle: "Passwort vergessen",
      emailHint: "E-Mail-Adresse",
      requestButton: "Passwort zurücksetzen",
      requestPageSubtitle: "Sie haben Ihr ADEY Sense®-Passwort vergessen?",
      content:
        "Wussten Sie schon? Sie haben jetzt ein einziges Login für alle ADEY-Dienste, sodass alle Änderungen, die Sie hier vornehmen, für Ihre ADEY ProCheck™- und ADEY Sense™-Konten gelten. \n\nGeben Sie unten Ihre E-Mail-Adresse ein, um eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts zu erhalten.",
      signInPage: "Zurück zur Anmeldung",
      emailRequiredError: "E-Mail-Adresse wird benötigt",
      emailInvalidError: "Ungültige E-Mail Adresse",
    },
    forgotPasswordSent: {
      pageTitle: "E-Mail zum Zurücksetzen des Passwortes gesendet",
      message: "Wir haben Ihnen eine E-Mail zum Zurücksetzen des Passwortes gesendet",
      signInButton: "Gehen Sie zur Anmeldung",
    },

    passwordResetRequired: {
      resetRequiredTitel: "Passwort zurücksetzen",
      resetRequiredMessage:
        "Das Zurücksetzen des Passworts für diesen Benutzer ist erforderlich, um das Portal weiterhin nutzen zu können. Bitte setzen Sie Ihr Passwort zurück, indem Sie auf die Schaltfläche unten klicken",
      resetRequiredProClubMessage:
        "Wir haben unsere Sicherheit verbessert, um Ihre Informationen zu schützen. Um die ADEY-Dienste weiterhin nutzen zu können, setzen Sie bitte jetzt Ihr Passwort zurück. Ihr Passwort muss mindestens 8 Zeichen lang sein und mindestens 1 Kleinbuchstaben, Großbuchstaben, Zahlen und Sonderzeichen enthalten.",
    },

    proClub: {
      status412:
        "As you are already an existing ADEY ProClub user, you do not need to register again. Please login using your existing ProClub email and password and follow instruction on screen. \n \n Forgotten your password? /forgot-password to reset",
      status412Link: "Cliquez ici",
    },

    registrationErrors: {
      exists:
        "Da Sie bereits ADEY Sense-Benutzer sind, müssen Sie sich nicht erneut registrieren. Bitte melden Sie sich mit Ihrer bestehenden Sense-E-Mail-Adresse und Ihrem Passwort an und folgen Sie den Anweisungen auf dem Bildschirm. \n \n Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen",
    },

    forgotPasswordSubmit: {
      pageTitle: "Passwort zurücksetzen",
      passwordHint: "Neues Passwort",
      resetButton: "Passwort zurücksetzen",
      smartphoneHint:
        "Bitte beachten Sie: Wenn Sie dies auf Ihrem Smartphone sehen, schließen Sie bitte den Bildschirm nach dem Zurücksetzen Ihres Passworts. Danach können Sie mit der Anmeldung über die App fortfahren.",
      successTitle: "Passwort erfolgreich geändert",
      successMessage: "Bitte melden Sie sich in Ihrem Konto an",
      successSignInButton: "Gehen Sie zur Anmeldung",
      requiredError: "Neues Passwort benötigt",
      invalidError: "Ungültiges Passwort",
    },
    forgotPasswordTokenExpired: {
      title: "Forgot Password",
      message:
        "Your reset password link has expired, please go to the reset password page and try again.",
      homepageButton: "Return to sign in",
      passwordResetButton: "To password reset",
    },
    personalInfoPage: {
      title: "Persönliche Daten",
      firstNameHint: "Vorname",
      surnameHint: "Nachname",
      contactNumberHint: "Telefonnummer",
      postcodeHint: "Postleitzahl",
      continueButton: "Weiter",
      confirmNumberHint: "Telefonnummer bestätigen",
      firstNameRequiredError: "Vorname erforderlich",
      surnameRequiredError: "Nachname erforderlich",
      contactNumberRequiredError: "Telefonnummer erforderlich",
      postcodeRequiredError: "Postleitzahl erforderlich",
      confirmNumberRequiredError: "Bestätigung Telefonnummer erforderlich",
      confirmNumberIncorrectError:
        "Bestätigung Telefonnummer stimmt nicht mit Telefonnummer überein",
    },
    createOrganisationPage: {
      title: "Erstellen Sie ein Unternehmen",
      fallbackError: "Unternehmen kann nicht erstellt werden",
    },
    organisationEditor: {
      agentLabel: "Adey Partner",
      addressLabel: "Adresse",
      agentHint: "Als Partnerunternehmen erstellen",
      nameFieldHint: "Name des Unternehmens",
      numberFieldHint: "Telefonnummer des Unternehmens",
      vatNumberHint: "Umsatzsteueridentifikationsnummer (VAT)",
      addressLine1Hint: "Adresszeile 1",
      addressLine2Hint: "Adresszeile 2",
      addressLine3Hint: "Adresszeile 3",
      addressCityHint: "Stadt",
      addressPostcodeHint: "Postleitzahl",
      addressCountryHint: "Land",
      continueButton: "Weiter",
    },
    organisationLogoUploader: {
      addButton: "Hinzufügen",
      subtitle: "Unternehmens-Logo",
    },
    createOrganisationAdminForm: {
      title: "Erstelle ein Unternehmen",
      nameFieldHint: "Name Unternehmen",
      nameRequiredError: "Name des Unternehmens wird benötigt",
      numberFieldHint: "Telefonnummer des Unternehmens",
      numberRequiredError: "Telefonnummer des Unternehmens wird benötigt",
      invitePrompt: "Fehlen Ihnen Details?",
      inviteButton: "Einladung senden, um ein Unternehmen zu erstellen",
      continueButton: "Weiter",
    },
    organisationOwnerAdminForm: {
      title: "Unternehmen Inhaber Info",
      emailHint: "E-Mail-Adresse",
      emailRequiredError: "E-Mail-Adresse erforderlich",
      emailInvalidError: "Ungültige E-Mail-Adresse",
      confirmEmailHint: "Bestätigen Sie die E-Mail-Adresse",
      confirmEmailRequiredError: "Bestätigung der E-Mail-Adresse erforderlich",
      confirmEmailError:
        "Bestätigung der E-Mail-Adresse stimmt nicht mit der E-Mail-Adresse überein",
      finishButton: "Fertig",
      backButton: "Zurück",
      error: "Benutzer kann nicht eingeladen werden",
    },
    createOrganisationAdminEmailSent: {
      title: "Einladungs-E-Mail gesendet",
      body: "Wir haben dem Unternehmen eine Einladung -inklusive Anleitung- zur Erstellung eines Kontos per E-mail gesendet",
      returnButton: "Zurück",
    },
    inviteOrganisationAdminForm: {
      title: "Unternehmen einladen",
      emailHint: "E-Mail-Adresse",
      emailRequiredError: "E-Mail-Adresse erforderlich",
      confirmEmailHint: "Bitte bestätigen Sie die E-Mail-Adresse",
      confirmEmailRequiredError: "Bestätigung der E-Mail-Adresse erforderlich",
      emailMatchError:
        "Die bestätigte E-Mail-Adresse stimmt nicht mit der E-Mail-Adresse überein",
      sendInviteButton: "Einladung senden",
      backButton: "Zurück",
      error: "Einladung kann nicht zugestellt werden",
    },
    inviteOrgDialog: {
      errorTitle: "Fehler Einladung",
    },
    inviteEntityPanel: {
      inviteContractorTitle: "Auftragnehmer einladen",
      inviteUserTitle: "Benutzer einladen",
      emailHint: "E-Mail-Adresse",
      emailError: "E-Mail-Adresse wird benötigt",
      confirmEmailHint: "Bestätigen Sie die E-Mail-Adresse",
      confirmEmailError: "Bestätigung der E-Mail-Adresse erforderlich",
      emailMatchError: "E-Mails stimmen nicht überein",
      submitButton: "Einladung senden",
      error: "Fehler bei Einladung senden",
      userTypeHint: "Wählen Sie den Benutzertyp",
      userTypeRequiredError: "Benutzertyp erforderlich",
      descriptionText:
        'Stellen Sie sicher, dass Sie den "Inhaber/SuperAdmin" einladen, dessen E-Mail mit dem benannten Auftragsnehmer Konto verknüpft ist',
      contactHint: "Für mehr Info, kontaktieren Sie bitte",
      contactEmail: "info.de@adey.com",
      inviteAgentTitle: "Partner einladen",
      premiumInviteSummary:
        "Haben Sie jemanden, für den Sie Sense-kompatible Produkte verwenden möchten? Kein Problem! Sie können ihr Konto verknüpfen, indem Sie ihre E-Mail-Adresse auf dem nächsten Bildschirm hinzufügen.",
      premiumAccessSubtitle: "Dies ist eine Premium-Funktion",
      infoSummary: "Bitte kontaktieren Sie info.de@adey.com für weitere Informationen",
      premiumAccessSummary:
        "Durch das Einladen zusätzlicher Benutzer, Ihrem Konto beizutreten, ändert sich Ihr Kontostatus in ein Konto auf Unternehmensebene. Dadurch erhöht sich Ihre monatliche Abrechnung nach Ablauf der vorab vereinbarten Probezeiträume. Bitte kontaktieren Sie info.de@adey.comfür weitere Informationen",
      premiumAccessContractorSummary:
        "Haben Sie jemanden, für den Sie Sense-kompatible Produkte verwenden möchten? Kein Problem! Sie können ihr Konto verknüpfen, indem Sie E-Mail-Adresse auf dem nächsten Bildschirm hinzufügen.",
    },
    organisationSwitcher: {
      createOrganisationButton: "Erstelle Unternehmen",
      organisationTitle: "Unternehmen",
      organisationDetails: "Angaben zum Unternehmen",
      accountTitle: "Konto",
      signOut: "Abmelden",
      accountButton: "Mein Konto",
      accountSettings: "Konto-Einstellungen",
      fetchError: "Angaben zum Unternehmen können nicht abgerufen werden",
      subtitleViaFormat: "\n<ROLE> via <ORG>:",
      agentButton: "Zurück zum <ORG_NAME> Partner dashboard",
    },
    userList: {
      ownerTab: "Inhaber/Super Admin",
      adminTab: "Administratoren",
      engineersTab: "Servicetechniker",
      developersTab: "Developers",
      invitesTab: "Ausstehende Einladungen",
      searchPlaceholder: "Benutzer suchen",
      inviteButton: "Benutzer einladen",
      emptyText: "Kein Benutzer gefunden",
    },
    copyrightFooter: {
      text: "\nCopyright ADEY Innovation Ltd © {{date}}. All rights reserved",
      termsLink: "Geschäftsbedingungen",
      privacyLink: "Datenschutz Bestimmungen",
      cookiesLink: "Cookie Richtlinie",
      developer: "Developer",
    },
    propertyTable: {
      headerStatus: "Status",
      headerStatusMessage: "Statusmeldung",
      headerProperty: "Adresse Gebäude",
      headerProducts: "Produkte",
      headerMove: "Select",
      headerEmail: "Email",
      headerOwner: "Inhaber Unternehmen",
      headerContractorName: "Auftragsnehmer",
      rowContractorNamePlaceholder: "Kein Auftragsnehmer zugewiesen",
      rowOwnerNamePlaceholder: "Name Inhaber fehlt",
    },
    statusMessage: {
      testDuePlaceholder: "Test am %s",
      testFailed: "Empfehlungen",
      testMissing: "Keine Tests",
      testOverdue: "Test überfällig",
      testPassed: "Bestanden",
    },
    notificationMessageCode: {
      3000: "The batteries are in good condition.",
      3001: "The battery levels are low, replacements required in next 2 months. ",
      3002: "The battery levels are very low, replacements are required immediately.",
      4000: "Filter is currently connected and reporting",
      4001: "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5000: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      5001: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5002: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
      6000: "Service next due: {{date}}",
      6001: "Service overdue: {{date}}",
      6002: "Service due: {{date}}",
    },
    connectionMessage: {
      GSM: "Is there a good signal present?",
      WIFI: "Has Wi-Fi router or password changed?",
    },
    propertyListPage: {
      errorTitle: "Gebäude können nicht geladen werden",
      errorRetryButton: "Versuchen Sie es noch einmal",
      emptyMessage: "Keine Gebäude gefunden",
      searchBarPlaceholder: "Gebäude suchen",
    },
    selectedPropertiesPanel: {
      switchLabel: "Show only selected",
      countDescription: "{{count}} property selected",
      countDescription_plural: "{{count}} properties selected",
    },
    moveDeviceDialog: {
      searchPlaceholder: "Search properties",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your device has been moved successfully",
      successTitle_plural: "Your devices have been moved successfully",
      failureTitle: "Failed to move device",
      failureTitle_plural: "Failed to move your devices",
      title: "Confirm Move Device",
      title_plural: "Confirm Move Devices",
    },

    movePropertyDialog: {
      destinationTitle: "Find destination organisation",
      confirmationTitle: "Confirmation",
      successTitle: "Your property has been moved successfully",
      successTitle_plural: "Your properties have been moved successfully",
      failureTitle: "Failed to move property",
      failureTitle_plural: "Failed to move your properties",
      title: "Confirm Move Property",
      title_plural: "Confirm Move Properties",
      searchPlaceholder: "Search organisations",
    },
    moveProCheckDialog: {
      title: "Confirm Move Test",
      title_plural: "Confirm Move Tests",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your test has been moved successfully",
      successTitle_plural: "Your tests have been moved successfully",
      failureTitle: "Failed to move test",
      failureTitle_plural: "Failed to move your tests",
      searchPlaceholder: "Search properties",
    },
    moveEntitySuccessPanel: {
      description:
        "Successfully moved {{items, emphasizeList}} to <strong>{{destination}}</strong>. Closing this window will redirect you to the destination page.",
      descriptionPartial: "Following item has not been moved: {{items, emphasizeList}}",
      descriptionPartial_plural:
        "Following items have not been moved: {{items, emphasizeList}}",
      closeButton: "Close this dialog",
    },

    moveEntityErrorPanel: {
      description:
        "Failed to move {{items, emphasizeList}} to <strong>{{destination}}</strong>. Please try again later, or, if the error persists, contact administration.",
      closeButton: "Close this dialog",
    },

    moveEntityReviewPanel: {
      title: "Review selected items",
      entityTitle: {
        proCheck: "Move test",
        proCheck_plural: "Move tests",
        device: "Move device",
        device_plural: "Move devices",
        property: "Move property",
        property_plural: "Move properties",
      },

      moveEntityWarningPanel: {
        title: "Confirmation",
        descriptionProperty:
          "Are you sure you want to move {{items, emphasizeList}} to <strong>{{destionation}}</strong>?",
        descriptionOther:
          "Are you sure you want to move {{deviceType, emphasizeList}} from <strong>{{origin}}</strong> to <strong>{{destination}}<strong/>?",
      },
      destinationTitle: {
        property: "To property",
        organisation: "To organisation",
      },
    },

    filterMenu: {
      title: "Filteransicht",
      propertyStatusTitle: "Status Gebäude",
      overdueStatusTitle: "Service Status",
      deviceStatusTitle: "Produkte",
    },
    adminOrgDetailTabs: {
      orgOwnerTab: "Inhaber/Super Admin",
      orgDetailTab: "Details",
      orgUserTab: "Benutzer",
      propertiesTab: "Gebäude",
      relatedOrgsTab: "Auftragnehmer und damit verbundene Unternehmen",
      proCheckReports: "ProCheck Reports",
    },
    ragStatus: {
      green: "Gut",
      amber: "Erfordert Aufmerksamkeit",
      red: "Erfordert sofortiges Eingreifen",
    },
    overdueStatus: {
      overdue: "Properties with service overdue",
      upcoming: "Properties due service in the next 30 days",
    },

    deviceStatus: {
      batteryLow: "Battery Low",
      batteryCritical: "Battery Critical",
      magnetiteLevelMedium: "Magnetite Level Medium",
      magnetiteLevelHigh: "Magnetite Level High",
      noContactWithDevice: "No Contact With Device",
    },

    ragStatusCard: {
      totalTitle: "Gesamt",
      propertiesSubtitle: "Gebäude",
      greenInfo:
        "Die Heizungsanlagen in diesen Gebäuden sind in Ordnung - Keine Maßnahmen erforderlich",
      amberInfo:
        "Die Heizungsanlagen in diesen Gebäuden müssen überwacht werden - Maßnahmen werden bald fällig",
      redInfo:
        "Die Heizungsanlagen in diesen Gebäuden benötigen eine sofortige Überprüfung - Serviceeinsatz nötig",
    },
    accountSettingsPage: {
      userSettingsLink: "Dashboard",
      userSettingsTitle: "Kontoeinstellungen",
      errorTitle: "Benutzerdaten können nicht geladen werden",
      updateError: "Benutzerdaten können nicht aktualisiert werden",
      changePasswordSuccess: "Passwort erfolgreich geändert",
    },
    userDetailsPanel: {
      postcodeHeader: "Postleitzahl",
      firstNameHeader: "Vorname",
      lastNameHeader: "Nachname",
      testerHeader: "Tester",
      testerHint: "Enable testing functionality",
      contactNumberHeader: "Telefonnummer",
      emailHeader: "E-Mail",
      passwordHeader: "Passwort",
    },
    changePasswordPanel: {
      oldPasswordFieldHint: "Aktuelles Passwort",
      newPasswordFieldHint: "Neues Passwort",
      oldPasswordRequired: "Das aktuelle Passwort wird benötigt",
      newPasswordRequired: "Neues Passwort wird benötigt",
      updatePasswordButtonLabel: "Passwort aktualisieren",
    },
    propertyDetailPage: {
      propertyLink: "Gebäude",
      errorTitle: "Gebäude kann nicht geladen werden",
      updateError: "Gebäude kann nicht aktualisiert werden",
      ownerOrgLabel: "Inhaber Unternehmen",
    },
    addressPanel: {
      addressHeader: "Adresse",
      contactHeader: "Name",
      contactNumberHeader: "Telefonnummer",
      emailHeader: "E-Mail",
      notesHeader: "Notizen",
    },
    productGrid: {
      title: "Produkte",
    },
    productSummary: {
      productTitle: "Produkt",
      productTypeTitle: "Produkt Typ",
      statusMessageTitle: "Statusmeldung",
    },
    productReportTable: {
      title: "Produktberichte",
      headerContractor: "Auftragnehmer",
      headerTester: "Tester",
      headerStatus: "Status",
      headerEmail: "Email",
      headerReportee: "Tester E-Mail",
      headerProperty: "Eigentum",
      headerDate: "Datum",
      downloadReportLabel: "Download Bericht",
      emailReportLabel: "Export",
      noReportsTaken: "Kein Bericht erstellt",
      noReportsFound: "Kein Bericht gefunden",
    },
    resendActivationEmailRequestPage: {
      title: "Kontoaktivierung benötigt",
      message:
        "Bitte aktivieren Sie Ihr Konto, um ins Sense Portal zu gelangen. Eine Aktivierungsmail wurde Ihnen geschickt; bitte klicken Sie auf den Link um fortzufahren",
      resendEmailButton: "Aktivierungsmail noch einmal senden",
      signInPrompt: "Haben Sie Ihr Konto bereits aktiviert?",
      signInButton: "Bitte melden Sie sich in Ihrem Konto an",
      error:
        "E-Mail kann nicht erneut gesendet werden. Versuchen Sie es später noch einmal",
    },
    resendActivationEmailSuccessPage: {
      title: "Kontoaktivierungsmail gesendet",
      message:
        "Wir haben Ihnen eine Aktivierungsmail gesendet. Bitte folgen Sie dort den Anweisungen, um Ihr Konto zu aktivieren",
      signInPrompt: "Haben Sie Ihr Konto aktiviert?",
      signInButton: "Melden Sie sich in Ihrem Konto an",
    },
    addressEditor: {
      line1: "Zeile 1",
      line2: "Zeile 2",
      line3: "Zeile 3",
      city: "Stadt",
      postCode: "Postleitzahl",
      country: "Land",
      line1RequiredError: "Zeile 1 erforderlich",
      cityRequiredError: "Stadt erforderlich",
      postCodeRequiredError: "Postleitzahl erforderlich",
      countryRequiredError: "Land erforderlich",
      addressLookupButton: "Adresssuche",
    },

    limboAddress: {
      message:
        "This property is missing the full address. Please complete it before you could fully access and edit other property fields",
    },

    limboSignpost: {
      inviteTitle: "Treten Sie einem bestehenden Firmenkonto bei",
      createTitle: "Erstellen Sie Ihr Firmenkonto",
      createAction: "Erstellen Sie ein Unternehmen",
      inviteBody:
        "Sie benötigen eine Einladung von einer bereits registrierten Firma, um fortzufahren. Bitte fordern Sie diese beim Administrator des Firmenskontos an. Alternativ kontaktieren Sie bitte",
      createBody:
        "Bitte vervollständigen Sie den folgenden Abschnitt, um Ihr Firmenkonto zum ersten Mal zu registrieren. Für weitere Informationen kontaktieren Sie bitte",
      contactLink: "info.de@adey.com",
    },
    headerTabs: {
      dashboard: "Dashboard",
      userList: "Nutzer",
      reports: "Reports",
      organisations: "Unternehmen",
      properties: "Gebäude",
      agents: "Adey Partner",
      memberUserList: "Nutzer",
      totalOrganisationList: "Unternehmen",
    },
    organisationListPage: {
      searchPlaceholder: "Suche nach einem Unternehmen",
      addButton: "Auftragnehmer einladen",
      loadError: "Laden von Unternehmen nicht möglich",
      emptyMessage: "Keine Unternehmen gefunden",
      emptyInviteMessage: "Keine Einladungen gefunden",
      contractorTab: "Auftragnehmer",
      associatedOrgsTab: "Verbundene Unternehmen",
      invitesTab: "Ausstehende Einladungen",
      inviteTooltip:
        "Liste von Einladungen, die noch von Nutzern akzeptiert werden müssen",
      contractorTooltip: "Liste der Firmen, die für mein Unternehmen arbeiten",
      associatesTooltip: "Liste der Firmen, für die mein Unternehmen arbeitet",
    },
    userTable: {
      nameHeader: "Name",
      emailHeader: "E-mail",
      phoneHeader: "Telefonnummer",
    },
    orgTable: {
      nameHeader: "Name",
      propertiesHeader: "Gebäude",
      managedPropertiesHeader: "Verwaltete Eigenschaften",
      phoneHeader: "Telefonnummer",
    },
    agentTable: {
      nameHeader: "Name",
      phoneHeader: "Telefonnummer",
      countryHeader: "Land",
    },
    agentSwitch: {
      labelTitle: "Adey Partner",
      switchTitle: "Zugang Adey Partner",
      agentExistsHint:
        "Sie können dieses Unternehmen nicht als Adey Partner registrieren, da dieser in diesem Land bereits existiert",
    },
    agentDialog: {
      title: "Zugang Adey Partner",
      removeAgentText:
        "Sind Sie sicher, dass Sie den ADEY Partnerzugang von %s widerrufen möchten. Das Konto ändert sich in ein Standardunternehmenskonto",
      removeAgentWarning:
        "Beachten Sie: Unternehmen, die durch %s betreut werden, werden den Ihnen zugeteilten Partner verlieren",
      grantAgentAccessText:
        "Sind Sie sicher, dass Sie dem Adey Partner zu %s Zugang gewähren möchten?",
      grantAgentAccessWarning:
        "Achtung: Sie werden den Zugang zu den regulären Features verlieren",
    },
    agentAssignmentDialog: {
      agentRemovedTitle: "ADEY Partner Zugang widerrufen",
      agentAssignedTitle: "ADEY Partner Zugang gewährt",
      agentRemovedText:
        'ADEY Partner Zugang erfolgreich von %s widerrufen. Durch "Bestägigen" gelangen Sie zur Unternehmensseite',
      agentAssignedText:
        'ADEY Partner Zugang zu %s erfolgreich gewährt. Durch "Bestätigen" gelangen Sie zur ADEY Partner Seite',
    },
    inviteTable: {
      emailHeader: "E-Mail",
      role: "Funktion",
      actionsHeader: "Maßnahmen",
    },
    acceptOrganisationInvitePanel: {
      title: "Treten Sie dem Unternehmen bei",
      messageFormat:
        "Sie wurden von diesem Unternehmen als %s eingeladen. Durch annehmen der Einladung erhalten Sie Zugang.",
      acceptButton: "Einladung annehmen",
      acceptError: "Einladung kann nicht angenommen werden",
      rejectError: "Einladung kann nicht abgelehnt werden",
      declineButton: "Ablehnen",
    },
    acceptContractorInvitePanel: {
      title: "Einladung Auftragnehmer",
      messageFormat:
        "Ihr Unternehmen wurde eingeladen, ein Auftragnehmer für dieses Unternehmen zu werden.",
      acceptButton: "Einladung annehmen",
      acceptError: "Einladung kann nicht angenommen werden",
      rejectError: "Einladung kann nicht abgelehnt werden",
      declineButton: "Ablehnen",
    },
    userTableMenu: {
      removeUser: "Nutzer entfernen",
      confirmActionText: "Sind Sie sicher?",
    },
    orgTableMenu: {
      removeOrg: "Unternehmen entfernen",
      confirmActionText: "Sind Sie sicher?",
      removeOrgError: "Unternehmen kann nicht entfernt werden",
    },
    itemTableMenu: {
      confirmActionText: "Sind Sie sicher?",
    },
    invitePendingFlag: {
      label: "Einladung ausstehend",
    },
    pendingInviteMenu: {
      resendEmail: "Einladungsmail noch einmal senden",
      emailResent: "Einladungsmail noch einmal gesendet",
      resendError: "Einladungsmail kann nicht noch einmal gesendet werden",
      revokeError: "Einladung kann nicht zurückgenommen werden",
      revokeInvite: "Einladung widerrufen",
    },
    userDetailPage: {
      firstNameLabel: "Vorname",
      lastNameLabel: "Nachname",
      emailLabel: "E-Mail",
      numberLabel: "Telefonnummer",
      roleLabel: "Funktion",
      organisationLabel: "Unternehmen",
      loadError: "Zugang zu Nutzerdetails nicht möglich",
      removeError: "Nutzer kann nicht entfernt werden",
    },

    userDetailPageTabs: {
      userDetailTab: "Nutzer Details",
      proCheckTab: "ProCheck Results",
    },

    orgDetailPage: {
      orgNameLabel: "Name Unternehmen",
      addressLabel: "Adresse",
      numberLabel: "Telefonnummer",
      vatLabel: "Umsatzsteueridentifikationsnummer (VAT)",
      removeError: "Unternehmen kann nicht entfernt werden",
      agentError:
        "Dieses Unternehmen ist ein Partner. Sie können es nicht als Standard-Unternehmen sehen",
    },
    orgDetailsSwitcher: {
      detailsLabel: "Details",
      ownerLabel: "Inhaber/Super Admin",
      propertiesLabel: "Gebäude",
    },
    roles: {
      owner: "Super Admin",
      admin: "Admin",
      developer: "Developer",
      installer: "Servicetechniker",
      contractor: "Auftragsnehmer",
      agent: "Adey Partner",
    },
    removeUserButton: {
      labelPlaceholder: "Von %s entfernen",
    },
    confirmRemoveUserDialog: {
      title: "Nutzer entfernen",
      bodyPlaceholder:
        "Sind Sie sicher, dass Sie <user_name> entfernen wollen? Sie werden den Zugang zu allen Gebäuden innerhalb <org_name>verlieren",
      removeButtonPlaceholder: "Entfernen",
    },
    userRemovedDialog: {
      title: "Nutzer entfernt",
      bodyPlaceholder: "\n<user_name> wurde von <org_name> entfernt",
      confirmButton: "Zurück zur Auflistung",
    },
    removeContractorButton: {
      labelPlaceholder: "Von %s entfernen",
    },
    adminRemoveContractorDialog: {
      title: "Auftragnehmer entfernen",
      bodyPlaceholder:
        "Sind Sie sicher, dass <contractor_name> von <contractee_name> entfernen möchten? <contractor_name> wird den Zugang zu allen Gebäuden von <contractee_name> verlieren",
      removeButtonPlaceholder: "Entfernen",
    },
    confirmRemoveContractorDialog: {
      title: "Auftragnehmer entfernen",
      bodyPlaceholder:
        "\nSind Sie sicher, dass Sie <contractor_name> entfernen möchten? Sie werden den Zugang zu allen Gebäuden von <org_name> verlieren",
      removeButtonPlaceholder: "Entfernen",
    },
    contractorRemovedDialog: {
      title: "Auftragnehmer entfernt",
      bodyPlaceholder:
        "\n<contractor_name> wurde als Auftragnehmer bei  <org_name> entfernt",
      confirmButton: "Zurück zur Auflistung",
    },
    currentOrganisationPage: {
      organisationNameLabel: "Name Unternehmen",
      addressLabel: "Adresse",
      contactNumberLabel: "Telefonnummer",
      vatNumberLabel: "Umsatzsteueridentifikationsnummer (VAT)",
      pageTitle: "Aktuelles Unternehmen",
      dashboardLink: "Dashboard",
      editError: "Bearbeiten der Details nicht möglich",
    },
    createOwnerOrganisationPromptPanel: {
      title: "Einladung Auftragnehmer",
      messageFormat:
        "\nSie wurden eingeladen, ein Auftragnehmer von %s zu werden. Um die Einladung zu akzeptieren, müssen Sie ein Unternehmen anlegen",
      createOrganisationButton: "Unternehmen anlegen",
      declineButton: "Ablehnen",
    },
    createOrganisationPanel: {
      title: "Unternehmen anlegen",
      backButton: "Zurück",
      error: "Unternehmen kann nicht angelegt werden",
      tooltip:
        "Wenn Sie ein neues Unternehmen anlegen wird dieses nicht mit einer anderem Unternehmen verlinkt sein",
    },
    stopContractingButton: {
      title: "Vertrag mit %s beenden",
    },
    confirmStopConfirmingDialog: {
      title: "Vertrag beenden",
      bodyPlaceholder:
        "Wenn Sie den Vertrag mit %s beenden, verlieren Sie den Zugang zu allen Gebäuden, die Ihnen zugewiesen wurden.",
      stopContractingButton: "Vertrag beenden",
    },
    adminRemoveContracteeDialog: {
      title: "Vertragspartner entfernen",
      bodyPlaceholder:
        "Sind Sie sicher, dass Sie <contractee_org>? <contractor_org> wird den Zugang zu allen Gebäuden, die von <contractee_org> zugewiesen worden sind, verlieren.",
      stopContractingButton: "Entfernen",
    },
    stopContractingCompletedDialog: {
      title: "Vertrag beendet",
      bodyPlaceholder: "Der Vertrag mit %s ist beendet",
      returnToListButton: "Zurück zur Auflistung",
    },
    addressLookup: {
      countryField: "Land",
      countryRequiredError: "Land erforderlich",
      title: "Adresssuche",
      searchAgainButton: "Suche erneut starten",
      fetchError: "Adressen nicht auffindbar",
      emptyError: "Adressen konnten für diese Suche nicht gefunden werden",
      retrieveError: "Adress-Details können nicht geladen werden",
      searchButton: "Suche",
      searchPlaceholder: "Suche nach Adresse oder Postleitzahl",
      requiredError: "Bitte Suchtext eingeben",
    },
    homeButton: {
      adminText: "Admin Portal",
    },
    platformUsersList: {
      tableTitle: "Nutzer",
      searchBarPlaceholder: "Nutzer suchen",
      loadError: "Nutzerliste kann nicht geladen werden",
      emptyMessage: "Für diese Suche konnten keine Nutzer gefunden werden",
    },
    platformAgentList: {
      tableTitle: "Adey Bevollmächtigter",
      searchBarPlaceholder: "Suche nach Adey Bevollmächtigten",
      loadError: "Bevollmächtigten Liste kann nicht geladen werden",
      emptyMessage: "Für diese Suche konnten keine Bevollmächtigten gefunden werden",
    },
    platformOrganisationsList: {
      tableTitle: "Unternehmen",
      addOrganisationButton: "Unternehmen hinzufügen",
      searchBarPlaceholder: "Suche nach Unternehmen",
      loadError: "Unternehmensliste kann nicht geladen werden",
      emptyMessage: "Für diese Suche konnten keine Unternehmen gefunden werden",
    },
    inviteSentPanel: {
      title: "Einladung gesendet",
      bodyPlaceholder: "Eine Einladungsmail wurde an %s gesendet",
      continueButton: "Weiter",
    },

    senseDeviceInfoCard: {
      lastReading: "Last Reading",
      product: "Produkt",
      status: "Status",
      totalStatus: "Gesamtstatus",
      serialNumber: "Serial Number",
      moreInfoButton: "More Info",
    },

    senseDeviceReadingsCard: {
      magnetiteLevel: "Magnetite Level",
      statusMessage: "Last Notifications",
      service: "Service",
      lastReading: "Last reading",
      connection: "Connection",
      lastCleaned: "Last cleaned",
    },

    senseDeviceStatus: {
      green: "Good",
      amber: "Needs Attention",
      red: "Needs Immediate Attention",
    },

    senseDevices: {
      loadError: "Unable to load Sense Devices",
      activationError: "Unable to change device activation status",
    },

    selectedSenseDevice: {
      loadError: "Unable to load selected Sense Device",
    },

    senseDeviceBatteryIndicator: {
      title: "Battery level",
      low: "The battery levels are very low, replacements are required immediately.",
      medium: "The battery levels are low, replacements required in next 2 months. ",
      good: "The batteries are in good condition.",
      noSignal: "Could not get battery level",
    },

    senseDeviceMagnetiteIndicator: {
      noLevel:
        "Could not get device magnetite level. If the problem persists, please contact a member of the ADEY support team info@adey.com",
      1: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      2: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      3: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      4: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
    },

    senseDeviceContactIndicator: {
      noContact:
        "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      contactOk: "Filter is currently connected and reporting",
    },

    proCheck: {
      reportName: "Bericht %s",
      name: "ProCheck",
      loadError: "ProCheck Ergebnisse können nicht geladen werden",
      statusTitle: "Status",
      latestTestTitle: "Letztes Test-Datum",
      moreInfoButton: "Weitere Informationen",
      downloadLatestTestButton: "Download des letzten Tests",
      noTestsLabel: "Keine Tests gemacht",
      pass: "Bestanden",
      recommendation: "Empfehlung",
      productTitle: "Produkt",
      downloadError: "Download des Berichts nicht möglich",
      productType: "Wassertest",
      exportSuccess: "Erfolgreich exportiert!",
    },
    proCheckTabs: {
      detailsTab: "Bericht Details",
      resultsTab: "Testergebnis",
    },
    proCheckDetailsTab: {
      timezone: "Zeitzone",
      date: "Datum",
      time: "Uhrzeit",
      email: "E-Mail",
      engineer: "Servicetechniker",
      reason: "Grund der Überprüfung",
      address: "Adresse",
      type: "Typ",
      filter: "Filter eingebaut",
      boilerNumber: "S/N Wärmeerzeuger",
      jobReference: "Auftragskennung",
    },
    ProCheckResultsTab: {
      title: "Test Ergebnis",
      summary: "Zusammenfassung",
      note: "Anmerkungen zum Test",
      waterSample: "Wasserprobe",
      testCard: "Testkarte",
      location: "Ort",
      waterTest: "Trübheit",
    },
    ProCheckWaterTest: {
      pass: "Wassertest bestanden",
      fail: "Wassertest nicht bestanden",
      recommendation: "Wassertest nicht bestanden - siehe Empfehlungen",
    },
    ProCheckMaps: {
      locationMissing: "ProCheck-Test Ort fehlt",
    },
    ProCheckSummary: {
      colourTestMissing:
        "Die Wasserprobe hat die Sichtkontrolle nicht bestanden. Es wurde Korrosion festgestellt. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Die ADEY-Wasserprüfsets sind bei örtlichen Händlern erhältlich.",
      molyPassRec:
        "Inhibitorgehalt ok, Anlage ist geschützt, keine weiteren Maßnahmen erforderlich.",
      molyLowRec:
        "Inhibitorgehalt zu niedrig muss aufgefüllt werden. Verwenden Sie ADEY MC1+ (oder ein gleichwertiges Produkt). Lassen Sie es 30 Minuten in der Anlage zirkulieren und wiederholen Sie dann den Test.",
      ironHighRecCopperPass:
        "Iron level is high. If left untreated it can cause performance deterioration and, ultimately, failure. Full system clean is advised.",
      ironHighRec:
        "Hoher Korrosionsgrad. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Die ADEY-Wasserprüfsets sind bei örtlichen Händlern erhältlich.",
      ironModerateRec:
        "Mäßiger Korrosionsgrad derzeit keine weiteren Maßnahmen erforderlich.",
      ironPassRec: "Niedriger Korrosionsgrad keine weiteren Maßnahmen erforderlich.",
      ironModerateCopperPresentRec:
        "Iron level is moderate. No further action required at this stage.",
      ironPassCopperPresentRec: "Iron level is low. No further action required",
      phPassRec: "pH-Wert ok. Nkeine weiteren Maßnahmen erforderlich.",
      phLowRec:
        "Der pH-Wert ist niedrig und weist auf einen schlechten Zustand der Anlage hin. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Die ADEY-Wasserprüfsets sind bei örtlichen Händlern erhältlich.",
      phLowLowMoly:
        "Der pH-Wert ist niedrig und weist auf einen schlechten Zustand der Anlage hin. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Hinweis: Wenn außerdem Zu wenig Inhibitor festgestellt wurde normalisiert sich der pH-Wert möglicherweise nach dem Auffüllen des Inhibitors. Lassen Sie das Mittel 30 Minuten in der Anlage zirkulieren und wiederholen Sie dann den Test.",
      phHighRec:
        "Der pH-Wert ist hoch und weist auf einen schlechten Zustand der Anlage hin. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Die ADEY-Wasserprüfsets sind bei örtlichen Händlern erhältlich.",
      phHighRecLowMoly:
        "Der pH-Wert ist hoch und weist auf einen schlechten Zustand der Anlage hin. Wir empfehlen einen Labortest oder eine Reinigung der gesamten Anlage. Hinweis: Wenn außerdem Zu wenig Inhibitor festgestellt wurde normalisiert sich der pH-Wert möglicherweise nach dem Auffüllen des Inhibitors. Lassen Sie das Mittel 30 Minuten in der Anlage zirkulieren und wiederholen Sie dann den Test.",
      copperHighRec:
        "Copper level is high. If left untreated it can cause pitting corrosion of aluminum and steel. Full system clean is advised. ",
      copper_pass: "Copper level ok",
      copper_low:
        "copper level is very low  indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
    },
    proCheckTable: {
      searchBarPlaceholder: "Filtern nach Tester",
      expandedSearchBarPlaceholder: "Search for a heating engineer or property address",
      exportSelectedButtonLabel: "Export/Download ausgewählt",
      resultSelectorCounter: "\n<result_counter> von 5 Berichten gewählt",
    },
    proCheckResultTable: {
      phLevel: "pH-Wert",
      corrosionLevel: "Korrosionsgrad",
      inhibitorLevel: "Inhibitorgehalt",
      overallLevel: "Gesamt",
      recommendation: "Empfehlung",
      pass: "Bestanden",
      fail: "Nicht bestanden",
      typeHeader: "Typ",
      resultsHeader: "Ergebnisse",
    },
    proCheckExportDialog: {
      title: "Ausgewählte Berichte",
      privacySwitchLabel: "Optionen Privatsphäre des Testers",
      privacySwitchHint: "Persönliche Informationen des Testers ausblenden",
      forgetEmailHint: "Forget email(s)",
      rememberEmailHint: "Remember email(s)",
      exportButtonLabel: "Senden",
      emailFieldLabel: "Senden an",
      emailFieldPlaceholder: "Email(s)",
      emailFieldHint:
        'Sie können mehrere E-Mails auswählen. Drücken Sie "Enter" oder klicken Sie auf "Hinzufügen"',
      addEmailButtonLabel: "Hinzufügen",
      validationError: "Bitte stellen Sie eine gültige E-Mail-Adresse zur Verfügung",
      notesFieldLabel: "Anmerkungen (optional)",
    },

    emailValidator: {
      label: "The email you have provided has several issues",
      empty: "This email address is empty",
      trailingDot: "Trailing dot is present",
      leadingDot: "Leading dot is present",
      spaces: "Extra spaces are present",
      noAt: "This email address does not contain an asperand (@)",
      multipleAt: "This email address contains multiple asperands (@)",
      noLocalPart: "Local part is missing",
      doubleDot: "Multiple successive dots are present",
      unicodeChars: "Email should not contain non-alphanumeric values (Aa-Zz, 0-9)",
      noTopLevelDomain: "Top level domain is missing",
      extraDots: "Unnecessary extra dots",
      extraChars: "Please remove extra characters after the address",
      specialChars: "Email address should not contain any special characters",
      invalidEmail: "This email address is invalid",
    },
    propertyContractorPanel: {
      noContractor: "Kein Auftragnehmer",
      title: "Auftragnehmer",
    },
    platformUserDetail: {
      usersLink: "Nutzer",
      noDevelopers: "No developers found",
      userDetailsTab: "Nutzer Details",
      orgsTab: "Unternehmen",
      loadError: "Nutzer Details können nicht geladen werden",
      updateError: "Nutzer Details können nicht aktualisiert werden",
      removeError: "Nutzer kann nicht aus Unternehmen entfernt werden",
      firstNameLabel: "Vorname",
      surnameLabel: "Nachname",
      emailLabel: "E-Mail",
      numberLabel: "Telefonnummer",
      userSearch: "Nutzer suchen",
      inviteUserButton: "Nutzer einladen",
      noOrganisations: "Dieser Nutzer gehört zu keinem Unternehmen",
      noOwner: "Kein Besitzer gefunden",
      noEngineers: "Kein Servicetechniker gefunden",
      noAdmins: "Kein Admin gefunden",
      noUsers: "Kein Nutzer gefunden",
      noPendingInvites: "Keine ausstehenden Einladungen gefunden",
      removedSuccess: "Erfolgreich vom Unternehmen entfernt",
    },
    orgAndRoleTable: {
      nameHeader: "Name des Unternehmens",
      numberHeader: "Telefonnummer",
      roleHeader: "Funktion Nutzer",
    },
    platformOrganisationDetail: {
      orgSearch: "Suche Unternehmen",
      inviteContractorButton: "Vertragspartner einladen",
      contractorLoadError: "Vertragspartner können nicht geladen werden",
      contractorsTableTitle: "Vertragspartner",
      associatedOrgsTableTitle: "Zugewiesene Unternehmen",
      invitesTableTitle: "Ausstehende Einladungen",
      noContractors: "Keine Vertragspartner gefunden",
      noAssociatedOrgs: "Keine zugewiesenen Unternehmen gefunden",
      noInvites: "Keine ausstehenden Einladungen gefunden",
      organisationRemoveSuccess: "Unternehmen erfolgreich entfernt",
      agentAccessGrantError: "ADEY Partner konnte kein Zugang erteilt werden",
      agentAssignmentError: "ADEY Partner kann nicht zugewiesen werden",
    },
    platformAgentDetails: {
      notAgentError: "Dieses Untermnehmen ist kein Partner",
      agentDetailsLoadError: "Partner-Details können nicht geladen werden",
      agentUpdateError: "Partneraktualisierung nicht möglich",
      managedOrganisationLoadError: "Verwaltete Unternehmen können nicht geladen werden",
      removeManagedOrganisationError:
        "VerwalteteUnternehmen können nicht entfernt werden",
      revokeAgentAccessError: "Widerrufen Partnerzugang nicht möglich",
      removeManagedOrgSuccess: "Verwaltetes Unternehmen entfernt",
      agentUsersLoadError: "Nutzer Unternehmen können nicht geladen werden",
      removeAgentUserError: "Nutzer kann nicht entfernt werden",
      inviteError: "Nutzer kann nicht eingeladen werden",
    },
    platformAgentDetailTabs: {
      agentDetailsTab: "Details",
      managedOrganisationsTab: "Verwaltete Unternehmen",
      agentUsersTab: "Nutzer",
    },
    adminInviteContractorDialog: {
      titlePlaceholder: "Vertragspartner einladen zu %s",
      emailHint: "E-Mail-Adresse",
      emailRequiredError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      confirmEmailHint: "Bestätigen Sie die E-Mail",
      confirmEmailRequired: "Bestätigung der E-Mail erforderlich",
      confirmEmailMatchError: "E-Mails müssen identisch sein",
      inviteButton: "Einladung senden",
      inviteError: "Einladung kann nicht gesendet werden",
      inviteSentMessage: "Einladung erfolgreich gesendet",
      descriptionText:
        'Versichern Sie sich, dass Sie den zugehörigen "Besitzer/SuperAdmin" des Vertragspartnerkontos einladen,',
      contactHint: "Für weitere Informationen, kontaktieren Sie",
      contactEmail: "info.de@adey.com",
    },
    adminInviteUserDialog: {
      titlePlaceholder: "Nutzer zu %s einladen",
      emailHint: "E-Mail-Adresse",
      emailRequiredError: "Geben Sie eine gültige E-Mail-Adresse ein",
      roleHint: "Funktion Nutzer",
      roleRequired: "Funktion Nutzer wird benötigt",
      confirmEmailHint: "Bestätigen Sie die E-Mail-Adresse",
      confirmEmailRequired: "Bestätigung der E-Mail-Adresse erforderlich",
      confirmEmailMatchError: "E-Mail-Adressen müssen identisch sein",
      inviteButton: "Einladung senden",
      inviteError: "Einladung kann nicht gesendet werden",
      inviteSentMessage: "Einladung erfolgreich gesendet",
      developerContext:
        "Developers will be able to view existing properties and create new properties for the organisations that they are a part of, as well as access the Developer Zone",
      adminContext:
        "Unternehmensadministratoren können bestehende Gebäudedaten einsehen und neue für das Unternehmen erstellen zu dem sie gehören. Zudem können sie Nutzerdaten einsehen und Partnerschaften mit Auftragnehmern und zugehörigen Unternehmen verwalten.",
      heatingEngineerContext:
        "Servicetechniker können bestehende Gebäudedaten einsehen und für ihr Unternehmen neue erstellen",
    },
    deletePropertyHandler: {
      buttonTitle: "Gebäude löschen",
      confirmDialogTitlePlaceholder: "Lösche %s",
      confirmDialogBody:
        "Wenn Sie dieses Gebäude löschen, haben Sie keinen Zugriff mehr auf dessen daten. Das beinhaltet alle Tests und dort verwendeten Produkte.",
      confirmDialogCancelButton: "Widerrufen",
      confirmDialogDeleteButton: "Löschen",
      deleteError: "Gebäude kann nicht gelöscht werden",
      propertyDeletedTitle: "Gebäude gelöscht",
      propertyDeletedBody:
        "Dieses Gebäude wurde erfolgreich gelöscht. Sie haben keinen Zugang zu dessen Daten mehr.",
      propertyDeletedDashButton: "Zurück zum Dashboard",
    },
    agentUserList: {
      ownerTab: "Inhaber/Super Admin",
      agentTab: "Partner",
      developersTab: "Developers",
      invitesTab: "Ausstehende Einladungen",
      searchPlaceholder: "Nutzer suchen",
      inviteButton: "Nutzer einladen",
      emptyText: "Keine Nutzer gefunden",
    },
    agentOrganisationListPage: {
      searchPlaceholder: "Suche nach verwaltetem Unternehmen",
      noManagedOrganisations: "Keine verwalteten Unternehmen gefunden",
      switchingOrgTitle: "Zu <org_name> wechseln…",
    },
    agentInfoCard: {
      labelTitle: "ADEY Partner",
      organisationLabel: "Unternehmen",
      countryLabel: "Land",
    },
    adminRemoveManagedOrgDialog: {
      title: "Verwaltetes Unternehmen entfernen",
      bodyPlaceholder:
        "Zuweisung <managed_organisation> von <adey_agent> aufheben? <adey_agent> wird das Unternehmen nicht mehr verwalten können",
      removeButtonPlaceholder: "Entfernen",
    },
    organisationAgentPanel: {
      noAgent: "Kein ADEY Partner",
      title: "ADEY Partner",
    },

    exportBar: {
      title: "Artikel exportieren",
    },

    exportItem: {
      initializing: "Initialising report generation",
      generating: "Bericht erstellen",
      generated: "Bericht exportiert",
      failure: "Bericht fehlgeschlagen",
      cancelled: "Cancelling export...",
    },

    senseDetailsPageHeaders: {
      details: "Details",
      readings: "Readings",
    },

    senseActivationConfirmationDialog: {
      title: "Device Activation",
      contentActivate:
        "Are you sure you want to activate this device? {{- organisation_name}} will gain access to this device.",
      contentDeactivate:
        "Are you sure you want to deactivate this device? {{- organisation_name}} will lose access to this device until it is reactivated.",
      confirm: "Confirm",
      switchTitle: "Device Activation",
      switchLabelActive: "Device is Active",
      switchLabelDisabled: "Device is Disabled",
    },

    homeZoneSwitchPanel: {
      switchLabel: "HomeZone Notification",
      infoText:
        "Please enter homeowner details before activation of the HomeZone notification",
      title: "HomeZone",
    },

    senseDeviceDetailsTab: {
      productType: "Product Type",
      productNumber: "Product Number",
      serialNumber: "Serial Number",
      identifier: "MAC/IMEI",
      firmwareVersion: "Firmware Version",
      serviceInfo: "Service Information",
      organisation: "Unternehmens",
      contractor: "Auftragnehmer",
      heatingEngineer: "Service Techniker",
      deviceInstalled: "Device Installed",
      serviceDue: "Service Due",
      warrantyExpiry: "Warranty Expiry",
      notes: "Notes",
      lastReading: "Last reading",
      lastContact: "Last contact",
      lastCleaned: "Last cleaned",
    },

    senseDeviceReadingsTab: {
      title: "Latest Readings",
    },

    senseDeviceReadingsHeaders: {
      reading: "Reading",
      date: "Date",
      time: "Uhrzeit",
    },

    senseDeviceNames: {
      PROCHECK: "ProCheck",
      PRO3: "Pro3",
      M3SM: "Micro3 SIM",
      M3WF: "Micro3 Wi-Fi",
    },

    systemMessageContainer: {
      title: "Systemnachricht",
      hideLabel: "Nicht mehr anzeigen",
    },

    maintenanceDateMessage: {
      content:
        "Die Wartung findet statt: {{from}} - {{to}} Wir hoffen, dass wir so schnell wie möglich wieder in Betrieb gehen können",
    },

    systemDownMessage: {
      title: "PLANMÄSSIGE WARTUNG",
      content:
        "Wir nehmen derzeit einige aufregende Änderungen vor, was bedeutet, dass Sie dieses Portal derzeit nicht verwenden können. \n\nWir hoffen, dass wir so schnell wie möglich wieder in Betrieb gehen können. Entschuldigen Sie die entstandenen Unannehmlichkeiten",
    },

    scheduledMaintenanceMessage: {
      title: "PLANMÄSSIGE WARTUNG",
      content:
        "Wir nehmen einige aufregende Änderungen vor, was bedeutet, dass Sie dieses Portal für eine Weile nicht nutzen können.",
    },

    promotionMessage: {
      title: "FÖRDERUNG",
      content:
        "[NOT TRANSLATED] Sample promotion text. \nThe promotions lasts: \n{{from}} – {{to}} \nSome extra text",
    },

    developerZonePopup: {
      title: "Developer Zone",
      subTitle: "Welcome to the ADEY Sense Developer Zone",
      description:
        "A central hub for developers to access Sense APIs and support information",
      inviteButtonLabel: "Invite Developer",
      redirectButtonLabel: "Get in the Zone!",
    },

    userSegmentation: {
      updateError: "Error submitting segmentation details",
      questionTitle: {
        "question-1": "Welche der folgenden Aussagen beschreibt Sie am besten?",
        "question-2": "Wo erledigen Sie den Großteil Ihrer Arbeit?",
      },
      answerDescription: {
        "heating-engineer-independent": "Heizungsinstallateur (selbstständig)",
        plumber: "Klempner",
        "residential-contractor": "Wohnbauunternehmer",
        "residential-contractor-50": "Wohnbauunternehmer (50+ Angestellte)",
        other: "Andere",
        residential: "Einfamilienhäuser",
        "new-build": "Neubau",
        "social-housing": "Sozialer Wohnungsbau/HA/LA",
        utility: "Energieversorger",
        "boiler-manufacturer-oem": "Heizungshersteller/OEM",
      },
    },
  },
};

export default translations;
