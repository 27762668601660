class MockAxiosError extends Error {
  isAxiosError = true;

  constructor(
    public statusCode?: number,
    public request?: {
      method?: string;
      url?: string;
    },
    public errors?: object
  ) {
    super();
  }

  // Use this to conform to the expected
  // error message format
  get response(): any {
    return {
      status: this.statusCode,
      data: {
        message: this.message,
        errors: this.errors,
      },
    };
  }

  get config(): any {
    return {
      method: this.request?.method,
      url: this.request?.url,
    };
  }
}

export default MockAxiosError;
