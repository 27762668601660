import { TFunction } from "react-i18next";
import { MatchInfo } from "react-linkify";
import { FORGOT_PASSWORD_ROUTE } from "../routes/routes";

const linkifyUrlMatcher = (text: string | undefined, t: TFunction): MatchInfo[] => {
  let matches: MatchInfo[] = [];

  if (text === undefined) {
    return matches;
  }

  if (text.includes(FORGOT_PASSWORD_ROUTE)) {
    matches.push({
      text: t("proClub.status412Link"),
      url: FORGOT_PASSWORD_ROUTE,
      schema: "",
      index: text.lastIndexOf(FORGOT_PASSWORD_ROUTE),
      lastIndex: text.lastIndexOf(FORGOT_PASSWORD_ROUTE) + FORGOT_PASSWORD_ROUTE.length,
    });
  }

  return matches;
};

export default linkifyUrlMatcher;
