// Register

export const REGISTER_START_TYPE = "REGISTER_START_TYPE";

export interface RegisterStartAction {
  type: typeof REGISTER_START_TYPE;
  email: string;
  password: string;
}

export const registerStartAction = (
  email: string,
  password: string
): RegisterStartAction => {
  return {
    type: REGISTER_START_TYPE,
    email: email,
    password: password,
  };
};

// Register success

export const REGISTER_SUCCESS_TYPE = "REGISTER_SUCCESS_TYPE";

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS_TYPE;
}

export const registrationSuccess = (): RegisterSuccessAction => {
  return {
    type: REGISTER_SUCCESS_TYPE,
  };
};

// Register error

export const REGISTER_ERROR_TYPE = "REGISTER_ERROR_TYPE";

export interface RegisterErrorAction {
  type: typeof REGISTER_ERROR_TYPE;
  error: string;
}

export const registerError = (error: string): RegisterErrorAction => {
  return {
    type: REGISTER_ERROR_TYPE,
    error: error,
  };
};

// Union type

type RegistrationAction =
  | RegisterStartAction
  | RegisterSuccessAction
  | RegisterErrorAction;

export default RegistrationAction;
