import { Box } from "@material-ui/core";
import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { DataEntry } from "react-minimal-pie-chart/types/commonTypes";
import PieChartCenterLabel from "./components/PieChartCenterLabel";

const textColor = "#656565";
const backwardChartColor = "rgba(40, 15, 113, 0.15)";
const segmentWidth = 24;

interface Props {
  data: DataEntry[] | DataEntry | undefined;
  size: number;
  animated: boolean;
}

const AnimatedPieChart = (props: Props) => {
  const boxProps = { width: props.size, height: props.size, position: "relative" };

  if (Array.isArray(props.data)) {
    return (
      <Box {...boxProps}>
        <MultiSegmentPieChart data={props.data} />
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      {/* A 100% bar chart behind this one to set the background color */}
      <PieChart
        data={[{ value: 100, color: backwardChartColor }]}
        style={{ position: "absolute", zIndex: 1 }}
        lineWidth={segmentWidth}
      />

      {/* The actual data on top of the background one.*/}
      {props.data !== undefined ? (
        <PieChart
          style={{ position: "absolute", zIndex: 2 }}
          data={[props.data]}
          totalValue={100}
          lineWidth={segmentWidth}
          startAngle={270}
          animate={props.animated}
        />
      ) : undefined}

      {/* The "50%" label on top */}
      {props.data ? (
        <PieChartCenterLabel
          textColor={textColor}
          size={props.size}
          value={props.data.value}
        />
      ) : undefined}
    </Box>
  );
};

// A pie chart without a center label with multiple entries.
const MultiSegmentPieChart = (props: { data: DataEntry[] }) => {
  return (
    <PieChart
      style={{ position: "absolute", zIndex: 2 }}
      data={props.data}
      lineWidth={segmentWidth}
      startAngle={270}
    />
  );
};

export default AnimatedPieChart;
