import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import HomeButton from "../../ui/components/DashboardLayout/components/HomeButton";
import { BACKGROUND_ACCENT_COLOR } from "../../ui/theme/createMaterialTheme";
import termsEn from "./assets/adey-sense-tnc-en.pdf";
import termsFr from "./assets/adey-sense-tnc-fr.pdf";
import termsDe from "./assets/adey-sense-tnc-de.pdf";

import DocumentPage from "./components/DocumentPage";
import LanguageSwitcher from "../../ui/components/LanguageSwitcher";

type Props = WithTranslation & RouteComponentProps;

type AvailableLanguages = "en" | "de" | "fr";

const TermsAndConditionsDocumentPage = (props: Props) => {
  //Component state
  const [termsFile, setTermsFile] = useState(termsEn);

  //Component effect - if the language is changed, the path to PDF will change
  useEffect(() => {
    languageDetector(props.i18n.language as AvailableLanguages);
  }, [props.i18n.language]);

  //Helpers
  const languageDetector = (lng: AvailableLanguages) => {
    switch (lng) {
      case "en":
        setTermsFile(termsEn);
        return;
      case "de":
        setTermsFile(termsDe);
        return;
      case "fr":
        setTermsFile(termsFr);
        return;
    }
  };

  //Handlers
  const handleLogoClicked = () => {
    // Push an empty route so we're redirected to the home page
    props.history.push("/");
  };

  return (
    <Box bgcolor={BACKGROUND_ACCENT_COLOR} minWidth="100%" maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        paddingTop={3}
        alignContent
        paddingRight={1}
      >
        <HomeButton onClick={handleLogoClicked} adminMode={false} />
        <LanguageSwitcher styled={true} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="none"
        width="100%"
      >
        <DocumentPage filePath={termsFile} fullscreen />
      </Box>
    </Box>
  );
};

export default withRouter(withTranslation()(TermsAndConditionsDocumentPage));
