import { put, takeEvery } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import AuthService from "../../Login/service/AuthService";
import {
  forgotPasswordActivationRequired,
  forgotPasswordError,
  ForgotPasswordRequestAction,
  forgotPasswordRequestSuccess,
  ForgotPasswordSubmitAction,
  forgotPasswordSubmitSuccess,
  forgotPasswordTokenExpired,
  FORGOT_PASSWORD_REQUEST_TYPE,
  FORGOT_PASSWORD_SUBMIT_TYPE,
} from "./ForgotPasswordAction";
import AuthProxyService from "../../Login/service/AuthProxyService";
import { AxiosError } from "axios";

function* forgotPasswordSagas() {
  yield takeEvery(FORGOT_PASSWORD_REQUEST_TYPE, onForgotPasswordRequest);
  yield takeEvery(FORGOT_PASSWORD_SUBMIT_TYPE, onForgotPasswordSubmit);
}

function* onForgotPasswordRequest(action: ForgotPasswordRequestAction) {
  const authProxyService: AuthProxyService = yield getDependency("authProxyService");

  try {
    yield authProxyService.forgotPasswordRequest(action.email);

    yield put(forgotPasswordRequestSuccess());
  } catch (e) {
    // a 422 means activation is required
    const activationRequired = (e as AxiosError).response?.status === 422;

    if (activationRequired) {
      yield put(forgotPasswordActivationRequired(action.email));
    } else {
      const message = getErrorMessage(e, undefined);
      yield put(forgotPasswordError(message));
    }
  }
}

function* onForgotPasswordSubmit(action: ForgotPasswordSubmitAction) {
  const authService: AuthService = yield getDependency("authService");

  try {
    yield authService.forgotPasswordSubmit(action.email, action.code, action.newPassword);

    yield put(forgotPasswordSubmitSuccess());
  } catch (e) {
    const tokenExpired: boolean = (e as any).code === "ExpiredCodeException";

    if (tokenExpired) {
      yield put(forgotPasswordTokenExpired());
      return;
    }

    const message = getErrorMessage(e, undefined);
    console.error("Error logging in " + (e as any).message);
    yield put(forgotPasswordError(message));
  }
}

export default forgotPasswordSagas;
