import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { RoleName } from "../../../../rest/roles/model/Role";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import RolePicker from "../../../../ui/components/RolePicker";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import isValidEmail from "../../../../utils/isValidEmail";

//Props
interface Props extends WithT {
  open: boolean;
  organisationName: string;
  isLoading: boolean;
  inviteSent: boolean;
  hasOwner?: boolean;
  canInviteDeveloper?: boolean;
  onClose: () => void;
  sendInvite: (email: string, role: RoleName) => void;
}

//State
interface State {
  email: FieldState;
  confirmEmail: FieldState;
  role: RoleName | undefined;
  roleError: string | undefined;
  showSuccess: boolean;
}

class AdminInviteUserDialog extends Component<Props, State> {
  state = {
    email: { value: "" },
    confirmEmail: { value: "" },
    role: undefined,
    roleError: undefined,
    showSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.inviteSent && !prevProps.inviteSent) {
      this.props.onClose();

      this.setState({
        email: { value: "" },
        confirmEmail: { value: "" },
        role: undefined,
        roleError: undefined,
      });
    }
  }

  render() {
    const titlePlaceholder = this.props.t("adminInviteUserDialog.titlePlaceholder");
    const emailHint = this.props.t("adminInviteUserDialog.emailHint");
    const confirmEmailHint = this.props.t("adminInviteUserDialog.confirmEmailHint");
    const roleHint = this.props.t("adminInviteUserDialog.roleRequired");
    const inviteButton = this.props.t("adminInviteUserDialog.inviteButton");

    const title = titlePlaceholder
      .replace("%s", this.props.organisationName)
      .toLocaleUpperCase();

    const availableRoles: RoleName[] = this.props.hasOwner
      ? [
          "administrator",
          "installer",
          ...(this.props.canInviteDeveloper ? ["developer" as RoleName] : []),
        ]
      : ["owner"];

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Box padding={DEFAULT_SPACING}>
          <Grid container spacing={SMALL_SPACING} alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" align="center">
                {title}
              </Typography>
            </Grid>
            <Grid item />
            <Grid item xs={12}>
              <PrimaryTextField
                {...this.state.email}
                hint={emailHint}
                onChange={(v) => this.handleFieldChanged(v, "email")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryTextField
                hint={confirmEmailHint}
                {...this.state.confirmEmail}
                onChange={(v) => this.handleFieldChanged(v, "confirmEmail")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <RolePicker
                role={this.state.role}
                availableRoles={availableRoles}
                hint={roleHint}
                helperText={this.showContextText(this.state.role)}
                onRoleChanged={this.onRoleSelectorChange}
                errorText={this.state.roleError}
              />
            </Grid>
            <Grid item />

            <Grid item xs={12}>
              <PrimaryButton
                label={inviteButton}
                endIcon={<FontAwesomeIcon icon={faArrowRight} size="2x" />}
                onClick={this.handleInviteSent}
                fullWidth
                isLoading={this.props.isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
  //Helpers
  showContextText = (role: RoleName | undefined) => {
    const heatingEngineerContext = this.props.t(
      "adminInviteUserDialog.heatingEngineerContext"
    );
    const adminContext = this.props.t("adminInviteUserDialog.adminContext");
    const developerContext = this.props.t("adminInviteUserDialog.developerContext");

    //TODO: Add Owner Context strings
    const ownerContext = "";

    switch (role) {
      case "administrator":
        return adminContext;
      case "installer":
        return heatingEngineerContext;
      case "owner":
        return ownerContext;
      case "developer":
        return developerContext;
      default:
        return undefined;
    }
  };

  //MARK: Handlers
  handleFieldChanged = (value: string, field: keyof State) => {
    const state: any = {};
    state[field] = { value: value };
    this.setState(state);
  };

  onRoleSelectorChange = (role: RoleName) => {
    this.setState({
      ...this.state,
      role: role,
      roleError: undefined,
    });
  };

  handleInviteSent = () => {
    if (this.validate().valid) {
      const email = this.state.email.value.trim();
      const role = this.state.role;
      if (role !== undefined) {
        this.props.sendInvite(email, role);
      }
    }
  };

  //MARK: Validation
  validate = () => {
    const email = this.state.email.value.trim();
    const confirmEmail = this.state.confirmEmail.value.trim();
    const role = this.state.role;
    let valid = true;

    if (email.length === 0 && !isValidEmail(email)) {
      valid = false;
      this.setState({
        email: {
          value: email,
          errorText: this.props.t("adminInviteUserDialog.emailRequiredError"),
        },
      });
    }

    if (confirmEmail.length === 0 && !isValidEmail(email)) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: email,
          errorText: this.props.t("adminInviteUserDialog.confirmEmailRequired"),
        },
      });
    } else if (confirmEmail !== email) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: confirmEmail,
          errorText: this.props.t("adminInviteUserDialog.confirmEmailMatchError"),
        },
      });
    }

    if (role === undefined) {
      valid = false;
      this.setState({
        roleError: this.props.t("adminInviteUserDialog.roleRequired"),
      });
    }

    return { valid: valid, email: email, confirmEmail: confirmEmail };
  };
}

export default withTranslation()(AdminInviteUserDialog);
