import { Box, Dialog, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Address from "../../../pages/properties/PropertyDetailPage/model/Address";
import RootState from "../../../redux/RootState";
import { LoqateCountryCode } from "../../../rest/loqate/LoqateRepository";
import { LoqateFindItem } from "../../../rest/loqate/model/LoqateFindResponse";
import CountryCode from "../../../utils/CountryCode";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../theme/dimensions";
import ErrorSnackbarHandler from "../ErrorSnackbar/ErrorSnackbarHandler";
import AddressLookupSearch from "./components/AddressLookupSearch";
import AddressLookupSelect from "./components/AddressLookupSelect";
import { clearSearch, itemSelected, searchAddress } from "./redux/AddressLookupAction";
import AddressLookupState from "./redux/AddressLookupState";

type ReduxStateProps = AddressLookupState;

interface ReduxDispatchProps {
  searchForAddress: (text: string, country: LoqateCountryCode) => void;
  clearSearch: () => void;
  selectItem: (item: LoqateFindItem) => void;
}

interface ExternalProps {
  open: boolean;
  country: string;
  isAgent?: boolean;
  onCountryChanged: (value: string) => void;
  onAddressLoaded: (address: Address) => void;
  onClose: () => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps & WithT;

class AddressLookupDialog extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.loadedAddress !== undefined && prevProps.loadedAddress === undefined) {
      this.props.onAddressLoaded(this.props.loadedAddress);
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Box display="flex" flexDirection="column" width={400} padding={DEFAULT_SPACING}>
          <Typography
            align="center"
            style={{ textTransform: "uppercase" }}
            variant="h2"
            gutterBottom
          >
            <strong>
              <Trans>addressLookup.title</Trans>
            </strong>
          </Typography>

          <Box height={EXTRA_LARGE_SPACING} />

          {this.renderBody()}

          <Box height={LARGE_SPACING} />

          <ErrorSnackbarHandler message={this.props.error} />
        </Box>
      </Dialog>
    );
  }

  renderBody = () => {
    if (this.props.addresses) {
      return (
        <AddressLookupSelect
          items={this.props.addresses}
          loadingItem={this.props.loadingItem}
          onSearchAgainClicked={this.props.clearSearch}
          onItemSelected={this.props.selectItem}
        />
      );
    } else {
      return (
        <AddressLookupSearch
          isAgent={this.props.isAgent}
          country={this.props.country as any}
          onCountryChanged={this.props.onCountryChanged}
          isLoading={this.props.isLoading}
          onSearchClicked={this.handleSearchClicked}
        />
      );
    }
  };

  // Handlers

  handleSearchClicked = (text: string, country: CountryCode) => {
    const loqateCode: LoqateCountryCode = country;
    this.props.searchForAddress(text, loqateCode);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => state.addressLookup;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  searchForAddress: (text, country) => dispatch(searchAddress(text, country)),
  clearSearch: () => dispatch(clearSearch()),
  selectItem: (item) => dispatch(itemSelected(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddressLookupDialog));
