import InviteOrganisationAdminAction from "./InviteOrganisationAdminAction";
import InviteOrganisationAdminState from "./InviteOrganisationAdminState";

const initialState: InviteOrganisationAdminState = {
  isLoading: false,
  inviteSent: false,
  error: undefined,
};

const inviteOrganisationAdminReducer = (
  state: InviteOrganisationAdminState = initialState,
  action: InviteOrganisationAdminAction
): InviteOrganisationAdminState => {
  switch (action.type) {
    case "ADMIN_INVITE_SUBMITTED_TYPE":
      return {
        isLoading: true,
        inviteSent: false,
        error: undefined,
      };

    case "ADMIN_INVITE_SUBMITTED_ERROR_TYPE":
      return {
        isLoading: false,
        inviteSent: false,
        error: action.error,
      };

    case "ADMIN_INVITE_SUBMITTED_SUCCESS_TYPE":
      return {
        isLoading: false,
        inviteSent: true,
        error: undefined,
      };

    default:
      return state;
  }
};

export default inviteOrganisationAdminReducer;
