import { delay, put, select } from "@redux-saga/core/effects";
import { takeLatest } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import UserDetails from "../../../../rest/user/model/UserDetails";
import Page from "../../../../rest/utils/Page";
import {
  LoadPlatformUserSuccessAction,
  LOAD_PLATFORM_USER_SUCCESS_TYPE,
} from "../../PlatformUserDetailPage/redux/PlatformUserDetailAction";
import {
  AdminAccessChangedAction,
  ADMIN_ACCESS_CHANGED_TYPE,
} from "../../redux/AdeyAdminAction";
import {
  loadPlatformUsers,
  LOAD_PLATFORM_USERS_TYPE,
  platformUsersError,
  platformUsersLoaded,
  SEARCH_TEXT_CHANGED_TYPE,
} from "./PlatformUserListAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* platformUserListSagas() {
  yield takeLatest(ADMIN_ACCESS_CHANGED_TYPE, adminAccessChangedSaga);
  yield takeLatest(LOAD_PLATFORM_USERS_TYPE, loadPlatformUsersSaga);
  yield takeLatest(SEARCH_TEXT_CHANGED_TYPE, searchTextChangedSaga);

  yield takeLatest(LOAD_PLATFORM_USER_SUCCESS_TYPE, userLoadedSaga);
}

function* adminAccessChangedSaga(action: AdminAccessChangedAction) {
  if (action.hasAdminAccess) {
    yield put(loadPlatformUsers(1));
  }
}

function* searchTextChangedSaga() {
  // Wait a short delay before loading so that we don't
  // search for every keystroke
  yield delay(150);
  yield loadPlatformUsersSaga();
}

function* loadPlatformUsersSaga() {
  try {
    const rootState: RootState = yield select();
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const page = rootState.platformUserList.currentPage;
    const searchText = rootState.platformUserList.searchText;

    const userResponse: Page<UserDetails[]> = yield adminRepo.fetchAllUsers(
      searchText,
      page
    );

    const users = userResponse.data;
    const currentPage = userResponse.meta.current_page;
    const maxPage = userResponse.meta.last_page;

    yield put(platformUsersLoaded(users, currentPage, maxPage));
  } catch (e) {
    const message = getErrorMessage(e, "platformUsersList.loadError");
    yield put(platformUsersError(message));
  }
}

function* userLoadedSaga(action: LoadPlatformUserSuccessAction) {
  const rootState: RootState = yield select();

  const { users, currentPage, maxPage } = rootState.platformUserList;

  const userIndex = users?.findIndex((u) => u.id === action.userDetails.id);
  if (userIndex === undefined || userIndex < 0) {
    return;
  }

  const userCopy = [...users!];
  userCopy[userIndex] = action.userDetails;

  yield put(platformUsersLoaded(userCopy, currentPage, maxPage));
}

export default platformUserListSagas;
