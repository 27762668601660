import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { RoleName } from "../../../../../rest/roles/model/Role";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import PrimaryTextField, {
  FieldState,
} from "../../../../../ui/components/PrimaryTextField";
import RolePicker from "../../../../../ui/components/RolePicker";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import isValidEmail from "../../../../../utils/isValidEmail";

//Props
interface Props extends WithT {
  open: boolean;
  organisationName: string;
  isLoading: boolean;
  inviteSent: boolean | undefined;
  hasOwner: boolean;

  onClose: () => void;
  sendInvite: (email: string, role: RoleName) => void;
}

//State
interface State {
  email: FieldState;
  confirmEmail: FieldState;
  showSuccess: boolean;
  role: RoleName | undefined;
  roleError: string | undefined;
}

class InviteAgentUserDialog extends Component<Props, State> {
  state = {
    email: { value: "" },
    confirmEmail: { value: "" },
    showSuccess: false,
    role: undefined,
    roleError: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.inviteSent && !prevProps.inviteSent) {
      this.props.onClose();

      this.setState({
        email: { value: "" },
        confirmEmail: { value: "" },
      });
    }
  }

  render() {
    const titlePlaceholder = this.props.t("adminInviteUserDialog.titlePlaceholder");
    const emailHint = this.props.t("adminInviteUserDialog.emailHint");
    const confirmEmailHint = this.props.t("adminInviteUserDialog.confirmEmailHint");
    const inviteButton = this.props.t("adminInviteUserDialog.inviteButton");
    const roleHint = this.props.t("adminInviteUserDialog.roleRequired");

    const title = titlePlaceholder
      .replace("%s", this.props.organisationName)
      .toLocaleUpperCase();

    const availableRoles: RoleName[] = ["owner"];

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Box padding={DEFAULT_SPACING}>
          <Grid container spacing={SMALL_SPACING} alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" align="center">
                {title}
              </Typography>
            </Grid>
            <Grid item />
            <Grid item xs={12}>
              <PrimaryTextField
                {...this.state.email}
                hint={emailHint}
                onChange={(v) => this.handleFieldChanged(v, "email")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryTextField
                hint={confirmEmailHint}
                {...this.state.confirmEmail}
                onChange={(v) => this.handleFieldChanged(v, "confirmEmail")}
                type="email"
                disabled={this.props.isLoading}
              />
            </Grid>

            {this.props.hasOwner ? undefined : (
              <Grid item xs={12}>
                <RolePicker
                  role={this.state.role}
                  availableRoles={availableRoles}
                  hint={roleHint}
                  onRoleChanged={this.onRoleSelectorChange}
                  errorText={this.state.roleError}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <PrimaryButton
                label={inviteButton}
                endIcon={<FontAwesomeIcon icon={faArrowRight} size="2x" />}
                onClick={this.handleInviteSent}
                fullWidth
                isLoading={this.props.isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
  //MARK: Handlers
  handleFieldChanged = (value: string, field: keyof State) => {
    const state: any = {};
    state[field] = { value: value };
    this.setState(state);
  };

  onRoleSelectorChange = (role: RoleName) => {
    this.setState({
      ...this.state,
      role: role,
      roleError: undefined,
    });
  };

  handleInviteSent = () => {
    if (this.validate().valid) {
      const email = this.state.email.value.trim();
      const role = this.state.role;
      if (this.props.hasOwner) {
        this.props.sendInvite(email, "administrator");
      } else if (role !== undefined) {
        this.props.sendInvite(email, role);
      }
    }
  };

  //MARK: Validation
  validate = () => {
    const email = this.state.email.value.trim();
    const confirmEmail = this.state.confirmEmail.value.trim();
    let valid = true;

    if (email.length === 0 && !isValidEmail(email)) {
      valid = false;
      this.setState({
        email: {
          value: email,
          errorText: this.props.t("adminInviteUserDialog.emailRequiredError"),
        },
      });
    }

    if (confirmEmail.length === 0 && !isValidEmail(email)) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: email,
          errorText: this.props.t("adminInviteUserDialog.confirmEmailRequired"),
        },
      });
    } else if (confirmEmail !== email) {
      valid = false;
      this.setState({
        confirmEmail: {
          value: confirmEmail,
          errorText: this.props.t("adminInviteUserDialog.confirmEmailMatchError"),
        },
      });
    }

    return { valid: valid, email: email, confirmEmail: confirmEmail };
  };
}

export default withTranslation()(InviteAgentUserDialog);
