import ForgotPasswordAction from "./ForgotPasswordAction";
import ForgotPasswordState from "./ForgotPasswordState";

const initialState: ForgotPasswordState = {
  isLoading: false,
  requestedSuccessfully: false,
  submittedSuccessfully: false,
  error: undefined,
  tokenExpired: undefined,
};

const forgotPasswordReducer = (
  state: ForgotPasswordState = initialState,
  action: ForgotPasswordAction
): ForgotPasswordState => {
  switch (action.type) {
    case "FORGOT_PASSWORD_REQUEST_TYPE":
      return {
        isLoading: true,
        requestedSuccessfully: false,
        submittedSuccessfully: false,
        error: undefined,
      };

    case "FORGOT_PASSWORD_TOKEN_EXPIRED_TYPE":
      return {
        isLoading: false,
        requestedSuccessfully: false,
        submittedSuccessfully: false,
        error: undefined,
        tokenExpired: true,
      };

    case "FORGOT_PASSWORD_REQUEST_SUCCESS_TYPE":
      return {
        isLoading: false,
        requestedSuccessfully: true,
        submittedSuccessfully: false,
        error: undefined,
      };

    case "FORGOT_PASSWORD_ERROR_TYPE":
      return {
        isLoading: false,
        requestedSuccessfully: false,
        submittedSuccessfully: false,
        error: action.error,
      };

    case "FORGOT_PASSWORD_SUBMIT_TYPE":
      return {
        isLoading: true,
        requestedSuccessfully: false,
        submittedSuccessfully: false,
        error: undefined,
      };

    case "FORGOT_PASSWORD_SUBMIT_SUCCESS_TYPE":
      return {
        isLoading: false,
        requestedSuccessfully: false,
        submittedSuccessfully: true,
        error: undefined,
      };

    case "FORGOT_PASSWORD_ACTIVATION_REQUIRED":
      return {
        isLoading: false,
        requestedSuccessfully: false,
        submittedSuccessfully: false,
        error: undefined,
        emailActivationRequired: action.email,
      };

    case "CANCEL_FORGOT_PASSWORD_ACTIVATION_TYPE":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
