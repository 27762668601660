import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { EXTRA_LARGE_SPACING } from "../../../../ui/theme/dimensions";

const UserDetailsLoadingPanel = () => {
  return (
    <Box display="flex" padding={EXTRA_LARGE_SPACING} justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default UserDetailsLoadingPanel;
