import { put, takeLatest } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import UserRepository from "../../../../rest/user/UserRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import AuthService from "../../../auth/Login/service/AuthService";
import CurrentUserDetails from "../model/CurrentUserDetails";
import {
  CurrentUserChangePasswordAction,
  currentUserChangePasswordFailure,
  CurrentUserDetailsUpdatedAction,
  currentUserLoaded,
  currentUserLoadError,
  currentUserPasswordChanged,
  currentUserUpdateError,
  CurrentUserChangePasswordFailureAction,
  CURRENT_USER_CHANGE_PASSWORD_TYPE,
  CURRENT_USER_UPDATED_TYPE,
  LoadCurrentUserAction,
  LOAD_CURRENT_USER_TYPE,
} from "./CurrentUserAction";

function* currentUserSagas() {
  yield takeLatest(LOAD_CURRENT_USER_TYPE, getCurrentUser);
  yield takeLatest(CURRENT_USER_UPDATED_TYPE, updateCurrentUserAction);
  yield takeLatest(CURRENT_USER_CHANGE_PASSWORD_TYPE, changeCurrentUserPassword);
}

export function* updateCurrentUserAction(action: CurrentUserDetailsUpdatedAction) {
  try {
    const userRepo: UserRepository = yield getDependency("userRepository");

    const updatedUser: CurrentUserDetails = yield userRepo.updateUserDetails(
      action.user,
      action.user.onboarding
    );
    yield put(currentUserLoaded(updatedUser));
  } catch (e) {
    const message = getErrorMessage(e, undefined);
    yield put(currentUserUpdateError(message));
  }
}

export function* changeCurrentUserPassword(action: CurrentUserChangePasswordAction) {
  try {
    const authService: AuthService = yield getDependency("authService");

    yield authService.changePassword(
      action.password.old_password,
      action.password.new_password
    );

    yield put(currentUserPasswordChanged(action.message));
  } catch (e) {
    yield put(currentUserChangePasswordFailure((e as any).message));
  }
}

export function* getCurrentUser(action: LoadCurrentUserAction) {
  try {
    const userRepo: UserRepository = yield getDependency("userRepository");
    const me: CurrentUserDetails = yield userRepo.getMe();

    yield put(currentUserLoaded(me));
  } catch (e) {
    const message = getErrorMessage(e, undefined);
    yield put(currentUserLoadError(message));
  }
}

export default currentUserSagas;
