import ErrorSnackbar from "./ErrorSnackbar";
import React from "react";

interface Props {
  message: string | undefined;
}

interface State {
  errorMessage: string | undefined;
}

/**
 * Copies the error from the props into this components state.
 * This will allow the error to be dismissed without the redux state
 * having to be updated.
 */
class ErrorSnackbarHandler extends React.Component<Props, State> {
  state = { errorMessage: undefined };

  componentDidUpdate(prevProps: Props) {
    if (this.props.message !== prevProps.message) {
      this.setState({ errorMessage: this.props.message });
    }
  }

  render() {
    return (
      <ErrorSnackbar
        message={this.state.errorMessage}
        onClose={this.handleSnackbarClosed}
      />
    );
  }

  handleSnackbarClosed = () => {
    this.setState({ errorMessage: undefined });
  };
}

export default ErrorSnackbarHandler;
