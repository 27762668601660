import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import { LARGE_SPACING } from "../../../../../ui/theme/dimensions";

interface IProps {
  disabled?: boolean;
  onClick: () => void;
}

const MoveProCheckTestButton = (props: IProps) => {
  return (
    <OutlineButton
      style={{ marginLeft: LARGE_SPACING * 2 }}
      labelId="buttons.move"
      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

export default MoveProCheckTestButton;
