import { TFunction } from "i18next";
import { OrganisationOwnerAdminFormState } from "./OrganisationOwnerAdminForm";

const validateOrganisationOwnerAdminForm = (
  t: TFunction,
  state: OrganisationOwnerAdminFormState
) => {
  let isValid = true;
  let newState = { ...state };

  if (state.emailAddress.value.trim().length === 0) {
    isValid = false;
    newState.emailAddress = {
      ...newState.emailAddress,
      errorText: t("organisationOwnerAdminForm.emailRequiredError"),
    };
  } else if (!state.emailAddress.value.includes("@")) {
    isValid = false;
    newState.emailAddress = {
      ...newState.emailAddress,
      errorText: t("organisationOwnerAdminForm.emailInvalidError"),
    };
  }

  if (state.confirmEmailAddress.value.trim().length === 0) {
    isValid = false;
    newState.confirmEmailAddress = {
      ...newState.confirmEmailAddress,
      errorText: t("organisationOwnerAdminForm.confirmEmailRequiredError"),
    };
  } else if (state.confirmEmailAddress.value !== state.emailAddress.value) {
    isValid = false;
    newState.confirmEmailAddress = {
      ...newState.confirmEmailAddress,
      errorText: t("organisationOwnerAdminForm.confirmEmailError"),
    };
  }

  return {
    isValid: isValid,
    newState: newState,
  };
};

export default validateOrganisationOwnerAdminForm;
