import React from "react";
import PrimaryTextField, { PrimaryTextFieldProps } from "./PrimaryTextField";

// Omit some properties from Props that we define.
type PhoneNumberTextFieldProps = Omit<PrimaryTextFieldProps, "type">;

const allowedCharacters = "+()1234567890 ";

const PhoneNumberTextField = (props: PhoneNumberTextFieldProps) => {
  const changeHandler = (value: string) => {
    // Filter out any chars that aren't allowed.
    const filteredValue = value
      .split("")
      .filter((char) => allowedCharacters.includes(char))
      .join("");

    props.onChange?.(filteredValue);
  };

  return <PrimaryTextField {...props} type="tel" onChange={changeHandler} />;
};

export default PhoneNumberTextField;
