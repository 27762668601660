import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Property from "../../../../../../rest/properties/model/Property";
import AlertDialog from "../../../../../../ui/components/AlertDialog";

interface Props extends WithT {
  open: boolean;
  property: Property;
  onDeleteConfirmed: () => void;
  onClose: () => void;
}

const ConfirmDeletePropertyDialog = (props: Props) => {
  const titlePlaceholder = props.t("deletePropertyHandler.confirmDialogTitlePlaceholder");
  const title = titlePlaceholder.replace("%s", props.property.address_line_1 || "");

  return (
    <AlertDialog
      open={props.open}
      onClose={props.onClose}
      title={title}
      body="deletePropertyHandler.confirmDialogBody"
      actions={[
        {
          title: "deletePropertyHandler.confirmDialogCancelButton",
          onClick: props.onClose,
        },
        {
          title: "deletePropertyHandler.confirmDialogDeleteButton",
          onClick: props.onDeleteConfirmed,
          destructive: true,
        },
      ]}
    />
  );
};

export default withTranslation()(ConfirmDeletePropertyDialog);
