import Organisation from "../../../../rest/organisation/model/Organisation";
import Property from "../../../../rest/properties/model/Property";

export const RESET_PROPERTY_TYPE = "RESET_PROPERTY";
export interface ResetPropertyAction {
  type: typeof RESET_PROPERTY_TYPE;
}
export const resetProperty = (): ResetPropertyAction => {
  return {
    type: RESET_PROPERTY_TYPE,
  };
};

// Load property
export const LOAD_PROPERTY_TYPE = "LOAD_PROPERTY";
export interface LoadPropertyAction {
  type: typeof LOAD_PROPERTY_TYPE;
  organisationId: string;
  propertyId: string;
}
export const loadProperty = (
  organisationId: string,
  propertyId: string
): LoadPropertyAction => {
  return {
    type: LOAD_PROPERTY_TYPE,
    organisationId: organisationId,
    propertyId: propertyId,
  };
};

// Property loaded
export const PROPERTY_LOADED_TYPE = "PROPERTY_LOADED";
export interface PropertyLoadedAction {
  type: typeof PROPERTY_LOADED_TYPE;
  property: Property;
  ownerOrganisation: Organisation;
  availableContractors: Organisation[];
}
export const propertyLoaded = (
  property: Property,
  ownerOrganisation: Organisation,
  availableContractors: Organisation[]
): PropertyLoadedAction => {
  return {
    type: PROPERTY_LOADED_TYPE,
    property: property,
    ownerOrganisation: ownerOrganisation,
    availableContractors: availableContractors,
  };
};

// Property load error
export const PROPERTY_LOAD_ERROR_TYPE = "PROPERTY_LOAD_ERROR";
export interface PropertyLoadErrorAction {
  type: typeof PROPERTY_LOAD_ERROR_TYPE;
  error: string;
}
export const propertyLoadError = (error: string): PropertyLoadErrorAction => {
  return {
    type: PROPERTY_LOAD_ERROR_TYPE,
    error: error,
  };
};

// Update property
export const PROPERTY_UPDATED_TYPE = "PROPERTY_UPDATED_TYPE";
export interface PropertyUpdatedAction {
  type: typeof PROPERTY_UPDATED_TYPE;
  property: Property;
  organisationId: string;
}
export const propertyUpdated = (
  organisationId: string,
  property: Property
): PropertyUpdatedAction => ({
  type: PROPERTY_UPDATED_TYPE,
  organisationId: organisationId,
  property: property,
});

// Property updated success
export const PROPERTY_UPDATED_SUCCESS_TYPE = "PROPERTY_UPDATED_SUCCESS_TYPE";
export interface PropertyUpdatedSuccessAction {
  type: typeof PROPERTY_UPDATED_SUCCESS_TYPE;
  property: Property;
}
export const propertyUpdatedSuccess = (
  property: Property
): PropertyUpdatedSuccessAction => ({
  type: PROPERTY_UPDATED_SUCCESS_TYPE,
  property: property,
});

// Update property error
export const PROPERTY_UPDATED_ERROR_TYPE = "PROPERTY_UPDATED_ERROR_TYPE";
export interface PropertyUpdatedErrorAction {
  type: typeof PROPERTY_UPDATED_ERROR_TYPE;
  error: string;
}
export const propertyUpdatedError = (error: string): PropertyUpdatedErrorAction => ({
  type: PROPERTY_UPDATED_ERROR_TYPE,
  error: error,
});

// Update contractor
export const UPDATE_CONTRACTOR_TYPE = "UPDATE_CONTRACTOR_TYPE";
export interface UpdateContractorAction {
  type: typeof UPDATE_CONTRACTOR_TYPE;
  organisation: Organisation;
  property: Property;
  newContractorId: string | undefined;
}
export const updateContractor = (
  organisation: Organisation,
  property: Property,
  contractorId: string | undefined
): UpdateContractorAction => ({
  type: UPDATE_CONTRACTOR_TYPE,
  newContractorId: contractorId,
  organisation: organisation,
  property: property,
});

// Delete property
export const DELETE_PROPERTY_TYPE = "DELETE_PROPERTY";
export interface DeletePropertyAction {
  type: typeof DELETE_PROPERTY_TYPE;
  property: Property;
  organisationId: string;
}
export const deleteProperty = (
  property: Property,
  organisationId: string
): DeletePropertyAction => ({
  type: DELETE_PROPERTY_TYPE,
  property: property,
  organisationId: organisationId,
});

// Delete property success
export const DELETE_PROPERTY_SUCCESS_TYPE = "DELETE_PROPERTY_SUCCESS_TYPE";
export interface DeletePropertySuccessAction {
  type: typeof DELETE_PROPERTY_SUCCESS_TYPE;
  property: Property;
}
export const deletePropertySuccess = (
  property: Property
): DeletePropertySuccessAction => ({
  type: DELETE_PROPERTY_SUCCESS_TYPE,
  property: property,
});

// Delete property
export const DELETE_PROPERTY_ERROR_TYPE = "DELETE_PROPERTY_ERROR_TYPE";
export interface DeletePropertyErrorAction {
  type: typeof DELETE_PROPERTY_ERROR_TYPE;
  error: string;
}
export const deletePropertyError = (error: string): DeletePropertyErrorAction => ({
  type: DELETE_PROPERTY_ERROR_TYPE,
  error: error,
});

type PropertyDetailAction =
  | LoadPropertyAction
  | PropertyLoadedAction
  | PropertyLoadErrorAction
  | PropertyUpdatedAction
  | PropertyUpdatedSuccessAction
  | PropertyUpdatedErrorAction
  | UpdateContractorAction
  | DeletePropertyAction
  | DeletePropertyErrorAction
  | DeletePropertySuccessAction
  | ResetPropertyAction;

export default PropertyDetailAction;
