import { put, select, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import OrganisationUserRepository from "../../../../rest/organisation-user/OrganisationUserRepository";
import Role from "../../../../rest/roles/model/Role";
import RoleRepository from "../../../../rest/roles/RoleRepository";
import {
  InviteEntityAction,
  inviteEntityError,
  INVITE_ENTITY_TYPE,
  entityInvited,
} from "./CurrentInviteAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import inviteErrorHandler from "../../../../rest/invites/errors/inviteErrorHandler";

function* currentInviteSagas() {
  yield takeLatest(INVITE_ENTITY_TYPE, inviteEntity);
}

function* inviteEntity(action: InviteEntityAction) {
  try {
    const orgUserRepo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );
    const orgContractorRepo: OrganisationContractorRepository = yield getDependency(
      "organisationContractorRepository"
    );

    const roleRepo: RoleRepository = yield getDependency("roleRepository");
    const rootState: RootState = yield select();
    const inviteState = rootState.inviteEntity.currentInvitation;
    if (!inviteState) {
      //Replace with a translated string
      throw new Error("User details are not set");
    }
    const orgId = rootState.activeOrganisation.currentOrganisation?.id;
    if (!orgId) {
      throw new Error();
    }

    const roles: Role[] = yield roleRepo.getAllRoles();
    const role = roles.filter((role) => role.name === action.invite.role)[0];

    let inviteResponse: PendingInvite;

    //Switching REST repo based on the invitee user type
    switch (action.invite.type) {
      case "agent":
        inviteResponse = yield orgUserRepo.inviteOrganisationUser(
          orgId,
          action.invite.userEmail,
          role.id
        );
        break;
      case "user":
        inviteResponse = yield orgUserRepo.inviteOrganisationUser(
          orgId,
          action.invite.userEmail,
          role.id
        );
        break;
      case "contractor":
        inviteResponse = yield orgContractorRepo.inviteOrganisationContractor(
          false,
          orgId,
          action.invite.userEmail
        );
        break;
    }

    yield put(entityInvited(inviteResponse));
  } catch (e) {
    const message = getErrorMessage(e, "inviteEntityPanel.error", inviteErrorHandler);
    yield put(inviteEntityError(message));
  }
}

export default currentInviteSagas;
