import { AxiosResponse } from "axios";

// Rest responses come wrapped in "data"
interface DataWrapper<T> {
  data: T;
}

export const unwrapResponse = <T>(response: AxiosResponse<DataWrapper<T>>): T => {
  return response.data.data;
};

export const unwrapData = <T>(wrapper: DataWrapper<T>): T => {
  return wrapper.data;
};

export default DataWrapper;
