import { Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";

const NoOrgsDisplay = () => {
  return (
    <Typography variant="h5" align="center">
      <Trans>platformUserDetail.noOrganisations</Trans>
    </Typography>
  );
};

export default NoOrgsDisplay;
