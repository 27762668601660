import React from "react";
import TMoveDestination from "../../../pages/admin/AdminMoveEntity/types/TMoveDestination";
import { Box, IconButton, Typography } from "@material-ui/core";
import ColoredText from "../ColoredText";
import {
  BACKGROUND_ACCENT_COLOR,
  SECONDARY_COLOR,
} from "../../theme/createMaterialTheme";
import { useTranslation } from "react-i18next";
import TEntityToMove from "../../../pages/admin/AdminMoveEntity/types/TEntityToMove";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../theme/dimensions";

interface IProps<T> {
  destination: TMoveDestination;
  entityType: TEntityToMove;
  selectedItems: T[];
  getSelectedItemName: (item: T) => string;
  removeSelectedItemCallback?: (item: T) => void;
  handleBackPressed: () => void;
  handleNextPressed: () => void;
}

const MoveEntitiesReviewPanel = <T extends unknown>(props: IProps<T>) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const nextButton = t("buttons.next");
  const backButton = t("buttons.back");

  //Helpers
  const getEntityHeader = () => {
    switch (props.entityType) {
      case "device":
        return t("moveEntityReviewPanel.entityTitle.device", {
          count: props.selectedItems.length,
        });
      case "property":
        return t("moveEntityReviewPanel.entityTitle.property", {
          count: props.selectedItems.length,
        });
      case "procheck":
        return t("moveEntityReviewPanel.entityTitle.proCheck", {
          count: props.selectedItems.length,
        });
    }
  };

  const getDestinationHeader = () => {
    switch (props.entityType) {
      case "device":
        return t("moveEntityReviewPanel.destinationTitle.property");
      case "property":
        return t("moveEntityReviewPanel.destinationTitle.organisation");
      case "procheck":
        return t("moveEntityReviewPanel.destinationTitle.property");
    }
  };

  const renderSelectedItems = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} width={"100%"}>
        {props.selectedItems.map((item) => {
          return (
            <Box display="flex" flexDirection={"row"}>
              <Typography>{props.getSelectedItemName(item)}</Typography>
              {props.removeSelectedItemCallback && (
                <Box marginLeft={"auto"}>
                  <IconButton
                    size="small"
                    onClick={() => props.removeSelectedItemCallback}
                  >
                    <FontAwesomeIcon color={SECONDARY_COLOR} icon={faTimes} />
                  </IconButton>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <ColoredText
        textColor={BACKGROUND_ACCENT_COLOR}
        variant="button"
        style={{ marginTop: SMALL_SPACING, marginBottom: SMALL_SPACING }}
      >
        <strong>{getEntityHeader().toUpperCase()}</strong>
      </ColoredText>
      {renderSelectedItems()}
      <ColoredText
        textColor={BACKGROUND_ACCENT_COLOR}
        variant="button"
        style={{ marginTop: EXTRA_LARGE_SPACING, marginBottom: SMALL_SPACING }}
      >
        <strong>{getDestinationHeader().toUpperCase()}</strong>
      </ColoredText>
      <Typography>{props.destination.description}</Typography>
      <Box marginTop={DEFAULT_SPACING} />
      <PrimaryButton label={nextButton} onClick={props.handleNextPressed} />
      <Box marginTop={SMALL_SPACING} />
      <SecondaryButton label={backButton} onClick={props.handleBackPressed} />
    </Box>
  );
};

export default MoveEntitiesReviewPanel;
