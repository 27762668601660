import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface Props extends WithT {
  open: boolean;
  organisationName: string;
  org: Organisation;

  onCancelClicked: () => void;
  onDeleteClicked: () => void;
}

const ConfirmRemoveContractorDialog = (props: Props) => {
  const title = props.t("confirmRemoveContractorDialog.title");
  const bodyPlaceholder = props.t("confirmRemoveContractorDialog.bodyPlaceholder");
  const removeButton = props.t("confirmRemoveContractorDialog.removeButtonPlaceholder");

  const body = bodyPlaceholder
    .replace("<contractor_name>", props.org.name)
    .replace("<org_name>", props.organisationName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={removeButton}
      onConfirmed={props.onDeleteClicked}
      onCancelClicked={props.onCancelClicked}
    />
  );
};

export default withTranslation()(ConfirmRemoveContractorDialog);
