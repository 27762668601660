import { call, delay, put, race, take, takeEvery } from "redux-saga/effects";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import getDependency from "../../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import ExportRepository from "../../../../rest/export/ExportRepository";
import ExportItemModel from "../models/ExportItemModel";
import {
  addExportingItem,
  AddExportingItemAction,
  CancelExportingItemAction,
  CANCEL_EXPORTING_ITEM_TYPE,
  exportInitError,
  InitGlobalExportAction,
  InitItemExportAction,
  initItemProcessing,
  InitItemProcessingAction,
  INIT_GLOBAL_EXPORT_TYPE,
  INIT_ITEM_EXPORT_TYPE,
  INIT_ITEM_PPROCESSING_TYPE,
  removeExportingFailureItem,
  removeExportingSuccessItem,
  updateExportingItem,
  UpdateExportingItemAction,
  UPDATE_EXPORTING_ITEM_TYPE,
  InitReportExportAction,
  INIT_REPORT_EXPORT_TYPE,
} from "./ExportBarAction";

function* exportBarSagas() {
  yield takeEvery(INIT_ITEM_EXPORT_TYPE, initExportSaga);
  yield takeEvery(INIT_REPORT_EXPORT_TYPE, initReportExportSaga);
  yield takeEvery(UPDATE_EXPORTING_ITEM_TYPE, processUpdatedItemSaga);
  yield takeEvery(INIT_GLOBAL_EXPORT_TYPE, initGlobalExportSaga);
  yield takeEvery(INIT_ITEM_PPROCESSING_TYPE, processingHandlerSaga);
}

function* processingHandlerSaga(action: InitItemProcessingAction): any {
  yield race({
    task: call(processExportItemSaga, addExportingItem(action.item)),
    cancel: call(() => {
      return cancelExportSaga(action.item);
    }),
  });
}

//This could be improved for in the future for optimisation - highly unlickely that it will be required
function* cancelExportSaga(item: ExportItemModel): any {
  let notFound = true;
  while (notFound) {
    const action: CancelExportingItemAction = yield take(CANCEL_EXPORTING_ITEM_TYPE);
    if (item.id === action.item.id) {
      yield put(updateExportingItem({ ...action.item, cancelled: true }));
      notFound = false;
    }
  }
}

function* initReportExportSaga(action: InitReportExportAction): any {
  const isAdmin: boolean = yield isAdeyAdmin();
  const exportRepository: ExportRepository = yield getDependency("exportRepository");

  let exportItem: ExportItemModel = {
    id: action.org_id,
    organisation_id: action.org_id,
    url: null,
    processed: null,
    failed: null,
    failed_reason: null,
  };
  try {
    yield put(addExportingItem(exportItem));
    yield delay(1000);

    const initialisedExportItem: ExportItemModel =
      yield exportRepository.initialiseProCheckExport(
        action.org_id,
        isAdmin,
        action.result_ids,
        action.privacy,
        action.languageHeader
      );
    yield put(
      initItemProcessing({ ...exportItem, ...initialisedExportItem }, action.org_id)
    );
  } catch (e) {
    //TODO: Replace error message
    const message = getErrorMessage(e, "placeholder");
    yield put(exportInitError(message));
    yield put(updateExportingItem({ ...exportItem, processed: 1, failed: 1 }));
  }
}

function* initExportSaga(action: InitItemExportAction): any {
  const isAdmin: boolean = yield isAdeyAdmin();
  const exportRepository: ExportRepository = yield getDependency("exportRepository");

  const startDate = action.startDate.toISOString().split("T")[0];
  const endDate = action.endDate.toISOString().split("T")[0];

  let exportItem: ExportItemModel = {
    id: action.org_id,
    organisation_id: action.org_id,
    startDate: startDate,
    endDate: endDate,
    url: null,
    processed: null,
    failed: null,
    failed_reason: null,
  };
  try {
    yield put(addExportingItem(exportItem));
    yield delay(1000);

    const initialisedExportItem: ExportItemModel =
      yield exportRepository.initialiseOverviewExport(
        action.org_id,
        isAdmin,
        startDate,
        endDate
      );
    yield put(
      initItemProcessing({ ...exportItem, ...initialisedExportItem }, action.org_id)
    );
  } catch (e) {
    //TODO: Replace error message
    const message = getErrorMessage(e, "placeholder");
    yield put(exportInitError(message));
    yield put(updateExportingItem({ ...exportItem, processed: 1, failed: 1 }));
  }
}

function* initGlobalExportSaga(action: InitGlobalExportAction) {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const exportRepository: ExportRepository = yield getDependency("exportRepository");

    if (isAdmin) {
      const exportItem: ExportItemModel = yield exportRepository.initialiseGlobalExport();
      yield put(addExportingItem(exportItem));
    } else {
      return;
    }
  } catch (e) {
    //TODO: Replace error message
    const message = getErrorMessage(e, "placeholder");

    yield put(exportInitError(message));
  }
}

function* processExportItemSaga(action: AddExportingItemAction): any {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const exportRepository: ExportRepository = yield getDependency("exportRepository");
    let limit = 10;
    let counter = 0;
    let checking = true;
    while (checking && counter < limit) {
      yield delay(1000);
      const exportItem: ExportItemModel = yield exportRepository.fetchExportStatus(
        action.item.id,
        isAdmin,
        action.item.organisation_id
      );
      if (exportItem.failed === 1 || exportItem.processed === 1) {
        checking = false;
        yield put(updateExportingItem(exportItem));
      }
      counter++;
    }
    if (counter === limit) {
      yield put(updateExportingItem({ ...action.item, failed: 1 }));
    }
  } catch (e) {
    const message = getErrorMessage(e, "placeholder");
    yield put(updateExportingItem({ ...action.item, processed: 1, failed: 1 }));
    yield put(exportInitError(message));
  }
}

function* processUpdatedItemSaga(action: UpdateExportingItemAction) {
  try {
    if (action.item.cancelled) {
      yield delay(3000);
      yield put(removeExportingFailureItem(action.item));
    } else if (action.item.failed === 1) {
      yield delay(3000);
      yield put(removeExportingFailureItem(action.item));
    } else if (action.item.processed === 1 && action.item.url !== null) {
      yield delay(3000);
      yield put(removeExportingSuccessItem(action.item));
    }
  } catch (e) {
    //Currently, fail silently
    yield put(updateExportingItem({ ...action.item, failed: 1 }));
    const message = getErrorMessage(e, "placeholder");
    console.log(message);
  }
}

export default exportBarSagas;
