import OrganisationDetailAction from "./OrganisationDetailActions";
import OrganisationDetailState from "./OrganisationDetailState";

const initialState: OrganisationDetailState = {
  error: undefined,
  loading: false,
  organisation: undefined,
  organisationRelationship: undefined,
  isRemovingOrganisation: false,
  organisationRemoved: false,
  updateSuccessful: false,
};

const organisationDetailReducer = (
  state = initialState,
  action: OrganisationDetailAction
): OrganisationDetailState => {
  switch (action.type) {
    case "LOAD_ORGANISATION_ADMIN_TYPE":
    case "LOAD_ORGANISATION_TYPE":
      return {
        error: undefined,
        loading: true,
        organisation: undefined,
        isRemovingOrganisation: false,
        organisationRemoved: false,
        organisationRelationship: undefined,
        updateSuccessful: false,
      };

    case "LOAD_ORGANISATION_SUCCESS_TYPE":
      return {
        error: undefined,
        loading: false,
        organisation: action.organisation,
        isRemovingOrganisation: false,
        organisationRemoved: false,
        organisationRelationship: action.relationship,
        updateSuccessful: false,
      };

    case "EDIT_ORGANISATION":
      return {
        ...state,
        loading: true,
        updateSuccessful: true,
      };
    case "ADMIN_EDIT_ORGANISATION": {
      return {
        ...state,
        loading: true,
        updateSuccessful: false,
      };
    }
    case "EDIT_ORGANISATION_SUCCESS": {
      return {
        ...state,
        loading: false,
        organisation: action.organisation,
        updateSuccessful: true,
      };
    }

    case "EDIT_ORGANISATION_ERROR": {
      return {
        ...state,
        loading: false,
        updateSuccessful: false,
        error: action.error,
      };
    }

    case "LOAD_ORGANISATION_ERROR_TYPE":
      return {
        error: action.error,
        loading: false,
        organisation: undefined,
        isRemovingOrganisation: false,
        organisationRemoved: false,
        organisationRelationship: undefined,
        updateSuccessful: false,
      };
    case "REMOVE_ORGANISATION_TYPE":
      return {
        ...state,
        isRemovingOrganisation: true,
        organisationRemoved: false,
        error: undefined,
      };

    case "REMOVE_ORGANISATION_SUCCESS_TYPE":
      return {
        ...state,
        isRemovingOrganisation: false,
        organisationRemoved: true,
        error: undefined,
      };

    case "REMOVE_ORGANISATION_ERROR_TYPE":
      return {
        ...state,
        isRemovingOrganisation: false,
        organisationRemoved: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default organisationDetailReducer;
