import { AxiosInstance } from "axios";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import PermissionResponse from "./model/PermissionResponse";
import PermissionsRepository from "./PermissionsRepository";

class RestPermissionsRepository implements PermissionsRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  fetchPermissionsForRole = (roleId: string): Promise<PermissionResponse[]> => {
    const endpoint = `/roles/${roleId}/permissions`;

    return this.axios
      .get<DataWrapper<PermissionResponse[]>>(endpoint)
      .then(unwrapResponse);
  };
}

export default RestPermissionsRepository;
