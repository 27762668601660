import AdminCreateOrganisationAction from "./AdminCreateOrganisationAction";
import AdminCreateOrganisationState from "./AdminCreateOrganisationState";

const initialState: AdminCreateOrganisationState = {
  createdOrganisation: undefined,
  organisationDetails: undefined,
  isLoading: false,
  error: undefined,
};

const adminCreateOrganisationReducer = (
  state = initialState,
  action: AdminCreateOrganisationAction
): AdminCreateOrganisationState => {
  switch (action.type) {
    case "SUBMIT_ORG_DETAILS_TYPE":
      return {
        createdOrganisation: undefined,
        organisationDetails: action.details,
        isLoading: false,
        error: undefined,
      };

    case "SUBMIT_OWNER_INFO_TYPE":
      return {
        createdOrganisation: undefined,
        organisationDetails: state.organisationDetails,
        isLoading: true,
        error: undefined,
      };

    case "CREATE_ORG_SUCCESS_TYPE": {
      return {
        createdOrganisation: action.organisation,
        organisationDetails: undefined,
        isLoading: false,
        error: undefined,
      };
    }

    case "CREATE_ORG_ERROR_TYPE":
      return {
        createdOrganisation: undefined,
        organisationDetails: state.organisationDetails,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default adminCreateOrganisationReducer;
