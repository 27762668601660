import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React, { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import Property from "../../../../rest/properties/model/Property";
import DeviceLogo from "../../../../ui/assets/DeviceImages/DeviceLogo";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail, { RailLink } from "../../../../ui/components/NavigationRail";
import OutlineButton from "../../../../ui/components/OutlineButton";
import TabModel from "../../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import ProductVerticalCard from "../../../../ui/components/ProductVerticalCard/ProductVerticalCard";
import SuccessSnackbarHandler from "../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import ExportProcheckResultsDialog from "../../ProCheckPage/components/ExportProcheckResultsDialog";
import ProCheckDetailsPageState from "../model/ProCheckDetailsPageState";
import { ProCheckPageType } from "../ProCheckDetailsPage";
import ProCheckDetailsTab from "./tabs/ProCheckDetailsTab";
import ProCheckResultsTab from "./tabs/ProCheckResultsTab";
import MoveProCheckTestHandler from "../../ProCheckPage/components/MoveProCheckHandler/MoveProCheckTestHandler";

type ProcheckDetailTab = "report-details" | "test-results";

interface Props extends WithT {
  resultDetails: ProCheckDetailsPageState;
  property: Property | undefined;
  isAdeyAdmin: boolean;
  pageType: ProCheckPageType;

  errorMessage: string | undefined;
  isExported: boolean | undefined;
  exporting: boolean;

  onExportReportClicked: (
    emails: string[],
    results: string[],
    hidePersonalDetails: boolean,
    notes?: string
  ) => void;
  onDownloadReportClicked: (results: string[], hidePersonalDetails: boolean) => void;
  //Navigation
  //TODO - Refactor this bit to be moved outside of the component if any expansion, or additional handlers are required
  onDashboardClicked: () => void;
  onPropertyClicked: () => void;
  onProcheckClicked: () => void;
  onUserClicked: () => void;
  onUserTabClicked: () => void;
  onOrgsClicked?: () => void;
  onOrganisationClicked?: () => void;
  onReportsClicked?: () => void;
}

interface TabState {
  activeTab: ProcheckDetailTab;
}

type State = TabState;

const areResultsEqual = (prevResults: Props, nextResults: Props) => {
  return (
    prevResults.resultDetails === nextResults.resultDetails &&
    prevResults.isExported === nextResults.isExported
  );
};

const LoadedProCheckDetailsPage = (props: Props) => {
  //Page State
  const [pageState, setPageState] = useState<State>({
    activeTab: "report-details",
  });

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  //State Setters
  const setActiveTab = (tab: string) => {
    setPageState({ ...pageState, activeTab: tab as ProcheckDetailTab });
  };

  //Update if report was sent successfully

  //Labels
  const summaryCardTitle = props.t("proCheck.name");
  const summaryCardProductType = props.t("proCheck.productType");
  const statusMessage = props.resultDetails.pass
    ? props.t("proCheck.pass")
    : props.t("proCheck.recommendation");

  const title = props.t("proCheck.reportName").replace("%s", props.resultDetails.date);
  const reportTitle = `${props.t("proCheck.name")}  ${title}`;
  const exportButton = props.t("productReportTable.emailReportLabel");
  const successMessage = props.t("proCheck.exportSuccess");

  //Links
  const constructLinks = () => {
    let links: RailLink[] = [];
    if (props.pageType === "property-page") {
      links = [
        ...(props.onOrgsClicked !== undefined &&
        props.isAdeyAdmin &&
        props.onOrganisationClicked !== undefined
          ? [
              {
                key: "all-orgs",
                name: props.t("headerTabs.organisations"),
                onClick: props.onOrgsClicked,
              },
              {
                key: "organisation",
                name: props.property?.organisation?.name || "",
                onClick: props.onOrganisationClicked,
              },
            ]
          : [
              {
                key: "properties",
                name: props.t("propertyDetailPage.propertyLink"),
                onClick: props.onDashboardClicked,
              },
            ]),

        {
          key: "address",
          name: props.property?.address_line_1 || "",
          onClick: props.onPropertyClicked,
        },
        {
          key: "procheck",
          name: props.t("proCheck.name"),
          onClick: props.onProcheckClicked,
        },
      ];
    } else if (props.pageType === "user-page") {
      links = [
        {
          key: "users",
          name: props.t("headerTabs.userList"),
          onClick: props.onUserTabClicked,
        },
        {
          key: "user",
          name:
            `${props.resultDetails?.user.first_name} ${props.resultDetails?.user.last_name}` ||
            "",
          onClick: props.onUserClicked,
        },
      ];
    } else if ("org-page") {
      links = [
        ...(props.onReportsClicked
          ? [
              {
                key: "users",
                name: props.t("headerTabs.reports"),
                onClick: props.onReportsClicked,
              },
            ]
          : []),
        {
          key: "address",
          name: props.property?.address_line_1 || "",
          onClick: props.onPropertyClicked,
        },
      ];
    }
    return links;
  };

  //Tabs
  const buildTabs = (): TabModel[] => {
    return [
      {
        title: "proCheckTabs.detailsTab",
        navKey: "report-details" as ProcheckDetailTab,
      },
      {
        title: "proCheckTabs.resultsTab",
        navKey: "test-results" as ProcheckDetailTab,
      },
    ];
  };

  useEffect(() => {
    if (props.isExported) {
      setDialogOpen(false);
    }
  }, [props.isExported, setDialogOpen]);

  //Handlers
  const handleReportExportClicked = (
    emails: string[],
    hidePersonalDetails: boolean,
    notes?: string
  ) => {
    props.onExportReportClicked(
      emails,
      [props.resultDetails.id],
      hidePersonalDetails,
      notes
    );
  };

  const handleReportDownloadClicked = (hidePersonalDetails: boolean) => {
    props.onDownloadReportClicked([props.resultDetails.id], hidePersonalDetails);
    setDialogOpen(false);
  };

  const renderTabs = () => {
    switch (pageState.activeTab) {
      case "report-details":
        return (
          <ProCheckDetailsTab
            resultDetails={props.resultDetails}
            property={props.property}
          />
        );
      case "test-results":
        return (
          <ProCheckResultsTab
            isAdeyAdmin={props.isAdeyAdmin}
            resultDetails={props.resultDetails}
            propertyCountry={props.property?.address_country.code}
          />
        );
    }
  };

  return (
    <Box>
      <Box padding={DEFAULT_SPACING} display="flex" justifyContent="space-between">
        <NavigationRail currentPageTitle={title} links={constructLinks()} />
      </Box>
      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Box width="100%" display="flex" flexDirection="row">
          <Box maxWidth="30%" minWidth="240px">
            <ProductVerticalCard
              productName={summaryCardTitle}
              productType={summaryCardProductType}
              statusMessage={statusMessage}
              logo={<DeviceLogo deviceType="PROCHECK" height={70} />}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              marginLeft: EXTRA_LARGE_SPACING,
              marginRight: EXTRA_LARGE_SPACING * 4,
            }}
          />
          <Box width={"100%"}>
            <Box display="flex" flexDirection="row" alignItems="center" width={"100%"}>
              <DeviceLogo deviceType="PROCHECK" height={60} />
              <Box marginLeft={SMALL_SPACING}>
                <Typography variant="h4">
                  <strong>{reportTitle}</strong>
                </Typography>
              </Box>

              {props.isAdeyAdmin && props.property && (
                <Box style={{ marginLeft: "auto", marginRight: LARGE_SPACING }}>
                  <MoveProCheckTestHandler
                    orgId={props.property.organisation?.id}
                    selectedReports={[
                      {
                        ...props.resultDetails,
                        organisation_id: props.property.organisation_id ?? "",
                        property: props.property,
                        tested_at: `${props.resultDetails.date} ${props.resultDetails.time}`,
                      },
                    ]}
                  />
                </Box>
              )}

              <OutlineButton
                label={exportButton}
                endIcon={<FontAwesomeIcon icon={faShareSquare} />}
                onClick={() => setDialogOpen(!isDialogOpen)}
              />
            </Box>
            <PageHeaderTabs
              noPadding={true}
              tabs={buildTabs()}
              activeKey={pageState.activeTab}
              tabClicked={setActiveTab}
            />

            {renderTabs()}
          </Box>
        </Box>
      </Box>
      <ExportProcheckResultsDialog
        exported={props.isExported}
        open={isDialogOpen}
        isLoading={props.exporting}
        onDialogClose={() => {
          setDialogOpen(!isDialogOpen);
        }}
        handleReportsDownload={handleReportDownloadClicked}
        handleReportsExport={handleReportExportClicked}
      />
      <SuccessSnackbarHandler message={props.isExported ? successMessage : undefined} />
      <ErrorSnackbarHandler message={props.errorMessage} />
    </Box>
  );
};

export default withTranslation()(React.memo(LoadedProCheckDetailsPage, areResultsEqual));
