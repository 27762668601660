import { Grid } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import SenseDeviceProductsTile from "../../../devices/components/SenseDeviceProductsTile";
import ProCheckProductTile from "../../../procheck/components/ProCheckProductTile/ProCheckProductTile";

interface Props extends WithTranslation {
  onMoreInfoClicked: () => void;
  onProductDetailsClicked: (deviceId: string) => void;
}

const ProductGrid = (props: Props) => {
  const title = props.t("productGrid.title");

  return (
    <Grid container spacing={2} style={{ minHeight: "200px" }}>
      <Grid item xs={12}>
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button" gutterBottom>
          <strong>{title}</strong>
        </ColoredText>
      </Grid>

      {/* TODO: The implementation of this has to be changed to accomodate a placeholder when no products are present
       */}

      <ProCheckProductTile onMoreInfoClicked={props.onMoreInfoClicked} />

      <SenseDeviceProductsTile onMoreInfoClicked={props.onProductDetailsClicked} />
    </Grid>
  );
};

export default withTranslation()(ProductGrid);
