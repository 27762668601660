import { Box, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import LabelledText from "../../../../ui/components/LabelledText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import RAGSymbol from "../../../properties/PropertyListPage/components/RAGSymbol";
import RAGStatus from "../../../properties/PropertyListPage/model/RAGStatus";

interface Props extends WithTranslation {
  title: string;
  status?: RAGStatus;
  productType: string;
  statusMessage?: string;

  logo?: React.ReactNode;
}

const ProductSummary = (props: Props) => {
  const productTitle = props.t("productSummary.productTitle");
  const productTypeTitle = props.t("productSummary.productTypeTitle");
  const statusMessageTitle = props.t("productSummary.statusMessageTitle");

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
          <strong>{productTitle}</strong>
        </ColoredText>

        {props.status ? (
          <Box paddingX={1}>
            <RAGSymbol status={props.status} />
          </Box>
        ) : undefined}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {props.logo}

        <Box marginLeft={SMALL_SPACING}>
          <Typography variant="h4">
            <strong>{props.title}</strong>
          </Typography>
        </Box>
      </Box>
      <Box marginTop={SMALL_SPACING}>
        <LabelledText label={productTypeTitle} text={props.productType} />
      </Box>

      {props.statusMessage ? (
        <Box marginTop={SMALL_SPACING}>
          <LabelledText label={statusMessageTitle} text={props.statusMessage} />
        </Box>
      ) : undefined}
    </Box>
  );
};

export default withTranslation()(ProductSummary);
