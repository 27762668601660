import { Auth } from "aws-amplify";
import AuthService from "./AuthService";
import i18n from "../../../../i18n/i18n";
import { CognitoUser } from "@aws-amplify/auth";

// Use this type alias to allow for constructor dependency injection
type AuthWrapper = typeof Auth;

class CognitoAuthService implements AuthService {
  auth: AuthWrapper;

  constructor(auth: AuthWrapper = Auth) {
    this.auth = auth;
  }

  refreshLanguage = async (): Promise<void> => {
    const user = await this.auth.currentAuthenticatedUser();
    const newAttributes = this._getUserAttributes();
    if (newAttributes.locale !== user.attributes.locale) {
      await this.auth.updateUserAttributes(user, newAttributes);
    }
  };

  getCurrentToken = async (): Promise<string | undefined> => {
    let token: string;

    try {
      const user = await this.auth.currentAuthenticatedUser();
      token = user?.signInUserSession?.getIdToken()?.jwtToken;
    } catch {
      // The user is signed out
      return undefined;
    }

    // If it's anything over than a string, return undefined.
    if (typeof token === "string") {
      return token;
    } else {
      return undefined;
    }
  };

  getCurrentEmail = async (): Promise<string | undefined> => {
    let email: string;

    try {
      const user = await this.auth.currentAuthenticatedUser();
      email = user?.attributes?.email;
    } catch {
      // The user is signed out
      return undefined;
    }

    // If it's anything over than a string, return undefined.
    if (typeof email === "string") {
      return email;
    } else {
      return undefined;
    }
  };

  signIn = async (username: string, password: string): Promise<CognitoUser> => {
    const credentials = { username: username, password: password };

    // The call returns either a CognitoUser, or any. So
    // check the right value is there.
    const authResults: CognitoUser = await this.auth.signIn(credentials);

    const token = await this.getCurrentToken();
    if (!token) {
      throw new Error("Unable to sign in. No token saved");
    }

    return authResults;
  };

  signOut = async (): Promise<void> => {
    await this.auth.signOut();
  };

  register = async (username: string, password: string): Promise<void> => {
    const credentials = {
      username: username,
      password: password,
      attributes: this._getUserAttributes(),
    };

    await this.auth.signUp(credentials);
  };

  activateAccount = async (username: string, code: string): Promise<void> => {
    await this.auth.confirmSignUp(username, code);
  };

  resendActivationEmail = async (email: string): Promise<void> => {
    await this.auth.resendSignUp(email);
  };

  forgotPasswordRequest = async (email: string): Promise<void> => {
    await this.auth.forgotPassword(email);
  };

  changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    const user = await this.auth.currentAuthenticatedUser();

    await this.auth.changePassword(user, oldPassword, newPassword);
  };

  forgotPasswordSubmit = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await this.auth.forgotPasswordSubmit(email, code, newPassword);
  };

  isAdeyAdmin = async (): Promise<boolean> => {
    try {
      // SRC: https://github.com/aws-amplify/amplify-js/issues/4306

      const user = await this.auth.currentAuthenticatedUser();
      const idToken = user?.signInUserSession?.getIdToken();
      const cognitoGroups = idToken?.payload["cognito:groups"];

      return Array.isArray(cognitoGroups) && cognitoGroups.includes("Admin");
    } catch (e) {
      return false;
    }
  };

  private _getUserAttributes = () => {
    const language = i18n.language;
    return { locale: language };
  };
}

export default CognitoAuthService;
