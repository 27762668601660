import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import RootState from "../../../../../redux/RootState";
import ProCheckResult from "../../../../../rest/procheck/model/ProCheckResult";
import CountryCode from "../../../../../utils/CountryCode";
import getLanguageHeader from "../../../../../utils/getLanguageHeader";
import ProductReportTableItem from "../../../../procheck/ProCheckPage/model/ProductReportTableItem";
import { downloadReport } from "../../../../procheck/redux/ProCheckAction";
import { ExportDialogForm } from "../components/ProCheckReportsTab";
import { TProCheckReportTab } from "../../../../../utils/types/TProCheckReportTab";
import { initReportExport } from "../../../../../ui/components/ExportBar/redux/ExportBarAction";

const useReportsTab = (
  reportsTab: TProCheckReportTab = "org-reports",
  postActionCallback?: () => void
) => {
  //Selector
  const { currentOrg, isAdmin, selectedOrg, isLoading, exported } = useSelector(
    (state: RootState) => {
      return {
        exported: state.proCheck.reportExported,
        currentOrg: state.activeOrganisation.currentOrganisation,
        isAdmin: state.adeyAdmin.hasAdminAccess,
        selectedOrg: state.orgDetail,
        isLoading: state.proCheck.isLoading,
        searchString: state.proCheck.searchString,
      };
    }
  );

  //Hooks
  const { organisationId: pageOrganisationId } = useParams<{
    organisationId?: string;
  }>();

  //Dispatch
  const dispatch = useDispatch();

  //States
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  //Refs
  const exportedRef = useRef(false);

  //Effects
  useEffect(() => {
    if (exported !== exportedRef.current && exported !== undefined) {
      setDialogOpen(false);
      postActionCallback?.();
    }
    exportedRef.current = !!exported;
  }, [exported, postActionCallback]);

  //Handlers

  const handleSingleReportExport = (
    item: ProductReportTableItem,
    emails: string[],
    results: string[],
    privacy: boolean,
    notes?: string
  ) => {
    const orgId = item.orgId || pageOrganisationId || "";
    const propertyId = item.propertyId;
    const exportDetails = {
      emailAddresses: emails,
      resultIds: results,
      removePersonalInformation: privacy,
      notes: notes,
    };

    dispatch(
      downloadReport(
        orgId,
        exportDetails,
        propertyId,
        getLanguageHeader(currentOrg?.address_country.code as CountryCode)
      )
    );
  };

  const handleReportsExport = (
    emails: string[],
    results: string[],
    privacy: boolean,
    notes?: string
  ) => {
    const orgId = isAdmin
      ? selectedOrg.organisation?.id || ""
      : currentOrg?.id || pageOrganisationId || "";
    dispatch(
      downloadReport(
        orgId,
        {
          emailAddresses: emails,
          resultIds: results,
          removePersonalInformation: privacy,
          notes: notes,
        },
        undefined,
        getLanguageHeader(currentOrg?.address_country.code as CountryCode)
      )
    );
  };

  const handleSingleReportDownload = (
    item: ProductReportTableItem,
    results: string[],
    privacy?: boolean
  ) => {
    const orgId = item.orgId || pageOrganisationId || "";
    setDialogOpen(false);
    dispatch(
      initReportExport(
        orgId,
        results,
        privacy,
        getLanguageHeader(currentOrg?.address_country.code as CountryCode)
      )
    );
    postActionCallback?.();
  };

  const handleMultipleReportDownload = (
    selectedReports: ProCheckResult[],
    privacy?: boolean
  ) => {
    const results = selectedReports.map((report) => report.id);

    const orgId = isAdmin
      ? selectedOrg.organisation?.id || ""
      : currentOrg?.id || pageOrganisationId || "";
    dispatch(
      initReportExport(
        orgId,
        results,
        privacy,
        getLanguageHeader(currentOrg?.address_country.code as CountryCode)
      )
    );
    setDialogOpen(false);
    postActionCallback?.();
  };

  const handleExportButtonClicked = () => {
    setDialogOpen(!isDialogOpen);
  };

  //Helpers
  const exportSelectedReports = (
    dialogForm: ExportDialogForm,
    selectedReports: ProCheckResult[]
  ) => {
    const reportIds = selectedReports.map((report) => report.id);

    handleReportsExport(
      dialogForm.emails,
      reportIds,
      dialogForm.hidePersonalDetails,
      dialogForm.notes
    );
  };

  return {
    isDialogOpen,
    isLoading,
    handleExportButtonClicked,
    handleMultipleReportDownload,
    handleSingleReportDownload,
    handleSingleReportExport,
    exportSelectedReports,
  };
};

export default useReportsTab;
