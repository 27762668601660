import { Box, Typography } from "@material-ui/core";
import { Component } from "react";
import { Trans } from "react-i18next";
import PendingInvite from "../../../../../rest/invites/model/PendingInvite";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../../rest/organisation/model/OrganisationUser";
import { RoleName } from "../../../../../rest/roles/model/Role";
import UserDetails from "../../../../../rest/user/model/UserDetails";
import CollapsiblePanel from "../../../../../ui/components/CollapsiblePanel/CollapsiblePanel";
import InviteTable from "../../../../../ui/components/InviteTable/InviteTable";
import OrgUserTable from "../../../../../ui/components/OrgUserTable/OrgUserTable";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../../../ui/theme/dimensions";
import { OrgUserDetails } from "../../../AdminOrganisationDetailsPage/redux/AdminOrganisationDetailsState";

interface Props {
  users: OrgUserDetails;
  org?: Organisation;
  onUserClicked?: (user: UserDetails) => void;
  onRemoveUserClicked?: (user: UserDetails, org: Organisation) => void;
}

export default class AgentUserTables extends Component<Props> {
  render() {
    const spacer = <Box height={2 * EXTRA_LARGE_SPACING} />;

    return (
      <Box paddingX={LARGE_SPACING}>
        <CollapsiblePanel title="userList.ownerTab">
          {this.props.users !== undefined ? (
            this.renderUserTable(this.props.users.users, "owner")
          ) : (
            <Typography variant="h6">
              <Trans>platformUserDetail.noOwners</Trans>
            </Typography>
          )}
        </CollapsiblePanel>

        {spacer}

        <CollapsiblePanel title="userList.adminTab">
          {this.props.users !== undefined ? (
            this.renderUserTable(this.props.users.users, "administrator")
          ) : (
            <Typography variant="h6">
              <Trans>platformUserDetail.noAdmins</Trans>
            </Typography>
          )}
        </CollapsiblePanel>

        {spacer}

        <CollapsiblePanel title="userList.invitesTab">
          {this.props.users.invites !== undefined ? (
            this.renderInviteTable(this.props.users.invites)
          ) : (
            <Typography variant="h6">
              <Trans>platformUserDetail.noPendingInvites</Trans>
            </Typography>
          )}
        </CollapsiblePanel>
        {spacer}
      </Box>
    );
  }

  renderInviteTable = (invites: PendingInvite[]) => {
    return invites.length === 0 ? (
      <Typography variant="h6">
        <Trans>platformUserDetail.noPendingInvites</Trans>
      </Typography>
    ) : (
      <InviteTable invites={invites} />
    );
  };

  renderUserTable = (users: OrganisationUser[], role: RoleName) => {
    let userTable: OrganisationUser[];
    userTable = this.filterUserTable(users, role);
    if (userTable.length === 0) {
      switch (role) {
        case "administrator":
          return (
            <Typography variant="h6">
              <Trans>platformUserDetail.noAdmins</Trans>
            </Typography>
          );
        case "owner":
          return (
            <Typography variant="h6">
              <Trans>platformUserDetail.noOwner</Trans>
            </Typography>
          );
        default:
          return (
            <Typography variant="h6">
              <Trans>platformUserDetail.noUsers</Trans>
            </Typography>
          );
      }
    } else {
      return (
        <OrgUserTable
          users={userTable}
          org={this.props.org!}
          onUserClicked={this.props.onUserClicked}
          onRemoveUser={this.props.onRemoveUserClicked}
        />
      );
    }
  };

  filterUserTable = (users: OrganisationUser[], role: RoleName): OrganisationUser[] => {
    return users.filter((user) => user.role.name === role).map((user) => user);
  };
}
