import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";

// Easy equality check for all the ids within the property.
// Not comprehensive, it just checks the ids
// CB should have confirmed these values are unique
// https://adeyinnovation.atlassian.net/browse/SAD-172
const areSameOrganisation = (
  org1: MemberOrganisation | undefined,
  org2: MemberOrganisation | undefined
): boolean => {
  if (!org1 && !org2) {
    // If both are undefined, they're equal
    return true;
  } else if (!org1 || !org2) {
    // If only one is, they're not equal
    return false;
  }

  return (
    org1.id === org2.id &&
    org1.role?.id === org2.role?.id &&
    org1.role?.via === org2.role?.via
  );
};

export default areSameOrganisation;
