import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import ActiveOrganisationAction from "./ActiveOrganisationActions";
import ActiveOrganisationState from "./ActiveOrganisationState";

const initialState: ActiveOrganisationState = {
  currentOrganisation: undefined,
  organisationList: [],
  switchingToOrganisation: undefined,
  error: undefined,
  permissions: {
    canViewActiveOrganisationUsers: false,
    canRemoveActiveOrganisationUsers: false,
    canViewOrganisationContractors: false,
    canRemoveOrganisationContractors: false,
    canInviteContractors: false,
    canEditOrganisationDetails: false,
    canCreateProperties: false,
    canAssignContractorsToProperties: false,
    canDeleteProperties: false,
  },
};

const activeOrganisationReducer = (
  state: ActiveOrganisationState = initialState,
  action: ActiveOrganisationAction
): ActiveOrganisationState => {
  switch (action.type) {
    case "SWITCH_ORGANISATION_CHANGED":
      return {
        ...state,
        switchingToOrganisation: action.currentOrganisation,
        error: undefined,
      };

    case "ACTIVE_ORGANISATION_CHANGED_TYPE":
      return {
        currentOrganisation: action.organisation,
        organisationList: action.availableOrganisations || state.organisationList,
        switchingToOrganisation: undefined,
        error: undefined,
        permissions: action.permissions,
      };

    case "ACTIVE_ORGANISATION_ERROR_TYPE":
      return {
        ...state,
        switchingToOrganisation: undefined,
        error: action.error,
      };

    case "ACTIVE_ORGANISATION_UPDATED_TYPE":
      const memberOrg: MemberOrganisation = {
        role: state.currentOrganisation!.role,
        ...action.org,
      };

      const updatedOrgList = [...state.organisationList];
      const orgListIndex = updatedOrgList.findIndex((org) => org.id === memberOrg.id);
      if (orgListIndex > -1 && orgListIndex < updatedOrgList.length) {
        updatedOrgList[orgListIndex] = memberOrg;
      }

      return {
        currentOrganisation: memberOrg,
        permissions: state.permissions,
        organisationList: updatedOrgList,
        error: undefined,
        switchingToOrganisation: undefined,
      };

    case "CLEAR_ORGANISATIONS_TYPE":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default activeOrganisationReducer;
