import { ThemeProvider } from "@material-ui/core";
import React from "react";
import AccountSetupNavigator from "../pages/auth/AccountSetup/AccountSetupNavigator";
import AppRouter from "../routes/AppRouter";
import createMaterialTheme from "../ui/theme/createMaterialTheme";

const App = () => {
  return (
    <ThemeProvider theme={createMaterialTheme()}>
      <AccountSetupNavigator>
        <AppRouter />
      </AccountSetupNavigator>
    </ThemeProvider>
  );
};

export default App;
