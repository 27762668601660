import { Box } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { Country } from "../../../rest/countries/model/Country";
import Organisation from "../../../rest/organisation/model/Organisation";
import { DASHBOARD_ROUTE } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import NavigationRail from "../../../ui/components/NavigationRail";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../ui/theme/dimensions";
import OrganisationAgentPanel from "../../admin/AdminOrganisationDetailsPage/components/OrganisationAgentPanel";
import EditableAddress from "../../properties/PropertyDetailPage/components/EditableField/EditableAddress";
import EditableField from "../../properties/PropertyDetailPage/components/EditableField/EditableField";
import Address from "../../properties/PropertyDetailPage/model/Address";
import { editCurrentOrganisationSubmit } from "./redux/EditCurrentOrganisationAction";

interface ReduxStateProps {
  activeOrganisation: Organisation | undefined;
  canEdit: boolean;
  isLoading: boolean;
  error: string | undefined;
  updateSuccess: boolean;
}

interface ReduxDispatchProps {
  updateOrganisation: (org: Organisation) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

type FieldName = "organisationName" | "address" | "contactNumber" | "vatNumber";

interface State {
  editingField: FieldName | undefined;
}

class CurrentOrganisationPage extends React.Component<Props, State> {
  state = {
    editingField: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.updateSuccess && !prevProps.updateSuccess) {
      this.setState({ editingField: undefined });
    }
  }

  render() {
    const lookupEnabled = this.props.activeOrganisation?.address_country.code !== "FR";

    return (
      <DashboardLayout>
        <Box>
          <Box paddingX={DEFAULT_SPACING} paddingTop={DEFAULT_SPACING}>
            <NavigationRail
              currentPageTitle={this.props.activeOrganisation?.name || ""}
              links={[
                {
                  name: this.props.t("currentOrganisationPage.dashboardLink"),
                  key: "Dashboard",
                  onClick: this.dashboardClickedHandler,
                },
              ]}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="top"
              padding={LARGE_SPACING}
              justifyContent="flex-start"
              marginX={EXTRA_LARGE_SPACING}
              flexGrow={1}
            >
              {this.renderEditableField(
                "currentOrganisationPage.organisationNameLabel",
                this.props.activeOrganisation?.name,
                "organisationName"
              )}

              <EditableAddress
                lookupEnabled={lookupEnabled}
                label={this.props.t("currentOrganisationPage.addressLabel")}
                country={this.buildCountry()}
                address={this.buildAddress()}
                isEditing={this.state.editingField === "address"}
                onEditClicked={() => this.editClickedHandler("address")}
                hoverEnabled={this.canHover()}
                onCancelClicked={this.cancelClickedHandler}
                onSaveClicked={this.saveAddressClickedHandler}
                loading={this.props.isLoading}
              />

              {this.renderEditableField(
                "currentOrganisationPage.contactNumberLabel",
                this.props.activeOrganisation?.phone_number,
                "contactNumber"
              )}

              {this.renderEditableField(
                "currentOrganisationPage.vatNumberLabel",
                this.props.activeOrganisation?.vat_number,
                "vatNumber"
              )}
            </Box>
            {this.props.activeOrganisation &&
            this.props.activeOrganisation.is_agent_for?.id === undefined ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="top"
                padding={LARGE_SPACING}
                marginX={EXTRA_LARGE_SPACING}
                flexGrow={1}
              >
                <OrganisationAgentPanel organisation={this.props.activeOrganisation} />
              </Box>
            ) : null}
          </Box>

          <ErrorSnackbarHandler message={this.props.error} />
        </Box>
      </DashboardLayout>
    );
  }

  renderEditableField = (
    labelId: string,
    value: string | undefined,
    fieldName: FieldName
  ) => {
    const label = this.props.t(labelId);
    return (
      <EditableField
        label={label}
        text={value}
        required={true}
        isEditing={this.state.editingField === fieldName}
        onEditClicked={() => this.editClickedHandler(fieldName)}
        hoverEnabled={this.canHover()}
        onCancelClicked={this.cancelClickedHandler}
        onSaveClicked={(value) => this.saveClickedHandler(fieldName, value)}
        loading={this.props.isLoading}
        gutterBottom
      />
    );
  };

  buildCountry = (): Country => {
    return {
      id: this.props.activeOrganisation?.address_country.id || "",
      code: this.props.activeOrganisation?.address_country.code || "",
      name: this.props.activeOrganisation?.address_country.name || "",
    };
  };

  buildAddress = (): Address => {
    return {
      line1: this.props.activeOrganisation?.address_line_1 || "",
      line2: this.props.activeOrganisation?.address_line_2,
      line3: this.props.activeOrganisation?.address_line_3,
      city: this.props.activeOrganisation?.address_city || "",
      postcode: this.props.activeOrganisation?.address_postcode || "",
      country: this.props.activeOrganisation?.address_country.code || "",
    };
  };

  canHover = (): boolean => {
    return (
      this.state.editingField === undefined && this.props.canEdit && !this.props.isLoading
    );
  };

  // Handlers

  editClickedHandler = (field: FieldName) => {
    this.setState({ editingField: field });
  };

  cancelClickedHandler = () => {
    this.setState({ editingField: undefined });
  };

  saveClickedHandler = (field: FieldName, value: string) => {
    if (this.props.activeOrganisation === undefined) {
      return;
    }

    const updatedOrg = { ...this.props.activeOrganisation };

    switch (field) {
      case "contactNumber":
        updatedOrg.phone_number = value;
        break;

      case "organisationName":
        updatedOrg.name = value;
        break;

      case "vatNumber":
        updatedOrg.vat_number = value;
        break;
    }

    this.props.updateOrganisation(updatedOrg);
  };

  saveAddressClickedHandler = (address: Address) => {
    if (this.props.activeOrganisation === undefined) {
      return;
    }

    const updatedOrg: Organisation = {
      ...this.props.activeOrganisation,
      address_line_1: address.line1,
      address_line_2: address.line2,
      address_line_3: address.line3,
      address_city: address.city,
      address_country: { id: "", name: "", code: address.country }, //Name and code do not matter
      address_postcode: address.postcode,
    };

    this.props.updateOrganisation(updatedOrg);
  };

  dashboardClickedHandler = () => {
    this.props.history.replace(DASHBOARD_ROUTE);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  activeOrganisation: state.activeOrganisation.currentOrganisation,
  canEdit: state.activeOrganisation.permissions.canEditOrganisationDetails,
  isLoading: state.editCurrentOrg.isLoading,
  error: state.editCurrentOrg.error,
  updateSuccess: state.editCurrentOrg.updateSuccessful,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  updateOrganisation: (org) => dispatch(editCurrentOrganisationSubmit(org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CurrentOrganisationPage)));
