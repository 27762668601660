import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Property from "../../../rest/properties/model/Property";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import { PropertyPageType } from "../../../rest/properties/model/Property";
import {
  buildOrgDetailsRoute,
  buildPlatformOrgDetailsRoute,
  buildProCheckRoute,
  buildSenseDeviceDetailRoute,
  DASHBOARD_ROUTE,
  ORGANISATION_LIST_ROUTE,
  PLATFORM_ORGANISATIONS_LIST_ROUTE,
} from "../../../routes/routes";
import OrganisationDetailState from "../../organisations/details/redux/OrganisationDetailState";
import PropertyDetailLoadingPanel from "./components/PropertyDetailLoadingPanel";
import PropertyDetailPanel from "./components/PropertyDetailPanel";
import { loadProperty, propertyUpdated } from "./redux/PropertyDetailActions";
import PropertyDetailState from "./redux/PropertyDetailState";
import { downloadReport } from "../../procheck/redux/ProCheckAction";
import ProCheckExport from "../../../rest/procheck/model/ProCheckExport";
import ProCheckState from "../../procheck/redux/ProCheckState";

interface URLParams {
  propertyId: string;
  organisationId: string;
}

interface ReduxDispatchProps {
  loadProperty: (orgId: string, propertyId: string) => void;
  onPropertyUpdated: (orgId: string, property: Property) => void;
  downloadResult: (
    orgId: string,
    propertyId: string,
    exportDetails: ProCheckExport,
    languageHeader?: string
  ) => void;
}

interface ReduxStateProps extends PropertyDetailState {
  page: PropertyPageType | undefined;
  orgDetails: OrganisationDetailState;
  lookupEnabled: boolean;
  homeZoneAvailable: boolean;
  canEditContractor: boolean;
  reportUrl: string | undefined;
  proCheckState: ProCheckState;
  isAdmin: boolean;
}

type Props = RouteComponentProps<URLParams> &
  WithTranslation &
  ReduxDispatchProps &
  ReduxStateProps;

class PropertyDetailPage extends React.Component<Props> {
  componentDidMount() {
    const propertyId = this.props.match.params.propertyId;
    if (propertyId !== undefined && propertyId !== this.props.property?.id) {
      this.props.loadProperty(this.orgIdSwitcher(this.props.page), propertyId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.reportUrl && this.props.reportUrl !== prevProps.reportUrl) {
      // Download the report url
      window.open(this.props.reportUrl, "_blank");
    }
  }

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  renderBody = () => {
    if (this.props.property) {
      const links = this.constructNavLinks(this.props.page);

      return (
        <PropertyDetailPanel
          homezoneAvailable={this.props.homeZoneAvailable}
          lookupEnabled={this.props.lookupEnabled}
          pageTitle={this.props.property?.address_line_1 || ""}
          navLinks={links}
          onMoreInfoClicked={this.handleMoreInfoClicked}
          onSenseDetailsClicked={this.handleDeviceDetailsClicked}
          property={this.props.property}
          onPropertyUpdated={this.handlePropertyUpdated}
          loading={this.props.isLoading}
          proCheckReportIds={this.props.proCheckState.resultIds}
          error={this.props.error}
          owner={this.props.ownerOrganisation}
          isValidProperty={this.isPropertyVaild(this.props.property)}
          contractorOrgName={this.props.property.contractor?.name}
          organisationId={this.props.match.params.organisationId}
        />
      );
    } else if (this.props.error) {
      return (
        <ErrorDisplay
          title={this.props.t("propertyDetailPage.errorTitle")}
          message={this.props.error}
        />
      );
    } else {
      return <PropertyDetailLoadingPanel />;
    }
  };

  // MARK: Handlers
  isPropertyVaild = (property: Property) => {
    return !(
      property.address_line_1.trim().length === 0 ||
      property.address_country.name.trim().length === 0 ||
      property.address_postcode.trim().length === 0 ||
      property.address_city.trim().length === 0
    );
  };

  handlePropertyUpdated = (property: Property) => {
    this.props.onPropertyUpdated(this.orgIdSwitcher(this.props.page), property);
  };

  handleNavLinkClicked = (key: string) => {
    // We check for keys as the nav links change based on the 'state.propertyList.page' property
    switch (key) {
      case "selected-org":
        if (typeof this.props.orgDetails.organisation?.id === "string")
          this.props.history.replace(
            buildOrgDetailsRoute(this.props.orgDetails.organisation?.id)
          );
        break;
      case "organisations":
        this.props.history.replace(ORGANISATION_LIST_ROUTE);
        break;
      case "dashboard":
        this.props.history.replace(DASHBOARD_ROUTE);
        break;
      case "admin-organisations":
        this.props.history.replace(PLATFORM_ORGANISATIONS_LIST_ROUTE);
        break;
      case "admin-org-detail":
        if (typeof this.props.orgDetails.organisation?.id === "string")
          this.props.history.replace(
            buildPlatformOrgDetailsRoute(this.props.orgDetails.organisation?.id)
          );
        break;
      default:
        this.props.history.replace(DASHBOARD_ROUTE);
    }
  };

  //If the selected property is of the associated organisation, substitude user 'orgId' with the selected organisation's 'orgId'
  orgIdSwitcher = (page: PropertyPageType | undefined): string => {
    if (page === "contractee-list") {
      return this.props.orgDetails.organisation?.id as string;
    } else {
      return this.props.match.params.organisationId;
    }
  };

  handleMoreInfoClicked = () => {
    const propertyId = this.props.match.params.propertyId;
    const orgId = this.props.match.params.organisationId;
    const route = buildProCheckRoute(orgId, propertyId);
    this.props.history.push(route);
  };

  handleDeviceDetailsClicked = (deviceId: string) => {
    const propertyId = this.props.match.params.propertyId;
    const orgId = this.props.match.params.organisationId;
    const route = buildSenseDeviceDetailRoute(orgId, propertyId, deviceId);
    this.props.history.push(route);
  };

  //MARK: Helpers
  constructNavLinks = (page: PropertyPageType | undefined) => {
    const links = [];
    switch (page) {
      case "dashboard":
        links.push({
          key: "properties",
          name: this.props.t("propertyDetailPage.propertyLink"),
          startIcon: faArrowLeft,
          onClick: this.handleNavLinkClicked,
        });
        break;
      case "contractee-list":
      case "contractor-list":
        this.props.orgDetails.organisation?.id
          ? links.push(
              {
                key: "organisations",
                name: this.props.t("headerTabs.organisations"),
                startIcon: faArrowLeft,
                onClick: this.handleNavLinkClicked,
              },
              {
                key: "selected-org",
                name: this.props.orgDetails.organisation?.name as string,
                startIcon: faArrowLeft,
                onClick: this.handleNavLinkClicked,
              }
            )
          : links.push({
              key: "properties",
              name: this.props.t("propertyDetailPage.propertyLink"),
              startIcon: faArrowLeft,
              onClick: this.handleNavLinkClicked,
            });
        break;
      case "admin-org-detail":
        this.props.orgDetails.organisation?.id
          ? links.push(
              {
                key: "admin-organisations",
                name: this.props.t("headerTabs.organisations"),
                startIcon: faArrowLeft,
                onClick: this.handleNavLinkClicked,
              },
              {
                key: "admin-org-detail",
                name: this.props.orgDetails.organisation?.name as string,
                startIcon: faArrowLeft,
                onClick: this.handleNavLinkClicked,
              }
            )
          : links.push({
              key: "properties",
              name: this.props.t("propertyDetailPage.propertyLink"),
              startIcon: faArrowLeft,
              onClick: this.handleNavLinkClicked,
            });
        break;
      default:
        links.push({
          key: "properties",
          name: this.props.t("propertyDetailPage.propertyLink"),
          startIcon: faArrowLeft,
          onClick: this.handleNavLinkClicked,
        });
        break;
    }
    return links;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  const isHomeZoneAvailable = () => {
    return (
      state.propertyDetail.ownerOrganisation?.address_country.code === "IE" ||
      state.propertyDetail.ownerOrganisation?.address_country.code === "GB"
    );
  };

  return {
    ...state.propertyDetail,
    page: state.propertyList.page,
    orgDetails: state.orgDetail,
    homeZoneAvailable: isHomeZoneAvailable(),
    lookupEnabled:
      state.activeOrganisation.currentOrganisation?.address_country.code !== "FR",
    reportUrl: state.proCheck.reportUrl,
    canEditContractor:
      state.activeOrganisation.permissions.canAssignContractorsToProperties,
    isAdmin: state.adeyAdmin.hasAdminAccess,
    proCheckState: state.proCheck,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadProperty: (orgId, propertyId) => dispatch(loadProperty(orgId, propertyId)),
  onPropertyUpdated: (orgId, property) => dispatch(propertyUpdated(orgId, property)),
  downloadResult: (orgId, propertyId, result, languageHeader) =>
    dispatch(downloadReport(orgId, result, propertyId, languageHeader)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PropertyDetailPage)));
