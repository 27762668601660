// Account not activated

export const ACCOUNT_NOT_ACTIVATED_TYPE = "ACCOUNT_NOT_ACTIVATED_TYPE";

export interface AccountNotActivatedType {
  type: typeof ACCOUNT_NOT_ACTIVATED_TYPE;
  email: string;
}

export const accountNotActivated = (email: string): AccountNotActivatedType => {
  return {
    type: ACCOUNT_NOT_ACTIVATED_TYPE,
    email: email,
  };
};

// Activation email requested

export const ACTIVATION_EMAIL_REQUESTED_TYPE = "ACTIVATION_EMAIL_REQUESTED_TYPE";

export interface ActivationEmailRequestedAction {
  type: typeof ACTIVATION_EMAIL_REQUESTED_TYPE;
  email?: string;
}

export const activationEmailRequested = (
  email?: string
): ActivationEmailRequestedAction => {
  return {
    type: ACTIVATION_EMAIL_REQUESTED_TYPE,
    email: email,
  };
};

// Activation email resent

export const ACTIVATION_EMAIL_RESENT_TYPE = "ACTIVATION_EMAIL_RESENT_TYPE";

export interface ActivationEmailResentAction {
  type: typeof ACTIVATION_EMAIL_RESENT_TYPE;
}

export const activationEmailResent = (): ActivationEmailResentAction => {
  return {
    type: ACTIVATION_EMAIL_RESENT_TYPE,
  };
};

// Activation email resend error

export const ACTIVATION_EMAIL_RESEND_ERROR_TYPE = "ACTIVATION_EMAIL_RESEND_ERROR_TYPE";

export interface ActivationEmailResendErrorAction {
  type: typeof ACTIVATION_EMAIL_RESEND_ERROR_TYPE;
  error: string;
}

export const activationEmailResendError = (
  error: string
): ActivationEmailResendErrorAction => {
  return {
    type: ACTIVATION_EMAIL_RESEND_ERROR_TYPE,
    error: error,
  };
};

// Activation email reset complete

export const ACTIVATION_EMAIL_RESEND_COMPLETE_TYPE =
  "ACTIVATION_EMAIL_RESEND_COMPLETE_TYPE";

export interface ActivationEmailResendComplete {
  type: typeof ACTIVATION_EMAIL_RESEND_COMPLETE_TYPE;
}

export const activationEmailResendComplete = (): ActivationEmailResendComplete => {
  return {
    type: ACTIVATION_EMAIL_RESEND_COMPLETE_TYPE,
  };
};

// Union type

type ResendActivationEmailAction =
  | ActivationEmailRequestedAction
  | AccountNotActivatedType
  | ActivationEmailResendErrorAction
  | ActivationEmailResentAction
  | ActivationEmailResendComplete;

export default ResendActivationEmailAction;
