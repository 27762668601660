import { SenseProductType } from "../model/SenseDevice";

export const isProduct = (product: string): product is SenseProductType => {
  return ["PRO3", "M3SM", "M3WF"].indexOf(product) !== -1;
};

const parseSenseProduct = (serialNumber: string): SenseProductType | undefined => {
  let devicePart = serialNumber.split("-").slice(0, -1);

  const deviceType = devicePart.join().toUpperCase();

  if (isProduct(deviceType)) {
    return deviceType;
  } else {
    return undefined;
  }
};

export default parseSenseProduct;
