import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import ProCheckExport from "../../../rest/procheck/model/ProCheckExport";
import {
  buildProcheckReportDetailRoute,
  buildPropertyDetailRoute,
  DASHBOARD_ROUTE,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import Spinner from "../../../ui/components/Spinner";
import CountryCode from "../../../utils/CountryCode";
import getLanguageHeader from "../../../utils/getLanguageHeader";
import { loadProperty } from "../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import PropertyDetailState from "../../properties/PropertyDetailPage/redux/PropertyDetailState";
import {
  downloadReport,
  filterProCheckResults,
  loadProCheckResults,
} from "../redux/ProCheckAction";
import ProCheckState from "../redux/ProCheckState";
import getProCheckReportArray from "../utils/getProCheckReportArray";
import LoadedProCheckPage from "./components/LoadedProCheckPage";
import ProductReportTableItem from "./model/ProductReportTableItem";
import getProCheckDisplayItems from "../utils/getProCheckDisplayItems";
import { initReportExport } from "../../../ui/components/ExportBar/redux/ExportBarAction";

interface URLParams {
  organisationId: string;
  propertyId: string;
}

type ReduxStateProps = {
  proCheck: ProCheckState;
  propertyState: PropertyDetailState;
};

interface ReduxDispatchProps {
  loadResults: (orgId: string, propertyId: string) => void;
  emailReport: (
    orgId: string,
    propertyId: string,
    exportDetails: ProCheckExport,
    languageHeader?: string
  ) => void;
  downloadReport: (
    orgId: string,
    resultIds: string[],
    privacy?: boolean,
    languageHeader?: string
  ) => void;
  searchResults: (value: string, organisationId: string, extraIds?: string[]) => void;
  loadProperty: (orgId: string, propertyId: string) => void;
}

type Props = RouteComponentProps<URLParams> &
  WithTranslation &
  ReduxStateProps &
  ReduxDispatchProps;

class ProCheckPage extends React.Component<Props> {
  componentDidMount() {
    if (
      this.props.proCheck.results === undefined ||
      this.props.propertyState.property?.id !== this.props.match.params.propertyId
    ) {
      const orgId = this.props.match.params.organisationId;
      const propertyId = this.props.match.params.propertyId;
      if (this.props.propertyState.property === undefined) {
        this.props.loadProperty(orgId, propertyId);
      } else {
        this.props.loadResults(orgId, propertyId);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.proCheck.reportUrl &&
      this.props.proCheck.reportUrl !== prevProps.proCheck.reportUrl
    ) {
      // Download the report url
      window.open(this.props.proCheck.reportUrl, "_blank");
    }
  }

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  renderBody() {
    if (
      this.props.proCheck.results !== undefined &&
      this.props.propertyState.property !== undefined
    ) {
      return (
        <LoadedProCheckPage
          property={this.props.propertyState.property}
          results={getProCheckDisplayItems(
            this.props.proCheck.results,
            this.props.proCheck.pagination.displayItems
          )}
          platformReports={getProCheckReportArray({
            allIds: this.props.proCheck.resultIds,
            resultsById: this.props.proCheck.results,
          })}
          onSearchBarChanged={(searchValue, extraIds) =>
            this.props.searchResults(
              searchValue,
              this.props.match.params.organisationId,
              extraIds
            )
          }
          errorMessage={this.props.proCheck.error}
          onReportClicked={this.handleReportClicked}
          onDashboardClicked={this.handleDashboardClicked}
          onPropertyClicked={this.handlePropertyClicked}
          onEmailReportClicked={this.handleEmailReport}
          onDownloadReportClicked={this.handleDownloadReport}
          isExported={this.props.proCheck.reportExported}
          exporting={this.props.proCheck.exportingReport !== undefined}
        />
      );
    } else if (this.props.proCheck.error) {
      return <ErrorDisplay title={this.props.proCheck.error} />;
    } else {
      return <Spinner />;
    }
  }

  // Handlers

  handleDashboardClicked = () => {
    this.props.history.push(DASHBOARD_ROUTE);
  };

  handlePropertyClicked = () => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    const route = buildPropertyDetailRoute(orgId, propertyId);
    this.props.history.push(route);
  };

  handleEmailReport = (
    emails: string[],
    results: string[],
    privacy: boolean,
    notes?: string
  ) => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    this.props.emailReport(
      orgId,
      propertyId,
      {
        emailAddresses: emails,
        resultIds: results,
        removePersonalInformation: privacy,
        notes: notes,
      },
      getLanguageHeader(
        this.props.propertyState.property?.address_country.code as CountryCode
      )
    );
  };

  handleDownloadReport = (results: string[], privacy: boolean) => {
    const orgId = this.props.match.params.organisationId;
    this.props.downloadReport(
      orgId,
      results,
      privacy,
      getLanguageHeader(
        this.props.propertyState.property?.address_country.code as CountryCode
      )
    );
  };

  handleReportClicked = (report: ProductReportTableItem) => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    const procheckId = report.id;
    const route = buildProcheckReportDetailRoute(orgId, propertyId, procheckId);
    this.props.history.push(route);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    proCheck: state.proCheck,
    propertyState: state.propertyDetail,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadProperty: (orgId, propertyId) => dispatch(loadProperty(orgId, propertyId)),
  loadResults: (orgId, propertyId) => dispatch(loadProCheckResults(orgId, propertyId)),
  searchResults: (searchBarValue, orgId, extraIds) =>
    dispatch(
      filterProCheckResults(
        searchBarValue.trim().toLowerCase(),
        orgId,
        undefined,
        extraIds
      )
    ),
  emailReport: (orgId, propertyId, exportDetails, languageHeader) =>
    dispatch(downloadReport(orgId, exportDetails, propertyId, languageHeader)),
  downloadReport: (orgId, resultIds, privacy, languageHeader) =>
    dispatch(initReportExport(orgId, resultIds, privacy)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProCheckPage)));
