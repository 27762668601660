import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import RootState from "../../../../redux/RootState";
import { buildPlatformOrgDetailsRoute } from "../../../../routes/routes";
import BorderedBox from "../../../../ui/components/BorderedBox";
import HoverableField from "./EditableField/components/HoverableField";

interface ExternalProps {
  ownerOrganisationName: string;
  ownerOrgId?: string;
  marginButton?: number;
}

type Props = ExternalProps & WithTranslation;

const PropertyOwnerOrganisationPanel = (props: Props) => {
  const label = props.t("propertyDetailPage.ownerOrgLabel");

  //Selector
  const isAdmin = useSelector((state: RootState) => state.adeyAdmin.hasAdminAccess);

  //Hooks
  const history = useHistory();

  // This uses a hoverable field so that it matches the contractor panel padding-wise
  // It's disabled so you can't actually hover & edit it.
  return (
    <BorderedBox marginBottom={props.marginButton}>
      <HoverableField
        label={label}
        text={props.ownerOrganisationName}
        onClick={() => {
          if (isAdmin) {
            history.push(buildPlatformOrgDetailsRoute(props.ownerOrgId || ""));
          }
        }}
        hideEditIcon
        hoverEnabled={isAdmin}
      />
    </BorderedBox>
  );
};

export default withTranslation()(PropertyOwnerOrganisationPanel);
