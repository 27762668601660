import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CenteredAuthCard from "../../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING } from "../../../../../ui/theme/dimensions";
import termsEn from "../../../../documents/assets/adey-sense-tnc-en.pdf";
import termsFr from "../../../../documents/assets/adey-sense-tnc-fr.pdf";
import termsDe from "../../../../documents/assets/adey-sense-tnc-de.pdf";
import DocumentPage from "../../../../documents/components/DocumentPage";

type AvailableLanguages = "en" | "de" | "fr";

//State
interface State {
  filePath: any;
}

interface ExternalProps extends WithTranslation {
  onTermsAccepted: () => void;
}

type Props = ExternalProps & WithTranslation;

class TermsAndConditionsPage extends React.Component<Props, State> {
  componentDidMount() {
    this.languageDetector(this.props.i18n.language as AvailableLanguages);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.t !== prevProps.t) {
      this.languageDetector(this.props.i18n.language as AvailableLanguages);
    }
  }

  state = {
    filePath: termsEn,
  };

  //Helpers
  languageDetector = (lng: AvailableLanguages) => {
    switch (lng) {
      case "en":
        this.setState({ filePath: termsEn });
        return;
      case "de":
        this.setState({ filePath: termsDe });
        return;
      case "fr":
        this.setState({ filePath: termsFr });
        return;
    }
  };

  render() {
    const title = this.props.t("termsAndConditionsPage.title");
    const accept = this.props.t("termsAndConditionsPage.acceptButton");

    return (
      <CenteredAuthCard
        title={title}
        overflow="hidden"
        width="1000px"
        noMaxWidth
        includeSignOut
      >
        <Grid container spacing={DEFAULT_SPACING} justify="center">
          <Grid item style={{ width: "100%" }}>
            <Box maxHeight="45vh" display="flex" overflow="auto">
              <DocumentPage filePath={this.state.filePath} />
            </Box>
          </Grid>

          <Grid item>
            <PrimaryButton
              label={accept}
              endIcon={<FontAwesomeIcon icon={faCheck} />}
              onClick={this.props.onTermsAccepted}
            />
          </Grid>
        </Grid>
      </CenteredAuthCard>
    );
  }
}

export default withTranslation()(TermsAndConditionsPage);
