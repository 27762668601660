import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import ProCheckExport from "../../../rest/procheck/model/ProCheckExport";
import {
  buildPlatformOrgDetailsRoute,
  buildProCheckRoute,
  buildPropertyDetailRoute,
  buildUserDetailRoute,
  DASHBOARD_ROUTE,
  PLATFORM_ORGANISATIONS_LIST_ROUTE,
  PROCHECK_REPORTING_ROUTE,
  PROCHECK_RESULT_ROUTE,
  PROCHECK_USER_RESULTS_ROUTE,
  REPORTING_ROUTE,
  USER_LIST_ROUTE,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import Spinner from "../../../ui/components/Spinner";
import CountryCode from "../../../utils/CountryCode";
import getLanguageHeader from "../../../utils/getLanguageHeader";
import { loadProperty } from "../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import PropertyDetailState from "../../properties/PropertyDetailPage/redux/PropertyDetailState";
import { downloadReport, loadProCheckDetails } from "../redux/ProCheckAction";
import ProCheckState from "../redux/ProCheckState";
import LoadedProCheckDetailsPage from "./components/LoadedProCheckDetailsPage";
import { initReportExport } from "../../../ui/components/ExportBar/redux/ExportBarAction";

export type ProCheckPageType = "user-page" | "property-page" | "org-page";

interface ReduxStateProps {
  procheckState: ProCheckState;
  propertyState: PropertyDetailState;
  activeOrganisationId?: string;
  isAdmin: boolean;
}

interface ReduxDispatchProps {
  loadDetails: (orgId: string, propertyId: string, procheckId: string) => void;
  loadProperty: (orgId: string, propertyId: string) => void;
  downloadReport: (
    orgId: string,
    resultIds: string[],
    privacy: boolean,
    languageHeader?: string
  ) => void;
  emailReport: (
    orgId: string,
    propertyId: string,
    exportDetails: ProCheckExport,
    languageHeader?: string
  ) => void;
}

interface URLParams {
  organisationId: string;
  propertyId: string;
  procheckId: string;
  userId: string;
}

interface State {
  proCheckPageType: ProCheckPageType;
}

type Props = RouteComponentProps<URLParams> &
  ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation;

const getPageType = (path: string) => {
  if (matchPath<URLParams>(path, PROCHECK_REPORTING_ROUTE)?.params.procheckId) {
    return "org-page" as ProCheckPageType;
  } else if (matchPath<URLParams>(path, PROCHECK_USER_RESULTS_ROUTE)?.params.userId) {
    //User ProCheck route

    return "user-page" as ProCheckPageType;
  } else if (matchPath<URLParams>(path, PROCHECK_RESULT_ROUTE)?.params.propertyId) {
    //Normal ProCheck route

    return "property-page" as ProCheckPageType;
  } else {
    return "property-page" as ProCheckPageType;
  }
};
export class ProCheckDetailsPage extends React.Component<Props, State> {
  state = {
    proCheckPageType: getPageType(this.props.location.pathname),
  };

  // Keep this callback so we can unregister the history path listener
  callback: (() => void) | undefined = undefined;

  componentDidMount() {
    if (
      this.props.procheckState?.selectedReport?.id !== this.props.match.params.procheckId
    ) {
      let orgId = this.props.match.params.organisationId;
      if (
        matchPath<URLParams>(this.props.location.pathname, PROCHECK_REPORTING_ROUTE) &&
        this.props.activeOrganisationId
      ) {
        orgId = this.props.activeOrganisationId;
      }
      const propertyId = this.props.match.params.propertyId;
      const procheckId = this.props.match.params.procheckId;
      if (
        !this.props.propertyState.property ||
        this.props.propertyState.property.id !== propertyId
      ) {
        this.props.loadProperty(orgId, propertyId);
      }
      this.props.loadDetails(orgId, propertyId, procheckId);
    }

    //Adding listener
    this.handlePathChanged(this.props.location.pathname);
    this.callback = this.props.history.listen((location) =>
      this.handlePathChanged(location.pathname)
    );
  }

  // Stop listening when we unmount
  componentWillUnmount() {
    this.callback?.();
  }

  //Path change handler
  handlePathChanged = (path: string) => {
    if (matchPath<URLParams>(path, PROCHECK_REPORTING_ROUTE)?.params.procheckId) {
      this.setState({ proCheckPageType: "org-page" });
    } else if (matchPath<URLParams>(path, PROCHECK_USER_RESULTS_ROUTE)?.params.userId) {
      //User ProCheck route

      this.setState({ proCheckPageType: "user-page" });
    } else if (matchPath<URLParams>(path, PROCHECK_RESULT_ROUTE)?.params.propertyId) {
      //Normal ProCheck route

      this.setState({ proCheckPageType: "property-page" });
    }
  };

  //Navigation Handlers
  handleDashboardClicked = () => {
    this.props.history.push(DASHBOARD_ROUTE);
  };

  handlePropertyClicked = () => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    const route = buildPropertyDetailRoute(orgId, propertyId);
    this.props.history.push(route);
  };

  handleProcheckClicked = () => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    const route = buildProCheckRoute(orgId, propertyId);
    this.props.history.push(route);
  };

  handleUserClicked = () => {
    const userId = this.props.match.params.userId;
    const orgId = this.props.match.params.organisationId;
    const route = buildUserDetailRoute(orgId, userId);
    this.props.history.push(route);
  };

  handleUserTabClicked = () => {
    this.props.history.push(USER_LIST_ROUTE);
  };

  handleReportTabClicked = () => {
    this.props.history.push(REPORTING_ROUTE);
  };

  handleOrgsClicked = () => {
    this.props.history.push(PLATFORM_ORGANISATIONS_LIST_ROUTE);
  };

  handleOrganisationClicked = () => {
    const orgId = this.props.match.params.organisationId;
    this.props.history.push(buildPlatformOrgDetailsRoute(orgId));
  };

  //Report Export Handler
  handleExportClicked = (
    emails: string[],
    results: string[],
    privacy: boolean,
    notes?: string
  ) => {
    const orgId = this.props.match.params.organisationId;
    const propertyId = this.props.match.params.propertyId;
    this.props.emailReport(
      orgId,
      propertyId,
      {
        emailAddresses: emails,
        resultIds: results,
        removePersonalInformation: privacy,
        notes: notes,
      },
      getLanguageHeader(
        this.props.propertyState.property?.address_country.code as CountryCode
      )
    );
  };

  handleDownloadClicked = (results: string[], privacy: boolean) => {
    const orgId = this.props.match.params.organisationId;
    this.props.downloadReport(
      orgId,
      results,
      privacy,
      getLanguageHeader(
        this.props.propertyState.property?.address_country.code as CountryCode
      )
    );
  };

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  renderBody() {
    if (
      this.props.procheckState.selectedReport !== undefined &&
      this.props.propertyState.property !== undefined
    ) {
      return (
        <LoadedProCheckDetailsPage
          //Export dialog props
          errorMessage={this.props.procheckState.error}
          isExported={this.props.procheckState.reportExported}
          exporting={this.props.procheckState.exportingReport !== undefined}
          onExportReportClicked={this.handleExportClicked}
          onDownloadReportClicked={this.handleDownloadClicked}
          //Loaded details props
          isAdeyAdmin={this.props.isAdmin}
          resultDetails={this.props.procheckState.selectedReport}
          property={this.props.propertyState.property}
          //Navigation
          pageType={this.state.proCheckPageType}
          onDashboardClicked={this.handleDashboardClicked}
          onPropertyClicked={this.handlePropertyClicked}
          onProcheckClicked={this.handleProcheckClicked}
          onUserClicked={this.handleUserClicked}
          onOrgsClicked={this.handleOrgsClicked}
          onOrganisationClicked={this.handleOrganisationClicked}
          onUserTabClicked={this.handleUserTabClicked}
          onReportsClicked={this.handleReportTabClicked}
        />
      );
    } else if (this.props.procheckState.error) {
      return <ErrorDisplay title={this.props.procheckState.error} />;
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  activeOrganisationId: state.activeOrganisation.currentOrganisation?.id,
  procheckState: state.proCheck,
  isAdmin: state.adeyAdmin.hasAdminAccess,
  propertyState: state.propertyDetail,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadDetails: (orgId, propertyId, procheckId) =>
    dispatch(loadProCheckDetails(orgId, propertyId, procheckId)),
  emailReport: (orgId, propertyId, exportDetails, languageHeader) =>
    dispatch(downloadReport(orgId, exportDetails, propertyId, languageHeader)),
  downloadReport: (orgId, resultIds, privacy, languageHeader) =>
    dispatch(initReportExport(orgId, resultIds, privacy, languageHeader)),
  loadProperty: (orgId, propertyId) => dispatch(loadProperty(orgId, propertyId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ProCheckDetailsPage)));
