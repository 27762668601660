import { Button, Box, Typography, CircularProgress } from "@material-ui/core";
import React, { ReactNode } from "react";
import { LARGE_SPACING } from "../theme/dimensions";

const largeMinHeight = 40;
const iconPadding = 40;

type PrimaryButtonSize = "small" | "large";

interface PrimaryButtonProps {
  // The text on the button
  label?: string;

  // If true, fill the available width.
  // Default: false
  fullWidth?: boolean;

  // The icon on the end
  // Does not apply if isLoading is true.
  endIcon?: ReactNode;

  // The icon at the start
  startIcon?: ReactNode;

  // If true, replace the endIcon with a progress indicator.
  // Default: false.
  isLoading?: boolean;

  // Button style variants. If present, will use style available in Material UI theme
  // Default: 'contained'
  variant?: "text" | "outlined" | "contained" | undefined;

  // Button colour variants. If present, will use colour available in Material UI theme
  // Default: 'primary'
  colour?: "inherit" | "default" | "primary" | "secondary" | undefined;

  // Click handler
  onClick?: () => void;

  // Disabled
  disabled?: boolean;

  size?: PrimaryButtonSize;
}

const PrimaryButton = (props: PrimaryButtonProps) => {
  // defaults to large
  const isSmall = props.size === "small";

  const endIcon = props.isLoading ? (
    <CircularProgress color="inherit" size={isSmall ? 20 : 30} />
  ) : (
    props.endIcon
  );

  const minHeight = isSmall ? 0 : largeMinHeight;
  const labelVariant = isSmall ? "button" : "h6";
  const padding = isSmall ? 0 : 1;
  const buttonVariant = props.variant ? props.variant : "contained";
  const buttonColor = props.colour ? props.colour : "primary";
  const endIconPadding = endIcon ? iconPadding : 0;
  const startIconPadding = props.startIcon ? <Box width={LARGE_SPACING} /> : undefined;

  return (
    <Button
      variant={buttonVariant}
      color={buttonColor}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      disabled={props.isLoading || props.disabled}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        minHeight={minHeight}
        paddingY={padding}
      >
        {props.startIcon}

        {startIconPadding}

        <Typography variant={labelVariant}>
          <strong>{props.label?.toUpperCase()}</strong>
        </Typography>

        <Box width={endIconPadding} />

        {endIcon}
      </Box>
    </Button>
  );
};

export default PrimaryButton;
