import OverdueStatus from "../../../pages/properties/PropertyListPage/model/OverdueStatus";

export type OverdueReasons =
  | "test_overdue"
  | "service_overdue"
  | "test_due"
  | "service_due";

export const convertOverdueStatusToReason = (
  overdueState: OverdueStatus
): OverdueReasons[] => {
  switch (overdueState) {
    case "overdue":
      return ["test_overdue", "service_overdue"];
    case "upcoming":
      return ["test_due", "service_due"];
  }
};
