import React from "react";
import ColoredText from "../../../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../../ui/theme/createMaterialTheme";

interface Props {
  label: string;
}

const EditableFieldLabel = (props: Props) => {
  return (
    <ColoredText
      style={{ paddingLeft: "8px" }}
      textColor={BACKGROUND_ACCENT_COLOR}
      variant="button"
    >
      <strong>{props.label}</strong>
    </ColoredText>
  );
};

export default EditableFieldLabel;
