import { TFunction } from "i18next";

type OverdueStatus = "overdue" | "upcoming";

export const titleForOverdueStatus = (status: OverdueStatus, t: TFunction): string => {
  switch (status) {
    case "overdue":
      return t("overdueStatus.overdue");

    case "upcoming":
      return t("overdueStatus.upcoming");
  }
};

export const colorForOverdueStatus = (status: OverdueStatus): string => {
  switch (status) {
    case "overdue":
      return "#FF0000";

    case "upcoming":
      return "#FF7F00";
  }
};

export default OverdueStatus;
