import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";

interface ExternalProps {
  onReturnClicked: () => void;
}

type Props = ExternalProps & WithT;

const CreateOrganisationAdminInviteSent = (props: Props) => {
  const title = props.t("createOrganisationAdminEmailSent.title");
  const returnButton = props.t("createOrganisationAdminEmailSent.returnButton");
  const message = props.t("createOrganisationAdminEmailSent.body");

  return (
    <Grid container direction="column" spacing={SMALL_SPACING}>
      <Grid item>
        <Typography variant="h2" align="center">
          <strong>{title.toUpperCase()}</strong>
        </Typography>
      </Grid>

      <Grid item />

      <Grid item>
        <Box paddingX={DEFAULT_SPACING}>
          <Typography align="center">{message}</Typography>
        </Box>
      </Grid>

      <Grid item />

      <Grid item>
        <PrimaryButton
          endIcon={<FontAwesomeIcon icon={faTimes} />}
          label={returnButton}
          onClick={props.onReturnClicked}
          fullWidth
        />
      </Grid>

      <Grid item />
    </Grid>
  );
};

export default withTranslation()(CreateOrganisationAdminInviteSent);
