import IPagination from "../../../utils/interfaces/IPagination";
import ProCheckAction from "./ProCheckAction";
import ProCheckState from "./ProCheckState";

const initialPagination: IPagination = {
  currentPage: 1,
  itemsPerPage: 10,
  maxPages: 1,
  displayItems: [],
};

const initialState: ProCheckState = {
  proCheckResultsType: "org-reports",
  results: undefined,
  searchString: undefined,
  resultIds: [],
  pagination: initialPagination,
  error: undefined,
  isLoading: false,
  exportingReport: undefined,
  reportUrl: undefined,
  reportExported: undefined,
  selectedReport: undefined,
};

const proCheckReducer = (state = initialState, action: ProCheckAction): ProCheckState => {
  switch (action.type) {
    case "LOAD_PROCHECK_RESULTS_TYPE":
      return {
        ...initialState,
        proCheckResultsType: "property-reports",
        isLoading: true,
      };

    case "LOAD_USER_PROCHECK_RESULTS_TYPE":
      return { ...initialState, proCheckResultsType: "user-reports", isLoading: true };

    case "LOAD_ORG_PROCHECK_RESULTS_TYPE":
      return { ...initialState, proCheckResultsType: "org-reports", isLoading: true };

    case "PROCHECK_RESULTS_LOADED_TYPE":
      return {
        proCheckResultsType: state.proCheckResultsType,
        results: action.results.resultsById,
        resultIds: action.results.allIds,
        pagination: action.pagination,
        searchString: state.searchString,
        error: undefined,
        isLoading: false,
        exportingReport: undefined,
        reportUrl: undefined,
        selectedReport: state.selectedReport || undefined,
        reportExported: undefined,
      };

    case "PROCHECK_RESULTS_LOAD_ERROR_TYPE":
      return {
        proCheckResultsType: state.proCheckResultsType,
        resultIds: [],
        results: undefined,
        error: action.error,
        isLoading: false,
        searchString: state.searchString,
        pagination: initialPagination,
        exportingReport: undefined,
        reportUrl: undefined,
        selectedReport: undefined,
        reportExported: undefined,
      };

    case "LOAD_PROCHECK_DETAILS_TYPE":
      return { ...state, selectedReport: undefined, isLoading: true };

    case "PROCHECK_DETAILS_LOADED_TYPE":
      return {
        proCheckResultsType: state.proCheckResultsType,
        results: state.results ?? undefined,
        resultIds: state.resultIds ?? [],
        pagination: state.pagination,
        searchString: state.searchString,
        error: undefined,
        isLoading: false,
        exportingReport: undefined,
        reportUrl: undefined,
        selectedReport: action.selectedReport,
        reportExported: undefined,
      };

    case "PROCHECK_DETAILS_LOAD_ERROR_TYPE":
      return {
        proCheckResultsType: state.proCheckResultsType,
        results: state.results ?? undefined,
        resultIds: state.resultIds ?? [],
        pagination: state.pagination,
        searchString: state.searchString,
        error: action.error,
        isLoading: false,
        exportingReport: undefined,
        reportUrl: undefined,
        selectedReport: undefined,
        reportExported: undefined,
      };

    case "DOWNLOAD_REPORT_TYPE":
      return {
        ...state,
        exportingReport: action.exportDetails,
        reportExported: undefined,
      };

    case "DOWNLOAD_REPORT_SUCCESS_TYPE":
      return {
        ...state,
        exportingReport: undefined,
        reportExported: true,
        reportUrl: action.reportUrl,
      };

    case "DOWNLOAD_REPORT_ERROR_TYPE":
      return {
        ...state,
        exportingReport: undefined,
        reportExported: false,
        reportUrl: undefined,
        error: action.error,
      };
    case "PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE":
      return {
        ...state,
        isLoading: true,
        pagination: { ...state.pagination, currentPage: action.page },
      };
    case "PROCHECK_FILTER_RESULTS_TYPE":
      return {
        ...state,
        isLoading: true,
        searchString: action.value,
      };
    default:
      return state;
  }
};

export default proCheckReducer;
