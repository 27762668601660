import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Country } from "../../../../../rest/countries/model/Country";
import CenteredAuthCard from "../../../../../ui/components/CenteredAuthCard";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OrganisationEditor from "../../../../../ui/components/OrganisationEditor/OrganisationEditor";
import { EXTRA_LARGE_SPACING } from "../../../../../ui/theme/dimensions";
import CreateOrganisationForm from "./model/CreateOrganisationForm";

interface Props extends WithTranslation {
  countryList: Country[] | undefined;
  isLoading: boolean;
  error: string | undefined;
  onOrgCreated: (form: CreateOrganisationForm) => void;
}

const CreateOrganisationPage = (props: Props) => {
  const title = props.t("createOrganisationPage.title");

  return (
    <CenteredAuthCard title={title} includeSignOut>
      <Box height={EXTRA_LARGE_SPACING} />

      <OrganisationEditor
        countryList={props.countryList}
        isLoading={props.isLoading}
        onContinuePressed={props.onOrgCreated}
      />

      <ErrorSnackbarHandler message={props.error} />
    </CenteredAuthCard>
  );
};

export default withTranslation()(CreateOrganisationPage);
