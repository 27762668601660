import { Box, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { Country } from "../../../rest/countries/model/Country";
import Organisation from "../../../rest/organisation/model/Organisation";
import { buildPlatformAgentDetailRoute } from "../../../routes/routes";
import AgentTable from "../../../ui/components/AgentTable/AgentTable";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import SearchBar from "../../../ui/components/SearchBar";
import Spinner from "../../../ui/components/Spinner";
import TableHeader from "../../../ui/components/TableList/components/TableHeader";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../ui/theme/dimensions";
import TablePageIndicator from "../../properties/PropertyListPage/components/TablePageIndicator";
import { loadPlatformAgents, searchTextChanged } from "./redux/PlatformAgentListAction";
import PlatformAgentListState from "./redux/PlatformAgentListState";

interface ReduxStateProps {
  agentList: PlatformAgentListState;
  countryList: Country[] | undefined;
}

interface ReduxDispatchProps {
  onPageChanged: (page: number) => void;
  searchTextChanged: (text: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithT & RouteComponentProps;

class PlatformAgentListPage extends React.Component<Props> {
  render() {
    //Labels
    const searchBarPlaceholder = this.props.t("platformAgentList.searchBarPlaceholder");

    return (
      <DashboardLayout hideRagStatus>
        <Box padding={DEFAULT_SPACING} display="flex" flexDirection="column">
          <SearchBar
            placeholder={searchBarPlaceholder}
            value={this.props.agentList.searchText}
            onChange={this.props.searchTextChanged}
          />
          <Box height={2 * EXTRA_LARGE_SPACING} />

          {this.renderTable()}
          <Box height={2 * EXTRA_LARGE_SPACING} />
          <TablePageIndicator
            selectedPage={this.props.agentList.currentPage}
            maxPages={this.props.agentList.maxPage}
            onPageNumberChanged={this.props.onPageChanged}
          />
        </Box>
      </DashboardLayout>
    );
  }

  //Handlers
  onAgentClicked = (agent: Organisation) => {
    const route = buildPlatformAgentDetailRoute(agent.id);
    this.props.history.push(route);
  };

  //Renderers
  renderTable = () => {
    if (
      this.props.agentList.agentOrganisations &&
      this.props.agentList.agentOrganisations.length > 0
    ) {
      return (
        <Box>
          <TableHeader text="platformAgentList.tableTitle" />
          <AgentTable
            agents={this.props.agentList.agentOrganisations}
            onAgentClicked={this.onAgentClicked}
            countries={this.props.countryList || []}
          />
        </Box>
      );
    } else if (
      this.props.agentList.agentOrganisations !== undefined &&
      this.props.agentList.agentOrganisations.length === 0
    ) {
      return (
        <Box padding={LARGE_SPACING}>
          <Typography variant="h2" color="textPrimary" align="center">
            <Trans>platformAgentList.emptyMessage</Trans>
          </Typography>
        </Box>
      );
    } else if (this.props.agentList.error) {
      return <ErrorDisplay title={this.props.agentList.error} />;
    } else {
      return <Spinner />;
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  agentList: state.platformAgentList,
  countryList: state.countryList.countryList,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onPageChanged: (number) => dispatch(loadPlatformAgents(number)),
  searchTextChanged: (text) => dispatch(searchTextChanged(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PlatformAgentListPage)));
