import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import Env from "../../app/environment/Env";
import CognitoAuthService from "../../pages/auth/Login/service/CognitoAuthService";
import RootState from "../../redux/RootState";
import { USER_LIST_ROUTE } from "../../routes/routes";
import AdeyLogo from "../assets/AdeyLogo/AdeyLogo";
import { BACKGROUND_ACCENT_COLOR } from "../theme/createMaterialTheme";
import { DEFAULT_SPACING, SMALL_SPACING } from "../theme/dimensions";
import ColoredText from "./ColoredText";
import PrimaryButton from "./PrimaryButton";

interface ExternalProps {
  styled?: boolean;
}

type Props = ExternalProps & RouteComponentProps;

const DeveloperZonePopup = (props: Props) => {
  //Consts
  const _logoHeight = 50;

  //Callback (Open Developer Zone)
  const [gettingAuthToken, setGettingAuthToken] = useState<boolean>(false);

  //State
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    const authService = new CognitoAuthService();

    if (gettingAuthToken) {
      authService.getCurrentToken().then((token) => {
        if (token !== undefined && showDialog) {
          const url = `${Env.developerZoneBaseUrl}/login?token=${token}`;
          window.open(url, "_blank")?.focus();
          setGettingAuthToken(false);
          setShowDialog(false);
        } else {
          setGettingAuthToken(false);
        }
      });
    }

    return () => {
      setGettingAuthToken(false);
    };
  }, [gettingAuthToken, showDialog]);

  //Hooks
  const { t } = useTranslation();
  const canInviteUsers: boolean = useSelector(
    (store: RootState) =>
      store.activeOrganisation.currentOrganisation?.role.name === "owner"
  );
  const isAdmin: boolean = useSelector(
    (store: RootState) => store.adeyAdmin.hasAdminAccess
  );

  //Strings
  const label = t("copyrightFooter.developer");
  const title = t("developerZonePopup.title");
  const subTitle = t("developerZonePopup.subTitle");
  const description = t("developerZonePopup.description");

  const inviteButtonLabel = t("developerZonePopup.inviteButtonLabel");
  const redirectButtonLabel = t("developerZonePopup.redirectButtonLabel");

  //Helpers
  const changeDialogStatus = () => {
    setShowDialog(!showDialog);
  };

  return (
    <Box>
      <Button
        variant={props.styled ? "contained" : undefined}
        style={{ textTransform: "initial" }}
        onClick={changeDialogStatus}
      >
        <ColoredText variant="body1" textColor={BACKGROUND_ACCENT_COLOR}>
          {label}
        </ColoredText>
      </Button>
      <Dialog open={showDialog} onClose={changeDialogStatus}>
        <Box padding={DEFAULT_SPACING} minWidth={440}>
          <Grid
            container
            spacing={SMALL_SPACING}
            alignContent="center"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} alignContent="center">
              <Box display="flex" alignItems="center" flexDirection="column">
                <AdeyLogo color="blue" height={_logoHeight} />
              </Box>
            </Grid>
            <ColoredText variant="h5" textColor={BACKGROUND_ACCENT_COLOR}>
              {title}
            </ColoredText>
            <Grid item />
            <Grid item xs={12}>
              <ColoredText
                variant="h6"
                textColor={BACKGROUND_ACCENT_COLOR}
                align="center"
              >
                {subTitle}
              </ColoredText>
            </Grid>
            <Grid item />

            <Grid item xs={12}>
              <ColoredText
                variant="body2"
                textColor={BACKGROUND_ACCENT_COLOR}
                align="center"
              >
                {description}
              </ColoredText>
            </Grid>
            <Grid item />

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <PrimaryButton
                  isLoading={gettingAuthToken}
                  variant="text"
                  colour="secondary"
                  label={redirectButtonLabel}
                  onClick={() => {
                    setGettingAuthToken(true);
                  }}
                />
              </Box>
            </Grid>
            {canInviteUsers && !isAdmin && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <PrimaryButton
                    label={inviteButtonLabel}
                    onClick={() => {
                      setShowDialog(false);
                      props.history.push({
                        pathname: USER_LIST_ROUTE,
                        search: "?inviteUser=true",
                      });
                    }}
                    endIcon={<FontAwesomeIcon icon={faArrowRight} size="2x" />}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};

export default withRouter(DeveloperZonePopup);
