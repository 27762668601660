import { put, takeEvery } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import AuthService from "../../Login/service/AuthService";
import {
  activationError,
  activationSuccess,
  StartActivationEvent,
  START_ACTIVATION_TYPE,
} from "./AccountActivationAction";

function* accountActivationSagas() {
  yield takeEvery(START_ACTIVATION_TYPE, onAccountActivationStarted);
}

function* onAccountActivationStarted(action: StartActivationEvent) {
  const authService: AuthService = yield getDependency("authService");

  try {
    yield authService.activateAccount(action.username, action.token);
    yield put(activationSuccess());
  } catch (e) {
    const message = getErrorMessage(e, undefined);
    yield put(activationError(message));
  }
}

export default accountActivationSagas;
