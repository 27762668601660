import { select } from "@redux-saga/core/effects";
import NoActiveOrganisationError from "../../errors/NoActiveOrganisationError";
import RootState from "../RootState";

/**
 * Gets the currently active organisation,
 * @returns a `MemberOrganisation`
 * @throws if none is set
 */
function* getCurrentOrganisation() {
  const rootState: RootState = yield select();
  const org = rootState.activeOrganisation.currentOrganisation;
  if (!org) {
    throw new NoActiveOrganisationError();
  }
  return org;
}

export default getCurrentOrganisation;
