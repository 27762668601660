import { Box, Dialog, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DEFAULT_SPACING } from "../../../../../ui/theme/dimensions";
import TMoveDestination from "../../../../admin/AdminMoveEntity/types/TMoveDestination";
import TSelectedMoveTab from "../../../../admin/AdminMoveEntity/types/TMoveTab";
import SearchDestinationPanel from "../../../../../ui/components/SearchDestinationPanel/SearchDestinationPanel";
import MoveEntitiesReviewPanel from "../../../../../ui/components/MoveEntitiesReviewPanel/MoveEntitiesReviewPanel";
import SenseDevice from "../../../../../rest/sense/model/SenseDevice";
import { formatPropertyAddress } from "../../../../../utils/formatAddress";
import parseSenseProduct from "../../../../../rest/sense/helpers/parseSenseProduct";
import MoveEntityWarningPanel from "../../../../../ui/components/MoveEntityWarningPanel/MoveEntityWarningPanel";
import MoveEntitySuccessPanel from "../../../../../ui/components/MoveEntitySuccessPanel/MoveEntitySuccessPanel";
import { IMoveActionResult } from "../../../../admin/AdminMoveEntity/services/AdminMoveEntityService";
import MoveEntityErrorPanel from "../../../../../ui/components/MoveEntityErrorPanel/MoveEntityErrorPanel";

interface IProps {
  open: boolean;
  handleOpen: () => void;
  handleSearchPressed: () => void;
  handleSetSearchString: (v: string) => void;
  setSelectedDestination: (id: string) => void;
  searchValue: string;
  isLoading: boolean | undefined;
  moved: boolean | undefined;
  destinations: TMoveDestination[];
  handleNextPressed: () => void;
  handleBackPressed: () => void;
  handleMovePressed: () => void;
  handleSuccessPressed: () => void;
  moveResults?: IMoveActionResult;
  activeTab: TSelectedMoveTab;
  selectedDestination?: string;
  selectedDevices: SenseDevice[];
}

const MoveDeviceDialog = ({
  open,
  handleOpen,
  searchValue,
  isLoading,
  handleSearchPressed,
  handleSetSearchString,
  destinations,
  selectedDestination,
  setSelectedDestination,
  handleNextPressed,
  handleBackPressed,
  handleMovePressed,
  handleSuccessPressed,
  activeTab,
  moveResults,
  selectedDevices,
}: IProps) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const searchPlaceholder = t("moveDeviceDialog.searchPlaceholder");

  const destination = destinations.filter((dest) => dest.id === selectedDestination)[0];

  const getSuccessItems = () => {
    return selectedDevices.filter((device) => {
      return moveResults?.movedEntities.find((id) => id === device.id) !== undefined;
    });
  };

  const getFailureItems = () => {
    return selectedDevices.filter((device) => {
      return moveResults?.rejectedEntities.find((id) => id === device.id) !== undefined;
    });
  };

  //Helpers
  const getTitle = () => {
    switch (activeTab) {
      case "destination":
        return t("moveDeviceDialog.destinationTitle");
      case "confirmation":
        return t("moveDeviceDialog.title", { count: selectedDevices.length });
      case "warning":
        return t("moveDeviceDialog.confirmationTitle");
      case "success":
        return t("moveDeviceDialog.successTitle", { count: selectedDevices.length });
      case "failure":
        return t("moveDeviceDialog.failureTitle", { count: selectedDevices.length });
    }
  };

  //Renderers
  const renderBody = () => {
    switch (activeTab) {
      case "destination":
        return (
          <SearchDestinationPanel
            searchValue={searchValue}
            searchPlaceholder={searchPlaceholder}
            handleSearchChange={handleSetSearchString}
            handleSearchButtonClicked={handleSearchPressed}
            handleBackClicked={handleOpen}
            handleNextClicked={handleNextPressed}
            handleSelectDestination={setSelectedDestination}
            destinations={destinations}
            selectedDestinationId={selectedDestination}
            isLoading={isLoading}
          />
        );
      case "confirmation":
        return (
          <MoveEntitiesReviewPanel
            destination={destination}
            entityType={"device"}
            selectedItems={selectedDevices}
            getSelectedItemName={(item) =>
              `${parseSenseProduct(item.serial_number)}, ${item.serial_number}`
            }
            handleBackPressed={handleBackPressed}
            handleNextPressed={handleNextPressed}
          />
        );

      case "warning":
        return (
          <MoveEntityWarningPanel
            destination={destination}
            entityType={"device"}
            loading={isLoading}
            selectedItems={selectedDevices}
            getSelectedItemName={(item) =>
              `${parseSenseProduct(item.serial_number)}, ${item.serial_number}`
            }
            getOrigin={() => {
              return formatPropertyAddress(selectedDevices[0].property);
            }}
            handleBackPressed={handleBackPressed}
            handleNextPressed={handleMovePressed}
          />
        );
      case "success":
        return (
          <MoveEntitySuccessPanel
            entityType={"device"}
            getItemName={(item) =>
              `${parseSenseProduct(item.serial_number)}, ${item.serial_number}`
            }
            destination={destination}
            items={getSuccessItems()}
            rejectedItems={getFailureItems()}
            handleClosePressed={handleSuccessPressed}
          />
        );
      case "failure":
        return (
          <MoveEntityErrorPanel
            entityType={"device"}
            getItemName={(item) =>
              `${parseSenseProduct(item.serial_number)}, ${item.serial_number}`
            }
            destination={destination}
            items={getFailureItems()}
            handleClosePressed={handleOpen}
          />
        );
    }
  };

  return (
    <Dialog open={open} onClose={handleOpen}>
      <Box
        display="flex"
        flexDirection="column"
        width={500}
        paddingX={DEFAULT_SPACING * 1.5}
        paddingBottom={DEFAULT_SPACING}
        paddingTop={DEFAULT_SPACING * 1.5}
      >
        <Typography
          align="center"
          style={{ textTransform: "uppercase" }}
          variant="h2"
          gutterBottom
        >
          <strong>{getTitle()}</strong>
        </Typography>
        {renderBody()}
      </Box>
    </Dialog>
  );
};

export default MoveDeviceDialog;
