import SenseDeviceAction, {
  CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE,
  LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE,
  LOAD_SELECTED_SENSE_DEVICE_TYPE,
  LOAD_SENSE_DEVICES_ERROR_TYPE,
  LOAD_SENSE_DEVICES_TYPE,
  SELECTED_SENSE_DEVICE_LOADED_TYPE,
  SENSE_DEVICES_LOADED_TYPE,
  SENSE_DEVICE_ACTIVATION_STATUS_CHANGED_TYPE,
  SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR_TYPE,
} from "./SenseDevicesAction";
import SenseDevicesState from "./SenseDevicesState";

const initialState: SenseDevicesState = {
  isLoading: false,
  isActivationStatusChanging: false,
  devices: [],
  error: undefined,
  selectedDevice: undefined,
};

const senseDevicesReducer = (
  state = initialState,
  action: SenseDeviceAction
): SenseDevicesState => {
  switch (action.type) {
    case LOAD_SENSE_DEVICES_TYPE:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        selectedDevice: undefined,
      };
    case SENSE_DEVICES_LOADED_TYPE:
      return {
        ...state,
        isLoading: false,
        selectedDevice: undefined,
        error: undefined,
        devices: action.devices,
      };
    case LOAD_SENSE_DEVICES_ERROR_TYPE:
      return {
        ...state,
        isLoading: false,
        selectedDevice: undefined,
        devices: [],
        error: action.error,
      };
    case LOAD_SELECTED_SENSE_DEVICE_TYPE:
      return {
        ...state,
        isLoading: true,
        selectedDevice: undefined,
        error: undefined,
      };
    case SELECTED_SENSE_DEVICE_LOADED_TYPE:
      return {
        ...state,
        isLoading: false,
        selectedDevice: action.selectedDevice,
        error: undefined,
      };
    case LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE:
      return {
        ...state,
        isLoading: false,
        selectedDevice: undefined,
        devices: [],
        error: action.error,
      };

    case CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE:
      return {
        ...state,
        isActivationStatusChanging: true,
      };
    case SENSE_DEVICE_ACTIVATION_STATUS_CHANGED_TYPE:
      return {
        ...state,
        isActivationStatusChanging: false,
        selectedDevice: {
          readings: state.selectedDevice?.readings || [],
          device: action.updatedDevice,
        },
        error: undefined,
      };
    case SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR_TYPE:
      return {
        ...state,
        isActivationStatusChanging: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default senseDevicesReducer;
