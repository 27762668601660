import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../redux/RootState";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import PopoverMenuItem from "./PopoverMenuItem";
import { resendInvite } from "../redux/PendingInviteMenuAction";

interface ExternalProps {
  invite: PendingInvite;
  handleSuccess: () => void;
}

interface ReduxStateProps {
  inviteSent: boolean;
  isResendingInvite: boolean;
  resendError: string | undefined;
}

interface ReduxDispatchProps {
  resendInvite: () => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps;

interface State {
  errorMessage: string | undefined;
}

class ResendInviteButton extends React.Component<Props, State> {
  state = {
    errorMessage: undefined as string | undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.resendError !== prevProps.resendError) {
      this.setState({ errorMessage: this.props.resendError });
    }

    if (this.props.inviteSent && !prevProps.inviteSent) {
      this.props.handleSuccess();
    }
  }

  render() {
    if (this.state.errorMessage !== undefined) {
      return <PopoverMenuItem text={this.state.errorMessage} type="error" />;
    }

    return (
      <PopoverMenuItem
        text="pendingInviteMenu.resendEmail"
        loading={this.props.isResendingInvite}
        onClick={this.handleClick}
      />
    );
  }

  handleClick = () => {
    if (!this.props.isResendingInvite) {
      this.props.resendInvite();
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    inviteSent: state.pendingInviteMenu.resendInvite.success,
    isResendingInvite: state.pendingInviteMenu.resendInvite.loading,
    resendError: state.pendingInviteMenu.resendInvite.error,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: ExternalProps
): ReduxDispatchProps => {
  return {
    resendInvite: () => dispatch(resendInvite(props.invite)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendInviteButton);
