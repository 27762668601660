// This is placeholder lorem ipsum for now.

const termsAndConditions = `
PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE ADEY SENSE SERVICES AND THIS SITE
Terms of Use
These terms of use (together with the documents referred to in them) outline the terms under which ADEY Innovation Limited (together with any of its group or affiliated companies) (referred to in these terms as we, us, our, ADEY) permit you to subscribe to the ADEY Sense services which will enable you to: (a) manage your ProClub account (UK users only); (b) setup, register and monitor any ADEY Sense filters (such as the MagnaClean Professional3 Sense – UK users only) you have installed; and (c) store and access test results that you have submitted via the ADEY ProCheck mobile phone app (the "Sense Services"), by logging on to the online portal which can be found at www.sense.adey.com (the "Site").  Any data uploaded and stored via the Sense Services, such as filter registrations and readings and ProCheck test results, is referred to as “Sense Data”.
You will then be able to send copies of your Sense Data to your customers, should you wish to do so.
A full description of the Sense Services can be found at the Site.
We reserve the right to add and/or change features and functionality provided as part of the Sense Services without notice to you.
Please read these terms of use carefully before you start to use our Sense Services and the Site, as these will apply to your use of them. We recommend that you print a copy of these for future reference.
By using the Sense Services and the Site, you confirm that you accept these terms of use and that you agree to comply with them.
If you do not agree to these terms of use, you may not make use of our Sense Services or the Site.
Changes to These Terms
We may revise these terms of use at any time by amending this page.
Please check this page from time to time to take notice of any changes we make, as they are binding on you.
Changes to the Site
We may update the Site from time to time and may change the content of it at any time. However, please note that any of the content on the Site may be out of date at any given time, and we are under no obligation to update it.
We do not guarantee that the Site, or any content on it, will be free from errors or omissions.
Registering On the Site
Save for the aspects of the Site that require a valid, paid for account, known as the Sense Services, the Site is provided free of charge.
To use the Site, including the Sense Services, you will need to register by providing:
       - Your full name;
       - Your full address;
       - Your email address; and
       - Your contact telephone number.
Upon submitting the above information, you will be requested to create a password, unique to you, which you will use to access your personal section of the Site. This password must be kept confidential to you only and must not be shared with anyone else. If we have reason to believe that you have provided your login details to someone else, or your account has been compromised, we reserve the right to suspend your access to the Site. We will not be liable for any losses that may arise as a result of this suspension.
By registering an account, you will be able to use the same login details across all of ADEY’s online services and applications without the need for reregistering. 
Access to Sense Services
The level of Sense Services that you will have access to depends upon your ‘Account Level’. There are 3 ‘Account Levels’ available, as follows:
Company. The ‘Company Account Level’ offers the broadest range of functionality and will be applied to:
1.	Any engineer that registers for an account for the first time for their company, or as a sole trader; or
2.	Any company that employs, or subcontracts to, engineers (including Engineers). 
The ‘Company Account Level’ will enable the account administrators to invite Engineers to be linked to the Company’s account and will give the account holder access to all tests/sense filter registrationscompleted by any Engineer it has approved to use the Company’s account.
To subscribe as a Company, the company must:
(a)	Have purchased a ProCheck Kit, or ADEY Sense filter, and entered into the Site the unique activation code linked to that ProCheck Kit or ADEY Sense filter. This will provide you with access to the Sense Services for the length of time outlined on the ProCheck Kit or ADEY Sense filter you have purchased. For the avoidance of doubt, only one activation code may be used per account; or
(b)	be party to a current and subsisting agreement with ADEY for the purchase of ProCheck Kits on a repeating basis (i.e., monthly direct debit or invoice).
In respect of Company accounts registered under heading (a) above, once the access period outlined on the ProCheck Kit or ADEY Sense filter you have purchased and used to register the account has expired you will be required to purchase access to the Sense Services via the Site at the price outlined at the time of the subscription purchase. Any such purchase shall be valid for the term for which you purchased and is renewable at the end of each term.
ADEY will suspend the Company’s account upon:
(a)	the expiry of any account access to the Sense Services; or
(b)	the suspension or termination of the agreement with ADEY for the purchase of ProCheck Kits;
and ADEY will not be liable to you, or any Engineers authorised via the Company’s account, in any way as a result of you not being able to access any Sense Data due to your account access to the Sense Services having expired, lapsed, being suspended or cancelled.
Engineer. The ‘Engineer Account Level’ is for installers working on behalf of a Company. The ‘Engineer Account Level’ will give the user access to Sense Data in the account of any Company they are working for and have been authorised by.
To subscribe as an Engineer you will need to be employed by, or be an approved contractor to, a Company that has a ‘Company Level Account’ to the Sense Services. In such circumstances your access to the Sense Services will be controlled by the Company and you should contact the relevant person within that Company to arrange for access to the Sense Services.  For the avoidance of all doubt, there is no limit to the number of Companies that you can be linked to and authorised by.
Please note, where your account access is provided for you by a Company then any Sense Data you upload onto the Site will be under the control of  the Company and not yours. If your access to the Sense Services is removed by the Company you are authorised by, or by you at your request, you will not be able to access any Sense Data that  were uploaded whilst your account access was authorised by that Company. You will still be able to access any Sense Data uploaded when you were acting as an Engineer for any other Company you are authorised by, subject to that Company maintaining its own ‘Company Level Account’ to the Sense Services.
To manage your account each Engineer will need to be assigned to a Company.
ADEY will not be liable to you in any way as a result of you not being able to access any Sense Data due to your account access to the Sense Services having expired, lapsed, being suspended or cancelled.
Monitoring. The ‘Monitoring Account Level’ is for house builders, housing associations, councils and social housing providers. The ‘Monitoring Account Level’ will give the user access to all Sense Data uploaded in respect of properties owned, run, or managed by the account holders.
To subscribe to a Monitoring account ADEY will agree with you a tariff for access to the account based on the amount of Sense Data you wish to include within your account access. Once you have reached the allotted amount of Sense Data you have paid for as per your tariff ADEY will not allow you to access any further Sense Data until a revised tariff has been agreed and paid for.
ADEY will not be liable to you in any way as a result of you not being able to access any Sense Data due to your account access to the Sense Services having expired, lapsed, being suspended or cancelled.
Access to the Site
We do not guarantee that the Site, or any content on it, including current or previous Sense Data, will always be available or be uninterrupted.
We may suspend, withdraw, discontinue or change all or any part of the Site without notice.
We will not be liable to you if for any reason the Site is unavailable at any time or for any period.
You are responsible for making all arrangements necessary for you to have access to the Site.
You are also responsible for ensuring that all persons who access the Site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
Intellectual Property Rights
We are the owner or the licensee of all intellectual property rights in the Site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
The Sense Data belongs to ADEY and obtaining account access to the Sense Services means that ADEY is granting to you a worldwide, exclusive (but not to the exclusion of ADEY), revocable and terminable licence to any Sense Data that  fall within the scope of the Account Level for which you have subscribed. This licence shall automatically terminate upon the suspension or termination of your account access.
In the event that your account or account access is suspended, or terminated, ADEY makes no guarantees that it will be able to restore any or all data that you may have had access to prior to your account or Account access having been suspended or terminated.
No Reliance on Information
Although we make reasonable efforts to update the information on the Site, we make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up-to-date.
Data Protection
By supplying personal data to us you are warranting that you have the right to supply such information to us and that we have the right to process it on your behalf for the purposes of supplying the Sense Data to you.
We warrant that we will process all personal data relating to you, Engineers, or your customers, supplied to us via the Site and the Services, in accordance with all relevant data protection laws, regulations and codes of practice and that we will not pass the personal data you supply to any third party outside of ADEY without yours, or the data subject’s, prior consent.
Where we process any personal data on behalf of the data owner when performing our obligations, the data owner shall be the controller of that data and we shall be the processor.
The party that uploads any data to the Site will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to us for the duration and purposes of this agreement so that we may lawfully use, process and transfer the personal data in accordance with this agreement on the data owner’s behalf.
We shall, in relation to any personal data processed in connection with the performance by us of our obligations under this agreement:
       (a) process that personal data only on the data owner’s instructions unless we are required by the laws of any member of the European Union or by the laws of the European Union applicable to us and/or any relevant UK Law to process personal data.
       (b) not transfer any personal data outside of the European Economic Area unless the following conditions are fulfilled:
              (i) we have put in place and obtained appropriate safeguards in relation to the transfer;
              (ii) the data subject has enforceable rights and effective legal remedies;
              (iii) we comply with our obligations under the relevant data protection legislation by providing an adequate level of protection to any personal data that is transferred; and
              (iv) we comply with reasonable instructions notified to us in advance by the data owner with respect to the processing of the personal data;
       (c) assist the data owner, at the data owner's cost, in responding to any request from a data subject and in ensuring compliance with the data owner’s obligations under the relevant data protection legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;
       (d) notify the data owner without undue delay on becoming aware of a personal data breach;
       (e) at the written direction of the data owner, delete or return personal data and copies thereof to the data owner on termination of the agreement unless required by any relevant law to store the personal data (and for these purposes the term "delete" shall mean to put such data beyond use); and
       (f) maintain complete and accurate records and information to demonstrate our compliance with these provisions immediately inform the data owner if, in our opinion, an instruction infringes any relevant data protection legislation.
All parties to these terms shall ensure that they have in place appropriate technical and organisational measures to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measure).
We confirm that where we make use of any third-party processors, we have entered or (as the case may be) will enter with the third-party processor into a written agreement incorporating terms which are substantially similar to those set out above. As between ADEY and the data owner, we shall remain fully liable for all acts or omissions of any third-party processor appointed by us.
Limitation of Our Liability
Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
To the extent permitted by law, we exclude all conditions, warranties, representations, or other terms which may apply to the Sense Services and/or the Site or any content on it, whether express or implied.
We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
       - any information, results, figures or recommendations given by us in the Sense Data or derived from your use of the Sense Services and the Site;
       - any delay in payment you may receive from your customer(s) for works performed that arises due to the Sense Data not meeting your customer’s minimum standards or requirements or, as a result of the figures or recommendations given as part of the Sense Data; or
       - use of, or inability to use, the Site.
Where you are a business user, please note that in particular, we will not be liable for:
       - loss of profits, sales, business, or revenue;
       - the cost of obtaining further ProCheck Kits should any ProCheck test fail or not produce the results expected or required;
       - your time in having to revisit sites and obtain further samples for testing should previous samples be rejected or test results indicate that further tests should be undertaken;
       - business interruption;
       - loss of anticipated savings;
       - loss of business opportunity, goodwill or reputation; or
       - any indirect or consequential loss or damage.
If you are a consumer user, please note that we only provide the Sense Services and the Site for guidance only and we strongly recommend that should you have any concerns about the system you are testing, you seek professional advice as a matter of urgency.
We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Site or to your downloading of any content on it, or on any website linked to it.
Viruses
We do not guarantee that the Site will be secure or free from bugs or viruses.
You are responsible for configuring your information technology, computer programmes and platform in order to access the Site. You should use your own virus protection software.
Applicable Law
If you are a consumer, please note that these terms of use, their subject matter and its formation, are governed by English law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.
If you are a business, these terms of use, their subject matter and formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
`;

export default termsAndConditions;
