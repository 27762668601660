import { DateTime } from "luxon";

const toLocalUTCDateTime = (date: string, fullDate: boolean = true) => {
  const testDate = DateTime.fromISO(date, { setZone: true });

  // Switched to Luxon to get UTC at the time of measurement
  const timeZone = testDate.zoneName;

  const formattedTestDate = fullDate
    ? testDate.toISODate()
    : testDate.toLocaleString(DateTime.DATE_SHORT);

  const formattedTestTime = `${
    testDate.get("hour").toLocaleString().length > 1
      ? testDate.get("hour")
      : "0" + testDate.get("hour")
  }:${
    testDate.get("minute").toLocaleString().length > 1
      ? testDate.get("minute")
      : "0" + testDate.get("minute")
  }`;

  return `${formattedTestDate} ${formattedTestTime} ${fullDate ? `(${timeZone})` : ""}`;
};

export default toLocalUTCDateTime;
