import { Grid } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ArrowIcon from "../../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import PrimaryTextField, {
  FieldState,
} from "../../../../../ui/components/PrimaryTextField";
import PhoneNumberTextField from "../../../../../ui/components/PhoneNumberTextField";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import validatePersonalInfoPage from "./validatePersonalInfoPage";
import PersonalInfoFormData from "../../../../../rest/user/model/PersonalInfoFormData";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";

interface ExternalProps {
  isLoading: boolean;
  error: string | undefined;
  onSubmit: (form: PersonalInfoFormData) => void;
}

type Props = WithTranslation & ExternalProps;

export interface PersonalInfoPageState {
  firstName: FieldState;
  surname: FieldState;
  contactNumber: FieldState;
  confirmNumber: FieldState;
  addressPostcode: FieldState;
}

class PersonalInfoPage extends React.Component<Props, PersonalInfoPageState> {
  state = {
    firstName: { value: "" },
    surname: { value: "" },
    contactNumber: { value: "" },
    confirmNumber: { value: "" },
    addressPostcode: { value: "" },
  };

  render() {
    const title = this.props.t("personalInfoPage.title");
    const firstNameFieldHint = this.props.t("personalInfoPage.firstNameHint");
    const surnameFieldHint = this.props.t("personalInfoPage.surnameHint");
    const contactNumberFieldHint = this.props.t("personalInfoPage.contactNumberHint");
    const continueButton = this.props.t("personalInfoPage.continueButton");
    const confirmNumberFieldHint = this.props.t("personalInfoPage.confirmNumberHint");
    const postcodeHintField = this.props.t("personalInfoPage.postcodeHint");

    return (
      <CenteredAuthCard title={title} includeSignOut>
        <Grid container spacing={SMALL_SPACING} direction="column">
          <Grid item />
          <Grid item />

          <Grid item>
            <PrimaryTextField
              type="text"
              disabled={this.props.isLoading}
              {...this.state.firstName}
              hint={firstNameFieldHint}
              onChange={this.handleFirstNameChanged}
              fullWidth
            />
          </Grid>

          <Grid item>
            <PrimaryTextField
              type="text"
              disabled={this.props.isLoading}
              {...this.state.surname}
              hint={surnameFieldHint}
              onChange={this.handleSurnameChanged}
              fullWidth
            />
          </Grid>

          <Grid item>
            <PhoneNumberTextField
              disabled={this.props.isLoading}
              {...this.state.contactNumber}
              hint={contactNumberFieldHint}
              onChange={this.handleContactNumberChanged}
              fullWidth
            />
          </Grid>

          <Grid item>
            <PhoneNumberTextField
              disabled={this.props.isLoading}
              {...this.state.confirmNumber}
              hint={confirmNumberFieldHint}
              onChange={this.handleConfirmNumberChanged}
              fullWidth
            />
          </Grid>

          <Grid item>
            <PrimaryTextField
              disabled={this.props.isLoading}
              {...this.state.addressPostcode}
              hint={postcodeHintField}
              onChange={this.handlePostcodeChanged}
              fullWidth
            />
          </Grid>

          <Grid item />

          <Grid item>
            <PrimaryButton
              label={continueButton}
              endIcon={<ArrowIcon />}
              onClick={this.handleContinueButtonPressed}
              isLoading={this.props.isLoading}
              fullWidth
            />
          </Grid>
        </Grid>

        <ErrorSnackbarHandler message={this.props.error} />
      </CenteredAuthCard>
    );
  }

  // Handlers

  handleFirstNameChanged = (value: string) => {
    this.setState({ firstName: { value: value } });
  };

  handleSurnameChanged = (value: string) => {
    this.setState({ surname: { value: value } });
  };

  handleContactNumberChanged = (value: string) => {
    this.setState({ contactNumber: { value: value } });
  };

  handleConfirmNumberChanged = (value: string) => {
    this.setState({ confirmNumber: { value: value } });
  };

  handlePostcodeChanged = (value: string) => {
    this.setState({ addressPostcode: { value: value } });
  };

  handleContinueButtonPressed = () => {
    if (this.validate()) {
      const form: PersonalInfoFormData = {
        firstName: this.state.firstName.value,
        lastName: this.state.surname.value,
        phoneNumber: this.state.contactNumber.value,
        addressPostcode: this.state.addressPostcode.value,
        termsAccepted: true,
      };
      this.props.onSubmit(form);
    }
  };

  // Validation

  validate = (): boolean => {
    const result = validatePersonalInfoPage(this.state, this.props.t);
    this.setState(result.newState);
    return result.isValid;
  };
}

export default withTranslation()(PersonalInfoPage);
