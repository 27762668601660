import { TestOutcome } from "../../../../rest/procheck/model/ProCheckResultDetails";

const getCombinedTestOutcome = (outcomes: TestOutcome[]): TestOutcome => {
  if (outcomes.includes("fail")) {
    return "fail";
  } else if (outcomes.includes("out of range")) {
    return "out of range";
  } else if (outcomes.length > 0) {
    return "pass";
  }

  return "fail";
};

export default getCombinedTestOutcome;
