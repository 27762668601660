import { Box } from "@material-ui/core";
import React from "react";
import ColoredText from "../../ColoredText";

interface Props {
  size: number;
  value: number;
  textColor: string;
}

// The "50%" label in the center.
const PieChartCenterLabel = (props: Props) => {
  return (
    <Box
      position="absolute"
      width={props.size}
      height={props.size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={3}
    >
      <Box display="flex">
        {/* Add a transparent % on the lhs to center it */}
        <ColoredText style={{ userSelect: "none" }} textColor="transparent">
          %
        </ColoredText>
        <ColoredText textColor={props.textColor} variant="h4">
          {props.value?.toFixed(0)}
        </ColoredText>
        <ColoredText style={{ userSelect: "none" }} textColor={props.textColor}>
          %
        </ColoredText>
      </Box>
    </Box>
  );
};

export default PieChartCenterLabel;
