import { AxiosInstance, AxiosRequestConfig } from "axios";
import ExportItemModel from "../../ui/components/ExportBar/models/ExportItemModel";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import ExportRepository from "./ExportRepository";

class RestExportRepository implements ExportRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  //Initialise item export
  initialiseOverviewExport = (
    org_id: string,
    isAdmin: boolean,
    startDate: string,
    endDate: string,
    property_ids?: string[]
  ): Promise<ExportItemModel> => {
    const config: AxiosRequestConfig = {
      params: {
        ...(property_ids !== undefined && {
          property_ids: property_ids,
        }),
        startDate: startDate,
        endDate: endDate,
      },
    };

    const admin = isAdmin ? "/admin" : "";
    const url = `${admin}/organisations/${org_id}/reports/properties-overview`;

    return this.axios.get<DataWrapper<ExportItemModel>>(url, config).then(unwrapResponse);
  };

  //Initialise ProCheck Report
  initialiseProCheckExport = (
    org_id: string,
    isAdmin: boolean,
    result_ids?: string[],
    remove_personal_information?: boolean,
    languageHeader?: string
  ): Promise<ExportItemModel> => {
    const exportLanguageInterceptor = !!languageHeader
      ? this.axios.interceptors.request.use(async (request) => {
          request.headers["Accept-Language"] = languageHeader;
          return request;
        })
      : undefined;

    const params = new URLSearchParams();

    params.append("result_ids", result_ids?.join(",") || "");
    params.append("remove_personal_information", remove_personal_information ? "1" : "0");

    const admin = isAdmin ? "/admin" : "";
    const url = `${admin}/organisations/${org_id}/procheck/download`;

    return this.axios
      .post<DataWrapper<ExportItemModel>>(url, params)
      .then((value) => {
        if (!!exportLanguageInterceptor) {
          this.axios.interceptors.request.eject(exportLanguageInterceptor);
        }
        return value;
      })
      .then(unwrapResponse);
  };

  //Initialise global item export
  initialiseGlobalExport = (): Promise<ExportItemModel> => {
    const url = `/admin/reports/all-products`;
    return this.axios.get<DataWrapper<ExportItemModel>>(url).then(unwrapResponse);
  };

  //Check item export status
  fetchExportStatus = (
    report_id: string,
    isAdmin: boolean,
    org_id: string
  ): Promise<ExportItemModel> => {
    const admin = isAdmin ? "/admin" : `organisations/${org_id}`;
    const url = `${admin}/media/${report_id}`;

    return this.axios.get<DataWrapper<ExportItemModel>>(url).then(unwrapResponse);
  };

  //Download exported item
  downloadGeneratedItem = (report_url: string): Promise<void> => {
    //Not robust at this moment - good way to expand would be to handle any format,
    // or try to get it from the response - need discussion with Chris B.
    return this.axios
      .get(report_url, {
        headers: {
          "Content-Type": "application/xml",
        },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "report.csv"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };
}

export default RestExportRepository;
