import { Box, Card, useMediaQuery } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import RAGStatus from "../../../../pages/properties/PropertyListPage/model/RAGStatus";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../theme/dimensions";
import AnimatedPieChart from "../../AnimatedPieChart/AnimatedPieChart";
import { DashboardMetrics } from "../../DashboardLayout/redux/DashboardState";
import createRAGCardData from "../utils/createRAGCardData";
import getRAGCardTotal from "../utils/getRAGCardTotal";
import RAGCardText from "./RAGCardText";
import RAGInfoButton from "./RAGInfoButton";

const cardHeight = 170;
const cardWidth = 300;

export type RAGCardType = RAGStatus | "total";

interface Props extends WithTranslation {
  type: RAGCardType;
  animated: boolean;
  active: boolean;

  metrics: DashboardMetrics | undefined;
}

const RAGCard = (props: Props) => {
  const cardStyle = {
    margin: LARGE_SPACING,
    width: cardWidth,
    height: cardHeight,
    opacity: props.active ? 1 : 0.5,
  };
  const pieChartSize = cardHeight - EXTRA_LARGE_SPACING * 2;
  const elevation = props.active ? 12 : 0;

  const total = props.metrics ? getRAGCardTotal(props.type, props.metrics) : undefined;
  const data = props.metrics ? createRAGCardData(props.type, props.metrics) : undefined;

  // Don't show the pie chart if there isn't enough space for it
  const showPieChart = useMediaQuery("(min-width: 1200px)");

  return (
    <Card style={cardStyle} elevation={elevation}>
      <Box
        display="flex"
        height="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <RAGCardText type={props.type} total={total} fullWidth={showPieChart} />

        {showPieChart ? (
          <Box marginX={2}>
            <AnimatedPieChart data={data} size={pieChartSize} animated={props.animated} />
          </Box>
        ) : undefined}

        <RAGInfoButton type={props.type} />
      </Box>
    </Card>
  );
};

export default withTranslation()(RAGCard);
