import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ReactLinkify from "react-linkify";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { FORGOT_PASSWORD_EMAIL_SENT_ROUTE, LOGIN_ROUTE } from "../../../routes/routes";
import ArrowIcon from "../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../ui/components/PrimaryTextField";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../ui/theme/dimensions";
import ResetPasswordAccountActivationPopup from "./components/ResetPasswordAccountActivationPopup";
import { forgotPasswordRequest } from "./redux/ForgotPasswordAction";

interface ReduxStateProps {
  isLoading: boolean;
  success: boolean;
  error: string | undefined;
}

interface ReduxDispatchProps {
  submitForgotPassword: (email: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

interface State {
  email: FieldState;
}

class ForgotPasswordRequestPage extends React.Component<Props, State> {
  state = {
    email: { value: "" },
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.success && this.props.success !== prevProps.success) {
      this.props.history.push(FORGOT_PASSWORD_EMAIL_SENT_ROUTE);
    }
  }

  render() {
    const title = this.props.t("forgotPassword.requestPageTitle");
    const subtitle = this.props.t("forgotPassword.requestPageSubtitle");
    const content = this.props.t("forgotPassword.content");
    const emailHint = this.props.t("forgotPassword.emailHint");
    const resetButton = this.props.t("forgotPassword.requestButton");
    const returnToSignInButton = this.props.t("forgotPassword.signInPage");

    return (
      <CenteredAuthCard title={title}>
        <Grid container spacing={DEFAULT_SPACING} direction="column">
          <Grid item style={{ marginTop: SMALL_SPACING }}>
            <Typography variant="subtitle1" color="secondary" align="center">
              {subtitle.toLocaleUpperCase()}
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: 0, paddingTop: SMALL_SPACING }}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              <ReactLinkify>{content}</ReactLinkify>
            </Typography>
          </Grid>

          <Grid item>
            <PrimaryTextField
              {...this.state.email}
              type="email"
              onChange={this.handleEmailChanged}
              hint={emailHint}
              fullWidth
              disabled={this.props.isLoading}
            />
          </Grid>

          <Grid item>
            <PrimaryButton
              label={resetButton}
              fullWidth
              endIcon={<ArrowIcon />}
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
            />
          </Grid>

          <Grid item justify="center">
            <Box display="flex" alignItems="center" justifyContent="center">
              <SecondaryButton
                label={returnToSignInButton}
                onClick={this.handleSignInPressed}
              />
            </Box>
          </Grid>
        </Grid>

        <ErrorSnackbarHandler message={this.props.error} />

        <ResetPasswordAccountActivationPopup />
      </CenteredAuthCard>
    );
  }

  // Handlers

  handleSignInPressed = () => {
    this.props.history.push(LOGIN_ROUTE);
  };

  handleSubmit = () => {
    if (this.validate()) {
      this.props.submitForgotPassword(this.state.email.value);
    }
  };

  handleEmailChanged = (value: string) => {
    this.setState({
      email: { value: value },
    });
  };

  // MARK: Validation

  validate = (): boolean => {
    const email = this.state.email.value;
    if (email.length === 0) {
      this.setState({
        email: {
          value: email,
          errorText: this.props.t("forgotPassword.emailRequiredError"),
        },
      });
      return false;
    } else if (!email.includes("@")) {
      this.setState({
        email: {
          value: email,
          errorText: this.props.t("forgotPassword.emailInvalidError"),
        },
      });
      return false;
    }
    return true;
  };
}

const mapPropsToState = (state: RootState): ReduxStateProps => {
  return {
    isLoading: state.forgotPassword.isLoading,
    error: state.forgotPassword.error,
    success: state.forgotPassword.requestedSuccessfully,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    submitForgotPassword: (email) => dispatch(forgotPasswordRequest(email)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withTranslation()(withRouter(ForgotPasswordRequestPage)));
