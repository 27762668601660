import { delay, put, select, takeLatest } from "redux-saga/effects";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import RootState from "../../../../redux/RootState";
import getDependency from "../../../../redux/utils/getDependency";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import Organisation from "../../../../rest/organisation/model/Organisation";
import Page from "../../../../rest/utils/Page";
import { CREATE_ORG_SUCCESS_TYPE } from "../../AdminInviteOrganisationDialog/redux/AdminCreateOrganisationAction";
import { AGENT_ACCESS_GRANTED_TYPE } from "../../AdminOrganisationDetailsPage/redux/AdminOrganisationDetailsAction";
import { AGENT_ACCESS_REVOKED_TYPE } from "../../PlatformAgentDetailsPage/redux/PlatformAgentDetailsActions";
import {
  AdminAccessChangedAction,
  ADMIN_ACCESS_CHANGED_TYPE,
} from "../../redux/AdeyAdminAction";
import {
  loadPlatformAgents,
  LOAD_PLATFORM_AGENTS_TYPE,
  platformAgentsError,
  AGENT_SEARCH_TEXT_CHANGED_TYPE,
  platformAgentsLoaded,
} from "./PlatformAgentListAction";

function* platformAgentListSagas() {
  yield takeLatest(ADMIN_ACCESS_CHANGED_TYPE, adminAccessChangedSaga);

  // Load the current page if...
  yield takeLatest(
    [
      LOAD_PLATFORM_AGENTS_TYPE, // the page loads
      CREATE_ORG_SUCCESS_TYPE, // a new org is created by adey
      AGENT_ACCESS_REVOKED_TYPE, // agent access revoked
      AGENT_ACCESS_GRANTED_TYPE, // an agent is assigned
    ],
    loadPlatformAgentsSaga
  );
  yield takeLatest(AGENT_SEARCH_TEXT_CHANGED_TYPE, searchTextChangedSaga);
}

function* adminAccessChangedSaga(action: AdminAccessChangedAction) {
  if (action.hasAdminAccess) {
    yield put(loadPlatformAgents(1));
  }
}

function* searchTextChangedSaga() {
  yield delay(150);
  yield loadPlatformAgentsSaga();
}

function* loadPlatformAgentsSaga() {
  try {
    const rootState: RootState = yield select();
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    const page = rootState.platformOrganisationList.currentPage;
    const searchText = rootState.platformOrganisationList.searchText;

    const agentOrgResponse: Page<Organisation[]> = yield adminRepo.fetchAllAgents(
      searchText,
      page
    );

    const agents = agentOrgResponse.data;
    const currentPage = agentOrgResponse.meta.current_page;
    const maxPage = agentOrgResponse.meta.last_page;

    yield put(platformAgentsLoaded(agents, currentPage, maxPage));
  } catch (e) {
    const message = getErrorMessage(e, "platformAgentList.loadError");
    yield put(platformAgentsError(message));
  }
}

export default platformAgentListSagas;
