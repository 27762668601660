import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

const maxButtonsVisible = 10;

const disabledStyle = {
  width: 40,
  height: 40,
};

const enabledStyle = {
  ...disabledStyle,
  color: BACKGROUND_ACCENT_COLOR,
};

interface Props {
  maxPages: number;
  selectedPage: number;
  onPageNumberChanged: (pageNumber: number) => void;
}

const TablePageIndicator = (props: Props) => {
  const buttons = buildButtons(
    props.maxPages,
    props.selectedPage,
    props.onPageNumberChanged
  );

  return (
    <Box display="flex" justifyContent="center">
      <ArrowButton
        key="back"
        type="back"
        enabled={props.selectedPage > 1}
        onClick={() => {
          props.onPageNumberChanged(props.selectedPage - 1);
        }}
      />

      {buttons}

      <ArrowButton
        key="forward"
        type="forward"
        enabled={props.selectedPage < props.maxPages}
        onClick={() => {
          props.onPageNumberChanged(props.selectedPage + 1);
        }}
      />
    </Box>
  );
};

const buildButtons = (
  maxPages: number,
  selectedPage: number,
  onPageNumberChanged: (pageNumber: number) => void
) => {
  // Handle special case where only one button is required.
  if (maxPages === 1) {
    return (
      <PageButton
        key={1}
        pageNumber={1}
        currentPage={true}
        onClick={onPageNumberChanged}
      />
    );
  }

  // An array of every page. 1...n
  // taken from here https://stackoverflow.com/a/33352604/11528182
  const allPages = Array.from(Array(maxPages).keys()).map((n) => n + 1);

  // Slice an array of length `maxButtonsVisible` centered on `selectedPage`
  const sliceStart = Math.max(selectedPage - maxButtonsVisible / 2, 0);
  const sliceEnd = Math.min(selectedPage + maxButtonsVisible / 2, maxPages);
  const pagesToDisplay = allPages.slice(sliceStart, sliceEnd);

  // Map each of the pages to components
  let buttons = pagesToDisplay.map((pageNumber) => (
    <PageButton
      key={pageNumber}
      pageNumber={pageNumber}
      currentPage={pageNumber === selectedPage}
      onClick={onPageNumberChanged}
    />
  ));

  // If page 1 isn't visible, add it behind an elipsis
  if (!pagesToDisplay.includes(1)) {
    buttons = [
      <PageButton
        key={1}
        pageNumber={1}
        currentPage={selectedPage === 1}
        onClick={onPageNumberChanged}
      />,

      // Include an ellipsis if there are skipped buttons
      pagesToDisplay.includes(2) ? (
        <div key="div_1" />
      ) : (
        <EllipsisButton key="ellipsis_1" />
      ),

      ...buttons,
    ];
  }

  if (!pagesToDisplay.includes(maxPages)) {
    buttons = [
      ...buttons,

      // Include an ellipsis if there are skipped buttons
      pagesToDisplay.includes(maxPages - 1) ? (
        <div key="div_2" />
      ) : (
        <EllipsisButton key="ellipsis_2" />
      ),

      <PageButton
        key={maxPages}
        pageNumber={maxPages}
        currentPage={selectedPage === maxPages}
        onClick={onPageNumberChanged}
      />,
    ];
  }

  return buttons;
};

const PageButton = (props: {
  pageNumber: number;
  currentPage: boolean;
  onClick: (pageNumber: number) => void;
}) => {
  const text = props.currentPage ? <strong>{props.pageNumber}</strong> : props.pageNumber;

  return (
    <IconButton
      style={enabledStyle}
      disabled={props.currentPage}
      onClick={() => props.onClick(props.pageNumber)}
    >
      <Typography>{text}</Typography>
    </IconButton>
  );
};

const ArrowButton = (props: {
  key: string;
  type: "forward" | "back";
  enabled: boolean;
  onClick: () => void;
}) => {
  const style = props.enabled ? enabledStyle : disabledStyle;

  return (
    <IconButton disabled={!props.enabled} style={style} onClick={props.onClick}>
      {props.type === "forward" ? <ArrowForward /> : <ArrowBack />}
    </IconButton>
  );
};

const EllipsisButton = (props: { key: string }) => {
  return (
    <IconButton style={enabledStyle} disabled>
      <Typography>...</Typography>
    </IconButton>
  );
};

export default TablePageIndicator;
