import { Box, Button } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import RootState from "../../../../redux/RootState";
import { TERMS_AND_CONDITION_ROUTE } from "../../../../routes/routes";
import { BACKGROUND_ACCENT_COLOR } from "../../../theme/createMaterialTheme";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../theme/dimensions";
import ColoredText from "../../ColoredText";
import DeveloperZonePopup from "../../DeveloperZonePopup";
import LanguageSwitcher from "../../LanguageSwitcher";
import isOrgDevFeatureEnabled from "../../../../redux/utils/isOrgDevFeatureEnabled";

type Props = RouteComponentProps;

const CopyrightFooter = (props: Props) => {
  //Translation
  const { t } = useTranslation();

  //Year
  const currentYear = new Date().getFullYear();

  //Hooks
  const showDeveloperZone: boolean = useSelector(
    (store: RootState) =>
      (store.activeOrganisation.currentOrganisation?.role.name === "owner" ||
        store.activeOrganisation.currentOrganisation?.role.name === "developer") &&
      !store.adeyAdmin.hasAdminAccess &&
      !store.adeyAgent.hasAgentAccess &&
      isOrgDevFeatureEnabled(store.activeOrganisation.currentOrganisation.features || [])
  );

  return (
    <Box
      display="flex"
      paddingX={DEFAULT_SPACING}
      paddingBottom={SMALL_SPACING}
      justifyContent="space-between"
    >
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR}>
        {t("copyrightFooter.text", { date: currentYear })}
      </ColoredText>

      <Box display="flex">
        {showDeveloperZone && <DeveloperZonePopup />}

        <CopyrightFooterLink
          href={`${window.location.origin}${TERMS_AND_CONDITION_ROUTE}`}
        >
          <Trans>copyrightFooter.termsLink</Trans>
        </CopyrightFooterLink>

        <CopyrightFooterLink href="https://www.adey.com/privacy-policy">
          <Trans>copyrightFooter.privacyLink</Trans>
        </CopyrightFooterLink>

        <LanguageSwitcher />
      </Box>
    </Box>
  );
};

const CopyrightFooterLink = (props: { children: JSX.Element; href?: string }) => {
  return (
    <Button style={{ textTransform: "initial" }} href={props.href}>
      <ColoredText variant="body1" textColor={BACKGROUND_ACCENT_COLOR}>
        {props.children}
      </ColoredText>
    </Button>
  );
};

export default withRouter(CopyrightFooter);
