import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { loadCountryList } from "../../pages/countries/redux/CountryAction";
import RootState from "../../redux/RootState";
import { Country } from "../../rest/countries/model/Country";
import PrimaryTextField, { PrimaryTextFieldProps } from "./PrimaryTextField";

interface ReduxStateProps {
  countryList: Country[] | undefined;
  isLoading: boolean | undefined;
  error: string | undefined;
}

interface ReduxDispatchProps {
  loadCountryList: () => void;
}

interface ExternalProps extends PrimaryTextFieldProps {
  isAgent?: boolean;
}

type Props = ReduxStateProps & ReduxDispatchProps & ExternalProps;

const CountryPicker = (props: Props) => {
  useEffect(() => {
    if (props.countryList === undefined) {
      props.loadCountryList();
    }
  });

  const options = props.countryList
    ? props.countryList.map((country) => ({
        value: country.code,
        labelId: `countryCode.${country.code}`,
      }))
    : undefined;

  return <PrimaryTextField {...props} selectOptions={options} disabled={props.isAgent} />;
};

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  countryList: state.countryList.countryList,
  isLoading: state.countryList.isLoading,
  error: state.countryList.error,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadCountryList: () => dispatch(loadCountryList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryPicker);
