import PendingInvite, {
  ContractorInvitation,
  OrganisationUserInvitation,
} from "../../../rest/invites/model/PendingInvite";

// Fetch pending invites
export const FETCH_PENDING_INVITES_TYPE = "FETCH_PENDING_INVITES_TYPE";
export interface FetchPendingInvitesAction {
  type: typeof FETCH_PENDING_INVITES_TYPE;
}
export const fetchPendingInvites = (): FetchPendingInvitesAction => ({
  type: FETCH_PENDING_INVITES_TYPE,
});

// Fetch pending invites success
export const FETCH_PENDING_INVITES_SUCCESS_TYPE = "FETCH_PENDING_INVITES_SUCCESS_TYPE";
export interface FetchPendingInvitesSuccessAction {
  type: typeof FETCH_PENDING_INVITES_SUCCESS_TYPE;
  contractorInvites: ContractorInvitation[];
  userInvites: OrganisationUserInvitation[];
}
export const fetchPendingInvitesSuccess = (
  contractorInvites: ContractorInvitation[],
  userInvites: OrganisationUserInvitation[]
): FetchPendingInvitesSuccessAction => ({
  type: FETCH_PENDING_INVITES_SUCCESS_TYPE,
  contractorInvites: contractorInvites,
  userInvites: userInvites,
});

// Accept invite
export const ACCEPT_INVITE_TYPE = "ACCEPT_INVITE_TYPE";
export interface AcceptInviteAction {
  type: typeof ACCEPT_INVITE_TYPE;
  pendingInvite: PendingInvite;
}
export const acceptInvite = (pendingInvite: PendingInvite): AcceptInviteAction => ({
  type: ACCEPT_INVITE_TYPE,
  pendingInvite: pendingInvite,
});

// Accept invite success
export const ACCEPT_INVITE_SUCCESS_TYPE = "ACCEPT_INVITE_SUCCESS_TYPE";
export interface AcceptInviteSuccessAction {
  type: typeof ACCEPT_INVITE_SUCCESS_TYPE;
  acceptedInvite: PendingInvite;
}
export const acceptInviteSuccess = (
  pendingInvite: PendingInvite
): AcceptInviteSuccessAction => ({
  type: ACCEPT_INVITE_SUCCESS_TYPE,
  acceptedInvite: pendingInvite,
});

// Accept invite error
export const ACCEPT_INVITE_ERROR_TYPE = "ACCEPT_INVITE_ERROR_TYPE";
export interface AcceptInviteErrorAction {
  type: typeof ACCEPT_INVITE_ERROR_TYPE;
  error: string;
}
export const acceptInviteError = (error: string): AcceptInviteErrorAction => ({
  type: ACCEPT_INVITE_ERROR_TYPE,
  error: error,
});

// Reject invite
export const REJECT_INVITE_TYPE = "REJECT_INVITE_TYPE";
export interface RejectInviteAction {
  type: typeof REJECT_INVITE_TYPE;
  pendingInvite: PendingInvite;
}
export const rejectInvite = (pendingInvite: PendingInvite): RejectInviteAction => ({
  type: REJECT_INVITE_TYPE,
  pendingInvite: pendingInvite,
});

// Reject invite success
export const REJECT_INVITE_SUCCESS_TYPE = "REJECT_INVITE_SUCCESS_TYPE";
export interface RejectInviteSuccessAction {
  type: typeof REJECT_INVITE_SUCCESS_TYPE;
  acceptedInvite: PendingInvite;
}
export const rejectInviteSuccess = (
  pendingInvite: PendingInvite
): RejectInviteSuccessAction => ({
  type: REJECT_INVITE_SUCCESS_TYPE,
  acceptedInvite: pendingInvite,
});

// Accept invite error
export const REJECT_INVITE_ERROR_TYPE = "REJECT_INVITE_ERROR_TYPE";
export interface RejectInviteErrorAction {
  type: typeof REJECT_INVITE_ERROR_TYPE;
  error: string;
}
export const rejectInviteError = (error: string): RejectInviteErrorAction => ({
  type: REJECT_INVITE_ERROR_TYPE,
  error: error,
});

// Clear accepted invite
export const CLEAR_ACCEPTED_INVITE_FLAG_TYPE = "CLEAR_ACCEPTED_INVITE_FLAG_TYPE";
export interface ClearAcceptedInviteFlagAction {
  type: typeof CLEAR_ACCEPTED_INVITE_FLAG_TYPE;
}
export const clearAcceptedInviteFlag = (): ClearAcceptedInviteFlagAction => {
  return {
    type: CLEAR_ACCEPTED_INVITE_FLAG_TYPE,
  };
};

// Org owner state changed
export const ORG_OWNER_STATE_CHANGED_TYPE = "ORG_OWNER_STATE_CHANGED_TYPE";
export interface OrgOwnerStateChangedAction {
  type: typeof ORG_OWNER_STATE_CHANGED_TYPE;
  isOrganisationOwner: boolean;
}
export const orgOwnerStateChanged = (
  isOrganisationOwner: boolean
): OrgOwnerStateChangedAction => {
  return {
    type: ORG_OWNER_STATE_CHANGED_TYPE,
    isOrganisationOwner: isOrganisationOwner,
  };
};

type PendingInviteAction =
  | FetchPendingInvitesAction
  | FetchPendingInvitesSuccessAction
  | AcceptInviteAction
  | AcceptInviteErrorAction
  | AcceptInviteSuccessAction
  | RejectInviteAction
  | RejectInviteErrorAction
  | RejectInviteSuccessAction
  | ClearAcceptedInviteFlagAction
  | OrgOwnerStateChangedAction;

export default PendingInviteAction;
