import { Box, BoxProps } from "@material-ui/core";
import React from "react";
import { BORDER_COLOR } from "../theme/createMaterialTheme";
import { BORDER_RADIUS, SMALL_SPACING } from "../theme/dimensions";

type Props = Omit<BoxProps, "border" | "borderRadius">;

const BorderedBox = (props: Props) => {
  const padding = props.padding === undefined ? SMALL_SPACING : props.padding;

  return (
    <Box
      border={"1px solid " + BORDER_COLOR}
      borderRadius={BORDER_RADIUS}
      padding={padding}
      {...props}
    />
  );
};

export default BorderedBox;
