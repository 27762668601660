import { Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import ArrowIcon from "../../../ui/assets/ArrowIcon/ArrowIcon";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";
import { rejectInvite } from "../redux/PendingInviteAction";
import CreateOrganisationPanel from "../../organisations/create/CreateOrganisationPanel";

interface ExternalProps {
  pendingInvite: PendingInvite;
}

interface ReduxStateProps {
  isLoading: boolean;
  errorMessage: string | undefined;
}

interface ReduxDispatchProps {
  rejectInvite: (invite: PendingInvite) => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps & WithT;

interface State {
  isCreatingOrg: boolean;
}

class CreateOwnerOrganisationPromptPanel extends React.Component<Props, State> {
  state = {
    isCreatingOrg: false,
  };

  render() {
    if (this.state.isCreatingOrg) {
      return (
        <CreateOrganisationPanel
          onBackPressed={() => this.setState({ isCreatingOrg: false })}
        />
      );
    }

    const title = this.props.t("createOwnerOrganisationPromptPanel.title");
    const messageFormat = this.props.t(
      "createOwnerOrganisationPromptPanel.messageFormat"
    );
    const createOrganisationButton = this.props.t(
      "createOwnerOrganisationPromptPanel.createOrganisationButton"
    );
    const declineButton = this.props.t(
      "createOwnerOrganisationPromptPanel.declineButton"
    );

    const orgName = this.props.pendingInvite.organisation.name;
    const message = messageFormat.replace("%s", orgName);

    return (
      <Grid container spacing={DEFAULT_SPACING} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            <Trans>
              <strong>{title.toUpperCase()}</strong>
            </Trans>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">
            <Trans>{message}</Trans>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton
            label={createOrganisationButton}
            endIcon={<ArrowIcon />}
            isLoading={this.props.isLoading}
            onClick={this.handleCreateOrganisationPressed}
            fullWidth
          />
        </Grid>

        <Grid item>
          <SecondaryButton
            label={declineButton}
            disabled={this.props.isLoading}
            onClick={() => this.props.rejectInvite(this.props.pendingInvite)}
          />
        </Grid>

        <ErrorSnackbarHandler message={this.props.errorMessage} />
      </Grid>
    );
  }

  // MARK: Handlers

  handleCreateOrganisationPressed = () => {
    this.setState({ isCreatingOrg: true });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isLoading: state.pendingInvite.isLoading,
    errorMessage: state.pendingInvite.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  rejectInvite: (invite) => dispatch(rejectInvite(invite)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateOwnerOrganisationPromptPanel));
