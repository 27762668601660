import { TFunction } from "i18next";
import Address from "../../../pages/properties/PropertyDetailPage/model/Address";
import { AddressEditorState } from "./AddressEditor";

// Attempts to create a valid address,
// will return the address if it's valid
const validateAddressEditor = (
  state: AddressEditorState,
  setState: (state: AddressEditorState) => void,
  t: TFunction
): Address | undefined => {
  const line1 = state.line1.value.trim();
  const line2 = state.line2.value.trim();
  const line3 = state.line3.value.trim();
  const city = state.city.value.trim();
  const postcode = state.postcode.value.trim();
  const country = state.country.value.trim();

  let isValid = true;
  let newState = { ...state };

  if (line1.length === 0) {
    newState.line1.errorText = t("addressEditor.line1RequiredError");
    isValid = false;
  }

  if (city.length === 0) {
    newState.city.errorText = t("addressEditor.cityRequiredError");
    isValid = false;
  }

  if (postcode.length === 0) {
    newState.postcode.errorText = t("addressEditor.postCodeRequiredError");
    isValid = false;
  }

  if (country.length === 0) {
    newState.country.errorText = t("addressEditor.countryRequiredError");
    isValid = false;
  }

  if (isValid) {
    return {
      line1: line1,
      line2: line2,
      line3: line3,
      city: city,
      postcode: postcode,
      country: country,
    };
  } else {
    setState(newState);

    return undefined;
  }
};

export default validateAddressEditor;
