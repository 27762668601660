import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import RootState from "../../../../../redux/RootState";
import ProCheckResult from "../../../../../rest/procheck/model/ProCheckResult";
import ProductReportTableItem from "../../../../procheck/ProCheckPage/model/ProductReportTableItem";
import mapProCheckReportToProductTableItem from "../../../../procheck/utils/mapProCheckReportToProductTableItem";
import {
  filterProCheckResults,
  loadOrgProCheckResults,
  loadUserProCheckResults,
} from "../../../../procheck/redux/ProCheckAction";
import getProCheckReportArray from "../../../../procheck/utils/getProCheckReportArray";
import {
  buildAdminOrgProcheckReportDetailRoute,
  buildOrgProcheckReportDetailRoute,
} from "../../../../../routes/routes";
import { resetProperty } from "../../../../properties/PropertyDetailPage/redux/PropertyDetailActions";
import { TProCheckReportTab } from "../../../../../utils/types/TProCheckReportTab";

const useReportsSelection = (reportsTab: TProCheckReportTab = "org-reports") => {
  //Translation
  const { t: translate } = useTranslation();

  //Selector
  const { proCheckState, currentOrg, isAdmin, selectedOrg, isLoading, searchString } =
    useSelector((state: RootState) => {
      return {
        proCheckState: state.proCheck,
        currentOrg: state.activeOrganisation.currentOrganisation,
        isAdmin: state.adeyAdmin.hasAdminAccess,
        selectedOrg: state.orgDetail,
        isLoading: state.proCheck.isLoading,
        searchString: state.proCheck.searchString,
      };
    });

  //Hooks
  const { userId: pageUserId, organisationId: pageOrganisationId } = useParams<{
    userId?: string;
    organisationId?: string;
  }>();

  //Dispatch
  const dispatch = useDispatch();

  //References
  const prevUserId = useRef<string>();
  const prevOrgId = useRef<string>();

  //Navigation
  const history = useHistory();

  //States
  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<ProCheckResult[]>([]);

  //If needed, this could be moved further to redux, although this is not strictly necessary
  const getCurrentPageItemIds = () => {
    return proCheckState.pagination.displayItems;
  };

  //Consts
  const tableItems =
    getProCheckReportArray({
      allIds: getCurrentPageItemIds(),
      resultsById: proCheckState.results || {},
    }).map((r) => mapProCheckReportToProductTableItem(r, translate)) || [];

  //Effects
  useEffect(() => {
    //Dispatches appropriate loader method
    const loadProCheckResults = (reportTab: TProCheckReportTab, orgId: string) => {
      switch (reportTab) {
        case "org-reports":
          dispatch(loadOrgProCheckResults(undefined, orgId));
          break;
        case "user-reports":
          dispatch(
            loadUserProCheckResults(undefined, pageOrganisationId || "", pageUserId || "")
          );
          break;
        default:
          dispatch(loadOrgProCheckResults(undefined, orgId));
          break;
      }
    };

    if (currentOrg && currentOrg?.id !== prevOrgId.current) {
      const orgId = isAdmin ? selectedOrg.organisation?.id || "" : currentOrg.id;
      prevOrgId.current = orgId;
      prevUserId.current = pageUserId;
      //Needed to avoid incorrect state on other pages which use ProCheck redux
      dispatch(resetProperty());
      loadProCheckResults(reportsTab, orgId);
    }
  }, [
    currentOrg,
    dispatch,
    isAdmin,
    pageOrganisationId,
    pageUserId,
    reportsTab,
    selectedOrg.organisation?.id,
  ]);

  useEffect(() => {
    if (searchBarValue.toLowerCase().trim() !== searchString) {
      const extraIds = selectedReports.map((report) => report.id);
      const orgId = isAdmin
        ? selectedOrg.organisation?.id || ""
        : currentOrg?.id || pageOrganisationId || "";

      dispatch(
        filterProCheckResults(
          searchBarValue.trim().toLowerCase(),
          orgId,
          reportsTab === "user-reports" ? pageUserId || "" : undefined,
          extraIds
        )
      );
    }
  }, [
    currentOrg?.id,
    dispatch,
    isAdmin,
    pageOrganisationId,
    pageUserId,
    reportsTab,
    searchBarValue,
    searchString,
    selectedOrg.organisation?.id,
    selectedReports,
  ]);

  //Handlers
  const handleReportCheckmarkClicked = (
    reportTableItem: ProductReportTableItem,
    selected: boolean
  ) => {
    if (proCheckState.results !== undefined) {
      const result = getProCheckReportArray({
        allIds: proCheckState.resultIds,
        resultsById: proCheckState.results || {},
      }).find((r) => r.id === reportTableItem.id);
      selected && result !== undefined
        ? (() => {
            setSelectedReports([...selectedReports, result]);
          })()
        : (() => {
            setSelectedReports(selectedReports.filter((report) => report !== result));
          })();
    }
  };

  const handleSearchBarChanged = (value: string) => {
    setSearchBarValue(value);
  };

  const handleReportClicked = (report: ProductReportTableItem) => {
    if (isAdmin && report.orgId) {
      history.push(
        buildAdminOrgProcheckReportDetailRoute(report.propertyId, report.id, report.orgId)
      );
    } else {
      history.push(buildOrgProcheckReportDetailRoute(report.propertyId, report.id));
    }
  };

  const resetSelectedReports = () => {
    setSelectedReports([]);
  };

  return {
    proCheckState,
    selectedReports,
    tableItems,
    searchBarValue,
    isLoading,
    handleReportCheckmarkClicked,
    handleSearchBarChanged,
    handleReportClicked,
    resetSelectedReports,
  };
};

export default useReportsSelection;
