import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  ACCOUNT_ACTIVATION_TOKEN_PARAM,
  ACCOUNT_ACTIVATION_USERNAME_PARAM,
} from "../../../routes/routes";
import RootState from "../../../redux/RootState";
import { connect } from "react-redux";
import { AccountActivationStateType } from "./redux/AccountActivationState";
import { Dispatch } from "redux";
import { startActivation } from "./redux/AccountActivationAction";
import AccountActivationErrorCard from "./components/AccountActivationErrorCard";
import AccountActivationSuccessCard from "./components/AccountActivationSuccessCard";
import AccountActivationLoadingCard from "./components/AccountActivationLoadingCard";
import extractQueryParam from "../../../utils/extractQueryParam";

interface ReduxStateProps {
  activationState: AccountActivationStateType;
  error: string | undefined;
}

interface ReduxDispatchProps {
  startActivation: (token: string, username: string) => void;
}

type Props = RouteComponentProps & ReduxStateProps & ReduxDispatchProps;

class AccountActivationPage extends React.Component<Props> {
  render() {
    switch (this.props.activationState) {
      case "error":
        return <AccountActivationErrorCard />;

      case "success":
        return <AccountActivationSuccessCard />;

      case "idle":
      case "loading":
        return <AccountActivationLoadingCard />;
    }
  }

  componentDidMount() {
    if (this.props.activationState === "idle") {
      const token = this.extractToken();
      const username = this.extractUsername();
      this.props.startActivation(token, username);
    }
  }

  extractToken = (): string => {
    return extractQueryParam(ACCOUNT_ACTIVATION_TOKEN_PARAM, this.props);
  };

  extractUsername = (): string => {
    return extractQueryParam(ACCOUNT_ACTIVATION_USERNAME_PARAM, this.props);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  activationState: state.accountActivation.type,
  error: state.accountActivation.error,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  startActivation: (token, username) =>
    dispatch(startActivation({ token: token, username: username })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountActivationPage));
