import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";

// Load users
export const LOAD_USERS_TYPE = "LOAD_USERS_TYPE";
export interface LoadUsersAction {
  type: typeof LOAD_USERS_TYPE;
}
export const loadUsers = () => {
  return {
    type: LOAD_USERS_TYPE,
  };
};

// Load users success
export const LOAD_USERS_SUCCESS_TYPE = "LOAD_USERS_SUCCESS_TYPE";
export interface LoadUsersSuccessAction {
  type: typeof LOAD_USERS_SUCCESS_TYPE;
  users: OrganisationUser[];
  invites: PendingInvite[];
}
export const loadUsersSuccess = (
  users: OrganisationUser[],
  invites: PendingInvite[]
): LoadUsersSuccessAction => {
  return {
    type: LOAD_USERS_SUCCESS_TYPE,
    users: users,
    invites: invites,
  };
};

// Load users error
export const LOAD_USERS_ERROR_TYPE = "LOAD_USERS_ERROR_TYPE";
export interface LoadUsersErrorAction {
  type: typeof LOAD_USERS_ERROR_TYPE;
  error: string;
}
export const loadUsersError = (error: string) => {
  return {
    type: LOAD_USERS_ERROR_TYPE,
    error: error,
  };
};

// User search text changed
export const USER_SEARCH_TEXT_CHANGED_TYPE = "USER_SEARCH_TEXT_CHANGED_TYPE";
export interface UserSearchTextChangedAction {
  type: typeof USER_SEARCH_TEXT_CHANGED_TYPE;
  searchText: string;
}
export const userSearchTextChanged = (searchText: string) => {
  return {
    type: USER_SEARCH_TEXT_CHANGED_TYPE,
    searchText: searchText,
  };
};

type UserListAction =
  | LoadUsersAction
  | LoadUsersSuccessAction
  | LoadUsersErrorAction
  | UserSearchTextChangedAction;

export default UserListAction;
