import { TFunction } from "i18next";
import Address from "../pages/properties/PropertyDetailPage/model/Address";
import { PropertyAddress } from "../rest/properties/model/Property";

export const formatAddress = (address: Address | undefined, t?: TFunction): string => {
  if (address === undefined) {
    return "";
  }

  return [
    address.line1,
    address.line2,
    address.line3,
    address.city,
    address.postcode,
    ...(t ? [t?.("countryCode." + address.country)] : ""),
  ]
    .filter((line) => typeof line === "string")
    .map((line) => (line as string).trim())
    .filter((line) => line.length > 0)
    .join(", ");
};

export const formatPropertyAddress = (address: PropertyAddress, t?: TFunction) => {
  const addr: Address = {
    line1: address.address_line_1,
    line2: address.address_line_2,
    line3: address.address_line_3,
    city: address.address_city,
    postcode: address.address_postcode,
    country: address.address_country.code,
  };
  return formatAddress(addr, t);
};
