import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { LoqateFindItem } from "../../../../rest/loqate/model/LoqateFindResponse";

interface Props {
  key: string;
  item: LoqateFindItem;
  loading: boolean;

  onItemSelected: (item: LoqateFindItem) => void;
}

const AddressLookupSelectItem = (props: Props) => {
  const onClick = () => {
    props.onItemSelected(props.item);
  };

  const isGroup = props.item.Type.toLowerCase() !== "address";
  const text = isGroup
    ? props.item.Description
    : props.item.Text + ", " + props.item.Description;

  const renderEndComponent = () => {
    if (props.loading) {
      return <CircularProgress color="inherit" size={14} />;
    } else if (isGroup) {
      return <FontAwesomeIcon icon={faChevronDown} />;
    } else {
      return undefined;
    }
  };

  return (
    <Button disabled={props.loading} onClick={onClick} fullWidth>
      <Typography style={{ textTransform: "initial" }} align="left">
        {text}
      </Typography>

      <Box flexGrow={1} />

      {renderEndComponent()}
    </Button>
  );
};

export default AddressLookupSelectItem;
