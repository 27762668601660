import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { logInAction } from "./redux/LoginAction";
import RootState from "../../../redux/RootState";
import { Box, Grid, Typography } from "@material-ui/core";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import PrimaryTextField from "../../../ui/components/PrimaryTextField";
import PasswordTextField from "../../../ui/components/PasswordTextField";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import ArrowIcon from "../../../ui/assets/ArrowIcon/ArrowIcon";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { WithTranslation, withTranslation } from "react-i18next";
import ErrorSnackbar from "../../../ui/components/ErrorSnackbar/ErrorSnackbar";
import { RouteComponentProps, withRouter } from "react-router";
import {
  FORGOT_PASSWORD_ROUTE,
  REGISTRATION_ROUTE,
  RESEND_ACTIVATION_EMAIL_ROUTE,
} from "../../../routes/routes";
import { SMALL_SPACING } from "../../../ui/theme/dimensions";
import PasswordResetRequiredDialog from "./components/PasswordResetRequiredDialog";

interface ReduxProps {
  isLoggingIn: boolean;
  userNotConfirmed: boolean;
  error: string | undefined;
  passwordResetRequired: boolean;
  proClubUser: boolean;
}

interface ReduxDispatchProps {
  logIn: (email: string, password: string) => void;
}

interface State {
  email: string;
  password: string;
  errorMessage: string | undefined;
  emailError: string | undefined;
  passwordError: string | undefined;
}

type Props = ReduxProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

class LoginPage extends React.Component<Props, State> {
  state = {
    email: "",
    password: "",
    errorMessage: undefined,
    emailError: undefined,
    passwordError: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.userNotConfirmed && !prevProps.userNotConfirmed) {
      this.props.history.push(RESEND_ACTIVATION_EMAIL_ROUTE);
    }

    if (!this.props.userNotConfirmed && this.props.error !== prevProps.error) {
      this.setState({ errorMessage: this.props.error });
    }
  }

  render() {
    const title = this.props.t("loginPage.title");
    const email = this.props.t("loginPage.emailFieldLabel");
    const password = this.props.t("loginPage.passwordFieldLabel");
    const forgotPassword = this.props.t("loginPage.forgotPasswordButton");
    const continueButton = this.props.t("loginPage.continueButton");
    const registerPrompt = this.props.t("loginPage.registerPrompt");
    const registerButton = this.props.t("loginPage.registerButton");

    return (
      <CenteredAuthCard title={title}>
        <Grid container direction="column" spacing={SMALL_SPACING}>
          <Grid item />

          <Grid item>
            <PrimaryTextField
              autocomplete="username"
              type="email"
              value={this.state.email}
              fullWidth
              hint={email}
              onChange={this.handleEmailChanged}
              disabled={this.props.isLoggingIn}
              errorText={this.state.emailError}
              onEnter={this.handleSubmit}
            />
          </Grid>
          <Grid item>
            <PasswordTextField
              autocomplete="current-password"
              value={this.state.password}
              fullWidth
              hint={password}
              onChange={this.handlePasswordChanged}
              disabled={this.props.isLoggingIn}
              errorText={this.state.passwordError}
              onEnter={this.handleSubmit}
            />

            <Box height={SMALL_SPACING} />

            <SecondaryButton
              label={forgotPassword}
              onClick={this.handleForgotPasswordClicked}
            />
          </Grid>

          <Grid item>
            <PrimaryButton
              fullWidth
              label={continueButton}
              endIcon={<ArrowIcon />}
              isLoading={this.props.isLoggingIn}
              onClick={this.handleSubmit}
            />
          </Grid>

          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Typography>{registerPrompt}</Typography>
              <SecondaryButton
                label={registerButton}
                onClick={this.handleRegisterClicked}
              />
            </Grid>
          </Grid>
        </Grid>

        <PasswordResetRequiredDialog
          proClubUser={this.props.proClubUser}
          showResetDialog={this.props.passwordResetRequired}
          handleResetClicked={this.handleForgotPasswordClicked}
        />

        <ErrorSnackbar
          message={this.state.errorMessage}
          onClose={this.handleSnackbarClosed}
        />
      </CenteredAuthCard>
    );
  }

  // MARK: Handlers

  handleSubmit = () => {
    if (this.validate()) {
      this.props.logIn(this.state.email, this.state.password);
    }
  };

  handleEmailChanged = (value: string) => {
    this.setState({
      email: value,
      emailError: undefined,
    });
  };

  handlePasswordChanged = (value: string) => {
    this.setState({
      password: value,
      passwordError: undefined,
    });
  };

  handleSnackbarClosed = () => {
    this.setState({ errorMessage: undefined });
  };

  handleForgotPasswordClicked = () => {
    this.props.history.push(FORGOT_PASSWORD_ROUTE);
  };

  handleRegisterClicked = () => {
    this.props.history.push(REGISTRATION_ROUTE);
  };

  // MARK: Validation

  validate = (): boolean => {
    let isValid = true;

    if (this.state.email.trim().length === 0) {
      this.setState({
        emailError: this.props.t("loginPage.emailRequired"),
      });
      isValid = false;
    }

    if (this.state.password.trim().length === 0) {
      this.setState({
        passwordError: this.props.t("loginPage.passwordRequired"),
      });
      isValid = false;
    }
    return isValid;
  };
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    isLoggingIn: state.login.status === "logging_in",
    error: state.login.error,
    userNotConfirmed: !!state.resendActivationEmail.emailAddress,
    passwordResetRequired: !!state.login.resetRequired,
    proClubUser: !!state.login.proClubUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    logIn: (email, password) => dispatch(logInAction(email, password)),
  };
};

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
);
