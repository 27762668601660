import Organisation from "../../../../rest/organisation/model/Organisation";

// Load platform agents
export const LOAD_PLATFORM_AGENTS_TYPE = "LOAD_PLATFORM_AGENTS_TYPE";
export interface LoadPlatformAgentsAction {
  type: typeof LOAD_PLATFORM_AGENTS_TYPE;
  page: number;
}
export const loadPlatformAgents = (page: number): LoadPlatformAgentsAction => ({
  type: LOAD_PLATFORM_AGENTS_TYPE,
  page: page,
});

// Platform agents loaded
export const PLATFORM_AGENTS_LOADED_TYPE = "PLATFORM_AGENTS_LOADED_TYPE";
export interface PlatformAgentsLoadedAction {
  type: typeof PLATFORM_AGENTS_LOADED_TYPE;
  agents: Organisation[];
  page: number;
  maxPages: number;
}
export const platformAgentsLoaded = (
  agents: Organisation[],
  page: number,
  maxPages: number
): PlatformAgentsLoadedAction => ({
  type: PLATFORM_AGENTS_LOADED_TYPE,
  agents: agents,
  page: page,
  maxPages: maxPages,
});

// Update an agent within the list
export const PLATFORM_AGENTS_UPDATE_ENTRY_TYPE = "PLATFORM_AGENTS_UPDATE_ENTRY";
export interface PlatformAgentUpdateEntityAction {
  type: typeof PLATFORM_AGENTS_UPDATE_ENTRY_TYPE;
  agent: Organisation;
}

export const updateAgentListEntry = (
  agent: Organisation
): PlatformAgentUpdateEntityAction => ({
  type: PLATFORM_AGENTS_UPDATE_ENTRY_TYPE,
  agent: agent,
});

// Platform agents error
export const PLATFORM_AGENTS_ERROR_TYPE = "PLATFORM_AGENTS_ERROR_TYPE";
export interface PlatformAgentsErrorAction {
  type: typeof PLATFORM_AGENTS_ERROR_TYPE;
  error: string;
}
export const platformAgentsError = (error: string): PlatformAgentsErrorAction => ({
  type: PLATFORM_AGENTS_ERROR_TYPE,
  error: error,
});

// Search text changed
export const AGENT_SEARCH_TEXT_CHANGED_TYPE = "AGENT_SEARCH_TEXT_CHANGED_TYPE";
export interface SearchTextChangedAction {
  type: typeof AGENT_SEARCH_TEXT_CHANGED_TYPE;
  text: string;
}
export const searchTextChanged = (text: string): SearchTextChangedAction => ({
  type: AGENT_SEARCH_TEXT_CHANGED_TYPE,
  text: text,
});

type PlatformAgentListAction =
  | LoadPlatformAgentsAction
  | PlatformAgentsLoadedAction
  | PlatformAgentsErrorAction
  | SearchTextChangedAction
  | PlatformAgentUpdateEntityAction;

export default PlatformAgentListAction;
