import { Box, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import MemberOrganisation from "../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../rest/organisation/model/Organisation";
import organisationMatchesSearchText from "../../../rest/organisation/utils/organisationMatchesSearchText";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import { switchOrganisationChanged } from "../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import OrganisationTable from "../../../ui/components/OrganisationTable/OrganisationTable";
import SearchBar from "../../../ui/components/SearchBar";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";
import SwitchingManagedOrgPopup from "./components/SwitchingManagedOrgPopup";

//Props
interface ReduxStateProps {
  organisationList: MemberOrganisation[];
  activeOrganisation: MemberOrganisation | undefined;
  switchingToOrganisation: MemberOrganisation | undefined;
}

interface ReduxDispatchProps {
  switchOrganisation: (organisation: MemberOrganisation) => void;
}

type Props = WithT & ReduxStateProps & ReduxDispatchProps & RouteComponentProps;

//State
interface State {
  searchText: string;
}

class AgentManagedOrganisationList extends React.Component<Props, State> {
  state = {
    searchText: "",
  };

  render() {
    //Labels
    const searchBarPlaceholder = this.props.t(
      "agentOrganisationListPage.searchPlaceholder"
    );
    const noManagedOrganisation = this.props.t(
      "agentOrganisationListPage.noManagedOrganisations"
    );
    const organisationsTitle = this.props.t("headerTabs.organisations");

    const managedOrgs = this.props.organisationList.filter(
      (org) =>
        org.is_agent_for?.id === undefined &&
        org.role.name === "agent" &&
        org.agent?.id === this.props.activeOrganisation?.id
    );
    const organisations = this.filterBySearch(managedOrgs);

    return (
      <DashboardLayout>
        <Box padding={DEFAULT_SPACING} display="flex" flexDirection="column">
          <SearchBar
            placeholder={searchBarPlaceholder}
            value={this.state.searchText}
            onChange={this.onSearchTextChanged}
          />

          <Box marginTop={DEFAULT_SPACING} />

          <Typography
            style={{ textTransform: "uppercase" }}
            color="secondary"
            variant="h5"
          >
            <strong>{organisationsTitle}</strong>
          </Typography>
          {organisations.length > 0 ? (
            <Box display="flex" flexDirection="column">
              <OrganisationTable
                organisations={organisations}
                onOrgClicked={
                  !this.props.switchingToOrganisation
                    ? this.handleOrganisationClicked
                    : undefined
                }
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              padding={DEFAULT_SPACING}
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h4" color="secondary">
                {noManagedOrganisation}
              </Typography>
            </Box>
          )}
          {this.props.switchingToOrganisation !== undefined ? (
            <SwitchingManagedOrgPopup
              isOpen={true}
              organisationName={this.props.switchingToOrganisation.name}
            />
          ) : null}
        </Box>
      </DashboardLayout>
    );
  }

  // Handlers
  onSearchTextChanged = (newText: string) => {
    this.setState({ searchText: newText });
  };

  handleOrganisationClicked = (organisation: Organisation) => {
    // Find organisation in the list of agent organisations
    const memberOrg = this.props.organisationList.find((o) => o.id === organisation.id);

    if (memberOrg !== undefined) {
      this.props.switchOrganisation(memberOrg);
    }
  };

  // Filters
  filterBySearch = (orgs: Organisation[]): Organisation[] => {
    const filteredOrgs = orgs.filter(
      (o) =>
        organisationMatchesSearchText(o, this.state.searchText) && !o.is_agent_for?.id
    );
    return filteredOrgs;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  organisationList: state.activeOrganisation.organisationList,
  switchingToOrganisation: state.activeOrganisation.switchingToOrganisation,
  activeOrganisation: state.activeOrganisation.currentOrganisation,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  switchOrganisation: (organisation: MemberOrganisation) =>
    dispatch(switchOrganisationChanged(organisation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AgentManagedOrganisationList)));
