import { Box, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import BorderedBox from "../../../ui/components/BorderedBox";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import Spinner from "../../../ui/components/Spinner";
import ProCheckErrorTile from "../../procheck/components/ProCheckProductTile/components/ProCheckErrorTile";
import SenseDevicesState from "../redux/SenseDevicesState";
import SenseDeviceResultsTile from "./SenseDeviceProductsTile/components/SenseDeviceResultsTile";

type ReduxStateProps = { senseDeviceState: SenseDevicesState; isAdmin: boolean };
interface Props extends ReduxStateProps {
  onMoreInfoClicked: (deviceId: string) => void;
}

const SenseDeviceProductsTile = ({
  onMoreInfoClicked,
  senseDeviceState,
  isAdmin,
}: Props) => {
  if (senseDeviceState.devices !== undefined && senseDeviceState.isLoading !== true) {
    return (
      <React.Fragment>
        {senseDeviceState.devices.map((device) => {
          return (
            <Grid item xs={12} md={6} xl={4}>
              <Box display="flex" flexDirection="column" height="100%">
                <SenseDeviceResultsTile
                  isAdmin={isAdmin}
                  device={device}
                  onMoreInfoClicked={() => onMoreInfoClicked(device.id)}
                />
              </Box>
            </Grid>
          );
        })}

        <ErrorSnackbarHandler message={senseDeviceState.error} />
      </React.Fragment>
    );
  } else if (senseDeviceState.error !== undefined) {
    return (
      <Grid item xs={12} md={6} xl={4}>
        <ProCheckErrorTile error={senseDeviceState.error} />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} md={6} xl={4}>
        <BorderedBox padding={0} display="flex" flexDirection="column" height="100%">
          <Spinner />
        </BorderedBox>
      </Grid>
    );
  }
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    senseDeviceState: state.senseDevices,
    isAdmin: state.adeyAdmin.hasAdminAccess,
  };
};

export default connect(mapStateToProps)(SenseDeviceProductsTile);
