import PlatformAgentDetailsAction, {
  AGENT_ACCESS_REVOKED_TYPE,
  AGENT_DETAILS_LOADED_TYPE,
  AGENT_DETAILS_LOAD_ERROR_TYPE,
  AGENT_ORG_USERS_LOADED_TYPE,
  AGENT_ORG_USERS_LOAD_ERROR_TYPE,
  AGENT_USER_REMOVED_TYPE,
  INVITE_AGENT_USER_ERROR_TYPE,
  INVITE_AGENT_USER_SUCCESS_TYPE,
  INVITE_AGENT_USER_TYPE,
  LOAD_AGENT_DETAILS_TYPE,
  LOAD_AGENT_ORG_USERS_TYPE,
  LOAD_MANAGED_ORGANISATION_ERROR_TYPE,
  LOAD_MANAGED_ORGANISATION_TYPE,
  MANAGED_ORGANISATIONS_SEARCH_TYPE,
  MANAGED_ORGANISATION_LOADED_TYPE,
  MANAGED_ORGANISATION_REMOVED_TYPE,
  REMOVE_AGENT_USER_ERROR,
  REMOVE_AGENT_USER_TYPE,
  REMOVE_MANAGED_ORGANISATION_TYPE,
  REVOKE_AGENT_ACCESS_ERROR_TYPE,
  REVOKE_AGENT_ACCESS_TYPE,
  UPDATE_AGENT_DETAILS_TYPE,
} from "./PlatformAgentDetailsActions";
import PlatformAgentDetailsState from "./PlatformAgentDetailsState";

const initialState: PlatformAgentDetailsState = {
  userDetails: undefined,
  managedOrganisations: undefined,
  agentDetails: undefined,

  isLoadingManagedOrganisations: false,
  managedOrganisationLoaded: undefined,

  isLoadingAgentDetails: false,
  agentDetailsLoaded: undefined,

  isUpdatingAgentDetails: false,

  isRemovingOrganisation: false,
  organisationRemoved: undefined,

  isDisablingAgent: false,
  agentDisabled: undefined,

  isLoadingUsers: false,
  usersLoaded: undefined,

  isInvitingUser: false,
  userInvited: undefined,

  isRemovingUser: false,
  userRemoved: undefined,

  error: undefined,
  orgSearchText: "",
};

const platformAgentDetailsReducer = (
  state = initialState,
  action: PlatformAgentDetailsAction
): PlatformAgentDetailsState => {
  switch (action.type) {
    case LOAD_AGENT_DETAILS_TYPE:
      return {
        ...state,
        error: undefined,
        isLoadingAgentDetails: true,
        agentDetailsLoaded: false,
        isDisablingAgent: false,
        agentDisabled: false,
      };
    case AGENT_DETAILS_LOADED_TYPE:
      return {
        ...state,
        agentDetails: action.agentOrg,
        isLoadingAgentDetails: false,
        isUpdatingAgentDetails: false,
        agentDetailsLoaded: true,
      };
    case AGENT_DETAILS_LOAD_ERROR_TYPE:
      return {
        ...state,
        error: action.error,
        isUpdatingAgentDetails: false,
        isLoadingAgentDetails: false,
        agentDetailsLoaded: false,
      };
    case UPDATE_AGENT_DETAILS_TYPE:
      return {
        ...state,
        isUpdatingAgentDetails: true,
      };
    case LOAD_MANAGED_ORGANISATION_TYPE:
      return {
        ...state,
        error: undefined,
        isLoadingManagedOrganisations: true,
        managedOrganisationLoaded: false,
      };
    case MANAGED_ORGANISATION_LOADED_TYPE:
      return {
        ...state,
        managedOrganisations: action.managedOrgs,
        isUpdatingAgentDetails: false,
        isLoadingManagedOrganisations: false,
        managedOrganisationLoaded: true,
      };
    case MANAGED_ORGANISATIONS_SEARCH_TYPE:
      return {
        ...state,
        isLoadingManagedOrganisations: true,
        orgSearchText: action.searchText,
      };
    case LOAD_MANAGED_ORGANISATION_ERROR_TYPE:
      return {
        ...state,
        isUpdatingAgentDetails: false,
        isLoadingManagedOrganisations: false,
        managedOrganisationLoaded: false,
        error: action.error,
      };
    case REMOVE_MANAGED_ORGANISATION_TYPE:
      return {
        ...state,
        isRemovingOrganisation: true,
        organisationRemoved: false,
      };
    case MANAGED_ORGANISATION_REMOVED_TYPE:
      return {
        ...state,
        managedOrganisations: state.managedOrganisations?.filter(
          (org) => org.id !== action.removedOrg.id
        ),
        isRemovingOrganisation: false,
        organisationRemoved: true,
      };
    case REVOKE_AGENT_ACCESS_TYPE:
      return {
        ...state,
        isDisablingAgent: true,
        agentDisabled: false,
      };
    case AGENT_ACCESS_REVOKED_TYPE:
      return {
        ...state,
        isDisablingAgent: false,
        agentDisabled: true,
      };
    case REVOKE_AGENT_ACCESS_ERROR_TYPE:
      return {
        ...state,
        isDisablingAgent: false,
        agentDisabled: false,
        error: action.error,
      };
    case LOAD_AGENT_ORG_USERS_TYPE:
      return {
        ...state,
        isLoadingUsers: true,
        usersLoaded: false,
      };
    case AGENT_ORG_USERS_LOADED_TYPE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          users: action.users,
          invites: action.pendingInvites,
        },
        isLoadingUsers: false,
        usersLoaded: true,
      };
    case AGENT_ORG_USERS_LOAD_ERROR_TYPE:
      return {
        ...state,
        isLoadingUsers: false,
        usersLoaded: false,
        error: action.error,
      };
    case INVITE_AGENT_USER_TYPE:
      return {
        ...state,
        isInvitingUser: true,
        userInvited: false,
        error: undefined,
      };
    case INVITE_AGENT_USER_SUCCESS_TYPE:
      return {
        ...state,
        userDetails: {
          users: state.userDetails?.users || [],
          invites: [...(state.userDetails?.invites || []), action.invite],
        },
        isInvitingUser: false,
        userInvited: true,
      };
    case INVITE_AGENT_USER_ERROR_TYPE:
      return {
        ...state,
        isInvitingUser: false,
        userInvited: false,
        error: action.error,
      };
    case REMOVE_AGENT_USER_TYPE:
      return {
        ...state,
        isRemovingUser: true,
        userRemoved: false,
      };
    case AGENT_USER_REMOVED_TYPE:
      return {
        ...state,
        userDetails: {
          users:
            state.userDetails?.users?.filter(
              (user) => user.user.id !== action.removedUser.user.id
            ) || [],
          invites: state.userDetails?.invites || [],
        },
        isRemovingUser: false,
        userRemoved: true,
      };
    case REMOVE_AGENT_USER_ERROR:
      return {
        ...state,
        isRemovingUser: false,
        userRemoved: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default platformAgentDetailsReducer;
