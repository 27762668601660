import { Redirect, Route, Switch } from "react-router";
import AccountSettingsPage from "../../pages/account/AccountSettingsPage/AccountSettingsPage";
import AdminOrganisationDetailsPage from "../../pages/admin/AdminOrganisationDetailsPage/AdminOrganisationDetailsPage";
import PlatformAgentDetailsPage from "../../pages/admin/PlatformAgentDetailsPage/PlatformAgentDetailsPage";
import PlatformAgentListPage from "../../pages/admin/PlatformAgentListPage/PlatformAgentListPage";
import PlatformOrganisationListPage from "../../pages/admin/PlatformOrganisationListPage/PlatformOrganisationListPage";
import PlatformUserDetailPage from "../../pages/admin/PlatformUserDetailPage/PlatformUserDetailPage";
import PlatformUserListPage from "../../pages/admin/PlatformUserListPage/PlatformUserListPage";
import AccountActivationPage from "../../pages/auth/AccountActivation/AccountActivationPage";
import SenseDeviceDetailsPage from "../../pages/devices/SenseDeviceDetailsPage/SenseDeviceDetailsPage";
import TermsAndConditionsDocumentPage from "../../pages/documents/TermsAndConditionsDocumentPage";
import HomezoneUnsubscribePage from "../../pages/homezone/HomezoneUnsubscribePage";
import CurrentOrganisationPage from "../../pages/organisations/current/CurrentOrganisationPage";
import ProCheckDetailsPage from "../../pages/procheck/ProCheckDetailsPage/ProCheckDetailsPage";
import ProCheckPage from "../../pages/procheck/ProCheckPage/ProCheckPage";
import PropertyPageTypeListener from "../../pages/properties/PropertyDetailPage/components/PropertyPageTypeListener";
import PropertyDetailPage from "../../pages/properties/PropertyDetailPage/PropertyDetailPage";
import PropertyListPage from "../../pages/properties/PropertyListPage/PropertyListPage";
import AdminRAGCardListener from "../../ui/components/DashboardLayout/components/AdminRAGCardListener";
import {
  ACCOUNT_ACTIVATION_ROUTE,
  PLATFORM_USER_LIST_ROUTE,
  PLATFORM_ORGANISATIONS_LIST_ROUTE,
  PLATFORM_ORGANISATION_DETAIL_ROUTE,
  PLATFORM_USER_DETAIL_ROUTE,
  PROPERTY_DETAIL_ROUTE,
  PROCHECK_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  DASHBOARD_ROUTE,
  CURRENT_ORG_ROUTE,
  ORGANISATION_PROPERTY_DETAIL_ROUTE,
  PROCHECK_RESULT_ROUTE,
  PLATFORM_AGENT_LIST_ROUTE,
  PLATFORM_AGENT_DETAILS_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
  SENSE_DEVICE_ROUTE,
  HOMEZONE_UNSUBSCRIBE_ROUTE,
  ADMIN_ORG_PROCHECK_REPORTING_ROUTE,
} from "../routes";
/*
 * All of the pages available to Adey admins.
 */

// ... (other imports)

const AdeyAdminRouter = () => {
  // User has answered both questions, proceed to other routes
  return (
    <AdminRAGCardListener>
      <Switch>
        <Route exact path={[PROPERTY_DETAIL_ROUTE, ORGANISATION_PROPERTY_DETAIL_ROUTE]}>
          <PropertyPageTypeListener>
            <PropertyDetailPage />
          </PropertyPageTypeListener>
        </Route>

        <Route exact path={TERMS_AND_CONDITION_ROUTE}>
          <TermsAndConditionsDocumentPage />
        </Route>

        <Route exact path={HOMEZONE_UNSUBSCRIBE_ROUTE}>
          <HomezoneUnsubscribePage />
        </Route>

        <Route path={[PROCHECK_RESULT_ROUTE, ADMIN_ORG_PROCHECK_REPORTING_ROUTE]}>
          <ProCheckDetailsPage />
        </Route>

        <Route exact path={PLATFORM_ORGANISATION_DETAIL_ROUTE}>
          <AdminOrganisationDetailsPage />
        </Route>

        <Route exact path={PLATFORM_USER_LIST_ROUTE}>
          <PlatformUserListPage />
        </Route>

        <Route exact path={CURRENT_ORG_ROUTE}>
          <CurrentOrganisationPage />
        </Route>

        <Route exact path={DASHBOARD_ROUTE}>
          <PropertyListPage />
        </Route>

        <Route exact path={ACCOUNT_SETTINGS_ROUTE}>
          <AccountSettingsPage />
        </Route>

        <Route path={PLATFORM_ORGANISATIONS_LIST_ROUTE}>
          <PlatformOrganisationListPage />
        </Route>

        <Route path={ACCOUNT_ACTIVATION_ROUTE}>
          <AccountActivationPage />
        </Route>

        <Route exact path={PLATFORM_USER_DETAIL_ROUTE}>
          <PlatformUserDetailPage />
        </Route>

        <Route exact path={PROCHECK_ROUTE}>
          <ProCheckPage />
        </Route>

        <Route exact path={SENSE_DEVICE_ROUTE}>
          <SenseDeviceDetailsPage />
        </Route>

        <Route exact path={PLATFORM_AGENT_DETAILS_ROUTE}>
          <PlatformAgentDetailsPage />
        </Route>

        <Route exact path={PLATFORM_AGENT_LIST_ROUTE}>
          <PlatformAgentListPage />
        </Route>

        {/* Redirect any unknown routes to the dash */}
        <Route>
          <Redirect to={DASHBOARD_ROUTE} />
        </Route>
      </Switch>
    </AdminRAGCardListener>
  );
};

export default AdeyAdminRouter;
