import { Box } from "@material-ui/core";
import React from "react";
import BorderedBox from "../BorderedBox";

interface IProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  keyExtractor: (item: T) => string;
  maxHeight?: number;
}

const ScrollListPanel = <T extends unknown>(props: IProps<T>) => {
  return (
    <Box>
      <BorderedBox
        padding={0}
        maxHeight={props.maxHeight ?? 300}
        marginBottom={1}
        overflow="auto"
      >
        {props.items.map((item) => (
          <div key={props.keyExtractor(item)}>{props.renderItem(item)}</div>
        ))}
      </BorderedBox>
    </Box>
  );
};

export default ScrollListPanel;
