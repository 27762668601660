import ChangePassword from "../model/ChangePassword";
import CurrentUserDetails from "../model/CurrentUserDetails";

// Load current user details

export const LOAD_CURRENT_USER_TYPE = "LOAD_CURRENT_USER";

export interface LoadCurrentUserAction {
  type: typeof LOAD_CURRENT_USER_TYPE;
}

export const loadCurrentUser = (): LoadCurrentUserAction => {
  return { type: LOAD_CURRENT_USER_TYPE };
};

// Current user details loaded

export const CURRENT_USER_LOADED_TYPE = "CURRENT_USER_LOADED";

export interface CurrentUserLoadedAction {
  type: typeof CURRENT_USER_LOADED_TYPE;
  user: CurrentUserDetails;
}

export const currentUserLoaded = (
  currentUser: CurrentUserDetails
): CurrentUserLoadedAction => {
  return {
    type: CURRENT_USER_LOADED_TYPE,
    user: currentUser,
  };
};

// Current User Load Error

export const CURRENT_USER_LOAD_ERROR_TYPE = "CURRENT_USER_LOAD_ERROR";

export interface CurrentUserLoadErrorAction {
  type: typeof CURRENT_USER_LOAD_ERROR_TYPE;
  error: string;
}

export const currentUserLoadError = (error: string): CurrentUserLoadErrorAction => {
  return {
    type: CURRENT_USER_LOAD_ERROR_TYPE,
    error: error,
  };
};

// Update user details

export const UPDATE_CURRENT_USER_TYPE = "UPDATE_CURRENT_USER";

export interface UpdateCurrentUserAction {
  type: typeof UPDATE_CURRENT_USER_TYPE;
}

export const updateCurrentUser = (): UpdateCurrentUserAction => {
  return { type: UPDATE_CURRENT_USER_TYPE };
};

// Current User Details Updated

export const CURRENT_USER_UPDATED_TYPE = "CURRENT_USER_UPDATED_TYPE";

export interface CurrentUserDetailsUpdatedAction {
  type: typeof CURRENT_USER_UPDATED_TYPE;
  user: CurrentUserDetails;
}

export const currentUserDetailsUpdated = (
  currentUser: CurrentUserDetails
): CurrentUserDetailsUpdatedAction => ({
  type: CURRENT_USER_UPDATED_TYPE,
  user: currentUser,
});

// Current User Update Error

export const CURRENT_USER_UPDATE_ERROR_TYPE = "CURRENT_USER_UPDATE_ERROR";

export interface CurrentUserUpdateErrorAction {
  type: typeof CURRENT_USER_UPDATE_ERROR_TYPE;
  error: string;
}
export const currentUserUpdateError = (error: string): CurrentUserUpdateErrorAction => {
  return {
    type: CURRENT_USER_UPDATE_ERROR_TYPE,
    error: error,
  };
};

// Current User Change Password Request

export const CURRENT_USER_CHANGE_PASSWORD_TYPE = "CURRENT_USER_CHANGE_PASSWORD";
export interface CurrentUserChangePasswordAction {
  type: typeof CURRENT_USER_CHANGE_PASSWORD_TYPE;
  password: ChangePassword;
  message: string;
}

export const currentUserChangePassword = (
  password: ChangePassword,
  message: string
): CurrentUserChangePasswordAction => {
  return {
    type: CURRENT_USER_CHANGE_PASSWORD_TYPE,
    password: password,
    message: message,
  };
};

// Current User Change Password Failure
export const CURRENT_USER_CHANGE_PASSWORD_FAILURE_TYPE =
  "CURRENT_USER_CHANGE_PASSWORD_FAILURE";
export interface CurrentUserChangePasswordFailureAction {
  type: typeof CURRENT_USER_CHANGE_PASSWORD_FAILURE_TYPE;
  error: string;
}

export const currentUserChangePasswordFailure = (
  error: string
): CurrentUserChangePasswordFailureAction => {
  return {
    type: CURRENT_USER_CHANGE_PASSWORD_FAILURE_TYPE,
    error: error,
  };
};

// Current User Password Changed

export const CURRENT_USER_PASSWORD_CHANGED_TYPE = "CURRENT_USER_PASSWORD_CHANGED";

export interface CurrentUserPasswordChangedAction {
  type: typeof CURRENT_USER_PASSWORD_CHANGED_TYPE;
  success: string;
}

export const currentUserPasswordChanged = (
  success: string
): CurrentUserPasswordChangedAction => ({
  type: CURRENT_USER_PASSWORD_CHANGED_TYPE,
  success: success,
});

type CurrentUserAction =
  | LoadCurrentUserAction
  | CurrentUserLoadedAction
  | CurrentUserLoadErrorAction
  | CurrentUserDetailsUpdatedAction
  | CurrentUserUpdateErrorAction
  | CurrentUserChangePasswordAction
  | CurrentUserChangePasswordFailureAction
  | CurrentUserPasswordChangedAction;

export default CurrentUserAction;
