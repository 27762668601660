import ProCheckExport from "../../../rest/procheck/model/ProCheckExport";
import Property from "../../../rest/properties/model/Property";
import IPagination from "../../../utils/interfaces/IPagination";
import INormalisedResults from "../interfaces/INormalisedResults";
import ProCheckDetailsPageState from "../ProCheckDetailsPage/model/ProCheckDetailsPageState";

// Load procheck results
export const LOAD_PROCHECK_RESULTS_TYPE = "LOAD_PROCHECK_RESULTS_TYPE";
export interface LoadProCheckResultsAction {
  type: typeof LOAD_PROCHECK_RESULTS_TYPE;
  organisationId: string;
  propertyId: string;
}

export const loadProCheckResults = (
  organisationId: string,
  propertyId: string
): LoadProCheckResultsAction => ({
  type: LOAD_PROCHECK_RESULTS_TYPE,
  organisationId: organisationId,
  propertyId: propertyId,
});

// Load user ProCheck results
export const LOAD_USER_PROCHECK_RESULTS_TYPE = "LOAD_USER_PROCHECK_RESULTS_TYPE";

export interface LoadUserProCheckResultsAction {
  type: typeof LOAD_USER_PROCHECK_RESULTS_TYPE;
  organisationId: string;
  page: number;
  searchString?: string;
  userId: string;
}

export const loadUserProCheckResults = (
  page: number = 1,
  organisationId: string,
  userId: string,
  searchString?: string
): LoadUserProCheckResultsAction => ({
  type: LOAD_USER_PROCHECK_RESULTS_TYPE,
  organisationId: organisationId,
  page: page,
  searchString: searchString,
  userId: userId,
});

// Load org ProCheck results
export const LOAD_ORG_PROCHECK_RESULTS_TYPE = "LOAD_ORG_PROCHECK_RESULTS_TYPE";

export interface LoadOrgProCheckResultsAction {
  type: typeof LOAD_ORG_PROCHECK_RESULTS_TYPE;
  organisationId: string;
  page: number;
  searchString?: string;
}

export const loadOrgProCheckResults = (
  page: number = 1,
  organisationId: string,
  searchString?: string
): LoadOrgProCheckResultsAction => ({
  type: LOAD_ORG_PROCHECK_RESULTS_TYPE,
  organisationId: organisationId,
  page: page,
  searchString: searchString,
});

// ProCheck results loaded
export const PROCHECK_RESULTS_LOADED_TYPE = "PROCHECK_RESULTS_LOADED_TYPE";
export interface ProCheckResultsLoadedAction {
  type: typeof PROCHECK_RESULTS_LOADED_TYPE;
  results: INormalisedResults;
  pagination: IPagination;
}
export const proCheckResultsLoaded = (
  results: INormalisedResults,
  pagination: IPagination
): ProCheckResultsLoadedAction => ({
  type: PROCHECK_RESULTS_LOADED_TYPE,
  pagination: pagination,
  results: results,
});

// ProCheck results load error
export const PROCHECK_RESULTS_LOAD_ERROR_TYPE = "PROCHECK_RESULTS_LOAD_ERROR_TYPE";
export interface ProCheckResultsLoadErrorAction {
  type: typeof PROCHECK_RESULTS_LOAD_ERROR_TYPE;
  error: string;
}
export const proCheckResultsLoadError = (
  error: string
): ProCheckResultsLoadErrorAction => ({
  type: PROCHECK_RESULTS_LOAD_ERROR_TYPE,
  error: error,
});

//Load ProCheck Details
export const LOAD_PROCHECK_DETAILS_TYPE = "LOAD_PROCHECK_DETAILS_TYPE";
export interface LoadProCheckDetailsAction {
  type: typeof LOAD_PROCHECK_DETAILS_TYPE;
  organisationId: string;
  propertyId: string;
  procheckId: string;
}
export const loadProCheckDetails = (
  organisationId: string,
  propertyId: string,
  procheckId: string
): LoadProCheckDetailsAction => ({
  type: LOAD_PROCHECK_DETAILS_TYPE,
  organisationId: organisationId,
  propertyId: propertyId,
  procheckId: procheckId,
});

//ProCheck Details Loaded
export const PROCHECK_DETAILS_LOADED_TYPE = "PROCHECK_DETAILS_LOADED_TYPE";
export interface ProCheckDetailsLoadedAction {
  type: typeof PROCHECK_DETAILS_LOADED_TYPE;
  selectedReport: ProCheckDetailsPageState;
  property: Property;
}
export const proCheckDetailsLoaded = (
  selectedReport: ProCheckDetailsPageState,
  property: Property
): ProCheckDetailsLoadedAction => ({
  type: PROCHECK_DETAILS_LOADED_TYPE,
  selectedReport: selectedReport,
  property: property,
});

//Procheck Details Load Error
export const PROCHECK_DETAILS_LOAD_ERROR_TYPE = "PROCHECK_DETAILS_LOAD_ERROR_TYPE";
export interface ProCheckDetailsLoadErrorAction {
  type: typeof PROCHECK_DETAILS_LOAD_ERROR_TYPE;
  error: string;
}
export const proCheckDetailsLoadError = (
  error: string
): ProCheckDetailsLoadErrorAction => ({
  type: PROCHECK_DETAILS_LOAD_ERROR_TYPE,
  error: error,
});

// Download report
export const DOWNLOAD_REPORT_TYPE = "DOWNLOAD_REPORT_TYPE";
export interface DownloadReportAction {
  type: typeof DOWNLOAD_REPORT_TYPE;
  orgId: string;
  propertyId?: string;
  exportDetails: ProCheckExport;
  languageHeader?: string;
}
export const downloadReport = (
  orgId: string,
  exportDetails: ProCheckExport,
  propertyId?: string,
  languageHeader?: string
): DownloadReportAction => ({
  type: DOWNLOAD_REPORT_TYPE,
  orgId: orgId,
  propertyId: propertyId,
  exportDetails: exportDetails,
  languageHeader: languageHeader,
});

// Download report success
export const DOWNLOAD_REPORT_SUCCESS_TYPE = "DOWNLOAD_REPORT_SUCCESS_TYPE";
export interface DownloadReportSuccessAction {
  type: typeof DOWNLOAD_REPORT_SUCCESS_TYPE;
  reportUrl: string;
}
export const downloadReportSuccess = (
  reportUrl: string
): DownloadReportSuccessAction => ({
  type: DOWNLOAD_REPORT_SUCCESS_TYPE,
  reportUrl: reportUrl,
});

// Download report error
export const DOWNLOAD_REPORT_ERROR_TYPE = "DOWNLOAD_REPORT_ERROR_TYPE";
export interface DownloadReportErrorAction {
  type: typeof DOWNLOAD_REPORT_ERROR_TYPE;
  error: string;
}
export const downloadReportError = (error: string): DownloadReportErrorAction => ({
  type: DOWNLOAD_REPORT_ERROR_TYPE,
  error: error,
});

// Pagination navigation actions
export const PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE =
  "PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE";

export interface ProcheckNavigateToPageAction {
  type: typeof PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE;
  page: number;
  organisationId: string;
  userId?: string;
  extraIds?: string[];
  value?: string;
}

export const navigateToProCheckPage = (
  page: number,
  organisationId: string,
  userId?: string,
  extraIds?: string[],
  value?: string
): ProcheckNavigateToPageAction => ({
  type: PROCHECK_PAGINATION_NAVIGATE_TO_PAGE_TYPE,
  organisationId: organisationId,
  userId: userId,
  page: page,
  extraIds: extraIds,
  value: value,
});

export const PROCHECK_FILTER_RESULTS_TYPE = "PROCHECK_FILTER_RESULTS_TYPE";

export interface ProcheckFilterResultsAction {
  type: typeof PROCHECK_FILTER_RESULTS_TYPE;
  value: string;
  organisationId: string;
  userId?: string;
  extraIds?: string[];
}

export const filterProCheckResults = (
  value: string,
  organisationId: string,
  userId?: string,
  extraIds?: string[]
): ProcheckFilterResultsAction => ({
  type: PROCHECK_FILTER_RESULTS_TYPE,
  value: value,
  organisationId: organisationId,
  userId: userId,
  extraIds: extraIds,
});

// Union type
type ProCheckAction =
  | LoadProCheckResultsAction
  | LoadUserProCheckResultsAction
  | ProCheckResultsLoadedAction
  | ProCheckResultsLoadErrorAction
  | DownloadReportAction
  | DownloadReportSuccessAction
  | DownloadReportErrorAction
  | LoadProCheckDetailsAction
  | ProCheckDetailsLoadedAction
  | ProCheckDetailsLoadErrorAction
  | LoadOrgProCheckResultsAction
  | ProcheckNavigateToPageAction
  | ProcheckFilterResultsAction;

export default ProCheckAction;
