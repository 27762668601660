import { TFunction } from "i18next";

type RAGStatus = "red" | "amber" | "green";

export const colorForStatus = (status: RAGStatus): string => {
  switch (status) {
    case "red":
      return "#FF0000";

    case "amber":
      return "#FF7F00";

    case "green":
      return "#069247";
  }
};

export const titleForRAGStatus = (status: RAGStatus, t: TFunction): string => {
  switch (status) {
    case "red":
      return t("ragStatus.red");

    case "amber":
      return t("ragStatus.amber");

    case "green":
      return t("ragStatus.green");
  }
};

export default RAGStatus;
