import AdeyAdminAction from "./AdeyAdminAction";
import AdeyAdminState from "./AdeyAdminState";

const initialState: AdeyAdminState = {
  hasAdminAccess: false,
};

const adeyAdminReducer = (
  state = initialState,
  action: AdeyAdminAction
): AdeyAdminState => {
  switch (action.type) {
    case "ADMIN_ACCESS_CHANGED_TYPE":
      return {
        hasAdminAccess: action.hasAdminAccess,
      };

    default:
      return state;
  }
};

export default adeyAdminReducer;
