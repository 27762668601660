import { useState } from "react";

const useStoreEmails = () => {
  const [remember, setRemember] = useState<boolean>(false);

  const getLocalStorageEmails = (userId: string): string[] => {
    const messages = JSON.parse(localStorage.getItem(`emails_${userId}`) || "[]");
    return messages;
  };

  const addLocalStorageEmail = (userId: string, email: string) => {
    const messageArray: string[] = getLocalStorageEmails(userId);
    if (!messageArray.includes(email)) {
      messageArray.push(email);
    }
    localStorage.setItem(`emails_${userId}`, JSON.stringify(messageArray));
    return messageArray;
  };

  const clearEmailLocalStorage = (userId: string) => {
    localStorage.removeItem(`emails_${userId}`);
  };

  const setRememberFlag = (remember: boolean) => {
    setRemember(remember);
  };

  return {
    getLocalStorageEmails,
    addLocalStorageEmail,
    clearEmailLocalStorage,
    setRememberFlag,
    remember,
  };
};

export default useStoreEmails;
