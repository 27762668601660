import { TFunction } from "i18next";
import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import { formatPropertyAddress } from "../../../utils/formatAddress";
import { formatDate } from "../../../utils/formatDate";
import ProductReportTableItem from "../ProCheckPage/model/ProductReportTableItem";

const mapProCheckReportToProductTableItem = (
  report: ProCheckResult,
  t: TFunction
): ProductReportTableItem => {
  const date = new Date(report.tested_at);
  const statusMessage = report.pass ? t("proCheck.pass") : t("proCheck.recommendation");
  const address = formatPropertyAddress(report.property, t);

  return {
    id: report.id,
    status: report.pass ? "green" : "red",
    statusMessage: statusMessage,
    date: formatDate(date, "dd-MM-yyyy"),
    email: report.user.email,
    propertyId: report.property.id,
    propertyAddress: address,
    orgId: report.organisation_id,
    userId: report.user.id,
    contractor: report.property.contractor?.name ?? "-",
    tester: `${report.user.first_name} ${report.user.last_name}` ?? "-",
  };
};

export default mapProCheckReportToProductTableItem;
