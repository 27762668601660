import { Box, Link, PropTypes, Typography } from "@material-ui/core";
import React from "react";
import { BACKGROUND_ACCENT_COLOR } from "../theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING } from "../theme/dimensions";
import ColoredText from "./ColoredText";

interface Props {
  key?: string;
  label: string;
  text: string | undefined;
  mailLink?: boolean;
  textAlign?: PropTypes.Alignment;
  gutterBottom?: boolean;
  lineBreak?: boolean; //Support for new lines
}

const LabelledText = (props: Props) => {
  let textComponent = (
    <Typography
      align={props.textAlign}
      variant="h5"
      style={{
        whiteSpace: props.lineBreak ? "pre-line" : undefined,
      }}
    >
      {props.text}
    </Typography>
  );
  if (props.mailLink) {
    textComponent = (
      <Link href={`mailto:${props.text}`} color="secondary">
        {textComponent}
      </Link>
    );
  }

  const bottomPadding = props.gutterBottom ? (
    <Box height={EXTRA_LARGE_SPACING} />
  ) : undefined;

  return (
    <Box display="flex" flexDirection="column">
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
        <strong>{props.label}</strong>
      </ColoredText>

      {textComponent}

      {bottomPadding}
    </Box>
  );
};

export default LabelledText;
