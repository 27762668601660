import CurrentUserAction, {
  CURRENT_USER_CHANGE_PASSWORD_FAILURE_TYPE,
  CURRENT_USER_CHANGE_PASSWORD_TYPE,
  CURRENT_USER_LOADED_TYPE,
  CURRENT_USER_LOAD_ERROR_TYPE,
  CURRENT_USER_PASSWORD_CHANGED_TYPE,
  CURRENT_USER_UPDATED_TYPE,
  CURRENT_USER_UPDATE_ERROR_TYPE,
  LOAD_CURRENT_USER_TYPE,
} from "./CurrentUserAction";
import CurrentUserState from "./CurrentUserState";

const initialState: CurrentUserState = {
  user: undefined,
  isLoading: false,
  error: undefined,
  success: undefined,
};

const currentUserReducer = (
  state = initialState,
  action: CurrentUserAction
): CurrentUserState => {
  switch (action.type) {
    case LOAD_CURRENT_USER_TYPE:
      return {
        user: undefined,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    case CURRENT_USER_LOADED_TYPE:
      return {
        user: action.user,
        isLoading: false,
        error: undefined,
        success: undefined,
      };
    case CURRENT_USER_LOAD_ERROR_TYPE:
      return {
        user: undefined,
        isLoading: false,
        error: action.error,
        success: undefined,
      };
    case CURRENT_USER_UPDATED_TYPE:
      return {
        user: action.user,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    case CURRENT_USER_CHANGE_PASSWORD_TYPE:
      return {
        user: state.user,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    case CURRENT_USER_CHANGE_PASSWORD_FAILURE_TYPE:
      return {
        user: state.user,
        isLoading: false,
        error: action.error,
        success: undefined,
      };
    case CURRENT_USER_UPDATE_ERROR_TYPE:
      return {
        user: state.user,
        isLoading: false,
        error: action.error,
        success: undefined,
      };
    case CURRENT_USER_PASSWORD_CHANGED_TYPE:
      return {
        user: state.user,
        isLoading: false,
        error: undefined,
        success: action.success,
      };

    default:
      return state;
  }
};

export default currentUserReducer;
