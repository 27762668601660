import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import LabelledText from "../../../../ui/components/LabelledText";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithTranslation {
  organisation: Organisation;
}

const OrganisationDetailPanel = (props: Props) => {
  const organisation = props.organisation;

  const orgNameLabel = props.t("orgDetailPage.orgNameLabel");
  const addressLabel = props.t("orgDetailPage.addressLabel");
  const vatLabel = props.t("orgDetailPage.vatLabel");
  const contactNumberLabel = props.t("orgDetailPage.numberLabel");

  const formatAddress = (): string => {
    return [
      props.organisation.address_line_1,
      props.organisation.address_line_2,
      props.organisation.address_line_2,
      props.organisation.address_city,
      props.organisation.address_postcode,
      props.t("countryCode." + props.organisation.address_country.code),
    ]
      .filter((line) => typeof line === "string")
      .flatMap((line) => (line as string).trim())
      .filter((line) => line.length > 0)
      .join(", ");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="top"
      paddingX={LARGE_SPACING}
      justifyContent="flex-start"
      marginX={EXTRA_LARGE_SPACING}
    >
      <Box flexGrow={1}>
        <LabelledText label={orgNameLabel} text={organisation.name} gutterBottom />
        <LabelledText label={addressLabel} text={formatAddress()} gutterBottom />
        <LabelledText
          label={contactNumberLabel}
          text={organisation.phone_number}
          gutterBottom
        />
        <LabelledText
          label={vatLabel}
          text={organisation.vat_number || "No VAT number"}
          gutterBottom
        />
      </Box>
    </Box>
  );
};
export default withTranslation()(OrganisationDetailPanel);
