import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import { RoleName } from "../../../../rest/roles/model/Role";
import UserDetails from "../../../../rest/user/model/UserDetails";

//Init Agent Details Load
export const LOAD_AGENT_DETAILS_TYPE = "LOAD_AGENT_DETAILS";

export interface LoadAgentDetailsAction {
  type: typeof LOAD_AGENT_DETAILS_TYPE;
  agentOrgId: string;
}

export const loadAgentDetails = (agentOrgId: string): LoadAgentDetailsAction => ({
  type: LOAD_AGENT_DETAILS_TYPE,
  agentOrgId: agentOrgId,
});

//Update Agent Details
export const UPDATE_AGENT_DETAILS_TYPE = "UPDATE_AGENT_DETAILS";

export interface UpdateAgentDetailsAction {
  type: typeof UPDATE_AGENT_DETAILS_TYPE;
  updatedAgentDetails: Organisation;
}

export const updateAgentDetails = (
  updatedAgentDetails: Organisation
): UpdateAgentDetailsAction => ({
  type: UPDATE_AGENT_DETAILS_TYPE,
  updatedAgentDetails: updatedAgentDetails,
});

//Agent Details Loaded
export const AGENT_DETAILS_LOADED_TYPE = "AGENT_DETAILS_LOADED";

export interface AgentDetailsLoadedAction {
  type: typeof AGENT_DETAILS_LOADED_TYPE;
  agentOrg: Organisation;
}

export const agentDetailsLoaded = (agentOrg: Organisation): AgentDetailsLoadedAction => ({
  type: AGENT_DETAILS_LOADED_TYPE,
  agentOrg: agentOrg,
});

//Agent Details Load Error
export const AGENT_DETAILS_LOAD_ERROR_TYPE = "AGENT_DETAILS_LOAD_ERROR";

export interface LoadAgentDetailsErrorAction {
  type: typeof AGENT_DETAILS_LOAD_ERROR_TYPE;
  error: string;
}

export const loadAgentDetailsError = (error: string): LoadAgentDetailsErrorAction => ({
  type: AGENT_DETAILS_LOAD_ERROR_TYPE,
  error: error,
});

//Init Managed Orgs Load
export const LOAD_MANAGED_ORGANISATION_TYPE = "LOAD_MANAGED_ORGANISATION";

export interface LoadManagedOrganisationAction {
  type: typeof LOAD_MANAGED_ORGANISATION_TYPE;
}

export const loadManagedOrganisations = (): LoadManagedOrganisationAction => ({
  type: LOAD_MANAGED_ORGANISATION_TYPE,
});

//Managed Orgs Loaded
export const MANAGED_ORGANISATION_LOADED_TYPE = "MANAGED_ORGANISATION_LOADED";

export interface ManagedOrganisationsLoadedAction {
  type: typeof MANAGED_ORGANISATION_LOADED_TYPE;
  managedOrgs: Organisation[];
}

export const managedOrganisationsLoaded = (
  managedOrgs: Organisation[]
): ManagedOrganisationsLoadedAction => ({
  type: MANAGED_ORGANISATION_LOADED_TYPE,
  managedOrgs: managedOrgs,
});

//Managed Orgs Load Error
export const LOAD_MANAGED_ORGANISATION_ERROR_TYPE = "LOAD_MANAGED_ORGANISATION_ERROR";

export interface LoadManagedOrganisationErrorAction {
  type: typeof LOAD_MANAGED_ORGANISATION_ERROR_TYPE;
  error: string;
}

export const loadManagedOrganisationsError = (
  error: string
): LoadManagedOrganisationErrorAction => ({
  type: LOAD_MANAGED_ORGANISATION_ERROR_TYPE,
  error: error,
});

//Remove Managed Org
export const REMOVE_MANAGED_ORGANISATION_TYPE = "REMOVE_MANAGED_ORGANISATION";

export interface RemoveManagedOrganisationAction {
  type: typeof REMOVE_MANAGED_ORGANISATION_TYPE;
  org: Organisation;
}

export const removeManagedOrganisation = (
  org: Organisation
): RemoveManagedOrganisationAction => ({
  type: REMOVE_MANAGED_ORGANISATION_TYPE,
  org: org,
});

//Managed Organisation Removed
export const MANAGED_ORGANISATION_REMOVED_TYPE = "MANAGED_ORGANISATION_REMOVED";

export interface ManagedOrganisationRemovedAction {
  type: typeof MANAGED_ORGANISATION_REMOVED_TYPE;
  removedOrg: Organisation;
}

export const managedOrganisationRemoved = (
  removedOrg: Organisation
): ManagedOrganisationRemovedAction => ({
  type: MANAGED_ORGANISATION_REMOVED_TYPE,
  removedOrg: removedOrg,
});

//Manage Organisation Search Changed
export const MANAGED_ORGANISATIONS_SEARCH_TYPE = "MANAGED_ORGANISATIONS_SEARCH";

export interface SearchManagedOrganisationAction {
  type: typeof MANAGED_ORGANISATIONS_SEARCH_TYPE;
  searchText: string;
}

export const searchManagedOrganisations = (searchText: string) => ({
  type: MANAGED_ORGANISATIONS_SEARCH_TYPE,
  searchText: searchText,
});

//Unassign Agent
export const REVOKE_AGENT_ACCESS_TYPE = "REVOKE_AGENT_ACCESS_TYPE";

export interface RevokeAgentAccessAction {
  type: typeof REVOKE_AGENT_ACCESS_TYPE;
  agentOrg: Organisation;
}

export const revokeAgentAccess = (agentOrg: Organisation): RevokeAgentAccessAction => ({
  type: REVOKE_AGENT_ACCESS_TYPE,
  agentOrg: agentOrg,
});

//Agent Unassigned
export const AGENT_ACCESS_REVOKED_TYPE = "AGENT_ACCESS_REVOKED_TYPE";

export interface AgentAccessRevokedAction {
  type: typeof AGENT_ACCESS_REVOKED_TYPE;
  demotedAgentOrg: Organisation;
}

export const agentAccesRevoked = (
  demotedAgentOrg: Organisation
): AgentAccessRevokedAction => ({
  type: AGENT_ACCESS_REVOKED_TYPE,
  demotedAgentOrg: demotedAgentOrg,
});

//Unassign Agent Error
export const REVOKE_AGENT_ACCESS_ERROR_TYPE = "REVOKE_AGENT_ACCESS_ERROR_TYPE";

export interface RevokeAgentAccessErrorAction {
  type: typeof REVOKE_AGENT_ACCESS_ERROR_TYPE;
  error: string;
}

export const revokeAgentAccessError = (error: string): RevokeAgentAccessErrorAction => ({
  type: REVOKE_AGENT_ACCESS_ERROR_TYPE,
  error: error,
});

//Load Agent Users
export const LOAD_AGENT_ORG_USERS_TYPE = "LOAD_AGENT_ORG_USERS_TYPE";
export interface LoadAgentOrgUsersAction {
  type: typeof LOAD_AGENT_ORG_USERS_TYPE;
  organisationId: string;
}
export const loadAgentOrgUsers = (organisationId: string): LoadAgentOrgUsersAction => ({
  type: LOAD_AGENT_ORG_USERS_TYPE,
  organisationId: organisationId,
});

//Agent Users Loaded
export const AGENT_ORG_USERS_LOADED_TYPE = "AGENT_ORG_USERS_LOADED_TYPE";
export interface AgentOrgUsersLoadedAction {
  type: typeof AGENT_ORG_USERS_LOADED_TYPE;
  users: OrganisationUser[];
  pendingInvites: PendingInvite[];
}

export const agentOrgUsersLoaded = (
  users: OrganisationUser[],
  pendingInvites: PendingInvite[]
): AgentOrgUsersLoadedAction => ({
  type: AGENT_ORG_USERS_LOADED_TYPE,
  users: users,
  pendingInvites: pendingInvites,
});

//Agent Users Load Error
export const AGENT_ORG_USERS_LOAD_ERROR_TYPE = "AGENT_ORG_USERS_LOAD_ERROR_TYPE";

export interface AgentOrgUsersLoadErrorAction {
  type: typeof AGENT_ORG_USERS_LOAD_ERROR_TYPE;
  error: string;
}
export const agentOrgUsersLoadError = (error: string): AgentOrgUsersLoadErrorAction => ({
  type: AGENT_ORG_USERS_LOAD_ERROR_TYPE,
  error: error,
});

//Invite Agent User
export const INVITE_AGENT_USER_TYPE = "INVITE_AGENT_USER_TYPE";

export interface InviteAgentUserAction {
  type: typeof INVITE_AGENT_USER_TYPE;
  organisationId: string;
  email: string;
  role: RoleName;
}
export const inviteAgentUser = (
  organisationId: string,
  email: string,
  role: RoleName
): InviteAgentUserAction => ({
  type: INVITE_AGENT_USER_TYPE,
  organisationId: organisationId,
  email: email,
  role: role,
});

//Agent User Invited
export const INVITE_AGENT_USER_SUCCESS_TYPE = "INVITE_AGENT_USER_SUCCESS_TYPE";

export interface InviteAgentUserSuccessAction {
  type: typeof INVITE_AGENT_USER_SUCCESS_TYPE;
  invite: PendingInvite;
}
export const inviteAgentUserSuccess = (
  invite: PendingInvite
): InviteAgentUserSuccessAction => ({
  type: INVITE_AGENT_USER_SUCCESS_TYPE,
  invite: invite,
});

//Agent User Invite Error
export const INVITE_AGENT_USER_ERROR_TYPE = "INVITE_AGENT_USER_ERROR_TYPE";
export interface InviteAgentUserErrorAction {
  type: typeof INVITE_AGENT_USER_ERROR_TYPE;
  error: string;
}
export const inviteAgentUserError = (error: string): InviteAgentUserErrorAction => ({
  type: INVITE_AGENT_USER_ERROR_TYPE,
  error: error,
});

//Remove User Agent
export const REMOVE_AGENT_USER_TYPE = "REMOVE_AGENT_USER_TYPE";
export interface RemoveAgentUserAction {
  user: UserDetails;
  organisation: Organisation;
  type: typeof REMOVE_AGENT_USER_TYPE;
}

export const removeAgentUser = (
  user: UserDetails,
  organisation: Organisation
): RemoveAgentUserAction => ({
  user: user,
  organisation: organisation,
  type: REMOVE_AGENT_USER_TYPE,
});

//User Agent Removed
export const AGENT_USER_REMOVED_TYPE = "AGENT_USER_REMOVED_TYPE";
export interface AgentUserRemovedAction {
  type: typeof AGENT_USER_REMOVED_TYPE;
  removedUser: OrganisationUser;
}

export const agentUserRemoved = (
  removedUser: OrganisationUser
): AgentUserRemovedAction => ({
  type: AGENT_USER_REMOVED_TYPE,
  removedUser: removedUser,
});

//User Agent Remove Error
export const REMOVE_AGENT_USER_ERROR = "REMOVE_AGENT_USER_ERROR";
export interface RemoveAgentUserError {
  type: typeof REMOVE_AGENT_USER_ERROR;
  error: string;
}

export const removeAgentUserError = (error: string): RemoveAgentUserError => ({
  type: REMOVE_AGENT_USER_ERROR,
  error: error,
});

type PlatformAgentDetailsAction =
  | LoadAgentDetailsAction
  | AgentDetailsLoadedAction
  | LoadAgentDetailsErrorAction
  | LoadManagedOrganisationAction
  | ManagedOrganisationsLoadedAction
  | LoadManagedOrganisationErrorAction
  | ManagedOrganisationRemovedAction
  | RemoveManagedOrganisationAction
  | RevokeAgentAccessErrorAction
  | RevokeAgentAccessAction
  | AgentAccessRevokedAction
  | SearchManagedOrganisationAction
  | UpdateAgentDetailsAction
  | LoadAgentOrgUsersAction
  | AgentOrgUsersLoadedAction
  | AgentOrgUsersLoadErrorAction
  | InviteAgentUserAction
  | InviteAgentUserSuccessAction
  | InviteAgentUserErrorAction
  | RemoveAgentUserAction
  | AgentUserRemovedAction
  | RemoveAgentUserError;

export default PlatformAgentDetailsAction;
