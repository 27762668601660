import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../../redux/RootState";
import { navigateToProCheckPage } from "../../../../procheck/redux/ProCheckAction";
import TProCheckReportTab from "../../../../../utils/types/TProCheckReportTab";
import { useParams } from "react-router";

const useReportPagination = (reportsTab: TProCheckReportTab = "org-reports") => {
  //Selector
  const { currentPage, maxPages, itemsPerPage, displayItems } = useSelector(
    (state: RootState) => {
      return state.proCheck.pagination;
    }
  );

  const { currentOrg, isAdmin, selectedOrg } = useSelector((state: RootState) => {
    return {
      proCheckState: state.proCheck,
      userId: state.login.currentUserId,
      currentOrg: state.activeOrganisation.currentOrganisation,
      isAdmin: state.adeyAdmin.hasAdminAccess,
      selectedOrg: state.orgDetail,
      isLoading: state.proCheck.isLoading,
    };
  });

  //Dispatch
  const dispatch = useDispatch();

  //URL Params
  const { userId } = useParams<{ userId?: string }>();

  const onPageNumberChanged = (pageNumber: number, extraIds?: string[]) => {
    if (currentOrg) {
      const orgId = isAdmin ? selectedOrg.organisation?.id || "" : currentOrg.id;

      dispatch(navigateToProCheckPage(pageNumber, orgId, userId, extraIds));
    }
  };

  return { currentPage, maxPages, itemsPerPage, displayItems, onPageNumberChanged };
};

export default useReportPagination;
