import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Country } from "../../../rest/countries/model/Country";
import Organisation from "../../../rest/organisation/model/Organisation";
import TableList from "../TableList/TableList";
import AgentTableRow from "./components/AgentTableRow";

//MARK: Style Const
const smallCellWidth = "15vw";

interface Props extends WithTranslation {
  agents: Organisation[];
  countries: Country[];
  onAgentClicked?: (agent: Organisation) => void;
}

const AgentTable = (props: Props) => {
  //Localization keys for the header
  const headerKeys: string[] = ["agentTable.phoneHeader", "agentTable.countryHeader"];
  const headerTitle: string = "agentTable.nameHeader";

  //Getting localised country name
  const getCountryName = (agent: Organisation) => {
    return props.t(`countryCode.${agent.is_agent_for?.code || "missing"}`);
  };

  //Mapping agent organisations to row items
  const items = () => {
    return props.agents.map((agent) => (
      <AgentTableRow
        agent={agent}
        countryText={getCountryName(agent)}
        key={agent.id}
        smallCellWidth={smallCellWidth}
        onClick={props.onAgentClicked}
      />
    ));
  };

  return (
    <TableList itemRows={items()} headerKeys={headerKeys} headerTitle={headerTitle} />
  );
};

export default withTranslation()(AgentTable);
