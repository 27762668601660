import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Property from "../../../../rest/properties/model/Property";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import Address from "../model/Address";
import getAddress from "../utils/getAddress";
import EditableAddress from "./EditableField/EditableAddress";
import EditableField from "./EditableField/EditableField";

interface Props extends WithTranslation {
  property: Property;
  loading: boolean;
  error: string | undefined;
  lookupEnabled?: boolean;
  isValidProperty: boolean;

  onPropertyUpdated: (property: Property) => void;
}

type PanelItem = "address" | "name" | "number" | "email" | "notes";

interface State {
  openField: PanelItem | undefined;
}

class AddressPanel extends React.Component<Props, State> {
  state = {
    openField: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.loading && !this.props.loading && this.props.error === undefined) {
      this.setState({ openField: undefined });
    }
  }

  render() {
    const addressLabel = this.props.t("addressPanel.addressHeader");
    const contactLabel = this.props.t("addressPanel.contactHeader");
    const contactNumberLabel = this.props.t("addressPanel.contactNumberHeader");
    const emailLabel = this.props.t("addressPanel.emailHeader");
    const notesLabel = this.props.t("addressPanel.notesHeader");
    const limboAddressMessage = this.props.t("limboAddress.message");

    // Only enable hover if no field is being edited;
    const hoverEnabled = this.state.openField === undefined;

    return (
      <Box>
        <EditableAddress
          lookupEnabled={this.props.lookupEnabled}
          country={this.props.property.address_country}
          label={addressLabel}
          address={getAddress(this.props.property)}
          isEditing={this.state.openField === "address"}
          hoverEnabled={hoverEnabled}
          onEditClicked={() => this.handleEditClicked("address")}
          onCancelClicked={this.handleCancelClicked}
          onSaveClicked={this.handleAddressSaved}
          loading={this.props.loading}
        />
        {!this.props.isValidProperty && (
          <Box maxWidth={"600px"}>
            <Alert
              style={{ marginTop: LARGE_SPACING, marginBottom: SMALL_SPACING }}
              icon={false}
              severity={"warning"}
            >
              {limboAddressMessage}
            </Alert>
          </Box>
        )}
        {this.props.isValidProperty && (
          <>
            <EditableField
              label={contactLabel}
              text={this.props.property.contact_name}
              isEditing={this.state.openField === "name"}
              hoverEnabled={hoverEnabled && this.props.isValidProperty}
              onEditClicked={() => this.handleEditClicked("name")}
              onCancelClicked={this.handleCancelClicked}
              onSaveClicked={(value) => this.handleFieldSaved("name", value)}
              loading={this.props.loading}
              gutterBottom
            />

            <EditableField
              label={contactNumberLabel}
              text={this.props.property.contact_phone}
              isEditing={this.state.openField === "number"}
              hoverEnabled={hoverEnabled && this.props.isValidProperty}
              onEditClicked={() => this.handleEditClicked("number")}
              onCancelClicked={this.handleCancelClicked}
              onSaveClicked={(value) => this.handleFieldSaved("number", value)}
              loading={this.props.loading}
              gutterBottom
            />

            <EditableField
              label={emailLabel}
              text={this.props.property.contact_email}
              isEditing={this.state.openField === "email"}
              hoverEnabled={
                hoverEnabled &&
                !this.props.property.homezone_reporting_enabled &&
                this.props.isValidProperty
              }
              onEditClicked={() => this.handleEditClicked("email")}
              onCancelClicked={this.handleCancelClicked}
              onSaveClicked={(value) => this.handleFieldSaved("email", value)}
              loading={this.props.loading}
              gutterBottom
            />

            <EditableField
              label={notesLabel}
              text={this.props.property.notes}
              isEditing={this.state.openField === "notes"}
              hoverEnabled={hoverEnabled && this.props.isValidProperty}
              editorFieldProps={{ type: "text", multiline: true }}
              onEditClicked={() => this.handleEditClicked("notes")}
              onCancelClicked={this.handleCancelClicked}
              onSaveClicked={(value) => this.handleFieldSaved("notes", value)}
              loading={this.props.loading}
              gutterBottom
            />
          </>
        )}
      </Box>
    );
  }

  handleEditClicked = (item: PanelItem) => {
    // Don't allow opening a field if it's already open
    this.setState((prevState) => ({
      openField: prevState.openField || item,
    }));
  };

  handleCancelClicked = () => {
    this.setState({ openField: undefined });
  };

  handleAddressSaved = (address: Address) => {
    const newProperty = {
      ...this.props.property,
      address_line_1: address.line1,
      address_line_2: address.line2,
      address_line_3: address.line3,
      address_city: address.city,
      address_postcode: address.postcode,
      address_country: { id: "", name: "", code: address.country }, //Name and id do not matter
    };
    this.props.onPropertyUpdated(newProperty);
  };

  handleFieldSaved = (field: PanelItem, value: string) => {
    const newProperty = { ...this.props.property };

    switch (field) {
      case "email":
        newProperty.contact_email = value;
        break;

      case "name":
        newProperty.contact_name = value;
        break;

      case "number":
        newProperty.contact_phone = value;
        break;

      case "notes":
        newProperty.notes = value;
    }

    this.props.onPropertyUpdated(newProperty);
  };
}

export default withTranslation()(AddressPanel);
