import { IMoveActionResult } from "../services/AdminMoveEntityService";
import TEntityToMove from "../types/TEntityToMove";
import TMoveDestination from "../types/TMoveDestination";

//Init entity move
export const INIT_ADMIN_MOVE_ENTITIES_TYPE = "INIT_ADMIN_MOVE_ENTITIES";

export interface InitAdminMoveEntitiesAction {
  entityType: TEntityToMove;
  idsToMove: string[];
  destinationId: string;
  originOrganisationId: string;
  originId?: string;
  type: typeof INIT_ADMIN_MOVE_ENTITIES_TYPE;
}

export const initAdminMoveEntities = (
  entityType: TEntityToMove,
  idsToMove: string[],
  destinationId: string,
  originOrganisationId: string,
  originId?: string
): InitAdminMoveEntitiesAction => {
  return {
    type: INIT_ADMIN_MOVE_ENTITIES_TYPE,
    entityType: entityType,
    idsToMove: idsToMove,
    originOrganisationId: originOrganisationId,
    destinationId: destinationId,
    originId: originId,
  };
};

//Entity moved success
export const ADMIN_MOVE_ENTITIES_SUCCESS_TYPE = "ADMIN_MOVE_ENTITIES_SUCCESS";

export interface AdminMoveEntitiesSuccessAction {
  type: typeof ADMIN_MOVE_ENTITIES_SUCCESS_TYPE;
  moveResults: IMoveActionResult;
}

export const adminMoveEntitiesSuccess = (
  moveResults: IMoveActionResult
): AdminMoveEntitiesSuccessAction => {
  return { type: ADMIN_MOVE_ENTITIES_SUCCESS_TYPE, moveResults: moveResults };
};

//Entity move error
export const ADMIN_MOVE_ENTITIES_ERROR_TYPE = "ADMIN_MOVE_ENTITIES_ERROR";

export interface AdminMoveEntitiesErrorAction {
  type: typeof ADMIN_MOVE_ENTITIES_ERROR_TYPE;
  moveResults: IMoveActionResult;
  error: string;
}

export const adminMoveEntitiesError = (
  error: string,
  moveResults: IMoveActionResult
): AdminMoveEntitiesErrorAction => {
  return {
    type: ADMIN_MOVE_ENTITIES_ERROR_TYPE,
    moveResults: moveResults,
    error: error,
  };
};

//Search destination init
export const ADMIN_INIT_DESTINATION_SEARCH_TYPE = "ADMIN_INIT_DESTINATION_SEARCH";

export interface AdminInitDestinationSearchAction {
  type: typeof ADMIN_INIT_DESTINATION_SEARCH_TYPE;
  entityType: TEntityToMove;
  queryString: string;
  entityScopeId?: string;
}

export const adminInitDestinationSearch = (
  queryString: string,
  entityType: TEntityToMove,
  entityScopeId?: string
): AdminInitDestinationSearchAction => {
  return {
    type: ADMIN_INIT_DESTINATION_SEARCH_TYPE,
    entityType: entityType,
    queryString: queryString,
    entityScopeId: entityScopeId,
  };
};

//Search destination success
export const ADMIN_DESTINATION_SEARCH_SUCCESS_TYPE = "ADMIN_DESTINATION_SEARCH_SUCCESS";

export interface AdminDestinationSearchSuccessAction {
  type: typeof ADMIN_DESTINATION_SEARCH_SUCCESS_TYPE;
  destinationItems: TMoveDestination[];
}

export const adminDestinationSearchSuccess = (
  destinationItems: TMoveDestination[]
): AdminDestinationSearchSuccessAction => {
  return {
    type: ADMIN_DESTINATION_SEARCH_SUCCESS_TYPE,
    destinationItems: destinationItems,
  };
};

//Search destination error
export const ADMIN_DESTINATION_SEARCH_ERROR_TYPE = "ADMIN_DESTINATION_SEARCH_ERROR";

export interface AdminSearchDestinationErrorAction {
  type: typeof ADMIN_DESTINATION_SEARCH_ERROR_TYPE;
  error: string;
}

export const adminSearchDestinationError = (
  error: string
): AdminSearchDestinationErrorAction => {
  return {
    type: ADMIN_DESTINATION_SEARCH_ERROR_TYPE,
    error: error,
  };
};

//Reset - TODO: check if needed
export const ADMIN_RESET_MOVE_STATE_TYPE = "ADMIN_RESET_MOVE_STATE";

export interface AdminResetMoveStateAction {
  type: typeof ADMIN_RESET_MOVE_STATE_TYPE;
}

export const adminResetMoveState = (): AdminResetMoveStateAction => {
  return {
    type: ADMIN_RESET_MOVE_STATE_TYPE,
  };
};

type AdminMoveEntityAction =
  | InitAdminMoveEntitiesAction
  | AdminMoveEntitiesSuccessAction
  | AdminMoveEntitiesErrorAction
  | AdminInitDestinationSearchAction
  | AdminDestinationSearchSuccessAction
  | AdminSearchDestinationErrorAction
  | AdminResetMoveStateAction;

export default AdminMoveEntityAction;
