import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { SECONDARY_COLOR } from "../../theme/createMaterialTheme";
import { BORDER_RADIUS, DEFAULT_SPACING, LARGE_SPACING } from "../../theme/dimensions";

interface Props {
  title?: string;
}

interface State {
  isOpen: boolean;
}

class CollapsiblePanel extends React.Component<Props, State> {
  state = { isOpen: true };

  render() {
    const icon = this.state.isOpen ? faCaretDown : faCaretUp;

    return (
      <Box>
        <ButtonBase
          style={{ padding: DEFAULT_SPACING, borderRadius: BORDER_RADIUS }}
          onClick={this.clickHandler}
        >
          <Typography
            style={{ textTransform: "uppercase" }}
            color="secondary"
            variant="h5"
          >
            <Trans>{this.props.title}</Trans>
          </Typography>

          <FontAwesomeIcon
            style={{ marginLeft: LARGE_SPACING }}
            icon={icon}
            size="2x"
            color={SECONDARY_COLOR}
          />
        </ButtonBase>

        {this.state.isOpen ? this.props.children : undefined}
      </Box>
    );
  }

  clickHandler = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };
}

export default CollapsiblePanel;
