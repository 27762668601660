import { applyMiddleware, combineReducers, compose, createStore, Store } from "redux";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import inviteOrganisationAdminReducer from "../pages/admin/AdminInviteOrganisationDialog/components/InviteOrganisationAdminForm/redux/InviteOrganisationAdminReducer";
import inviteOrganisationAdminSagas from "../pages/admin/AdminInviteOrganisationDialog/components/InviteOrganisationAdminForm/redux/InviteOrganisationAdminSagas";
import adminCreateOrganisationReducer from "../pages/admin/AdminInviteOrganisationDialog/redux/adminCreateOrganisationReducer";
import adminCreateOrganisationSaga from "../pages/admin/AdminInviteOrganisationDialog/redux/adminCreateOrganisationSaga";
import currentUserReducer from "../pages/account/AccountSettingsPage/redux/currentUserReducer";
import currentUserSagas from "../pages/account/AccountSettingsPage/redux/currentUserSagas";
import accountActivationReducer from "../pages/auth/AccountActivation/redux/accountActivationReducer";
import accountActivationSagas from "../pages/auth/AccountActivation/redux/accountActivationSagas";
import accountSetupReducer from "../pages/auth/AccountSetup/redux/accountSetupReducer";
import accountSetupSagas from "../pages/auth/AccountSetup/redux/accountSetupSagas";
import forgotPasswordReducer from "../pages/auth/ForgotPassword/redux/forgotPasswordReducer";
import forgotPasswordSagas from "../pages/auth/ForgotPassword/redux/forgotPasswordSagas";
import { checkLoginStatus, LOG_OUT_TYPE } from "../pages/auth/Login/redux/LoginAction";
import loginReducer from "../pages/auth/Login/redux/loginReducer";
import loginSagas from "../pages/auth/Login/redux/loginSagas";
import registrationReducer from "../pages/auth/Registration/redux/registrationReducer";
import registrationSagas from "../pages/auth/Registration/redux/registrationSagas";
import resendActivationEmailReducer from "../pages/auth/ResendActivationEmail/redux/resendActivationEmailReducer";
import resendActivationEmailSagas from "../pages/auth/ResendActivationEmail/redux/resendActivationEmailSagas";
import propertyDetailReducer from "../pages/properties/PropertyDetailPage/redux/propertyDetailReducer";
import propertyDetailSagas from "../pages/properties/PropertyDetailPage/redux/propertyDetailSagas";
import propertyListReducer from "../pages/properties/PropertyListPage/redux/propertyListReducer";
import propertyListSagas from "../pages/properties/PropertyListPage/redux/propertyListSagas";
import activeOrganisationReducer from "../ui/components/OrganisationSwitcher/redux/ActiveOrganisationReducer";
import activeOrganisationSagas from "../ui/components/OrganisationSwitcher/redux/activeOrganisationSagas";
import RootAction from "./RootAction";
import RootState from "./RootState";
import SagaContext, { defaultSagaContext } from "./SagaContext";
import userListReducer from "../pages/users/list/redux/userListReducer";
import userListSagas from "../pages/users/list/redux/userListSagas";
import pendingInvitesReducer from "../pages/invites/redux/pendingInviteReducer";
import pendingInviteSagas from "../pages/invites/redux/pendingInvitesSagas";
import currentInviteSagas from "../pages/invites/InviteDialog/redux/currentInviteSagas";
import pendingInviteMenuReducer from "../ui/components/PendingInviteMenu/redux/pendingInviteMenuReducer";
import pendingInviteMenuSagas from "../ui/components/PendingInviteMenu/redux/pendingInviteMenuSagas";
import inviteEntityReducer from "../pages/invites/InviteDialog/redux/currentInviteReducer";
import organisationListReducer from "../pages/organisations/list/redux/organisationListReducer";
import organisationListSagas from "../pages/organisations/list/redux/organisationListSagas";
import userDetailReducer from "../pages/users/detail/redux/userDetailReducer";
import userDetailSagas from "../pages/users/detail/redux/userDetailSagas";
import organisationDetailReducer from "../pages/organisations/details/redux/organisationDetailReducer";
import organisationDetailSagas from "../pages/organisations/details/redux/organisationDetailSagas";
import adeyAdminSagas from "../pages/admin/redux/adeyAdminSagas";
import adeyAdminReducer from "../pages/admin/redux/adeyAdminReducer";
import editCurrentOrganisationReducer from "../pages/organisations/current/redux/editCurrentOrganisationReducer";
import editCurrentOrganisationSagas from "../pages/organisations/current/redux/editCurrentOrganisationSagas";
import createOrganisationReducer from "../pages/organisations/create/redux/createOrganisationReducer";
import createOrganisationSagas from "../pages/organisations/create/redux/createOrganisationSagas";
import addressLookupReducer from "../ui/components/AddressLookupDialog/redux/addressLookupReducer";
import addressLookupSagas from "../ui/components/AddressLookupDialog/redux/addressLookupSagas";
import platformUserListReducer from "../pages/admin/PlatformUserListPage/redux/platformUserListReducer";
import platformUserListSagas from "../pages/admin/PlatformUserListPage/redux/platformUserListSagas";
import proCheckReducer from "../pages/procheck/redux/proCheckReducer";
import proCheckSagas from "../pages/procheck/redux/proCheckSagas";
import platformOrganisationListReducer from "../pages/admin/PlatformOrganisationListPage/redux/platformOrganisationListReducer";
import platformOrganisationListSagas from "../pages/admin/PlatformOrganisationListPage/redux/platformOrganisationListSagas";
import platformUserDetailReducer from "../pages/admin/PlatformUserDetailPage/redux/platformUserDetailReducer";
import platformUserDetailSagas from "../pages/admin/PlatformUserDetailPage/redux/platformUserDetailSagas";
import adminOrganisationDetailsReducer from "../pages/admin/AdminOrganisationDetailsPage/redux/adminOrganisationDetailsReducer";
import adminOrganisationDetailsSagas from "../pages/admin/AdminOrganisationDetailsPage/redux/adminOrganisationDetailsSagas";
import dashboardReducer from "../ui/components/DashboardLayout/redux/dashboardReducer";
import dashboardSagas from "../ui/components/DashboardLayout/redux/dashboardSagas";
import countryReducer from "../pages/countries/redux/countryReducer";
import countrySagas from "../pages/countries/redux/countrySagas";
import platformAgentListReducer from "../pages/admin/PlatformAgentListPage/redux/platformAgentListReducer";
import platformAgentListSagas from "../pages/admin/PlatformAgentListPage/redux/platformAgentListSagas";
import platformAgentDetailsReducer from "../pages/admin/PlatformAgentDetailsPage/redux/platformAgentDetailsReducer";
import platformAgentDetailsSagas from "../pages/admin/PlatformAgentDetailsPage/redux/platformAgentDetailsSagas";
import adeyAgentReducer from "../pages/agents/redux/adeyAgentReducer";
import adeyAgentSagas from "../pages/agents/redux/adeyAgentSagas";
import exportBarReducer from "../ui/components/ExportBar/redux/exportBarReducer";
import exportBarSagas from "../ui/components/ExportBar/redux/exportBarSagas";
import senseDevicesReducer from "../pages/devices/redux/senseDevicesReducer";
import senseDeviceSagas from "../pages/devices/redux/senseDevicesSagas";
import homeZoneReducer from "../pages/homezone/redux/homeZoneReducer";
import homeZoneSagas from "../pages/homezone/redux/homeZoneSagas";
import systemMessageReducer from "../pages/auth/SystemMessagesDialogs/redux/systemMessageReducer";
import systemMessageSagas from "../pages/auth/SystemMessagesDialogs/redux/systemMessageSagas";
import adminMoveEntityReducer from "../pages/admin/AdminMoveEntity/redux/adminMoveEntityReducer";
import adminMoveEntitySagas from "../pages/admin/AdminMoveEntity/redux/adminMoveEntitySagas";

// tell typescript that window has the redux devtools property.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const createRootStore = (): Store<RootState, RootAction> => {
  // get the dev tools if they're available.
  const reduxDevTools =
    process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null;
  const composeEnhancers = reduxDevTools || compose;

  const combinedReducer = combineReducers({
    adeyAgent: adeyAgentReducer,
    adeyAdmin: adeyAdminReducer,
    accountActivation: accountActivationReducer,
    accountSetup: accountSetupReducer,
    currentUser: currentUserReducer,
    login: loginReducer,
    registration: registrationReducer,
    forgotPassword: forgotPasswordReducer,
    propertyList: propertyListReducer,
    propertyDetail: propertyDetailReducer,
    resendActivationEmail: resendActivationEmailReducer,
    activeOrganisation: activeOrganisationReducer,
    inviteOrganisation: inviteOrganisationAdminReducer,
    adminCreateOrganisation: adminCreateOrganisationReducer,
    userList: userListReducer,
    pendingInvite: pendingInvitesReducer,
    inviteEntity: inviteEntityReducer,
    pendingInviteMenu: pendingInviteMenuReducer,
    orgList: organisationListReducer,
    userDetail: userDetailReducer,
    orgDetail: organisationDetailReducer,
    editCurrentOrg: editCurrentOrganisationReducer,
    createOrganisation: createOrganisationReducer,
    addressLookup: addressLookupReducer,
    platformUserList: platformUserListReducer,
    proCheck: proCheckReducer,
    platformOrganisationList: platformOrganisationListReducer,
    platformUserDetail: platformUserDetailReducer,
    adminOrganisationDetails: adminOrganisationDetailsReducer,
    dashboard: dashboardReducer,
    countryList: countryReducer,
    platformAgentList: platformAgentListReducer,
    platformAgentDetails: platformAgentDetailsReducer,
    exportBar: exportBarReducer,
    senseDevices: senseDevicesReducer,
    homezone: homeZoneReducer,
    systemMessages: systemMessageReducer,
    adminMoveEntity: adminMoveEntityReducer,
  });

  /// If we see a LOG_OUT_TYPE, pass in undefined as the current state,
  /// that'll trigger the initialState to be returned from every reducer.
  /// clearing any other state
  const rootReducer = (state: RootState | undefined, action: RootAction): RootState => {
    if (action.type === LOG_OUT_TYPE) {
      return combinedReducer(undefined, action);
    } else {
      return combinedReducer(state, action);
    }
  };

  // create saga middleware with the dependencies provided
  const sagaMiddleware = createSagaMiddleware<SagaContext>({
    context: defaultSagaContext,
  });

  // apply that middleware + devtools
  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

  const store = createStore(rootReducer, enhancers);

  setupSagas(sagaMiddleware);
  dispatchInitialActions(store);

  return store;
};

/// Ran after the store has been created
const setupSagas = (sagaMiddleware: SagaMiddleware) => {
  sagaMiddleware.run(loginSagas);
  sagaMiddleware.run(currentUserSagas);
  sagaMiddleware.run(registrationSagas);
  sagaMiddleware.run(forgotPasswordSagas);
  sagaMiddleware.run(accountActivationSagas);
  sagaMiddleware.run(propertyListSagas);
  sagaMiddleware.run(propertyDetailSagas);
  sagaMiddleware.run(accountSetupSagas);
  sagaMiddleware.run(resendActivationEmailSagas);
  sagaMiddleware.run(activeOrganisationSagas);
  sagaMiddleware.run(inviteOrganisationAdminSagas);
  sagaMiddleware.run(adminCreateOrganisationSaga);
  sagaMiddleware.run(userListSagas);
  sagaMiddleware.run(pendingInviteSagas);
  sagaMiddleware.run(currentInviteSagas);
  sagaMiddleware.run(pendingInviteMenuSagas);
  sagaMiddleware.run(organisationListSagas);
  sagaMiddleware.run(userDetailSagas);
  sagaMiddleware.run(organisationDetailSagas);
  sagaMiddleware.run(adeyAdminSagas);
  sagaMiddleware.run(editCurrentOrganisationSagas);
  sagaMiddleware.run(createOrganisationSagas);
  sagaMiddleware.run(addressLookupSagas);
  sagaMiddleware.run(platformUserListSagas);
  sagaMiddleware.run(proCheckSagas);
  sagaMiddleware.run(platformOrganisationListSagas);
  sagaMiddleware.run(platformUserDetailSagas);
  sagaMiddleware.run(adminOrganisationDetailsSagas);
  sagaMiddleware.run(dashboardSagas);
  sagaMiddleware.run(countrySagas);
  sagaMiddleware.run(platformAgentListSagas);
  sagaMiddleware.run(platformAgentDetailsSagas);
  sagaMiddleware.run(adeyAgentSagas);
  sagaMiddleware.run(exportBarSagas);
  sagaMiddleware.run(senseDeviceSagas);
  sagaMiddleware.run(homeZoneSagas);
  sagaMiddleware.run(systemMessageSagas);
  sagaMiddleware.run(adminMoveEntitySagas);
};

/// Ran after the store has been created
const dispatchInitialActions = (store: Store) => {
  store.dispatch(checkLoginStatus());
};

export default createRootStore;
