import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import UserDetails from "../../../../rest/user/model/UserDetails";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import ConfirmRemoveUserDialog from "./ConfirmRemoveUserDialog";
import UserRemovedDialog from "./UserRemovedDialog";

interface Props extends WithT {
  organisationName: string | undefined;
  user: UserDetails | undefined;
  loading: boolean;
  userRemoved: boolean;

  removeUserConfirmed: (user: UserDetails) => void;
  returnToListClicked: () => void;
}

interface State {
  isConfirmOpen: boolean;
}

class RemoveUserButton extends React.Component<Props, State> {
  state = {
    isConfirmOpen: false,
  };

  render() {
    const organisationName = this.props.organisationName;
    const user = this.props.user;
    if (!organisationName || !user) {
      return null;
    }

    const labelPlaceholder = this.props.t("removeUserButton.labelPlaceholder");
    const label = labelPlaceholder.replace("%s", organisationName);

    return (
      <div>
        <PrimaryButton
          size="small"
          label={label}
          startIcon={<FontAwesomeIcon icon={faUserSlash} />}
          onClick={this.handleClicked}
          isLoading={this.props.loading}
        />

        <ConfirmRemoveUserDialog
          organisationName={organisationName}
          user={user}
          open={this.state.isConfirmOpen}
          onCancelClicked={this.handleCancelClicked}
          onDeleteClicked={this.handleDeleteClicked}
        />

        <UserRemovedDialog
          open={this.props.userRemoved}
          organisationName={organisationName}
          user={user}
          onOkClicked={this.props.returnToListClicked}
        />
      </div>
    );
  }

  handleClicked = () => {
    this.setState({ isConfirmOpen: true });
  };

  handleCancelClicked = () => {
    this.setState({ isConfirmOpen: false });
  };

  handleDeleteClicked = () => {
    if (this.props.user) {
      this.props.removeUserConfirmed(this.props.user);
      this.handleCancelClicked();
    }
  };
}

export default withTranslation()(RemoveUserButton);
