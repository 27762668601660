import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { ACCOUNT_CREATED_ROUTE, LOGIN_ROUTE } from "../../../routes/routes";
import ArrowIcon from "../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import ErrorSnackbar from "../../../ui/components/ErrorSnackbar/ErrorSnackbar";
import PasswordTextField from "../../../ui/components/PasswordTextField";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import PrimaryTextField from "../../../ui/components/PrimaryTextField";
import SecondaryButton from "../../../ui/components/SecondaryButton";
import { SMALL_SPACING } from "../../../ui/theme/dimensions";
import { registerStartAction } from "./redux/RegistrationAction";
import isValidPassword from "../../../utils/isValidPassword";

interface ReduxStateProps {
  isRegistering: boolean;
  success: boolean;
  error: string | undefined;
}

interface ReduxDispatchProps {
  register: (email: string, password: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

interface State {
  email: string;
  password: string;
  emailError: string | undefined;
  passwordError: string | undefined;
  errorMessage: string | undefined;
}

class RegistrationPage extends React.Component<Props, State> {
  state = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    errorMessage: "",
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.error !== prevProps.error) {
      this.setState({ errorMessage: this.props.error });
    }

    if (this.props.success && !prevProps.success) {
      this.navigateToAccountCreatedPage();
    }
  }

  render() {
    const title = this.props.t("registrationPage.title");
    const email = this.props.t("registrationPage.emailFieldHint");
    const password = this.props.t("registrationPage.passwordFieldHint");
    const smartphoneHint = this.props.t("registrationPage.smartphoneHint");
    const registerButton = this.props.t("registrationPage.registerButton");
    const passwordHelperText = this.props.t("validationHelper.password");
    const backButton = this.props.t("registrationPage.returnSignInButton");

    return (
      <CenteredAuthCard title={title}>
        <Grid container direction="column" spacing={SMALL_SPACING}>
          <Grid item />

          <Grid item>
            <PrimaryTextField
              type="email"
              autocomplete="email"
              value={this.state.email}
              fullWidth
              hint={email}
              onChange={this.handleEmailChanged}
              disabled={this.props.isRegistering}
              errorText={this.state.emailError}
              onEnter={this.handleSubmit}
            />
          </Grid>
          <Grid item>
            <PasswordTextField
              autocomplete="new-password"
              value={this.state.password}
              fullWidth
              hint={password}
              onChange={this.handlePasswordChanged}
              disabled={this.props.isRegistering}
              errorText={this.state.passwordError}
              onEnter={this.handleSubmit}
              helperText={passwordHelperText}
            />
            <Box marginTop={SMALL_SPACING} />
            <Typography align="center" variant="body1" gutterBottom color="secondary">
              {smartphoneHint}
            </Typography>
          </Grid>

          <Grid item>
            <PrimaryButton
              fullWidth
              label={registerButton}
              endIcon={<ArrowIcon />}
              isLoading={this.props.isRegistering}
              onClick={this.handleSubmit}
            />
          </Grid>

          <Grid item>
            <Box display="flex" justifyContent="center">
              <SecondaryButton label={backButton} onClick={this.handleBackPressed} />
            </Box>
          </Grid>
        </Grid>

        <ErrorSnackbar
          message={this.state.errorMessage}
          onClose={this.handleSnackbarClosed}
        />
      </CenteredAuthCard>
    );
  }

  // Handlers

  handleSubmit = () => {
    if (this.validate()) {
      this.props.register(this.state.email, this.state.password);
    }
  };

  handleEmailChanged = (value: string) => {
    this.setState({
      email: value,
      emailError: undefined,
    });
  };

  handlePasswordChanged = (value: string) => {
    this.setState({
      password: value,
      passwordError: undefined,
    });
  };

  handleSnackbarClosed = () => {
    this.setState({ errorMessage: undefined });
  };

  handleBackPressed = () => {
    this.props.history.replace(LOGIN_ROUTE);
  };

  // Validation

  validate = (): boolean => {
    let isValid = true;

    if (this.state.email.trim().length === 0) {
      this.setState({
        emailError: this.props.t("registrationPage.emailRequired"),
      });
      isValid = false;
    } else if (!this.state.email.includes("@")) {
      this.setState({
        emailError: this.props.t("registrationPage.emailInvalidError"),
      });
      isValid = false;
    }

    if (this.state.password.trim().length === 0) {
      this.setState({
        passwordError: this.props.t("registrationPage.passwordRequired"),
      });
      isValid = false;
    } else if (!isValidPassword(this.state.password)) {
      this.setState({
        passwordError: this.props.t("registrationPage.passwordValidationError"),
      });
      isValid = false;
    }

    return isValid;
  };

  // Navigation

  navigateToAccountCreatedPage = () => {
    this.props.history.push(ACCOUNT_CREATED_ROUTE);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isRegistering: state.registration.isRegistering,
    success: state.registration.registeredSuccessfully,
    error: state.registration.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    register: (email, password) => dispatch(registerStartAction(email, password)),
  };
};

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegistrationPage))
);
