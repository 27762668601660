import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import PropertyTableItem from "../model/PropertyTableItem";
import { colorForStatus } from "../model/RAGStatus";
import RAGSymbol from "./RAGSymbol";

interface Props {
  tableItems: PropertyTableItem[];
  showOwner: boolean;
  onClick: (item: PropertyTableItem) => void;
  hideStatus?: boolean;
  hideMetrics?: boolean;
  onSelect?: (item: PropertyTableItem) => void;
}

const PropertyTable = (props: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {!props.hideStatus && (
            <HeaderCell textId="propertyTable.headerStatus" align="center" />
          )}
          {!props.hideStatus && <HeaderCell textId="propertyTable.headerStatusMessage" />}
          <HeaderCell textId="propertyTable.headerProperty" />
          {props.showOwner && <HeaderCell textId="propertyTable.headerOwner" />}

          {!props.hideMetrics && (
            <HeaderCell textId="propertyTable.headerProducts" align="center" />
          )}
          <HeaderCell textId="propertyTable.headerContractorName" align="center" />
          {props.onSelect && (
            <HeaderCell
              textId="propertyTable.headerMove"
              align="center"
              style={{ padding: "checkbox" }}
            />
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {props.tableItems.map((item) => (
          <PropertyTableRow
            key={item.id}
            item={item}
            onSelect={props.onSelect}
            hideMetrics={props.hideMetrics}
            hideStatus={props.hideStatus}
            showOwner={props.showOwner}
            onClick={props.onClick}
            selected={item.selected}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const HeaderCell = (props: {
  textId: string;
  align?: "center" | "left";
  style?: React.CSSProperties;
}) => {
  return (
    <TableCell align={props.align || "left"}>
      <Typography variant="button" color="secondary">
        <Trans>{props.textId}</Trans>
      </Typography>
    </TableCell>
  );
};

const PropertyTableRow = (props: {
  item: PropertyTableItem;
  showOwner: boolean;
  onClick: (item: PropertyTableItem) => void;
  hideStatus?: boolean;
  hideMetrics?: boolean;
  onSelect?: (item: PropertyTableItem) => void;
  selected?: boolean;
}) => {
  return (
    <TableRow
      style={{ cursor: "pointer" }}
      onClick={() => props.onClick(props.item)}
      hover
    >
      {!props.hideStatus && (
        <TableCell>
          <Box display="flex" justifyContent="center">
            {props.item.status !== undefined ? (
              <RAGSymbol status={props.item.status} />
            ) : (
              "-"
            )}
          </Box>
        </TableCell>
      )}
      {!props.hideStatus && (
        <TableCell>
          {props.item.status !== undefined ? (
            <ColoredText textColor={colorForStatus(props.item.status)}>
              {props.item.statusMessage}
            </ColoredText>
          ) : (
            "-"
          )}
        </TableCell>
      )}

      <TableCell>
        <Typography>{props.item.property}</Typography>
      </TableCell>

      {props.showOwner ? (
        <TableCell>
          <Typography>{props.item.ownerName}</Typography>
        </TableCell>
      ) : undefined}

      {!props.hideMetrics && (
        <TableCell align="center">
          <Typography>{props.item.products}</Typography>
        </TableCell>
      )}
      <TableCell align="center">
        <Typography>{props.item.contractorName}</Typography>
      </TableCell>
      {props.onSelect && (
        <TableCell padding="checkbox" align="center">
          <Checkbox
            onChange={() => props.onSelect?.(props.item)}
            onClick={(event) => event.stopPropagation()}
            checked={props.selected}
            // disabled={props.isSelectingDisabled}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default PropertyTable;
