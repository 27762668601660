import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Address from "../../model/Address";
import AddressEditor from "../../../../../ui/components/AddressEditor/AddressEditor";
import HoverableField from "./components/HoverableField";
import { Country } from "../../../../../rest/countries/model/Country";

interface Props extends WithT {
  label: string;
  address: Address | undefined;
  country: Country;
  isEditing: boolean;
  hoverEnabled: boolean;
  isAgent?: boolean;
  lookupEnabled?: boolean;

  loading?: boolean;
  required?: boolean;

  onEditClicked: () => void;
  onCancelClicked: () => void;
  onSaveClicked: (address: Address) => void;
}

class EditableAddress extends React.Component<Props> {
  render() {
    if (this.props.isEditing) {
      return <AddressEditor {...this.props} />;
    } else {
      return (
        <HoverableField
          label={this.props.label}
          text={this.formatAddress(this.props.address, this.props.country)}
          onClick={this.props.onEditClicked}
          hoverEnabled={this.props.hoverEnabled}
          paddingBottom
        />
      );
    }
  }

  formatAddress = (address: Address | undefined, country: Country): string => {
    if (address === undefined) {
      return "";
    }

    return [
      address.line1,
      address.line2,
      address.line3,
      address.city,
      address.postcode,
      this.props.t("countryCode." + country.code),
    ]
      .filter((line) => typeof line === "string")
      .flatMap((line) => (line as string).trim())
      .filter((line) => line.length > 0)
      .join(", ");
  };
}

export default withTranslation()(EditableAddress);
