import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import Organisation from "../../../../rest/organisation/model/Organisation";
import { PLATFORM_AGENT_LIST_ROUTE } from "../../../../routes/routes";
import NavigationRail from "../../../../ui/components/NavigationRail";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

interface ExternalProps {
  organisation: Organisation;
  navigateToRoute: (route: string) => void;
  endComponent?: React.ReactElement;
}

type Props = ExternalProps & WithT & RouteComponentProps;

const AgentDetailsNavRail = (props: Props) => {
  const handleRailLinkClicked = () => {
    props.navigateToRoute(PLATFORM_AGENT_LIST_ROUTE);
  };

  const name = props.organisation.name;
  const links = [
    {
      key: "agent-list",
      name: props.t("headerTabs.agents"),
      onClick: handleRailLinkClicked,
    },
  ];

  return (
    <Box
      paddingX={DEFAULT_SPACING}
      paddingTop={DEFAULT_SPACING}
      display="flex"
      justifyContent="space-between"
    >
      <NavigationRail links={links} currentPageTitle={name} />
      {props.endComponent}
    </Box>
  );
};

export default withRouter(withTranslation()(AgentDetailsNavRail));
