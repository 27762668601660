import { put, select, takeLatest } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import Property from "../../../../rest/properties/model/Property";
import PropertyDetailService, { PropertyDetails } from "../service/PropertyDetailService";
import {
  DeletePropertyAction,
  deletePropertyError,
  deletePropertySuccess,
  DELETE_PROPERTY_TYPE,
  LoadPropertyAction,
  LOAD_PROPERTY_TYPE,
  propertyLoaded,
  propertyLoadError,
  PropertyUpdatedAction,
  propertyUpdatedError,
  propertyUpdatedSuccess,
  PROPERTY_UPDATED_TYPE,
  UpdateContractorAction,
  UPDATE_CONTRACTOR_TYPE,
} from "./PropertyDetailActions";
import isAdeyAdmin from "../../../../redux/utils/isAdeyAdmin";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import propertyErrorHandler from "../../../../rest/properties/error/propertyErrorHandler";
import PropertiesRepository from "../../../../rest/properties/PropertiesRepository";
import checkCountry from "../../../../redux/utils/checkCountry";
import buildPropertyFromCode from "../../PropertyListPage/utils/buildPropertyFromCode";

function* propertyDetailSagas() {
  yield takeLatest(LOAD_PROPERTY_TYPE, loadProperty);
  yield takeLatest(PROPERTY_UPDATED_TYPE, updateProperty);
  yield takeLatest(UPDATE_CONTRACTOR_TYPE, updateContractorSaga);
  yield takeLatest(DELETE_PROPERTY_TYPE, deletePropertySaga);
}

function* loadProperty(action: LoadPropertyAction) {
  try {
    const rootState: RootState = yield select();
    const isAdmin: boolean = yield isAdeyAdmin();
    const canViewContractors =
      rootState.activeOrganisation.permissions.canAssignContractorsToProperties;

    const service: PropertyDetailService = yield getDependency("propertyDetailService");

    const details: PropertyDetails = yield service.loadProperty(
      isAdmin,
      action.organisationId,
      action.propertyId,
      canViewContractors
    );

    yield checkCountry(details.property.address_country.id);

    yield put(
      propertyLoaded(details.property, details.organisation, details.contractors)
    );
  } catch (e) {
    const message = getErrorMessage(
      e,
      "propertyDetailPage.errorTitle",
      propertyErrorHandler
    );
    yield put(propertyLoadError(message));
  }
}

function* updateProperty(action: PropertyUpdatedAction) {
  try {
    const rootState: RootState = yield select();
    const isAdeyAdmin = rootState.adeyAdmin.hasAdminAccess;

    const service: PropertyDetailService = yield getDependency("propertyDetailService");

    //'address_country' property expects an object. If no country is found, then the API will throw the error that the country is invalid;
    const newProperty = buildPropertyFromCode(
      action.property,
      rootState.countryList.countryList
    );

    const property: Property = yield service.updateProperty(
      isAdeyAdmin,
      action.organisationId,
      newProperty
    );

    yield checkCountry(property.address_country.id);

    yield put(propertyUpdatedSuccess(property));
  } catch (e) {
    const message = getErrorMessage(e, "propertyDetailPage.updateError");
    yield put(propertyUpdatedError(message));
  }
}

function* updateContractorSaga(action: UpdateContractorAction) {
  try {
    const rootState: RootState = yield select();
    const isAdeyAdmin = rootState.adeyAdmin.hasAdminAccess;

    const service: PropertyDetailService = yield getDependency("propertyDetailService");

    //'address_country' property expects an object. If no country is found, then the API will throw the error that the country is invalid;
    const newProperty = buildPropertyFromCode(
      action.property,
      rootState.countryList.countryList
    );

    const property: Property = yield service.updateContractor(
      isAdeyAdmin,
      action.organisation.id,
      newProperty,
      action.newContractorId
    );

    yield put(propertyUpdatedSuccess(property));
  } catch (e) {
    const message = getErrorMessage(e, "propertyDetailPage.updateError");
    yield put(propertyUpdatedError(message));
  }
}

function* deletePropertySaga(action: DeletePropertyAction) {
  try {
    const propertyRepo: PropertiesRepository = yield getDependency(
      "propertiesRepository"
    );

    const isAdmin: boolean = yield isAdeyAdmin();

    yield propertyRepo.deleteProperty(isAdmin, action.organisationId, action.property.id);
    yield put(deletePropertySuccess(action.property));
  } catch (e) {
    const error = getErrorMessage(e, "deletePropertyHandler.deleteError");
    yield put(deletePropertyError(error));
  }
}

export default propertyDetailSagas;
