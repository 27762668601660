import { useState } from "react";
import Property from "../../../../../../rest/properties/model/Property";

const usePropertiesSelector = () => {
  //State
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);

  const handleShowSelectedSwitch = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const handleSelectProperty = (property: Property) => {
    if (selectedProperties.includes(property)) {
      setSelectedProperties(selectedProperties.filter((prop) => prop !== property));
      if (selectedProperties.length - 1 <= 0) {
        setShowOnlySelected(false);
      }
    } else {
      setSelectedProperties([...selectedProperties, property]);
    }
  };

  const handleResetSelectedProperties = () => {
    setSelectedProperties([]);
    setShowOnlySelected(false);
  };

  return {
    showOnlySelected,
    selectedProperties,
    handleShowSelectedSwitch,
    handleSelectProperty,
    handleResetSelectedProperties,
  };
};

export default usePropertiesSelector;
