import { put, takeLatest } from "redux-saga/effects";
import getErrorMessage from "../../../errors/messages/getErrorMessage";
import getDependency from "../../../redux/utils/getDependency";
import isAdeyAdmin from "../../../redux/utils/isAdeyAdmin";
import CountryRepository from "../../../rest/countries/CountryRepository";
import { Country } from "../../../rest/countries/model/Country";
import {
  countryListLoaded,
  countryListLoadError,
  countryUpdated,
  countryUpdateError,
  CreateCountryAction,
  CREATE_COUNTRY_TYPE,
  LOAD_COUNTRY_LIST_TYPE,
  UpdateCountryAction,
  UPDATE_COUNTRY_TYPE,
} from "./CountryAction";

function* countrySagas() {
  yield takeLatest(LOAD_COUNTRY_LIST_TYPE, loadCountryListSaga);
  yield takeLatest(UPDATE_COUNTRY_TYPE, updateCountrySaga);
  yield takeLatest(CREATE_COUNTRY_TYPE, createCountrySaga);
}

function* loadCountryListSaga() {
  try {
    const isAdmin: boolean = yield isAdeyAdmin();
    const countryRepo: CountryRepository = yield getDependency("countryRepository");

    const countries: Country[] = yield countryRepo.fetchCountryList(isAdmin);

    yield put(countryListLoaded(countries));
  } catch (e) {
    const message = getErrorMessage(e, "error.countryList404");
    yield put(countryListLoadError(message));
  }
}

function* updateCountrySaga(action: UpdateCountryAction) {
  try {
    const countryRepo: CountryRepository = yield getDependency("countryRepository");
    const country: Country = yield countryRepo.adminUpdateCountry(
      { name: action.country.name, code: action.country.code },
      action.country.id
    );

    yield put(countryUpdated(country));
  } catch (e) {
    const message = getErrorMessage(e, "error.updateCountry404");
    yield put(countryUpdateError(message));
  }
}

function* createCountrySaga(action: CreateCountryAction) {
  try {
    const countryRepo: CountryRepository = yield getDependency("countryRepository");
    const country: Country = yield countryRepo.adminCreateCountry(action.countryForm);

    yield put(countryUpdated(country));
  } catch (e) {
    const message = getErrorMessage(e, "error.createCountry422");
    yield put(countryUpdateError(message));
  }
}

export default countrySagas;
