import SystemMessage from "../model/SystemMessage";

export const INIT_GET_SYSTEM_MESSAGES = "INIT_GET_SYSTEM_MESSAGES_TYPE";

export interface InitGetSystemMessagesAction {
  type: typeof INIT_GET_SYSTEM_MESSAGES;
  messageType?: string;
  active?: boolean;
  debug?: boolean;
}

export const getSystemMessagesAction = (
  messageType?: string,
  active?: boolean,
  debug?: boolean
): InitGetSystemMessagesAction => {
  return {
    type: INIT_GET_SYSTEM_MESSAGES,
    messageType: messageType,
    active: active,
    debug: debug,
  };
};

export const GET_SYSTEM_MESSAGES_SUCCESS = "GET_SYSTEM_MESSAGES_SUCCESS_TYPE";

export interface GetSystemMessagesSuccessAction {
  type: typeof GET_SYSTEM_MESSAGES_SUCCESS;
  messages: SystemMessage[];
}

export const systemMessagesSuccessAction = (
  messages: SystemMessage[]
): GetSystemMessagesSuccessAction => {
  return {
    type: GET_SYSTEM_MESSAGES_SUCCESS,
    messages: messages,
  };
};

export const GET_SYSTEM_MESSAGES_ERROR = "GET_SYSTEM_MESSAGES_ERROR_TYPE";

export interface GetSystemMessagesErrorAction {
  type: typeof GET_SYSTEM_MESSAGES_ERROR;
  error: string;
  systemDown?: boolean;
}

export const systemMessagesErrorAction = (
  error: string
): GetSystemMessagesErrorAction => {
  return {
    type: GET_SYSTEM_MESSAGES_ERROR,
    error: error,
  };
};

export const SYSTEM_DOWN = "SYSTEM_DOWN_TYPE";

export interface SystemDownAction {
  type: typeof SYSTEM_DOWN;
}

export const systemDownAction = (): SystemDownAction => {
  return {
    type: SYSTEM_DOWN,
  };
};

export type SystemMessageAction =
  | SystemDownAction
  | GetSystemMessagesErrorAction
  | GetSystemMessagesSuccessAction
  | InitGetSystemMessagesAction;

export default SystemMessageAction;
