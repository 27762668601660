import { Box, Divider, Popover } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import PendingInvite from "../../../rest/invites/model/PendingInvite";
import ResendInviteButton from "./components/ResendInviteButton";
import RevokeInviteButton from "./components/RevokeInviteButton";

const _width = 300;

interface ExternalProps {
  invite: PendingInvite | undefined;
  anchorElement: Element | undefined;

  onClose: () => void;
}

interface ReduxStateProps {
  enableClose: boolean;
}

type Props = ReduxStateProps & ExternalProps;

const PendingInviteMenu = (props: Props) => {
  return (
    <Popover
      open={props.invite !== undefined}
      anchorEl={props.anchorElement}
      onClose={props.enableClose ? props.onClose : undefined}
    >
      {renderBody(props)}
    </Popover>
  );
};

const renderBody = (props: Props) => {
  if (props.invite) {
    return (
      <Box minWidth={_width}>
        <ResendInviteButton invite={props.invite} handleSuccess={props.onClose} />
        <Divider />
        <RevokeInviteButton invite={props.invite} handleSuccess={props.onClose} />
      </Box>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  // Disable closing the menu if we're loading
  enableClose:
    !state.pendingInviteMenu.revokeInvite.loading &&
    !state.pendingInviteMenu.resendInvite.loading,
});

export default connect(mapStateToProps)(PendingInviteMenu);
