import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { Description } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { WithT } from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import OutlineButton from "../../../../ui/components/OutlineButton";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import TagInputField from "../../../../ui/components/TagInputField";
import {
  DEFAULT_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import exportEmailValidator, {
  EEmailValidationError,
} from "../../../../utils/exportEmailValidator";
import useStoreEmails from "../../../../utils/hooks/useStoreEmails";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { useSelector } from "react-redux";
import RootState from "../../../../redux/RootState";
import ColoredText from "../../../../ui/components/ColoredText";
import { SECONDARY_TEXT_COLOR } from "../../../../ui/theme/createMaterialTheme";

interface ExternalProps {
  open: boolean;
  isLoading: boolean;
  exported: boolean | undefined;
  onDialogClose: () => void;
  handleReportsExport: (
    emails: string[],
    hidePersonalDetails: boolean,
    notes?: string
  ) => void;
  handleReportsDownload?: (hidePersonalDetails: boolean) => void;
}

type Props = ExternalProps & WithT;

const ExportProcheckResultsDialog = (props: Props) => {
  //Selector
  const userId = useSelector((state: RootState) => state.currentUser.user?.id);

  //Local State
  const [switchState, setSwitchState] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<FieldState>({ value: "" });
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [notesState, setNotesState] = useState<FieldState>({ value: "" });

  //Localisation
  const emailDialogTitle = props.t("proCheckExportDialog.title");
  const privacySwitchLabel = props.t("proCheckExportDialog.privacySwitchLabel");
  const privacySwitchHint = props.t("proCheckExportDialog.privacySwitchHint");
  const forgetEmailHint = props.t("proCheckExportDialog.forgetEmailHint");
  const rememberEmailHint = props.t("proCheckExportDialog.rememberEmailHint");
  const exportButtonLabel = props.t("proCheckExportDialog.exportButtonLabel");
  const emailFieldLabel = props.t("proCheckExportDialog.emailFieldLabel");
  const emailFieldPlaceholder = props.t("proCheckExportDialog.emailFieldPlaceholder");
  const emailInputHint = props.t("proCheckExportDialog.emailFieldHint");
  const emailInputButtonLabel = props.t("proCheckExportDialog.addEmailButtonLabel");
  const notesFieldLabel = props.t("proCheckExportDialog.notesFieldLabel");
  const downloadButtonLabel = props.t("buttons.download");
  const dividerLabel = props.t("divider.option");

  //Helpers
  const buildErrorString = (errors: EEmailValidationError[]): string => {
    const errorLabel = `${props.t("emailValidator.label")} \n`;
    const errorStringArray: string[] = [];
    errors.forEach((err) => {
      errorStringArray.push(`• ${props.t(`emailValidator.${err}`)} \n`);
    });

    return errorLabel + errorStringArray.join("");
  };

  //Hooks
  const {
    getLocalStorageEmails,
    addLocalStorageEmail,
    clearEmailLocalStorage,
    setRememberFlag,
    remember,
  } = useStoreEmails();

  //Handlers
  const handlePrivacySwitchChange = () => {
    setSwitchState(!switchState);
  };

  const handleEmailChanged = (value: string) => {
    setEmailValue({ value: value });
  };

  const handleNotesChanged = (value: string) => {
    setNotesState({ value: value });
  };

  const cleanUpState = () => {
    setSwitchState(false);
    setEmailValue({ value: "" });
    setSelectedEmails([]);
    setNotesState({ value: "" });
  };

  useEffect(() => {
    if (props.exported) {
      cleanUpState();
    }
  }, [props.exported]);

  const handleEmailTagsChanged = (
    event: React.ChangeEvent<{}>,
    v: (string | string[])[],
    reason: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case "create-option":
      case "select-option":
        const emailArray = v as string[];
        const currentEmailValue = emailArray[emailArray.length - 1].trim();
        let result = exportEmailValidator(currentEmailValue);
        if (result.isValid) {
          if (remember) {
            addLocalStorageEmail(userId || "", currentEmailValue);
          }
          setSelectedEmails(v as string[]);
        } else {
          setEmailValue({
            ...emailValue,
            value: currentEmailValue,
            errorText: buildErrorString(result.validationErrors),
          });
        }
        break;
      case "remove-option":
        setSelectedEmails(v as string[]);
        break;
      case "clear":
        setSelectedEmails([]);
        break;
      default:
        break;
    }
  };

  const handleAddEmailClicked = () => {
    const currentEmailValue = emailValue.value.trim();
    let result = exportEmailValidator(currentEmailValue);
    if (result.isValid) {
      if (remember) {
        addLocalStorageEmail(userId || "", currentEmailValue);
      }
      setSelectedEmails([...selectedEmails, emailValue.value]);
      setEmailValue({ value: "" });
    } else {
      setEmailValue({
        ...emailValue,
        value: currentEmailValue,
        errorText: buildErrorString(result.validationErrors),
      });
    }
  };

  /*
    As React Components' 'setState' methods do not provide
    a callback and it is awkward to use Hooks for this, 
    current email value is instead retained and spread onto array of selected emails 
  */
  const handleReportsExport = () => {
    const currentEmailValue = emailValue.value.trim();
    let result = exportEmailValidator(currentEmailValue);
    if (selectedEmails.length > 0 && currentEmailValue === "") {
      if (remember) {
        selectedEmails.forEach((email) => addLocalStorageEmail(userId || "", email));
      }
      props.handleReportsExport([...selectedEmails], switchState, notesState.value);
    } else if (result.isValid) {
      handleAddEmailClicked();
      props.handleReportsExport(
        [...selectedEmails, currentEmailValue],
        switchState,
        notesState.value
      );
    } else {
      setEmailValue({
        ...emailValue,
        errorText: buildErrorString(result.validationErrors),
      });
    }
  };

  const handleReportsDownload = () => {
    props.handleReportsDownload?.(switchState);
    cleanUpState();
  };

  return (
    <Dialog open={props.open} onClose={props.onDialogClose}>
      <Box padding={DEFAULT_SPACING}>
        <Grid container spacing={SMALL_SPACING} alignContent="center">
          <Grid item xs={12}>
            <Typography variant="h5" color="primary" align="center">
              <strong>{emailDialogTitle.toUpperCase()}</strong>
            </Typography>
          </Grid>
          <Grid item />
          <Grid item xs={12}>
            <Typography align="left" variant="button" color="secondary">
              <strong>{privacySwitchLabel}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchState}
                  onChange={handlePrivacySwitchChange}
                  color="primary"
                />
              }
              label={privacySwitchHint}
            />
          </Grid>

          <Grid item />
          <Grid item xs={12}>
            <Typography align="left" variant="button" color="secondary">
              <strong>{notesFieldLabel}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <PrimaryTextField
              value={notesState.value}
              onChange={handleNotesChanged}
              multiline={true}
              type="text"
            />
          </Grid>

          <Grid item />
          <Grid item xs={12}>
            <Typography align="left" variant="button" color="secondary">
              <strong>{emailFieldLabel}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TagInputField
              errorText={emailValue.errorText}
              tagValues={selectedEmails}
              inputValue={emailValue.value}
              options={[
                ...new Set([...selectedEmails, ...getLocalStorageEmails(userId || "")]),
              ]}
              label={emailFieldPlaceholder}
              placeholder={emailFieldPlaceholder}
              helperText={emailValue.errorText || emailInputHint}
              handleInputChanged={handleEmailChanged}
              handleTagChange={handleEmailTagsChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display={"flex"} flexDirection={"column"} alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={() => setRememberFlag(!remember)}
                    />
                  }
                  label={rememberEmailHint}
                />
                <SecondaryButton
                  label={forgetEmailHint}
                  onClick={() => clearEmailLocalStorage(userId || "")}
                />
              </Box>

              <Box>
                <OutlineButton
                  label={emailInputButtonLabel}
                  onClick={handleAddEmailClicked}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item />
          <Grid item xs={12}>
            <PrimaryButton
              label={exportButtonLabel}
              endIcon={<FontAwesomeIcon icon={faShareSquare} size="2x" />}
              onClick={handleReportsExport}
              fullWidth
              isLoading={props.isLoading}
            />
            {props.handleReportsDownload && (
              <>
                <Box
                  marginY={SMALL_SPACING}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Divider style={{ width: "auto", flex: 1 }} />
                    <ColoredText
                      style={{ marginLeft: LARGE_SPACING, marginRight: LARGE_SPACING }}
                      variant="button"
                      textColor={SECONDARY_TEXT_COLOR}
                    >
                      {dividerLabel}
                    </ColoredText>
                    <Divider style={{ width: "auto", flex: 1 }} />
                  </Box>
                </Box>
                <PrimaryButton
                  label={downloadButtonLabel}
                  endIcon={<Description />}
                  onClick={handleReportsDownload}
                  fullWidth
                  isLoading={props.isLoading}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(ExportProcheckResultsDialog);
