import { TFunction } from "i18next";
import { RoleName } from "../../../../rest/roles/model/Role";

const displayNameForRole = (role: RoleName, t: TFunction): string => {
  switch (role) {
    case "installer":
      return t("roles.installer");

    case "administrator":
      return t("roles.admin");

    case "owner":
      return t("roles.owner");

    case "contractor":
      return t("roles.contractor");

    case "agent":
      return t("roles.agent");

    case "developer":
      return t("roles.developer");
  }
};

export default displayNameForRole;
