import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  BACKGROUND_ACCENT_COLOR,
  SELECTABLE_ITEM_COLOR,
} from "../../../../../../ui/theme/createMaterialTheme";
import {
  BORDER_RADIUS,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../../ui/theme/dimensions";
import EditableFieldLabel from "./EditableFieldLabel";

const minFieldHeight = 32;

interface Props {
  label: string;
  text: string | undefined;
  hoverEnabled: boolean;
  paddingBottom?: boolean;
  hideEditIcon?: boolean;
  onClick?: () => void;
}

interface State {
  isHovered: boolean;
}

class HoverableField extends React.Component<Props, State> {
  state = {
    isHovered: false,
  };

  render() {
    const editIcon =
      this.state.isHovered && !this.props.hideEditIcon ? (
        <FontAwesomeIcon icon={faPencilAlt} color={BACKGROUND_ACCENT_COLOR} size="sm" />
      ) : undefined;

    const textBackgroundColor = this.state.isHovered ? SELECTABLE_ITEM_COLOR : undefined;
    const padding = LARGE_SPACING + "px";

    const hoverFunctionality = this.props.hoverEnabled
      ? { style: { cursor: "pointer" }, onClick: this.props.onClick }
      : undefined;

    return (
      <Box
        display="flex"
        flexDirection="column"
        onMouseEnter={() => this.handleHoverChanged(true)}
        onMouseLeave={() => this.handleHoverChanged(false)}
        paddingBottom={this.props.paddingBottom ? SMALL_SPACING : 0}
      >
        <Box display="flex" flexDirection="row">
          <EditableFieldLabel label={this.props.label} />

          <Box width={LARGE_SPACING} />

          {editIcon}
        </Box>

        <Box
          bgcolor={textBackgroundColor}
          padding={padding}
          borderRadius={BORDER_RADIUS}
          minHeight={minFieldHeight}
          {...hoverFunctionality}
        >
          <Typography variant="h5">{this.props.text || ""}</Typography>
        </Box>
      </Box>
    );
  }

  handleHoverChanged = (hover: boolean) => {
    const enableHover = this.props.hoverEnabled && hover;

    this.setState({ isHovered: enableHover });
  };
}

export default HoverableField;
