import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import Property, {
  PropertyPageType,
} from "../../../../../rest/properties/model/Property";
import ExportItemModel from "../../../../../ui/components/ExportBar/models/ExportItemModel";
import { initItemExport } from "../../../../../ui/components/ExportBar/redux/ExportBarAction";
import AddPropertyFormModel from "../../../../properties/AddPropertyDialog/model/AddPropertyFormModel";
import FilterablePropertyList from "../../../../properties/PropertyListPage/components/FilterablePropertyList";
import {
  addProperty,
  changePageType,
  pageChanged,
  searchTextChanged,
} from "../../../../properties/PropertyListPage/redux/PropertyListActions";
import AdminPropertyListWrapper from "../../../../properties/PropertyListPage/components/AdminPropertyListWrapper/AdminPropertyListWrapper";

interface ExternalProps {
  organisationId: string;
  onPropertyClicked: (property: Property) => void;
}

interface ReduxStateProps {
  searchText: string;
  errorMessage: string | undefined;
  maxPages: number;
  currentPage: number;
  addingProperty: boolean;
  addPropertyError: string | undefined;
  isPropertyAdded: boolean | undefined;
  properties: Property[] | undefined;
  loadedPropertyOrgId: string | undefined;
  pageType: PropertyPageType | undefined;
  isGeneratingReport: boolean;
  exportItems: ExportItemModel[];
}

interface ReduxDispatchProps {
  changePageType: () => void;
  onSearchTextChanged: (value: string) => void;
  onPageNumberChanged: (page: number) => void;
  onPropertyFormSubmitted: (propertyData: AddPropertyFormModel) => void;
  exportOrganisation: (startDate: Date, endDate: Date, organisationId: string) => void;
}

interface State {
  exportDate: Date[];
}

const initialDate = new Date();

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps;

class AdminOrgPropertiesTab extends React.Component<Props, State> {
  state = {
    exportDate: [
      new Date(initialDate.getFullYear(), initialDate.getMonth(), 1),
      initialDate,
    ],
  };

  componentDidMount() {
    if (
      this.props.organisationId !== this.props.loadedPropertyOrgId ||
      this.props.pageType !== "admin-org-detail"
    ) {
      this.props.changePageType();
    }
  }

  render() {
    // Admin can always create properties.

    return (
      <AdminPropertyListWrapper
        showOwnerInTable={false}
        {...this.props}
        exportDate={this.state.exportDate}
        setExportDate={(date) => {
          this.setState({ ...this.state, exportDate: date });
        }}
        isGeneratingReport={this.props.isGeneratingReport || this.isSameItemExporting()}
        canCreateProperties
        handleExportClicked={(startDate, endDate) =>
          this.props.exportOrganisation(startDate, endDate, this.props.organisationId)
        }
      />
    );
  }

  //Helpers
  isSameItemExporting = () => {
    return (
      this.props.exportItems.filter(
        (item) =>
          item.organisation_id === this.props.organisationId &&
          this.state.exportDate !== null &&
          this.state.exportDate[0].toISOString().split("T")[0] === item.startDate &&
          this.state.exportDate[1].toISOString().split("T")[0] === item.endDate
      ).length > 0
    );
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  const isGeneratingReport =
    state.exportBar.exportItems.filter(
      (item) => item.id === state.orgDetail.organisation?.id //If report has the same ID as org, it means we are attempting to generate it
    ).length > 0;

  return {
    searchText: state.propertyList.searchText,
    errorMessage: state.propertyList.errorMessage,
    maxPages: state.propertyList.maxPages,
    currentPage: state.propertyList.currentPage,
    addingProperty: state.propertyList.addingProperty,
    addPropertyError: state.propertyList.addPropertyError,
    isPropertyAdded: state.propertyList.isPropertyAdded,
    properties: state.propertyList.propertyList,
    loadedPropertyOrgId: state.propertyList.organisationId,
    pageType: state.propertyList.page,
    exportItems: state.exportBar.exportItems,
    isGeneratingReport: isGeneratingReport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  changePageType: () => dispatch(changePageType("admin-org-detail")),
  onSearchTextChanged: (value) => dispatch(searchTextChanged(value)),
  onPageNumberChanged: (page) => dispatch(pageChanged(page)),
  onPropertyFormSubmitted: (propertyData) => dispatch(addProperty(propertyData)),
  exportOrganisation: (startDate, endDate, organisationId) =>
    dispatch(initItemExport(organisationId, startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrgPropertiesTab);
