import { Button, Typography } from "@material-ui/core";
import React from "react";
import {
  PAGE_BACKGROUND_COLOR,
  SECONDARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR_DARK,
} from "../../theme/createMaterialTheme";

interface IProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
}

const ScrollListItem = ({ onClick, label, disabled, loading, selected }: IProps) => {
  return (
    <Button
      disabled={disabled || loading}
      style={{
        ...(selected && { backgroundColor: SECONDARY_TEXT_COLOR }),
      }}
      fullWidth
      onClick={onClick}
    >
      <Typography style={{ textTransform: "initial", width: "100%" }} align="left">
        {label}
      </Typography>
    </Button>
  );
};

export default ScrollListItem;
