import { Box, Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";

interface ExternalProps {
  isOpen: boolean;
  isAgent: boolean;

  organisationName: string;

  onCancel: () => void;
  onSubmit: () => void;
}

type Props = ExternalProps & WithTranslation;

const AgentDialog = (props: Props) => {
  //Labels and Translates Strings
  const title = props.t("agentDialog.title");
  const agentRemovalText = props
    .t("agentDialog.removeAgentText")
    .replace("%s", props.organisationName);
  const agentRemovalWarning = props
    .t("agentDialog.removeAgentWarning")
    .replace("%s", props.organisationName);
  const agentAssignmentText = props
    .t("agentDialog.grantAgentAccessText")
    .replace("%s", props.organisationName);
  const agentAssignmentWarning = props
    .t("agentDialog.grantAgentAccessWarning")
    .replace("%s", props.organisationName);
  const cancelLabel = props.t("buttons.cancel");
  const confirmLabel = props.t("buttons.confirm");

  return (
    <Dialog open={props.isOpen} onClose={props.onCancel}>
      <Box
        padding={DEFAULT_SPACING}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h2" gutterBottom paragraph align="center">
          {title}
        </Typography>
        <Typography gutterBottom align="center">
          {props.isAgent ? agentRemovalText : agentAssignmentText}
        </Typography>
        <Box marginTop={SMALL_SPACING} />
        <Typography color="secondary" gutterBottom variant="button" align="center">
          <strong>{props.isAgent ? agentRemovalWarning : agentAssignmentWarning}</strong>
        </Typography>

        <Box display="flex" justifyContent="space-between" marginTop={SMALL_SPACING}>
          <Button color="secondary" onClick={props.onCancel}>
            {cancelLabel}
          </Button>
          <Button color="primary" onClick={props.onSubmit}>
            {confirmLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(AgentDialog);
