import React from "react";
import FilterablePropertyList from "../FilterablePropertyList";
import IFilterablePropertyList from "../../interfaces/IFilterablePropertyList";
import usePropertiesSelector from "./hooks/usePropertiesSelector";

const AdminPropertyListWrapper = (props: IFilterablePropertyList) => {
  //Hooks
  const movePropertiesProps = usePropertiesSelector();

  return <FilterablePropertyList {...props} moveProperties={movePropertiesProps} />;
};

export default AdminPropertyListWrapper;
