import { put, takeEvery } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import AuthService from "../../Login/service/AuthService";
import {
  registerError,
  RegisterStartAction,
  REGISTER_START_TYPE,
  registrationSuccess,
} from "./RegistrationAction";
import ProClubRepository from "../../../../rest/proclub/ProClubRepository";
import registrationErrorHandler from "../utils/registrationErrorHandler";
import isProCheckUser from "../../Login/utils/isProCheckUser";

function* registrationSagas() {
  yield takeEvery(REGISTER_START_TYPE, onRegisterAction);
}

export function* onRegisterAction(action: RegisterStartAction) {
  try {
    const authService: AuthService = yield getDependency("authService");
    const proClubService: ProClubRepository = yield getDependency("proClubRepository");

    const proCheckUser: boolean = yield isProCheckUser(
      proClubService,
      action.email,
      action.password
    );

    if (proCheckUser) {
      yield put(registerError("proClub.status412"));
      return;
    }

    yield authService.register(action.email, action.password);
    yield put(registrationSuccess());
  } catch (e) {
    const message = getErrorMessage(e, undefined, registrationErrorHandler);
    yield put(registerError(message));
  }
}

export default registrationSagas;
