import {
  DASHBOARD_ROUTE,
  ORGANISATION_LIST_ROUTE,
  PLATFORM_AGENT_LIST_ROUTE,
  PLATFORM_ORGANISATIONS_LIST_ROUTE,
  PLATFORM_USER_LIST_ROUTE,
  REPORTING_ROUTE,
  USER_LIST_ROUTE,
} from "../../../routes/routes";

interface Args {
  canViewUsers: boolean;
  canViewOrganisations: boolean;
  canViewReports: boolean;
  hasAdeyAdminAccess: boolean;
  hasAdeyAgentAccess: boolean;
}

interface TabModel {
  title: string;
  route: string;
}

const buildTabModels = (args: Args): TabModel[] => {
  if (args.hasAdeyAdminAccess) {
    return buildAdeyAdminTabs();
  } else if (args.hasAdeyAgentAccess) {
    return buildAdeyAgentTabs();
  }

  const tabModels = [
    {
      title: "headerTabs.dashboard",
      route: DASHBOARD_ROUTE,
    },
    ...(args.canViewUsers
      ? [
          {
            title: "headerTabs.userList",
            route: USER_LIST_ROUTE,
          },
        ]
      : []),
    ...(args.canViewOrganisations
      ? [
          {
            title: "headerTabs.organisations",
            route: ORGANISATION_LIST_ROUTE,
          },
        ]
      : []),
    ...(args.canViewReports
      ? [
          {
            title: "headerTabs.reports",
            route: REPORTING_ROUTE,
          },
        ]
      : []),
  ];

  return tabModels;
};

const buildAdeyAgentTabs = (): TabModel[] => {
  return [
    {
      title: "headerTabs.organisations",
      route: DASHBOARD_ROUTE,
    },
    {
      title: "headerTabs.memberUserList",
      route: USER_LIST_ROUTE,
    },
  ];
};

const buildAdeyAdminTabs = (): TabModel[] => {
  return [
    {
      title: "headerTabs.properties",
      route: DASHBOARD_ROUTE,
    },
    {
      title: "headerTabs.memberUserList",
      route: PLATFORM_USER_LIST_ROUTE,
    },
    {
      title: "headerTabs.totalOrganisationList",
      route: PLATFORM_ORGANISATIONS_LIST_ROUTE,
    },
    {
      title: "headerTabs.agents",
      route: PLATFORM_AGENT_LIST_ROUTE,
    },
  ];
};

export default buildTabModels;
