import { Box } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RAGStatus from "../../../pages/properties/PropertyListPage/model/RAGStatus";
import RootState from "../../../redux/RootState";
import { DEFAULT_SPACING } from "../../theme/dimensions";
import { DashboardMetrics } from "../DashboardLayout/redux/DashboardState";
import RAGCard from "./components/RAGCard";

// Because the animation should run only once, we want this to
// be globally defined for every DashboardLayout.
// We do not want this to be different for each instance of DashboardLayout.
let hasAnimationRun = false;

interface ReduxStateProps {
  isGreenCardActive: boolean;
  isAmberCardActive: boolean;
  isRedCardActive: boolean;

  metrics: DashboardMetrics | undefined;
}

type Props = ReduxStateProps;

class RAGCards extends React.Component<Props> {
  componentDidMount() {
    // This is run after the first render, turning off the animation
    // and making sure it's only once.
    hasAnimationRun = true;
  }

  render() {
    return (
      <Box display="flex" justifyContent="center" paddingX={DEFAULT_SPACING}>
        <RAGCard
          type="total"
          animated={!hasAnimationRun}
          metrics={this.props.metrics}
          active
        />

        <RAGCard
          type="green"
          animated={!hasAnimationRun}
          active={this.props.isGreenCardActive}
          metrics={this.props.metrics}
        />

        <RAGCard
          type="amber"
          animated={!hasAnimationRun}
          active={this.props.isAmberCardActive}
          metrics={this.props.metrics}
        />

        <RAGCard
          type="red"
          animated={!hasAnimationRun}
          active={this.props.isRedCardActive}
          metrics={this.props.metrics}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  // When everything is deselected, all the cards become active
  const isRAGActive = (color: RAGStatus) => {
    const selectedFilters = state.propertyList.selectedStatusFilters;
    return selectedFilters.size === 0 || selectedFilters.has(color);
  };

  return {
    isRedCardActive: isRAGActive("red"),
    isAmberCardActive: isRAGActive("amber"),
    isGreenCardActive: isRAGActive("green"),

    metrics: state.dashboard.metrics,
  };
};

export default connect(mapStateToProps)(RAGCards);
