import { Grid } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import CountryCode from "../../../../utils/CountryCode";
import { SMALL_SPACING } from "../../../theme/dimensions";
import CountryPicker from "../../CountryPicker";
import OutlineButton from "../../OutlineButton";
import PrimaryTextField, { FieldState } from "../../PrimaryTextField";

interface ExternalProps {
  isLoading: boolean;
  country: CountryCode | "";
  isAgent?: boolean;

  onSearchClicked: (searchText: string, country: CountryCode) => void;
  onCountryChanged: (country: CountryCode) => void;
}

type Props = ExternalProps & WithT;

interface State {
  searchText: FieldState;
  countryError: string | undefined;
}

class AddressLookupSearch extends React.Component<Props, State> {
  state = {
    searchText: { value: "" },
    countryError: undefined,
  };

  render() {
    const searchButton = this.props.t("addressLookup.searchButton");
    const searchPlaceholder = this.props.t("addressLookup.searchPlaceholder");

    return (
      <Grid container spacing={SMALL_SPACING}>
        <Grid item xs={12}>
          <CountryPicker
            hint={this.props.t("addressLookup.countryField")}
            errorText={this.state.countryError}
            value={this.props.country || ""}
            onChange={this.handleCountryChanged}
            isAgent={this.props.isAgent}
            fullWidth
          />
        </Grid>

        <Grid item xs={8}>
          <PrimaryTextField
            {...this.state.searchText}
            hint={searchPlaceholder}
            onChange={this.handleSearchTextChanged}
          />
        </Grid>

        <Grid item xs={4}>
          <OutlineButton
            label={searchButton}
            style={{ width: "100%", height: "100%" }}
            onClick={this.handleSearchClicked}
            isLoading={this.props.isLoading}
          />
        </Grid>
      </Grid>
    );
  }

  // MARK: Handlers
  handleSearchClicked = () => {
    const searchText = this.state.searchText.value.trim();
    const country = this.props.country;
    let valid = true;

    if (searchText.length === 0) {
      this.setState({
        searchText: {
          value: searchText,
          errorText: this.props.t("addressLookup.requiredError"),
        },
      });
      valid = false;
    }

    if (country === undefined || country === "") {
      this.setState({
        countryError: this.props.t("addressLookup.countryRequiredError"),
      });
      valid = false;
    }

    if (valid && country !== "") {
      this.props.onSearchClicked(searchText, country);
    }
  };

  handleSearchTextChanged = (value: string) => {
    this.setState({ searchText: { value: value } });
  };

  handleCountryChanged = (value: string) => {
    if (
      value === "GB" ||
      value === "IE" ||
      value === "AT" ||
      value === "FR" ||
      value === "AU"
    ) {
      this.props.onCountryChanged(value);
      this.setState({ countryError: undefined });
    }
  };
}

export default withTranslation()(AddressLookupSearch);
