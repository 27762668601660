import { Box, Divider } from "@material-ui/core";
import { WithT } from "i18next";
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import DeviceLogo from "../../../../../ui/assets/DeviceImages/DeviceLogo";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import SuccessSnackbarHandler from "../../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  EXTRA_LARGE_SPACING,
  DEFAULT_SPACING,
  LARGE_SPACING,
} from "../../../../../ui/theme/dimensions";
import ExportProcheckResultsDialog from "../../../../procheck/ProCheckPage/components/ExportProcheckResultsDialog";
import ProductReportTable from "../../../../procheck/ProCheckPage/components/ProductReportTable";
import ProductSummary from "../../../../procheck/ProCheckPage/components/ProductSummary";
import TablePageIndicator from "../../../../properties/PropertyListPage/components/TablePageIndicator";
import useReportPagination from "../hooks/useReportsPagination";
import useReportsTab from "../hooks/useReportsTab";
import useReportsSelection from "../hooks/useReportSelection";

export interface ExportDialogForm {
  emails: string[];
  hidePersonalDetails: boolean;
  notes?: string;
}

const areResultsEqual = (prevResults: {}, nextResults: {}) => {
  return false;
};

type Props = WithT & { isAdmin?: boolean };

const ProCheckReportsTab = (props: Props) => {
  const {
    proCheckState,
    selectedReports,
    tableItems,
    searchBarValue,
    handleReportCheckmarkClicked,
    handleSearchBarChanged,
    handleReportClicked,
    resetSelectedReports,
  } = useReportsSelection();

  const resetCallback = useCallback(() => {
    resetSelectedReports?.();
  }, [resetSelectedReports]);

  const {
    isDialogOpen,
    handleExportButtonClicked,
    exportSelectedReports,
    handleMultipleReportDownload,
  } = useReportsTab("org-reports", resetCallback);

  const { currentPage, maxPages, onPageNumberChanged } = useReportPagination();

  //Strings
  const title = props.t("proCheck.name");
  const productType = props.t("proCheck.productType");
  const successMessage = props.t("proCheck.exportSuccess");

  //Helpers
  const handleNextPageClicked = (page: number) => {
    onPageNumberChanged(
      page,
      selectedReports.map((report) => report.id)
    );
  };

  const onSearchBarChange = (value: string) => {
    handleSearchBarChanged(value);
  };

  return (
    <Box>
      <Box paddingX={EXTRA_LARGE_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Box display="flex" flexDirection="row">
          <Box flex={1} marginRight={LARGE_SPACING}>
            <ProductSummary
              title={title}
              productType={productType}
              logo={<DeviceLogo deviceType="PROCHECK" height={60} />}
            />
          </Box>
        </Box>

        <Box marginY={DEFAULT_SPACING}>
          <Divider />
        </Box>

        <div>
          <ProductReportTable
            hideTitle
            searchBarValue={searchBarValue}
            tableItems={tableItems}
            isLoading={proCheckState.isLoading}
            onReportClicked={handleReportClicked}
            onSearchChange={onSearchBarChange}
            onReportChange={handleReportCheckmarkClicked}
            onSelectedExportClicked={handleExportButtonClicked}
            selectedReports={selectedReports}
            showContractorName
            showTesterName
            propertyAddress={true}
          />
        </div>

        <ExportProcheckResultsDialog
          exported={proCheckState.reportExported}
          open={isDialogOpen}
          isLoading={proCheckState.exportingReport !== undefined}
          onDialogClose={() => {
            handleExportButtonClicked();
          }}
          handleReportsExport={(
            emails: string[],
            hidePersonalDetails: boolean,
            notes?: string
          ) => {
            exportSelectedReports(
              {
                emails: emails,
                hidePersonalDetails: hidePersonalDetails,
                notes: notes,
              },
              selectedReports
            );
          }}
          handleReportsDownload={(hidePersonalInformation) => {
            handleMultipleReportDownload(selectedReports, hidePersonalInformation);
          }}
        />
        <TablePageIndicator
          selectedPage={currentPage}
          maxPages={maxPages}
          onPageNumberChanged={handleNextPageClicked}
        />
      </Box>

      <SuccessSnackbarHandler
        message={proCheckState.reportExported ? successMessage : undefined}
      />
      <ErrorSnackbarHandler message={proCheckState.error} />
    </Box>
  );
};

export default withTranslation()(React.memo(ProCheckReportsTab, areResultsEqual));
