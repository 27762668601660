import { Box } from "@material-ui/core";
import React from "react";
import RAGStatus, { colorForStatus } from "../model/RAGStatus";

interface Props {
  status: RAGStatus;
  diameter?: number;
}

const RAGSymbol = (props: Props) => {
  const diameter = props.diameter || 20;
  const color = colorForStatus(props.status);

  return (
    <Box width={diameter} height={diameter} borderRadius={diameter / 2} bgcolor={color} />
  );
};

export default RAGSymbol;
