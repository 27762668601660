import AdminRepository from "../../../../rest/admin/AdminRepository";
import { OrgContractorDetails } from "../redux/AdminOrganisationDetailsState";

/**
 * Fetch the OrgContractorDetails for an org.
 * @param orgId The id of the org
 * @param adminRepo The repo to use
 * @returns
 */
const fetchOrgContractorDetails = async (
  orgId: string,
  adminRepo: AdminRepository
): Promise<OrgContractorDetails> => {
  // Use Promise.all to call all 3 at once.
  const result = await Promise.all([
    adminRepo.fetchOrganisationContractorList(orgId, undefined),
    adminRepo.fetchContractingOrganisations(orgId),
    adminRepo.fetchContractorInvites(orgId),
  ]);

  return {
    contractors: result[0],
    associatedOrgs: result[1],
    invites: result[2],
  };
};

export default fetchOrgContractorDetails;
