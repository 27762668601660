import { put, select, takeEvery } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import UserRepository from "../../../../rest/user/UserRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import {
  checkLoginStatus,
  LoginStateChangedAction,
  LOGIN_STATUS_CHANGED_TYPE,
} from "../../Login/redux/LoginAction";
import {
  accountSetupError,
  CreateOrganisationSubmittedAction,
  CREATE_ORGANISATION_SUBMITTED_TYPE,
  PersonalInfoSubmittedAction,
  PERSONAL_INFO_SUBMITTED_TYPE,
  userSetup,
  SEGMENTATION_DATA_SUBMITTED_TYPE,
  SegmentationDataSubmittedAction,
} from "./AccountSetupActions";
import buildOrgFormFromCode from "../../../admin/AdminOrganisationDetailsPage/utils/buildOrgFormFromCode";
import RootState from "../../../../redux/RootState";

function* accountSetupSagas() {
  yield takeEvery(PERSONAL_INFO_SUBMITTED_TYPE, submitPersonalInfo);
  yield takeEvery(CREATE_ORGANISATION_SUBMITTED_TYPE, submitCreateOrg);
  yield takeEvery(SEGMENTATION_DATA_SUBMITTED_TYPE, submitUserSegmentation);
  yield takeEvery(LOGIN_STATUS_CHANGED_TYPE, onLogInChanged);
}

function* submitPersonalInfo(action: PersonalInfoSubmittedAction) {
  try {
    const userRepository: UserRepository = yield getDependency("userRepository");

    yield userRepository.registerUserDetails(action.form);
    yield put(checkLoginStatus());
  } catch (e) {
    const message = getErrorMessage(e, "personalInfoPageFallbackError");
    yield put(accountSetupError(message));
  }
}

function* submitUserSegmentation(action: SegmentationDataSubmittedAction) {
  try {
    const userRepository: UserRepository = yield getDependency("userRepository");
    const rootState: RootState = yield select();
    const currentUser = rootState.currentUser.user;
    if (!currentUser) {
      throw new Error("No user data!");
    }
    const answersAsPairs: { [x in string]: string } = {};
    action.segmentationData.forEach((answer) => {
      answersAsPairs[answer.question_id] = answer.id;
    });

    yield userRepository.updateUserDetails(currentUser, answersAsPairs);
    yield put(checkLoginStatus());
  } catch (e) {
    const message = getErrorMessage(e, "userSegmentation.updateError");
    yield put(accountSetupError(message));
  }
}

function* submitCreateOrg(action: CreateOrganisationSubmittedAction) {
  try {
    const organisationRepository: OrganisationRepository = yield getDependency(
      "organisationRepository"
    );

    const rootState: RootState = yield select();

    const org = buildOrgFormFromCode(action.form, rootState.countryList.countryList);

    yield organisationRepository.registerOrganisation(org);

    yield put(checkLoginStatus());
  } catch (e) {
    const message = getErrorMessage(e, "createOrganisationPage.fallbackError");
    yield put(accountSetupError(message));
  }
}

function* onLogInChanged(action: LoginStateChangedAction) {
  if (action.loginStatus === "logged_in" || action.loginStatus === "logged_out") {
    // If logged in, or logged out, clear the account setup state
    yield put(userSetup());
  }
}

export default accountSetupSagas;
