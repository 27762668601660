import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import createRootStore from "./redux/createRootStore";
import { Provider } from "react-redux";
import initCognito from "./redux/initCognito";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { PAGE_BACKGROUND_COLOR } from "./ui/theme/createMaterialTheme";
import { initializeErrorInterceptor } from "./rest/utils/axiosErrorInterceptor";

// Init cognito
initCognito();

// initialize redux
const rootStore = createRootStore();

// Listen to 401 events and dispatch automatically
initializeErrorInterceptor(rootStore);

// Globally set the background color
document.body.style.backgroundColor = PAGE_BACKGROUND_COLOR;

// wrap the app in a provider for the
// root level store.
const app = (
  <Provider store={rootStore}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
