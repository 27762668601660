import { createRestError } from "../../utils/RestError";

const inviteErrorHandler = (e: any): string | undefined => {
  if (e === undefined || !e.isAxiosError) {
    return undefined;
  }

  const restError = createRestError(e);

  if (restError.statusCode === 404) {
    return "error.inviteStatus404";
  } else if (restError.statusCode === 409) {
    return "error.inviteStatus409";
  } else if (restError.statusCode === 400) {
    return e.response.data.message !== undefined
      ? e.response.data.message
      : "error.inviteStatus400";
  } else {
    return undefined;
  }
};
export default inviteErrorHandler;
