import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import UserDetails from "../../../../rest/user/model/UserDetails";

// Load platform user
export const LOAD_PLATFORM_USER_TYPE = "LOAD_PLATFORM_USER_TYPE";
export interface LoadPlatformUserAction {
  type: typeof LOAD_PLATFORM_USER_TYPE;
  userId: string;
}
export const loadPlatformUser = (userId: string): LoadPlatformUserAction => ({
  type: LOAD_PLATFORM_USER_TYPE,
  userId: userId,
});

// Load platform user success
export const LOAD_PLATFORM_USER_SUCCESS_TYPE = "LOAD_PLATFORM_USER_SUCCESS_TYPE";
export interface LoadPlatformUserSuccessAction {
  type: typeof LOAD_PLATFORM_USER_SUCCESS_TYPE;
  userDetails: UserDetails;
  organisations: MemberOrganisation[];
}
export const loadPlatformUserSuccess = (
  userDetails: UserDetails,
  organisations: MemberOrganisation[]
): LoadPlatformUserSuccessAction => ({
  type: LOAD_PLATFORM_USER_SUCCESS_TYPE,
  userDetails: userDetails,
  organisations: organisations,
});

// Load platform user error
export const PLATFORM_USER_ERROR_TYPE = "PLATFORM_USER_ERROR_TYPE";
export interface PlatformUserErrorAction {
  type: typeof PLATFORM_USER_ERROR_TYPE;
  error: string;
}
export const platformUserError = (error: string): PlatformUserErrorAction => ({
  type: PLATFORM_USER_ERROR_TYPE,
  error: error,
});

// Update user details
export const UPDATE_USER_DETAILS_TYPE = "UPDATE_USER_DETAILS_TYPE";
export interface UpdateUserDetailsAction {
  type: typeof UPDATE_USER_DETAILS_TYPE;
  details: UserDetails;
}
export const updateUserDetails = (userDetails: UserDetails): UpdateUserDetailsAction => ({
  type: UPDATE_USER_DETAILS_TYPE,
  details: userDetails,
});

// Remove user from an organisation dispatch
export const REMOVE_USER_FROM_ORG_TYPE = "REMOVE_USER_FROM_ORG_TYPE";
export interface RemoveUserFromOrgAction {
  type: typeof REMOVE_USER_FROM_ORG_TYPE;
  user: UserDetails;
  organisation: Organisation;
}

export const removeUserFromOrg = (
  user: UserDetails,
  org: Organisation
): RemoveUserFromOrgAction => ({
  type: REMOVE_USER_FROM_ORG_TYPE,
  user: user,
  organisation: org,
});

// Remove user from an organisation success
export const REMOVE_USER_FROM_ORG_SUCCESS_TYPE = "REMOVE_USER_FROM_ORG_SUCCESS_TYPE";

export interface RemoveUserFromOrgSuccessAction {
  type: typeof REMOVE_USER_FROM_ORG_SUCCESS_TYPE;
  user: UserDetails;
  organisation: Organisation;
}

export const removeUserFromOrgSuccess = (
  user: UserDetails,
  org: Organisation
): RemoveUserFromOrgSuccessAction => ({
  type: REMOVE_USER_FROM_ORG_SUCCESS_TYPE,
  user: user,
  organisation: org,
});

// Remove user from an organisation error
export const REMOVE_USER_FROM_ORG_ERROR_TYPE = "REMOVE_USER_FROM_ORG_ERROR_TYPE";

export interface RemoveUserFromOrgErrorAction {
  type: typeof REMOVE_USER_FROM_ORG_ERROR_TYPE;
  error: string;
}

export const removeUserFromOrgError = (error: string): RemoveUserFromOrgErrorAction => ({
  type: REMOVE_USER_FROM_ORG_ERROR_TYPE,
  error: error,
});





type PlatformUserDetailAction =
  | LoadPlatformUserAction
  | PlatformUserErrorAction
  | LoadPlatformUserSuccessAction
  | UpdateUserDetailsAction
  | RemoveUserFromOrgAction
  | RemoveUserFromOrgSuccessAction
  | RemoveUserFromOrgErrorAction;

export default PlatformUserDetailAction;
