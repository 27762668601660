import { Button } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import UserDetails from "../../../../rest/user/model/UserDetails";
import ConfirmRemoveUserDialog from "../../../users/detail/components/ConfirmRemoveUserDialog";

interface Props extends WithT {
  organisation: Organisation | undefined;
  user: UserDetails | undefined;
  loading: boolean;

  removeUserConfirmed: (user: UserDetails, organisation: Organisation) => void;
}

interface State {
  isConfirmOpen: boolean;
}

class AdminRemoveUserButton extends React.Component<Props, State> {
  state = {
    isConfirmOpen: false,
  };

  render() {
    const organisationName = this.props.organisation?.name || "";
    const user = this.props.user;
    const removeButtonLabel = this.props.t("buttons.remove");
    if (!organisationName || !user) {
      return null;
    }

    return (
      <div>
        <Button
          style={{ width: 80, height: 40 }}
          variant="contained"
          color="primary"
          onClick={this.handleClicked}
        >
          {removeButtonLabel}
        </Button>

        <ConfirmRemoveUserDialog
          organisationName={organisationName}
          user={user}
          open={this.state.isConfirmOpen}
          onCancelClicked={this.handleCancelClicked}
          onDeleteClicked={this.handleDeleteClicked}
        />
      </div>
    );
  }

  handleClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ isConfirmOpen: true });
    e.stopPropagation();
  };

  handleCancelClicked = () => {
    this.setState({ isConfirmOpen: false });
  };

  handleDeleteClicked = () => {
    if (this.props.user && this.props.organisation) {
      this.props.removeUserConfirmed(this.props.user, this.props.organisation);
      this.handleCancelClicked();
    }
  };
}

export default withTranslation()(AdminRemoveUserButton);
