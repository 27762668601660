import Organisation from "../../../../rest/organisation/model/Organisation";
import CreateOrganisationForm from "../../../auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";

// Submit org details

export const SUBMIT_ORG_DETAILS_TYPE = "SUBMIT_ORG_DETAILS_TYPE";

export interface SubmitOrgDetailsAction {
  type: typeof SUBMIT_ORG_DETAILS_TYPE;
  details: CreateOrganisationForm;
}

export const submitOrgDetails = (form: CreateOrganisationForm) => ({
  type: SUBMIT_ORG_DETAILS_TYPE,
  details: form,
});

// Submit owner info

export const SUBMIT_OWNER_INFO_TYPE = "SUBMIT_OWNER_INFO_TYPE";

export interface SubmitOwnerInfoAction {
  type: typeof SUBMIT_OWNER_INFO_TYPE;
  email: string;
}

export const submitOwnerInfo = (email: string) => ({
  type: SUBMIT_OWNER_INFO_TYPE,
  email: email,
});

// Create org success

export const CREATE_ORG_SUCCESS_TYPE = "CREATE_ORG_SUCCESS_TYPE";

export interface CreateOrgSuccessAction {
  type: typeof CREATE_ORG_SUCCESS_TYPE;
  organisation: Organisation;
}

export const createOrgSuccess = (org: Organisation): CreateOrgSuccessAction => ({
  type: CREATE_ORG_SUCCESS_TYPE,
  organisation: org,
});

// Create org error

export const CREATE_ORG_ERROR_TYPE = "CREATE_ORG_ERROR_TYPE";

export interface CreateOrgErrorAction {
  type: typeof CREATE_ORG_ERROR_TYPE;
  error: string;
}

export const createOrgError = (error: string): CreateOrgErrorAction => ({
  type: CREATE_ORG_ERROR_TYPE,
  error: error,
});

// Union type
type AdminCreateOrganisationAction =
  | SubmitOrgDetailsAction
  | SubmitOwnerInfoAction
  | CreateOrgSuccessAction
  | CreateOrgErrorAction;

export default AdminCreateOrganisationAction;
