import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import SecondaryButton from "../../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithT {
  onSignInClicked: () => void;
}

const ResendActivationEmailSuccessPanel = (props: Props) => {
  const message = props.t("resendActivationEmailSuccessPage.message");
  const prompt = props.t("resendActivationEmailSuccessPage.signInPrompt");
  const signInButton = props.t("resendActivationEmailSuccessPage.signInButton");

  return (
    <Grid container spacing={DEFAULT_SPACING} justify="center">
      <Grid item />

      <Grid item>
        <Typography align="center" variant="body2">
          {message}
        </Typography>
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body2">
            <strong>{prompt}</strong>
          </Typography>
          <SecondaryButton label={signInButton} onClick={props.onSignInClicked} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ResendActivationEmailSuccessPanel);
