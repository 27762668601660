export type LoadingBackgroundType = "logged_in" | "logged_out";

class LoadingBackgroundService {
  private static _backgroundTypeStorageKey = "LOADING_BACKGROUND";

  constructor(private storage: Storage = window.localStorage) {}

  setBackgroundType = (backgroundType: LoadingBackgroundType) => {
    this.storage.setItem(
      LoadingBackgroundService._backgroundTypeStorageKey,
      backgroundType
    );
  };

  getBackgroundType = (): LoadingBackgroundType => {
    const value = this.storage.getItem(
      LoadingBackgroundService._backgroundTypeStorageKey
    );

    if (value === "logged_in") {
      return value;
    } else {
      return "logged_out";
    }
  };
}

export default LoadingBackgroundService;
