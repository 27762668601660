import React from "react";
import { Typography, TypographyProps, withStyles } from "@material-ui/core";

// The props of this component are the same as the Typography props
// but without "color" and with "textColor".
//
// Note: Omit<Foo, "bar"> creates a type like Foo but without
// property "bar"
interface Props extends Omit<TypographyProps, "color"> {
  textColor: string;
}

/**
 * The material component `Typography` does not
 * support arbitrary colors. This overrides that
 * behavior and allows setting any css color
 * to the text.
 * All `Typography` props (other than "color") are
 * all working.
 *
 * @param props.textColor
 */
const ColoredText = (props: Props) => {
  // Create a version of typography that has the color set
  const ColoredTypography = withStyles({
    root: {
      color: props.textColor,
    },
  })(Typography);

  // Return that component with the props forwarded
  // but delete textColor because it's already been used.
  const propsToForward: any = { ...props };
  delete propsToForward.textColor;

  return <ColoredTypography {...propsToForward} />;
};

export default ColoredText;
