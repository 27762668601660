import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../../../ui/theme/dimensions";
import OverdueStatus, {
  colorForOverdueStatus,
  titleForOverdueStatus,
} from "../../../model/OverdueStatus";

// Setting a fixed width to the text is fine here
// It'll get bigger in height.
const textWidth = 120;

interface Props extends WithTranslation {
  status: OverdueStatus;
  isChecked: boolean;
  startIcon?: IconDefinition;
  onCheckChanged: (status: OverdueStatus, checked: boolean) => void;
}

const OverdueStatusCheckbox = (props: Props) => {
  const onChange = (_: any, isChecked: boolean) => {
    props.onCheckChanged(props.status, isChecked);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{ marginRight: SMALL_SPACING }}
    >
      <Box display="flex" alignItems="center">
        {props.startIcon ? (
          <FontAwesomeIcon
            icon={props.startIcon}
            color={colorForOverdueStatus(props.status)}
          />
        ) : null}
        <Box width={LARGE_SPACING} />

        <Typography style={{ width: textWidth }}>
          {titleForOverdueStatus(props.status, props.t)}
        </Typography>
      </Box>
      <Checkbox checked={props.isChecked} onChange={onChange} />
    </Box>
  );
};

export default withTranslation()(OverdueStatusCheckbox);
