import { Box } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import { Country } from "../../../../../rest/countries/model/Country";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import AgentDialog from "../AgentDialog/AgentDialog";
import AgentSwitch from "../AgentSwitch/AgentSwitch";
import { DEFAULT_SPACING, EXTRA_LARGE_SPACING } from "../../../../../ui/theme/dimensions";
import EditableAddress from "../../../../properties/PropertyDetailPage/components/EditableField/EditableAddress";
import EditableField from "../../../../properties/PropertyDetailPage/components/EditableField/EditableField";
import Address from "../../../../properties/PropertyDetailPage/model/Address";
import {
  revokeAgentAccess,
  updateAgentDetails,
} from "../../redux/PlatformAgentDetailsActions";
import { buildPlatformOrgDetailsRoute } from "../../../../../routes/routes";
import AgentAssignmentDialog from "../AgentAssignmentDialog/AgentAssignmentDialog";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import AgentInfoCard from "../AgentInfoCard/AgentInfoCard";
import Spinner from "../../../../../ui/components/Spinner";

//Types
type FieldName = "organisationName" | "address" | "contactNumber" | "vatNumber";

//Props
interface ReduxDispatchProps {
  updateAgentOrganisation: (org: Organisation) => void;
  revokeAgentAccess: (org: Organisation) => void;
}

interface ReduxStateProps {
  selectedOrganisation: Organisation | undefined;
  isLoading: boolean;
  loadingManagedOrgs: boolean;
  isUpdating: boolean;
  error: string | undefined;
  updateSuccess: boolean | undefined;
  agentDisabled: boolean | undefined;
  orgCounter: number | undefined;
}

//State
interface State {
  editingField: FieldName | undefined;
  showAgentDialog: boolean;
}

type Props = ReduxStateProps & ReduxDispatchProps & WithTranslation & RouteComponentProps;

class PlatformAgentDetailsTab extends Component<Props, State> {
  state = {
    editingField: undefined,
    showAgentDialog: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.updateSuccess && !this.props.isUpdating && prevProps.isUpdating) {
      this.setState({ editingField: undefined });
    }
  }

  render() {
    return (
      <Box>
        {!this.props.isLoading && !this.props.loadingManagedOrgs ? (
          <Box display="flex" flexDirection="row">
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="top"
              paddingY={DEFAULT_SPACING}
              justifyContent="flex-start"
              marginX={EXTRA_LARGE_SPACING}
            >
              {this.renderEditableField(
                "currentOrganisationPage.organisationNameLabel",
                this.props.selectedOrganisation?.name,
                "organisationName"
              )}

              <EditableAddress
                isAgent={true}
                country={this.buildCountry()}
                label={this.props.t("currentOrganisationPage.addressLabel")}
                address={this.buildAddress()}
                isEditing={this.state.editingField === "address"}
                onEditClicked={() => this.editClickedHandler("address")}
                hoverEnabled={this.canHover()}
                onCancelClicked={this.cancelClickedHandler}
                onSaveClicked={this.saveAddressClickedHandler}
                loading={this.props.isUpdating}
              />

              {this.renderEditableField(
                "currentOrganisationPage.contactNumberLabel",
                this.props.selectedOrganisation?.phone_number,
                "contactNumber"
              )}

              {this.renderEditableField(
                "currentOrganisationPage.vatNumberLabel",
                this.props.selectedOrganisation?.vat_number,
                "vatNumber"
              )}

              <AgentSwitch
                isAgent={this.props.selectedOrganisation?.is_agent_for !== undefined}
                switchAgentState={this.handleAgentAccessSwitch}
              />

              <AgentDialog
                isAgent={this.props.selectedOrganisation?.is_agent_for !== undefined}
                isOpen={this.state.showAgentDialog}
                organisationName={this.props.selectedOrganisation?.name || ""}
                onCancel={this.handleAgentDialogCancel}
                onSubmit={this.handleRemoveAccessClicked}
              />

              <AgentAssignmentDialog
                isAgent={true}
                onOkClicked={this.handleAccessRemovedClicked}
                organisationName={this.props.selectedOrganisation?.name || ""}
                open={this.props.agentDisabled}
              />
            </Box>
            <Box flex={1} marginX={EXTRA_LARGE_SPACING}>
              <AgentInfoCard
                countryId={
                  this.props.selectedOrganisation?.is_agent_for?.code || "MISSING"
                }
                organisationCounter={this.props.orgCounter}
              />
            </Box>
          </Box>
        ) : (
          <Spinner />
        )}

        <ErrorSnackbarHandler message={this.props.error} />
      </Box>
    );
  }
  buildCountry = (): Country => {
    return {
      id: this.props.selectedOrganisation?.address_country.id || "",
      code: this.props.selectedOrganisation?.address_country.code || "",
      name: this.props.selectedOrganisation?.address_country.name || "",
    };
  };

  buildAddress = (): Address => {
    return {
      line1: this.props.selectedOrganisation?.address_line_1 || "",
      line2: this.props.selectedOrganisation?.address_line_2,
      line3: this.props.selectedOrganisation?.address_line_3,
      city: this.props.selectedOrganisation?.address_city || "",
      postcode: this.props.selectedOrganisation?.address_postcode || "",
      country: this.props.selectedOrganisation?.address_country.code || "",
    };
  };

  renderEditableField = (
    labelId: string,
    value: string | undefined,
    fieldName: FieldName
  ) => {
    const label = this.props.t(labelId);
    return (
      <EditableField
        label={label}
        text={value}
        required={true}
        isEditing={this.state.editingField === fieldName}
        onEditClicked={() => this.editClickedHandler(fieldName)}
        hoverEnabled={this.canHover()}
        onCancelClicked={this.cancelClickedHandler}
        onSaveClicked={(value) => this.saveClickedHandler(fieldName, value)}
        loading={this.props.isUpdating}
        gutterBottom
      />
    );
  };

  // Handlers
  handleRemoveAccessClicked = () => {
    if (this.props.selectedOrganisation) {
      this.props.revokeAgentAccess(this.props.selectedOrganisation);
    }
  };

  handleAccessRemovedClicked = () => {
    if (this.props.selectedOrganisation?.id) {
      const route = buildPlatformOrgDetailsRoute(this.props.selectedOrganisation?.id);
      this.props.history.replace(route);
    }
  };

  handleAgentAccessSwitch = (showAgentDialog: boolean) => {
    this.setState({ showAgentDialog: true });
  };

  handleAgentDialogCancel = () => {
    this.setState({ showAgentDialog: false });
  };

  canHover = (): boolean => {
    return this.state.editingField === undefined && !this.props.isUpdating;
  };

  editClickedHandler = (field: FieldName) => {
    this.setState({ editingField: field });
  };

  cancelClickedHandler = () => {
    this.setState({ editingField: undefined });
  };

  saveClickedHandler = (field: FieldName, value: string) => {
    if (this.props.selectedOrganisation === undefined) {
      return;
    }

    const updatedOrg = { ...this.props.selectedOrganisation };

    switch (field) {
      case "contactNumber":
        updatedOrg.phone_number = value;
        break;

      case "organisationName":
        updatedOrg.name = value;
        break;

      case "vatNumber":
        updatedOrg.vat_number = value;
        break;
    }

    this.props.updateAgentOrganisation(updatedOrg);
  };

  saveAddressClickedHandler = (address: Address) => {
    if (this.props.selectedOrganisation === undefined) {
      return;
    }

    const updatedOrg: Organisation = {
      ...this.props.selectedOrganisation,
      address_line_1: address.line1,
      address_line_2: address.line2,
      address_line_3: address.line3,
      address_city: address.city,
      address_country: { id: "", name: "", code: address.country }, //Name and code do not matter
      address_postcode: address.postcode,
    };

    this.props.updateAgentOrganisation(updatedOrg);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  selectedOrganisation: state.platformAgentDetails.agentDetails,
  isLoading: state.platformAgentDetails.isLoadingAgentDetails,
  loadingManagedOrgs: state.platformAgentDetails.isLoadingManagedOrganisations,
  isUpdating: state.platformAgentDetails.isUpdatingAgentDetails,
  error: state.platformAgentDetails.error,
  updateSuccess: state.platformAgentDetails.agentDetailsLoaded,
  agentDisabled: state.platformAgentDetails.agentDisabled,
  orgCounter: state.platformAgentDetails.managedOrganisations?.length,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  updateAgentOrganisation: (org) => dispatch(updateAgentDetails(org)),
  revokeAgentAccess: (org) => dispatch(revokeAgentAccess(org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PlatformAgentDetailsTab)));
