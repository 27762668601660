import { TFunction } from "i18next";

type DeviceStatus =
  | "battery_low"
  | "battery_critical"
  | "magnetite_level_medium"
  | "magnetite_level_high"
  | "no_contact_with_device";

export const titleForDeviceStatus = (status: DeviceStatus, t: TFunction): string => {
  switch (status) {
    case "battery_low":
      return t("deviceStatus.batteryLow");
    case "battery_critical":
      return t("deviceStatus.batteryCritical");
    case "magnetite_level_medium":
      return t("deviceStatus.magnetiteLevelMedium");
    case "magnetite_level_high":
      return t("deviceStatus.magnetiteLevelHigh");
    case "no_contact_with_device":
      return t("deviceStatus.noContactWithDevice");
  }
};

export const colorForDeviceStatus = (status: DeviceStatus): string => {
  switch (status) {
    case "battery_critical":
      return "#FF0000";
    case "battery_low":
      return "#FF7F00";
    case "magnetite_level_medium":
      return "#FF7F00";
    case "magnetite_level_high":
      return "#FF0000";
    case "no_contact_with_device":
      return "#FF0000";
  }
};

export default DeviceStatus;
