import { put, takeEvery } from "redux-saga/effects";
import getErrorMessage from "../../../errors/messages/getErrorMessage";
import getDependency from "../../../redux/utils/getDependency";
import PropertiesRepository from "../../../rest/properties/PropertiesRepository";
import {
  HomeZoneUnsubscribeAction,
  homeZoneUnsubscribed,
  homeZoneUnsubscribeError,
  HOMEZONE_UNSUBSCRIBE_TYPE,
} from "./HomeZoneAction";

function* homeZoneSagas() {
  yield takeEvery(HOMEZONE_UNSUBSCRIBE_TYPE, homeZoneUnsubscribeSaga);
}

function* homeZoneUnsubscribeSaga(action: HomeZoneUnsubscribeAction) {
  //Function is future-proofed to accomodate possible behavior changes.
  //To comply with security recommendations, no user-facing information is available on whether the unsubscription is succesfull or not
  try {
    // Get the dependencies from the context
    const authService: PropertiesRepository = yield getDependency("propertiesRepository");
    yield authService.unsubscribeFromHomezone(action.token);
    yield put(homeZoneUnsubscribed());
  } catch (e) {
    const message = getErrorMessage(e, "homezoneUnsubscribePage.unsubscribeError");

    yield put(homeZoneUnsubscribeError(message));
  }
}

export default homeZoneSagas;
