import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { buildPropertyDetailRoute } from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import AddPropertyFormModel from "../AddPropertyDialog/model/AddPropertyFormModel";
import {
  addProperty,
  changePageType,
  pageChanged,
  searchTextChanged,
} from "./redux/PropertyListActions";
import Property, { PropertyPageType } from "../../../rest/properties/model/Property";
import FilterablePropertyList from "./components/FilterablePropertyList";
import {
  initGlobalExport,
  initItemExport,
} from "../../../ui/components/ExportBar/redux/ExportBarAction";
import BarePropertyList from "./components/BarePropertyList";
import ExportItemModel from "../../../ui/components/ExportBar/models/ExportItemModel";

interface ReduxStateProps {
  isAdeyAdmin: boolean;
  searchText: string;
  properties: Property[] | undefined;
  currentPage: number;
  maxPages: number;
  errorMessage: string | undefined;
  addingProperty: boolean;
  isPropertyAdded: boolean | undefined;
  addPropertyError: string | undefined;
  orgId: string | undefined;
  page: PropertyPageType | undefined;
  canCreateProperties: boolean;
  isGeneratingReport: boolean;
  exportItems: ExportItemModel[];
}

interface ReduxDispatchProps {
  onSearchTextChanged: (text: string) => void;
  onPageNumberChanged: (page: number) => void;
  onPropertyFormSubmitted: (propertyData: AddPropertyFormModel) => void;
  onPageTypeChanged: (page: PropertyPageType) => void;
  exportOrganisation: (organisationId: string, startDate: Date, endDate: Date) => void;
  globalExport: () => void;
}

interface State {
  isAddPropertyDialogOpen: boolean;
  exportDate: Date[];
}

type Props = RouteComponentProps & ReduxStateProps & ReduxDispatchProps & WithT;

const initialDate = new Date();

class PropertyListPage extends React.Component<Props, State> {
  state = {
    isAddPropertyDialogOpen: false,
    exportDate: [
      new Date(initialDate.getFullYear(), initialDate.getMonth(), 1),
      initialDate,
    ],
  };

  componentDidMount() {
    const correctPage = this.props.isAdeyAdmin ? "admin-all-properties" : "dashboard";
    if (this.props.page !== correctPage) {
      this.props.onPageTypeChanged(correctPage);
    }
  }

  componentDidUpdate() {
    const correctPage = this.props.isAdeyAdmin ? "admin-all-properties" : "dashboard";
    if (this.props.page !== correctPage) {
      this.props.onPageTypeChanged(correctPage);
    }
  }

  render() {
    // Disable adding properties if we're on the all properties page
    // or if we don't have permission
    // Additionally, show a bare property list when the page is an admin dashboard
    const allowAddingProperties =
      this.props.page !== "admin-all-properties" && this.props.canCreateProperties;

    return (
      <DashboardLayout hideRagStatus={this.props.page === "admin-all-properties"}>
        {this.props.page === "admin-all-properties" ? (
          <BarePropertyList
            {...this.props}
            showOwnerInTable
            onPropertyClicked={this.handlePropertyClicked}
          />
        ) : (
          <FilterablePropertyList
            {...this.props}
            showOwnerInTable={false}
            canCreateProperties={allowAddingProperties}
            onPropertyClicked={this.handlePropertyClicked}
            exportDate={this.state.exportDate}
            setExportDate={(date) => {
              this.setState({ ...this.state, exportDate: date });
            }}
            isGeneratingReport={
              this.props.isGeneratingReport || this.isSameItemExporting()
            }
            handleExportClicked={(startDate, endDate) =>
              this.props.orgId !== undefined
                ? this.props.page !== "admin-all-properties"
                  ? this.props.exportOrganisation(this.props.orgId, startDate, endDate)
                  : this.props.globalExport()
                : undefined
            }
          />
        )}
      </DashboardLayout>
    );
  }

  // Helpers
  isSameItemExporting = () => {
    return (
      this.props.exportItems.filter(
        (item) =>
          item.organisation_id === this.props.orgId &&
          this.state.exportDate !== null &&
          this.state.exportDate[0].toISOString().split("T")[0] === item.startDate &&
          this.state.exportDate[1].toISOString().split("T")[0] === item.endDate
      ).length > 0
    );
  };

  // MARK: Handler

  handlePropertyClicked = (item: Property) => {
    const orgId = item.organisation?.id || this.props.orgId;
    if (orgId === undefined) {
      return;
    }

    const route = buildPropertyDetailRoute(orgId, item.id);
    this.props.history.push(route);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  const isGeneratingReport =
    state.exportBar.exportItems.filter(
      (item) => item.id === state.activeOrganisation.currentOrganisation?.id //If report has the same ID as org, it means we are attempting to generate it
    ).length > 0;

  return {
    isAdeyAdmin: state.adeyAdmin.hasAdminAccess,
    orgId: state.activeOrganisation.currentOrganisation?.id,
    searchText: state.propertyList.searchText,
    properties: state.propertyList.propertyList,
    currentPage: state.propertyList.currentPage,
    maxPages: state.propertyList.maxPages,
    errorMessage: state.propertyList.errorMessage,
    addPropertyError: state.propertyList.addPropertyError,
    addingProperty: state.propertyList.addingProperty,
    isPropertyAdded: state.propertyList.isPropertyAdded,
    page: state.propertyList.page,
    canCreateProperties: state.activeOrganisation.permissions.canCreateProperties,
    exportItems: state.exportBar.exportItems,
    isGeneratingReport: isGeneratingReport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    onPageNumberChanged: (page) => dispatch(pageChanged(page)),
    onSearchTextChanged: (text) => dispatch(searchTextChanged(text)),
    onPropertyFormSubmitted: (propertyForm) => dispatch(addProperty(propertyForm)),
    onPageTypeChanged: (pageType) => dispatch(changePageType(pageType)),
    exportOrganisation: (organisationId, startDate, endDate) =>
      dispatch(initItemExport(organisationId, startDate, endDate)),
    globalExport: () => dispatch(initGlobalExport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PropertyListPage)));
