import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import ProCheckResult from "../../../../rest/procheck/model/ProCheckResult";
import ColoredText from "../../../../ui/components/ColoredText";
import OutlineButton from "../../../../ui/components/OutlineButton";
import SearchBar from "../../../../ui/components/SearchBar";
import Spinner from "../../../../ui/components/Spinner";
import { BACKGROUND_ACCENT_COLOR } from "../../../../ui/theme/createMaterialTheme";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import ProductReportTableItem from "../model/ProductReportTableItem";
import ProductReportTableRow from "./ProductReportTableRow";
import NoReportsFound from "./NoReportsFound";

interface Props extends WithT {
  tableItems: ProductReportTableItem[];
  selectedReports: ProCheckResult[];
  searchBarValue: string;
  onExportClicked?: (report: ProductReportTableItem) => void;
  onSelectedExportClicked?: () => void;
  onSelectedMoveClicked?: () => void;
  onReportChange?: (report: ProductReportTableItem, selected: boolean) => void;
  onSearchChange: (value: string) => void;
  onReportClicked: (report: ProductReportTableItem) => void;
  selectableRows?: boolean;
  propertyAddress?: boolean;
  showTesterName?: boolean;
  showContractorName?: boolean;
  hideTitle?: boolean;
  isLoading?: boolean;
}

//Used to set default selectable value to true
const defaultProps = {
  hideTitle: false,
  selectableRows: true,
  propertyAddress: false,
};

const ProductReportTable = (props: Props) => {
  props = { ...defaultProps, ...props };

  const StyledTable = withStyles({
    root: {
      borderCollapse: "separate",
      borderSpacing: "0 8px",
      width: "100%",
    },
  })(Table);

  const paddedHeaderStyle = {
    paddingLeft: "8px",
  };

  const checkboxStyle = {
    padding: "checkbox",
  };

  const searchPlaceholder =
    !props.showContractorName && !props.showTesterName
      ? props.t("proCheckTable.searchBarPlaceholder")
      : props.t("proCheckTable.expandedSearchBarPlaceholder");
  const selectedCounter = props
    .t("proCheckTable.resultSelectorCounter")
    .replace("<result_counter>", `${props.selectedReports.length}`);

  const checkRowSelected = (item: ProductReportTableItem) => {
    return props.selectedReports.find((report) => report.id === item.id);
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        {!props.hideTitle && (
          <ColoredText
            textColor={BACKGROUND_ACCENT_COLOR}
            variant="button"
            style={{ paddingRight: EXTRA_LARGE_SPACING }}
          >
            <strong>
              <Trans>productReportTable.title</Trans>
            </strong>
          </ColoredText>
        )}

        <Box marginLeft="auto">
          {props.selectedReports.length > 0 ? (
            <ColoredText
              textColor={BACKGROUND_ACCENT_COLOR}
              variant="button"
              style={{ paddingRight: EXTRA_LARGE_SPACING }}
            >
              <strong>
                <Trans>{selectedCounter}</Trans>
              </strong>
            </ColoredText>
          ) : null}
        </Box>
      </Box>

      <Box margin={DEFAULT_SPACING}></Box>

      <Box display="flex" flexDirection="row">
        <SearchBar
          placeholder={searchPlaceholder}
          value={props.searchBarValue}
          onChange={props.onSearchChange}
        />

        {props.selectableRows && props.onSelectedExportClicked ? (
          <OutlineButton
            style={{ marginLeft: LARGE_SPACING * 2, width: "250px" }}
            labelId="proCheckTable.exportSelectedButtonLabel"
            endIcon={<FontAwesomeIcon icon={faShareSquare} />}
            onClick={props.onSelectedExportClicked}
            disabled={props.selectedReports.length === 0 ? true : false}
            filled
          />
        ) : undefined}

        {props.selectableRows && props.onSelectedMoveClicked ? (
          <>
            <Divider orientation="vertical" />
          </>
        ) : undefined}
      </Box>

      <Box margin={SMALL_SPACING}></Box>
      {props.isLoading && <Spinner />}
      {!props.isLoading && props.tableItems.length === 0 && <NoReportsFound />}
      {!props.isLoading && props.tableItems.length > 0 && (
        <StyledTable>
          <TableHead>
            <TableRow>
              {props.selectableRows &&
                !props.showTesterName &&
                !props.showContractorName && <HeaderCell style={checkboxStyle} />}
              <HeaderCell textId="productReportTable.headerStatus" />
              <HeaderCell
                textId="productReportTable.headerDate"
                style={paddedHeaderStyle}
              />
              {props.showContractorName && (
                <HeaderCell
                  textId={"productReportTable.headerContractor"}
                  style={paddedHeaderStyle}
                />
              )}
              {props.showTesterName && (
                <HeaderCell
                  textId={"productReportTable.headerTester"}
                  style={paddedHeaderStyle}
                />
              )}

              {props.propertyAddress ? (
                <HeaderCell
                  textId="productReportTable.headerProperty"
                  style={paddedHeaderStyle}
                />
              ) : (
                <HeaderCell
                  textId="productReportTable.headerReportee"
                  style={paddedHeaderStyle}
                />
              )}

              {props.selectableRows &&
                props.showTesterName &&
                props.showContractorName && (
                  <HeaderCell
                    style={checkboxStyle}
                    textId="productReportTable.headerEmail"
                  />
                )}

              <HeaderCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {props.tableItems.map((item) => (
              <ProductReportTableRow
                key={item.id}
                item={item}
                onExportClicked={props.onExportClicked}
                onReportChange={props.onReportChange}
                onReportClicked={props.onReportClicked}
                isSelected={checkRowSelected(item) !== undefined}
                isSelectingDisabled={
                  checkRowSelected(item) === undefined &&
                  props.selectedReports.length === 5
                }
                selectable={props.selectableRows}
                showPropertyAddress={props.propertyAddress}
                showTesterName={props.showTesterName}
                showContractorName={props.showContractorName}
              />
            ))}
          </TableBody>
        </StyledTable>
      )}
    </Box>
  );
};

const HeaderCell = (props: { textId?: string; style?: React.CSSProperties }) => {
  const StyledCell = withStyles({
    root: {
      border: "none",
      paddingBottom: "0",
    },
  })(TableCell);

  return (
    <StyledCell align="left" padding="none" style={props.style}>
      <ColoredText textColor={BACKGROUND_ACCENT_COLOR} variant="button">
        <strong>
          <Trans>{props.textId}</Trans>
        </strong>
      </ColoredText>
    </StyledCell>
  );
};

export default withTranslation()(ProductReportTable);
