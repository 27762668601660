import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import { Country } from "../../../../../rest/countries/model/Country";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import OrganisationEditor from "../../../../../ui/components/OrganisationEditor/OrganisationEditor";
import SecondaryButton from "../../../../../ui/components/SecondaryButton";
import { DEFAULT_SPACING } from "../../../../../ui/theme/dimensions";
import CreateOrganisationForm from "../../../../auth/AccountSetup/pages/CreateOrganisation/model/CreateOrganisationForm";
import { submitOrgDetails } from "../../redux/AdminCreateOrganisationAction";

interface ExternalProps {
  onFormCompleted: () => void;
  onInviteClicked: () => void;
}

interface ReduxStateProps {
  orgDetails: CreateOrganisationForm | undefined;
  countryList: Country[] | undefined;
  areCountriesLoading: boolean | undefined;
  error: string | undefined;
}

interface ReduxDispatchProps {
  onDetailsSubmitted: (form: CreateOrganisationForm) => void;
}

type Props = WithT & ExternalProps & ReduxStateProps & ReduxDispatchProps;

class CreateOrganisationAdminForm extends React.Component<Props> {
  componentDidUpdate() {
    // When the org details change, the form is completed
    // Tell the parent component to change page
    if (this.props.orgDetails) {
      this.props.onFormCompleted();
    }
  }

  render() {
    const title = this.props.t("createOrganisationAdminForm.title");
    const invitationPrompt = this.props.t("createOrganisationAdminForm.invitePrompt");
    const invitationButton = this.props.t("createOrganisationAdminForm.inviteButton");

    return (
      <form>
        <Grid container direction="column" spacing={DEFAULT_SPACING}>
          <Grid item>
            <Typography variant="h2" align="center" gutterBottom>
              <strong>{title.toUpperCase()}</strong>
            </Typography>
          </Grid>

          <OrganisationEditor
            countryList={this.props.countryList}
            isLoading={false}
            organisation={this.convertToOrg(this.props.orgDetails)}
            onContinuePressed={this.handleContinueClicked}
            isAdmin={true}
          />

          <Grid item>
            <Typography align="center">{invitationPrompt}</Typography>

            <Box display="flex" justifyContent="center">
              <SecondaryButton
                label={invitationButton}
                onClick={this.handleInviteClicked}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  }

  // MARK: Handlers

  handleOrganisationNameChanged = (value: string) => {
    this.setState({ organisationName: { value: value } });
  };

  handleOrganisationNumberChanged = (value: string) => {
    this.setState({ organisationContactNumber: { value: value } });
  };

  handleContinueClicked = (form: CreateOrganisationForm) => {
    this.props.onDetailsSubmitted(form);
  };

  handleInviteClicked = () => {
    this.props.onInviteClicked();
  };

  // MARK: Mapping data

  convertToOrg = (form: CreateOrganisationForm | undefined): Organisation | undefined => {
    if (!form) return undefined;

    return {
      features: [],
      id: "", // doesn't matter
      name: form.organisationName,
      phone_number: form.organisationNumber,
      vat_number: form.vatNumber,
      address_line_1: form.address.line1,
      address_line_2: form.address.line2,
      address_line_3: form.address.line3,
      address_city: form.address.city,
      address_postcode: form.address.postcode,
      address_country: { id: "", name: "", code: form.address.country }, // Name and code do not matter
    };
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  orgDetails: state.adminCreateOrganisation.organisationDetails,
  countryList: state.countryList.countryList,
  areCountriesLoading: state.countryList.isLoading,
  error: state.countryList.error,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onDetailsSubmitted: (form) => dispatch(submitOrgDetails(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateOrganisationAdminForm));
