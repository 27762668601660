import React from "react";
import BorderedBox from "../../../../../ui/components/BorderedBox";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";

interface Props {
  error: string;
}

const ProCheckErrorTile = (props: Props) => {
  return (
    <BorderedBox display="flex" flexDirection="column" height="100%">
      <ErrorDisplay title={props.error} />
    </BorderedBox>
  );
};

export default ProCheckErrorTile;
