import { takeLatest } from "@redux-saga/core/effects";
import { put, select } from "redux-saga/effects";
import Address from "../../../../pages/properties/PropertyDetailPage/model/Address";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import LoqateRepository from "../../../../rest/loqate/LoqateRepository";
import LoqateFindResponse from "../../../../rest/loqate/model/LoqateFindResponse";
import LoqateRetrieveResponse from "../../../../rest/loqate/model/LoqateRetrieveResponse";
import {
  addressDetailsLoaded,
  itemExpanded,
  ItemSelectedAction,
  ITEM_SELECTED_TYPE,
  SearchAddressAction,
  searchAddressError,
  searchAddressSuccess,
  SEARCH_ADDRESS_TYPE,
} from "./AddressLookupAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import LocalizedError from "../../../../errors/LocalizedError";

function* addressLookupSagas() {
  yield takeLatest(SEARCH_ADDRESS_TYPE, searchAddressSaga);
  yield takeLatest(ITEM_SELECTED_TYPE, itemSelectedSaga);
}

function* searchAddressSaga(action: SearchAddressAction) {
  try {
    const repo: LoqateRepository = yield getDependency("loqateRepository");

    const response: LoqateFindResponse = yield repo.findAddresses(
      action.searchText,
      action.country
    );
    if (response.Items.length === 0) {
      throw new LocalizedError("addressLookup.emptyError");
    }

    yield put(searchAddressSuccess(response.Items));
  } catch (e) {
    const error = getErrorMessage(e, "addressLookup.fetchError");
    yield put(searchAddressError(error));
  }
}

function* itemSelectedSaga(action: ItemSelectedAction) {
  if (action.item.Type.toLowerCase() === "address") {
    yield retrieveAddressSaga(action);
  } else {
    yield expandGroupSaga(action);
  }
}

function* expandGroupSaga(action: ItemSelectedAction) {
  try {
    const repo: LoqateRepository = yield getDependency("loqateRepository");
    const rootState: RootState = yield select();

    const lastSearch = rootState.addressLookup.lastSearch;
    if (lastSearch === undefined) {
      throw new Error();
    }

    const response: LoqateFindResponse = yield repo.expandContainer(
      lastSearch.searchText,
      action.item.Id,
      lastSearch.loqateCountry
    );
    yield put(itemExpanded(action.item, response.Items));
  } catch (e) {
    const message = getErrorMessage(e, "addressLookup.fetchError");
    yield put(searchAddressError(message));
  }
}

function* retrieveAddressSaga(action: ItemSelectedAction) {
  try {
    const repo: LoqateRepository = yield getDependency("loqateRepository");
    const rootState: RootState = yield select();

    const response: LoqateRetrieveResponse = yield repo.retrieveAddress(action.item.Id);
    const itemLength = response.Items?.length || 0;

    if (itemLength === 0) {
      throw new LocalizedError("addressLookup.retrieveError");
    }

    const item = response.Items[0];

    const countryId = rootState.countryList.countryList?.find(
      (country) => country.code === item.CountryIso2
    )?.code;

    const address: Address = {
      line1: item.Line1 || "",
      line2: item.Line2 || "",
      line3: item.Line3 || "",
      postcode: item.PostalCode || "",
      city: item.City || "",
      country: countryId || "",
    };

    yield put(addressDetailsLoaded(address));
  } catch (e) {
    const error = getErrorMessage(e, "addressLookup.fetchError");
    yield put(searchAddressError(error));
  }
}

export default addressLookupSagas;
