import { put, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import InviteRepository from "../../../../rest/invites/InviteRepository";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import OrganisationUserRepository from "../../../../rest/organisation-user/OrganisationUserRepository";
import OrganisationUser from "../../../../rest/organisation/model/OrganisationUser";
import { ACTIVE_ORGANISATION_CHANGED_TYPE } from "../../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import {
  ResendInviteSuccessAction,
  RESEND_INVITE_SUCCESS_TYPE,
  RevokeInviteSuccessAction,
  REVOKE_INVITE_SUCCESS_TYPE,
} from "../../../../ui/components/PendingInviteMenu/redux/PendingInviteMenuAction";
import { CURRENT_USER_LOADED_TYPE } from "../../../account/AccountSettingsPage/redux/CurrentUserAction";
import { ENTITY_INVITED_TYPE } from "../../../invites/InviteDialog/redux/CurrentInviteAction";
import { REMOVE_USER_SUCCESS_TYPE } from "../../detail/redux/UserDetailActions";
import {
  loadUsersError,
  loadUsersSuccess,
  LOAD_USERS_TYPE,
  USER_SEARCH_TEXT_CHANGED_TYPE,
} from "./UserListAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import LocalizedError from "../../../../errors/LocalizedError";

function* userListSagas() {
  // Refresh the list when...
  yield takeLatest(
    [
      LOAD_USERS_TYPE, // Asked to load users
      ACTIVE_ORGANISATION_CHANGED_TYPE, // The user switches their org
      USER_SEARCH_TEXT_CHANGED_TYPE, // The user types in the search box
      CURRENT_USER_LOADED_TYPE, // When the user updates their details
      REMOVE_USER_SUCCESS_TYPE, // When a user is removed from the org
      ENTITY_INVITED_TYPE, // a new invite
    ],
    loadUsersSaga
  );

  yield takeEvery(REVOKE_INVITE_SUCCESS_TYPE, onInviteRevoked);
  yield takeEvery(RESEND_INVITE_SUCCESS_TYPE, inviteResentSaga);
}

function* loadUsersSaga() {
  try {
    const rootState: RootState = yield select();
    const permission =
      rootState.activeOrganisation.permissions.canViewActiveOrganisationUsers;
    if (!permission) {
      throw new LocalizedError("error.unauthorizedPage");
    }
    const userRepo: OrganisationUserRepository = yield getDependency(
      "organisationUserRepository"
    );
    const inviteRepo: InviteRepository = yield getDependency("inviteRepository");

    const currentOrgId = rootState.activeOrganisation.currentOrganisation?.id;
    if (!currentOrgId) {
      throw new Error();
    }
    const searchText = rootState.userList.searchText;

    const users: OrganisationUser[] = yield userRepo.fetchOrganisationUserList(
      currentOrgId,
      searchText
    );
    const invites: PendingInvite[] = yield inviteRepo.fetchPendingInvitesForOrganisation(
      currentOrgId,
      searchText
    );
    yield put(loadUsersSuccess(users, invites));
  } catch (e) {
    const message = getErrorMessage(e, "userTable.loadError");
    yield put(loadUsersError(message));
  }
}

function* onInviteRevoked(action: RevokeInviteSuccessAction) {
  try {
    const state: RootState = yield select();
    const users = state.userList.users || [];

    const oldInvites = state.userList.invites || [];
    const invites = oldInvites.filter((invite) => invite.id !== action.invite.id);

    yield put(loadUsersSuccess(users, invites));
  } catch (e) {
    // Unable to refresh list
    console.error(e);
  }
}

function* inviteResentSaga(action: ResendInviteSuccessAction) {
  try {
    const state: RootState = yield select();
    const users = state.userList.users;

    // Replace the old invite with the new one
    const oldInvites = state.userList.invites || [];
    const invites = [...oldInvites];
    const index = invites.findIndex((invite) => invite.id === action.oldInvite.id);
    if (index >= 0 && index < invites.length) {
      invites[index] = action.newInvite;
    }

    yield put(loadUsersSuccess(users || [], invites));
  } catch (e) {
    // Unable to refresh list
    console.error(e);
  }
}

export default userListSagas;
