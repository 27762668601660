import ResendActivationEmailAction from "./ResendActivationEmailAction";
import ResendActivationEmailState from "./ResendActivationEmailState";

const initialState: ResendActivationEmailState = {
  emailAddress: undefined,
  isLoading: false,
  errorMessage: undefined,
  resendSuccessful: false,
};

const resendActivationEmailReducer = (
  state: ResendActivationEmailState = initialState,
  action: ResendActivationEmailAction
) => {
  switch (action.type) {
    case "ACTIVATION_EMAIL_REQUESTED_TYPE":
      return {
        emailAddress: state.emailAddress,
        isLoading: true,
        errorMessage: undefined,
        resendSuccessful: false,
      };

    case "ACCOUNT_NOT_ACTIVATED_TYPE":
      return {
        emailAddress: action.email,
        isLoading: false,
        errorMessage: undefined,
        resendSuccessful: false,
      };

    case "ACTIVATION_EMAIL_RESENT_TYPE":
      return {
        emailAddress: state.emailAddress,
        isLoading: false,
        errorMessage: undefined,
        resendSuccessful: true,
      };

    case "ACTIVATION_EMAIL_RESEND_ERROR_TYPE":
      return {
        emailAddress: state.emailAddress,
        isLoading: false,
        errorMessage: action.error,
        resendSuccessful: false,
      };

    case "ACTIVATION_EMAIL_RESEND_COMPLETE_TYPE":
      return {
        emailAddress: undefined,
        isLoading: false,
        errorMessage: undefined,
        resendSuccessful: false,
      };

    default:
      return state;
  }
};

export default resendActivationEmailReducer;
