import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import englishTranslations from "./en/translate";
import englishAustraliaTranslations from "./en-AU/translate";

import frenchTranslations from "./fr/translate";
import austrianTranslations from "./de-AT/translate";

// Based on this:
// https://medium.com/@tariqul.islam.rony/internationalization-localization-with-react-js-65d6f6badd56

declare namespace Intl {
  type ListType = "conjunction" | "disjunction";

  interface ListFormatOptions {
    localeMatcher?: "lookup" | "best fit";
    type?: ListType;
    style?: "long" | "short" | "narrow";
  }

  interface ListFormatPart {
    type: "element" | "literal";
    value: string;
  }

  class ListFormat {
    constructor(locales?: string | string[], options?: ListFormatOptions);
    format(values: any[]): string;
    formatToParts(values: any[]): ListFormatPart[];
    supportedLocalesOf(locales: string | string[], options?: ListFormatOptions): string[];
  }
}

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
      format: (value: any, format?: string, lng?: string) => {
        if (format === "emphasizeList" && Array.isArray(value)) {
          value = value.map((v: any) => `<strong>${v}</strong>`);
          return new Intl.ListFormat(lng, {
            style: "long",
            type: "conjunction",
          }).format(value);
        }
        return value;
      },
    },
    resources: {
      en: englishTranslations,
      de: austrianTranslations,
      fr: frenchTranslations,
      "en-AU": englishAustraliaTranslations,
    },

    supportedLngs: ["en", "fr", "de", "en-AU"],
    /* When react i18next not finding any language to as default in browser */

    fallbackLng: "en",

    /* Debug when not in prod */
    debug: process.env.NODE_ENV !== "production",

    /* Set the namespace of the translations file */
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18next;
