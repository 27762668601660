import {
  Box,
  Collapse,
  Divider,
  Fade,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import { SMALL_SPACING } from "../../theme/dimensions";
import ExportItem from "./ExportItem";
import ExportItemModel from "./models/ExportItemModel";
import {
  cancelExportingItem,
  removeExportingFailureItem,
  removeExportingSuccessItem,
} from "./redux/ExportBarAction";

interface ReduxStateProps {
  exportedItems: ExportItemModel[];
  error: string | undefined;
}

interface ReduxDispatchProps {
  removeExportingSuccessItem: (item: ExportItemModel) => void;
  removeExportingErrorItem: (item: ExportItemModel) => void;
  onCancelClicked: (item: ExportItemModel) => void;
}

type Props = WithTranslation & ReduxStateProps & ReduxDispatchProps;

const ExportBar = (props: Props) => {
  //Translated strings
  const exportingTitle = props.t("exportBar.title");

  //Component State
  const [collapsibleItems, setCollapsibleItems] = useState<boolean>(true);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={props.exportedItems.length > 0}
    >
      <Paper>
        <Box minWidth="300px" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            paddingX={SMALL_SPACING}
            paddingY={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="button" color="secondary">
              <strong>{exportingTitle}</strong>
            </Typography>
            <IconButton
              color="secondary"
              onClick={() => setCollapsibleItems(!collapsibleItems)}
            >
              {collapsibleItems ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Divider />
          <Collapse in={collapsibleItems} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column">
              {props.exportedItems.map((item) => (
                <Fade in={item !== undefined}>
                  <ExportItem
                    exportItem={item}
                    onCancelClicked={
                      !item.cancelled &&
                      (item.processed !== 1 || item.failed !== 1) &&
                      item.organisation_id !== item.id &&
                      item.url === null
                        ? () => props.onCancelClicked(item)
                        : undefined
                    }
                  />
                </Fade>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Paper>
    </Snackbar>
  );
};

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  exportedItems: state.exportBar.exportItems,
  error: state.exportBar.error,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  removeExportingSuccessItem: (item) => dispatch(removeExportingSuccessItem(item)),
  removeExportingErrorItem: (item) => dispatch(removeExportingFailureItem(item)),
  onCancelClicked: (item) => dispatch(cancelExportingItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportBar));
