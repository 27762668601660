import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../../../ui/components/PrimaryButton";

interface Props extends WithT {
  loading: boolean;
  onClick: () => void;
}

const DeletePropertyButton = (props: Props) => {
  const label = props.t("deletePropertyHandler.buttonTitle");

  return (
    <PrimaryButton
      size="small"
      label={label}
      startIcon={<FontAwesomeIcon icon={faTrash} />}
      isLoading={props.loading}
      onClick={props.onClick}
    />
  );
};

export default withTranslation()(DeletePropertyButton);
