import AdminRepository from "../../../../rest/admin/AdminRepository";
import { OrgUserDetails } from "../redux/AdminOrganisationDetailsState";

/**
 * Fetch the OrgUserDetails for an org.
 * @param orgId The id of the org
 * @param adminRepo The repo to use
 * @returns
 */
const fetchOrgUserDetails = async (
  orgId: string,
  adminRepo: AdminRepository
): Promise<OrgUserDetails> => {
  // Use Promise.all to call all 2 at once.
  const result = await Promise.all([
    adminRepo.fetchOrganisationUsers(orgId),
    adminRepo.fetchOrganisationUserInvites(orgId),
  ]);

  return {
    users: result[0],
    invites: result[1],
  };
};

export default fetchOrgUserDetails;
