import PendingInviteMenuAction from "./PendingInviteMenuAction";
import PendingInviteMenuState from "./PendingInviteMenuState";

const initialState: PendingInviteMenuState = {
  resendInvite: {
    loading: false,
    success: false,
    error: undefined,
  },
  revokeInvite: {
    loading: false,
    success: false,
    error: undefined,
  },
};

const pendingInviteMenuReducer = (
  state = initialState,
  action: PendingInviteMenuAction
): PendingInviteMenuState => {
  switch (action.type) {
    case "RESET_MENU_TYPE":
      return {
        ...initialState,
      };

    case "RESEND_INVITE_TYPE":
      return {
        revokeInvite: state.revokeInvite,
        resendInvite: {
          loading: true,
          success: false,
          error: undefined,
        },
      };

    case "RESEND_INVITE_SUCCESS_TYPE":
      return {
        revokeInvite: state.revokeInvite,
        resendInvite: {
          loading: false,
          success: true,
          error: undefined,
        },
      };

    case "RESEND_INVITE_ERROR_TYPE":
      return {
        revokeInvite: state.revokeInvite,
        resendInvite: {
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case "REVOKE_INVITE_TYPE":
      return {
        resendInvite: state.resendInvite,
        revokeInvite: {
          loading: true,
          success: false,
          error: undefined,
        },
      };

    case "REVOKE_INVITE_SUCCESS_TYPE":
      return {
        resendInvite: state.resendInvite,
        revokeInvite: {
          loading: false,
          success: true,
          error: undefined,
        },
      };

    case "REVOKE_INVITE_ERROR_TYPE":
      return {
        resendInvite: state.resendInvite,
        revokeInvite: {
          loading: false,
          success: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default pendingInviteMenuReducer;
