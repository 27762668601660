import axios, { AxiosInstance } from "axios";
import i18next from "i18next";
import AuthService from "../pages/auth/Login/service/AuthService";
import axiosErrorInterceptor from "./utils/axiosErrorInterceptor";

const buildAxiosInstance = (
  baseURL: string,
  authService: AuthService,
  withCredentials: boolean = false,
  withBearer: boolean = true,
  withLanguage: boolean = true
): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: baseURL,
  });

  axiosInstance.defaults.withCredentials = withCredentials;

  if (withBearer) {
    axiosInstance.interceptors.request.use(async (request) => {
      const token = await authService.getCurrentToken();

      request.headers["Authorization"] = "Bearer " + token;
      return request;
    });
  }

  if (withLanguage) {
    axiosInstance.interceptors.request.use(async (request) => {
      const languageCode = i18next.t("language.code");

      if (languageCode.length && !request.headers["Accept-Language"]) {
        request.headers["Accept-Language"] = languageCode;
      }
      return request;
    });
  }

  axiosInstance.interceptors.response.use(undefined, axiosErrorInterceptor);

  return axiosInstance;
};

export default buildAxiosInstance;
