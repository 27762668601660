import { Box } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import Organisation from "../../../rest/organisation/model/Organisation";
import Property from "../../../rest/properties/model/Property";
import UserDetails from "../../../rest/user/model/UserDetails";
import {
  buildOrganisationPropertyDetailRoute,
  buildPlatformOrgDetailsRoute,
  buildPlatformUserDetailRoute,
} from "../../../routes/routes";
import DashboardLayout from "../../../ui/components/DashboardLayout/DashboardLayout";
import ErrorDisplay from "../../../ui/components/ErrorDisplay";
import TabModel from "../../../ui/components/PageHeaderTabs/models/TabModel";
import PageHeaderTabs from "../../../ui/components/PageHeaderTabs/PageHeaderTabs";
import Spinner from "../../../ui/components/Spinner";
import OrganisationDetailNavRail from "../../organisations/details/components/OrganisationDetailNavRail";
import { adminLoadOrganisation } from "../../organisations/details/redux/OrganisationDetailActions";
import OrganisationDetailState from "../../organisations/details/redux/OrganisationDetailState";
import ProCheckReportsTab from "../../reports/dashboard/ReportsDashboardPage/components/ProCheckReportsTab";
import { removeUserFromOrg } from "../PlatformUserDetailPage/redux/PlatformUserDetailAction";
import AdminOrganisationDetailsTab from "./components/tabs/AdminOrganisationDetailsTab";
import AdminOrgContractorsTab from "./components/tabs/AdminOrgContractorsTab";
import AdminOrgPropertiesTab from "./components/tabs/AdminOrgPropertiesTab";
import AdminOrgUsersTab from "./components/tabs/AdminOrgUsersTab";

//Types Definition
type AdminOrgDetailTabType =
  | "org-details"
  | "properties"
  | "org-user"
  | "org-procheck"
  | "related-organisations";

interface URLParams {
  organisationId: string;
}
//Component State
interface State {
  tab: AdminOrgDetailTabType;
}

//Component Props
interface ReduxStateProps {
  organisationDetailsState: OrganisationDetailState;
  orgOwner: UserDetails | undefined;
}

interface ReduxDispatchProps {
  loadOrganisation: (organisation: string) => void;
  removeUser: (user: UserDetails, org: Organisation) => void;
}

type Props = RouteComponentProps<URLParams> &
  ReduxStateProps &
  ReduxDispatchProps &
  WithTranslation &
  RouteComponentProps;

class AdminOrganisationDetailsPage extends React.Component<Props, State> {
  state = {
    tab: "org-details" as AdminOrgDetailTabType,
  };

  componentDidMount() {
    // On first loading this component, load the org
    const organisationId = this.props.match.params.organisationId;
    this.props.loadOrganisation(organisationId);
  }

  componentDidUpdate(prevProps: Props) {
    // When the user selects a contractor org, this page will reload and
    // componentDidMount won't be called.
    // so if the org id changes (and it's not already loading) then
    // load the org and go back to the first tab
    const organisationId = this.props.match.params.organisationId;
    const prevOrg = prevProps.organisationDetailsState?.organisation;
    if (prevOrg !== undefined && organisationId !== prevOrg.id) {
      this.props.loadOrganisation(organisationId);

      if (this.state.tab !== "org-details") {
        this.setState({ tab: "org-details" });
      }
    }
  }

  render() {
    return <DashboardLayout>{this.renderBody()}</DashboardLayout>;
  }

  //MARK: Renderers

  renderBody = () => {
    if (this.props.organisationDetailsState.organisation) {
      return (
        <Box>
          <OrganisationDetailNavRail
            organisation={this.props.organisationDetailsState.organisation}
            navigateToRoute={this.handleRailLinkClicked}
            relationship={"contractor"}
            isAdmin={true}
          />

          <Box paddingY={1}>
            <PageHeaderTabs
              tabs={this.buildTabs()}
              activeKey={this.state.tab}
              tabClicked={this.handleTabClicked}
            />
          </Box>
          {this.renderTabs()}
        </Box>
      );
    } else if (this.props.organisationDetailsState.error) {
      return <ErrorDisplay title={this.props.organisationDetailsState.error} />;
    } else {
      return <Spinner />;
    }
  };

  renderTabs = () => {
    switch (this.state.tab) {
      case "org-details":
        return <AdminOrganisationDetailsTab />;
      case "properties":
        const orgId = this.props.match.params.organisationId;
        return (
          <AdminOrgPropertiesTab
            organisationId={orgId}
            onPropertyClicked={this.handlePropertyClicked}
          />
        );
      case "org-user":
        return (
          <AdminOrgUsersTab
            organisation={this.props.organisationDetailsState.organisation!}
            onUserClicked={this.handleUserClicked}
            removeUser={this.props.removeUser}
          />
        );
      case "related-organisations":
        return (
          <AdminOrgContractorsTab
            organisationName={
              this.props.organisationDetailsState.organisation?.name || ""
            }
            organisationId={this.props.organisationDetailsState.organisation?.id || ""}
            onOrgClicked={this.handleOrgClicked}
          />
        );
      case "org-procheck":
        return <ProCheckReportsTab isAdmin />;
    }
  };

  buildTabs = (): TabModel[] => {
    return [
      {
        title: "adminOrgDetailTabs.orgDetailTab",
        navKey: "org-details" as AdminOrgDetailTabType,
      },
      {
        title: "adminOrgDetailTabs.propertiesTab",
        navKey: "properties" as AdminOrgDetailTabType,
      },
      {
        title: "adminOrgDetailTabs.orgUserTab",
        navKey: "org-user" as AdminOrgDetailTabType,
      },
      {
        title: "adminOrgDetailTabs.relatedOrgsTab",
        navKey: "related-organisations" as AdminOrgDetailTabType,
      },
      {
        title: "adminOrgDetailTabs.proCheckReports",
        navKey: "org-procheck" as AdminOrgDetailTabType,
      },
    ];
  };

  //MARK: Handlers
  handleTabClicked = (key: string) => {
    this.setState({ ...this.state, tab: key as AdminOrgDetailTabType });
  };

  handleRailLinkClicked = (route: string) => {
    this.props.history.replace(route);
  };

  handlePropertyClicked = (property: Property) => {
    const orgId = this.props.match.params.organisationId;
    const route = buildOrganisationPropertyDetailRoute(orgId, property.id);
    this.props.history.push(route);
  };

  handleOrgClicked = (org: Organisation) => {
    const route = buildPlatformOrgDetailsRoute(org.id);
    this.props.history.push(route);
  };

  handleUserClicked = (user: UserDetails) => {
    const route = buildPlatformUserDetailRoute(user.id);
    this.props.history.push(route);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    organisationDetailsState: state.orgDetail,
    orgOwner: state.orgDetail.organisation?.owner,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  loadOrganisation: (organisationId) => dispatch(adminLoadOrganisation(organisationId)),
  removeUser: (user, org) => dispatch(removeUserFromOrg(user, org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AdminOrganisationDetailsPage)));
