import SenseDevice from "../../../rest/sense/model/SenseDevice";
import SelectedSenseDevice from "../models/SelectedSenseDevice";

//Load all devices
export const LOAD_SENSE_DEVICES_TYPE = "LOAD_SENSE_DEVICES_TYPE";

export interface LoadSenseDevicesAction {
  type: typeof LOAD_SENSE_DEVICES_TYPE;
  organisation_id: string;
  property_id: string;
}

export const loadSenseDevices = (
  organisation_id: string,
  property_id: string
): LoadSenseDevicesAction => ({
  type: LOAD_SENSE_DEVICES_TYPE,
  organisation_id: organisation_id,
  property_id: property_id,
});

//Devices loaded
export const SENSE_DEVICES_LOADED_TYPE = "SENSE_DEVICES_LOADED_TYPE";

export interface SenseDevicesLoadedAction {
  type: typeof SENSE_DEVICES_LOADED_TYPE;
  devices: SenseDevice[];
}

export const senseDevicesLoaded = (devices: SenseDevice[]): SenseDevicesLoadedAction => ({
  type: SENSE_DEVICES_LOADED_TYPE,
  devices: devices,
});

//Devices load error
export const LOAD_SENSE_DEVICES_ERROR_TYPE = "LOAD_SENSE_DEVICES_ERROR_TYPE";

export interface LoadSenseDevicesErrorAction {
  type: typeof LOAD_SENSE_DEVICES_ERROR_TYPE;
  error: string;
}

export const loadSenseDevicesError = (error: string): LoadSenseDevicesErrorAction => ({
  type: LOAD_SENSE_DEVICES_ERROR_TYPE,
  error: error,
});

//Load selected devices
export const LOAD_SELECTED_SENSE_DEVICE_TYPE = "LOAD_SELECTED_SENSE_DEVICE_TYPE";

export interface LoadSelectedSenseDeviceAction {
  type: typeof LOAD_SELECTED_SENSE_DEVICE_TYPE;
  organisation_id: string;
  property_id: string;
  serial: string;
}

export const loadSelectedSenseDevice = (
  organisation_id: string,
  property_id: string,
  serial: string
) => ({
  type: LOAD_SELECTED_SENSE_DEVICE_TYPE,
  organisation_id: organisation_id,
  property_id: property_id,
  serial: serial,
});

//Selected device loaded
export const SELECTED_SENSE_DEVICE_LOADED_TYPE = "SELECTED_SENSE_DEVICE_LOADED_TYPE";

export interface SelectedSenseDeviceLoadedAction {
  type: typeof SELECTED_SENSE_DEVICE_LOADED_TYPE;
  selectedDevice: SelectedSenseDevice;
}

export const selectedSenseDeviceLoaded = (
  selectedDevice: SelectedSenseDevice
): SelectedSenseDeviceLoadedAction => ({
  type: SELECTED_SENSE_DEVICE_LOADED_TYPE,
  selectedDevice: selectedDevice,
});

//Selected device load error
export const LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE =
  "LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE";
export interface LoadSelectedSenseDeviceErrorAction {
  type: typeof LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE;
  error: string;
}

export const loadSelectedSenseDeviceError = (error: string) => ({
  type: LOAD_SELECTED_SENSE_DEVICE_ERROR_TYPE,
  error: error,
});

//Change device activation status
export const CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE =
  "CHANGE_SENSE_DEVICE_ACTIVATION_STATUS";

export interface ChangeSenseDeviceActivationStatusAction {
  type: typeof CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE;
  organisation_id: string;
  property_id: string;
  serial: string;
  activation_status: boolean;
}

export const changeSenseDeviceActivationStatus = (
  organisation_id: string,
  property_id: string,
  serial: string,
  activation_status: boolean
): ChangeSenseDeviceActivationStatusAction => ({
  type: CHANGE_SENSE_DEVICE_ACTIVATION_STATUS_TYPE,
  organisation_id: organisation_id,
  property_id: property_id,
  serial: serial,
  activation_status: activation_status,
});

//Change device activation status
export const SENSE_DEVICE_ACTIVATION_STATUS_CHANGED_TYPE =
  "SENSE_DEVICE_ACTIVATION_STATUS_CHANGED";

export interface SenseDeviceActivationStatusChangedAction {
  type: typeof SENSE_DEVICE_ACTIVATION_STATUS_CHANGED_TYPE;
  updatedDevice: SenseDevice;
}

export const senseDeviceActivationStatusChanged = (
  updatedDevice: SenseDevice
): SenseDeviceActivationStatusChangedAction => ({
  type: SENSE_DEVICE_ACTIVATION_STATUS_CHANGED_TYPE,
  updatedDevice: updatedDevice,
});

//Selected device load error
export const SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR_TYPE =
  "SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR";
export interface SenseDeviceActivationStatusChangeErrorAction {
  type: typeof SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR_TYPE;
  error: string;
}

export const senseDeviceActivationStatusChangeError = (
  error: string
): SenseDeviceActivationStatusChangeErrorAction => ({
  type: SENSE_DEVICE_ACTIVATION_STATUS_CHANGE_ERROR_TYPE,
  error: error,
});

type SenseDeviceAction =
  | LoadSenseDevicesAction
  | SenseDevicesLoadedAction
  | LoadSenseDevicesErrorAction
  | LoadSelectedSenseDeviceAction
  | SelectedSenseDeviceLoadedAction
  | LoadSelectedSenseDeviceErrorAction
  | ChangeSenseDeviceActivationStatusAction
  | SenseDeviceActivationStatusChangedAction
  | SenseDeviceActivationStatusChangeErrorAction;

export default SenseDeviceAction;
