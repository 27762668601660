import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";
import SecondaryButton from "../../../../ui/components/SecondaryButton";

interface ExternalProps {
  onResetClicked: () => void;
  onHomepageClicked: () => void;
}

type Props = ExternalProps & WithTranslation;

const ResetPasswordTokenExpiredBody = (props: Props) => {
  const title = props.t("forgotPasswordTokenExpired.title");
  const message = props.t("forgotPasswordTokenExpired.message");
  const homepageButton = props.t("forgotPasswordTokenExpired.homepageButton");
  const resetButton = props.t("forgotPasswordTokenExpired.passwordResetButton");

  return (
    <CenteredAuthCard title={title}>
      <Grid container spacing={DEFAULT_SPACING} direction="column">
        <Grid item />

        <Grid item>
          <Typography align="center">{message}</Typography>
        </Grid>

        <Grid item>
          <PrimaryButton
            label={resetButton}
            endIcon={<ArrowIcon />}
            onClick={props.onResetClicked}
            fullWidth
          />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SecondaryButton label={homepageButton} onClick={props.onHomepageClicked} />
        </Grid>
      </Grid>
    </CenteredAuthCard>
  );
};

export default withTranslation()(ResetPasswordTokenExpiredBody);
