import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import inviteMatchesSearchText from "../../../../../rest/invites/utils/inviteMatchesSearchText";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import { RoleName } from "../../../../../rest/roles/model/Role";
import UserDetails from "../../../../../rest/user/model/UserDetails";
import userMatchesSearchText from "../../../../../rest/user/utils/userMatchesSearchText";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OutlineButton from "../../../../../ui/components/OutlineButton";
import SearchBar from "../../../../../ui/components/SearchBar";
import Spinner from "../../../../../ui/components/Spinner";
import SuccessSnackbarHandler from "../../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../../ui/theme/dimensions";
import { inviteUser } from "../../redux/AdminOrganisationDetailsAction";
import AdminOrganisationDetailsState, {
  OrgUserDetails,
} from "../../redux/AdminOrganisationDetailsState";
import AdminInviteUserDialog from "../AdminInviteUserDialog";
import OrganisationUserTables from "../OrganisationUserTables";
import isOrgDevFeatureEnabled from "../../../../../redux/utils/isOrgDevFeatureEnabled";

//Props
interface ExternalProps {
  organisation: Organisation;
  onUserClicked: (user: UserDetails) => void;
  removeUser: (user: UserDetails, org: Organisation) => void;
}

type ReduxStateProps = AdminOrganisationDetailsState;

interface ReduxDispatchProps {
  inviteUser: (orgId: string, email: string, role: RoleName) => void;
}

type Props = ExternalProps & ReduxStateProps & WithT & ReduxDispatchProps;

//State
interface State {
  searchText: string;
  inviteDialogOpen: boolean;
  inviteSuccess: boolean;
  removeSuccess: boolean;
}

class AdminOrgUsersTab extends React.Component<Props, State> {
  state = {
    searchText: "",
    inviteDialogOpen: false,
    inviteSuccess: false,
    removeSuccess: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.invitedUser !== prevProps.invitedUser) {
      this.setState({ inviteSuccess: this.props.invitedUser !== undefined });
    }
    if (this.props.userRemovedFromOrg !== prevProps.userRemovedFromOrg) {
      this.setState({ removeSuccess: this.props.userRemovedFromOrg !== undefined });
    }
  }

  render() {
    if (this.props.userDetails) {
      const userDetails = this.filterBySearch(this.props.userDetails);
      return (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            paddingX={LARGE_SPACING}
            paddingTop={SMALL_SPACING}
            paddingBottom={DEFAULT_SPACING}
          >
            <SearchBar
              value={this.state.searchText}
              onChange={this.handleSearchTextChanged}
              placeholder={this.props.t("platformUserDetail.userSearch")}
            />

            <Box width={EXTRA_LARGE_SPACING} />

            <OutlineButton
              style={{ minWidth: 200 }}
              labelId="platformUserDetail.inviteUserButton"
              endIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={this.handleInviteButtonClicked}
              disabled={this.hasOwnerInvites()}
              filled
            />
          </Box>
          <OrganisationUserTables
            devZoneEnabled={isOrgDevFeatureEnabled(
              this.props.organisation.features || []
            )}
            org={this.props.organisation}
            users={userDetails}
            onUserClicked={this.props.onUserClicked}
            onRemoveUserClicked={this.props.removeUser}
          />

          <AdminInviteUserDialog
            organisationName={this.props.organisation.name}
            open={this.state.inviteDialogOpen}
            canInviteDeveloper={isOrgDevFeatureEnabled(
              this.props.organisation.features || []
            )}
            hasOwner={this.hasOwner() || this.hasOwnerInvites()}
            isLoading={this.props.isInvitingUser}
            inviteSent={this.props.invitedUser !== undefined}
            onClose={this.handleDialogClosed}
            sendInvite={this.handleUserInvited}
          />

          <ErrorSnackbarHandler message={this.props.error} />

          <SuccessSnackbarHandler message={this.handleSuccessMessage()} />
        </Box>
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  }

  // Handlers
  handleSuccessMessage = (): string | undefined => {
    if (this.state.inviteSuccess) {
      return this.props.t("adminInviteUserDialog.inviteSentMessage");
    } else if (this.state.removeSuccess) {
      return this.props.t("platformUserDetail.removedSuccess");
    } else {
      return undefined;
    }
  };

  handleSearchTextChanged = (value: string) => {
    this.setState({ searchText: value });
  };

  handleInviteButtonClicked = () => {
    this.setState({ inviteDialogOpen: true });
  };

  handleDialogClosed = () => {
    this.setState({ inviteDialogOpen: false });
  };

  handleUserInvited = (email: string, role: RoleName) => {
    this.props.inviteUser(this.props.organisation.id, email, role);
  };

  // Filters

  filterBySearch = (details: OrgUserDetails): OrgUserDetails => {
    return {
      users: details.users.filter((o) =>
        userMatchesSearchText(o.user, this.state.searchText)
      ),
      invites: details.invites.filter((i) =>
        inviteMatchesSearchText(i, this.state.searchText)
      ),
    };
  };

  //Helpers
  hasOwner = (): boolean => {
    let owner: boolean = false;

    //Check if owner exists as a part of org object
    if (
      this.props.organisation.owner !== undefined &&
      this.props.organisation.owner !== null
    ) {
      owner = true;
    }

    //Check if owner exists in the user list
    const ownerFromList = this.props.userDetails?.users.find(
      (orgUser) => orgUser.role.name === "owner"
    );

    if (ownerFromList !== undefined) {
      owner = true;
    }

    return owner;
  };

  //Check if invite of the owner exists in the user list
  hasOwnerInvites = (): boolean => {
    const ownerInvite = this.props.userDetails?.invites.find(
      (invite) => invite.role?.name === "owner"
    );

    return ownerInvite !== undefined;
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps =>
  state.adminOrganisationDetails;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inviteUser: (orgId, email, role) => dispatch(inviteUser(orgId, email, role)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminOrgUsersTab));
