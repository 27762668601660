import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import Organisation from "../../../../../rest/organisation/model/Organisation";
import { buildPlatformOrgDetailsRoute } from "../../../../../routes/routes";
import ErrorDisplay from "../../../../../ui/components/ErrorDisplay";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import OrganisationTable from "../../../../../ui/components/OrganisationTable/OrganisationTable";
import SearchBar from "../../../../../ui/components/SearchBar";
import Spinner from "../../../../../ui/components/Spinner";
import SuccessSnackbarHandler from "../../../../../ui/components/SuccessSnackbar/SuccessSnackbarHandler";
import TableHeader from "../../../../../ui/components/TableList/components/TableHeader";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
} from "../../../../../ui/theme/dimensions";
import {
  removeManagedOrganisation,
  searchManagedOrganisations,
} from "../../redux/PlatformAgentDetailsActions";

interface ReduxStateProps {
  agentOrg: Organisation | undefined;
  managedOrganisationList: Organisation[] | undefined;
  error: string | undefined;
  isLoading: boolean;
  loaded: boolean | undefined;
  isRemoving: boolean;
  removed: boolean | undefined;
  searchText: string;
}

interface ReduxDispatchProps {
  searchTextChanged: (text: string) => void;
  removeManagedOrg: (org: Organisation) => void;
}

type Props = WithTranslation & ReduxDispatchProps & ReduxStateProps & RouteComponentProps;

interface State {
  removed: boolean | undefined;
}

class AgentManagedOrganisationsTab extends React.Component<Props, State> {
  state = {
    removed: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.removed !== prevProps.removed) {
      this.setState({
        removed: this.props.removed !== undefined,
      });
    }
  }

  render() {
    //Labels
    const searchBarPlaceholder = this.props.t("");

    return (
      <Box padding={DEFAULT_SPACING} display="flex" flexDirection="column">
        <SearchBar
          placeholder={searchBarPlaceholder}
          value={this.props.searchText}
          onChange={this.props.searchTextChanged}
        />
        <Box height={2 * EXTRA_LARGE_SPACING} />
        {this.renderTable()}
        <ErrorSnackbarHandler message={this.props.error} />

        <SuccessSnackbarHandler message={this.handleSuccessMessage()} />
      </Box>
    );
  }

  //Handlers
  onOrganisationClicked = (org: Organisation) => {
    const route = buildPlatformOrgDetailsRoute(org.id);
    this.props.history.push(route);
  };

  onRemoveClicked = (org: Organisation) => {
    this.props.removeManagedOrg(org);
  };

  handleSuccessMessage = (): string | undefined => {
    if (this.state.removed) {
      return this.props.t("platformAgentDetails.removeManagedOrgSuccess");
    } else return undefined;
  };

  //Renderers
  renderTable = () => {
    if (this.props.isLoading) {
      return <Spinner />;
    } else if (
      this.props.managedOrganisationList !== undefined &&
      this.props.managedOrganisationList.length > 0
    ) {
      return (
        <Box>
          <TableHeader text="platformOrganisationsList.tableTitle" />
          <OrganisationTable
            organisations={this.props.managedOrganisationList}
            onOrgClicked={this.onOrganisationClicked}
            onRemoveClicked={this.onRemoveClicked}
            agentOrg={this.props.agentOrg}
          />
        </Box>
      );
    } else if (
      this.props.managedOrganisationList !== undefined &&
      this.props.managedOrganisationList.length === 0
    ) {
      return (
        <Box padding={LARGE_SPACING}>
          <Typography variant="h2" color="textPrimary" align="center">
            <Trans>platformOrganisationsList.emptyMessage</Trans>
          </Typography>
        </Box>
      );
    } else if (this.props.error) {
      return <ErrorDisplay title={this.props.error} />;
    } else {
      return <Spinner />;
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  agentOrg: state.platformAgentDetails.agentDetails,
  managedOrganisationList: state.platformAgentDetails.managedOrganisations,
  error: state.platformAgentDetails.error,
  isLoading: state.platformAgentDetails.isLoadingManagedOrganisations,
  loaded: state.platformAgentDetails.managedOrganisationLoaded,
  isRemoving: state.platformAgentDetails.isRemovingOrganisation,
  removed: state.platformAgentDetails.organisationRemoved,
  searchText: state.platformAgentDetails.orgSearchText,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  searchTextChanged: (text) => dispatch(searchManagedOrganisations(text)),
  removeManagedOrg: (org) => dispatch(removeManagedOrganisation(org)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AgentManagedOrganisationsTab)));
