import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SecondaryButton from "../../../../../../ui/components/SecondaryButton";

interface Props extends WithTranslation {
  loading?: boolean;

  onCancel: () => void;
  onSave: () => void;
}

const EditorButtons = (props: Props) => {
  const save = props.t("buttons.save");
  const cancel = props.t("buttons.cancel");

  const endIcon = props.loading ? (
    <CircularProgress color="secondary" size={20} />
  ) : undefined;

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1} />

      <SecondaryButton label={cancel} onClick={props.onCancel} disabled={props.loading} />

      <Box width="4px" />

      <SecondaryButton
        label={save}
        onClick={props.onSave}
        endIcon={endIcon}
        disabled={props.loading}
        filled
      />
    </Box>
  );
};

export default withTranslation()(EditorButtons);
