import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithT } from "i18next";
import { withTranslation } from "react-i18next";
import { TestOutcome } from "../../../../../rest/procheck/model/ProCheckResultDetails";

interface Props extends WithT {
  result: TestOutcome;
}

const TestResultIcon = (props: Props) => {
  switch (props.result) {
    case "fail":
      return <FontAwesomeIcon icon={faExclamation} color="orange" />;
    case "out of range":
      return <FontAwesomeIcon icon={faExclamation} color="orange" />;
    case "pass":
      return <FontAwesomeIcon icon={faCheck} color="green" />;
  }
};

export default withTranslation()(TestResultIcon);
