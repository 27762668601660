import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryTextField, {
  FieldState,
  PrimaryTextFieldProps,
} from "../../../../../../ui/components/PrimaryTextField";
import { LARGE_SPACING } from "../../../../../../ui/theme/dimensions";
import EditableFieldLabel from "./EditableFieldLabel";
import EditorButtons from "./EditorButtons";

interface Props extends WithT {
  label: string;
  text: string | undefined;
  required: boolean;
  loading: boolean;
  textFieldProps?: Omit<PrimaryTextFieldProps, "value">;
  onCancel: () => void;
  onSave: (newText: string) => void;
}

interface State {
  fieldState: FieldState;
}

class FieldEditor extends React.Component<Props, State> {
  state = {
    fieldState: {
      value: this.props.text || "",
      errorText: undefined,
    },
  };

  render() {
    return (
      <Box display="flex" flexDirection="column">
        <EditableFieldLabel label={this.props.label} />

        <PrimaryTextField
          {...this.state.fieldState}
          {...this.props.textFieldProps}
          onChange={this.changeHandler}
          onFocus={this.focusHandler}
          disabled={this.props.loading}
        />

        <Box height={LARGE_SPACING + "px"} />

        <EditorButtons
          onCancel={this.props.onCancel}
          onSave={this.saveHandler}
          loading={this.props.loading}
        />
      </Box>
    );
  }

  changeHandler = (newValue: string) => {
    this.setState({
      fieldState: {
        value: newValue,
      },
    });
  };

  focusHandler = () => {
    // Clear the error on focus
    this.setState({
      fieldState: {
        value: this.state.fieldState.value,
      },
    });
  };

  saveHandler = () => {
    if (this.validate()) {
      const value = this.state.fieldState.value;
      this.props.onSave(value);
    }
  };

  validate = (): boolean => {
    // If not required, anything is valid
    if (!this.props.required) {
      return true;
    }

    const trimmed = this.state.fieldState.value.trim();
    if (trimmed.length === 0) {
      const placeholder = this.props.t("error.requiredPlaceholder");
      const error = placeholder.replace("%s", this.props.label);

      this.setState((prevState) => ({
        fieldState: {
          value: prevState.fieldState.value,
          errorText: error,
        },
      }));
      return false;
    } else {
      return true;
    }
  };
}

export default withTranslation()(FieldEditor);
