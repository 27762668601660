import PendingInvite, {
  ContractorInvitation,
  OrganisationUserInvitation,
} from "../../../rest/invites/model/PendingInvite";
import PendingInviteAction, { ACCEPT_INVITE_SUCCESS_TYPE } from "./PendingInviteAction";
import PendingInviteState from "./PendingInviteState";

const initialState: PendingInviteState = {
  hasAcceptedAnInvite: false,
  invites: undefined,
  isLoading: false,
  error: undefined,
  canAcceptContractorInvites: false,
};

const pendingInvitesReducer = (
  state = initialState,
  action: PendingInviteAction
): PendingInviteState => {
  switch (action.type) {
    case "FETCH_PENDING_INVITES_SUCCESS_TYPE":
      return {
        ...state,
        invites: {
          user: action.userInvites,
          contractor: action.contractorInvites,
        },
      };

    case "REJECT_INVITE_TYPE":
    case "ACCEPT_INVITE_TYPE":
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case "REJECT_INVITE_ERROR_TYPE":
    case "ACCEPT_INVITE_ERROR_TYPE":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case "REJECT_INVITE_SUCCESS_TYPE":
    case "ACCEPT_INVITE_SUCCESS_TYPE":
      return {
        isLoading: false,
        error: undefined,
        invites: {
          user: filterOutInvite(
            state.invites?.user,
            action.acceptedInvite.id
          ) as OrganisationUserInvitation[],

          contractor: filterOutInvite(
            state.invites?.contractor,
            action.acceptedInvite.id
          ) as ContractorInvitation[],
        },

        hasAcceptedAnInvite: action.type === ACCEPT_INVITE_SUCCESS_TYPE,
        canAcceptContractorInvites: state.canAcceptContractorInvites,
      };

    case "CLEAR_ACCEPTED_INVITE_FLAG_TYPE":
      return {
        ...state,
        hasAcceptedAnInvite: false,
      };

    case "ORG_OWNER_STATE_CHANGED_TYPE":
      return {
        ...state,
        canAcceptContractorInvites: action.isOrganisationOwner,
      };

    default:
      return state;
  }
};

// Filter out the invite with id from the list
const filterOutInvite = (invites: PendingInvite[] | undefined, id: string) => {
  return invites?.filter((i) => i.id !== id) || [];
};

export default pendingInvitesReducer;
