export const ADMIN_INVITE_SUBMITTED_TYPE = "ADMIN_INVITE_SUBMITTED_TYPE";

export interface AdminInviteSubmittedAction {
  type: typeof ADMIN_INVITE_SUBMITTED_TYPE;
  email: string;
}

export const adminInviteSubmitted = (email: string) => ({
  type: ADMIN_INVITE_SUBMITTED_TYPE,
  email: email,
});

export const ADMIN_INVITE_SUBMITTED_ERROR_TYPE = "ADMIN_INVITE_SUBMITTED_ERROR_TYPE";

export interface AdminInviteSubmittedErrorAction {
  type: typeof ADMIN_INVITE_SUBMITTED_ERROR_TYPE;
  error: string;
}

export const adminInviteSubmittedError = (error: string) => ({
  type: ADMIN_INVITE_SUBMITTED_ERROR_TYPE,
  error: error,
});

export const ADMIN_INVITE_SUBMITTED_SUCCESS_TYPE = "ADMIN_INVITE_SUBMITTED_SUCCESS_TYPE";

export interface AdminInviteSubmittedSuccessAction {
  type: typeof ADMIN_INVITE_SUBMITTED_SUCCESS_TYPE;
}

export const adminInviteSubmittedSuccess = () => ({
  type: ADMIN_INVITE_SUBMITTED_SUCCESS_TYPE,
});

type InviteOrganisationAdminAction =
  | AdminInviteSubmittedAction
  | AdminInviteSubmittedErrorAction
  | AdminInviteSubmittedSuccessAction;

export default InviteOrganisationAdminAction;
