import { put, takeLatest } from "redux-saga/effects";
import {
  ADMIN_INIT_DESTINATION_SEARCH_TYPE,
  AdminInitDestinationSearchAction,
  INIT_ADMIN_MOVE_ENTITIES_TYPE,
  InitAdminMoveEntitiesAction,
  adminDestinationSearchSuccess,
  adminMoveEntitiesError,
  adminMoveEntitiesSuccess,
  adminSearchDestinationError,
} from "./AdminMoveEntityAction";
import getDependency from "../../../../redux/utils/getDependency";
import AdminMoveEntityService, {
  IMoveActionResult,
} from "../services/AdminMoveEntityService";
import TMoveDestination from "../types/TMoveDestination";

//TODO: Implement Sagas
function* adminMoveEntitySagas() {
  yield takeLatest(INIT_ADMIN_MOVE_ENTITIES_TYPE, moveEntitySaga);
  yield takeLatest(ADMIN_INIT_DESTINATION_SEARCH_TYPE, searchDestinationSaga);
}

function* searchDestinationSaga(action: AdminInitDestinationSearchAction) {
  const moveEntityServiceRepo: AdminMoveEntityService = yield getDependency(
    "adminMoveEntityService"
  );

  try {
    const searchResults: TMoveDestination[] =
      yield moveEntityServiceRepo.fetchDestinationList(
        action.entityType,
        action.queryString,
        action.entityScopeId
      );

    yield put(adminDestinationSearchSuccess(searchResults));
  } catch (e) {
    yield put(adminSearchDestinationError(e as string));
  }
}

function* moveEntitySaga(action: InitAdminMoveEntitiesAction) {
  const moveEntityServiceRepo: AdminMoveEntityService = yield getDependency(
    "adminMoveEntityService"
  );

  try {
    const moved: IMoveActionResult = yield moveEntityServiceRepo.moveEntity(
      action.entityType,
      action.originOrganisationId,
      action.idsToMove,
      action.destinationId,
      action.originId
    );

    if (moved.rejectedEntities.length === action.idsToMove.length) {
      yield put(adminMoveEntitiesError("Failed to move properties", moved));
    } else {
      yield put(adminMoveEntitiesSuccess(moved));
    }
  } catch (e) {
    yield put(
      adminMoveEntitiesError(e as string, {
        movedEntities: [],
        rejectedEntities: action.idsToMove,
      })
    );
  }
}

export default adminMoveEntitySagas;
