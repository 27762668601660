import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Property from "../../../../../rest/properties/model/Property";
import LabelledText from "../../../../../ui/components/LabelledText";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import ProCheckDetailsPageState from "../../model/ProCheckDetailsPageState";

interface Props extends WithT {
  resultDetails: ProCheckDetailsPageState;
  property: Property | undefined;
}

const ProCheckDetailsTab = (props: Props) => {
  //Labels

  const dateLabel = props.t("proCheckDetailsTab.date");
  const timeLabel = props.t("proCheckDetailsTab.time");
  const emailLabel = props.t("proCheckDetailsTab.email");
  const engineerLabel = props.t("proCheckDetailsTab.engineer");
  const reasonLabel = props.t("proCheckDetailsTab.reason");
  const boilerLabel = props.t("proCheckDetailsTab.boilerNumber");
  const referenceLabel = props.t("proCheckDetailsTab.jobReference");
  const addressLabel = props.t("proCheckDetailsTab.address");
  const typeLabel = props.t("proCheckDetailsTab.type");
  const filterLabel = props.t("proCheckDetailsTab.filter");

  //Address formatter
  const formatAddress = (property: Property): string => {
    return [
      property.address_line_1,
      property.address_line_2,
      property.address_line_2,
      property.address_city,
      property.address_postcode,
      props.t("countryCode." + property.address_country.code),
    ]
      .filter((line) => typeof line === "string")
      .flatMap((line) => (line as string).trim())
      .filter((line) => line.length > 0)
      .join(", ");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="top"
      paddingX={SMALL_SPACING}
      justifyContent="flex-start"
      maxWidth="60%"
    >
      <Box flexGrow={1}>
        <Box display="flex" flexDirection="row">
          <LabelledText label={dateLabel} text={props.resultDetails.date} gutterBottom />
          <Box paddingLeft={LARGE_SPACING} />
          <LabelledText label={timeLabel} text={props.resultDetails.time} gutterBottom />
        </Box>

        <LabelledText
          label={emailLabel}
          text={props.resultDetails.user.email}
          gutterBottom
        />
        <LabelledText
          label={engineerLabel}
          text={`${props.resultDetails.user.first_name} ${props.resultDetails.user.last_name}`}
          gutterBottom
        />

        <LabelledText
          label={reasonLabel}
          text={props.resultDetails.system_info.reason}
          gutterBottom
        />
        <LabelledText
          label={addressLabel}
          text={props.property ? formatAddress(props.property) : ""}
          gutterBottom
        />
        <LabelledText
          label={typeLabel}
          text={props.resultDetails.system_info.type}
          gutterBottom
        />
        <LabelledText
          label={filterLabel}
          text={props.resultDetails.system_info.filter ? "Yes" : "No"}
          gutterBottom
        />
        <LabelledText
          label={boilerLabel}
          text={
            props.resultDetails.system_info.serialBoiler
              ? props.resultDetails.system_info.serialBoiler
              : "-"
          }
          gutterBottom
        />
        <LabelledText
          label={referenceLabel}
          text={
            props.resultDetails.job_reference_id
              ? props.resultDetails.job_reference_id
              : "-"
          }
          gutterBottom
        />
      </Box>
    </Box>
  );
};

export default withTranslation()(ProCheckDetailsTab);
