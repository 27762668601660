import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import RootState from "../../../redux/RootState";
import buildTabModels from "./buildTabModels";
import HeaderTabItem from "./components/HeaderTabItem";

interface ReduxStateProps {
  canViewUsers: boolean;
  canViewOrganisations: boolean;
  canViewReports: boolean;
  hasAdeyAdminAccess: boolean;
  hasAdeyAgentAccess: boolean;
}

type Props = ReduxStateProps;

const HeaderTabs = (props: Props) => {
  const { t } = useTranslation();

  const tabs = buildTabModels(props).map((m) => (
    <HeaderTabItem key={m.route} route={m.route}>
      {t(m.title)}
    </HeaderTabItem>
  ));

  return (
    <Box display="flex" justifyContent="space-around" alignItems="baseline" flexGrow={1}>
      {tabs}
    </Box>
  );
};

const mapStateToProps = (rootState: RootState): ReduxStateProps => {
  const permissions = rootState.activeOrganisation.permissions;
  return {
    canViewUsers: permissions.canViewActiveOrganisationUsers,
    canViewOrganisations: permissions.canViewOrganisationContractors,
    canViewReports:
      rootState.activeOrganisation.currentOrganisation?.role.via !== undefined ||
      permissions.canViewOrganisationContractors,
    hasAdeyAdminAccess: rootState.adeyAdmin.hasAdminAccess,
    hasAdeyAgentAccess: rootState.adeyAgent.hasAgentAccess,
  };
};

export default connect(mapStateToProps)(HeaderTabs);
