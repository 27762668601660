import i18next from "i18next";

/// An error with a key that translates to a key
/// in the en.json.
/// It's handled by getErrorMessage
class LocalizedError extends Error {
  isLocalizedError = true;

  constructor(public messageKey: string) {
    super(i18next.t(messageKey));
  }
}

export const isLocalizedError = (obj: any): obj is LocalizedError => {
  return obj?.isLocalizedError === true;
};

export default LocalizedError;
