import { Country } from "../../../../rest/countries/model/Country";
import Property from "../../../../rest/properties/model/Property";

const buildPropertyFromCode = (
  property: Property,
  countryList: Country[] | undefined
): Property => {
  const newProperty: Property = {
    ...property,
    address_country: countryList?.find(
      (country) => country.code === property.address_country.code
    ) || {
      id: "",
      code: "",
      name: "",
    },
  };

  return newProperty;
};

export default buildPropertyFromCode;
