import { Box, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import RootState from "../../../../redux/RootState";
import { PropertyNotification } from "../../../../rest/properties/model/Property";
import BorderedBox from "../../../../ui/components/BorderedBox";
import ErrorSnackbarHandler from "../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import Spinner from "../../../../ui/components/Spinner";
import PropertyDetailState from "../../../properties/PropertyDetailPage/redux/PropertyDetailState";
import ProCheckState from "../../redux/ProCheckState";
import getProCheckReportArray from "../../utils/getProCheckReportArray";
import ProCheckErrorTile from "./components/ProCheckErrorTile";
import ProCheckResultsTile from "./components/ProCheckResultsTile";

type ReduxStateProps = {
  proCheck: ProCheckState;
  property: PropertyDetailState;
};
interface Props extends ReduxStateProps {
  onMoreInfoClicked: () => void;
}

const ProCheckProductTile = (props: Props) => {
  //Helpers
  const getProCheckNote = (notifications: PropertyNotification[] | undefined) => {
    let proCheckNote = notifications?.find(
      (notification) => notification.product === "procheck"
    );

    return proCheckNote;
  };

  if (props.proCheck.results !== undefined) {
    if (props.proCheck.resultIds.length > 0) {
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Box display="flex" flexDirection="column" height="100%">
            <ProCheckResultsTile
              results={getProCheckReportArray({
                resultsById: props.proCheck.results,
                allIds: props.proCheck.resultIds,
              })}
              notification={getProCheckNote(
                props.property.property?.status?.notifications
              )}
              onMoreInfoClicked={props.onMoreInfoClicked}
            />
            <ErrorSnackbarHandler message={props.proCheck.error} />
          </Box>
        </Grid>
      );
    } else {
      return <Box></Box>;
    }
  } else if (props.proCheck.error !== undefined) {
    return (
      <Grid item xs={12} md={6} xl={4}>
        <ProCheckErrorTile error={props.proCheck.error} />{" "}
      </Grid>
    );
  } else {
    return (
      <BorderedBox flexDirection="column" height="100%" padding={0}>
        <Spinner />
      </BorderedBox>
    );
  }
};

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return { proCheck: state.proCheck, property: state.propertyDetail };
};

export default connect(mapStateToProps)(ProCheckProductTile);
