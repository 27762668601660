import { Box } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Property from "../../../../rest/properties/model/Property";
import ErrorDisplay from "../../../../ui/components/ErrorDisplay";
import SearchBar from "../../../../ui/components/SearchBar";
import Spinner from "../../../../ui/components/Spinner";
import {
  SMALL_SPACING,
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
} from "../../../../ui/theme/dimensions";
import PropertyTableItem from "../model/PropertyTableItem";
import mapToPropertyTableItem from "../utils/mapToPropertyTableItem";
import PropertyListEmptyMessage from "./PropertyListEmptyMessage";
import PropertyTable from "./PropertyTable";
import TablePageIndicator from "./TablePageIndicator";

const tableBottomSpacing = EXTRA_LARGE_SPACING * 2;

interface IProps {
  searchText: string;
  errorMessage: string | undefined;
  canCreateProperties: boolean;
  maxPages: number;
  currentPage: number;
  addingProperty: boolean;
  addPropertyError: string | undefined;
  isPropertyAdded: boolean | undefined;
  properties: Property[] | undefined;
  showOwnerInTable: boolean;

  onSearchTextChanged: (value: string) => void;
  onPageNumberChanged: (page: number) => void;
  onPropertyClicked: (property: Property) => void;
  handleExportClicked?: (startDate: Date, endDate: Date) => void;
}

type Props = IProps & WithT;

interface State {}

class BarePropertyList extends React.Component<Props, State> {
  render() {
    return (
      <Box display="flex" flexDirection="row" margin={SMALL_SPACING}>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          marginX={DEFAULT_SPACING}
          marginTop={SMALL_SPACING}
        >
          <Box display="flex" flexDirection="row" marginBottom={SMALL_SPACING}>
            <Box display="flex" flexGrow={1} marginRight={SMALL_SPACING}>
              <SearchBar
                placeholder={this.props.t("propertyListPage.searchBarPlaceholder")}
                value={this.props.searchText}
                onChange={this.props.onSearchTextChanged}
              />
            </Box>
          </Box>

          {this.renderBody()}

          <Box height={tableBottomSpacing} />

          <TablePageIndicator
            maxPages={this.props.maxPages}
            selectedPage={this.props.currentPage}
            onPageNumberChanged={this.props.onPageNumberChanged}
          />
        </Box>
      </Box>
    );
  }

  renderBody = () => {
    if (this.props.errorMessage) {
      return (
        <ErrorDisplay
          title={this.props.t("propertyListPage.errorTitle")}
          message={this.props.errorMessage}
        />
      );
    } else if (this.props.properties?.length === 0) {
      return <PropertyListEmptyMessage />;
    } else if (this.props.properties) {
      const tableItems = this.props.properties.map((p) =>
        mapToPropertyTableItem(p, this.props.t)
      );
      return (
        <PropertyTable
          hideStatus
          hideMetrics
          tableItems={tableItems}
          showOwner={this.props.showOwnerInTable}
          onClick={this.handlePropertyClicked}
        />
      );
    } else {
      return <Spinner />;
    }
  };

  // Handlers

  handleAddPropertyClicked = () => {
    this.setState({ isAddPropertyDialogOpen: true });
  };

  handleAddPropertyDialogClosed = () => {
    this.setState({ isAddPropertyDialogOpen: false });
  };

  handlePropertyClicked = (item: PropertyTableItem) => {
    const property = this.props.properties?.find((p) => p.id === item.id);
    if (property) {
      this.props.onPropertyClicked(property);
    }
  };
}

export default withTranslation()(BarePropertyList);
