import { AxiosError } from "axios";
import i18next, { TFunction } from "i18next";
import { createRestError } from "../../rest/utils/RestError";

// The t function has to be bound to work properly
// https://github.com/i18next/i18next/issues/1287#issuecomment-604273657
// It's only being changed for testing.
const axiosErrorHandler = (
  e: any,
  t: TFunction = i18next.t.bind(i18next)
): string | undefined => {
  if (!e?.isAxiosError) {
    return undefined;
  }

  const statusCode = (e as AxiosError).response?.status;
  if (statusCode === 401) {
    return "error.status401";
  } else if (statusCode === 403) {
    return "error.status403";
  } else if (statusCode === 404) {
    return "error.status404";
  } else if (statusCode === 422) {
    return status422ErrorHandler(e, t);
  } else if (statusCode === 429) {
    return "error.status429";
  }

  return undefined;
};

const status422ErrorHandler = (e: AxiosError, t: TFunction): string | undefined => {
  const restError = createRestError(e);
  const errors = restError.errors || {};

  const includesError = (name: string): boolean => {
    return Object.keys(errors).includes(name);
  };

  // Incomplete profile errors
  if (includesError("terms_accepted")) {
    return "status422Terms";
  } else if (includesError("profile")) {
    return "status422Profile";
  } else if (includesError("organisation")) {
    return "status422Organisation";
  }

  let fieldName: string = "";

  // If there's a validation error on a field
  // display the field name within an error message
  if (includesError("address_line_1")) {
    fieldName = t("addressEditor.line1");
  } else if (includesError("address_city")) {
    fieldName = t("addressEditor.city");
  } else if (includesError("address_postcode")) {
    fieldName = t("addressEditor.postCode");
  } else if (includesError("address_country")) {
    fieldName = t("addressEditor.country");
  } else if (includesError("email") || includesError("email_address")) {
    fieldName = t("registrationPage.emailFieldHint");
  } else if (includesError("first_name")) {
    fieldName = t("personalInfoPage.firstNameHint");
  } else if (includesError("last_name")) {
    fieldName = t("personalInfoPage.surnameHint");
  } else if (includesError("name")) {
    fieldName = t("orgTable.nameHeader");
  } else if (includesError("phone_number")) {
    fieldName = t("personalInfoPage.contactNumberHint");
  }

  if (fieldName !== "") {
    const placeholder = t("error.status422RequiredField");
    return placeholder.replace("%s", fieldName.toLocaleLowerCase());
  }

  // Otherwise we can't do anything
  return undefined;
};

export default axiosErrorHandler;
