import UserDetails from "../../../../rest/user/model/UserDetails";

// Load platform users
export const LOAD_PLATFORM_USERS_TYPE = "LOAD_PLATFORM_USERS_TYPE";
export interface LoadPlatformUsersAction {
  type: typeof LOAD_PLATFORM_USERS_TYPE;
  page: number;
}
export const loadPlatformUsers = (page: number): LoadPlatformUsersAction => ({
  type: LOAD_PLATFORM_USERS_TYPE,
  page: page,
});

// Platform users loaded
export const PLATFORM_USERS_LOADED_TYPE = "PLATFORM_USERS_LOADED_TYPE";
export interface PlatformUsersLoadedAction {
  type: typeof PLATFORM_USERS_LOADED_TYPE;
  users: UserDetails[];
  page: number;
  maxPages: number;
}
export const platformUsersLoaded = (
  users: UserDetails[],
  page: number,
  maxPages: number
): PlatformUsersLoadedAction => ({
  type: PLATFORM_USERS_LOADED_TYPE,
  users: users,
  page: page,
  maxPages: maxPages,
});

// Platform users error
export const PLATFORM_USERS_ERROR_TYPE = "PLATFORM_USERS_ERROR_TYPE";
export interface PlatformUsersErrorAction {
  type: typeof PLATFORM_USERS_ERROR_TYPE;
  error: string;
}
export const platformUsersError = (error: string): PlatformUsersErrorAction => ({
  type: PLATFORM_USERS_ERROR_TYPE,
  error: error,
});

// Search text changed
export const SEARCH_TEXT_CHANGED_TYPE = "SEARCH_TEXT_CHANGED_TYPE";
export interface SearchTextChangedAction {
  type: typeof SEARCH_TEXT_CHANGED_TYPE;
  text: string;
}
export const searchTextChanged = (text: string): SearchTextChangedAction => ({
  type: SEARCH_TEXT_CHANGED_TYPE,
  text: text,
});

type PlatformUserListAction =
  | LoadPlatformUsersAction
  | PlatformUsersLoadedAction
  | PlatformUsersErrorAction
  | SearchTextChangedAction;

export default PlatformUserListAction;
