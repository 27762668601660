import { CSSProperties } from "@material-ui/styles";
import React from "react";
import { SenseProductType } from "../../../rest/sense/model/SenseDevice";
import pro3Logo from "./Pro3_500.png";
import micro3Logo from "./Micro3_500.png";
import proCheckLogo from "./ProCheck_500.png";
import placeholderLogo from "./Placeholder_500.png";

const defaultHeight = 60;

interface Props {
  deviceType?: SenseProductType;
  height?: number;
  style?: Omit<CSSProperties, "maxHeight" | "width" | "borderRadius">;
}

const DeviceLogo = (props: Props) => {
  const height = props.height || defaultHeight;

  const getDeviceSource = () => {
    switch (props.deviceType) {
      case "PRO3":
        return pro3Logo;
      case "M3SM":
      case "M3WF":
        return micro3Logo;
      case "PROCHECK":
        return proCheckLogo;
      default:
        return placeholderLogo;
    }
  };

  const style = {
    ...props.style,
    maxHeight: height,
    width: "auto",
    borderRadius: 10,
  };

  return <img style={style} src={getDeviceSource()} alt="" />;
};

export default DeviceLogo;
