// Forgot password request

export const FORGOT_PASSWORD_REQUEST_TYPE = "FORGOT_PASSWORD_REQUEST_TYPE";

export interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUEST_TYPE;
  email: string;
}

export const forgotPasswordRequest = (email: string): ForgotPasswordRequestAction => {
  return {
    type: FORGOT_PASSWORD_REQUEST_TYPE,
    email: email,
  };
};

// Forgot password success

export const FORGOT_PASSWORD_REQUEST_SUCCESS_TYPE =
  "FORGOT_PASSWORD_REQUEST_SUCCESS_TYPE";

export interface ForgotPasswordRequestSuccessAction {
  type: typeof FORGOT_PASSWORD_REQUEST_SUCCESS_TYPE;
}

export const forgotPasswordRequestSuccess = (): ForgotPasswordRequestSuccessAction => {
  return {
    type: FORGOT_PASSWORD_REQUEST_SUCCESS_TYPE,
  };
};

// Forgot password error

export const FORGOT_PASSWORD_ERROR_TYPE = "FORGOT_PASSWORD_ERROR_TYPE";

export interface ForgotPasswordErrorAction {
  type: typeof FORGOT_PASSWORD_ERROR_TYPE;
  error: string;
}

export const forgotPasswordError = (error: string): ForgotPasswordErrorAction => {
  return {
    type: FORGOT_PASSWORD_ERROR_TYPE,
    error: error,
  };
};

// Forgot password token expired

export const FORGOT_PASSWORD_TOKEN_EXPIRED_TYPE = "FORGOT_PASSWORD_TOKEN_EXPIRED_TYPE";

export interface ForgotPasswordErrorTokenExpiredAction {
  type: typeof FORGOT_PASSWORD_TOKEN_EXPIRED_TYPE;
}

export const forgotPasswordTokenExpired = (): ForgotPasswordErrorTokenExpiredAction => {
  return {
    type: FORGOT_PASSWORD_TOKEN_EXPIRED_TYPE,
  };
};

// Forgot password submit

export const FORGOT_PASSWORD_SUBMIT_TYPE = "FORGOT_PASSWORD_SUBMIT_TYPE";

export interface ForgotPasswordSubmitAction {
  type: typeof FORGOT_PASSWORD_SUBMIT_TYPE;
  email: string;
  newPassword: string;
  code: string;
}

export const forgotPasswordSubmit = (
  email: string,
  newPassword: string,
  code: string
): ForgotPasswordSubmitAction => {
  return {
    type: FORGOT_PASSWORD_SUBMIT_TYPE,
    email: email,
    newPassword: newPassword,
    code: code,
  };
};

// Forgot password submit success

export const FORGOT_PASSWORD_SUBMIT_SUCCESS_TYPE = "FORGOT_PASSWORD_SUBMIT_SUCCESS_TYPE";

export interface ForgotPasswordSubmitSuccessAction {
  type: typeof FORGOT_PASSWORD_SUBMIT_SUCCESS_TYPE;
}

export const forgotPasswordSubmitSuccess = (): ForgotPasswordSubmitSuccessAction => {
  return {
    type: FORGOT_PASSWORD_SUBMIT_SUCCESS_TYPE,
  };
};

// Forgot password activation required

export const FORGOT_PASSWORD_ACTIVATION_REQUIRED_TYPE =
  "FORGOT_PASSWORD_ACTIVATION_REQUIRED";

export interface ForgotPasswordActivationRequiredAction {
  type: typeof FORGOT_PASSWORD_ACTIVATION_REQUIRED_TYPE;
  email: string;
}

export const forgotPasswordActivationRequired = (
  email: string
): ForgotPasswordActivationRequiredAction => {
  return {
    type: FORGOT_PASSWORD_ACTIVATION_REQUIRED_TYPE,
    email: email,
  };
};

// Cancel forgot password activation

export const CANCEL_FORGOT_PASSWORD_ACTIVATION_TYPE =
  "CANCEL_FORGOT_PASSWORD_ACTIVATION_TYPE";

export interface CancelForgotPasswordActivation {
  type: typeof CANCEL_FORGOT_PASSWORD_ACTIVATION_TYPE;
}

export const cancelForgotPasswordActivation = (): CancelForgotPasswordActivation => {
  return {
    type: CANCEL_FORGOT_PASSWORD_ACTIVATION_TYPE,
  };
};

// Union type

type ForgotPasswordAction =
  | ForgotPasswordRequestAction
  | ForgotPasswordRequestSuccessAction
  | ForgotPasswordErrorAction
  | ForgotPasswordSubmitAction
  | ForgotPasswordSubmitSuccessAction
  | ForgotPasswordActivationRequiredAction
  | CancelForgotPasswordActivation
  | ForgotPasswordErrorTokenExpiredAction;

export default ForgotPasswordAction;
