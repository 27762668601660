import { Box, Typography } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { DEFAULT_SPACING } from "../../../../ui/theme/dimensions";

const NoReportsFound = (props: WithTranslation) => {
  //Translated strings
  const noReportsFound = props.t("productReportTable.noReportsFound");

  return (
    <Box paddingBottom={DEFAULT_SPACING}>
      <Typography variant="h5">{noReportsFound}</Typography>
    </Box>
  );
};

export default withTranslation()(NoReportsFound);
