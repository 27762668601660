import { put, select, takeLatest } from "redux-saga/effects";
import {
  logInError,
  LoginStateChangedAction,
  LOGIN_STATUS_CHANGED_TYPE,
  logOut,
} from "../../../../pages/auth/Login/redux/LoginAction";
import { REMOVE_ORGANISATION_SUCCESS_TYPE } from "../../../../pages/organisations/details/redux/OrganisationDetailActions";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import MemberOrganisation from "../../../../rest/organisation/model/MemberOrganisation";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import OrganisationPermissionsService, {
  OrganisationPermissions,
} from "../service/OrganisationPermissionsService";
import {
  activeOrganisationChanged,
  activeOrganisationError,
  clearOrganisations,
  LOAD_MY_ORGANISATIONS_TYPE,
  ORGANISATION_ADDED_TYPE,
  SwitchOrganisationAction,
  SWITCH_ORGANISATION_TYPE,
} from "./ActiveOrganisationActions";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* activeOrganisationSagas() {
  // This is listening to actions of type "SWITCH_ORGANISATION_TYPE"
  // and on getting one, it runs onOrganisationChanged.
  // takeLatest means if one is already running when another comes it,
  // it'll discard the first saga before running the next
  yield takeLatest(SWITCH_ORGANISATION_TYPE, onOrganisationChanged);

  // When the user is logged in, fetch the list of organisations
  yield takeLatest(LOGIN_STATUS_CHANGED_TYPE, onLogInChanged);

  // Refresh the orgs when...
  yield takeLatest(
    [
      ORGANISATION_ADDED_TYPE, // A new one is added
      LOAD_MY_ORGANISATIONS_TYPE, // it's asked for specifically
      REMOVE_ORGANISATION_SUCCESS_TYPE, // one has been removed
    ],
    fetchOrganisations
  );
}

function* onOrganisationChanged(action: SwitchOrganisationAction) {
  try {
    const orgPermissionService: OrganisationPermissionsService = yield getDependency(
      "organisationPermissionsService"
    );

    const orgPermissions: OrganisationPermissions =
      yield orgPermissionService.getPermissionsForRole(
        action.currentOrganisation.role.id
      );

    yield put(activeOrganisationChanged(action.currentOrganisation, orgPermissions));

    // Get the orgRepo from the saga context. This is a kind of dependency injection,
    // meaning we can replace the OrganisationRepository with a fake version for testing.
    // It's set it SagaContext.ts
    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");

    // Tell the backend the active org has changed. It doesn't matter if this fails,
    // it'll just mean when the user logs back in their org won't have changed
    yield orgRepo.setActiveOrganisation(action.currentOrganisation);
  } catch (e) {
    const message = getErrorMessage(e, "organisationSwitcher.fetchError");
    yield put(activeOrganisationError(message));
  }
}

function* onLogInChanged(action: LoginStateChangedAction) {
  if (action.loginStatus === "logged_in") {
    yield fetchOrganisations();
  } else if (action.loginStatus === "logged_out") {
    yield put(clearOrganisations());
  }
}

function* fetchOrganisations() {
  try {
    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");

    // Call the APIs to get the active org and the list of available orgs
    const orgList: MemberOrganisation[] = yield orgRepo.fetchOrganisationList();
    const activeOrgResponse: Organisation = yield orgRepo.getActiveOrganisation();

    if (orgList.length === 0) {
      // An empty org list is useless.
      throw new Error();
    }

    // Get the organisation whose id matches, or default to the first in the list.
    const activeOrgId = activeOrgResponse.id || "";

    // Checking for presense of an Agent Org - if it exists, it is always set as an active one;
    // NOTE: There should only ever be one Agent org that the user belongs to;
    const agentOrgId = orgList.find((org) => org.is_agent_for?.id);

    const activeOrg =
      agentOrgId === undefined
        ? orgList.find((org) => org.id === activeOrgId) || orgList[0]
        : agentOrgId;

    // MARK - Active Organisation will default to Agent Organisation if it is present in the active org list

    const orgPermissionService: OrganisationPermissionsService = yield getDependency(
      "organisationPermissionsService"
    );

    const orgPermissions: OrganisationPermissions =
      yield orgPermissionService.getPermissionsForRole(activeOrg.role.id);

    const rootState: RootState = yield select();
    const currentActiveOrg = rootState.activeOrganisation.currentOrganisation;
    const currentOrgList = rootState.activeOrganisation.organisationList;
    const currentPermissions = rootState.activeOrganisation.permissions;

    // Only dispatch the action if the orgs have changed
    if (
      JSON.stringify(currentActiveOrg) !== JSON.stringify(activeOrg) ||
      JSON.stringify(currentOrgList) !== JSON.stringify(orgList) ||
      JSON.stringify(currentPermissions) !== JSON.stringify(orgPermissions)
    ) {
      yield put(activeOrganisationChanged(activeOrg, orgPermissions, orgList));
    }
  } catch (e) {
    const message = getErrorMessage(e, "organisationSwitcher.fetchError");
    // if we can't get an org we can't do anything
    yield put(logOut());
    yield put(logInError(message));
  }
}

export default activeOrganisationSagas;
