import { put, select, takeLatest } from "@redux-saga/core/effects";
import getDependency from "../../../../redux/utils/getDependency";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import OrganisationContractorRepository from "../../../../rest/organisation-contractor/OrganisationContractorRepository";
import {
  LoadOrganisationAction,
  loadOrganisationError,
  loadOrganisationSuccess,
  LOAD_ORGANISATION_TYPE,
  RemoveOrganisationAction,
  removeOrganisationError,
  removeOrganisationSuccess,
  REMOVE_ORGANISATION_TYPE,
  LOAD_ORGANISATION_ADMIN_TYPE,
  LoadOrganisationAdminAction,
  AdminEditOrganisationAction,
  updateOrganisationSuccess,
  updateOrganisationError,
  ADMIN_EDIT_ORGANISATION_TYPE,
} from "./OrganisationDetailActions";
import RootState from "../../../../redux/RootState";
import OrganisationDetailService from "../service/OrganisationDetailService";
import { OrganisationRelationship } from "./OrganisationDetailState";
import AdminRepository from "../../../../rest/admin/AdminRepository";
import { updateOrgListEntry } from "../../../admin/PlatformOrganisationListPage/redux/PlatformOrganisationListAction";
import NoActiveOrganisationError from "../../../../errors/NoActiveOrganisationError";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import LocalizedError from "../../../../errors/LocalizedError";
import organisationDetailErrorHandler from "../../../../rest/organisation/error/organisationDetailErrorHandler";
import checkCountry from "../../../../redux/utils/checkCountry";
import buildOrganisationFromCode from "../../../admin/AdminOrganisationDetailsPage/utils/buildOrganisationFromCode";

function* organisationDetailSagas() {
  yield takeLatest(LOAD_ORGANISATION_TYPE, loadOrganisationSaga);
  yield takeLatest(LOAD_ORGANISATION_ADMIN_TYPE, adminLoadOrganisationSaga);
  yield takeLatest(REMOVE_ORGANISATION_TYPE, removeOrganisationSaga);
  yield takeLatest(ADMIN_EDIT_ORGANISATION_TYPE, adminUpdateOrgSaga);
}

function* loadOrganisationSaga(action: LoadOrganisationAction) {
  try {
    const rootState: RootState = yield select();
    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");

    const organisationDetailService: OrganisationDetailService = yield getDependency(
      "organisationDetailService"
    );
    const organisationResponse: Organisation = yield orgRepo.getOrganisation(
      action.organisationId
    );

    const currentOrgId = rootState.activeOrganisation.currentOrganisation?.id;
    if (!currentOrgId) {
      throw new NoActiveOrganisationError();
    }

    const relationship: OrganisationRelationship | undefined =
      yield organisationDetailService.getOrganisationRelationship(
        action.organisationId,
        currentOrgId
      );

    if (organisationResponse.is_agent_for) {
      throw new LocalizedError("orgDetailPage.agentError");
    }

    yield checkCountry(organisationResponse.address_country.id);

    yield put(loadOrganisationSuccess(organisationResponse, relationship));
  } catch (e) {
    const message = getErrorMessage(
      e,
      "orgDetailPage.loadError",
      organisationDetailErrorHandler
    );
    yield put(loadOrganisationError(message));
  }
}

function* adminLoadOrganisationSaga(action: LoadOrganisationAdminAction) {
  try {
    const rootState: RootState = yield select();
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    if (!rootState.adeyAdmin.hasAdminAccess) {
      throw new LocalizedError("error.unauthorizedPage");
    }

    const organisationResponse: Organisation = yield adminRepo.getOrganisation(
      action.organisationId
    );

    if (organisationResponse.is_agent_for) {
      throw new LocalizedError("orgDetailPage.agentError");
    }

    yield checkCountry(organisationResponse.address_country.id);

    yield put(loadOrganisationSuccess(organisationResponse, undefined));
  } catch (e) {
    const message = getErrorMessage(e, "orgDetailPage.loadError");
    yield put(loadOrganisationError(message));
  }
}

function* removeOrganisationSaga(action: RemoveOrganisationAction) {
  try {
    const rootState: RootState = yield select();
    const relationship = rootState.orgDetail.organisationRelationship;

    const orgContractorRepo: OrganisationContractorRepository = yield getDependency(
      "organisationContractorRepository"
    );

    if (relationship === "contractor") {
      yield orgContractorRepo.removeContractorFromOrganisation(
        action.contractor.id,
        action.organisation.id
      );
    } else {
      yield orgContractorRepo.stopContracting(
        action.contractor.id,
        action.organisation.id
      );
    }

    yield put(removeOrganisationSuccess(action.contractor));
  } catch (e) {
    const message = getErrorMessage(e, "orgDetailPage.removeError");
    yield put(removeOrganisationError(message));
  }
}

function* adminUpdateOrgSaga(action: AdminEditOrganisationAction) {
  try {
    const rootState: RootState = yield select();
    const adminRepo: AdminRepository = yield getDependency("adminRepository");

    if (!rootState.adeyAdmin.hasAdminAccess) {
      throw new LocalizedError("error.unauthorizedPage");
    }

    const newOrg = buildOrganisationFromCode(
      action.organisation,
      rootState.countryList.countryList
    );

    const orgResponse: Organisation = yield adminRepo.updateOrganisation(newOrg);

    yield checkCountry(orgResponse.address_country.id);

    yield put(updateOrganisationSuccess(orgResponse));
    yield put(updateOrgListEntry(orgResponse));
  } catch (e) {
    const message = getErrorMessage(e, "currentOrganisationPage.editError");
    yield put(updateOrganisationError(message));
  }
}

export default organisationDetailSagas;
