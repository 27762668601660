/**
 * Small function to determine if the enivornment is a test one - checks the base API url if it contains 'behold' in it
 */

import Env from "../app/environment/Env";

const isDevServer = () => {
  return Env.apiBaseUrl.includes("behold");
};

export default isDevServer;
