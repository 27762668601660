import { TFunction } from "i18next";
import { PersonalInfoPageState } from "./PersonalInfoPage";

interface PersonalInfoPageValidation {
  isValid: boolean;
  newState: PersonalInfoPageState;
}

const validatePersonalInfoPage = (
  state: PersonalInfoPageState,
  t: TFunction
): PersonalInfoPageValidation => {
  const newState = { ...state };
  let isValid = true;

  // Ensure the firstName field has been filled
  if (state.firstName.value.trim().length === 0) {
    isValid = false;
    newState.firstName = {
      ...state.firstName,
      errorText: t("personalInfoPage.firstNameRequiredError"),
    };
  } else {
    newState.firstName = {
      ...state.firstName,
      errorText: undefined,
    };
  }
  // Ensure the surname field has been filled.
  if (state.surname.value.trim().length === 0) {
    isValid = false;
    newState.surname = {
      ...state.surname,
      errorText: t("personalInfoPage.surnameRequiredError"),
    };
  } else {
    newState.surname = {
      ...state.surname,
      errorText: undefined,
    };
  }

  // Ensure contact number has been filled.
  if (state.contactNumber.value.trim().length < 2) {
    isValid = false;
    newState.contactNumber = {
      ...state.contactNumber,
      errorText: t("personalInfoPage.contactNumberRequiredError"),
    };
  } else {
    newState.contactNumber = {
      ...state.contactNumber,
      errorText: undefined,
    };
  }

  // Ensure postcode has been filled.
  if (state.addressPostcode.value.trim().length < 2) {
    isValid = false;
    newState.addressPostcode = {
      ...state.addressPostcode,
      errorText: t("personalInfoPage.postcodeRequiredError"),
    };
  } else {
    newState.addressPostcode = {
      ...state.addressPostcode,
      errorText: undefined,
    };
  }

  // Ensure confirm number has been filled, and that it matches the
  // contact number field
  if (state.confirmNumber.value.trim().length === 0) {
    isValid = false;
    newState.confirmNumber = {
      ...state.confirmNumber,
      errorText: t("personalInfoPage.confirmNumberRequiredError"),
    };
  } else if (state.confirmNumber.value !== state.contactNumber.value) {
    isValid = false;
    newState.confirmNumber = {
      ...state.confirmNumber,
      errorText: t("personalInfoPage.confirmNumberIncorrectError"),
    };
  } else {
    newState.confirmNumber = {
      ...state.confirmNumber,
      errorText: undefined,
    };
  }

  return {
    isValid: isValid,
    newState: newState,
  };
};

export default validatePersonalInfoPage;
