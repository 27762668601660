import { faClock, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, Divider } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import { StatusReason } from "../../../../../rest/properties/model/Property";
import { SenseProductType } from "../../../../../rest/sense/model/SenseDevice";
import ColoredText from "../../../../../ui/components/ColoredText";
import { BACKGROUND_ACCENT_COLOR } from "../../../../../ui/theme/createMaterialTheme";
import { EXTRA_LARGE_SPACING, SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import DeviceStatus from "../../model/DeviceStatus";
import OverdueStatus from "../../model/OverdueStatus";
import RAGStatus from "../../model/RAGStatus";
import {
  selectedDeviceStatusesChanged,
  selectedOverdueStatusesChanged,
  selectedRagStatusesChanged,
} from "../../redux/PropertyListActions";
import FilterMenuItem from "./components/FilterMenuItem";
import OverdueStatusCheckbox from "./components/OverdueStatusCheckbox";
import ProCheckFilterBox from "./components/ProCheckFilterBox";
import ProductFilterBox from "./components/ProductFilterBox";
import RAGStatusCheckbox from "./components/RAGStatusCheckbox";

interface ReduxStateProps {
  selectedRagStatuses: Set<RAGStatus>;
  selectedOverdueStatuses: Set<OverdueStatus>;
  selectedDeviceStatuses:
    | [SenseProductType, Set<DeviceStatus> | Set<StatusReason>]
    | undefined;
  showPropertiesFilter: boolean;
  showMicro3: boolean;
}

interface ReduxDispatchProps {
  onSelectedRagStatusesChanged: (set: Set<RAGStatus>) => void;
  onSelectedOverdueStatusesChanged: (set: Set<OverdueStatus>) => void;
  onSelectedDeviceStatusesChanged: (
    set: Set<DeviceStatus> | Set<StatusReason>,
    deviceType: SenseProductType | undefined
  ) => void;
}

type Props = WithTranslation & ReduxStateProps & ReduxDispatchProps;

interface State {
  propertyStatusOpen: boolean;
  overdueStatusOpen: boolean;
  deviceStatusOpen: boolean;
}

class StatusFilterMenu extends React.Component<Props, State> {
  state = {
    propertyStatusOpen: true,
    overdueStatusOpen: true,
    deviceStatusOpen: true,
  };

  render() {
    const menuTitle = this.props.t("filterMenu.title");
    const statusItemTitle = this.props.t("filterMenu.propertyStatusTitle");
    const overdueItemTitle = this.props.t("filterMenu.overdueStatusTitle");

    const productTitle = this.props.t("filterMenu.deviceStatusTitle");

    return (
      <Box padding={SMALL_SPACING}>
        <ColoredText textColor={BACKGROUND_ACCENT_COLOR}>
          <strong>{menuTitle.toUpperCase()}</strong>
        </ColoredText>

        <Box height={EXTRA_LARGE_SPACING} />

        <FilterMenuItem
          title={statusItemTitle}
          isOpen={this.state.propertyStatusOpen}
          didChangeOpen={this.togglePropertyStatusOpen}
        >
          <Box>
            <RAGStatusCheckbox
              status="green"
              isChecked={this.props.selectedRagStatuses.has("green")}
              onCheckChanged={this.handleRagStatusChanged}
            />
            <RAGStatusCheckbox
              status="amber"
              isChecked={this.props.selectedRagStatuses.has("amber")}
              onCheckChanged={this.handleRagStatusChanged}
            />
            <RAGStatusCheckbox
              status="red"
              isChecked={this.props.selectedRagStatuses.has("red")}
              onCheckChanged={this.handleRagStatusChanged}
            />
          </Box>
        </FilterMenuItem>

        <Box height={EXTRA_LARGE_SPACING} />

        <FilterMenuItem
          title={overdueItemTitle}
          isOpen={this.state.overdueStatusOpen}
          didChangeOpen={this.toggleOverdueStatusOpen}
        >
          <Box>
            <OverdueStatusCheckbox
              status="upcoming"
              isChecked={this.props.selectedOverdueStatuses.has("upcoming")}
              onCheckChanged={this.handleOverdueStatusChanged}
              startIcon={faClock}
            />
            <OverdueStatusCheckbox
              status="overdue"
              isChecked={this.props.selectedOverdueStatuses.has("overdue")}
              onCheckChanged={this.handleOverdueStatusChanged}
              startIcon={faExclamationCircle}
            />
          </Box>
        </FilterMenuItem>

        <Box height={EXTRA_LARGE_SPACING} />
        {this.props.showPropertiesFilter && (
          <FilterMenuItem
            title={productTitle}
            isOpen={this.state.deviceStatusOpen}
            didChangeOpen={this.toggleProductStatusOpen}
          >
            <>
              <ProCheckFilterBox
                deviceType="PROCHECK"
                checkboxState={
                  !!this.props.selectedDeviceStatuses
                    ? [
                        this.props.selectedDeviceStatuses[0],
                        this.props.selectedDeviceStatuses[1] as Set<StatusReason>,
                      ]
                    : undefined
                }
                onCheckChanged={this.handleDeviceStatusChanged}
              />
              <Divider />

              <ProductFilterBox
                deviceType="PRO3"
                checkboxState={
                  !!this.props.selectedDeviceStatuses
                    ? [
                        this.props.selectedDeviceStatuses[0],
                        this.props.selectedDeviceStatuses[1] as Set<DeviceStatus>,
                      ]
                    : undefined
                }
                onCheckChanged={this.handleDeviceStatusChanged}
              />
              {this.props.showMicro3 && <Divider />}

              {this.props.showMicro3 && (
                <ProductFilterBox
                  deviceType="M3SM"
                  checkboxState={
                    !!this.props.selectedDeviceStatuses
                      ? [
                          this.props.selectedDeviceStatuses[0],
                          this.props.selectedDeviceStatuses[1] as Set<DeviceStatus>,
                        ]
                      : undefined
                  }
                  onCheckChanged={this.handleDeviceStatusChanged}
                />
              )}
              {this.props.showMicro3 && <Divider />}
              {this.props.showMicro3 && (
                <ProductFilterBox
                  deviceType="M3WF"
                  checkboxState={
                    !!this.props.selectedDeviceStatuses
                      ? [
                          this.props.selectedDeviceStatuses[0],
                          this.props.selectedDeviceStatuses[1] as Set<DeviceStatus>,
                        ]
                      : undefined
                  }
                  onCheckChanged={this.handleDeviceStatusChanged}
                />
              )}
            </>
          </FilterMenuItem>
        )}
      </Box>
    );
  }

  togglePropertyStatusOpen = (open: boolean) => {
    this.setState({ propertyStatusOpen: open });
  };

  toggleOverdueStatusOpen = (open: boolean) => {
    this.setState({ overdueStatusOpen: open });
  };

  toggleProductStatusOpen = (open: boolean) => {
    this.setState({ deviceStatusOpen: open });
  };

  handleRagStatusChanged = (status: RAGStatus, checked: boolean) => {
    let newStatuses = new Set(this.props.selectedRagStatuses);

    if (checked) {
      newStatuses.add(status);
    } else {
      newStatuses.delete(status);
    }

    // Don't submit if nothing's changed.
    if (this.props.selectedRagStatuses !== newStatuses) {
      this.props.onSelectedRagStatusesChanged(newStatuses);
    }
  };

  handleOverdueStatusChanged = (status: OverdueStatus, checked: boolean) => {
    let newStatuses = new Set(this.props.selectedOverdueStatuses);

    if (checked) {
      newStatuses.add(status);
    } else {
      newStatuses.delete(status);
    }

    // Don't submit if nothing's changed.
    if (this.props.selectedOverdueStatuses !== newStatuses) {
      this.props.onSelectedOverdueStatusesChanged(newStatuses);
    }
  };

  handleDeviceStatusChanged = (
    deviceFilters: [SenseProductType, Set<DeviceStatus> | Set<StatusReason>] | undefined
  ) => {
    if (deviceFilters === undefined) {
      const emptySet = new Set<DeviceStatus>();
      this.props.onSelectedDeviceStatusesChanged(emptySet, undefined);
    } else {
      this.props.onSelectedDeviceStatusesChanged(deviceFilters[1], deviceFilters[0]);
    }
  };

  handleProCheckStatusChanged = (statuses: Set<StatusReason>) => {
    this.props.onSelectedDeviceStatusesChanged(statuses, "PROCHECK");
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    selectedDeviceStatuses: state.propertyList.selectedDeviceFilters,
    selectedRagStatuses: state.propertyList.selectedStatusFilters,
    selectedOverdueStatuses: state.propertyList.selectedOverdueFilters,
    showPropertiesFilter:
      state.adeyAdmin.hasAdminAccess ||
      state.activeOrganisation.currentOrganisation?.address_country.code === "GB" ||
      state.activeOrganisation.currentOrganisation?.address_country.code === "IE",
    showMicro3:
      state.adeyAdmin.hasAdminAccess ||
      state.activeOrganisation.currentOrganisation?.address_country.code === "GB" ||
      state.activeOrganisation.currentOrganisation?.address_country.code === "IE",
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    onSelectedRagStatusesChanged: (set: Set<RAGStatus>) =>
      dispatch(selectedRagStatusesChanged(set)),
    onSelectedOverdueStatusesChanged: (set: Set<OverdueStatus>) =>
      dispatch(selectedOverdueStatusesChanged(set)),
    onSelectedDeviceStatusesChanged: (
      set: Set<DeviceStatus> | Set<StatusReason>,
      deviceType: SenseProductType | undefined
    ) => dispatch(selectedDeviceStatusesChanged(set, deviceType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StatusFilterMenu));
