import { takeEvery } from "@redux-saga/core/effects";
import { put, select } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import Organisation from "../../../../rest/organisation/model/Organisation";
import OrganisationRepository from "../../../../rest/organisation/OrganisationRepository";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import { loadMyOrganisations } from "../../../../ui/components/OrganisationSwitcher/redux/ActiveOrganisationActions";
import {
  createOrganisationError,
  CreateOrganisationSubmitAction,
  createOrganisationSuccess,
  CREATE_ORGANISATION_SUBMIT_TYPE,
} from "./CreateOrganisationAction";
import buildOrgFormFromCode from "../../../admin/AdminOrganisationDetailsPage/utils/buildOrgFormFromCode";
import RootState from "../../../../redux/RootState";

function* createOrganisationSagas() {
  yield takeEvery(CREATE_ORGANISATION_SUBMIT_TYPE, createOrgSubmitSaga);
}

function* createOrgSubmitSaga(action: CreateOrganisationSubmitAction) {
  try {
    const orgRepo: OrganisationRepository = yield getDependency("organisationRepository");
    const rootState: RootState = yield select();
    const org = buildOrgFormFromCode(action.form, rootState.countryList.countryList);

    const response: Organisation = yield orgRepo.registerOrganisation(org);

    // Refresh the org list now it's completed
    yield put(loadMyOrganisations());

    yield put(createOrganisationSuccess(response));
  } catch (e) {
    const message = getErrorMessage(e, "createOrganisationPanel.error");
    yield put(createOrganisationError(message));
  }
}

export default createOrganisationSagas;
