import { Country } from "../../../../rest/countries/model/Country";
import AddPropertyFormModel from "../../AddPropertyDialog/model/AddPropertyFormModel";

const buildPropFormFromCode = (
  property: AddPropertyFormModel,
  countryList: Country[] | undefined
): AddPropertyFormModel => {
  const newProperty: AddPropertyFormModel = {
    ...property,
    address_country:
      countryList?.find((country) => country.code === property.address_country)?.id || "",
  };

  return newProperty;
};

export default buildPropFormFromCode;
