import { Box, Grid } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PasswordTextField from "../../../../ui/components/PasswordTextField";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import isValidPassword from "../../../../utils/isValidPassword";
import EditableFieldLabel from "../../../properties/PropertyDetailPage/components/EditableField/components/EditableFieldLabel";
import ChangePassword from "../model/ChangePassword";

interface State {
  oldPassword: string | undefined;
  newPassword: string | undefined;
  errorMessage: string | undefined;
  oldPasswordError: string | undefined;
  newPasswordError: string | undefined;
}

interface Props extends WithTranslation {
  loading: boolean | undefined;
  onPasswordChanged: (password: ChangePassword, message: string) => void;
}

class ChangePasswordPanel extends React.Component<Props, State> {
  state = {
    oldPassword: "",
    newPassword: "",
    errorMessage: "",
    oldPasswordError: "",
    newPasswordError: "",
    successMessage: this.props.t("accountSettingsPage.changePasswordSuccess"),
  };

  render() {
    const passwordLabel = this.props.t("loginPage.passwordFieldLabel");
    const oldPassword = this.props.t("changePasswordPanel.oldPasswordFieldHint");
    const newPassword = this.props.t("changePasswordPanel.newPasswordFieldHint");
    const updatePasswordButton = this.props.t(
      "changePasswordPanel.updatePasswordButtonLabel"
    );
    const passwordHelperText = this.props.t("validationHelper.passwordChange");

    return (
      <Box paddingX={LARGE_SPACING}>
        <Grid container direction="column" spacing={SMALL_SPACING}>
          <Grid item>
            <EditableFieldLabel label={passwordLabel} />
          </Grid>
          <Grid item>
            <PasswordTextField
              autocomplete="off"
              value={this.state.oldPassword}
              fullWidth
              hint={oldPassword}
              onChange={this.handleOldPasswordChange}
              disabled={this.props.loading}
              errorText={this.state.oldPasswordError}
              onEnter={this.handlePasswordSubmit}
            />
          </Grid>
          <Grid item>
            <PasswordTextField
              autocomplete="off"
              value={this.state.newPassword}
              fullWidth
              hint={newPassword}
              onChange={this.handleNewPasswordChange}
              disabled={this.props.loading}
              errorText={this.state.newPasswordError}
              onEnter={this.handlePasswordSubmit}
              helperText={passwordHelperText}
            />
          </Grid>
          <Grid item>
            {" "}
            <PrimaryButton
              fullWidth
              label={updatePasswordButton}
              isLoading={this.props.loading}
              onClick={this.handlePasswordSubmit}
              variant="outlined"
              colour="secondary"
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  //MARK: Handlers
  handleOldPasswordChange = (value: string) => {
    this.setState({
      oldPassword: value,
      oldPasswordError: undefined,
    });
  };
  handleNewPasswordChange = (value: string) => {
    this.setState({
      newPassword: value,
      newPasswordError: undefined,
    });
  };
  handlePasswordSubmit = () => {
    if (this.validate()) {
      let password: ChangePassword = {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      };
      this.props.onPasswordChanged(password, this.state.successMessage);
    }
  };
  //Validation

  validate = (): boolean => {
    let isValid = true;

    if (this.state.oldPassword.trim().length === 0) {
      this.setState({
        oldPasswordError: this.props.t("changePasswordPanel.oldPasswordRequired"),
      });
      isValid = false;
    }

    if (this.state.newPassword.trim().length === 0) {
      this.setState({
        newPasswordError: this.props.t("changePasswordPanel.newPasswordRequired"),
      });
      isValid = false;
    } else if (!isValidPassword(this.state.newPassword)) {
      this.setState({
        newPasswordError: this.props.t("registrationPage.passwordValidationError"),
      });
      isValid = false;
    }

    return isValid;
  };
}

export default withTranslation()(ChangePasswordPanel);
