import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { LOGIN_ROUTE } from "../../../routes/routes";
import ArrowIcon from "../../../ui/assets/ArrowIcon/ArrowIcon";
import CenteredAuthCard from "../../../ui/components/CenteredAuthCard";
import PrimaryButton from "../../../ui/components/PrimaryButton";
import { DEFAULT_SPACING } from "../../../ui/theme/dimensions";

type Props = WithTranslation & RouteComponentProps;

class ForgotPasswordEmailSentPage extends React.Component<Props> {
  render() {
    const title = this.props.t("forgotPasswordSent.pageTitle");
    const message = this.props.t("forgotPasswordSent.message");
    const signInButton = this.props.t("forgotPasswordSent.signInButton");

    return (
      <CenteredAuthCard title={title}>
        <Box marginLeft={DEFAULT_SPACING} marginRight={DEFAULT_SPACING}>
          <Grid container spacing={DEFAULT_SPACING} direction="column">
            <Grid item />

            <Grid item>
              <Typography align="center">{message}</Typography>
            </Grid>

            <Grid item>
              <PrimaryButton
                fullWidth
                label={signInButton}
                endIcon={<ArrowIcon />}
                onClick={this.handleSignInClicked}
              />
            </Grid>
          </Grid>
        </Box>
      </CenteredAuthCard>
    );
  }

  handleSignInClicked = () => {
    this.props.history.push(LOGIN_ROUTE);
  };
}

export default withTranslation()(withRouter(ForgotPasswordEmailSentPage));
