import React from "react";
import AlertDialog from "../../../../../../ui/components/AlertDialog";

interface Props {
  open: boolean;

  onClose: () => void;
}

const PropertyDeletedDialog = (props: Props) => {
  return (
    <AlertDialog
      open={props.open}
      onClose={undefined}
      title="deletePropertyHandler.propertyDeletedTitle"
      body="deletePropertyHandler.propertyDeletedBody"
      actions={[
        {
          title: "deletePropertyHandler.propertyDeletedDashButton",
          onClick: props.onClose,
        },
      ]}
    />
  );
};

export default PropertyDeletedDialog;
