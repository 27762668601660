import { AxiosInstance } from "axios";
import DataWrapper, { unwrapData } from "../utils/DataWrapper";
import MetricsRepository from "./MetricsRepository";
import MetricsResponse from "./model/MetricsResponse";

class RestMetricsRepository implements MetricsRepository {
  constructor(private axios: AxiosInstance) {}

  getOrganisationMetrics = (
    isAdeyAdmin: boolean,
    organisationId: string
  ): Promise<MetricsResponse> => {
    const urlPrefix = isAdeyAdmin ? "/admin" : "";
    const url = `${urlPrefix}/organisations/${organisationId}/metrics`;
    return this.axios
      .get<DataWrapper<MetricsResponse>>(url)
      .then((response) => response.data as DataWrapper<MetricsResponse>)
      .then(unwrapData);
  };

  getPlatformMetrics = (): Promise<MetricsResponse> => {
    const url = `/admin/metrics`;
    return this.axios
      .get<DataWrapper<MetricsResponse>>(url)
      .then((response) => response.data as DataWrapper<MetricsResponse>)
      .then(unwrapData);
  };
}

export default RestMetricsRepository;
