import AdminRepository from "../../../rest/admin/AdminRepository";
import MockProCheckRepository from "../../../rest/procheck/MockProCheckRepository";
import ProCheckResult from "../../../rest/procheck/model/ProCheckResult";
import ProCheckResultDetails from "../../../rest/procheck/model/ProCheckResultDetails";
import ProCheckRepository from "../../../rest/procheck/ProCheckRepository";
import Property from "../../../rest/properties/model/Property";
import PropertiesRepository from "../../../rest/properties/PropertiesRepository";
import Page from "../../../rest/utils/Page";

export interface ProCheckResults {
  results: ProCheckResult[];
  property: Property;
}

class ProCheckService {
  constructor(
    private proCheckRepository: ProCheckRepository,
    private propertyRepository: PropertiesRepository,
    //TODO: Remove after implementation is finished
    private mockProCheckRepository: MockProCheckRepository,
    private adminRepository: AdminRepository
  ) {}

  loadResults = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string
  ): Promise<ProCheckResults> => {
    // Both network calls at once
    const [results, property] = await Promise.all([
      this.listResults(isAdeyAdmin, organisationId, propertyId),
      this._getProperty(isAdeyAdmin, organisationId, propertyId),
    ]);

    return {
      results: results,
      property: property,
    };
  };

  loadUserResults = async (
    organisationId: string,
    isAdeyAdmin: boolean,
    userId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ): Promise<Page<ProCheckResult[]>> => {
    let results = await this._listUserResults(
      isAdeyAdmin,
      organisationId,
      userId,
      page,
      limit,
      searchString
    );
    return results;
  };

  loadOrgResults = async (
    organisationId: string,
    isAdeyAdmin: boolean,
    page?: number,
    limit?: number,
    searchString?: string,
    mocked?: boolean
  ): Promise<Page<ProCheckResult[]>> => {
    let results = await this._listOrgResults(
      organisationId,
      isAdeyAdmin,
      page,
      limit,
      searchString,
      mocked
    );
    return results;
  };

  getResult = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string,
    resultId: string
  ): Promise<ProCheckResultDetails> => {
    const [result, property] = await Promise.all([
      this._getResult(isAdeyAdmin, organisationId, propertyId, resultId),
      this._getProperty(isAdeyAdmin, organisationId, propertyId),
    ]);

    return {
      ...result,
      property: property,
    };
  };

  listResults = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string
  ) => {
    const repo = isAdeyAdmin ? this.adminRepository : this.proCheckRepository;
    return repo.listAllProCheckResults(organisationId, propertyId);
  };

  // Private methods

  private _listUserResults = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    userId: string,
    page?: number,
    limit?: number,
    searchString?: string
  ) => {
    const repo = isAdeyAdmin ? this.adminRepository : this.proCheckRepository;
    return repo.listUserProCheckResults(
      organisationId,
      userId,
      page,
      limit,
      searchString
    );
  };

  //Following the pattern in case this needs to be extended to Adey Admins
  private _listOrgResults = async (
    organisationId: string,
    isAdeyAdmin: boolean,
    page?: number,
    limit?: number,
    searchString?: string,
    mocked?: boolean
  ) => {
    const repo = mocked
      ? this.mockProCheckRepository
      : isAdeyAdmin
      ? this.adminRepository
      : this.proCheckRepository;
    return repo.listOrgProCheckResults(organisationId, page, limit, searchString);
  };

  private _getProperty = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string
  ) => {
    const repo = isAdeyAdmin ? this.adminRepository : this.propertyRepository;
    return repo.getProperty(organisationId, propertyId, true);
  };

  private _getResult = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string,
    resultId: string
  ) => {
    const repo = isAdeyAdmin ? this.adminRepository : this.proCheckRepository;
    return repo.fetchProCheckResult(organisationId, propertyId, resultId);
  };
}

export default ProCheckService;
