import AdminRepository from "../../../rest/admin/AdminRepository";
import Property from "../../../rest/properties/model/Property";
import PropertiesRepository from "../../../rest/properties/PropertiesRepository";
import SenseDevice from "../../../rest/sense/model/SenseDevice";
import SenseDeviceReadings from "../../../rest/sense/model/SenseDeviceReadings";
import SenseRepository from "../../../rest/sense/SenseRepository";
import SelectedSenseDevice from "../models/SelectedSenseDevice";

export interface SensePropertyDevices {
  property: Property;
  devices: SenseDevice[];
}

class SenseDeviceService {
  constructor(
    private devicesRepository: SenseRepository,
    private propertyRepository: PropertiesRepository,
    private adminRepository: AdminRepository
  ) {}

  fetchDeviceList = async (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string
  ): Promise<SensePropertyDevices> => {
    const [property, devices] = await Promise.all([
      this._getProperty(isAdmin, organisation_id, property_id),
      this.devicesRepository.listAllSenseDevices(isAdmin, organisation_id, property_id),
    ]);

    return {
      devices: devices,
      property: property,
    };
  };

  fetchDeviceDetails = async (
    isAdmin: boolean,
    organisation_id: string,
    property_id: string,
    serial: string
  ): Promise<SelectedSenseDevice> => {
    //Currently, this is used to never fetch device readings for non-admin users
    const noDeviceReadings: SenseDeviceReadings[] = [];

    const [device, readings] = await Promise.all([
      this.devicesRepository.getSenseDevice(
        isAdmin,
        organisation_id,
        property_id,
        serial
      ),
      isAdmin
        ? this.devicesRepository.listAllSenseDeviceReadings(
            isAdmin,
            organisation_id,
            property_id,
            serial
          )
        : noDeviceReadings,
    ]);

    return { device: device, readings: readings };
  };

  private _getProperty = async (
    isAdeyAdmin: boolean,
    organisationId: string,
    propertyId: string
  ) => {
    const repo = isAdeyAdmin ? this.adminRepository : this.propertyRepository;
    return repo.getProperty(organisationId, propertyId, true);
  };
}

export default SenseDeviceService;
