import { put, select, takeEvery } from "redux-saga/effects";
import getDependency from "../../../../redux/utils/getDependency";
import RootState from "../../../../redux/RootState";
import AuthService from "../../Login/service/AuthService";
import {
  ActivationEmailRequestedAction,
  activationEmailResendComplete,
  activationEmailResendError,
  activationEmailResent,
  ACTIVATION_EMAIL_REQUESTED_TYPE,
} from "./ResendActivationEmailAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";

function* resendActivationEmailSagas() {
  yield takeEvery(ACTIVATION_EMAIL_REQUESTED_TYPE, resendActivationEmail);
}

function* resendActivationEmail(action: ActivationEmailRequestedAction) {
  try {
    const authService: AuthService = yield getDependency("authService");
    const state: RootState = yield select();

    // Use the email from the action or pull from the state.
    const email = action.email ?? state.resendActivationEmail.emailAddress;

    if (!email) {
      console.error("No email in current state");
      yield put(activationEmailResendComplete());
    } else {
      yield authService.resendActivationEmail(email);
      yield put(activationEmailResent());
    }
  } catch (e) {
    const message = getErrorMessage(e, "resendActivationEmailRequestPage.error");
    yield put(activationEmailResendError(message));
  }
}

export default resendActivationEmailSagas;
