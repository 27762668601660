import { TFunction } from "i18next";
import { OrganisationEditorState } from "./OrganisationEditor";

interface OrganisationEditorValidation {
  isValid: boolean;
  newState: OrganisationEditorState;
}

const validateOrganisationEditor = (
  state: OrganisationEditorState,
  t: TFunction
): OrganisationEditorValidation => {
  const newState = { ...state };
  let isValid = true;
  const requiredPlaceholder = t("error.requiredPlaceholder");

  const orgNameHint = t("organisationEditor.nameFieldHint");
  const orgNumberHint = t("organisationEditor.numberFieldHint");
  const line1Hint = t("organisationEditor.addressLine1Hint");
  const postcodeHint = t("organisationEditor.addressPostcodeHint");
  const cityHint = t("organisationEditor.addressCityHint");
  const countryHint = t("organisationEditor.addressCountryHint");

  // Ensure the org name field has been filled
  if (state.organisationName.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", orgNameHint);
    newState.organisationName.errorText = error;
  }

  // Ensure the org number field has been filled
  if (state.organisationContactNumber.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", orgNumberHint);
    newState.organisationContactNumber.errorText = error;
  }

  // Ensure the line 1 field has been filled
  if (state.addressLine1.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", line1Hint);
    newState.addressLine1.errorText = error;
  }

  // Ensure the city field has been filled
  if (state.city.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", cityHint);
    newState.city.errorText = error;
  }

  // Ensure the postcode field has been filled
  if (state.postcode.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", postcodeHint);
    newState.postcode.errorText = error;
  }

  // Ensure the country field has been filled
  if (state.country.value.trim().length === 0) {
    isValid = false;
    const error = requiredPlaceholder.replace("%s", countryHint);
    newState.country.errorText = error;
  }

  return {
    isValid: isValid,
    newState: newState,
  };
};

export default validateOrganisationEditor;
