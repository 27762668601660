import { Box, Grid } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import EditableFieldLabel from "../../../pages/properties/PropertyDetailPage/components/EditableField/components/EditableFieldLabel";
import EditorButtons from "../../../pages/properties/PropertyDetailPage/components/EditableField/components/EditorButtons";
import Address from "../../../pages/properties/PropertyDetailPage/model/Address";
import { LARGE_SPACING } from "../../theme/dimensions";
import AddressLookupDialog from "../AddressLookupDialog/AddressLookupDialog";
import CountryPicker from "../CountryPicker";
import OutlineButton from "../OutlineButton";
import PrimaryTextField, { FieldState } from "../PrimaryTextField";
import validateAddressEditor from "./validateAddressEditor";

interface Props extends WithTranslation {
  label: string;
  address: Address | undefined;
  loading?: boolean;
  isAgent?: boolean;
  lookupEnabled?: boolean;

  onCancelClicked: () => void;
  onSaveClicked: (address: Address) => void;
}

export interface AddressEditorState {
  line1: FieldState;
  line2: FieldState;
  line3: FieldState;
  city: FieldState;
  postcode: FieldState;
  country: FieldState;

  showAddressLookup: boolean;
}

type Fields = "line1" | "line2" | "line3" | "city" | "postcode" | "country";
const gridItemStyle = { paddingTop: LARGE_SPACING, paddingBottom: LARGE_SPACING };

class AddressEditor extends React.Component<Props, AddressEditorState> {
  state = {
    line1: { value: this.props.address?.line1 || "" } as FieldState,
    line2: { value: this.props.address?.line2 || "" } as FieldState,
    line3: { value: this.props.address?.line3 || "" } as FieldState,
    city: { value: this.props.address?.city || "" } as FieldState,
    postcode: { value: this.props.address?.postcode || "" } as FieldState,
    country: { value: this.props.address?.country || "" } as FieldState,
    showAddressLookup: false,
  };

  render() {
    const line1Placeholder = this.props.t("addressEditor.line1");
    const line2Placeholder = this.props.t("addressEditor.line2");
    const line3Placeholder = this.props.t("addressEditor.line3");
    const cityPlaceholder = this.props.t("addressEditor.city");
    const postcodePlaceholder = this.props.t("addressEditor.postCode");
    const countryPlaceholder = this.props.t("addressEditor.country");

    const lookupEnabled = this.props.lookupEnabled ?? true;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <EditableFieldLabel label={this.props.label} />

            {lookupEnabled && (
              <OutlineButton
                label={this.props.t("addressEditor.addressLookupButton")}
                onClick={() => this.setState({ showAddressLookup: true })}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} style={gridItemStyle}>
          <PrimaryTextField
            hint={line1Placeholder}
            {...this.state.line1}
            onChange={(value) => this.handleFieldChanged("line1", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={12} style={gridItemStyle}>
          <PrimaryTextField
            hint={line2Placeholder}
            {...this.state.line2}
            onChange={(value) => this.handleFieldChanged("line2", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={12} style={gridItemStyle}>
          <PrimaryTextField
            hint={line3Placeholder}
            {...this.state.line3}
            onChange={(value) => this.handleFieldChanged("line3", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={6} style={gridItemStyle}>
          <PrimaryTextField
            hint={cityPlaceholder}
            {...this.state.city}
            onChange={(value) => this.handleFieldChanged("city", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={6} style={gridItemStyle}>
          <PrimaryTextField
            hint={postcodePlaceholder}
            {...this.state.postcode}
            onChange={(value) => this.handleFieldChanged("postcode", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={12} style={gridItemStyle}>
          <CountryPicker
            isAgent={this.props.isAgent}
            hint={countryPlaceholder}
            {...this.state.country}
            onChange={(value) => this.handleFieldChanged("country", value)}
            disabled={this.props.loading}
          />
        </Grid>

        <Grid item xs={12}>
          <EditorButtons
            loading={this.props.loading}
            onSave={this.handleSavedClicked}
            onCancel={this.props.onCancelClicked}
          />
        </Grid>

        <Grid item />

        <AddressLookupDialog
          open={this.state.showAddressLookup}
          country={this.state.country.value}
          isAgent={this.props.isAgent}
          onCountryChanged={(value) => this.handleFieldChanged("country", value)}
          onAddressLoaded={this.handleAddressLoaded}
          onClose={this.handleAddressLookupClosed}
        />
      </Grid>
    );
  }

  // Handler

  handleFieldChanged = (field: Fields, newValue: string) => {
    const newState = { ...this.state };
    newState[field] = { value: newValue, errorText: undefined };
    this.setState(newState);
  };

  handleSavedClicked = () => {
    const address = validateAddressEditor(this.state, this.setAddressState, this.props.t);
    if (address) {
      this.props.onSaveClicked(address);
    }
  };

  setAddressState = (state: AddressEditorState) => {
    this.setState(state);
  };

  handleAddressLookupClosed = () => {
    this.setState({ showAddressLookup: false });
  };

  handleAddressLoaded = (address: Address) => {
    this.setState({
      line1: { value: address.line1 },
      line2: { value: address.line2 || "" },
      line3: { value: address.line3 || "" },
      city: { value: address.city },
      postcode: { value: address.postcode },
      country: { value: address.country },

      showAddressLookup: false,
    });
  };
}

export default withTranslation()(AddressEditor);
