import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, withStyles } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { SECONDARY_COLOR, SELECTABLE_ITEM_COLOR } from "../theme/createMaterialTheme";
import { BORDER_RADIUS } from "../theme/dimensions";

const ThemedTextField = withStyles({
  root: {
    borderRadius: BORDER_RADIUS,
    background: SELECTABLE_ITEM_COLOR,
    width: "100%",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: SECONDARY_COLOR,
      },
    },
  },
})(TextField);

interface Props {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const SearchBar = (props: Props) => {
  return (
    <ThemedTextField
      value={props.value}
      label={props.placeholder}
      color="secondary"
      variant="outlined"
      InputProps={{
        endAdornment: <FontAwesomeIcon color={SECONDARY_COLOR} icon={faSearch} />,
      }}
      onChange={(event) => {
        props.onChange?.(event.target.value);
      }}
    />
  );
};

export default withTranslation()(SearchBar);
