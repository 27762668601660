import termsAndConditions from "./termsAndConditions";

const translations = {
  translations: {
    languages: {
      en: "English",
      fr: "Français",
      de: "Deutsche",
    },

    language: {
      code: "en",
    },

    buttons: {
      download: "Download",
      export: "Export",
      save: "Save",
      cancel: "Cancel",
      close: "OK",
      back: "Back",
      confirm: "Confirm",
      retry: "Retry",
      signOut: "Sign out",
      yes: "Yes",
      remove: "Remove",
      no: "No",
      next: "Next",
      move: "Move",
      clear: "Clear",
      search: "Search",
    },

    divider: {
      option: "or",
    },

    countryCode: {
      AU: "Australia",
      GB: "United Kingdom",
      IE: "Republic of Ireland",
      AT: "Austria",
      FR: "France",
      MISSING: "Unknown Country",
    },

    notificationMessageCode: {
      3000: "The batteries are in good condition.",
      3001: "The battery levels are low, replacements required in next 2 months. ",
      3002: "The battery levels are very low, replacements are required immediately.",
      4000: "Filter is currently connected and reporting",
      4001: "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5000: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      5001: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5002: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
      6000: "Service next due: {{date}}",
      6001: "Service overdue: {{date}}",
      6002: "Service due: {{date}}",
    },

    connectionMessage: {
      GSM: "Is there a good signal present?",
      WIFI: "Has Wi-Fi router or password changed?",
    },

    proClub: {
      status412:
        "As you are already an existing ADEY ProClub user, you do not need to register again. Please login using your existing ProClub email and password and follow instruction on screen. \n \n Forgotten your password? /forgot-password to reset",
      status412Link: "Click here",
    },

    registrationErrors: {
      exists:
        "As you are already an existing ADEY Sense user, you do not need to register again. Please login using your existing Sense email and password and follow instruction on screen. \n \n Forgotten your password? /forgot-password to reset",
    },

    error: {
      requiredPlaceholder: "%s is required",
      unauthorizedPage: "You don't have permission to view this page",
      noActiveOrganisation: "Please select an organisation",
      noCountryError: "Could not find the country provided",
      fallback: "Something went wrong. Please try again later",
      status401: "Your session has expired, please log in again",
      status403: "You don't have permission to access this",
      status404: "Nothing was found",
      status429:
        "We've received too many requests from your device. Please wait a while and try again",
      status422Terms: "Please accept the terms and conditions",
      status422Profile: "Please complete your user profile",
      status422Organisation: "You must belong to at least one organisation",
      status422RequiredField: "Please enter a valid %s",

      inviteStatus404: "That invitation doesn't exist",
      inviteStatus409: "That user has already been invited",
      inviteStatus400: "You cannot invite yourself",

      propertyStatus404: "No property was found",
      userDetail404: "No user was found",
      organisationDetail404: "No organisation was found",

      countryList404: "No countries were found",
      updateCountry404: "No country was found",
      updateCountry422: "Error updating the country",
      createCountry422: "Error creating the country",
    },

    loginPage: {
      title: "Sign in",
      emailFieldLabel: "Email address",
      passwordFieldLabel: "Password",
      forgotPasswordButton: "Forgot password?",
      continueButton: "Continue",
      registerPrompt: "Don't have an account?",
      registerButton: "Register here",
      emailRequired: "Email address is required",
      passwordRequired: "Password is required",
      loginError: "Unable to login. Please check your credentials and try again.",
    },

    QuestionPageOne: {
      title: "Which of the following best describes you?",
      choiceOne: "Heating Engineer (Independent)",
      choiceTwo: "Plumber",
      choiceThree: "Residential Contractor",
      choiceFour: "Residential Contractor (50+ contractors)",
      choiceFive: "Other",
      questionOneButton: "continue",
    },
    QuestionPageTwo: {
      title: "Where do you do most of your work?",
      choiceOne: "Residential (private Residence)",
      choiceTwo: "New Build",
      choiceThree: "Social Housing/HA/LA",
      choiceFour: "Utility",
      choiceFive: "Boiler Manufacturer/OEM",
      choiceSix: "Other",
      questionTwoButton: "Proceed To Dashboard",
    },

    homezoneUnsubscribePage: {
      title: "HomeZone Subscription",
      description: "Are you sure you want to unsubscribe from HomeZone?",
      unsubscribeButton: "Unsubscribe",
      backButton: "To Homepage",
      success: "You have successfully unsubscribed from the HomeZone",
      unsubscribeError:
        "You have already unsubscribed from the HomeZone, or the token is invalid",
    },

    addPropertyButton: {
      label: "Add property",
    },

    addPropertyDialog: {
      title: "Add a property",
      addressSectionTitle: "Address",
      nameFieldHint: "Organisation name",
      numberFieldHint: "Organisation phone number",
      addressLine1Hint: "Address Line 1",
      notesHint: "Notes (Optional)",
      addressLine2Hint: "Address Line 2",
      addressLookupButton: "Address Lookup",
      addressCityHint: "City",
      countyHint: "Country",
      addressPostcodeHint: "Postcode",
      addressCountryHint: "Country",
      contactDetailsTitle: "Property owner contact details",
      firstNameHint: "First name",
      lastNameHint: "Surname",
      contactNumberHint: "Contact number",
      emailHint: "Email",
      saveButton: "Save",
      skipButton: "Skip",
      backButton: "Back",
      nextButton: "Next",
      addError: "Unable to add property",
    },

    addPropertyAddressForm: {
      line1Error: "Line 1 is required",
      cityError: "City is required",
      postcodeError: "Postcode is required",
      countryError: "Select a country",
    },

    addPropertyContactForm: {
      contactFirstNameError: "First name is required",
      contactLastNameError: "Last name is required",
      contactNumberError: "Contact number is required",
      emailError: "Email is required",
    },

    propertySearchBar: {
      placeholder: "Search for property",
    },

    registrationPage: {
      title: "Registration",
      emailFieldHint: "Email",
      passwordFieldHint: "Password",
      smartphoneHint:
        "Please note: If you are viewing this on your smart phone, please ensure to close the screen down on acceptance of verification link and continue registration via the app.",
      registerButton: "Register",
      emailRequired: "Email is required",
      passwordRequired: "Password is required",
      emailInvalidError: "Please enter a valid email address",
      passwordValidationError: "Invalid password",
      returnSignInButton: "Return to sign in",
    },

    validationHelper: {
      password:
        "Your password must be at least 8 characters long and contain both uppercase and lowercase characters, as well as a number and a special character.",
      passwordChange:
        "Your password must be at least 8 characters long and contain both uppercase and lowercase characters, as well as a number and a special character",
      passwordReset:
        "Your password must be at least 8 characters long and contain both uppercase and lowercase characters, as well as a number and a special character.",
    },

    termsAndConditionsPage: {
      title: "Terms & Conditions",
      acceptButton: "Accept",
      termsAndConditions: termsAndConditions,
    },

    accountCreatedPage: {
      title: "Account created",
      message:
        "Your account has been created successfully. A verification email has now been sent to you, please open the email and follow the link provided to activate your account",
      signInButton: "Go to sign in",
    },

    accountActivationPage: {
      title: "Account activation",
      successPageTitle: "Account activation successful",
      successMessage:
        "Your account has been activated successfully, please sign in to access your account",
      signInButton: "Go to sign in",
      errorMessage: "Unable to activate your account. Please ensure the link is correct.",
    },

    forgotPassword: {
      requestPageTitle: "Forgot password",
      emailHint: "Email address",
      requestButton: "Reset password",
      requestPageSubtitle: "Forgotten your ADEY Sense® password?",
      content:
        "Did you know? You now have one login for all ADEY services, so any changes you make here will apply to your ADEY Sense®, ADEY ProCheck® and ADEY ProClub® accounts. \n \n Enter your email below to receive an email with instructions on how to reset your password.",
      signInPage: "Return to sign in",
      emailRequiredError: "Email address is required",
      emailInvalidError: "Invalid email address",
    },

    forgotPasswordSent: {
      pageTitle: "Reset email sent",
      message: "We have sent you an email with password reset instructions",
      signInButton: "Go to sign in",
    },

    passwordResetRequired: {
      resetRequiredTitel: "A password reset is  required",
      resetRequiredMessage:
        "Password reset for this user is required to continue using the portal. Please reset your password by pressing the button below.",
      resetRequiredProClubMessage:
        "We’ve upgraded our security to keep your information safe. To continue using ADEY services, please reset your password now. Your password must be at least 8 characters and contain at least 1 lowercase, uppercase, number and special character.",
    },

    forgotPasswordSubmit: {
      pageTitle: "Reset password",
      passwordHint: "New password",
      resetButton: "Reset password",
      smartphoneHint:
        "Please note: If you are viewing this on your smart phone, please ensure to close the screen down after resetting your password and continue login via the app.",
      successTitle: "Reset password successful",
      successMessage: "Please sign in to access your account",
      successSignInButton: "Go to sign in",
      requiredError: "New password is required",
      invalidError: "Invalid password",
    },
    forgotPasswordTokenExpired: {
      title: "Forgot Password",
      message:
        "Your reset password link has expired, please go to the reset password page and try again.",
      homepageButton: "Return to sign in",
      passwordResetButton: "To password reset",
    },
    personalInfoPage: {
      title: "Personal info",
      firstNameHint: "First name",
      surnameHint: "Surname",
      contactNumberHint: "Contact number",
      continueButton: "Continue",
      postcodeHint: "Postcode",
      confirmNumberHint: "Reconfirm contact number",
      firstNameRequiredError: "First name is required",
      surnameRequiredError: "Surname is required",
      contactNumberRequiredError: "Contact number is required",
      postcodeRequiredError: "Postcode is required",
      confirmNumberRequiredError: "Reconfirm contact number is required",
      confirmNumberIncorrectError:
        "Reconfirm contact number does not match contact number",
    },

    createOrganisationPage: {
      title: "Create Company Account",
      fallbackError: "Unable to create an organisation",
    },

    organisationEditor: {
      agentLabel: "Adey Agent",
      addressLabel: "Address",
      agentHint: "Create as Agent Organisation",
      nameFieldHint: "Organisation name",
      numberFieldHint: "Organisation phone number",
      vatNumberHint: "VAT Number",
      addressLine1Hint: "Address Line 1",
      addressLine2Hint: "Address Line 2",
      addressLine3Hint: "Address Line 3",
      addressCityHint: "City",
      addressPostcodeHint: "Postcode",
      addressCountryHint: "Country",
      continueButton: "Continue",
    },

    organisationLogoUploader: {
      addButton: "Add",
      subtitle: "Organisation Logo",
    },

    createOrganisationAdminForm: {
      title: "Create an organisation",
      nameFieldHint: "Organisation name",
      nameRequiredError: "Organisation name is required",
      numberFieldHint: "Organisation contact number",
      numberRequiredError: "Organisation contact number is required",
      invitePrompt: "Don't have all the details?",
      inviteButton: "Send invitation to create an organisation",
      continueButton: "Continue",
    },

    organisationOwnerAdminForm: {
      title: "Organisation owner info",
      emailHint: "Email address",
      emailRequiredError: "Email address is required",
      emailInvalidError: "Invalid email address",
      confirmEmailHint: "Confirm email address",
      confirmEmailRequiredError: "Confirm email address is required",
      confirmEmailError: "Confirm email address does not match the email address",
      finishButton: "Finish",
      backButton: "Go back",
      error: "Unable to invite user",
    },

    createOrganisationAdminEmailSent: {
      title: "Invitation email sent",
      body: "We have sent an invitation email to the organisation primary email with instructions to create an account.",
      returnButton: "Return",
    },

    inviteOrganisationAdminForm: {
      title: "Invite an organisation",
      emailHint: "Email address",
      emailRequiredError: "Email address is required",
      confirmEmailHint: "Confirm email address",
      confirmEmailRequiredError: "Confirm email address is required",
      emailMatchError: "Confirm email address does not match the email address",
      sendInviteButton: "Send invitation",
      backButton: "Go back",
      error: "Unable to submit invite",
    },

    inviteOrgDialog: {
      errorTitle: "Invitation Error",
    },

    inviteEntityPanel: {
      inviteAgentTitle: "Invite agent",
      inviteContractorTitle: "Invite contractor",
      inviteUserTitle: "Invite user",
      emailHint: "Email address",
      emailError: "Email address is required",
      confirmEmailHint: "Confirm email address",
      confirmEmailError: "Confirm email address is required",
      emailMatchError: "Emails do not match",
      submitButton: "Send invitation",
      error: "Invitation sending failure",
      userTypeHint: "Select user type",
      userTypeRequiredError: "User type is required",
      descriptionText:
        "Please ensure you are inviting the “Owner/SuperAdmin” email address linked to the nominated contractor account. ",
      contactHint: "For more information, contact ",
      contactEmail: "info@adey.com",
      infoSummary:
        "To find out more about Sense please contact info@adey.com for more information.",
      premiumInviteSummary:
        "Got someone you'd like to use Sense compatible products on your behalf? No problem! You can link their account by adding their email address on the next screen.",
      premiumAccessSubtitle: "This is a premium feature",
      premiumAccessSummary:
        "By continuing you will upgrade your account and will be charged on your next billing date. Subscription starts at just £2.99+VAT per month. \nTo find out more about Sense subscription charges please contact info@adey.com for more information.",
      premiumAccessContractorSummary:
        "Got a contractor you'd like to use Sense compatible products on your behalf? No problem! You can link their account by adding their email address on the next screen.",
    },

    organisationSwitcher: {
      createOrganisationButton: "Create Organisation",
      organisationTitle: "Organisations",
      organisationDetails: "Organisation Details",
      accountTitle: "Account",
      signOut: "Sign out",
      accountButton: "My Account",
      accountSettings: "Account Settings",
      fetchError: "Unable to fetch organisation details",
      subtitleViaFormat: "<ROLE> via <ORG>",
      agentButton: "Back to <ORG_NAME> agent dashboard",
    },

    userList: {
      ownerTab: "Owner/Super Admin",
      adminTab: "Administrators",
      developersTab: "Developers",
      engineersTab: "Heating engineers",
      invitesTab: "Pending invites",
      searchPlaceholder: "Search for a user",
      inviteButton: "Invite user",
      emptyText: "No users found",
    },

    agentUserList: {
      ownerTab: "Owner/Super Admin",
      agentTab: "Agents",
      developersTab: "Developers",
      invitesTab: "Pending invites",
      searchPlaceholder: "Search for a user",
      inviteButton: "Invite user",
      emptyText: "No users found",
    },

    copyrightFooter: {
      text: "Copyright ADEY Innovation Ltd © {{date}}. All rights reserved.",
      termsLink: "Terms & Conditions",
      privacyLink: "Privacy Policy",
      cookiesLink: "Cookies Policy",
      developer: "Developer",
    },

    propertyTable: {
      headerStatus: "Status",
      headerStatusMessage: "Status Message",
      headerProperty: "Property address",
      headerProducts: "Products",
      headerEmail: "Email",
      headerMove: "Select",
      headerOwner: "Owner Organisation",
      headerContractorName: "Contractor Name",
      rowContractorNamePlaceholder: "No contractor assigned",
      rowOwnerNamePlaceholder: "Owner name missing",
    },

    statusMessage: {
      testDuePlaceholder: "Test due on %s",
      testFailed: "Not passed",
      testMissing: "No tests",
      testOverdue: "Test overdue",
      testPassed: "Passed",
    },

    propertyListPage: {
      errorTitle: "Unable to load properties",
      errorRetryButton: "Retry",
      emptyMessage: "No properties found",
      searchBarPlaceholder: "Search for a property",
    },

    selectedPropertiesPanel: {
      switchLabel: "Show only selected",
      countDescription: "{{count}} property selected",
      countDescription_plural: "{{count}} properties selected",
    },

    moveDeviceDialog: {
      searchPlaceholder: "Search properties",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your device has been moved successfully",
      successTitle_plural: "Your devices have been moved successfully",
      failureTitle: "Failed to move device",
      failureTitle_plural: "Failed to move your devices",
      title: "Confirm Move Device",
      title_plural: "Confirm Move Devices",
    },

    movePropertyDialog: {
      destinationTitle: "Find destination organisation",
      confirmationTitle: "Confirmation",
      successTitle: "Your property has been moved successfully",
      successTitle_plural: "Your properties have been moved successfully",
      failureTitle: "Failed to move property",
      failureTitle_plural: "Failed to move your properties",
      title: "Confirm Move Property",
      title_plural: "Confirm Move Properties",
      searchPlaceholder: "Search organisations",
    },
    moveProCheckDialog: {
      title: "Confirm Move Test",
      title_plural: "Confirm Move Tests",
      destinationTitle: "Find destination property",
      confirmationTitle: "Confirmation",
      successTitle: "Your test has been moved successfully",
      successTitle_plural: "Your tests have been moved successfully",
      failureTitle: "Failed to move test",
      failureTitle_plural: "Failed to move your tests",
      searchPlaceholder: "Search properties",
    },

    moveEntityReviewPanel: {
      title: "Review selected items",
      entityTitle: {
        proCheck: "Move test",
        proCheck_plural: "Move tests",
        device: "Move device",
        device_plural: "Move devices",
        property: "Move property",
        property_plural: "Move properties",
      },
      destinationTitle: {
        property: "To property",
        organisation: "To organisation",
      },
    },

    moveEntityWarningPanel: {
      title: "Confirmation",
      descriptionProperty:
        "Are you sure you want to move {{items, emphasizeList}} to <strong>{{destination}}</strong>?",
      descriptionOther:
        "Are you sure you want to move {{deviceType, emphasizeList}} from <strong>{{origin}}</strong> to <strong>{{destination}}<strong/>?",
    },

    moveEntitySuccessPanel: {
      description:
        "Successfully moved {{items, emphasizeList}} to <strong>{{destination}}</strong>. Closing this window will redirect you to the destination page.",
      descriptionPartial: "Following item has not been moved: {{items, emphasizeList}}",
      descriptionPartial_plural:
        "Following items have not been moved: {{items, emphasizeList}}",
      closeButton: "Close this dialog",
    },

    moveEntityErrorPanel: {
      description:
        "Failed to move {{items, emphasizeList}} to <strong>{{destination}}</strong>. Please try again later, or, if the error persists, contact administration.",
      closeButton: "Close this dialog",
    },

    filterMenu: {
      title: "Filter View",
      propertyStatusTitle: "Property Status",
      overdueStatusTitle: "Service Status",
      deviceStatusTitle: "Products",
    },

    adminOrgDetailTabs: {
      orgOwnerTab: "Owner/Super Admin",
      orgDetailTab: "Details",
      orgUserTab: "Users",
      propertiesTab: "Properties",
      relatedOrgsTab: "Contractors and Associated Organisations",
      proCheckReports: "ProCheck Reports",
    },

    ragStatus: {
      green: "Good",
      amber: "Needs Attention",
      red: "Needs Immediate Attention",
    },

    overdueStatus: {
      overdue: "Properties with service overdue",
      upcoming: "Properties due service in the next 30 days",
    },

    deviceStatus: {
      batteryLow: "Battery Low",
      batteryCritical: "Battery Critical",
      magnetiteLevelMedium: "Magnetite Level Medium",
      magnetiteLevelHigh: "Magnetite Level High",
      noContactWithDevice: "No Contact With Device",
    },

    ragStatusCard: {
      totalTitle: "Total",
      propertiesSubtitle: "Properties",
      greenInfo:
        "The central heating systems in these properties are working correctly – No action required",
      amberInfo:
        "The central heating systems in these properties need to be monitored – Attention required soon",
      redInfo:
        "The central heating systems in these properties need immediate attention – Service visit required",
    },

    accountSettingsPage: {
      userSettingsLink: "Dashboard",
      userSettingsTitle: "Account settings",
      errorTitle: "Unable to load user data",
      updateError: "Unable to update user data",
      changePasswordSuccess: "Password changed successfully",
    },

    userDetailsPanel: {
      postcodeHeader: "Postcode",
      firstNameHeader: "First Name",
      lastNameHeader: "Surname",
      contactNumberHeader: "Contact Number",
      testerHeader: "Tester",
      testerHint: "Enable testing functionality",
      emailHeader: "Email",
      passwordHeader: "Password",
    },

    changePasswordPanel: {
      oldPasswordFieldHint: "Current password",
      newPasswordFieldHint: "New password",
      oldPasswordRequired: "Current password is required",
      newPasswordRequired: "New password is required",
      updatePasswordButtonLabel: "Update Password",
    },

    propertyDetailPage: {
      propertyLink: "Properties",
      errorTitle: "Unable to load property",
      updateError: "Unable to update property",
      ownerOrgLabel: "Owner organisation",
    },

    addressPanel: {
      addressHeader: "Address",
      contactHeader: "Contact name",
      contactNumberHeader: "Contact number",
      emailHeader: "Email",
      notesHeader: "Notes",
    },

    productGrid: {
      title: "Products",
    },

    productSummary: {
      productTitle: "Product",
      productTypeTitle: "Product Type",
      statusMessageTitle: "Status message",
    },

    productReportTable: {
      title: "Product Reports",
      headerContractor: "Contractor",
      headerTester: "Tester",
      headerStatus: "Status",
      headerReportee: "Tester Email",
      headerProperty: "Property",
      headerEmail: "Email",
      headerDate: "Date",
      downloadReportLabel: "Download Report",
      emailReportLabel: "Export",
      noReportsTaken: "No reports taken",
      noReportsFound: "No reports found",
    },

    resendActivationEmailRequestPage: {
      title: "Account activation required",
      message:
        "You need to activate your account in order to access the Sense Portal. An activation email was sent to you, please click the link to continue.",
      resendEmailButton: "Resend activation email",
      signInPrompt: "Already activated your account?",
      signInButton: "Sign in to your account",
      error: "Unable to resend email. Please try again later",
    },

    resendActivationEmailSuccessPage: {
      title: "Account activation email sent",
      message:
        "We have sent you an activation email, please follow the instructions in the email to activate your account.",
      signInPrompt: "Activated your account?",
      signInButton: "Sign in to your account",
    },

    addressEditor: {
      line1: "Line 1",
      line2: "Line 2",
      line3: "Line 3",
      city: "City",
      postCode: "Postcode",
      country: "Country",
      line1RequiredError: "Line 1 is required",
      cityRequiredError: "City is required",
      postCodeRequiredError: "Postcode is required",
      countryRequiredError: "Country is required",
      addressLookupButton: "Address lookup",
    },

    limboAddress: {
      message:
        "This property is missing the full address. Please complete it before you could fully access and edit other property fields",
    },

    limboSignpost: {
      inviteTitle: "Join an existing company account",
      createTitle: "Create Your Company Account",
      createAction: "Create Company Account",
      inviteBody:
        "You may require an invite from an existing company in order to proceed. Please request an invitation from the administrator at the company account to continue. Alternatively, please contact ",
      createBody:
        "Please complete the following section to register your company account for the first time. For more information, please contact ",
      contactLink: "info@adey.com",
    },

    headerTabs: {
      dashboard: "Dashboard",
      userList: "Users",
      reports: "Reports",
      organisations: "Organisations",
      agents: "Adey Agents",
      memberUserList: "Users",
      properties: "Properties",
      totalOrganisationList: "Organisations",
    },

    agentOrganisationListPage: {
      searchPlaceholder: "Search for a managed organisation",
      noManagedOrganisations: "No managed organisations found",
      switchingOrgTitle: "Switching to <org_name>...",
    },

    organisationListPage: {
      searchPlaceholder: "Search for an organisation",
      addButton: "Invite a contractor",
      loadError: "Unable to load organisations",
      emptyMessage: "No organisations found",
      emptyInviteMessage: "No invites found",
      contractorTab: "Contractors",
      associatedOrgsTab: "Associated organisations",
      invitesTab: "Pending invites",
      inviteTooltip: "A list of invitations which are yet to be accepted by users",
      contractorTooltip: "A list of companies working for my organisation",
      associatesTooltip: "A list of companies my organisation is working for",
    },

    userTable: {
      nameHeader: "Name",
      emailHeader: "Email",
      phoneHeader: "Contact number",
    },

    orgTable: {
      nameHeader: "Name",
      propertiesHeader: "Properties",
      managedPropertiesHeader: "Managed Properties",
      phoneHeader: "Contact number",
    },

    agentTable: {
      nameHeader: "Name",
      phoneHeader: "Contact number",
      countryHeader: "Country",
    },

    agentSwitch: {
      labelTitle: "Adey Agent",
      switchTitle: "ADEY Agent Access",
      agentExistsHint:
        "You can not make this organisation an ADEY Agent as Agent for this country already exists",
    },

    agentInfoCard: {
      labelTitle: "Adey Agent",
      organisationLabel: "Organisations",
      countryLabel: "Country",
    },

    agentDialog: {
      title: "Adey Agent Access",
      removeAgentText:
        "Are you sure you want to revoke ADEY Agent access from %s? It will turn it into a regular organisation.",
      removeAgentWarning:
        "NOTE: Organisations managed by %s won't have an agent assigned to them anymore",
      grantAgentAccessText: "Are you sure you want to grant ADEY agent access to %s?",
      grantAgentAccessWarning:
        "NOTE: They will lose access to regular organisation features.",
    },

    agentAssignmentDialog: {
      agentRemovedTitle: "ADEY Agent Access Revoked",
      agentAssignedTitle: "ADEY Agent Access Granted",
      agentRemovedText:
        "ADEY Agent access revoked from %s successfully. Press 'Confirm' to go to organisation details page",
      agentAssignedText:
        "ADEY Agent access granted to %s successfully. Press 'Confirm' to go to ADEY Agent details page",
    },

    inviteTable: {
      emailHeader: "Email",
      role: "Role",
      actionsHeader: "Actions",
    },

    acceptOrganisationInvitePanel: {
      title: "Join organisation",
      messageFormat:
        "You have been invited to join this organisation with a role of %s. Please accept to gain access.",
      acceptButton: "Accept invitation",
      acceptError: "Unable to accept invitation",
      rejectError: "Unable to reject invitation",
      declineButton: "Decline",
    },

    acceptContractorInvitePanel: {
      title: "Contractor Invitation",
      messageFormat:
        "Your organisation has been invited to become a contractor for this organisation. Please accept to gain access.",
      acceptButton: "Accept invitation",
      acceptError: "Unable to accept invitation",
      rejectError: "Unable to reject invitation",
      declineButton: "Decline",
    },

    userTableMenu: {
      removeUser: "Remove user",
      confirmActionText: "Are you sure?",
    },

    orgTableMenu: {
      removeOrg: "Remove organisation",
      confirmActionText: "Are you sure?",
      removeOrgError: "Unable to remove organisation",
    },

    itemTableMenu: {
      confirmActionText: "Are you sure?",
    },

    invitePendingFlag: {
      label: "Invitation pending",
    },

    pendingInviteMenu: {
      resendEmail: "Resend invitation email",
      emailResent: "Invitation email resent",
      resendError: "Unable to resend invitation",
      revokeError: "Unable to revoke invitation",
      revokeInvite: "Revoke invitation",
    },

    userDetailPage: {
      firstNameLabel: "First Name",
      lastNameLabel: "Surname",
      emailLabel: "Email",
      numberLabel: "Contact number",
      roleLabel: "Role",
      organisationLabel: "Organisation",
      loadError: "Unable to access user details",
      removeError: "Unable to remove user",
    },

    userDetailPageTabs: {
      userDetailTab: "User Details",
      proCheckTab: "ProCheck Results",
    },

    orgDetailPage: {
      orgNameLabel: "Organisation Name",
      addressLabel: "Address",
      numberLabel: "Contact number",
      vatLabel: "VAT Number",
      removeError: "Unable to remove organisation",
      agentError:
        "This organisation is an agent. You cannot view it as a regular organisation",
    },

    orgDetailsSwitcher: {
      detailsLabel: "Details",
      ownerLabel: "Owner/Super Admin",
      propertiesLabel: "Properties",
    },

    roles: {
      owner: "Super admin",
      admin: "Admin",
      installer: "Heating engineer",
      developer: "Developer",
      contractor: "Contractor",
      agent: "ADEY Agent",
    },

    removeUserButton: {
      labelPlaceholder: "Remove from %s",
    },

    confirmRemoveUserDialog: {
      title: "Remove user",
      bodyPlaceholder:
        "Are you sure you want to remove <user_name>? They will lose access to all properties within <org_name>.",
      removeButtonPlaceholder: "Remove",
    },

    userRemovedDialog: {
      title: "User removed",
      bodyPlaceholder: "<user_name> has been removed from <org_name>.",
      confirmButton: "Return to list",
    },

    removeContractorButton: {
      labelPlaceholder: "Remove from %s",
    },

    adminRemoveManagedOrgDialog: {
      title: "Remove managed organisation",
      bodyPlaceholder:
        "Unassign <managed_organisation> from <adey_agent>? <adey_agent> won't be able to manage the organisation.",
      removeButtonPlaceholder: "Remove",
    },

    adminRemoveContractorDialog: {
      title: "Remove contractor",
      bodyPlaceholder:
        "Are you sure you want to remove <contractor_name> from <contractee_name>? <contractor_name> will lose access to all properties within <contractee_name>.",
      removeButtonPlaceholder: "Remove",
    },

    confirmRemoveContractorDialog: {
      title: "Remove contractor",
      bodyPlaceholder:
        "Are you sure you want to remove <contractor_name>? They will lose access to all properties within <org_name>.",
      removeButtonPlaceholder: "Remove",
    },

    contractorRemovedDialog: {
      title: "Contractor removed",
      bodyPlaceholder:
        "<contractor_name> has been removed from <org_name> as a contractor.",
      confirmButton: "Return to list",
    },

    currentOrganisationPage: {
      organisationNameLabel: "Organisation name",
      addressLabel: "Address",
      contactNumberLabel: "Contact number",
      vatNumberLabel: "VAT number",
      pageTitle: "Current organisation",
      dashboardLink: "Dashboard",
      editError: "Unable to edit these details",
    },

    createOwnerOrganisationPromptPanel: {
      title: "Contractor invitation",
      messageFormat:
        "You have been invited to become a contractor for %s. To accept this invitation you must create an organisation.",
      createOrganisationButton: "Create organisation",
      declineButton: "Decline",
    },

    createOrganisationPanel: {
      title: "Create organisation",
      backButton: "Go Back",
      error: "Unable to create an organisation",
      tooltip:
        "Creating a new organisation will be separate from any organisation currently linked to.",
    },

    stopContractingButton: {
      title: "Stop contracting for %s",
    },

    confirmStopConfirmingDialog: {
      title: "Stop contracting",
      bodyPlaceholder:
        "If you stop contracting for %s you will lose access to the properties they have assigned to you.",
      stopContractingButton: "Stop contracting",
    },

    adminRemoveContracteeDialog: {
      title: "Remove contractee",
      bodyPlaceholder:
        "Are you sure you want to remove <contractee_org>? <contractor_org> will lose access to the properties <contractee_org> has assigned to them.",
      stopContractingButton: "Remove",
    },

    stopContractingCompletedDialog: {
      title: "Stopped contracting",
      bodyPlaceholder: "You have been removed from contracting for %s.",
      returnToListButton: "Return to list",
    },

    addressLookup: {
      countryField: "Country",
      countryRequiredError: "Country is required",
      title: "Search for address",
      searchAgainButton: "Search again",
      fetchError: "Unable to find addresses",
      emptyError: "No addresses were found for that search term",
      retrieveError: "Unable to load address details",
      searchButton: "Search",
      searchPlaceholder: "Search for an address or postcode",
      requiredError: "Please enter search text",
    },

    homeButton: {
      adminText: "Admin portal",
    },

    platformUsersList: {
      tableTitle: "Users",
      searchBarPlaceholder: "Search for user",
      loadError: "Unable to fetch user list",
      emptyMessage: "No users found with that search term",
    },

    platformAgentList: {
      tableTitle: "Adey Agents",
      searchBarPlaceholder: "Search for an agent",
      loadError: "Unable to get agent list",
      emptyMessage: "No agents found with that search term",
    },

    platformOrganisationsList: {
      tableTitle: "Organisations",
      addOrganisationButton: "Add Organisation",
      searchBarPlaceholder: "Search for organisation",
      loadError: "Unable to fetch organisation list",
      emptyMessage: "No organisations found",
    },

    inviteSentPanel: {
      title: "Invite sent",
      bodyPlaceholder: "An invitation email has been sent to %s",
      continueButton: "Continue",
    },

    senseDeviceInfoCard: {
      lastReading: "Last Reading",
      product: "Product",
      status: "Status",
      totalStatus: "Overall Status",
      serialNumber: "Serial Number",
      moreInfoButton: "More Info",
    },

    senseDeviceReadingsCard: {
      magnetiteLevel: "Magnetite Level",
      statusMessage: "Last Notifications",
      service: "Service",
      lastReading: "Last reading",
      connection: "Connection",
      lastCleaned: "Last cleaned",
    },

    senseDeviceStatus: {
      green: "Good",
      amber: "Needs Attention",
      red: "Needs Immediate Attention",
    },

    senseDevices: {
      loadError: "Unable to load Sense Devices",
      activationError: "Unable to change device activation status",
    },

    selectedSenseDevice: {
      loadError: "Unable to load selected Sense Device",
    },

    senseDeviceBatteryIndicator: {
      title: "Battery level",
      low: "The battery levels are very low, replacements are required immediately.",
      medium: "The battery levels are low, replacements required in next 2 months. ",
      good: "The batteries are in good condition.",
      noSignal: "Could not get battery level",
    },

    senseDeviceMagnetiteIndicator: {
      noLevel:
        "Could not get device magnetite level. If the problem persists, please contact a member of the ADEY support team info@adey.com",
      1: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      2: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      3: "Heating system water quality is in good condition. Sludge capture level is low. System is protected.",
      4: "The sludge capture level in the ADEY Sense™ filter has increased. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. \n For more information please feel free to contact a member of the ADEY support team info@adey.com",
      5: "The sludge capture level in the ADEY Sense™ filter is high. The heating system water is in a poor condition, which could cause problems if left untreated. Please arrange for a service visit to diagnose and resolve the problem as soon as possible. For more information please feel free contact a member of the ADEY support team info@adey.com",
    },

    senseDeviceContactIndicator: {
      noContact:
        "Device has lost contact with system. \n\n Check battery level is okay. \n\n Check {{device}} connectivity: {{deviceNote}}\n\nREBOOT system - (please see instruction manual or installation app) \n\n Reconnect filter - (please see instruction manual or installation app) \n\n Please arrange for a service visit & reconnect filter. For more information please feel free to contact a member of the ADEY support team info@adey.com",
      contactOk: "Filter is currently connected and reporting",
    },

    proCheck: {
      reportName: "Report %s",
      name: "ProCheck",
      loadError: "Unable to load ProCheck results",
      statusTitle: "Status",
      latestTestTitle: "Latest test date",
      moreInfoButton: "More info",
      downloadLatestTestButton: "Download latest test",
      noTestsLabel: "No tests taken",
      pass: "Pass",
      recommendation: "Recommendation",
      productTitle: "Product",
      downloadError: "Unable to export report",
      productType: "Water test",
      exportSuccess: "Sent successfully!",
    },

    proCheckTabs: {
      detailsTab: "Report Details",
      resultsTab: "Test Results",
    },

    proCheckDetailsTab: {
      date: "Date",
      time: "Time",
      timezone: "Timezone",
      email: "Email",
      engineer: "Engineer",
      reason: "Reason for check",
      address: "Address",
      type: "Type",
      filter: "Filter installed",
      boilerNumber: "Boiler serial number ID",
      jobReference: "Job reference",
    },

    ProCheckResultsTab: {
      title: "Test Results",
      summary: "Summary",
      note: "Test Notes",
      waterSample: "Water Sample",
      testCard: "Test Card",
      location: "Location",
      waterTest: "Turbidity",
    },

    ProCheckWaterTest: {
      pass: "Turbidity test passed",
      fail: "Turbidity test failed",
      recommendation: "Turbidity test failed - see available recommendations",
    },

    ProCheckMaps: {
      locationMissing: "ProCheck test location is missing",
    },

    ProCheckSummary: {
      colourTestMissing:
        "Water sample failed visual inspection, presence of corrosion detected. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk. \n MagnaCleanse - Features and Benefits on https://youtu.be/vS92E-Jq1uY",
      molyPassRec: "Inhibitor level ok system is protected, no further action required",
      molyLowRec:
        "Insufficient Inhibitor detected top up required. Use Adey MC1+ (or equivalent). Ensure to circulate for 30 minutes in the system and test again.",
      ironHighRecCopperPass:
        "Iron level is high. If left untreated it can cause performance deterioration and, ultimately, failure. Full system clean is advised.",
      ironHighRec:
        "Corrosion level is high. If left untreated it can cause performance deterioration and, ultimately, failure. Full system clean is advised.",
      ironModerateRec:
        "Corrosion level is moderate, no further action required at this stage",
      ironPassRec: "Corrosion level is low, no further action required",
      ironModerateCopperPresentRec:
        "Iron level is moderate. No further action required at this stage.",
      ironPassCopperPresentRec: "Iron level is low. No further action required",
      phPassRec: "pH level ok - \nno further action required",
      phLowRec:
        "pH level is low indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
      phLowLowMoly:
        'pH level is low indicating poor system health. Lab test is advised or a full system clean. Note: If "Insufficient Inhibitor" also detected, pH may adjust within range following an inhibitor top up. Ensure to circulate for 30 minutes in the system and test again',
      phHighRec:
        "pH level is high indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
      phHighRecLowMoly:
        'pH level is high indicating poor system health. Lab test is advised or a full system clean. Note: If "Insufficient Inhibitor" also detected, pH may adjust within range following an inhibitor top up. Ensure to circulate for 30 minutes in the system and test again',
      copperHighRec:
        "Copper level is high. If left untreated it can cause pitting corrosion of aluminum and steel. Full system clean is advised. ",
      copper_pass: "Copper level ok",
      copper_low:
        "copper level is very low  indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or adeyspares.co.uk",
    },

    proCheckTable: {
      searchBarPlaceholder: "Filter by tester",
      expandedSearchBarPlaceholder: "Search for a heating engineer or property address",
      exportSelectedButtonLabel: "Export/Download Selected",
      resultSelectorCounter: "<result_counter> of 5 reports selected",
    },

    proCheckResultTable: {
      phLevel: "pH Level",
      corrosionLevel: "Corrosion Level",
      inhibitorLevel: "Inhibitor Level",
      copperLevel: "copper Level",
      overallLevel: "Overall",
      recommendation: "Recommendation",
      pass: "Pass",
      fail: "Fail",
      typeHeader: "Type",
      resultsHeader: "Results",
    },

    proCheckExportDialog: {
      title: "Export Selected Report(s)",
      privacySwitchLabel: "Tester Privacy Option",
      privacySwitchHint: "Hide tester personal information",
      rememberEmailHint: "Remember email(s)",
      forgetEmailHint: "Forget email(s)",
      exportButtonLabel: "Send via email",
      emailFieldLabel: "Send To",
      notesFieldLabel: "Notes (Optional)",
      emailFieldPlaceholder: "Email(s)",
      emailFieldHint:
        "You can add multiple email by pressing 'Enter' or clicking on the 'Add' button",
      addEmailButtonLabel: "Add",
      validationError: "Please provide a valid email",
    },

    emailValidator: {
      label: "The email you have provided has several issues",
      empty: "This email address is empty",
      trailingDot: "Trailing dot is present",
      leadingDot: "Leading dot is present",
      spaces: "Extra spaces are present",
      noAt: "This email address does not contain an asperand (@)",
      multipleAt: "This email address contains multiple asperands (@)",
      noLocalPart: "Local part is missing",
      doubleDot: "Multiple successive dots are present",
      unicodeChars: "Email should not contain non-alphanumeric values (Aa-Zz, 0-9)",
      noTopLevelDomain: "Top level domain is missing",
      extraDots: "Unnecessary extra dots",
      extraChars: "Please remove extra characters after the address",
      specialChars: "Email address should not contain any special characters",
      invalidEmail: "This email address is invalid",
    },

    propertyContractorPanel: {
      noContractor: "No contractor",
      title: "Contractor",
    },

    organisationAgentPanel: {
      noAgent: "No ADEY Agent",
      title: "ADEY Agent",
    },

    platformUserDetail: {
      usersLink: "Users",
      noDevelopers: "No developers found",
      userDetailsTab: "User details",
      orgsTab: "Organisations",
      loadError: "Unable to load user details",
      updateError: "Unable to update user details",
      removeError: "Unable to remove user from an organisation",
      firstNameLabel: "First name",
      surnameLabel: "Surname",
      emailLabel: "Email",
      numberLabel: "Contact number",
      userSearch: "Search users",
      inviteUserButton: "Invite a user",
      noOrganisations: "This user is not a part of any organisations",
      noOwner: "No owners found",
      noEngineers: "No heating engineers found",
      noAdmins: "No admins found",
      noUsers: "No users found",
      noPendingInvites: "No pending invites found",
      removedSuccess: "Removed from organisation successfully",
    },

    orgAndRoleTable: {
      nameHeader: "Organisation name",
      numberHeader: "Contact number",
      roleHeader: "User Role",
    },

    platformOrganisationDetail: {
      orgSearch: "Search organisations",
      inviteContractorButton: "Invite a contractor",
      contractorLoadError: "Unable to load contractors",
      contractorsTableTitle: "Contractors",
      associatedOrgsTableTitle: "Associated Organisations",
      invitesTableTitle: "Pending invites",
      noContractors: "No contractors found",
      noAssociatedOrgs: "No associated organisations found",
      noInvites: "No pending invites found",
      organisationRemoveSuccess: "Organisation removed successfully",
      agentAccessGrantError: "Failed to grant ADEY Agent access",
      agentAssignmentError: "Failed to assign ADEY Agent",
    },
    platformAgentDetails: {
      notAgentError: "This organisation is not an agent",
      agentDetailsLoadError: "Unable to load agent details",
      agentUpdateError: "Unable to update the agent",
      managedOrganisationLoadError: "Unable to load managed organisations",
      removeManagedOrganisationError: "Unable to remove managed organisation",
      revokeAgentAccessError: "Unable to revoke agent access",
      removeManagedOrgSuccess: "Managed organisation removed",
      agentUsersLoadError: "Unable to load organisation users",
      removeAgentUserError: "Unable to remove user",
      inviteError: "Unable to invite user",
    },
    platformAgentDetailTabs: {
      agentDetailsTab: "Details",
      managedOrganisationsTab: "Managed Organisations",
      agentUsersTab: "Users",
    },
    adminInviteContractorDialog: {
      titlePlaceholder: "Invite a contractor to %s",
      emailHint: "Email address",
      emailRequiredError: "Please enter a valid email",
      confirmEmailHint: "Confirm email",
      confirmEmailRequired: "Confirm email is required",
      confirmEmailMatchError: "Emails must match",
      inviteButton: "Send invite",
      inviteError: "Unable to send invite",
      inviteSentMessage: "Invite sent successfully",
      descriptionText:
        "Please ensure you are inviting the “Owner/SuperAdmin” email address linked to the nominated contractor account. ",
      contactHint: "For more information, contact ",
      contactEmail: "info@adey.com",
    },

    adminInviteUserDialog: {
      titlePlaceholder: "Invite a user to %s",
      emailHint: "Email address",
      emailRequiredError: "Please enter a valid email",
      roleHint: "User role",
      roleRequired: "User role is required",
      confirmEmailHint: "Confirm email",
      confirmEmailRequired: "Confirm email is required",
      confirmEmailMatchError: "Emails must match",
      inviteButton: "Send invite",
      inviteError: "Unable to send invite",
      inviteSentMessage: "Invite sent successfully",
      developerContext:
        "Developers will be able to view existing properties and create new properties for the organisations that they are a part of, as well as access the Developer Zone",
      adminContext:
        "Organisation admins will be able to view existing properties and create new properties for the organisations that they are a part of. They will also be able to view users and manage relationships with contractors and associated organisations. ",
      heatingEngineerContext:
        "Heating engineers will be able to view existing properties and create new properties for the organisations that they are a part of.",
    },

    deletePropertyHandler: {
      buttonTitle: "Delete property",
      confirmDialogTitlePlaceholder: "Delete %s",
      confirmDialogBody:
        "If you delete this property you will no longer have access to its data. That includes any tests taken and any products fitted at this property.",
      confirmDialogCancelButton: "Cancel",
      confirmDialogDeleteButton: "Delete",
      deleteError: "Unable to delete property",
      propertyDeletedTitle: "Property deleted",
      propertyDeletedBody:
        "This property has been deleted successfully. You will no longer be able to access its data.",
      propertyDeletedDashButton: "Return to dashboard",
    },

    exportBar: {
      title: "Exporting items",
    },

    exportItem: {
      initializing: "Initialising report generation",
      generating: "Generating report",
      generated: "Report exported",
      failure: "Report failed",
      cancelled: "Cancelling export...",
    },

    senseDetailsPageHeaders: {
      details: "Details",
      readings: "Readings",
    },

    senseActivationConfirmationDialog: {
      title: "Device Activation",
      contentActivate:
        "Are you sure you want to activate this device? {{- organisation_name}} will gain access to this device.",
      contentDeactivate:
        "Are you sure you want to deactivate this device? {{- organisation_name}} will lose access to this device until it is reactivated.",
      confirm: "Confirm",
      switchTitle: "Device Activation",
      switchLabelActive: "Device is Active",
      switchLabelDisabled: "Device is Disabled",
    },

    homeZoneSwitchPanel: {
      switchLabel: "HomeZone Notification",
      infoText:
        "Please enter homeowner details before activation of the HomeZone notification",
      title: "HomeZone",
      confirmTurnOff: "Are you sure you want to turn off HomeZone?",
      confirmTurnOn: "Are you sure you want to turn on HomeZone?",
    },

    senseDeviceDetailsTab: {
      productType: "Product Type",
      productNumber: "Product Number",
      serialNumber: "Serial Number",
      identifier: "MAC/IMEI",
      firmwareVersion: "Firmware Version",
      serviceInfo: "Service Information",
      organisation: "Organisation",
      contractor: "Contractor",
      heatingEngineer: "Heating Engineer",
      deviceInstalled: "Device Installed",
      serviceDue: "Service Due",
      warrantyExpiry: "Warranty Expiry",
      notes: "Notes",
      lastReading: "Last reading",
      lastContact: "Last contact",
      lastCleaned: "Last cleaned",
    },

    senseDeviceReadingsTab: {
      title: "Latest Readings",
    },

    senseDeviceReadingsHeaders: {
      reading: "Reading",
      date: "Date",
      time: "Time",
    },

    senseDeviceNames: {
      PROCHECK: "ProCheck",
      PRO3: "Pro3",
      M3SM: "Micro3 SIM",
      M3WF: "Micro3 Wi-Fi",
    },

    systemMessageContainer: {
      title: "System Message",
      hideLabel: "Don’t show again",
    },

    maintenanceDateMessage: {
      content:
        "The maintenance is taking place: \n{{from}} – {{to}} \nWe hope to get back up and running as soon as possible",
    },

    systemDownMessage: {
      title: "SCHEDULED MAINTENANCE",
      content:
        "We’re currently making some exciting changes, which mean you can’t use this portal right now. \nWe hope to get back up and running as soon as possible. \nSorry for any inconvenience caused.",
    },

    scheduledMaintenanceMessage: {
      title: "SCHEDULED MAINTENANCE",
      content:
        "We’re making some exciting changes, which mean that you won’t be able to use this portal for a little while.",
    },

    promotionMessage: {
      title: "PROMOTION",
      content:
        "Sample promotion text. \nThe promotions lasts: \n{{from}} – {{to}} \nSome extra text",
    },

    developerZonePopup: {
      title: "Developer Zone",
      subTitle: "Welcome to the ADEY Sense Developer Zone",
      description:
        "A central hub for developers to access Sense APIs and support information",
      inviteButtonLabel: "Invite Developer",
      redirectButtonLabel: "Get in the Zone!",
    },
    userSegmentation: {
      updateError: "Error submitting segmentation details",
      questionTitle: {
        "question-1": "Which of the following best describes you?",
        "question-2": "Where do you do most of your work?",
      },
      answerDescription: {
        "heating-engineer-independent": "Heating Engineer (Independent)",
        plumber: "Plumber",
        "residential-contractor": "Residential Contractor",
        "residential-contractor-50": "Residential Contractor (50+ engineers)",
        other: "Other",
        residential: "Residential (Private Residence)",
        "new-build": "New Build",
        "social-housing": "Social Housing/HA/LA",
        utility: "Utility",
        "boiler-manufacturer-oem": "Boiler Manufacturer/OEM",
      },
    },
  },
};

export default translations;
