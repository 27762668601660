import { Redirect, Route, Switch } from "react-router";
import AccountSettingsPage from "../../pages/account/AccountSettingsPage/AccountSettingsPage";
import AgentManagedOrganisationList from "../../pages/agents/AgentManagedOrganisationList/AgentManagedOrganisationList";
import AgentUserListPage from "../../pages/agents/AgentUserListPage.tsx/AgentUserListPage";
import AccountActivationPage from "../../pages/auth/AccountActivation/AccountActivationPage";
import TermsAndConditionsDocumentPage from "../../pages/documents/TermsAndConditionsDocumentPage";
import HomezoneUnsubscribePage from "../../pages/homezone/HomezoneUnsubscribePage";
import CurrentOrganisationPage from "../../pages/organisations/current/CurrentOrganisationPage";
import UserDetailPage from "../../pages/users/detail/UserDetailPage";
import {
  ACCOUNT_ACTIVATION_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  CURRENT_ORG_ROUTE,
  DASHBOARD_ROUTE,
  HOMEZONE_UNSUBSCRIBE_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
  USER_DETAIL_ROUTE,
  USER_LIST_ROUTE,
} from "../routes";

/*
 * All of the pages available to Adey Agents.
 */

// ... (other imports)

const AdeyAgentRouter = () => {
  // User has answered both questions, proceed to other routes
  return (
    <Switch>
      {/*Public routes first */}
      <Route exact path={TERMS_AND_CONDITION_ROUTE}>
        <TermsAndConditionsDocumentPage />
      </Route>

      <Route exact path={HOMEZONE_UNSUBSCRIBE_ROUTE}>
        <HomezoneUnsubscribePage />
      </Route>

      {/*Agent Dashboard */}
      <Route exact path={DASHBOARD_ROUTE}>
        <AgentManagedOrganisationList />
      </Route>

      {/*Agent User List */}
      <Route exact path={USER_LIST_ROUTE}>
        <AgentUserListPage />
      </Route>

      {/*Agent User Page */}
      <Route exact path={USER_DETAIL_ROUTE}>
        <UserDetailPage />
      </Route>

      {/* Current Organisation Details */}
      <Route exact path={CURRENT_ORG_ROUTE}>
        <CurrentOrganisationPage />
      </Route>

      <Route exact path={ACCOUNT_SETTINGS_ROUTE}>
        <AccountSettingsPage />
      </Route>

      <Route exact path={ACCOUNT_ACTIVATION_ROUTE}>
        <AccountActivationPage />
      </Route>

      {/* Redirect any unknown routes to the dash */}
      <Route>
        <Redirect to={DASHBOARD_ROUTE} />
      </Route>
    </Switch>
  );
};

export default AdeyAgentRouter;
