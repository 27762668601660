import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Organisation from "../../../../rest/organisation/model/Organisation";
import CollapsiblePanel from "../../../../ui/components/CollapsiblePanel/CollapsiblePanel";
import InviteTable from "../../../../ui/components/InviteTable/InviteTable";
import OrganisationTable from "../../../../ui/components/OrganisationTable/OrganisationTable";
import { EXTRA_LARGE_SPACING, LARGE_SPACING } from "../../../../ui/theme/dimensions";
import { OrganisationRelationship } from "../../../organisations/details/redux/OrganisationDetailState";
import { OrgContractorDetails } from "../redux/AdminOrganisationDetailsState";

interface Props {
  details: OrgContractorDetails;
  selectedOrg: Organisation | undefined;
  onOrgClicked?: (org: Organisation) => void;
  onRemoveClicked?: (
    org: Organisation,
    contractor: Organisation,
    relationship: OrganisationRelationship
  ) => void;
}

const OrganisationContractorTables = (props: Props) => {
  const { t } = useTranslation();

  const spacer = <Box height={2 * EXTRA_LARGE_SPACING} />;

  //Handlers

  //Two separate handlers for contractor and contractee removal.
  //Although they both could removed via any of the two available API calls,
  //Currently, we will be using only one of them.

  const removeContractorHandler = (contractor: Organisation) => {
    //Contractor is the organisation being clicked, contractee is the selected organisation
    if (props.selectedOrg && props.onRemoveClicked) {
      props.onRemoveClicked?.(props.selectedOrg, contractor, "contractor");
    }
  };

  const removeContracteeHandler = (contractee: Organisation) => {
    //Contractor is the seleceted organisation, contractee is the organisation being clicked
    if (props.selectedOrg && props.onRemoveClicked) {
      props.onRemoveClicked?.(contractee, props.selectedOrg, "contractee");
    }
  };

  return (
    <Box paddingX={LARGE_SPACING}>
      <CollapsiblePanel title="platformOrganisationDetail.contractorsTableTitle">
        {props.details.contractors.length === 0 ? (
          <Typography variant="h6">
            {t("platformOrganisationDetail.noContractors")}
          </Typography>
        ) : (
          <OrganisationTable
            organisations={props.details.contractors}
            onOrgClicked={props.onOrgClicked}
            onRemoveClicked={props.onRemoveClicked ? removeContractorHandler : undefined}
            linkedOrg={props.selectedOrg}
            linkType={"contractee"}
          />
        )}
      </CollapsiblePanel>

      {spacer}

      <CollapsiblePanel title="platformOrganisationDetail.associatedOrgsTableTitle">
        {props.details.associatedOrgs.length === 0 ? (
          <Typography variant="h6">
            {t("platformOrganisationDetail.noAssociatedOrgs")}
          </Typography>
        ) : (
          <OrganisationTable
            organisations={props.details.associatedOrgs}
            onOrgClicked={props.onOrgClicked}
            onRemoveClicked={props.onRemoveClicked ? removeContracteeHandler : undefined}
            linkedOrg={props.selectedOrg}
            linkType={"contractor"}
          />
        )}
      </CollapsiblePanel>

      {spacer}

      <CollapsiblePanel title="platformOrganisationDetail.invitesTableTitle">
        {props.details.invites.length === 0 ? (
          <Typography variant="h6">
            {t("platformOrganisationDetail.noInvites")}
          </Typography>
        ) : (
          <InviteTable invites={props.details.invites} />
        )}
      </CollapsiblePanel>

      {spacer}
    </Box>
  );
};

export default OrganisationContractorTables;
