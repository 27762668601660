import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import RootState from "../../../redux/RootState";
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_TOKEN_PARAM,
  RESET_PASSWORD_USERNAME_PARAM,
} from "../../../routes/routes";
import ErrorSnackbarHandler from "../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import { FieldState } from "../../../ui/components/PrimaryTextField";
import extractQueryParam from "../../../utils/extractQueryParam";
import isValidPassword from "../../../utils/isValidPassword";
import ResetPasswordForm from "./components/ResetPasswordForm";
import ResetPasswordSuccessBody from "./components/ResetPasswordSuccessBody";
import ResetPasswordTokenExpiredBody from "./components/ResetPasswordTokenExpiredBody";
import { forgotPasswordSubmit } from "./redux/ForgotPasswordAction";

interface ReduxStateProps {
  isLoading: boolean;
  success: boolean;
  tokenExpired?: boolean;
  error: string | undefined;
}

interface ReduxDispatchProps {
  submitForgotPassword: (email: string, code: string, newPassword: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & RouteComponentProps & WithT;

interface State {
  newPassword: FieldState;
}

class ForgotPasswordSubmitPage extends React.Component<Props, State> {
  state = {
    newPassword: { value: "" },
  };

  render() {
    return (
      <div>
        {this.renderBody()}
        <ErrorSnackbarHandler message={this.props.error} />
      </div>
    );
  }

  // Renderer
  renderBody = () => {
    if (this.props.tokenExpired) {
      return (
        <ResetPasswordTokenExpiredBody
          onResetClicked={this.handleResetClicked}
          onHomepageClicked={this.handleSignInClicked}
        />
      );
    } else if (this.props.success) {
      return <ResetPasswordSuccessBody onSignInClicked={this.handleSignInClicked} />;
    } else {
      return (
        <ResetPasswordForm
          password={this.state.newPassword}
          isLoading={this.props.isLoading}
          handlePasswordChanged={this.handlePasswordChange}
          handleSubmit={this.handleSubmit}
        />
      );
    }
  };

  // Handlers
  handleSubmit = () => {
    if (this.validate()) {
      this.props.submitForgotPassword(
        this.extractEmailParam(),
        this.extractTokenParam(),
        this.state.newPassword.value
      );
    }
  };

  handlePasswordChange = (value: string) => {
    this.setState({
      newPassword: { value: value },
    });
  };

  handleSignInClicked = () => {
    this.props.history.push(LOGIN_ROUTE);
  };

  handleResetClicked = () => {
    this.props.history.push(FORGOT_PASSWORD_ROUTE);
  };

  // MARK: Validation

  validate = (): boolean => {
    const password = this.state.newPassword.value;

    let errorMessage: string | undefined = undefined;
    if (password.trim().length === 0) {
      errorMessage = this.props.t("forgotPasswordSubmit.requiredError");
    } else if (!isValidPassword(password)) {
      errorMessage = this.props.t("forgotPasswordSubmit.invalidError");
    }

    this.setState({
      newPassword: {
        value: password,
        errorText: errorMessage,
      },
    });

    return errorMessage === undefined;
  };

  // Query params

  extractEmailParam = (): string => {
    return extractQueryParam(RESET_PASSWORD_USERNAME_PARAM, this.props);
  };

  extractTokenParam = (): string => {
    return extractQueryParam(RESET_PASSWORD_TOKEN_PARAM, this.props);
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return {
    isLoading: state.forgotPassword.isLoading,
    error: state.forgotPassword.error,
    success: state.forgotPassword.submittedSuccessfully,
    tokenExpired: state.forgotPassword.tokenExpired,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    submitForgotPassword: (email, code, password) =>
      dispatch(forgotPasswordSubmit(email, password, code)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(ForgotPasswordSubmitPage)));
