import CurrentInviteAction, {
  ENTITY_INVITED_TYPE,
  ENTITY_INVITE_ERROR_TYPE,
  INVITE_ENTITY_TYPE,
} from "./CurrentInviteAction";
import InviteOrgUserState from "./CurrentInviteState";

const initialState: InviteOrgUserState = {
  isLoading: false,
  receivedInvite: undefined,
  currentInvitation: undefined,
  error: undefined,
};

const inviteEntityReducer = (
  state = initialState,
  action: CurrentInviteAction
): InviteOrgUserState => {
  switch (action.type) {
    case INVITE_ENTITY_TYPE:
      return {
        isLoading: true,
        receivedInvite: undefined,
        currentInvitation: action.invite,
        error: undefined,
      };
    case ENTITY_INVITED_TYPE:
      return {
        isLoading: false,
        receivedInvite: action.details,
        currentInvitation: undefined,
        error: undefined,
      };

    case ENTITY_INVITE_ERROR_TYPE:
      return {
        isLoading: false,
        receivedInvite: undefined,
        currentInvitation: undefined,
        error: action.error,
      };
    default:
      return state;
  }
};

export default inviteEntityReducer;
