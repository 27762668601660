import {
  HomeZoneAction,
  HOMEZONE_UNSUBSCRIBED_TYPE,
  HOMEZONE_UNSUBSCRIBE_ERROR_TYPE,
  HOMEZONE_UNSUBSCRIBE_TYPE,
} from "./HomeZoneAction";
import HomeZoneState from "./HomeZoneState";

const initialState: HomeZoneState = {
  unsubscribing: undefined,
  unsubscribed: undefined,
  error: undefined,
};

const homeZoneReducer = (state: HomeZoneState = initialState, action: HomeZoneAction) => {
  switch (action.type) {
    case HOMEZONE_UNSUBSCRIBE_TYPE: {
      return { ...initialState, unsubscribing: true };
    }
    case HOMEZONE_UNSUBSCRIBED_TYPE: {
      return { ...state, unsubscribing: false, unsubscribed: true };
    }
    case HOMEZONE_UNSUBSCRIBE_ERROR_TYPE: {
      return { ...state, unsubscribing: false, unsubscribed: false, error: action.error };
    }
    default: {
      return initialState;
    }
  }
};

export default homeZoneReducer;
