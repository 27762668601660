import { Box, Divider } from "@material-ui/core";
import BorderedBox from "../../../../ui/components/BorderedBox";
import SenseDeviceInfoCardModel from "../models/SenseDeviceInfoCardModel";
import { withTranslation, WithTranslation } from "react-i18next";
import { SMALL_SPACING } from "../../../../ui/theme/dimensions";
import BatteryPill from "../../../../ui/components/BatteryPill";
import formatDayTime from "../../../../utils/formatDayTime";
import MagnetiteBlocks from "../../../../ui/components/MagnetiteBlocks";
import LabeledNotification from "./ui/LabeledNotification";

type Props = SenseDeviceInfoCardModel & WithTranslation;

const SenseDeviceInfoCard = (props: Props) => {
  //Strings
  const serviceLabel = props.t("senseDeviceReadingsCard.service");
  const lastContactLabel = props.t("senseDeviceReadingsCard.connection");

  //Dates
  const serviceDueDate = formatDayTime(props.serviceDue as string);

  //Helpers

  return (
    <BorderedBox display="flex" flexDirection="column" minWidth="400px">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        marginBottom={SMALL_SPACING}
        paddingTop={SMALL_SPACING}
      >
        <MagnetiteBlocks magnetiteLevel={props.magnetiteLevel} />
      </Box>

      <Divider />

      <Box
        display="flex"
        flexDirection="row"
        paddingTop={SMALL_SPACING}
        marginBottom={SMALL_SPACING}
      >
        <BatteryPill displayMode batteryStatus={props.batteryLevel} width={100} />
      </Box>

      <Divider />
      <Box display="flex" flexDirection="row" paddingTop={SMALL_SPACING}>
        <LabeledNotification
          productType={props.productType}
          notificationType="contact"
          notifications={props.notifications}
          label={lastContactLabel}
        />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="row" paddingTop={SMALL_SPACING}>
        <LabeledNotification
          productType={props.productType}
          notificationType="service"
          notifications={props.notifications}
          date={`${serviceDueDate.date} ${serviceDueDate.time}`}
          label={serviceLabel}
        />
      </Box>
    </BorderedBox>
  );
};

export default withTranslation()(SenseDeviceInfoCard);
