import PendingInvite from "../model/PendingInvite";

const inviteMatchesSearchText = (
  invite: PendingInvite,
  searchText: string | undefined
): boolean => {
  const search = searchText?.trim()?.toLocaleLowerCase() || "";
  if (search === "") {
    return true;
  }

  const email = invite.email?.toLocaleLowerCase() || "";
  return email.includes(search);
};

export default inviteMatchesSearchText;
