import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import { SECONDARY_COLOR } from "../../../../ui/theme/createMaterialTheme";
import toLocalUTCDateTime from "../../../../utils/toLocalUTCDateTime";
import SystemMessage from "../model/SystemMessage";

interface ExternalProps {
  message: SystemMessage;
}

type Props = ExternalProps;

const SystemMessageCard = (props: Props) => {
  //Translation Hook
  const { t } = useTranslation();

  const getTranslationKey = () => {
    switch (props.message.type) {
      case "promotion":
        return "promotionMessage";
      case "maintenance":
        return "scheduledMaintenanceMessage";
    }
  };

  const getMessageContent = () => {
    if (props.message.reason !== undefined) {
      return props.message.reason;
    } else {
      return t(`${getTranslationKey()}.content`);
    }
  };

  //Translated String
  const title = t(`${getTranslationKey()}.title`);
  const content = getMessageContent();
  const date = t("maintenanceDateMessage.content", {
    from: toLocalUTCDateTime(props.message.start_date, false),
    to: toLocalUTCDateTime(props.message.end_date, false),
    interpolation: { escapeValue: false },
  });

  return (
    <Box display="flex" flexDirection="column">
      <ColoredText
        variant="h6"
        gutterBottom
        paragraph
        align="center"
        textColor={SECONDARY_COLOR}
        style={{ whiteSpace: "pre-line" }}
      >
        {title}
      </ColoredText>
      <Typography
        variant="body2"
        gutterBottom
        paragraph
        align="center"
        style={{ whiteSpace: "pre-line" }}
      >
        {content}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        paragraph
        align="center"
        style={{ whiteSpace: "pre-line" }}
      >
        {date}
      </Typography>
    </Box>
  );
};

export default SystemMessageCard;
