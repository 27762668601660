import { Box } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import EditableField from "../../../properties/PropertyDetailPage/components/EditableField/EditableField";
import HoverableField from "../../../properties/PropertyDetailPage/components/EditableField/components/HoverableField";
import CurrentUserDetails from "../model/CurrentUserDetails";
import { LARGE_SPACING } from "../../../../ui/theme/dimensions";

interface Props extends WithTranslation {
  user: CurrentUserDetails;
  loading: boolean;
  error: string | undefined;

  onUserUpdated: (user: CurrentUserDetails) => void;
}

type PanelItem =
  | "first_name"
  | "last_name"
  | "phone_number"
  | "email"
  | "address_postcode";

interface State {
  openField: PanelItem | undefined;
}

class UserDetailsPanel extends React.Component<Props, State> {
  state = {
    openField: undefined,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.loading && !this.props.loading && this.props.error === undefined) {
      this.setState({ openField: undefined });
    }
  }

  render() {
    const firstNameLabel = this.props.t("userDetailsPanel.firstNameHeader");
    const lastNameLabel = this.props.t("userDetailsPanel.lastNameHeader");
    const contactNumberLabel = this.props.t("userDetailsPanel.contactNumberHeader");
    const emailLabel = this.props.t("userDetailsPanel.emailHeader");
    const postcodeHeader = this.props.t("userDetailsPanel.postcodeHeader");
    // Only enable hover if no field is being edited
    const hoverEnabled = this.state.openField === undefined;

    return (
      <Box paddingX={LARGE_SPACING} minHeight="60vh">
        <EditableField
          label={firstNameLabel}
          text={this.props.user.first_name}
          isEditing={this.state.openField === "first_name"}
          required={true}
          hoverEnabled={hoverEnabled}
          onEditClicked={() => this.handleEditClicked("first_name")}
          onCancelClicked={this.handleCancelClicked}
          onSaveClicked={(value) => this.handleFieldSaved("first_name", value)}
          loading={this.props.loading}
          gutterBottom
        />
        <EditableField
          label={lastNameLabel}
          text={this.props.user.last_name}
          isEditing={this.state.openField === "last_name"}
          hoverEnabled={hoverEnabled}
          required={true}
          onEditClicked={() => this.handleEditClicked("last_name")}
          onCancelClicked={this.handleCancelClicked}
          onSaveClicked={(value) => this.handleFieldSaved("last_name", value)}
          loading={this.props.loading}
          gutterBottom
        />
        <EditableField
          label={contactNumberLabel}
          text={this.props.user.phone_number}
          isEditing={this.state.openField === "phone_number"}
          hoverEnabled={hoverEnabled}
          required={true}
          onEditClicked={() => this.handleEditClicked("phone_number")}
          onCancelClicked={this.handleCancelClicked}
          onSaveClicked={(value) => this.handleFieldSaved("phone_number", value)}
          loading={this.props.loading}
          gutterBottom
        />

        <EditableField
          label={postcodeHeader}
          text={this.props.user.address_postcode}
          isEditing={this.state.openField === "address_postcode"}
          hoverEnabled={hoverEnabled}
          required={true}
          onEditClicked={() => this.handleEditClicked("address_postcode")}
          onCancelClicked={this.handleCancelClicked}
          onSaveClicked={(value) => this.handleFieldSaved("address_postcode", value)}
          loading={this.props.loading}
          gutterBottom
        />
        <HoverableField
          label={emailLabel}
          text={this.props.user.email}
          hoverEnabled={false}
        />
      </Box>
    );
  }

  handleEditClicked = (item: PanelItem) => {
    // Don't allow opening a field if it's already open
    this.setState((prevState) => ({
      openField: prevState.openField || item,
    }));
  };

  handleCancelClicked = () => {
    this.setState({ openField: undefined });
  };

  handleFieldSaved = (field: PanelItem, value: string) => {
    const newUser = { ...this.props.user };

    switch (field) {
      case "phone_number":
        newUser.phone_number = value;
        break;

      case "first_name":
        newUser.first_name = value;
        break;

      case "last_name":
        newUser.last_name = value;
        break;

      case "address_postcode":
        newUser.address_postcode = value;
        break;
    }

    this.props.onUserUpdated(newUser);
  };
}

export default withTranslation()(UserDetailsPanel);
