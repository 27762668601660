import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ColoredText from "../../../../ui/components/ColoredText";
import { SECONDARY_COLOR } from "../../../../ui/theme/createMaterialTheme";

const SystemDownCard = () => {
  //Translation Hook
  const { t } = useTranslation();

  const title = t("systemDownMessage.title");
  const content = t("systemDownMessage.content");

  return (
    <Box display="flex" flexDirection="column">
      <ColoredText
        variant="h6"
        gutterBottom
        paragraph
        align="center"
        textColor={SECONDARY_COLOR}
        style={{ whiteSpace: "pre-line" }}
      >
        {title}
      </ColoredText>
      <Typography
        variant="body2"
        gutterBottom
        paragraph
        align="center"
        style={{ whiteSpace: "pre-line" }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default SystemDownCard;
