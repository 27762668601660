import { Box, Grid } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ArrowIcon from "../../../../ui/assets/ArrowIcon/ArrowIcon";
import AddressLookupDialog from "../../../../ui/components/AddressLookupDialog/AddressLookupDialog";
import CountryPicker from "../../../../ui/components/CountryPicker";
import OutlineButton from "../../../../ui/components/OutlineButton";
import PrimaryButton from "../../../../ui/components/PrimaryButton";
import PrimaryTextField, { FieldState } from "../../../../ui/components/PrimaryTextField";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../../../ui/theme/dimensions";
import EditableFieldLabel from "../../PropertyDetailPage/components/EditableField/components/EditableFieldLabel";
import Address from "../../PropertyDetailPage/model/Address";
import AddPropertyAddressInterface from "../interfaces/AddPropertyAddressInterface";

interface ExternalProps {
  address: Address | undefined;
  onNextClicked: (address: Address) => void;
  lookupEnabled?: boolean;
}

type AddPropertyAddressFormProps = WithT & ExternalProps;

const validationExemptions: string[] = ["line2", "line3"];

interface State extends AddPropertyAddressInterface {
  searchForAddressOpen: boolean;
}

class AddPropertyAddressForm extends React.Component<AddPropertyAddressFormProps, State> {
  state = {
    line1: { value: this.props.address?.line1 || "" } as FieldState,
    line2: { value: this.props.address?.line2 || "" } as FieldState,
    line3: { value: this.props.address?.line3 || "" } as FieldState,
    city: { value: this.props.address?.city || "" } as FieldState,
    postcode: { value: this.props.address?.postcode || "" } as FieldState,
    country: { value: this.props.address?.country || "" } as FieldState,
    searchForAddressOpen: false,
  };

  render() {
    const continueButton = this.props.t("addPropertyDialog.nextButton");
    const line1Hint = this.props.t("addPropertyDialog.addressLine1Hint");
    const line2Hint = this.props.t("addPropertyDialog.addressLine2Hint");
    const postcodeHint = this.props.t("addPropertyDialog.addressPostcodeHint");
    const cityHint = this.props.t("addPropertyDialog.addressCityHint");
    const countryHint = this.props.t("addPropertyDialog.addressCountryHint");

    const lookupEnabled = this.props.lookupEnabled ?? true;

    return (
      <Box paddingTop={DEFAULT_SPACING} paddingBottom={DEFAULT_SPACING}>
        <Grid container spacing={SMALL_SPACING} alignContent="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <EditableFieldLabel
                label={this.props.t("addPropertyDialog.addressSectionTitle")}
              />
              {lookupEnabled && (
                <OutlineButton
                  onClick={this.openAddressLookup}
                  label={this.props.t("addPropertyDialog.addressLookupButton")}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <PrimaryTextField
              {...this.state.line1}
              hint={line1Hint}
              onChange={(v) => this.onFieldChanged("line1", v)}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <PrimaryTextField
              {...this.state.line2}
              hint={line2Hint}
              onChange={(v) => this.onFieldChanged("line2", v)}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <PrimaryTextField
              {...this.state.city}
              disabled={false}
              hint={cityHint}
              onChange={(v) => this.onFieldChanged("city", v)}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <PrimaryTextField
              {...this.state.postcode}
              hint={postcodeHint}
              onChange={(v) => this.onFieldChanged("postcode", v)}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CountryPicker
              {...this.state.country}
              hint={countryHint}
              onChange={(v) => this.onFieldChanged("country", v)}
            />
          </Grid>

          <Grid item xs={12}>
            <PrimaryButton
              onClick={this.onContinuePressed}
              fullWidth
              label={continueButton}
              endIcon={<ArrowIcon />}
            />
          </Grid>
        </Grid>

        <AddressLookupDialog
          open={this.state.searchForAddressOpen}
          onCountryChanged={(value) => this.onFieldChanged("country", value)}
          country={this.state.country.value}
          onAddressLoaded={this.handleAddressLoaded}
          onClose={this.closeAddressLookup}
        />
      </Box>
    );
  }

  //MARK: Handlers
  onFieldChanged = (field: keyof AddPropertyAddressInterface, value: string) => {
    const newState = { ...this.state };
    newState[field] = { value: value };
    this.setState(newState);
  };

  onContinuePressed = () => {
    if (this.validate(validationExemptions)) {
      const address: Address = {
        line1: this.state.line1.value,
        line2: this.state.line2.value,
        line3: this.state.line3.value,
        city: this.state.city.value,
        postcode: this.state.postcode.value,
        country: this.state.country.value,
      };
      this.props.onNextClicked(address);
    }
  };

  openAddressLookup = () => {
    this.setState({ searchForAddressOpen: true });
  };

  closeAddressLookup = () => {
    this.setState({ searchForAddressOpen: false });
  };

  handleAddressLoaded = (address: Address) => {
    this.setState({
      searchForAddressOpen: false,
      line1: { value: address.line1 },
      line2: { value: address.line2 || "" },
      line3: { value: address.line3 || "" },
      country: { value: address.country },
      city: { value: address.city },
      postcode: { value: address.postcode },
    });
  };

  // MARK: Validation

  validate = (exemptions?: string[]): boolean => {
    let validation = true;
    //Typescript complains about the type of keys in state when trying to iterate, hence the following:
    const rawState = this.state as any as Record<string, FieldState>;
    for (let key in rawState) {
      // Ignore the boolean or any future values
      if (typeof rawState[key] !== "object") {
        continue;
      }

      let fieldValue = rawState[key].value?.trim() ?? "";
      if (rawState[key].value === undefined || fieldValue.length === 0) {
        if (!exemptions || !exemptions?.includes(key)) {
          validation = false;
          rawState[key].errorText = this.props.t(`addPropertyAddressForm.${key}Error`);
        }
      }
    }
    this.setState(rawState as any as State);
    return validation;
  };
}

export default withTranslation()(AddPropertyAddressForm);
