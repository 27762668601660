import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../redux/RootState";
import PendingInvite from "../../../../rest/invites/model/PendingInvite";
import PopoverMenuItem from "./PopoverMenuItem";
import { revokeInvite } from "../redux/PendingInviteMenuAction";
import PopoverMenuConfirmItem from "./PopoverMenuConfirmItem";

interface ExternalProps {
  invite: PendingInvite;

  handleSuccess: () => void;
}

interface ReduxStateProps {
  loading: boolean;
  revokeError: string | undefined;
  revokeSuccess: boolean;
}

interface ReduxDispatchProps {
  revokeInvite: () => void;
}

type Props = ExternalProps & ReduxStateProps & ReduxDispatchProps;

interface State {
  errorMessage: string | undefined;
  confirmRevoke: boolean;
}

class RevokeInviteButton extends React.Component<Props, State> {
  state = {
    errorMessage: undefined as string | undefined,
    confirmRevoke: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.revokeError !== prevProps.revokeError) {
      this.setState({ errorMessage: this.props.revokeError });
    }

    if (this.props.revokeSuccess !== prevProps.revokeSuccess) {
      this.props.handleSuccess();
    }
  }

  render() {
    if (this.state.errorMessage !== undefined) {
      return <PopoverMenuItem text={this.state.errorMessage} type="error" />;
    } else if (this.state.confirmRevoke) {
      return (
        <PopoverMenuConfirmItem
          onYesClicked={this.handleRevokeConfirmed}
          onCancelClicked={this.handleRevokeCancelled}
        />
      );
    }

    return (
      <PopoverMenuItem
        text="pendingInviteMenu.revokeInvite"
        type="error"
        onClick={this.handleRevokeClicked}
        loading={this.props.loading}
      />
    );
  }

  handleRevokeClicked = () => {
    this.setState({ confirmRevoke: true });
  };

  handleRevokeConfirmed = () => {
    this.setState({ confirmRevoke: false });
    this.props.revokeInvite();
  };

  handleRevokeCancelled = () => {
    this.setState({ confirmRevoke: false });
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
  loading: state.pendingInviteMenu.revokeInvite.loading,
  revokeError: state.pendingInviteMenu.revokeInvite.error,
  revokeSuccess: state.pendingInviteMenu.revokeInvite.success,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: ExternalProps
): ReduxDispatchProps => ({
  revokeInvite: () => dispatch(revokeInvite(props.invite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevokeInviteButton);
