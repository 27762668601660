import { AxiosInstance, AxiosRequestConfig } from "axios";
import PendingInvite from "../invites/model/PendingInvite";
import Organisation from "../organisation/model/Organisation";
import DataWrapper, { unwrapResponse } from "../utils/DataWrapper";
import OrganisationContractorRepository from "./OrganisationContractorRepository";

class RestOrganisationContractorRepository implements OrganisationContractorRepository {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  fetchOrganisationContractorList = (
    organisationId: string,
    keyword: string | undefined
  ): Promise<Organisation[]> => {
    const endpoint = `organisations/${organisationId}/contractors`;

    const config: AxiosRequestConfig = { params: { keyword: keyword, withMetrics: 1 } };

    return this.axios
      .get<DataWrapper<Organisation[]>>(endpoint, config)
      .then(unwrapResponse);
  };

  fetchOrganisationContractor = (
    organisationId: string,
    contractorId: string
  ): Promise<Organisation> => {
    const endpoint = `organisations/${organisationId}/contractors/${contractorId}`;

    return this.axios.get<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };

  removeContractorFromOrganisation = (
    contractorId: string,
    organisationId: string
  ): Promise<void> => {
    const endpoint = `organisations/${organisationId}/contractors/${contractorId}`;

    return this.axios.delete<DataWrapper<void>>(endpoint).then(unwrapResponse);
  };

  inviteOrganisationContractor = (
    isAdeyAdmin: boolean,
    organisationId: string,
    contractorEmail: string
  ): Promise<PendingInvite> => {
    const adminPrefix = isAdeyAdmin ? "/admin" : "";
    const endpoint = `${adminPrefix}/organisations/${organisationId}/contractors/invite`;
    const params = new URLSearchParams();
    params.append("email", contractorEmail);

    return this.axios
      .post<DataWrapper<PendingInvite>>(endpoint, params)
      .then(unwrapResponse);
  };

  fetchContractingOrganisations = (organisationId: string): Promise<Organisation[]> => {
    const endpoint = `/organisations/${organisationId}/contracting`;

    return this.axios.get<DataWrapper<Organisation[]>>(endpoint).then(unwrapResponse);
  };

  stopContracting = (
    contractorId: string,
    associatedOrgId: string
  ): Promise<Organisation> => {
    const endpoint = `/organisations/${contractorId}/contracting/${associatedOrgId}`;

    return this.axios.delete<DataWrapper<Organisation>>(endpoint).then(unwrapResponse);
  };
}

export default RestOrganisationContractorRepository;
