import { Box, Grid, Typography } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RootState from "../../../../../redux/RootState";
import ArrowIcon from "../../../../../ui/assets/ArrowIcon/ArrowIcon";
import ErrorSnackbarHandler from "../../../../../ui/components/ErrorSnackbar/ErrorSnackbarHandler";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import PrimaryTextField, {
  FieldState,
} from "../../../../../ui/components/PrimaryTextField";
import SecondaryButton from "../../../../../ui/components/SecondaryButton";
import { SMALL_SPACING } from "../../../../../ui/theme/dimensions";
import { adminInviteSubmitted } from "./redux/InviteOrganisationAdminAction";
import InviteOrganisationAdminState from "./redux/InviteOrganisationAdminState";
import validateInviteOrganisationAdminForm from "./validateInviteOrganisationAdminForm";

interface ExternalProps {
  onFormSubmitted: () => void;
  onBackPressed: () => void;
}

type ReduxStateProps = InviteOrganisationAdminState;

interface ReduxDispatchProps {
  submit: (email: string) => void;
}

type InviteOrganisationAdminFormProps = ExternalProps &
  WithT &
  ReduxStateProps &
  ReduxDispatchProps;

export interface InviteOrganisationAdminFormState {
  emailAddress: FieldState;
  confirmEmailAddress: FieldState;
}

class InviteOrganisationAdminForm extends React.Component<
  InviteOrganisationAdminFormProps,
  InviteOrganisationAdminFormState
> {
  state = {
    emailAddress: { value: "" },
    confirmEmailAddress: { value: "" },
  };

  componentDidUpdate(prevProps: InviteOrganisationAdminFormProps) {
    if (this.props.inviteSent && !prevProps.inviteSent) {
      this.props.onFormSubmitted();
    }
  }

  render() {
    const title = this.props.t("inviteOrganisationAdminForm.title");
    const emailHint = this.props.t("inviteOrganisationAdminForm.emailHint");
    const confirmEmailHint = this.props.t("inviteOrganisationAdminForm.confirmEmailHint");
    const sendInviteButton = this.props.t("inviteOrganisationAdminForm.sendInviteButton");
    const backButton = this.props.t("inviteOrganisationAdminForm.backButton");

    return (
      <Grid container direction="column" spacing={SMALL_SPACING}>
        <Grid item>
          <Typography variant="h2" align="center">
            <strong>{title.toUpperCase()}</strong>
          </Typography>
        </Grid>

        <Grid item />

        <Grid item>
          <PrimaryTextField
            type="email"
            {...this.state.emailAddress}
            hint={emailHint}
            onChange={this.handleEmailChanged}
            fullWidth
            disabled={this.props.isLoading}
          />
        </Grid>

        <Grid item>
          <PrimaryTextField
            type="email"
            {...this.state.confirmEmailAddress}
            hint={confirmEmailHint}
            onChange={this.handleConfirmEmailChanged}
            fullWidth
            disabled={this.props.isLoading}
          />
        </Grid>

        <Grid item />

        <Grid item>
          <PrimaryButton
            endIcon={<ArrowIcon />}
            label={sendInviteButton}
            onClick={this.handleSendInviteClicked}
            isLoading={this.props.isLoading}
            fullWidth
          />
        </Grid>

        <Grid item>
          <Box display="flex" justifyContent="center">
            <SecondaryButton label={backButton} onClick={this.props.onBackPressed} />
          </Box>
        </Grid>

        <ErrorSnackbarHandler message={this.props.error} />
      </Grid>
    );
  }

  // MARK: Handlers

  handleEmailChanged = (value: string) => {
    this.setState({ emailAddress: { value: value } });
  };

  handleConfirmEmailChanged = (value: string) => {
    this.setState({ confirmEmailAddress: { value: value } });
  };

  handleSendInviteClicked = () => {
    const result = validateInviteOrganisationAdminForm(this.props.t, this.state);
    if (result.isValid) {
      this.props.submit(this.state.emailAddress.value);
    } else {
      this.setState(result.newState);
    }
  };
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return state.inviteOrganisation;
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  return {
    submit: (email) => dispatch(adminInviteSubmitted(email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InviteOrganisationAdminForm));
