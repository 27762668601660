import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../ui/components/ConfirmDialog";

interface ExternalProps {
  open: boolean;
  contractorName: string;
  contracteeName: string;

  onCancelClicked: () => void;
  onStopContractingConfirmed: () => void;
}

type Props = ExternalProps & WithT;

const AdminRemoveContracteeDialog = (props: Props) => {
  const title = props.t("adminRemoveContracteeDialog.title");
  const bodyPlaceholder = props.t("adminRemoveContracteeDialog.bodyPlaceholder");
  const stopContractingButton = props.t(
    "adminRemoveContracteeDialog.stopContractingButton"
  );

  const body = bodyPlaceholder
    .replaceAll("<contractee_org>", props.contracteeName)
    .replaceAll("<contractor_org>", props.contractorName);

  return (
    <ConfirmDialog
      open={props.open}
      title={title}
      body={body}
      confirmButton={stopContractingButton}
      onCancelClicked={props.onCancelClicked}
      onConfirmed={props.onStopContractingConfirmed}
    />
  );
};

export default withTranslation()(AdminRemoveContracteeDialog);
