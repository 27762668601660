import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../../../ui/components/PrimaryButton";
import ConfirmStopContractingDialog from "./ConfirmStopContractingDialog";
import StopContractingCompletedDialog from "./StopContractingCompletedDialog";

interface ExternalProps {
  organisationName: string;
  loading: boolean;
  orgRemoved: boolean;

  stopContractingConfirmed: () => void;
  returnToListClicked: () => void;
}

type Props = ExternalProps & WithT;

interface State {
  confirmOpen: boolean;
}

class StopContractingButton extends React.Component<Props, State> {
  state = { confirmOpen: false };

  render() {
    const labelPlaceholder = this.props.t("stopContractingButton.title");
    const label = labelPlaceholder.replace("%s", this.props.organisationName);

    return (
      <div>
        <PrimaryButton
          size="small"
          label={label}
          onClick={this.clickHandler}
          isLoading={this.props.loading}
        />

        <ConfirmStopContractingDialog
          open={this.state.confirmOpen}
          organisationName={this.props.organisationName}
          onStopContractingConfirmed={this.confirmHandler}
          onCancelClicked={this.cancelHandler}
        />

        <StopContractingCompletedDialog
          open={this.props.orgRemoved}
          organisationName={this.props.organisationName}
          returnToListClicked={this.props.returnToListClicked}
        />
      </div>
    );
  }

  confirmHandler = () => {
    this.setState({ confirmOpen: false });
    this.props.stopContractingConfirmed();
  };

  clickHandler = () => {
    this.setState({ confirmOpen: true });
  };

  cancelHandler = () => {
    this.setState({ confirmOpen: false });
  };
}

export default withTranslation()(StopContractingButton);
