import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LARGE_SPACING, SMALL_SPACING } from "../../../../../../ui/theme/dimensions";
import DeviceStatus, {
  colorForDeviceStatus,
  titleForDeviceStatus,
} from "../../../model/DeviceStatus";

// Setting a fixed width to the text is fine here
// It'll get bigger in height.
const textWidth = 90;

interface Props extends WithTranslation {
  status: DeviceStatus;
  isChecked: boolean;
  startIcon?: IconProp;
  onCheckChanged: (status: DeviceStatus, checked: boolean) => void;
}

const ProductStatusCheckbox = (props: Props) => {
  const onChange = (_: any, isChecked: boolean) => {
    props.onCheckChanged(props.status, isChecked);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginY={1}
      justifyContent="space-between"
      style={{ marginRight: SMALL_SPACING }}
    >
      <Box display="flex" alignItems="center">
        {props.startIcon ? (
          <Box minWidth={"20px"}>
            <FontAwesomeIcon
              icon={props.startIcon}
              color={colorForDeviceStatus(props.status)}
            />
          </Box>
        ) : null}
        <Box width={LARGE_SPACING} />

        <Typography style={{ width: textWidth }}>
          {titleForDeviceStatus(props.status, props.t)}
        </Typography>
      </Box>
      <Checkbox checked={props.isChecked} onChange={onChange} />
    </Box>
  );
};

export default withTranslation()(ProductStatusCheckbox);
