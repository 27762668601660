import React from "react";

import TEntityToMove from "../../../pages/admin/AdminMoveEntity/types/TEntityToMove";
import TMoveDestination from "../../../pages/admin/AdminMoveEntity/types/TMoveDestination";
import { Trans, useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { DEFAULT_SPACING, SMALL_SPACING } from "../../theme/dimensions";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import { Check } from "@material-ui/icons";

interface IProps<T> {
  destination: TMoveDestination;
  entityType: TEntityToMove;
  selectedItems: T[];

  getOrigin?: () => string;
  getTypeName?: () => string;
  loading?: boolean;
  getSelectedItemName: (item: T) => string;
  handleBackPressed: () => void;
  handleNextPressed: () => void;
}

const MoveEntityWarningPanel = <T extends unknown>(props: IProps<T>) => {
  //Hooks
  const { t } = useTranslation();

  //Strings
  const confirmButton = t("buttons.confirm");
  const backButton = t("buttons.back");

  //Helpers
  const mergeItemNames = () => {
    const itemNames = [...props.selectedItems].map((item, index) => {
      if (index === 0 && props.getTypeName) {
        return `${props.getTypeName()} ${props.getSelectedItemName(item)}`;
      }
      return `${props.getSelectedItemName(item)}`;
    });

    return itemNames;
  };

  const getEntityDescription = () => {
    switch (props.entityType) {
      case "property":
        return (
          <Trans
            t={t}
            i18nKey={"moveEntityWarningPanel.descriptionProperty"}
            values={{
              items: mergeItemNames(),
              destination: props.destination.description,
            }}
          />
        );

      case "device":
      case "procheck":
        return (
          <Trans
            t={t}
            i18nKey={"moveEntityWarningPanel.descriptionOther"}
            values={{
              deviceType: mergeItemNames(),
              origin: props.getOrigin ? props.getOrigin() : "",
              destination: props.destination.description,
            }}
          />
        );
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
      <Typography align="center">{getEntityDescription()}</Typography>

      <Box marginTop={DEFAULT_SPACING} />
      <PrimaryButton
        disabled={props.loading}
        label={confirmButton}
        endIcon={<Check />}
        isLoading={props.loading}
        onClick={props.handleNextPressed}
      />
      <Box marginTop={SMALL_SPACING} />
      <SecondaryButton
        disabled={props.loading}
        label={backButton}
        onClick={props.handleBackPressed}
      />
    </Box>
  );
};

export default MoveEntityWarningPanel;
