import PermissionResponse from "../../../../rest/permissions/model/PermissionResponse";
import PermissionsRepository from "../../../../rest/permissions/PermissionsRepository";

export interface OrganisationPermissions {
  canInviteContractors: boolean;
  canViewActiveOrganisationUsers: boolean;
  canRemoveActiveOrganisationUsers: boolean;
  canViewOrganisationContractors: boolean;
  canRemoveOrganisationContractors: boolean;
  canEditOrganisationDetails: boolean;
  canCreateProperties: boolean;
  canDeleteProperties: boolean;
  canAssignContractorsToProperties: boolean;
}

class OrganisationPermissionsService {
  constructor(private permissionsRepository: PermissionsRepository) {}

  getPermissionsForRole = (roleId: string): Promise<OrganisationPermissions> => {
    return this.permissionsRepository
      .fetchPermissionsForRole(roleId)
      .then(this.permissionsFromResponse);
  };

  private permissionsFromResponse = (
    response: PermissionResponse[]
  ): OrganisationPermissions => {
    const canInviteContractors = this.listContainsPermission(
      response,
      "organisation.contractors.invite"
    );

    const canViewContractors = this.listContainsPermission(
      response,
      "organisation.contractors.view-all"
    );

    const canRemoveContractors = this.listContainsPermission(
      response,
      "organisation.contractors.delete"
    );

    const canViewUsers = this.listContainsPermission(
      response,
      "organisation.users.view-all"
    );
    const canRemoveUsers = this.listContainsPermission(
      response,
      "organisation.users.delete"
    );
    const canEditOrganisationDetails = this.listContainsPermission(
      response,
      "organisation.update"
    );
    const canCreateProperties = this.listContainsPermission(
      response,
      "organisation.property.create"
    );
    const canAssignContractorsToProperties = this.listContainsPermission(
      response,
      "organisation.property.contractor.update"
    );
    const canDeleteProperties = this.listContainsPermission(
      response,
      "organisation.property.delete"
    );

    return {
      canInviteContractors: canInviteContractors,
      canRemoveActiveOrganisationUsers: canRemoveUsers,
      canRemoveOrganisationContractors: canRemoveContractors,
      canViewActiveOrganisationUsers: canViewUsers,
      canViewOrganisationContractors: canViewContractors,
      canEditOrganisationDetails: canEditOrganisationDetails,
      canCreateProperties: canCreateProperties,
      canDeleteProperties: canDeleteProperties,
      canAssignContractorsToProperties: canAssignContractorsToProperties,
    };
  };

  private listContainsPermission = (
    permissions: PermissionResponse[],
    name: string
  ): boolean => {
    return permissions.filter((p) => p.name === name).length > 0;
  };
}

export default OrganisationPermissionsService;
