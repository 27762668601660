import { put, select, take, takeEvery } from "redux-saga/effects";
import {
  LogInStartAction,
  logInError,
  LOG_IN_START_TYPE,
  LOG_OUT_TYPE,
  CHECK_LOGIN_STATUS_TYPE,
  checkLoginStatus,
  loginStateChanged,
  LOGIN_STATUS_CHANGED_TYPE,
  LoginStateChangedAction,
  LOG_IN_ERROR_TYPE,
  setPasswordResetRequired,
  segmentationQuestionsLoaded,
  LOAD_SEGMENTATION_QUESTIONS_TYPE,
  loadSegmentationQuestions,
} from "./LoginAction";
import AuthService from "../service/AuthService";
import LoadingBackgroundService from "../../../loading/service/LoadingBackgroundService";
import getDependency from "../../../../redux/utils/getDependency";
import UserRepository from "../../../../rest/user/UserRepository";
import UserDetails from "../../../../rest/user/model/UserDetails";
import { userNotSetup } from "../../AccountSetup/redux/AccountSetupActions";
import isUserNotConfirmedError from "../../ResendActivationEmail/service/isUserNotConfirmedError";
import { accountNotActivated } from "../../ResendActivationEmail/redux/ResendActivationEmailAction";
import { fetchPendingInvites } from "../../../invites/redux/PendingInviteAction";
import getErrorMessage from "../../../../errors/messages/getErrorMessage";
import { getProfileIncompleteError } from "../utils/ProfileIncompleteError";
import { loadCountryList } from "../../../countries/redux/CountryAction";
import isPasswordResetByAdmin from "../utils/isPasswordResetByAdmin";
import { CognitoUser } from "@aws-amplify/auth";
import ProClubRepository from "../../../../rest/proclub/ProClubRepository";
import isProCheckUser from "../utils/isProCheckUser";
import {
  getSystemMessagesAction,
  GET_SYSTEM_MESSAGES_ERROR,
  GET_SYSTEM_MESSAGES_SUCCESS,
  SYSTEM_DOWN,
} from "../../SystemMessagesDialogs/redux/SystemMessageAction";
import RootState from "../../../../redux/RootState";
import AuthProxyService from "../service/AuthProxyService";
import IPasswordResetRequiredResponse from "../service/interfaces/IPasswordResetRequiredResponse";
import { currentUserLoaded } from "../../../account/AccountSettingsPage/redux/CurrentUserAction";
import CurrentUserDetails from "../../../account/AccountSettingsPage/model/CurrentUserDetails";
import SegmentationQuestion from "../../../../rest/user/model/SegmentationQuestion";

function* loginSagas() {
  yield takeEvery(LOG_IN_START_TYPE, onLogInAction);
  yield takeEvery(LOG_OUT_TYPE, onLogOutAction);
  yield takeEvery(CHECK_LOGIN_STATUS_TYPE, onCheckLoginStatusAction);
  yield takeEvery(LOAD_SEGMENTATION_QUESTIONS_TYPE, questionsSaga);
  yield takeEvery(LOGIN_STATUS_CHANGED_TYPE, setLoginBackground);
  yield takeEvery(LOGIN_STATUS_CHANGED_TYPE, loginStatusChangedSaga);
  yield takeEvery(LOG_IN_ERROR_TYPE, logoutWithCognito);
}

export function* questionsSaga() {
  const userRepo: UserRepository = yield getDependency("userRepository");
  try {
    const questions: SegmentationQuestion[] = yield userRepo.getSegmentationQuestions();
    yield put(segmentationQuestionsLoaded(questions));
  } catch (e) {
    yield put(logInError("Failed to load survey"));
    yield put(loginStateChanged("logged_out"));
  }
}

export function* onLogInAction(action: LogInStartAction) {
  try {
    // Get the dependencies from the context
    const authService: AuthService = yield getDependency("authService");

    const cognitoUserResponse: CognitoUser = yield authService.signIn(
      action.email,
      action.password
    );

    if ((cognitoUserResponse as Object).hasOwnProperty("challengeName")) {
      if ((cognitoUserResponse as any)["challengeName"] === "NEW_PASSWORD_REQUIRED") {
        yield setPasswordResetRequired();
        return;
      }
    } else {
      yield put(checkLoginStatus());
    }
  } catch (e) {
    const proClubService: ProClubRepository = yield getDependency("proClubRepository");
    const authProxyService: AuthProxyService = yield getDependency("authProxyService");

    const proCheckUser: boolean = yield isProCheckUser(
      proClubService,
      action.email,
      action.password
    );

    const passwordResetRequired: IPasswordResetRequiredResponse =
      yield authProxyService.checkPasswordResetRequired(action.email);

    if (isUserNotConfirmedError(e)) {
      yield put(accountNotActivated(action.email));
    }

    if (
      isPasswordResetByAdmin(e) ||
      (!!passwordResetRequired && passwordResetRequired.password_reset_required)
    ) {
      yield put(setPasswordResetRequired(proCheckUser));
      return;
    }

    const message = getErrorMessage(e, "loginPage.loginError");
    yield put(logInError(message));
  }
}

export function* onLogOutAction() {
  yield put(loginStateChanged("logged_out"));
}

export function* onCheckLoginStatusAction() {
  try {
    const authService: AuthService = yield getDependency("authService");
    const userRepo: UserRepository = yield getDependency("userRepository");

    const token: string | undefined = yield authService.getCurrentToken();
    const rootState: RootState = yield select();

    yield put(getSystemMessagesAction(undefined, false));
    yield take([GET_SYSTEM_MESSAGES_ERROR, GET_SYSTEM_MESSAGES_SUCCESS, SYSTEM_DOWN]);

    if (token === undefined || rootState.systemMessages.systemDown) {
      yield put(loginStateChanged("logged_out"));
      return;
    }

    // Now we have an auth token, check for pending invites
    yield put(fetchPendingInvites());

    // This is the best place to get the list of countries available, so we do it there
    yield put(loadCountryList());

    // Fetch the user details - if we're not set up we'll get a 422
    // error here.
    const userDetails: UserDetails = yield userRepo.getMe();
    // Load segmentation questions
    yield put(loadSegmentationQuestions());

    // Additionally, update current user details store
    yield put(currentUserLoaded(userDetails as CurrentUserDetails));

    if (!userDetails.onboarding_complete) {
      //Segmentation is handled differently - if we reach this point, the organisation & other details are already present
      yield put(
        userNotSetup({
          hasOrganisation: true,
          hasPersonalDetails: true,
          hasFinishedSegmentation: false,
        })
      );
    } else {
      yield put(loginStateChanged("logged_in", userDetails.id));
    }
  } catch (e) {
    const profileIncompleteError = getProfileIncompleteError(e);
    if (profileIncompleteError !== undefined) {
      yield put(
        userNotSetup({
          hasOrganisation: profileIncompleteError.hasOrganisation,
          // The profile & terms are on the same API call, so need to be handled together
          hasPersonalDetails:
            profileIncompleteError.profileComplete &&
            profileIncompleteError.termsAccepted,
          hasFinishedSegmentation: false,
        })
      );
    } else {
      const message = getErrorMessage(e, "loginPage.loginError");
      // If there's an error default to being logged out.
      yield put(logInError(message));
      yield put(loginStateChanged("logged_out"));
    }
  }
}

function* setLoginBackground(action: LoginStateChangedAction) {
  try {
    if (action.loginStatus === "logged_in" || action.loginStatus === "logged_out") {
      const loadingBackgroundService: LoadingBackgroundService = yield getDependency(
        "loadingBackgroundService"
      );

      loadingBackgroundService?.setBackgroundType(action.loginStatus);
    }
  } catch (e) {
    // Fail silently here. It's not worth crashing for.
    console.error("Error setting loading background", e);
  }
}

function* loginStatusChangedSaga(action: LoginStateChangedAction) {
  if (action.loginStatus === "logged_out") {
    yield logoutWithCognito();
  } else if (action.loginStatus === "logged_in") {
    yield refreshAuthLanguage();
  }
}

function* logoutWithCognito() {
  const authService: AuthService = yield getDependency("authService");
  yield authService.signOut();
}

function* refreshAuthLanguage() {
  try {
    const authService: AuthService = yield getDependency("authService");
    yield authService.refreshLanguage();
  } catch (e) {
    // Unable to refresh language
    // this isn't a big deal, so just fail silently.
    console.error(e);
  }
}

export default loginSagas;
