import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { LARGE_SPACING } from "../../../../ui/theme/dimensions";

const PropertyListEmptyMessage = () => {
  return (
    <Box padding={LARGE_SPACING}>
      <Typography variant="h2" color="textPrimary" align="center">
        <Trans>propertyListPage.emptyMessage</Trans>
      </Typography>
    </Box>
  );
};

export default PropertyListEmptyMessage;
