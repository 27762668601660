import InviteRepository from "../../../rest/invites/InviteRepository";
import PendingInvite, {
  ContractorInvitation,
  OrganisationUserInvitation,
} from "../../../rest/invites/model/PendingInvite";

export interface AllPendingInvites {
  contractor: ContractorInvitation[];
  user: OrganisationUserInvitation[];
}

class PendingInviteService {
  constructor(private inviteRepository: InviteRepository) {}

  fetchAllPendingInvites = async (): Promise<AllPendingInvites> => {
    const invites: PendingInvite[] = await this.inviteRepository.fetchPendingInvites();

    const userInvites = invites
      .filter((i) => i.type === "user")
      .map((i) => i as OrganisationUserInvitation);
    const contractorInvites = invites
      .filter((i) => i.type === "contractor")
      .map((i) => i as ContractorInvitation);

    return {
      user: userInvites,
      contractor: contractorInvites,
    };
  };
}

export default PendingInviteService;
