import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { LARGE_SPACING } from "../../../theme/dimensions";

const buttonStyle: any = {
  justifyContent: "flex-start",
  textTransform: "none",
  padding: LARGE_SPACING,
  width: "100%",
};

interface Props {
  text: string;
  type?: "success" | "error";
  loading?: boolean;
  onClick?: () => void;
}

const PopoverMenuItem = (props: Props) => {
  const textColor = props.type === "error" ? "primary" : "textPrimary";

  let endIcon: ReactNode | null = null;
  if (props.loading) {
    endIcon = <CircularProgress size={20} color="secondary" />;
  } else if (props.type === "success") {
    endIcon = <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />;
  }

  return (
    <Button
      style={buttonStyle}
      onClick={props.onClick}
      disabled={props.onClick === undefined}
    >
      <Typography align="left" color={textColor}>
        <Trans>{props.text}</Trans>
      </Typography>

      {endIcon ? <Box flexGrow={1} /> : undefined}

      {endIcon}
    </Button>
  );
};

export default PopoverMenuItem;
