import React from "react";
import AlertDialog, {
  AlertDialogAction,
} from "../../../../../../ui/components/AlertDialog";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  homeZoneCurrentlyEnabled: boolean;

  onConfirmed: () => void;
  onClose: () => void;
};

const HomeZoneConfirmationDialog = (props: Props) => {
  const { t } = useTranslation();

  const title = t("homeZoneSwitchPanel.title");
  const body = props.homeZoneCurrentlyEnabled
    ? t("homeZoneSwitchPanel.confirmTurnOff")
    : t("homeZoneSwitchPanel.confirmTurnOn");
  const yes = t("buttons.yes");
  const no = t("buttons.no");

  const actions: AlertDialogAction[] = [
    { title: no, onClick: props.onClose },
    { title: yes, onClick: props.onConfirmed },
  ];

  return (
    <AlertDialog
      open={props.open}
      title={title}
      body={body}
      actions={actions}
      onClose={props.onClose}
    />
  );
};

export default HomeZoneConfirmationDialog;
