import AdminOrganisationDetailsAction from "./AdminOrganisationDetailsAction";
import AdminOrganisationDetailsState from "./AdminOrganisationDetailsState";

const initialState: AdminOrganisationDetailsState = {
  userDetails: undefined,
  contractorDetails: undefined,
  error: undefined,
  isInvitingContractor: false,
  isInvitingUser: false,
  isRemovingUserFromOrg: false,
  isRemovingLinkedOrg: false,
  grantingAgentAccess: false,
  agentAccessGranted: false,
  assigningAgent: false,
  agentAssigned: false,
};

const adminOrganisationDetailsReducer = (
  state = initialState,
  action: AdminOrganisationDetailsAction
): AdminOrganisationDetailsState => {
  switch (action.type) {
    case "LOAD_CONTRACTORS_TYPE":
      return {
        ...initialState,
      };

    case "LOAD_ORG_USERS_TYPE":
      return {
        ...initialState,
      };

    case "CONTRACTORS_LOADED_TYPE":
      return {
        ...initialState,
        userDetails: state.userDetails,
        contractorDetails: {
          contractors: action.contractors,
          associatedOrgs: action.associatedOrgs,
          invites: action.pendingInvites,
        },
        error: undefined,
        isInvitingContractor: false,
      };
    case "ORG_USERS_LOADED_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: {
          users: action.users,
          invites: action.pendingInvites,
        },
        error: undefined,
        isInvitingUser: false,
        userRemovedFromOrg: state.userRemovedFromOrg,
      };
    case "CONTRACTORS_LOAD_ERROR_TYPE":
      return {
        ...initialState,
        contractorDetails: undefined,
        userDetails: state.userDetails,
        error: undefined,
        isInvitingContractor: false,
      };
    case "ORG_USERS_LOAD_ERROR_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: undefined,
        error: undefined,
        isInvitingUser: false,
      };

    case "INVITE_CONTRACTOR_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: state.userDetails,
        isInvitingContractor: true,
        error: undefined,
      };

    case "INVITE_USER_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: state.userDetails,
        isInvitingUser: true,
        error: undefined,
      };

    case "INVITE_CONTRACTOR_SUCCESS_TYPE":
      const details = state.contractorDetails
        ? {
            ...state.contractorDetails,
            invites: [...state.contractorDetails.invites, action.invite],
          }
        : undefined;

      return {
        ...initialState,
        userDetails: state.userDetails,
        contractorDetails: details,
        isInvitingContractor: false,
        invitedContractor: action.invite,
        error: undefined,
      };

    case "INVITE_USER_SUCCESS_TYPE":
      const users = state.userDetails
        ? {
            ...state.userDetails,
            invites: [...state.userDetails.invites, action.invite],
          }
        : undefined;

      return {
        ...initialState,
        userDetails: users,
        contractorDetails: state.contractorDetails,
        isInvitingUser: false,
        invitedUser: action.invite,
        error: undefined,
      };

    case "INVITE_CONTRACTOR_ERROR_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: state.userDetails,
        isInvitingContractor: false,
        error: action.error,
      };

    case "INVITE_USER_ERROR_TYPE":
      return {
        ...initialState,
        contractorDetails: state.contractorDetails,
        userDetails: state.userDetails,
        isInvitingUser: false,
        error: action.error,
      };
    case "USER_REMOVAL_INIT_TYPE":
      return {
        ...state,
        isRemovingUserFromOrg: true,
      };

    case "REMOVE_USER_SUCCESS_TYPE":
      return {
        ...state,
        isRemovingUserFromOrg: false,
        userRemovedFromOrg: true,
      };
    case "REMOVE_USER_FAILURE_TYPE":
      return {
        ...state,
        isRemovingUserFromOrg: true,
        userRemovedFromOrg: false,
      };
    case "LINKED_ORG_REMOVAL_INIT_TYPE":
      return {
        ...state,
        isRemovingUserFromOrg: true,
      };

    case "REMOVE_LINKED_ORG_SUCCESS_TYPE":
      return {
        ...state,
        isRemovingLinkedOrg: false,
        isLinkedOrgRemoved: true,
      };
    case "REMOVE_LINKED_ORG_FAILURE_TYPE":
      return {
        ...state,
        isRemovingLinkedOrg: true,
        isLinkedOrgRemoved: false,
      };
    case "AGENT_ACCESS_GRANTED":
      return {
        ...state,
        grantingAgentAccess: false,
        agentAccessGranted: true,
      };
    case "GRANT_AGENT_ACCESS":
      return {
        ...state,
        grantingAgentAccess: true,
        agentAccessGranted: false,
      };
    case "GRANT_AGENT_ACCESS_ERROR":
      return {
        ...state,
        grantingAgentAccess: false,
        agentAccessGranted: false,
        error: action.error,
      };
    case "AGENT_ASSIGNED":
      return {
        ...state,
        assigningAgent: false,
        agentAssigned: true,
      };
    case "ASSIGN_AGENT":
      return {
        ...state,
        assigningAgent: true,
        agentAssigned: false,
      };
    case "AGENT_ASSIGN_ERROR":
      return {
        ...state,
        assigningAgent: false,
        agentAssigned: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default adminOrganisationDetailsReducer;
