import ProCheckRecType from "../../../../rest/procheck/model/ProCheckRecType";
import { TestOutcome } from "../../../../rest/procheck/model/ProCheckResultDetails";

class ProCheckTestOutcomeProvider {
  static getPhOutcome = (recs: ProCheckRecType[]): TestOutcome => {
    return recs.includes("ph_pass") ? "pass" : "fail";
  };

  static getCopperOutcome = (recs: ProCheckRecType[]): TestOutcome => {
    return recs.includes("copper_pass") ? "pass" : "fail";
  };


  static getMolyOutcome = (recs: ProCheckRecType[]): TestOutcome => {
    return recs.includes("molybdate_pass") ? "pass" : "fail";
  };

  static getIronOutcome = (recs: ProCheckRecType[]): TestOutcome => {
    return recs.includes("iron_pass") || recs.includes("iron_moderate") ? "pass" : "fail";
  };
}

export default ProCheckTestOutcomeProvider;
