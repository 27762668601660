import { Breadcrumbs, Chip, Theme, Typography, withStyles } from "@material-ui/core";
import React from "react";

export interface RailLink {
  key: string;
  name: string;
  onClick: (key: string) => void;
}

interface Props {
  links: RailLink[];
  currentPageTitle: string;
}

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[800],
    fontWeight: "bolder",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))(Chip) as typeof Chip;

const NavigationRail = (props: Props) => {
  const breadcrumbs = props.links.map((link) => (
    <StyledBreadcrumb
      label={link.name}
      key={link.key}
      onClick={() => link.onClick(link.key)}
    />
  ));

  return (
    <Breadcrumbs>
      {breadcrumbs}

      <Typography>{props.currentPageTitle}</Typography>
    </Breadcrumbs>
  );
};

export default NavigationRail;
