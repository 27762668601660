import { Box, Divider } from "@material-ui/core";
import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import Property from "../../../../rest/properties/model/Property";
import DataRangePicker from "../../../../ui/components/DateRangePicker/DataRangePicker";
import ErrorDisplay from "../../../../ui/components/ErrorDisplay";
import SearchBar from "../../../../ui/components/SearchBar";
import Spinner from "../../../../ui/components/Spinner";
import {
  DEFAULT_SPACING,
  EXTRA_LARGE_SPACING,
  SMALL_SPACING,
} from "../../../../ui/theme/dimensions";
import AddPropertyDialog from "../../AddPropertyDialog/AddPropertyDialog";
import PropertyTableItem from "../model/PropertyTableItem";
import mapToPropertyTableItem from "../utils/mapToPropertyTableItem";
import AddPropertyButton from "./AddPropertyButton";
import ExportReportButton from "./ExportReportButton";
import StatusFilterMenu from "./FilterMenu/StatusFilterMenu";
import PropertyListEmptyMessage from "./PropertyListEmptyMessage";
import PropertyTable from "./PropertyTable";
import TablePageIndicator from "./TablePageIndicator";
import IFilterablePropertyList from "../interfaces/IFilterablePropertyList";
import SelectedPropertiesPanel from "./SelectedPropertiesPanel";
import MovePropertiesHandler from "./MovePropertiesHandler/MovePropertiesHandler";

const tableBottomSpacing = EXTRA_LARGE_SPACING * 2;

interface State {
  isAddPropertyDialogOpen: boolean;
}

type Props = IFilterablePropertyList & WithT;

class FilterablePropertyList extends React.Component<Props, State> {
  state = {
    isAddPropertyDialogOpen: false,
  };

  render() {
    return (
      <Box display="flex" flexDirection="row" margin={SMALL_SPACING}>
        <StatusFilterMenu />

        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          marginX={DEFAULT_SPACING}
          marginTop={SMALL_SPACING}
        >
          <Box display="flex" flexDirection="row" marginBottom={SMALL_SPACING}>
            <Box display="flex" flexGrow={1} marginRight={SMALL_SPACING}>
              <SearchBar
                placeholder={this.props.t("propertyListPage.searchBarPlaceholder")}
                value={this.props.searchText}
                onChange={this.props.onSearchTextChanged}
              />
            </Box>

            {this.props.canCreateProperties ? (
              <AddPropertyButton onClick={this.handleAddPropertyClicked} />
            ) : undefined}

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: EXTRA_LARGE_SPACING }}
            />

            {this.props.handleExportClicked !== undefined && [
              //Needs discussion //- they represent totality of all the properties of the organisation //but the exported results do not correspond to the items that are shown on the page //NOTE: Weird behavior - it is impossible to export if the property list is empty,
              <DataRangePicker
                dateRangeState={this.props.exportDate}
                setDateRange={(date) => {
                  this.props.setExportDate(date);
                }}
              />,

              <ExportReportButton
                disabled={
                  this.props.properties?.length === 0 ||
                  this.props.properties === undefined ||
                  this.props.isGeneratingReport ||
                  this.props.exportDate === null ||
                  this.props.exportDate.filter((date) => date === undefined).length > 0
                }
                onClick={() =>
                  this.props.handleExportClicked !== undefined &&
                  this.props.exportDate.length === 2
                    ? this.props.handleExportClicked(
                        this.props.exportDate[0],
                        this.props.exportDate[1]
                      )
                    : undefined
                }
              />,
            ]}

            {this.props.moveProperties && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: EXTRA_LARGE_SPACING }}
                />
                <MovePropertiesHandler
                  properties={this.props.moveProperties.selectedProperties}
                />
              </>
            )}
          </Box>

          {!!this.props.moveProperties &&
            this.props.moveProperties.selectedProperties.length > 0 && (
              <SelectedPropertiesPanel
                showSelected={this.props.moveProperties.showOnlySelected}
                selectedCount={this.props.moveProperties.selectedProperties.length}
                onClearPressed={this.props.moveProperties.handleResetSelectedProperties}
                switchShowItems={this.props.moveProperties.handleShowSelectedSwitch}
              />
            )}

          {this.renderBody()}

          <Box height={tableBottomSpacing} />

          {!this.props.moveProperties?.showOnlySelected && (
            <TablePageIndicator
              maxPages={this.props.maxPages}
              selectedPage={this.props.currentPage}
              onPageNumberChanged={this.props.onPageNumberChanged}
            />
          )}
        </Box>

        <AddPropertyDialog
          onPropertyFormSubmitted={this.props.onPropertyFormSubmitted}
          open={this.state.isAddPropertyDialogOpen}
          onClose={this.handleAddPropertyDialogClosed}
          isLoading={this.props.addingProperty}
          error={this.props.addPropertyError}
          isPropertyAdded={this.props.isPropertyAdded}
        />
      </Box>
    );
  }

  renderBody = () => {
    if (this.props.errorMessage) {
      return (
        <ErrorDisplay
          title={this.props.t("propertyListPage.errorTitle")}
          message={this.props.errorMessage}
        />
      );
    } else if (this.props.properties?.length === 0) {
      return <PropertyListEmptyMessage />;
    } else if (this.props.properties) {
      const tableItems = (
        this.props.moveProperties?.showOnlySelected
          ? this.props.moveProperties.selectedProperties
          : this.props.properties
      ).map((p) =>
        mapToPropertyTableItem(
          p,
          this.props.t,
          false,
          this.isPropertySelected(p, this.props.moveProperties?.selectedProperties ?? [])
        )
      );
      return (
        <PropertyTable
          tableItems={tableItems}
          showOwner={this.props.showOwnerInTable}
          onClick={this.handlePropertyClicked}
          onSelect={this.props.moveProperties ? this.handlePropertySelected : undefined}
        />
      );
    } else {
      return <Spinner />;
    }
  };

  // Helpers
  isPropertySelected = (property: Property, selectedProperties: Property[]) => {
    return selectedProperties.filter((p) => p.id === property.id).length > 0;
  };

  // Handlers

  handleAddPropertyClicked = () => {
    this.setState({ isAddPropertyDialogOpen: true });
  };

  handleAddPropertyDialogClosed = () => {
    this.setState({ isAddPropertyDialogOpen: false });
  };

  handlePropertyClicked = (item: PropertyTableItem) => {
    const property = this.props.properties?.find((p) => p.id === item.id);
    if (property) {
      this.props.onPropertyClicked(property);
    }
  };

  handlePropertySelected = (item: PropertyTableItem) => {
    const property = this.props.properties?.find((p) => p.id === item.id);
    if (property) {
      this.props.moveProperties?.handleSelectProperty(property);
    }
  };
}

export default withTranslation()(FilterablePropertyList);
